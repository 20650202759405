import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-materials-library',
  templateUrl: './materials-library.component.html',
  styleUrls: ['./materials-library.component.scss']
})
export class MaterialsLibraryComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  createMaterial = false;
  selectedCategory = null;
  categories =  {};
  filteredMaterials = [];
  constructor(private appActionsService: AppActionsService, public dataService: DataService) { }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })

  }


  ngOnInit() {
    this.selectedCategory = this.appActionsService.materialsLibrarySelectedCategory;
    this.generateCategories();

    this.subscriptions.push(this.appActionsService.projectCustomMaterialsUpdated.subscribe(() => {console.log('IN SUB'); this.generateCategories() }))

  }

  categoryClicked(catKey) {
    this.selectedCategory = catKey;
    this.appActionsService.materialsLibrarySelectedCategory = catKey;
  }

  generateCategories() {
    this.categories = {
      no_category: [],
      projectMaterials: []
    }
    for (let key in this.dataService.materialsLibrary) {
      const mat = this.dataService.materialsLibrary[key];


      if (!mat.categories) {
        this.categories['no_category'][key] = mat;
      } else {
        if (Array.isArray(mat.categories)) {
          mat.categories.forEach(cat => {
            if (!this.categories[cat]) {
              this.categories[cat] = []
            }

            this.categories[cat].push({ key: key, value: mat })
          })
        }

      }
    }


    //now sort each category
    for (let cat in this.categories) {
      this.categories[cat].sort(function (a, b) {
        if (a.value.name < b.value.name) {
          return -1;
        }
        if (a.value.name > b.value.name) {
          return 1;
        }
        return 0;
      });
    }
  }



  // filterBySelectedCategory() {

  // }

  close() {
    this.appActionsService.materialsLibraryOpen = false;

  }

  async materialClicked(material) {

    
    this.appActionsService.materialClickedOnLibrary.next(material.key)

  }

  createMaterialClicked() {
    this.createMaterial = true;
  }

  onCustomMaterialCreateClosed() {
    this.createMaterial = false;
  }

  removeCustomMaterialClicked(key) {
    this.dataService.removeCustomMaterialInProject(key)
  }

  

}
