import { Component, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import * as THREE from "../../assets/threejs/three.module.js";

@Component({
  selector: 'app-asset-data',
  templateUrl: './asset-data.component.html',
  styleUrls: ['./asset-data.component.scss']
})
export class AssetDataComponent implements OnInit {
  model = null;
  subscriptions: Subscription[] = []; 2
  materials = [];



  constructor(private appActionsService: AppActionsService, private dataService: DataService, private translate: TranslateService) { }

  ngOnInit() {

    this.appActionsService.selectedModel.subscribe((model) => {
      this.model = model;
      
      if (this.model) {

        this.scanMaterials();
      }

    })

  }

  scanMaterials() {
    this.materials = [];
    this.model.traverse(o => {

      let m_list: any[] = [];
      const mat = (o as THREE.Mesh).material
      if (mat) {
        if (Array.isArray(mat)) {
          for (let m of mat) {
            if (this.materials.indexOf(m) < 0) {
              this.materials.push(m)
            }
          }


        } else {
          if (this.materials.indexOf(mat) < 0) {
            this.materials.push(mat)
          }
        }
      }
    })

    
  }

  onMaterialSelected(originalMaterial, newMaterialIndex) {

    if (newMaterialIndex == -1) {

      newMaterialIndex = this.dataService.addNewMaterial()

    }




    const newMaterial = this.dataService.materials[newMaterialIndex]
    if (newMaterial) {

      this.appActionsService.objectDataAction.next({ action: 'materialClicked', materialName: newMaterial.material.name })
      const update = {
        from: originalMaterial.name,
        to: newMaterial.material.uuid
      };
      this.dataService.addAssetMaterialChange(this.model.userData.modelId, update)

      //trigger immidiate upate:
      this.appActionsService.assetCustomMaterialUpdate.next({
        modelId: this.model.userData.modelId,
        changes: {
          0: update
        }
      })

      this.scanMaterials();
    }





  }


  resetMaterials() {
    this.dataService.removeAllAssetMaterialChanges(this.model.userData.modelId)
    this.appActionsService.assetCustomMaterialUpdate.next({ modelId: this.model.userData.modelId, reset: true })
  }
}
