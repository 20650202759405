export class AppConfig {
    public firebaseConfig: any;
    public siteDomain: string;
    public cloudFunctionsConfig:any;

    constructor() {
        this.firebaseConfig = {
            apiKey: "AIzaSyBMlyLJh_k8axrvqK8NIh9CFAoezWVE98I",
            authDomain: "bimshow-preprod1.firebaseapp.com",
            databaseURL: "https://bimshow-preprod1-default-rtdb.firebaseio.com",
            projectId: "bimshow-preprod1",
            storageBucket: "bimshow-preprod1.appspot.com",
            messagingSenderId: "751908351486",
            appId: "1:751908351486:web:cd0971bc2468375a6324af",
            measurementId: "G-W21DD70M3X"
        };

        this.siteDomain = 'https://bimshow.io';

	this.cloudFunctionsConfig = {
            host: "https://europe-west3-bimshow-preprod1.cloudfunctions.net",
        }
    }

}
