import { Injectable, ɵConsole } from "@angular/core";
import { Note } from "../../models/note.model";
import { NoteComment } from "../../models/note-comment.model";
import { User } from "../../models/user.model";
import { UUID } from "angular2-uuid";
import { AppActionsService } from "./app-actions.service";
import { TreeNode } from "../../models/treenode.model";

import * as firebase from "firebase";
import { ReplaySubject, Subject, BehaviorSubject } from "rxjs";
import { ViewButton } from "src/models/viewButton.model";
import { reject } from "q";
import { ZipService } from "./zip.service";
import { resolve } from "url";
import { retryWhen } from "rxjs/operators";
import { ViewConfig } from "src/models/viewConfig.model";
import { AppConfig } from "../../models/appConfig.model";
import { HttpClient } from "@angular/common/http";
import { config } from "process";
import { Zone } from "src/models/zone.model";
import { Layer } from "src/models/layer.model";
import { ThreejsComponent } from "../threejs/threejs.component";
import { TranslateService } from '@ngx-translate/core';
import { FixSpecialChars } from "../pipes/fixSpecialChars.pipe";
import { Router } from '@angular/router'
import { CollectionDimensioning } from "../../assets/utils/Dimensioning.js";

import axios from 'axios'
import { debug } from "console";
import { ToolboxEvent } from "src/models/toolbox-event";
import * as THREE from "../../assets/threejs/three.module.js";


import { GLTFLoader } from "../../assets/threejs/loaders/GLTFLoader";
import { DRACOLoader } from "../../assets/threejs/loaders/DRACOLoader";
import { unzipSync, strFromU8 } from '../../assets/threejs/libs/fflate.module.js';
import { resizeImage } from "../modules/HelperFunctions";



@Injectable({
  providedIn: "root",
})
export class DataService {
  isOwner = false;
  threejsComp: ThreejsComponent | null = null;

  assetsCredits = {}
  materials = [];

  textures = null;

  objectsData = {};

  user = null;

  assetsSize = {
    models: 0,
    materials: 0
  }



  loadModelLocally = false;
  localModel = null;

  notifications = {};

  project = null;
  siteData = {
    longitude: null,
    latitude: null,
    elevation: 0,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    rotate: 0,
    altitude: 0
  };

  project3dObject = null;

  USERS = [];

  notes: Note[] = [];
  altitude: number;

  views = null;
  viewsToDelete = [];
  loadedView = null;
  photos = {}; //object based data. used for easy querying a photo from the note componenet. ("dataService.photos[photoId]")
  compareImages = [];

  labels = {};
  photospheres = {};
  materialsConfigs = {};
  ifcData: any = {};
  viewConfig = new ViewConfig();

  layersConfig = null;
  treeConfig = null;
  notesObjectsRelations = [];
  matStripe: BehaviorSubject<any> = new BehaviorSubject(null);

  photosDataFetched: ReplaySubject<any> = new ReplaySubject(1);

  compareImageAdded: ReplaySubject<any> = new ReplaySubject();
  compareImageChanged: Subject<any> = new Subject();
  compareImageRemoved: ReplaySubject<any> = new ReplaySubject();

  notesDataFetched: ReplaySubject<any> = new ReplaySubject(1);
  notesObjectsRelationsDataFetched: ReplaySubject<any> = new ReplaySubject(1);
  siteDataFetched: Subject<any> = new Subject();
  labelsUpdated: ReplaySubject<any> = new ReplaySubject(1);
  photospheresUpdated: ReplaySubject<any> = new ReplaySubject(1);
  materialsConfigsUpdated: ReplaySubject<any> = new ReplaySubject(1);
  notificationsUpdated: ReplaySubject<any> = new ReplaySubject(1);

  ifcDataFetched: ReplaySubject<any> = new ReplaySubject(1);

  doorsAndWindowsIds = [];
  loggedIn = false;

  objectsVisibility = {};


  cloudFunctionHost = new AppConfig().cloudFunctionsConfig.host;

  firstViewLoad = true;
  projectsThumbnails = {};

  projectTextures = {};
  materialTextureMapping = {};
  projectTexturesUpdated: ReplaySubject<any> = new ReplaySubject(1);

  projectRelatedFirebaseSubscribedRefs: firebase.database.Reference[] = [];

  //added by BL
  lastOpenPhoto: BehaviorSubject<any> = new BehaviorSubject(null);
  cameraFovUpdated: ReplaySubject<number> = new ReplaySubject(72);
  photoAmbientOcclusion: BehaviorSubject<boolean> = new BehaviorSubject(true);
  ambientLightIntensity: BehaviorSubject<number> = new BehaviorSubject(1.6);



  blockOrbit: ReplaySubject<boolean> = new ReplaySubject(1);
  blockZ: ReplaySubject<boolean> = new ReplaySubject(1);
  clipFill: ReplaySubject<boolean> = new ReplaySubject(1);
  stencilsColor: ReplaySubject<string> = new ReplaySubject(1);
  fileSize = 0;
  rotColladaModel: BehaviorSubject<number> = new BehaviorSubject(0.15);
  contrastLightAngle: ReplaySubject<number> = new ReplaySubject(1);
  contrastLightIntensity: ReplaySubject<number> = new ReplaySubject(1);


  projectSize = 0 // in MB
  currentUploads: { [id: string]: { size: number, uploadTask: firebase.storage.UploadTask } } = {};
  currentUploadsSize = 0;
  maxProjectSize = 100 //in mb
  projectViews = 0;

  modelFetchAndLoad = null;

  //to display edge of the meshes
  edgeBimshow: BehaviorSubject<boolean> = new BehaviorSubject(false);
  edgeBimshowColor: BehaviorSubject<string> = new BehaviorSubject('#000000');
  edgeBimshowOpacity: BehaviorSubject<number> = new BehaviorSubject(0.3);

  //sky
  skyEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  transparentMode: boolean;
  expr: any;


  //collection of dimensionings
  collectionDimensioning: CollectionDimensioning = new CollectionDimensioning(null) //need to set the scene later in threejs component
  dimensioningCollections: BehaviorSubject<any[]> = new BehaviorSubject(null) //list of collections name / key



  //model-lib:
  modelsCache: { [key: string]: THREE.Object3D } = {}
  modelsLibrary = null;
  modelDownloadsPromises: { [key: string]: Promise<any> } = {}
  modelsUpdated = new ReplaySubject(1);
  models = {};
  modelAddedByUser = new ReplaySubject(1)


  //maerial-lib
  materialsLibrary = {};


  //overwrites
  overwrites = {};
  overwriteKeyToGuid = {}
  deletedObjects = {};
  overwritesChanged: ReplaySubject<any> = new ReplaySubject(1);;
  deletedObjectsChanged: ReplaySubject<any> = new ReplaySubject(1);
  deletedObjectsKeyToGuid = {};


  firebaseUploadTasks = [];

  measures = {};

  lightweightData: any = {};

  currentLoadedPhotoId = null;

  constructor(
    private appActionsService: AppActionsService,
    private zipService: ZipService,
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router
  ) {



    // Initialize Firebase

    var config = new AppConfig().firebaseConfig;

    firebase.initializeApp(config);



    this.appActionsService.openProject.subscribe(async (project) => {

      this.appActionsService.editorMode = false;
      this.appActionsService.projectDataReady.next(false);
      this.project = project;
      if (this.user.level == 'admin') { this.maxProjectSize = 500; }


      if (await this.isViewAllowedByViewsCredit()) {

        this.updateProjectVisit();

        this.initProjectData().then(async (done) => {
          if (!this.appActionsService.lightVersion) {
            await Promise.all([
              this.fetchModelsLib(),
              this.fetchMaterialsLib().catch(err => { }),
            ]);

            this.updateAssetsSize();
            this.checkAssetsCredit();

          }

          ///finish all initing? so tell everyone.

          this.appActionsService.projectDataReady.next(true);


        });
      } else {
        //cant see project due to views quota exceeded
        this.appActionsService.viewsWarningOn = true;

      }

    });

    this.appActionsService.openProjectWhileUploading.subscribe((project) => {
      this.loadModelLocally = true;
      this.appActionsService.openProject.next(project);
    });
  }

  unsubscribeFromAllProjectRelatedFirebaseSubscribedRefs() {
    this.projectRelatedFirebaseSubscribedRefs.forEach(s => s.off())
    this.projectRelatedFirebaseSubscribedRefs = [];
  }

  async claimProjectOwner(pid) {

    await this.callHttpCloudFunction("askProjectOwner", {
      projectId: pid
    })

    await this.getToken(true)

  }


  async initProjectData() {
    try {
      this.isOwner = false;
      if (this.project.roles[this.user.id] === 'owner') {
        await this.claimProjectOwner(this.project.id);
        this.isOwner = true;
      }

      await firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((res) => {
        });
    } catch (err) {
      return err;
    }

    this.logUserAction("project_init");
    //reseting parameters:
    this.siteData = {
      longitude: null,
      latitude: null,
      elevation: 0,
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
      rotate: 0,
      altitude: 0
    };
    this.viewConfig = new ViewConfig();

    if (this.loadModelLocally == false) { //when loading locally we already have the projectsize set, and if we do it again we gonna delete some current uploads(ifcfile/threejsfile)
      this.initProjectSizeParams();
    }


    this.projectViews = 0;

    this.models = {};
    this.textures = {};
    this.loadedView = null;
    this.objectsData = {};
    this.notes = [];
    this.views = {};
    this.photos = {};
    this.compareImages = [];
    this.labels = {};
    this.photospheres = {};
    this.materialsConfigs = {};
    this.appActionsService.viewLoaded.next(null);
    this.appActionsService.openNote.next(null);
    this.doorsAndWindowsIds = [];
    this.firstViewLoad = true;
    this.projectTextures = {};
    this.overwrites = {};
    this.overwriteKeyToGuid = {};
    this.deletedObjectsKeyToGuid = {};
    this.deletedObjects = {}
    this.models

    this.layersConfig = null;
    this.treeConfig = null;
    this.notesObjectsRelations = [];

    //reseting the relpaySubjects
    this.appActionsService.modelIdToAdd = new ReplaySubject(1);
    this.appActionsService.updateModelsVisFromConfig = new ReplaySubject(1);
    this.appActionsService.selectedModelId = new ReplaySubject(1);
    this.appActionsService.resetShadowCamSize = new ReplaySubject(1);
    this.appActionsService.resetShadowTarget = new ReplaySubject(1);
    this.appActionsService.directionalLightOptionsChanged = new ReplaySubject(1);
    this.appActionsService.loadViewConfig.next(null);
    this.appActionsService.selectedDataModeChanged = new ReplaySubject(1)
    this.photosDataFetched = new ReplaySubject(1);
    this.appActionsService.objectDataAction = new ReplaySubject(1);
    this.appActionsService.toolboxEvents = new ReplaySubject(1);
    this.appActionsService.materialsGenerated = new ReplaySubject(1);
    this.appActionsService.materialsUpdated = new ReplaySubject(1);
    this.appActionsService.objectCustomMaterialUpdated = new ReplaySubject(1);
    this.appActionsService.updateMaterialsForSingleMesh = new ReplaySubject(1);
    this.appActionsService.updateMaterialsForSingleMesh = new ReplaySubject(1)
    this.appActionsService.objectCustomMaterialReset = new ReplaySubject(1);
    this.appActionsService.startRotating = new ReplaySubject(1);
    this.appActionsService.stopRotating = new ReplaySubject(1);
    this.appActionsService.updateModelsVisFromConfig = new ReplaySubject(1);
    this.appActionsService.selectedModelId = new ReplaySubject(1);
    this.appActionsService.toggleObjectHidden = new ReplaySubject(1);
    this.compareImageAdded = new ReplaySubject();
    this.compareImageRemoved = new ReplaySubject();
    this.notesDataFetched = new ReplaySubject(1);
    this.labelsUpdated = new ReplaySubject(1);
    this.photospheresUpdated = new ReplaySubject(1);
    this.materialsConfigsUpdated = new ReplaySubject(1);
    this.modelsUpdated = new ReplaySubject(1);
    this.modelAddedByUser = new ReplaySubject(1);
    this.overwritesChanged = new ReplaySubject(1);
    this.deletedObjectsChanged = new ReplaySubject(1);

    this.ifcDataFetched = new ReplaySubject(1);
    this.projectTexturesUpdated = new ReplaySubject(1);
    this.appActionsService.project3dObjectGenerated = new ReplaySubject(1);
    this.notesObjectsRelationsDataFetched = new ReplaySubject(1);

    this.blockOrbit = new ReplaySubject(1);
    this.blockZ = new ReplaySubject(1);
    this.contrastLightAngle = new ReplaySubject(1);
    this.contrastLightIntensity = new ReplaySubject(1);
    this.clipFill = new ReplaySubject(1);
    this.blockOrbit.next(false);
    this.blockZ.next(true);
    this.clipFill.next(false);


    this.measures = (await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/measures").once("value")).val()
    if (!this.measures) {

      this.measures = {
        '0defaultkey': {
          name: this.translate.instant('measure.defaultGroup'),
          visible: true,
          color: '#000000',
          fontColor: '#000000',
          children: {}
        }
      }


      this.viewConfig.measuresConfig['0defaultkey'] = true;


    }

    this.lightweightData = (await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/lightweightData").once("value")).val()


    this.stencilsColor.next('#E91E63');

    this.unsubscribeFromAllProjectRelatedFirebaseSubscribedRefs();

    let addCompletedImage = (image) => {
      this.getSignedUrl('projects/' + this.project.id + '/compareTool/' + image.id + "." + image.type)
        .then((url) => {
          image["url"] = url;

          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            image["imageData"] = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();

          let userOpacity, userVisible;
          if (this.viewConfig) {
            if (this.viewConfig.pdfCompareMode) {
              if (this.viewConfig.pdfCompareMode[image.id]) {
                userOpacity = this.viewConfig.pdfCompareMode[image.id].opacity;
                userVisible = this.viewConfig.pdfCompareMode[image.id].visible;
              }
            }
          }

          image["viewConfig"] = {
            opacity: userOpacity ? userOpacity : 1,
            visible: userVisible != null ? userVisible : false,
            open: false,
          };

          if (image.lockAspectRatio == null) {
            image["lockAspectRatio"] = true;
          }

          if (image.height == null) {
            image.height = 100;
          }

          if (image.width == null) {
            image.width = (image.height * image.aspectRatio).toFixed(0);
          }

          this.compareImages.push(image);
          this.compareImageAdded.next(image);
        })
        .catch((error) => {
          // Handle any errors
          console.warn(error);
        });
    };


    const compareImagesRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/compareImages");
    this.projectRelatedFirebaseSubscribedRefs.push(compareImagesRef);

    compareImagesRef.on("child_changed", (snapshot) => {
      let image = snapshot.val();
      image["id"] = snapshot.key;

      let imageExist = null;

      for (let existingImage of this.compareImages) {
        //check if image exist already
        if (existingImage.id == image.id) {
          imageExist = existingImage; //assign the exisiting image
        }
      }

      if (imageExist) {
        //copy the fetched image properties to our image, without deleting extra image we inserted on client!

        for (let property in image) {
          imageExist[property] = image[property];
        }
        this.compareImageChanged.next(imageExist);
      } else {
        //item doesnt exist yet.. so we need to add
        if (image.uploadCompleted) {
          addCompletedImage(image);
        }
      }
    });

    compareImagesRef.on("child_removed", (snapshot) => {
      for (let i = 0; i < this.compareImages.length; i++) {
        if (this.compareImages[i].id == snapshot.key) {
          this.compareImages.splice(i, 1);
          this.compareImageRemoved.next(snapshot.key);
          i = this.compareImages.length;
        }
      }
    });


    compareImagesRef.on("child_added", (snapshot) => {
      let image = snapshot.val();
      image["id"] = snapshot.key;

      if (image["uploadCompleted"]) {
        addCompletedImage(image);
      }
    });


    const labelsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/labels");
    this.projectRelatedFirebaseSubscribedRefs.push(labelsRef);

    labelsRef.on("child_changed", (snapshot) => {
      let updatedLabel = snapshot.val();
      for (let property in updatedLabel) {
        this.labels[snapshot.key][property] = updatedLabel[property];
      }
      this.labelsUpdated.next();
    });

    labelsRef.on("child_removed", (snapshot) => {
      delete this.labels[snapshot.key];
      this.labelsUpdated.next();
    });

    labelsRef.on("child_added", (snapshot) => {
      this.labels[snapshot.key] = snapshot.val();
      //add offset if not exist for old labels
      if (this.labels[snapshot.key].offset == null) {
        this.labels[snapshot.key].offset = {
          x: 0,
          y: 0,
          z: 0
        }
      }
      this.labelsUpdated.next();
    });


    const photospheresRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photospheres");
    this.projectRelatedFirebaseSubscribedRefs.push(photospheresRef)

    photospheresRef.on("child_changed", (snapshot) => {
      let updatedPhotosphere = snapshot.val();
      for (let property in updatedPhotosphere) {
        this.photospheres[snapshot.key][property] =
          updatedPhotosphere[property];
      }

      this.photospheresUpdated.next();
    });

    photospheresRef.on("child_removed", (snapshot) => {
      delete this.photospheres[snapshot.key];
      this.photospheresUpdated.next();
    });

    photospheresRef.on("child_added", (snapshot) => {
      this.photospheres[snapshot.key] = snapshot.val();
      this.photospheresUpdated.next();
    });

    const materialsConfigsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs");
    this.projectRelatedFirebaseSubscribedRefs.push(materialsConfigsRef);

    materialsConfigsRef.on("child_changed", (snapshot) => {
      this.materialsConfigs[snapshot.key] = snapshot.val();
      this.updateAssetsSize();
      this.materialsConfigsUpdated.next({
        type: "child_changed",
        key: snapshot.key,
      });
    });

    materialsConfigsRef.on("child_removed", (snapshot) => {
      delete this.materialsConfigs[snapshot.key];
      this.materialsConfigsUpdated.next({
        type: "child_removed",
        key: snapshot.key,
      });
      this.updateAssetsSize();
    });

    materialsConfigsRef.on("child_added", (snapshot) => {
      this.materialsConfigs[snapshot.key] = snapshot.val();
      this.materialsConfigsUpdated.next({
        type: "child_added",
        key: snapshot.key,
      });
      console.log('matconf updated')
      this.updateAssetsSize();
    });



    firebase
      .database()
      .ref("projects/" + this.project.id + "/data/views")
      .once("value")
      .then(async (snap) => {
        if (snap.val()) {
          this.views = snap.val();
        }

        await this.loadDefaultView();
        this.appActionsService.viewsDataLoaded.next();
      });

    const photosRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos");
    this.projectRelatedFirebaseSubscribedRefs.push(photosRef);

    photosRef.on("value", (snapshot) => {
      let fetchedPhotos = snapshot.val();
      let photos = [];
      for (let key in fetchedPhotos) {
        fetchedPhotos[key]["id"] = key;
        photos.push(fetchedPhotos[key]);
      }

      this.photos = fetchedPhotos ? fetchedPhotos : {};
      this.photosDataFetched.next(photos);
    });

    const notesRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/notes");

    this.projectRelatedFirebaseSubscribedRefs.push(notesRef);
    notesRef.on("value", (snapshot) => {
      let fetchedNotes = snapshot.val();
      let notes = [];
      for (let key in fetchedNotes) {
        fetchedNotes[key]["id"] = key;
        notes.push(fetchedNotes[key]);
      }

      this.notes = notes;
      this.notesDataFetched.next(notes);
    });


    const notesObjectsRelationsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/notesObjectsRelations");
    this.projectRelatedFirebaseSubscribedRefs.push(notesObjectsRelationsRef)
    notesObjectsRelationsRef.on("value", (snapshot) => {
      let fetchedRels = snapshot.val();
      let notesObjectsRelations = [];

      for (let key in fetchedRels) {
        fetchedRels[key]["id"] = key;

        notesObjectsRelations.push(fetchedRels[key]);
      }

      this.notesObjectsRelations = notesObjectsRelations;
      this.notesObjectsRelationsDataFetched.next(notesObjectsRelations);
    });


    const siteDataRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/siteData");
    this.projectRelatedFirebaseSubscribedRefs.push(siteDataRef);

    siteDataRef.on("value", (snapshot) => {
      let newSiteData = snapshot.val();

      if (newSiteData != null) {
        if (newSiteData.longitude != null && newSiteData.latitude != null) {
          this.siteData = newSiteData;
        } else {
          this.siteData.offsetX = newSiteData.offsetX;
          this.siteData.offsetY = newSiteData.offsetY;
          this.siteData.offsetZ = newSiteData.offsetZ;
          this.siteData.rotate = newSiteData.rotate;
        }
      }

      this.siteDataFetched.next();
      this.updateTimezone();
    });


    const stencilsColorRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/details/stencilsColor");
    this.projectRelatedFirebaseSubscribedRefs.push(stencilsColorRef);
    stencilsColorRef.on("value", (snapshot) => {

      const color = snapshot.val();
      if (color != null) {
        this.stencilsColor.next(String(snapshot.val()));
      } else {
        this.stencilsColor.next('#E91E63');
      }

    });

    const projectSizeRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/details/size");
    this.projectRelatedFirebaseSubscribedRefs.push(projectSizeRef);
    projectSizeRef.on("value", (snapshot) => {



      this.projectSize = Number(snapshot.val()) / 1024 / 1024
      this.appActionsService.projectSizeParamsUpdated.next();
    });


    const modelsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/models");
    this.projectRelatedFirebaseSubscribedRefs.push(modelsRef)

    modelsRef.on("child_changed", (snapshot) => {
      let updateModel = snapshot.val();
      for (let property in updateModel) {
        this.models[snapshot.key][property] =
          updateModel[property];
      }

      this.modelsUpdated.next();
    });

    modelsRef.on("child_removed", (snapshot) => {
      delete this.models[snapshot.key];
      this.modelsUpdated.next();
      this.updateAssetsSize();
      this.checkAssetsCredit();
    });

    modelsRef.on("child_added", (snapshot) => {
      this.models[snapshot.key] = snapshot.val();
      this.modelsUpdated.next();
      this.updateAssetsSize();
      this.checkAssetsCredit();
    });


    const overwritesRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/overwrites");
    this.projectRelatedFirebaseSubscribedRefs.push(overwritesRef)

    overwritesRef.on("child_changed", (snapshot) => {
      const value = snapshot.val();
      this.overwrites[value.guid] = value;
      this.overwritesChanged.next({ type: 'child_changed', guid: value.guid, value });
    });

    overwritesRef.on("child_removed", (snapshot) => {


      const guid = this.overwriteKeyToGuid[snapshot.key];
      delete this.overwrites[guid];
      this.overwritesChanged.next({ type: 'child_removed', guid: guid });

    });

    overwritesRef.on("child_added", (snapshot) => {
      const value = snapshot.val();
      this.overwrites[value.guid] = value
      this.overwriteKeyToGuid[snapshot.key] = value.guid;

      this.overwritesChanged.next({ type: 'child_added', guid: value.guid, value });

    });

    const deletedObjectsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/deletedObjects");
    this.projectRelatedFirebaseSubscribedRefs.push(deletedObjectsRef)


    deletedObjectsRef.on("child_removed", (snapshot) => {
      const guid = this.deletedObjectsKeyToGuid[snapshot.key];
      delete this.deletedObjects[guid];
      this.deletedObjectsChanged.next({ type: 'child_removed', guid: guid });

    });

    deletedObjectsRef.on("child_added", (snapshot) => {
      const guid = snapshot.val();
      this.deletedObjectsKeyToGuid[snapshot.key] = guid;
      this.deletedObjects[guid] = true;
      this.deletedObjectsChanged.next({ type: 'child_added', guid: guid });

    });

    const customMaterialsRef = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materials");



    customMaterialsRef.on("child_removed", async (snapshot) => {
      const updates = []

      for (let configKey in this.materialsConfigs) {
        for (let m_key in this.materialsConfigs[configKey].materials) {
          const material = this.materialsConfigs[configKey].materials[m_key];
          if (material.lib_material === snapshot.key) {
            material.lib_material = null;
            if (this.isOwner) {
              updates.push(firebase.database().ref(`projects/${this.project.id}/data/materialsConfigs/${configKey}/materials/${m_key}`).update({ lib_material: null }))
            }

          }
        }
      }

      // await Promise.all(updates) //i chose to comment this, but i keep it here. actually i think we dont need

      delete this.materialsLibrary[snapshot.key];
      this.appActionsService.projectCustomMaterialsUpdated.next();

    });

    customMaterialsRef.on("child_added", (snapshot) => {

      const material = snapshot.val();
      this.materialsLibrary[snapshot.key] = material;
      material.categories = ['projectMaterials']
      this.appActionsService.projectCustomMaterialsUpdated.next();

    });

    this.materialTextureMapping = (
      await firebase
        .database()
        .ref("projects")
        .child(this.project.id)
        .child("data")
        .child("materialTextureMapping")
        .once("value")
    ).val();


    this.calcProjectSize(this.project.id) //calc projectsize every project load in order to fix some errors in size calculations.
  }

  getAllNotes(): Note[] {
    return this.notes;
  }

  getPinnedNotes(): Note[] {
    let pinnedNotes: Note[] = [];

    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i].position) {
        pinnedNotes.push(this.notes[i]);
      }
    }

    return pinnedNotes;
  }


  //@mg get size of blob
  formatSizeUnits(bytes) {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
  }

  createNote(title, description, type, options?) {
    return new Promise<Note>((resolve, reject) => {
      let newNote = new Note(title, description, this.user.id, type, options);
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/notes")
        .push(newNote)
        .then((snap) => {
          snap.once("value").then((snapshot) => {
            let fetchedNote = snapshot.val();
            fetchedNote["id"] = snap.key;

            resolve(fetchedNote);
          });
        });
    });
  }

  deleteNote(noteId) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/notes/" + noteId)
      .remove();


  }


  addNewNoteComment(text: string, noteId: string) {
    return firebase
      .database()
      .ref(
        "projects/" + this.project.id + "/data/notes/" + noteId + "/comments"
      )
      .push(new NoteComment(text, this.user.id, noteId));
  }

  deleteComment(noteId: string, commentId: string) {
    return firebase
      .database()
      .ref(
        "projects/" +
        this.project.id +
        "/data/notes/" +
        noteId +
        "/comments/" +
        commentId
      )
      .remove();
  }

  relatePhotoToNote(noteId, photoId) {
    return firebase
      .database()
      .ref(
        "projects/" +
        this.project.id +
        "/data/notes/" +
        noteId +
        "/relatedPhotos"
      )
      .child(photoId)
      .set({ relatedBy: this.user.id })
      .catch((err) => {
        console.warn(err);
      });
  }

  removePhotoFromNote(noteId, photoId) {
    return firebase
      .database()
      .ref(
        "projects/" +
        this.project.id +
        "/data/notes/" +
        noteId +
        "/relatedPhotos/" +
        photoId
      )
      .remove();
  }

  getNoteById(noteId) {
    let note = null;
    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i].id == noteId) {
        note = this.notes[i];
      }
    }

    return note;
  }

  getRelatedNoteOfObject(objectGuid) {
    let relatedNotesIds = [];
    let relatedNotes = [];

    for (let i = 0; i < this.notesObjectsRelations.length; i++) {
      if (this.notesObjectsRelations[i].objectGuid == objectGuid) {
        relatedNotesIds.push(this.notesObjectsRelations[i].noteId);
      }
    }

    relatedNotesIds.forEach((noteId) => {
      for (let i = 0; i < this.notes.length; i++) {
        if (this.notes[i].id == noteId) {
          relatedNotes.push(this.notes[i]);
        }
      }
    });

    return relatedNotes;
  }

  getRelatedObjectsOfNote(
    noteId: string
  ): { noteId: string; objectGuid: string }[] {
    let relatedObjects = [];

    for (let i = 0; i < this.notesObjectsRelations.length; i++) {
      if (this.notesObjectsRelations[i].noteId == noteId) {
        relatedObjects.push(this.notesObjectsRelations[i]);
      }
    }

    return relatedObjects;
  }

  removeRelatedObjectToNote(relId) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref(
          "projects/" + this.project.id + "/data/notesObjectsRelations/" + relId
        )
        .remove()
        .then(
          (resolved) => {
            resolve("relation was deleted");
          },
          (rejected) => {
            reject(rejected);
          }
        );
    });
  }

  addRelatedObjectToNote(noteId: string, objectGuid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let o = this.getObjectIdOfGuid(objectGuid);
      if (o) {
        for (let i = 0; i < this.notesObjectsRelations.length; i++) {
          if (
            this.notesObjectsRelations[i].noteId == noteId &&
            this.notesObjectsRelations[i].objectGuid == objectGuid
          ) {
            reject({
              code: "1",
              message: "Object already related to this note",
            });
            return;
          }
        }

        firebase
          .database()
          .ref("projects/" + this.project.id + "/data/notesObjectsRelations")
          .push({
            noteId: noteId,
            objectGuid: objectGuid,
            createdBy: this.user.id,
          })
          .then(() => {

            resolve("Object added");
            return;
          })
          .catch((err) => {

            reject({ code: "2", message: "Permission error" });
            return;
          });
      } else {
        reject({ code: "0", message: "GUID does not exist" });
        return;
      }

      // })
    });
  }

  //implementing firebase:

  signup(userDetails) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(userDetails.email, userDetails.password)
        .then((snap) => {
          return firebase.database().ref("users").child(snap.user.uid).set({
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            avatarUrl: "",
            createdOn: new Date().toUTCString(),
          });
        })
        .then(() => {
          resolve("user added");
        })
        .catch(function (error) {

          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;

          reject(error);
        });
    });
  }

  logout() {
    return firebase.auth().signOut();
  }

  getToken(forceRefresh = false) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdToken(forceRefresh)
        .then(function (idToken) {
          // Send token to your backend via HTTPS
          // ...
          resolve(idToken);
        })
        .catch(function (error) {
          // Handle error
          reject();
        });
    });
  }

  login(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((res) => {

          this.user = res.user;

          firebase
            .database()
            .ref("users")
            .child(res.user.uid)
            .update({ lastLogin: new Date().toUTCString() });

          this.getToken(true).then(
            (token) => {
              resolve("loggedIn");
            },
            (bad) => {
              reject(bad);
            }
          );
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;


          reject(error);
          // ...
        });
    });
  }

  async loginAnonymous() {
    this.user = (await firebase.auth().signInAnonymously()).user;


  }

  checkIfUserLoggedAlready() {
    return new Promise<any>((resolve, reject) => {
      // Create a callback which logs the current auth state
      firebase.auth().onAuthStateChanged((user) => {

        if (user) {
          this.user = user;


          if (user.email) { //only if non anonnym...
            firebase
              .database()
              .ref("users")
              .child(user.uid)
              .update({ lastLogin: new Date().toUTCString() })
              .catch((err) => {
                //do nothing if error..
              });
          }

          this.getToken(true).then(
            (token) => {
              resolve(user);
            },
            (bad) => {
              reject(bad);
            }
          );
          // ...
        } else {
          // User is signed out.
          // ...

          reject();
        }
      });
    });
  }

  initNotifications() {
    // //notifications
    // for (let i = 0; i < 2; i++) {
    //   firebase.database().ref('notifications').child(this.user.uid).push({
    //     title: 'Title',
    //     text: 'Notification Text',
    //     date: new Date(2021, i).toUTCString()
    //   }).then(r => {

    //   });
    // }

    firebase
      .database()
      .ref("notifications")
      .child(this.user.uid)
      .on("child_changed", (snapshot) => {

        let updatedNotifcation = snapshot.val();
        for (let property in updatedNotifcation) {
          this.notifications[snapshot.key][property] =
            updatedNotifcation[property];
        }

        this.notificationsUpdated.next();
      });

    firebase
      .database()
      .ref("notifications")
      .child(this.user.uid)
      .on("child_removed", (snapshot) => {
        delete this.notifications[snapshot.key];
        this.notificationsUpdated.next();
      });

    firebase
      .database()
      .ref("notifications")
      .child(this.user.uid)
      .on("child_added", (snapshot) => {
        this.notifications[snapshot.key] = snapshot.val();
        this.notificationsUpdated.next();
      });
  }

  getUserData() {

    if (!this.loggedIn) {
      this.initNotifications();
    }

    return new Promise<any>((resolve, reject) => {
      let userData = null;
      let publicProjects = [];

      firebase
        .database()
        .ref("users/" + this.user.uid)
        .once("value")
        .then((snapshot) => {
          userData = snapshot.val() || {};
          userData["id"] = this.user.uid;
          userData["uid"] = this.user.uid;
          userData["isAnonymous"] = this.user.isAnonymous;

          //public projects:
          firebase
            .database()
            .ref("publicProjectsIds")
            .once("value")
            .then((snap) => {
              for (let pid in snap.val()) {
                if (userData.ownedProjects) {
                  //filtering out the owner's projects to be pushed to public projects
                  if (!userData.ownedProjects[pid]) {
                    publicProjects.push(pid);
                  }
                } else {
                  publicProjects.push(pid);
                }
              }
            })
            .then(() => {
              userData["projects"] = [];

              let projectDataFecthingPromises = [];
              let pidToFetch = [];
              let ownedProjects = [];
              publicProjects;

              for (let key in userData.ownedProjects) {
                ownedProjects.push(key);
              }



              if (ownedProjects) {
                if (ownedProjects.length > 0) {
                  pidToFetch = pidToFetch.concat(ownedProjects);
                }
              }

              if (publicProjects) {
                if (publicProjects.length > 0) {
                  pidToFetch = pidToFetch.concat(publicProjects);
                }
              }

              if (pidToFetch.length == 0) {

                resolve(userData);
              }

              pidToFetch.forEach((pid) => {
                projectDataFecthingPromises.push(
                  firebase
                    .database()
                    .ref("projects/" + pid + "/details")
                    .once("value")
                    .then((snapshot) => {
                      let projectToAdd = snapshot.val();

                      projectToAdd["id"] = pid;

                      userData.projects.push(projectToAdd); //keep in mind, now its actually project.details that are stored there..
                    })
                    .catch((err) => {
                      console.warn(err);
                    })
                );
              });

              Promise.all(
                projectDataFecthingPromises.map((p) => p.catch((err) => err))
              ).then((data) => {
                resolve(userData);
              });
            });
        })
        .catch((err) => {
          reject(err);
        });
    }).then((resolved) => {
      this.user = resolved;

      this.appActionsService.userSubscriptionIsActive = this.user.subscription ? this.user.subscription.active : false;
      if (!this.appActionsService.userSubscriptionIsActive) {
        if (this.user.subscription) {
          this.appActionsService.notify(this.translate.instant('generalWarnings.noActiveSubscriptionError'), 'warn', 300)
        }

      }

      this.getProjectsThumbnails();
      this.loggedIn = true;
      this.appActionsService.loggedIn.next(true);
      this.getAllUsersDetails();
    });
  }

  reloadUserData() {

    return this.getUserData().then(
      (resolved) => {
        this.appActionsService.projectsDataChanged.next();
      },
      (rejected) => {
        console.warn(rejected);
      }
    );
  }

  async getProjectModel(project) {
    if (this.loadModelLocally == false) {
      const url = await this.getProjectModelUrl(project);

      // const zipBlob = await (await fetch(url)).blob();

      this.modelFetchAndLoad = {
        name: 'modelFetchAndLoad',
        step: 'fetch',
        progress: 0,
        eta: null

      };


      const modelLibFetch = this.downloadAllExistingModelsInProject();

      const zipBlob = await this.downloadFile(url, (progress, eta) => {
        this.modelFetchAndLoad.progress = progress;
        this.modelFetchAndLoad.eta = eta;

      })

      this.modelFetchAndLoad.eta = null;
      this.modelFetchAndLoad.step = 'downloading_models';
      this.modelFetchAndLoad.progress = 0;

      await modelLibFetch;

      this.modelFetchAndLoad.eta = null;
      this.modelFetchAndLoad.step = 'unzip';
      this.modelFetchAndLoad.progress = 0;

      this.fileSize = this.formatSizeUnits(zipBlob.size)
      if (this.project.type == "threejs") {
        try {
          const unzippedJson = await this.zipService.unzipJsonModel(zipBlob);
          this.modelFetchAndLoad = null;
          await this.loadIfcData(
            this.generateIfcData(JSON.parse(unzippedJson))
          );
          return { type: "json", data: unzippedJson };
        } catch (err) {
          console.warn("error unziping json model");
          throw err;
        }
      } else {
        try {
          const unzipped = await this.zipService.unzip(zipBlob);
          this.modelFetchAndLoad = null;
          await this.loadIfcData();

          return { type: "dae", blob: unzipped.dae.blob };
        } catch (err) {
          console.warn(err);
          throw err;
        }
      }
    } else {
      //load locally
      const ifcData = this.generateIfcData(this.localModel);

      await this.loadIfcData(ifcData);
      this.loadModelLocally = false;
      return { type: "json", data: JSON.stringify(this.localModel) };
    }
  }

  async getProjectModelUrl(project) {
    const fileName =
      project.type == "threejs" ? "model.zip" : project.daeFileName;
    const url = await this.getSignedUrl('projects/' + project.id + '/generatedModels/' + fileName);

    return url;

  }

  getProjectIFCUrl() {
    return this.getSignedUrl('projects/' + this.project.id + '/ifcFiles/ifcFile.ifc');

  }

  fetchUnlistedProject(pid) {
    return new Promise((resolve, reject) => {
      if (
        this.user.projects.filter((p) => {
          p.id == pid;
        }).length == 0
      ) {

        firebase
          .database()
          .ref("projects/" + pid + "/details")
          .once("value")
          .then((snapshot) => {
            let projectToAdd = snapshot.val();

            projectToAdd["id"] = pid;

            this.user.projects.push(projectToAdd);
            resolve(projectToAdd);
          })
          .catch((err) => reject(err));
      } else {
        reject();
      }
    });
  }


  async uploadImageToFirebase(file, name, aspectRatio) {
    let type = null;
    switch (file.type) {
      // case 'application/pdf':
      //   type = 'pdf'
      //   break;
      case "image/png":
        type = "png";
        break;
      case "image/jpeg":
        type = "jpeg";
        break;
      case "image/gif":
        type = "gif";
        break;
    }

    if (type == null) {

      return;
    }



    const snap = await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/compareImages")
      .push({
        name: name,
        createdBy: this.user.id,
        type: type,
        aspectRatio: aspectRatio,
        uploadCompleted: false,
        opacity: 1
      })

    try {
      await this.uploadFileToStorage(firebase
        .storage()
        .ref("projects")
        .child(this.project.id)
        .child("compareTool")
        .child(snap.key + "." + type)
        , file,
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        });
    } catch (err) {
      await firebase
        .database()
        .ref("projects/" + this.project.id + "/data/compareImages")
        .child(snap.key).remove();
      throw (err)
    }

    await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/compareImages")
      .child(snap.key)
      .update({ uploadCompleted: true })



  }

  updateImageDataInFirebase(image) {
    let newImage = JSON.parse(JSON.stringify(image));
    delete newImage.viewConfig;
    delete newImage.id;
    delete newImage.imageData;
    delete newImage.modelReady;

    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/compareImages/" + image.id)
      .set(newImage)
      .catch((err) => {
        console.warn(err);
      });
  }

  removeCompareImage(image) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/compareImages/" + image.id)
        .remove()
        .then(
          (ok) => {
            firebase
              .storage()
              .ref("projects")
              .child(this.project.id)
              .child("compareTool")
              .child(image.id + '.' + image.type)
              .delete()
              .catch((err) => {
                console.warn(err);
              });
            resolve("image deleted");
          },
          (rejected) => {
            reject("error deleting image");
          }
        );
    }).catch((err) => {
      console.warn(err);
    });
  }

  getAllUsersDetails() {
    return;
    firebase
      .database()
      .ref("users")
      .once("value")
      .then((snapshot) => {
        let usersList = snapshot.val();
        this.USERS = [];
        for (let uid in usersList) {
          this.USERS.push(
            new User(
              uid,
              usersList[uid].firstName,
              usersList[uid].lastName,
              usersList[uid].email,
              usersList[uid].avatarUrl
            )
          );
        }
      })
      .catch((err) => {
        console.warn(err.message);
      });
  }

  getUserById(id) {
    return new Promise<User>((resolve, reject) => {
      //offline getUser..  works nice and faster with animation and so on... because fetching each user from firebase is quite long.. for example, on comments.. it create reload rendering bad expereince
      //in this case, if user change his name/avatar, the client only see it after he reload the app.. which.. is not that bad thing...
      for (let user of this.USERS) {
        if (user.id == id) {
          resolve(user);
        }
      }
      //if it doesnt find, it will get  it from firebase
      firebase
        .database()
        .ref("users/" + id)
        .once("value")
        .then((snapshot) => {
          let user = snapshot.val();
          user["id"] = id;
          resolve(user);
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });
  }

  reloadProject(project) {
    firebase
      .database()
      .ref("projects/" + project.id + "/details")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() == null) {

          return;
        }
        for (let i = 0; i < this.user.projects.length; i++) {
          if (this.user.projects[i].id == project.id) {
            this.user.projects[i] = snapshot.val();
            this.user.projects[i]["id"] = project.id; // important as projects has no 'id' on 'details' on firebase.. its just in our app that we add.
          }
        }
        this.appActionsService.projectsDataChanged.next();
      });
  }

  changeProjectName(project, name) {
    firebase
      .database()
      .ref("projects/" + project.id + "/details")
      .child("name")
      .set(name)
      .then(
        (resolved) => {
          this.reloadProject(project);
        },
        (rejected) => {
          this.reloadProject(project);
          console.warn(rejected);
        }
      );
  }

  updateUserData(userDataToUpdate) {
    return firebase
      .database()
      .ref("users/" + this.user.id)
      .update(userDataToUpdate)
      .then(() => {
        this.user.firstName = userDataToUpdate.firstName;
        this.user.lastName = userDataToUpdate.lastName;
        this.user.avatarUrl = userDataToUpdate.avatarUrl;
        this.user.birthDate = userDataToUpdate.birthDate;
        this.user.address = userDataToUpdate.address;

        this.getAllUsersDetails();
        this.appActionsService.usersDataChanged.next();
      });
  }

  createNewProject(name) {
    //implemented now inside the collada server..

    let roles = {};
    roles[this.user.id] = "owner";

    let newProject = {
      details: {
        name: name,
        dateCreated: new Date().toUTCString(),
        privacy: "private",
        roles: roles,
      },
    };



    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects")
        .push(newProject)
        .then(
          (snap) => {
            firebase
              .database()
              .ref("users/" + this.user.id + "/ownedProjects")
              .child(snap.key)
              .set(snap.key)
              .then(
                (ok) => {
                  resolve(snap.key);
                },
                (bad) => {
                  reject();
                }
              );
          },
          (err) => {
            console.warn(err);
          }
        );
    });
  }

  async deleteProject(project) {
    await this.callHttpCloudFunction("deleteProject", {
      projectId: project.id
    })

    if (this.project) { //incase there was an open project
      if (project.id == this.project.id) {
        window.location.href = window.location.origin + window.location.pathname;
      }
    }

  }

  updateProject(pid, updates) {
    return firebase
      .database()
      .ref("projects/" + pid + "/details")
      .update(updates)
      .then(() => {
        firebase.database().ref("users/");
      });
  }





  pushToPublicProjectsIds(projectId) {
    return firebase
      .database()
      .ref("publicProjectsIds/")
      .child(projectId)
      .set(projectId);
  }

  removeFromPublicProjectsIds(projectId) {
    return firebase
      .database()
      .ref("publicProjectsIds/")
      .child(projectId)
      .remove();
  }



  async addPhoto(photo, openOnAdded = true) {


    //remove nulled viewconfig:
    for (let config in photo.viewConfig) {
      if (photo.viewConfig[config] == null) {
        delete photo.viewConfig[config];
      }
    }
    const snap = await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos")
      .push(photo)


    let fetchedPhoto = (await snap.once("value")).val();
    fetchedPhoto["id"] = snap.key;
    if (openOnAdded) {
      this.appActionsService.openPhoto.next(fetchedPhoto);
      this.lastOpenPhoto.next(fetchedPhoto);
    }


    return snap.key;



  }

  refreshPhoto(photoId, photo) {
    //to keep up to date the last photo open used by components
    let newPhoto = this.lastOpenPhoto.getValue()
    this.lastOpenPhoto.next(newPhoto)

    delete photo["title"]; //beacuse we want the photo will keep the same name.
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos/" + photoId)
      .update(photo)
  }


  deletePhoto(photo) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/photosData/" + photo.id)
        .remove()
        .then(() => {
          firebase
            .database()
            .ref("projects/" + this.project.id + "/data/photos/" + photo.id)
            .remove()
            .then(() => {
              this.notesDataFetched.next(this.notes); // to initiatie update in note componenet , so if a deleted photo was related, it will refersh
              resolve("photo deleted");
            });
        });
    }).catch((err) => {
      console.warn(err);
    });
  }

  updatePhotoDetails(photoId, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos/" + photoId)
      .update(updates);
  }

  updateNoteDetails(noteId, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/notes/" + noteId)
      .update(updates);
  }

  onProjectDetailsUpdated(projectId) {
    return firebase
      .database()
      .ref("projects/" + projectId + "/details")
      .on("value", (snap) => {
        for (let i = 0; i < this.user.projects.length; i++) {
          if (this.user.projects[i].id == projectId) {
            if (snap.val().daeFileName) {
              this.user.projects[i].daeFileName = snap.val().daeFileName;
            }

            if (snap.val().ifcDataReady) {
              this.user.projects[i].ifcDataReady = snap.val().ifcDataReady;
            }

            if (
              this.user.projects[i].ifcDataReady &&
              this.user.projects[i].daeFileName
            ) {
              firebase
                .database()
                .ref("projects/" + projectId + "/details/daeFileName")
                .off();
            }
          }
        }
      });
  }

  saveSiteData() {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/siteData")
      .set(this.siteData)
      .catch((err) => {
        console.warn(err);
      });
  }

  restoreSiteData() {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/siteData")
      .once("value")
      .then(
        (snap) => {
          let siteData = snap.val();
          if (siteData != null) {
            this.siteData = siteData;
            if (
              this.siteData.longitude == null ||
              this.siteData.latitude == null
            ) {
              this.siteData.longitude = this.getIfcSiteLongLat().longitude;
              this.siteData.latitude = this.getIfcSiteLongLat().latitude;
              this.siteData.altitude = this.altitude;
            }
          } else {
            this.siteData.longitude = this.getIfcSiteLongLat().longitude;
            this.siteData.latitude = this.getIfcSiteLongLat().latitude;
            this.siteData.altitude = this.altitude;
            this.siteData.offsetX = 0;
            this.siteData.offsetY = 0;
            this.siteData.offsetZ = 0;
            this.siteData.rotate = 0;
          }
        },
        (err) => {
          console.warn(err);
        }
      );
  }

  removeButtonFromView(viewId, buttonId) {
    let button = null;
    let index = null;

    for (let i = 0; i < this.views[viewId].menu.length; i++) {
      if (this.views[viewId].menu[i].id == buttonId) {
        this.views[viewId].menu.splice(i, 1);
        return;
      }
      if (this.views[viewId].menu[i].children) {
        for (let j = 0; j < this.views[viewId].menu[i].children.length; j++) {
          if (this.views[viewId].menu[i].children[j].id == buttonId) {
            this.views[viewId].menu[i].children.splice(j, 1);
            if (this.views[viewId].menu[i].children.length == 0) {
              this.views[viewId].menu[i].children = null;
            }
            return;
          }
        }
      }
    }
  }

  createNewMainButton(viewId) {
    if (this.views[viewId]["menu"] == null) {
      this.views[viewId]["menu"] = [];
    }
    this.views[viewId].menu.push(

      new ViewButton(this.translate.instant('viewEditor.MainButton'), null, null, null, "main", {
        action: "loadPhoto",
        value: null,
      })
    );
  }

  createNewView(name?: string, wizardGenerated = false, style?: string) {
    let newView = {
      id: UUID.UUID(),
      name: name || "Untitled View",  //@mg name to translate
      createdBy: this.user.id,
      style: style || "default",
      cameraTransitions: true,
      rotate: true,
      wizardGenerated: wizardGenerated
    };

    this.views[newView.id] = newView;

    let userViewCount = 0;
    for (let id in this.views) {
      if (this.views[id]["createdBy"] == this.user.id) {
        userViewCount++;
      }
    }

    if (userViewCount == 1) {
      this.setViewAsDefult(newView.id);
    }

    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/views/")
        .child(newView.id)
        .set(newView)
        .then(
          //updating all the changes.
          (ok) => {
            resolve(newView);
          },
          (rejected) => {
            reject(rejected);
          }
        );
    });





  }




  deleteView(viewId) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/views/" + viewId)
        .remove()
        .then(() => {
          this.deleteFolderContents(
            "projects/" + this.project.id + "/viewGalleries/" + viewId
          );
          delete this.views[viewId];
          this.loadView(null)
          resolve("view deleted");
        });
    }).catch((err) => {
      console.warn(err);
      reject(err);
    });
  }

  async setStencilsColorInDB(color: string) {
    await firebase
      .database()
      .ref("projects/" + this.project.id + "/details/stencilsColor")
      .set(color)

  }




  setViewAsDefult(viewId) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/details/defaultView")
      .set(viewId)
      .then(
        (ok) => {
          this.project.defaultView = viewId;
        },
        (rejected) => {
          console.warn("error trying to changing default view");
        }
      );
  }

  async getLoadedViewDefaultPhoto() {
    if (this.project.defaultView && this.loadedView) {
      let photoId = this.loadedView.initialPhoto;
      if (photoId) {
        const snap = await firebase
          .database()
          .ref("projects/" + this.project.id + "/data/photos/" + photoId)
          .once("value")

        let photo = snap.val();
        return photo;

      }
    }

    return null
  }

  async loadDefaultView() {

    if (this.project.defaultView) {
      await this.loadView(this.project.defaultView);
    }




  }

  async loadView(viewId) {
    if (viewId) {
      this.loadedView = this.views[viewId];

      if (this.loadedView == null) {
        return;
      }

      let photoId = this.loadedView.initialPhoto;
      if (photoId) {
        const snap = await firebase
          .database()
          .ref("projects/" + this.project.id + "/data/photos/" + photoId)
          .once("value")

        let photo = snap.val();
        if (photo) {
          photo["id"] = snap.key;
          this.loadPhotoView(photo);
        }

      }
    } else {
      this.loadedView = null;
    }

    this.appActionsService.viewLoaded.next(this.loadedView);

    if (this.firstViewLoad) {
      this.firstViewLoad = false;
      if (this.loadedView.rotate) {
        this.appActionsService.startRotating.next();
      } else {
        this.appActionsService.stopRotating.next(true);
      }
    }
  }

  unloadView() {
    this.loadedView = null;
  }

  deleteTempDeletedViews() {
    let deletingPromises = [];

    for (let i of this.viewsToDelete) {
      deletingPromises.push(this.deleteView(i));
    }

    return new Promise<any>((resolve, reject) => {
      Promise.all(deletingPromises.map((p) => p.catch((err) => err))).then(
        (ok) => {
          resolve(true);
        },
        (notok) => {
          reject();
        }
      );
    });
  }

  updateView(viewId, updates) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/views/")
        .child(viewId)
        .update(updates)
        .then(
          //updating all the changes.
          (ok) => {

            resolve("views updated");
          },
          (rejected) => {
            reject(rejected);
          }
        );
    });
  }


  async uploadLabelImageToStorage(file, labelImageName, progressFunc?) {
    const ref = firebase
      .storage()
      .ref("projects")
      .child(this.project.id)
      .child("labels")
      .child(labelImageName)

    await this.uploadFileToStorage(ref, file, progressFunc);
    return ("label image upload to firebase completed")
  }

  async uploadViewImageToStorage(viewId, storageName, file) {
    const ref = firebase
      .storage()
      .ref("projects")
      .child(this.project.id)
      .child("viewGalleries")
      .child(viewId)
      .child(storageName);

    await this.uploadFileToStorage(ref, file);
    return ("image upload to firebase completed")

  }

  deleteRemovedViewImagesFromStorage(view) {
    let allFilesOfViewGallery = [];
    firebase
      .storage()
      .ref("projects")
      .child(this.project.id)
      .child("viewGalleries")
      .child(view.id)
      .listAll()
      .then(
        (list) => {
          for (let file of list.items) {
            let existInGallery = false;
            if (view.gallery) {
              for (let image of view.gallery) {
                if (image.storageName == file.name) {
                  existInGallery = true;
                }
              }
            }

            if (!existInGallery) {
              firebase
                .storage()
                .ref(file.fullPath)
                .delete()
                .then(
                  (deleted) => {
                  },
                  (rejected) => console.warn(rejected)
                )
                .catch((err) => console.warn(err));
            }
          }
        },
        (rejected) => console.warn(rejected)
      );
  }

  getViewImageUrl(image, viewId) {
    return this.getSignedUrl('projects/' + this.project.id + '/viewGalleries/' + viewId + '/' + image.storageName);

  }

  async addLabel(labelData: {
    title: string
    text?: string,
    type: string,
    linkedToId?: string,
    position?: { x: number, y: number, z: number },
    offset?: { x: number, y: number, z: number },
    generatedForViewId?: string
    usePhotoPosition?: boolean,
    showTextBehavior?: string
  }) {
    let newLabel = {
      title: labelData.title,
      text: labelData.text || '',
      createdBy: this.user.id,
      type: labelData.type,
      linkedToId: labelData.linkedToId || '',
      position: labelData.position || { x: 0, y: 0, z: 0 },
      visible: true,
      offset: labelData.offset || { x: 0, y: 0, z: 0 },
      generatedForViewId: labelData.generatedForViewId || null,
      usePhotoPosition: labelData.usePhotoPosition || null,
      showTextBehavior: labelData.showTextBehavior || null
    };
    const res = await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/labels")
      .push(newLabel)

    return res.key;
  }

  updateLabel(key, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/labels")
      .child(key)
      .update(updates);
  }

  removeLabel(key) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/labels")
      .child(key)
      .remove();
  }

  getPhotoFromFirebase(photoId) {
    if (photoId) {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/photos/" + photoId)
        .once("value")
        .then((snap) => {
          let photo = snap.val();
          if (photo) {
            photo["id"] = snap.key;
            return photo
          }
          else {
            return null
          }
        });
    }
  }
  loadPhotoView(photo) {
    if (photo == null) {
      return;
    }

    this.appActionsService.photoOpened.next(photo.id)

    this.viewConfig = new ViewConfig();

    if (photo.viewConfig) {
      //copying all viewconfig parameters. we couldnt just copy the viewconfig, as empty objects are getting deleted in firebase...
      for (let config in photo.viewConfig) {
        this.viewConfig[config] = JSON.parse(
          JSON.stringify(photo.viewConfig[config])
        );
      }
    }

    this.appActionsService.chosenObjectOid.next(null)
    this.appActionsService.loadViewConfig.next(this.viewConfig);
    this.lastOpenPhoto.next(photo);
    this.currentLoadedPhotoId = photo.id;
  }

  saveMeasuresInDB() {
    firebase
      .database()
      .ref().child("projects").child(this.project.id).child("data").child("measures").set(
        this.measures
      )
  }






  addNewDimensioningInCollection(collectionKey, dimensioningKey, dimensioning) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/dimensioningCollections/" + collectionKey + "/dimensionings/" + dimensioningKey)
      .set(dimensioning);
  }
  updateDimensioningInCollection(keyCollection, keyDimensioning, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/dimensioningCollections")
      .child(keyCollection)
      .child("dimensionings")
      .child(keyDimensioning)
      .update(updates);
  }
  removeDimensioningFromCollection(collectionKey, dimensioningKey) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/dimensioningCollections")
      .child(collectionKey)
      .child("dimensionings")
      .child(dimensioningKey)
      .remove();
  }



  //materials

  addNewMaterialConfig(config) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/materialsConfigs")
        .push(config).then((snapshot) => {
          resolve(snapshot.key)
        }).catch(err => { reject(err) })
    })
  }

  updateMaterialInConfig(key, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .child("materials")
      .update(updates);
  }

  //updates : { oid : <oid> , guid : <guid> , materialsToChange : [{<materialUuidToChange>:<newMaterialIndex>}] }
  //LinkObjectMaterials is ordered
  setLinkObjectMaterialInConfig(key, update) {
    const refMaterialConfigMaterials = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .child('LinkObjectMaterials')

    return refMaterialConfigMaterials.set(update)
  }
  //
  // key : config key
  // material : {name : "..." , username: "..."} UUID.UUID()
  // need to modify to declare the new material
  //
  addMaterialInConfig(key, material) {
    //add config
    const result = firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .child("materials")
      .push(material);

    //declare new material
    if ('key' in result) {
      const newMaterial = {}
      newMaterial['newMaterials/' + result.key] = result.key
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/materialsConfigs")
        .child(key)
        .update(newMaterial)
    }

    return result

  }

  updateMaterialName(key, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .child("materials")
      .update(updates);
  }

  removeMaterialConfig(key) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .remove();
  }

  updateMaterialConfigName(key, name) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs")
      .child(key)
      .update({ name: name });
  }

  getMaterialByUuid(uuid) {
    for (let index = 0; index < this.materials.length; index++) {
      if (this.materials[index].material.uuid == uuid) {
        return index
      }
    }
    return -1
  }

  addNewMaterial(oid?) {
    if (this.viewConfig.MaterialsMode.openedConfigKey) {
      const materialToAdd = new THREE.MeshStandardMaterial()
      materialToAdd.userData.name = this.translate.instant('objectData.newMaterial')
      materialToAdd.name = materialToAdd.uuid
      //add to the service data and notify to other component as materials-editor refresh
      const materialToAddToService = {
        material: materialToAdd,
        color: materialToAdd.color,
        envMap: null,
        opacity: materialToAdd.opacity,
        reflectivity: materialToAdd.reflectivity,
        rotation: 0,
        roughness: 1,
        metalness: 0,
        scale: 1,
        textureId: null,
        usedByObjects: oid ? [oid] : []
      }
      let indexNew = (this.materials.push(materialToAddToService) - 1) //push return the new length of the array so -1 to get last index
      //need to declare the new material in firebase config to recreate it when loading

      this.appActionsService.materialsGenerated.next(1)
      this.appActionsService.updateMaterialsForSingleMesh.next();

      //TODO add to the firebase for the current config

      const materialConfigToSave = {
        name: materialToAddToService.material.name,
        userName: materialToAddToService.material.userData.name,
        color: materialToAddToService.material.color.getStyle(),
        opacity: materialToAddToService.opacity,
        scale: materialToAddToService.scale,
        rotation: materialToAddToService.rotation,
        roughness: materialToAddToService.roughness,
        metalness: materialToAddToService.metalness,
        addedToIndex: indexNew,
        reflectivity: 0.9,  //if undefined
      }
      let result = this.addMaterialInConfig(this.viewConfig.MaterialsMode.openedConfigKey, materialConfigToSave)

      //add to the materials-editor namestokey to let update later
      this.appActionsService.materialAdded.next({ key: result.key, name: materialToAdd.name })


      return indexNew
    } else {
      throw new Error("no opened material config")
    }


  }

  // return index in materials of the new one
  setNewMaterialToObject(newMaterialIndex, guid, materialUuidToChange, oid) {


    var indexNew = null
    var materialToAdd = null;
    if (newMaterialIndex == -1) {
      indexNew = this.addNewMaterial(oid)
      materialToAdd = this.materials[indexNew].material;

      //TODO add to the firebase for the current config
      if (this.viewConfig.MaterialsMode.openedConfigKey) {



        //save in firebase config the link between this material and the object, add in threejs scanallmaterials()

        const currentViewConfigLinkObjectMaterial = this.materialsConfigs[this.viewConfig.MaterialsMode.openedConfigKey]

        if (!('LinkObjectMaterials' in currentViewConfigLinkObjectMaterial)) {
          currentViewConfigLinkObjectMaterial.LinkObjectMaterials = []
        }

        currentViewConfigLinkObjectMaterial.LinkObjectMaterials.push(
          {
            oid: oid,
            guid: guid,
            materialUuidToChange: materialUuidToChange,
            newMaterialIndex: indexNew,
            newUuid: materialToAdd.uuid,
            previousMaterialIndex: this.getMaterialByUuid(materialUuidToChange)
          }
        )

        this.appActionsService.objectCustomMaterialUpdated.next(currentViewConfigLinkObjectMaterial.LinkObjectMaterials.slice().pop()) //to update in threejs component
        this.setLinkObjectMaterialInConfig(
          this.viewConfig.MaterialsMode.openedConfigKey,
          currentViewConfigLinkObjectMaterial.LinkObjectMaterials
        )
      }

    }
    else {
      this.materials[newMaterialIndex].usedByObjects.push(oid)

      materialToAdd = this.materials[newMaterialIndex].material
      if (this.viewConfig.MaterialsMode.openedConfigKey) {
        //save in firebase config the link between this material and the object, add in threejs scanallmaterials()

        const currentViewConfigLinkObjectMaterial = this.materialsConfigs[this.viewConfig.MaterialsMode.openedConfigKey]

        if (!('LinkObjectMaterials' in currentViewConfigLinkObjectMaterial)) {
          currentViewConfigLinkObjectMaterial.LinkObjectMaterials = []
        }
        currentViewConfigLinkObjectMaterial.LinkObjectMaterials.push(
          {
            oid: oid,
            guid: guid,
            materialUuidToChange: materialUuidToChange,
            newMaterialIndex: newMaterialIndex,
            newUuid: materialToAdd.uuid,
            previousMaterialIndex: this.getMaterialByUuid(materialUuidToChange)
          }
        )

        this.appActionsService.objectCustomMaterialUpdated.next(currentViewConfigLinkObjectMaterial.LinkObjectMaterials.slice().pop()) //to update in threejs component

        this.setLinkObjectMaterialInConfig(
          this.viewConfig.MaterialsMode.openedConfigKey,
          currentViewConfigLinkObjectMaterial.LinkObjectMaterials
        )
      }
    }
    //apply to the current threejs session
    //test if the object is an array or single
    if (this.objectsData[oid]) { //we check if exist incase of object removed in ifc update
      if (Array.isArray(this.objectsData[oid].material)) {
        //potentially the material is multiple times in the material array
        for (let index = 0; index < this.objectsData[oid].material.length; index++) {
          if (this.objectsData[oid].material[index].uuid == materialUuidToChange) {
            this.objectsData[oid].material[index] = materialToAdd;
          }
        }
      }
      else { //single material and not array (DOES THAT EXIST????)
        this.objectsData[oid].material = materialToAdd
      }
    }
    //ask to update it to 3JS
    this.appActionsService.materialsUpdated.next()

    //remove object from materialItem usedby

    this.materials.forEach(materialItem => {
      if (materialItem.material.uuid == materialUuidToChange) {
        const indexOid = materialItem.usedByObjects.indexOf(oid);
        if (indexOid > -1) {
          materialItem.usedByObjects.splice(indexOid, 1)
        }
      }
    });

    return indexNew
  }

  logUserAction(actionName, extra?) {
    if (this.user.level == "admin") {
      return;
    }

    let newAction = {
      name: actionName,
      date: new Date().toUTCString(),
      userId: this.user.id,
      projectId: this.project.id,
    };

    if (extra) {
      newAction["extra"] = extra;
    }

    firebase
      .database()
      .ref("usersActions")
      .push(newAction)
      .catch((err) => {
        console.warn(err);
      });
  }

  getLabelOfObject(guid) {
    for (let key in this.labels) {
      if (this.labels[key].guid == guid) {
        return this.labels[key];
      }
    }

    return null;
  }

  updateProjectVisit() {
    let visit = {
      user: {
        id: this.user.id,
        name: this.user.firstName + " " + this.user.lastName,
        email: this.user.email || "none",
      },
      project: {
        id: this.project.id,
        name: this.project.name,
      },
      date: new Date().toUTCString(),
    };


    //older visits logging:
    firebase
      .database()
      .ref("visits")
      .push(visit)
      .catch((err) => {
        console.warn(err);
      });


  }

  duplicateView() {
    let newView = JSON.parse(JSON.stringify(this.loadedView));

    newView.id = UUID.UUID();
    newView.name = "duplicated view";

    this.views[newView.id] = newView;


  }

  async loadIfcData(data?) {
    this.ifcData = {}; //reset
    if (data) {
      this.ifcData = data;
    } else {
      this.ifcData = (
        await firebase
          .database()
          .ref("projects")
          .child(this.project.id)
          .child("data")
          .child("ifcData")
          .once("value")
      ).val();
    }
    this.ifcData.guidToId = {};

    for (let id in this.ifcData.nodes) {
      this.ifcData.guidToId[this.ifcData.nodes[id].guid] = id;
    }




    this.getIfcSiteLongLat();
    if (this.siteData.longitude == null || this.siteData.latitude == null) {
      let longLat = this.getIfcSiteLongLat();

      this.siteData.longitude = longLat.longitude;
      this.siteData.latitude = longLat.latitude;
      this.updateTimezone();
    }
    this.appActionsService.ifcDataFetched.next(this.ifcData);
  }



  getIfcSiteLongLat() {
    let project = this.ifcData.tree;

    return {
      longitude: project.RefLongitude || 0,
      latitude: project.RefLatitude || 0,
    };
  }

  getObjectIdOfGuid(guid) {
    return this.ifcData.guidToId[guid];
  }

  getGuidOfObjectId(objectId) {

    if (this.ifcData.nodes[objectId]) {

      return this.ifcData.nodes[objectId].guid;
    }

  }

  getLayers() {
    let layers: Layer[] = [];
    if (this.ifcData.layers) {
      for (let i = 0; i < this.ifcData.layers.length; i++) {
        let layer = new Layer(
          i,
          this.ifcData.layers[i].name,
          this.ifcData.layers[i].objects ? this.ifcData.layers[i].objects : [],
          1
        );
        layers.push(layer);
      }
    }

    return layers;
  }



  getZones() {
    let zones: Zone[] = [];

    for (let id in this.ifcData.nodes) {
      let node = this.ifcData.nodes[id];

      if (node.type == "IfcSpace") {
        zones.push(new Zone(id, node.guid, node.Name, node.LongName));
      }
    }

    return zones;
  }

  getObjectDataByObjectId(objectId) {
    if (this.ifcData.nodes[objectId]) {
      return [
        { fieldName: "Name", value: this.ifcData.nodes[objectId].Name },
        { fieldName: "Type", value: this.ifcData.nodes[objectId].type },
        { fieldName: "GUID", value: this.ifcData.nodes[objectId].guid }
      ];
    } else {
      return [];
    }
  }

  getVolumeDataByObjectId(objectId) {
    if (this.ifcData.nodes[objectId]) {

      return this.ifcData.nodes[objectId].volume

    } else {
      return;
    }
  }

  getAllDoorsAndWindows() {
    if (this.doorsAndWindowsIds.length == 0) {
      for (let id in this.ifcData.nodes) {
        let node = this.ifcData.nodes[id];

        if (node.type == "IfcWindow" || node.type == "IfcDoor") {
          this.doorsAndWindowsIds.push(node.guid);
        }
      }
    }

    return this.doorsAndWindowsIds;
  }

  deleteFolderContents(path) {
    const ref = firebase.storage().ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          this.deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  deleteFile(pathToFile, fileName) {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  }

  getProjectsThumbnails() {
    for (let project of this.user.projects) {
      if (project.defaultView) {
        firebase
          .database()
          .ref("projects")
          .child(project.id)
          .child("data")
          .child("views")
          .child(project.defaultView)
          .child("initialPhoto")
          .once("value", (snap) => {
            const initialPhotoId = snap.val();
            if (initialPhotoId) {
              firebase
                .database()
                .ref("projects")
                .child(project.id)
                .child("data")
                .child("photos")
                .child(initialPhotoId)
                .once("value", (snap) => {
                  const photoData = snap.val();
                  if (photoData && photoData.thumbData) {
                    this.projectsThumbnails[project.id] = photoData.thumbData;
                  } else {
                    this.projectsThumbnails[project.id] = project.fallbackThumb;
                  }
                });
            } else {
              this.projectsThumbnails[project.id] = project.fallbackThumb;
            }
          });
      } else {
        this.projectsThumbnails[project.id] = project.fallbackThumb;
      }
    }
  }

  async addNewPhotoSphere(file) {
    const projectId = this.project.id;

    const extension = file.name.split(".").pop();
    const id = UUID.UUID();

    await this.uploadFileToStorage(firebase
      .storage()
      .ref("projects")
      .child(projectId)
      .child("photospheres")
      .child(id + "." + extension)
      , file
    )

    await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photospheres")
      .child(id)
      .set({
        id: id,
        storageName: id + "." + extension,
        name: file.name,
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        radius: 1000,
        opacity: 1,
        yaw: 0,
        pitch: 0,
        showAlways: true,
      })

    return id;

  }

  updatePhotosphere(id, updates) {


    //update realtime database
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photospheres")
      .child(id)
      .update(updates);
  }

  deletePhotosphere(id) {
    const storageName = this.photospheres[id].storageName;


    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/photospheres")
        .child(id)
        .remove()
        .then((removed) => {
          firebase
            .storage()
            .ref("projects")
            .child(this.project.id)
            .child("photospheres")
            .child(storageName)
            .delete()
            .then(
              (ok) => {
                resolve(null);
              },
              (notDeletedFromStorage) => {
                console.warn(
                  "photosphere  removed from DB but not removed from storage"
                );
              }
            );
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  }

  getPhotosphereImageUrl(storageName) {
    return this.getSignedUrl('projects/' + this.project.id + '/photospheres/' + storageName);

  }


  archiveNotifcation(key) {
    firebase
      .database()
      .ref("notifications")
      .child(this.user.id)
      .child(key)
      .update({ archived: true });
  }

  markNotificationAsRead(key) {
    firebase
      .database()
      .ref("notifications")
      .child(this.user.id)
      .child(key)
      .update({ markedAsRead: true });
  }

  markAllNotifcationsAsRead() {
    for (let key in this.notifications) {
      if (!this.notifications[key].markedAsRead) {
        this.markNotificationAsRead(key);
      }
    }
  }

  async updateIfcProject(projectId, jsonModel, ifcFile?) {

    // //upload model, but dont await for it! so it can upload in background..
    await this.claimProjectOwner(projectId);
    this.uploadThreejsModel(jsonModel, projectId).catch(err => {
      console.warn('error on update threejsmodel')
    })

    if (ifcFile) {
      this.uploadIfcFile(ifcFile, projectId).catch(err => {
        console.warn('error on update ifc file')
      })
    }
  }

  async updateMaterialConfigsInUpdatedProject(projectId, jsonModel) {
    await this.claimProjectOwner(projectId); //why we need this? looks like we dont upload anything to storage here..

    let matConfigs = (await firebase
      .database()
      .ref("projects/" + projectId + "/data/materialsConfigs")
      .once("value")).val();


    if (matConfigs == null) {
      return;
    }

    const dict = {};
    const oldToNewIdMap = {

    }
    jsonModel.materials.forEach(m => {
      if (m.userData.originalName) {
        dict[m.userData.originalName] = m.uuid;
      }

    })

    for (let configId in matConfigs) {
      const matConfig = matConfigs[configId];
      Object.keys(matConfig.materials).forEach(key => {
        const matValues = matConfig.materials[key];
        const newId = dict[matValues.originalUserName];

        if (newId) {
          oldToNewIdMap[matValues.name] = newId
          matValues.name = newId;

        } 
      })


      Object.keys(matConfig.LinkObjectMaterials).forEach(key => {
        const linkValue = matConfig.LinkObjectMaterials[key];
        const newUuid = oldToNewIdMap[linkValue.newUuid];
        const materialUuidToChange = oldToNewIdMap[linkValue.materialUuidToChange];
        if (newUuid) {
          linkValue.newUuid = oldToNewIdMap[linkValue.newUuid];
        }
        if (materialUuidToChange) {

          linkValue.materialUuidToChange = materialUuidToChange
        }
      })

    }





    await firebase
      .database()
      .ref("projects/" + projectId + "/data/materialsConfigs")
      .update(matConfigs);


  }

  async createIfcProject(jsonModel, name, ifcFile?) {

    this.initProjectSizeParams();

    let projectDetails = {
      type: "threejs",
      roles: {},
      name: name,
      privacy: "private",
      dateCreated: new Date().toUTCString(),
    };

    projectDetails.roles[this.user.id] = "owner";

    let projectId = (
      await firebase
        .database()
        .ref("projects")
        .push({ details: projectDetails })
    ).key;
    await firebase
      .database()
      .ref("users/" + this.user.id + "/ownedProjects")
      .child(projectId)
      .set(projectId);



    // //upload model, but dont await for it! so it can upload in background..
    await this.claimProjectOwner(projectId);

    this.uploadThreejsModel(jsonModel, projectId).catch(err => {
      console.warn('errorr uploading threejs model ')
    })

    if (ifcFile) {
      this.uploadIfcFile(ifcFile, projectId).catch(err => {
        console.warn('errorr uploading ifc file ');
      })
    }

    return projectId;
  }

  async createThreejsProject(
    jsonModel,
    ifcData,
    materialTextureMapping,
    textures,
    name
  ) {

    this.initProjectSizeParams();


    let projectDetails = {
      type: "threejs",
      roles: {},
      origin: "collada",
      name: name,
      privacy: "private",
      dateCreated: new Date().toUTCString(),
      ifcDataReady: true,
    };

    projectDetails.roles[this.user.id] = "owner";

    let projectData = {
      materialTextureMapping: materialTextureMapping,
    };

    let projectId = (
      await firebase
        .database()
        .ref("projects")
        .push({ details: projectDetails, data: projectData })
    ).key;

    await firebase
      .database()
      .ref("users/" + this.user.id + "/ownedProjects")
      .child(projectId)
      .set(projectId);
    await this.claimProjectOwner(projectId);

    const textureUploads = [];

    let progs = {};


    if (textures.length > 0) {
      for (let texture of textures) {
        const previewImage = await resizeImage(texture.blob);
        progs[texture.id] = 0;
        textureUploads.push(
          // this.uploadTextureToProject(texture, projectId, progs)
          this.createCustomMaterialInProject({
            maps: {
              albedo: texture.blob
            },
            name: texture.id,
            enableEnvMap: false,
            preview: previewImage,
            size: texture.blob.size / 1024 / 1024
          }, projectId)
        );
      }

      const ivl = setInterval(() => {
        let total = 0;
        let n = 0;
        for (let id in progs) {
          n++;
          total += progs[id];
        }

        if (total / n >= 100) {
          clearInterval(ivl);
        }
      }, 1000);

      await Promise.all(textureUploads);
    }

    await this.uploadThreejsModel(jsonModel, projectId, false)


  }

  async uploadIfcFile(ifcFile, projectId) {


    return new Promise((resolve, reject) => { //this doesnt count on storage calculation, so we dont use uploadFileToStorage here.

      try {
        const progressSteps = []
        this.appActionsService.removeTask("ifcUpload") //incase was not removed.
        this.appActionsService.addTask("ifcUpload", { step: "uploading" })

        const task = firebase
          .storage()
          .ref("projects")
          .child(projectId)
          .child("ifcFiles")
          .child("ifcFile.ifc")
          .put(ifcFile);
        this.currentUploads[UUID.UUID()] = {
          size: 0, //because we dont want to calc this size on proejct size,
          uploadTask: task
        }


        task
          .on(
            "state_changed",
            (snapshot) => {
              const { eta, progress } = this.getProgressCalcs(snapshot.bytesTransferred, snapshot.totalBytes, progressSteps)
              console.log("update task ifcUpload state_changed")
              this.appActionsService.updateTask("ifcUpload", { eta, progress });



            },
            (error) => {
              console.warn(error);
              console.log("update task ifcUpload error")
              this.appActionsService.updateTask("ifcUpload", { status: "error" })
              reject(error)
              return;
            },
            () => {
              console.log("update task ifcUpload done")
              this.appActionsService.updateTask("ifcUpload", { status: "done" })
              resolve('dones')
              return;
            }
          )
      } catch (err) {
        reject(err)
      }

    })
  }

  async uploadThreejsModel(json, projectId, appActionsProgress = true) {

    const progressSteps = []
    this.appActionsService.removeTask("modelUpload") //incase was not removed.
    this.appActionsService.addTask("modelUpload", { step: "uploading", important: true })



    try {
      let file = new File([JSON.stringify(json)], "model.json", {
        type: "application/json",
      });
      const zipped = await this.zipService.zip(file, (percent) => {
        // console.log('in callback: ', percent)
      });


      await this.uploadFileToStorage(
        firebase
          .storage()
          .ref("projects")
          .child(projectId)
          .child("generatedModels")
          .child("model.zip"),
        zipped, (snapshot) => {
          if (!appActionsProgress) {
            return;
          }

          const { eta, progress } = this.getProgressCalcs(snapshot.bytesTransferred, snapshot.totalBytes, progressSteps)
          console.log("update task model updated progress", progress)
          this.appActionsService.updateTask("modelUpload", { eta, progress });

        })

    } catch (err) {
      console.warn(err);
      console.log("update task model updated error")
      this.appActionsService.updateTask("modelUpload", { status: "error", important: false })
      throw (err)
    }

    console.log("update task model updated done")
    this.appActionsService.updateTask("modelUpload", { status: "done", important: false })

    await firebase
      .database()
      .ref("projects")
      .child(projectId)
      .child('details').update({
        modelZip: true,
      }
      )



    this.reloadUserData();


  }



  async uploadTextureToProject(texture, pid, progs) {

    return new Promise((resolve, reject) => { //we dont use uploadFileToStorage here as project with textures is a dae file project type, and its generated before project loads.
      firebase
        .storage()
        .ref("projects")
        .child(pid)
        .child("textures")
        .child(texture.id + "." + texture.type)
        .put(texture.blob)
        .on(
          "state_changed",
          function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            progs[texture.id] =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(progs[texture.id] )
            // console.log('Upload is ' + progress.toFixed(1) + '% done')
          },
          (error) => {
            console.warn(error);
            reject(error);
            return;
          },
          () => {
            resolve(true);
            return;
          }
        );
    });
  }

  generateIfcData(jsonModel) {

    let filterNonVisualGeometry = (object, parent) => { //filter out objects that has no mesh and has no children with mesh.
      // console.log(object)
      if (object.children) {
        const childrenCopy = object.children.slice();
        for (let child of childrenCopy) {
          filterNonVisualGeometry(child, object);
        }

      }

      if (parent) {
        const hasNoChildren = object.children ? object.children.length == 0 : true;
        if (hasNoChildren && object.type == 'Object3D') { //has no mesh
          const i = parent.children.indexOf(object);
          if (i > -1) {
            parent.children.splice(i, 1)
          }

        }
      }
    }

    filterNonVisualGeometry(jsonModel.object, null);

    let nodes = {};
    let generateNodes = (object) => {
      // console.log(object)
      if (object.name == "") {
        //  change object name to guid!
        object.name = object.uuid;
      }

      const node: any = {
        children: [],
        id: object.name,
        guid: object.name,
        Name: object.userData ? object.userData.name : null,
        LongName: object.userData ? object.userData.longName : null,
        type: object.userData ? object.userData.ifcType : null,
        volume: object.userData ? object.userData.volume : null
      };

      if (node.type == "IfcProject") {
        node["RefLongitude"] = object.userData.RefLongitude;
        node["RefLatitude"] = object.userData.RefLatitude;
      }

      if (object.children) {
        for (let children of object.children) {
          const childNode = generateNodes(children);
          childNode.parentId = node.id;
          node.children.push(childNode);
        }
      }

      nodes[node.id] = node;
      return node;
    };

    let tree = generateNodes(jsonModel.object);


    return { tree: tree, nodes: nodes, layers: jsonModel.layers || [] };
  }


  async onFirstImageTaken(imgData) {
    const ref = firebase
      .database()
      .ref("projects/" + this.project.id + "/details")
      .child("fallbackThumb");
    let fallbackPhoto = (await ref.once("value")).val();
    if (fallbackPhoto == null) {
      ref.set(imgData);
    }
  }

  async generateTemplateFromTree(templateType) {
    const newView = await this.createNewView('Wizard View (' + templateType + ')', true, 'style1');
    this.loadView(newView.id);


    this.expr = templateType;
    // switch (this.expr) {
    //   case 'fromGalleryTemplate':
    //     console.log('Gallery template is based from snapshots in your snapshot gallery');
    //     break;
    //   case 'whiteModeltemplate':
    //     console.log('Auto snapshots around the model');
    //     break;
    //   case 'roomsAsButtons':
    //     console.log('All IFCSpaces (or zones) snapshots are generated on the menu -  Geolocation and elevations snaps');
    //     break;
    //   case 'roomsAsLabels':
    //     console.log('Labels are created for all IFCSpaces (or zones) -  Geolocation and elevations snaps');
    //     break;
    //   case 'simpleTemplate':
    //     console.log('Storeys and elevations snaps -  No geolocation');
    //     break;
    //   default:
    //     console.log(`Sorry, we are out of ${this.expr}.`);
    // }




    const homePhoto = this.threejsComp.generateHomePhoto()



    let menu = [];

    // make a view with already taken photos
    if (templateType == 'fromGalleryTemplate') {


      function pastelColors() {
        var r = (Math.round(Math.random() * 54) + 1).toString(16);
        var g = (Math.round(Math.random() * 54) + 54).toString(16);
        var b = (Math.round(Math.random() * 94) + 54).toString(16);
        return '#' + r + g + b;
      }
      for (let key in this.photos) {
        let photo = this.photos[key]

        menu.push({
          type: 'main',
          background: pastelColors(),
          id: UUID.UUID(),
          title: photo.title,
          onClick: {
            action: 'loadPhoto',
            value: key
          }
        })
      }
    }
    // make a view with 4 randow photos
    else if (templateType == 'whiteModeltemplate') {

      this.appActionsService.toolboxEvents.next(new ToolboxEvent('wizard', 'updates', this.constructor.name, { createMaterialConfig: { type: 'white', status: 'start' } }))
      await new Promise((resolve, reject) => {
        this.appActionsService.toolboxEvents.subscribe(event => {

          if (event.tool === 'wizard') {
            if (event.updates.createMaterialConfig.type === 'white' && event.updates.createMaterialConfig.status === 'done') {
              resolve('DOME')
              //todo: remove subscription.
            }
          }
        })
      })



      const { photo1, photo2, photo3, photo4 } = this.threejsComp.generateWhitePhotos();

      const photo1Key = await this.addPhoto(photo1, false)
      const photo2Key = await this.addPhoto(photo2, false)
      const photo3Key = await this.addPhoto(photo3, false)
      const photo4Key = await this.addPhoto(photo4, false)

      menu.push({
        type: 'main',
        background: '#000000',
        id: UUID.UUID(),
        title: this.translate.instant('wizard.viewGenerator.viewpoint').concat(" 1"),
        onClick: {
          action: 'loadPhoto',
          value: photo3Key
        }
      }
      )

      menu.push({
        type: 'main',
        background: '#455a64',
        id: UUID.UUID(),
        title: this.translate.instant('wizard.viewGenerator.viewpoint').concat(" 2"),
        onClick: {
          action: 'loadPhoto',
          value: photo2Key
        }
      }
      )

      menu.push({
        type: 'main',
        background: '#000000',
        id: UUID.UUID(),
        title: this.translate.instant('wizard.viewGenerator.viewpoint').concat(" 3"),
        onClick: {
          action: 'loadPhoto',
          value: photo4Key
        }
      }
      )

      menu.push({
        type: 'main',
        background: '#455a64',
        id: UUID.UUID(),
        title: this.translate.instant('wizard.viewGenerator.viewpoint').concat(" 4"),
        onClick: {
          action: 'loadPhoto',
          value: photo1Key
        }
      }
      )



    }
    else {

      // bunch of mapbox photos
      if (templateType == 'roomsAsLabels' || templateType == 'roomsAsButtons') {
        const { photoFar, photoClose } = this.threejsComp.generateSitesPhotos();
        const farPhotoKey = await this.addPhoto({ ...photoFar, generatedForViewId: newView.id }, false)
        const closePhotoKey = await this.addPhoto({ ...photoClose, generatedForViewId: newView.id }, false)
        menu.push({
          type: 'main',
          background: '#1a2f19',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.siteFar'),
          onClick: {
            action: 'loadPhoto',
            value: farPhotoKey
          }
        })

        menu.push({
          type: 'main',
          background: '#2e402c',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.siteClose'),
          onClick: {
            action: 'loadPhoto',
            value: closePhotoKey
          }
        })


      }
      // bunch of elevation photos
      if (templateType == 'roomsAsLabels' || templateType == 'roomsAsButtons' || templateType == 'simpleTemplate') {

        const { photoNorth, photoSouth, photoEast, photoWest } = this.threejsComp.generateSitesPhotos();
        const photoNorthKey = await this.addPhoto({ ...photoNorth, generatedForViewId: newView.id }, false)
        const photoSouthKey = await this.addPhoto({ ...photoSouth, generatedForViewId: newView.id }, false)
        const photoEastKey = await this.addPhoto({ ...photoEast, generatedForViewId: newView.id }, false)
        const photoWestKey = await this.addPhoto({ ...photoWest, generatedForViewId: newView.id }, false)


        const directionsButton = {
          type: 'main',
          background: '#5f6961',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.directions'),
          children: [],
          onClick: {
            action: 'doNothing'
          }
        }
        menu.push(directionsButton)

        directionsButton.children.push({
          type: 'sub',
          background: '#000000',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.photoSideEast'),
          onClick: {
            action: 'loadPhoto',
            value: photoEastKey
          }
        }
        )

        directionsButton.children.push(
          {
            type: 'sub',
            background: '#000000',
            id: UUID.UUID(),
            title: this.translate.instant('wizard.viewGenerator.photoSideSouth'),
            onClick: {
              action: 'loadPhoto',
              value: photoSouthKey
            }
          }
        )

        directionsButton.children.push({
          type: 'sub',
          background: '#000000',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.photoSideWest'),
          onClick: {
            action: 'loadPhoto',
            value: photoWestKey
          }
        }
        )

        directionsButton.children.push({
          type: 'sub',
          background: '#000000',
          id: UUID.UUID(),
          title: this.translate.instant('wizard.viewGenerator.photoSideNorth'),
          onClick: {
            action: 'loadPhoto',
            value: photoNorthKey
          }
        }
        )



      }
      // storeys
      if (templateType == 'roomsAsLabels' || templateType == 'roomsAsButtons' || templateType == 'simpleTemplate') {

        let floorPromises = Object.keys(this.ifcData.nodes).filter(key => (this.ifcData.nodes[key].type == 'IfcBuildingStorey')).map(async key => {

          const node = this.ifcData.nodes[key];

          const roomButtons = [];
          const roomLabels = [];

          if (templateType == "roomsAsLabels" || templateType == 'roomsAsButtons') {
            let roomPromises = node.children.map(async childNode => {
              if (childNode.type == 'IfcSpace') {
                let roomPhoto = this.threejsComp.generateSpacePhoto(childNode.guid);
                roomPhoto.title = childNode.LongName + ' - ' + childNode.Name;
                if (roomPhoto) {

                  const photoKey = await this.addPhoto({ ...roomPhoto, generatedForViewId: newView.id }, false)

                  if (templateType == 'roomsAsButtons') {
                    roomButtons.push({
                      type: 'sub',
                      background: '#000000',
                      id: UUID.UUID(),
                      title: childNode.LongName + ' - ' + childNode.Name,
                      onClick: {
                        action: 'loadPhoto',
                        value: photoKey
                      }
                    })
                  }

                  if (templateType == 'roomsAsLabels') {
                    //todo: add all label keys to array to change photo label config so room will be visibile
                    roomLabels.push(await this.addLabel({
                      title: childNode.LongName + ' - ' + childNode.Name,
                      type: 'photo',
                      linkedToId: photoKey,
                      generatedForViewId: newView.id,
                      usePhotoPosition: true,
                      showTextBehavior: 'hover'
                    }))
                  }

                }

              }
            })


            await Promise.all(roomPromises);
          }



          let floorPhoto = this.threejsComp.generateFloorPhoto(node.guid)
          floorPhoto.title = node.Name + ' (' + floorPhoto.title + ')';

          const parentBuildingNode = this.ifcData.nodes[node.parentId];
          const index = parentBuildingNode.children.indexOf(node);
          const hiddenObjects = parentBuildingNode.children.slice(index + 1).map(floorNode => ({ guid: floorNode.guid, hidden: true }))

          floorPhoto.viewConfig.treeConfig['hiddenObjects'] = hiddenObjects;

          if (templateType == 'roomsAsLabels') {
            floorPhoto.viewConfig.labelsConfig = {};
            roomLabels.forEach(labelKey => {
              floorPhoto.viewConfig.labelsConfig[labelKey] = { alwaysShowText: true, visible: true }
            })

          }
          if (templateType != 'fromGalleryTemplate') {
            const floorPhotoKey = await this.addPhoto({ ...floorPhoto, generatedForViewId: newView.id }, false)

            const floorButton: any = {
              type: templateType == 'roomsAsLabels' ? 'sub' : 'main',
              background: '#000000',
              id: UUID.UUID(),
              title: node.Name,
              onClick: {
                action: 'loadPhoto',
                value: floorPhotoKey
              }

            };
            if (roomButtons.length > 0) {
              floorButton.children = roomButtons;
            }
            return floorButton;
          }
        }
        )

        if (templateType != 'fromGalleryTemplate') {
          const floorsButtons = await Promise.all(floorPromises);

          if (templateType == 'roomsAsButtons' || templateType == 'simpleTemplate') {
            menu = menu.concat(floorsButtons);

          }

          if (templateType == 'roomsAsLabels') {
            menu.push({
              type: 'main',
              background: '#455a64',
              id: UUID.UUID(),
              title: this.translate.instant('wizard.viewGenerator.floors'),
              children: floorsButtons,
              onClick: {
                action: 'doNothing'
              }
            });
          }


        }








      }
    }



    this.loadedView.menu = menu;
    const homePhotoKey = await this.addPhoto({ ...homePhoto, generatedForViewId: newView.id }, false);
    this.loadedView.initialPhoto = homePhotoKey;
    this.updateView(this.loadedView.id, this.loadedView)
    this.loadPhotoView(this.photos[homePhotoKey]);



  }

  async updatePhotoViewConfig(photoId, updates) {

    const res = await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos/" + photoId + '/viewConfig')
      .update(updates)

    return ({
      updatesApplied: updates,
      status: 'succeed'
    })

  }
  async updateCameraPhotoViewConfig(photoId, updates) {

    const res = await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/photos/" + photoId + '/viewConfig/cameraParameters/')
      .update(updates)

    return ({
      updatesApplied: updates,
      status: 'succeed'
    })

  }



  async applyViewConfigUpdatesToAllPhotos(updateMode) {
    let updates = {};

    updates[updateMode] = JSON.parse(JSON.stringify(this.viewConfig[updateMode]))






    const taskId = this.appActionsService.addUserBlockingTask('Updating photos');
    const promises = Object.keys(this.photos).map((photoId) => this.updatePhotoViewConfig(photoId, updates));


    const promisesSettled = promises.map((promise) => promise.catch((err) => { console.warn(err) }))


    await Promise.all(promisesSettled) //this will just make us "wait" until all promises were either rejected or resolbved

    this.appActionsService.removeUserBlockTask(taskId);

    try {
      await Promise.all(promises) //this will make us know if we have atleast 1 error (rejected promise above)

    } catch (err) {
      //now we want to notify user.
      console.warn('ERROR UPDATING ONE OR MORE PHOTOS')
    }


  }
  async removeAllLabels() {
    for (let key in this.labels) {
      this.removeLabel(key)
    }

  }


  // cameraEffectsParameters
  async applyCameraViewConfigUpdatesToAllPhotos(updateMode) {
    let updates = {};

    this.threejsComp.updateCameraParamsInViewConfig();


    if (updateMode == 'lights') {
      updates = {
        directionalLightOptions: JSON.parse(JSON.stringify(this.viewConfig.cameraParameters.directionalLightOptions)),
        ambientLightIntensity: this.viewConfig.cameraParameters.ambientLightIntensity,
        contrastLightAngle: this.viewConfig.cameraParameters.contrastLightAngle,
        contrastLightIntensity: this.viewConfig.cameraParameters.contrastLightIntensity
      }



      const taskId = this.appActionsService.addUserBlockingTask('Updating photos');
      const promises = Object.keys(this.photos).map((photoId) => this.updateCameraPhotoViewConfig(photoId, updates));
      const promisesSettled = promises.map((promise) => promise.catch((err) => { console.warn(err) }))

      await Promise.all(promisesSettled) //this will just make us "wait" until all promises were either rejected or resolbved

      this.appActionsService.removeUserBlockTask(taskId);

      try {
        await Promise.all(promises) //this will make us know if we have atleast 1 error (rejected promise above)

      } catch (err) {
        //now we want to notify user.
        console.warn('ERROR UPDATING ONE OR MORE PHOTOS')
      }

    }
  }

  async callHttpCloudFunction(name, params) {
    const url = this.cloudFunctionHost + '/' + name;
    const res = await axios.get(url, {
      params: params,
      headers: { Authorization: 'Bearer ' + await this.getToken() }
    })


    return res.data;

  }


  async getSignedUrl(filePath) {
    const url = await this.callHttpCloudFunction("getSignedUrl", {
      path: filePath
    })



    return url;
  }


  async getLabelImageUrl(labelKey) {
    return this.getSignedUrl(`projects/${this.project.id}/labels/${labelKey}.jpg`)
  }

  async isViewAllowedByViewsCredit() {

    let ownerId = null;
    for (let uid in this.project.roles) {
      if (this.project.roles[uid] === "owner") {
        ownerId = uid;
      }

    }
    if (ownerId === null) {
      throw new Error("no owner id")
    }

    if (ownerId == this.user.id) {
      return true;
    }


    const { allow } = await this.callHttpCloudFunction('isAllowedByViewsCreditsQuota', {
      ownerId: ownerId
    })

    return !!allow;

  }

  async addViewCount() {
    await this.callHttpCloudFunction('addToViewCount', {
      projectId: this.project.id
    })


    this.getViewCount();

  }

  async getViewCount() {
    const viewsCount = (await firebase.database().ref("viewsCount/byProject/" + this.project.id).once("value")).val();
    this.projectViews = viewsCount;
  }

  async removeViewRelatedPhotosAndLabels(viewId) {

    const deletePhotosUpdate = {}
    for (let pid in this.photos) {
      if (this.photos[pid].generatedForViewId == viewId) {
        deletePhotosUpdate[pid] = null;
      }
    }

    const deleteLabelsUpdate = {}
    for (let lid in this.labels) {
      if (this.labels[lid].generatedForViewId == viewId) {
        deleteLabelsUpdate[lid] = null;
      }
    }



    await Promise.all([
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/photos").update(deletePhotosUpdate),
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/labels").update(deleteLabelsUpdate)
    ])



  }

  updateCurrentUploadsSize() {
    let currentTotalUploading = 0;
    for (let uploadId in this.currentUploads) {
      currentTotalUploading += this.currentUploads[uploadId].size / 1024 / 1024;
    }

    this.currentUploadsSize = currentTotalUploading;
    this.appActionsService.projectSizeParamsUpdated.next()
  }

  canUploadFile(file) {
    this.updateCurrentUploadsSize();

    if (file.size) {
      return (this.projectSize + (file.size / 1024 / 1024) + this.currentUploadsSize + this.assetsSize.models + this.assetsSize.materials <= this.maxProjectSize)
    } else {
      console.warn('Cant determinate file size')
      return false;
    }
  }

  canAddAsset(assetSize, type, lib_id) {
    let assetExist = false;
    if (type == 'model') {
      for (let key in this.models) {
        if (this.models[key].lib_id === lib_id) {
          assetExist = true;
          break;
        }
      }

    }

    if (type == 'material') {

      for (let configKey in this.materialsConfigs) {
        for (let mat_key in this.materialsConfigs[configKey].materials) {
          const mat = this.materialsConfigs[configKey].materials[mat_key];
          if (mat.lib_material === lib_id) {
            assetExist = true;
          }
        }
      }

    }

    if (assetExist) {
      return true
    } else {
      if (!assetSize) {
        assetSize = 0;
        console.warn("Can't determind asset size, adding anyway")
      }
      return (assetSize + this.projectSize + this.currentUploadsSize + this.assetsSize.models + this.assetsSize.materials <= this.maxProjectSize)
    }


  }


  async cancelAllExistingUploadTasks() {
    for (let uploadId in this.currentUploads) {

      const { uploadTask } = this.currentUploads[uploadId];
      uploadTask.cancel();
    }


  }

  async uploadFileToStorage(ref: firebase.storage.Reference, file: Blob | File, onProgress?: (snapshot: firebase.storage.UploadTaskSnapshot) => any) {
    return new Promise((resolve, reject) => {
      const uploadId = UUID.UUID();
      try {
        if (!file.size) {
          reject('Cant determinate file size')
          return;
        }

        const newFileSize = file.size

        if (!this.canUploadFile(file)) {
          reject('not enough storage place left')
          this.appActionsService.notify(
            this.translate.instant('storage.snackbackFileUploadError'),
            "warn",
            10
          )

          return;
        }


        const uploadTask = ref.put(file);
        this.currentUploads[uploadId] = { size: newFileSize, uploadTask };
        this.updateCurrentUploadsSize();

        uploadTask
          .on("state_changed",
            onProgress,
            err => {
              delete this.currentUploads[uploadId];
              this.updateCurrentUploadsSize();
              reject(err)

            },
            () => {
              delete this.currentUploads[uploadId];
              this.updateCurrentUploadsSize();
              resolve('done')
            })
      } catch (err) {
        reject(err)
      }
    })


  }


  initProjectSizeParams(backToCurrentProject = false) {
    this.projectSize = 0;
    this.currentUploads = {};
    this.currentUploadsSize = 0;

    if (backToCurrentProject && this.project) {

      this.projectSize = this.project.size / 1024 / 1024;

    }
    this.appActionsService.projectSizeParamsUpdated.next()
  }

  async calcProjectSize(projectId) {
    await this.callHttpCloudFunction('calcProjectStorage', { projectId: projectId })

  }

  getProgressCalcs(loaded, total, progressSteps): { eta: number, speed: number, progress: number } {
    const step = {
      t: new Date().getTime(),
      p: loaded / total
    }


    progressSteps.push(step)

    let dt = step.t - progressSteps[0].t;
    let dp = step.p - progressSteps[0].p

    const N = 20

    if (progressSteps.length > N) {
      dt = step.t - progressSteps[progressSteps.length - N].t;
      dp = step.p - progressSteps[progressSteps.length - N].p;
    }



    const speed = dp / dt;

    const eta = (1 - step.p) / speed / 1000;
    return { eta, speed, progress: step.p }
  }

  async downloadFile(url, onProgress: (progress, eta, total?) => void): Promise<Blob> {

    try {

      let progressSteps = [];


      const res = await axios.get(url, {
        onDownloadProgress: (event) => {

          const { progress, eta } = this.getProgressCalcs(event.loaded, event.total, progressSteps)

          onProgress(progress, eta, event.total)

        },
        responseType: 'blob'
      })

      return await res.data;
    } catch (error) {
      console.error(error)
      throw (error)
    }
  }

  async updateTimezone() {
    const myDate = new Date(this.appActionsService.directionalLightOptions.date);
    const timeStamp = (myDate.getTime() / 1000).toFixed(0);
    var apiKey = 'AIzaSyDwycyzhWcOCD_qjDI_95EQDcXUj6cBj5A'
    var url = 'https://maps.googleapis.com/maps/api/timezone/json?location=' + Number(this.siteData.latitude) + ',' + Number(this.siteData.longitude) + '&timestamp=' + timeStamp + '&key=' + apiKey
    var data = await (await fetch(url)).json();

    let timeInHours = Math.floor(Number(this.siteData.longitude) / 15); //this is not correct, but for bad response cases.

    if (data.rawOffset != null) {
      timeInHours = (data.rawOffset + data.dstOffset) / 3600
    }

    const h = Math.floor(timeInHours);
    const m = Math.floor((timeInHours % 1) * 60);

    const HH = (Math.sign(h) >= 0 ? '+' : '-') + (Math.abs(h) > 9 ? "" + Math.abs(h) : "0" + Math.abs(h));
    const MM = m > 9 ? "" + m : "0" + m;

    this.appActionsService.currentTimezone = HH + ':' + MM;


    this.appActionsService.directionalLightOptionsChanged.next();


  }
  async fetchMaterialsLib() {

    const materialsLibrary = (await firebase.database().ref("materials").once("value")).val();
    //fetch preview thumbnails:
    for (let key in materialsLibrary) {
      this.materialsLibrary[key] = materialsLibrary[key];
      const m = this.materialsLibrary[key];
      this.getSignedUrl(`materials/${m.name}/${m.name}-preview.jpg`).then(url => {
        m.preview = url;
      }).catch(err => {
        console.warn('error fetching preview img for material ', m.name, key)
      })
    }



  }

  async updateModelThumb(id, imgData) {
    if (this.user.level == 'admin') {
      await firebase.database().ref("models").child(id).update({
        thumb: imgData
      })
    }

  }

  async fetchModelsLib() {
    this.modelsLibrary = (await firebase.database().ref("models").once("value")).val();
  }

  async downloadModel(modelData) {

    const url = await this.getSignedUrl('models/' + modelData.category + '/' + modelData.filename);

    const zip = await this.downloadFile(url, (progress) => {
      //onProgress
      this.modelsLibrary[modelData.id].progress = progress;
    })





    // @ts-ignore
    const asArrayBuffer = await zip.arrayBuffer()


    const scene = (await this.handleZIP(asArrayBuffer)) as THREE.Object3D;
    // scene.rotateX(Math.PI / 2);
    scene.traverse(o => {
      o.castShadow = true;
      o.receiveShadow = true;
      o.userData.modelLibrary = true;
      o.userData.ambientOcclusion = true;


      let m_list: any[] = [];
      const mat = (o as THREE.Mesh).material
      if (mat) {
        if (Array.isArray(mat)) {
          m_list = mat;
        } else {
          m_list = [mat]
        }
      }

      if (modelData.category === 'plants_outdoor' || modelData.category === 'plants_indoor') { //billboard shadows
        m_list.forEach(m => {
          if (m.map) {
            m.side = THREE.DoubleSide;
            if (m.alphaTest == 0) {
              m.alphaTest = .5;
            }
            m.transparent = false;
            m.depthWrite = true;
          }
        })
      }



      m_list.forEach(m => {
        if (!m.metalnessMap) {
          // m.metalness = 0;
        }
      })

    })

    scene.scale.multiplyScalar(modelData.scale)
    const box = new THREE.Box3();
    box.setFromObject(scene);
    // scene.position.z -= box.min.z;

    scene.position.add(new THREE.Vector3(modelData.offset.x, modelData.offset.y, modelData.offset.z))
    scene.setRotationFromEuler(new THREE.Euler(modelData.euler.x * Math.PI, modelData.euler.y * Math.PI, modelData.euler.z * Math.PI, 'XYZ'));


    const parent = new THREE.Object3D();
    parent.userData.root = true;
    parent.userData.modelLibrary = true;
    parent.userData.category = modelData.category

    parent.add(scene);

    this.modelsCache[modelData.id] = parent;
    parent.name = modelData.name;

    this.modelsLibrary[modelData.id].ready = true;

    return parent;


  }

  async getModel(modelId) {
    const modelData = this.modelsLibrary[modelId];

    if (!modelData) {
      return null;
    }

    if (!this.modelsLibrary[modelId].ready) {


      let downloadPromise = this.modelDownloadsPromises[modelId]
      if (!downloadPromise) {
        downloadPromise = this.downloadModel(modelData);
        this.modelDownloadsPromises[modelId] = downloadPromise;

      }


      try {
        await downloadPromise;
      } catch (err) {

        console.warn(`error downloading model ${modelId}`);
        return;
      }



    }



    return this.modelsCache[modelId].clone();


  }




  async handleZIP(contents: any) {


    return new Promise((resolve, reject) => {

      const zip = unzipSync(new Uint8Array(contents));



      for (const path in zip) {


        const file = zip[path];

        const manager = new THREE.LoadingManager();
        manager.setURLModifier(function (url) {

          const file = zip[url];

          if (file) {

            const blob = new Blob([file.buffer], { type: 'application/octet-stream' });
            return URL.createObjectURL(blob);

          }

          return url;

        });

        let extension = path.split('.').pop()
        if (extension) {
          extension.toLowerCase();
        }

        switch (extension) {

          // case 'fbx':

          //   {

          //     const { FBXLoader } = await import('../../examples/jsm/loaders/FBXLoader.js');

          //     const loader = new FBXLoader(manager);
          //     const object = loader.parse(file.buffer);



          //     break;

          //   }

          case 'glb':

            {


              const dracoLoader = new DRACOLoader();
              dracoLoader.setDecoderPath('../examples/js/libs/draco/gltf/');

              const loader = new GLTFLoader();
              loader.setDRACOLoader(dracoLoader);

              loader.parse(file.buffer, '', function (result) {

                const scene = result.scene;

                scene.animations.push(...result.animations);

                resolve(scene)

              });

              break;

            }

          case 'gltf':

            {




              const dracoLoader = new DRACOLoader();
              dracoLoader.setDecoderPath('../examples/js/libs/draco/gltf/');

              const loader = new GLTFLoader(manager);
              loader.setDRACOLoader(dracoLoader);
              loader.parse(strFromU8(file), '', (result: any) => {

                const scene = result.scene;

                scene.animations.push(...result.animations);



                resolve(scene)

              });

              break;

            }

        }

      }
    })

  }


  async addModel(modelData) {

    const uuid = UUID.UUID();
    this.viewConfig.modelsConfig[uuid] = true;
    await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/models").child(uuid).update(modelData);


    this.appActionsService.updateModelsVisFromConfig.next();
    return uuid;

  }

  async updateModel(key, updates) {
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/models")
      .child(key)
      .update(updates);
  }


  async removeModel(key) {
    this.removeAllAssetMaterialChanges(key);
    return firebase
      .database()
      .ref("projects/" + this.project.id + "/data/models")
      .child(key)
      .remove();
  }

  async downloadAllExistingModelsInProject() {
    const downloads = [];
    for (let key in this.models) {
      downloads.push(this.getModel(this.models[key].lib_id))
    }

    await Promise.all(downloads)
  }


  async downloadMaterial(key, highRes = true) {
    if (this.appActionsService.isMobile) {
      highRes = false;
    }

    highRes = false; //for now we force this one for better performance. TODO: add option to toggle HIGHRES on material editor and choose some materials to be HIGHRES.

    const maps = [
      "albedo",
      "ao",
      "metalness",
      "normal-ogl",
      "roughness"
    ]

    const name = this.materialsLibrary[key].name;
    const isCustomMaterial = this.materialsLibrary[key].customMaterial;

    this.materialsLibrary[key].maps = {};
    const promises = [];
    maps.forEach(map => {
      // TODO : do it better.
      const ref = isCustomMaterial ? `projects/${this.project.id}/materials/${key}/${map}.jpg` : `materials/${name}/${name}-${map}${highRes ? '' : '-low'}.jpg`
      promises.push(this.getSignedUrl(ref).then(url => {
        this.materialsLibrary[key].maps[map] = url;
      }).catch(rej => { console.warn(`failed to load ${map} map for ${name}`) })
      )




    })

    try {
      await Promise.all(promises)
      const prefetch = [];
      Object.values(this.materialsLibrary[key].maps).forEach(url => {

        if (url) {

          prefetch.push(fetch(String(url)))
        }

      })

      await Promise.all(prefetch)

    } catch (err) {

      ///
    }

    return this.materialsLibrary[key];

  }

  checkAssetsCredit() {
    this.appActionsService.hasAssetCredits = false;
    if (!this.modelsLibrary) {
      return;
    }

    for (let id in this.models) {
      const lib_id = this.models[id].lib_id;
      if (this.modelsLibrary[lib_id]) {
        const credits = this.modelsLibrary[lib_id].credits;
        if (credits) {
          this.assetsCredits[lib_id] = credits;
          this.appActionsService.hasAssetCredits = true;
        }
      }

    }

  }
  updateAssetsSize() {

    const models = {};
    const materials = {};
    this.assetsSize.models = this.assetsSize.materials = 0;

    for (let key in this.models) {
      const model = this.models[key];
      models[model.lib_id] = true;

    }

    for (let lib_id in this.modelsLibrary) {
      if (models[lib_id]) {
        if (!this.modelsLibrary[lib_id].size) {
          console.warn(`no size for model `, this.modelsLibrary[lib_id].size)
        }
        this.assetsSize.models += this.modelsLibrary[lib_id].size || 0;
      }
    }


    for (let configKey in this.materialsConfigs) {
      for (let mat_key in this.materialsConfigs[configKey].materials) {
        const mat = this.materialsConfigs[configKey].materials[mat_key];
        if (mat.lib_material) {
          materials[mat.lib_material] = true;
        }

      }
    }

    for (let lib_id in this.materialsLibrary) {
      if (materials[lib_id]) {
        if (!this.materialsLibrary[lib_id].size) {
          console.warn(`no size for material ${lib_id}`, this.materialsLibrary[lib_id])
        }
        this.assetsSize.materials += this.materialsLibrary[lib_id].size || 0;
      }
    }


  }

  async predownloadAllUsedMaterials() {

    const materialsConfigs = (await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/materialsConfigs").once("value")).val()

    const materials = {};
    for (let configKey in materialsConfigs) {

      for (let mat_key in materialsConfigs[configKey].materials) {
        const mat = materialsConfigs[configKey].materials[mat_key];
        if (mat.lib_material) {
          materials[mat.lib_material] = true;
        }

      }
    }

    const promises = [];

    for (let lib_id in materials) {
      promises.push(this.downloadMaterial(lib_id).catch(err => {
        console.warn('error predownloading material ' + lib_id, err)
      }))
    }


    await Promise.all(promises);


  }

  updateOverwrite(guid, updates) {
    updates.guid = guid;

    let existingKey = null;
    for (let key in this.overwriteKeyToGuid) {
      if (this.overwriteKeyToGuid[key] == guid) {
        existingKey = key;
        break;
      }

    }
    if (existingKey) {
      return firebase.database().ref('projects').child(this.project.id).child('data/overwrites').child(existingKey).update(updates)
    } else {
      return firebase.database().ref('projects').child(this.project.id).child('data/overwrites').push(updates);
    }



  }

  async removeOverwrite(guid) {
    for (let key in this.overwriteKeyToGuid) {
      if (this.overwriteKeyToGuid[key] == guid) {
        return firebase.database().ref('projects').child(this.project.id).child('data/overwrites').child(key).remove();
      }

    }

  }


  deleteObject(guid) {
    return firebase.database().ref('projects').child(this.project.id).child('data/deletedObjects').push(guid);
  }

  resetDeletedObjects() {
    return firebase.database().ref('projects').child(this.project.id).child('data/deletedObjects').remove();
  }


  async createCustomMaterialInProject(newMaterial, projectId) {
    const uuid = UUID.UUID();
    const uploads: Promise<any>[] = [];

    for (let mapName in newMaterial.maps) {

      const file = newMaterial.maps[mapName]
      if (file) {
        const ref = firebase
          .storage()
          .ref("projects")
          .child(projectId)
          .child("materials")
          .child(uuid)
          .child(mapName + '.jpg')

        uploads.push(this.uploadFileToStorage(ref, file))

        if (mapName === 'albedo') {
          uploads.push(this.uploadFileToStorage(firebase
            .storage()
            .ref("projects")
            .child(projectId)
            .child("materials")
            .child(uuid)
            .child("preview.jpg"), file))
        }
      }

    }


    await Promise.all(uploads)


    //create file in project DB


    await firebase.database().ref('projects').child(projectId).child('data/materials').child(uuid).set({
      name: newMaterial.name,
      opacity: 1,
      roughness: 1,
      metalness: newMaterial.maps.metalness ? 1 : 0,
      enableEnvMap: newMaterial.enableEnvMap,
      scale: 1,
      preview: newMaterial.preview,
      customMaterial: true,
      size: newMaterial.size,
      mapsRatios: newMaterial.mapsRatios
    });


  }

  async removeCustomMaterialInProject(materiallKey) {
    await firebase.database().ref('projects').child(this.project.id).child('data/materials').child(materiallKey).remove();

    //now delete:

    const folderRef = firebase
      .storage()
      .ref("projects")
      .child(this.project.id)
      .child("materials")
      .child(materiallKey)



    const listResults = await folderRef.listAll();


    const promises = listResults.items.map((item) => {
      return item.delete();
    });

    Promise.all(promises);


  }

  async uploadVideoOfProject(file, name) {
    await firebase.storage().ref('projects').child(this.project.id).child('videos').child(name).put(file);
  }

  async updateLightWeightPhotos(photos, height, width) {
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData/photos').update(photos)
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData/details').update({
      width, height
    })
  }

  async updateLabelPositionsOfPrerendered(labelsConfig) {
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData/labels').update(labelsConfig)
  }

  async updatePhotosphereLabelsPositionsOfPrerendered(photosphereLabels) {
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData/photospheresLabels').update(photosphereLabels)
  }


  async uploadEquiPhotoOfProject(file, name) {
    await firebase.storage().ref('projects').child(this.project.id).child('equis').child(name).put(file);
  }

  async updateLastSuccededPrerendered() {
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData').update({
      lastSucceded: new Date().toUTCString()
    })
    this.lightweightData = (await firebase
      .database()
      .ref("projects/" + this.project.id + "/data/lightweightData").once("value")).val()
  }

  async resetPrerenderedData() {
    await firebase.database().ref('projects').child(this.project.id).child('data/lightweightData').set(null)
    //TODO: Create delete cloud functions for storage.
  }

  async getProjectVideos(onProgress?: (progress) => void, highPriority?) {
    const videos = {};
    const promises = [];
    let totals = {};
    let downloaded = 0;

    const calcTotal = () => {
      let total = 0
      let downloaded = 0;
      for (let key in totals) {
        total += totals[key].total;
        downloaded += totals[key].downloaded;
      }

      onProgress(downloaded / total)

    }

    const list = await this.callHttpCloudFunction("getLightVersionUrls", {
      projectId: this.project.id
    })

    list.videos.forEach(({ key, url }) => {



      if (highPriority[key.slice(0, -5)]) {
        const promise = new Promise<{ key: string, url: string, file: Blob }>(async resolve => {
          try {


            const file = await this.downloadFile(url, (progress, eta, total) => {
              totals[key] = {
                total,
                downloaded: progress * total
              }


              calcTotal();


            })
            // const vid = await fetch(url);




            resolve({ key, url, file })
          } catch (err) {
            console.warn('error fetching vid ' + key)
          }

        })


        promises.push(promise)
      }




    })

    const downalods = await Promise.all(promises); //first download all highpriority videos
    downalods.forEach(download => {
      videos[download.key] = window.URL.createObjectURL(download.file);
    })

    list.videos.forEach(({ key, url }) => { //now we fetch on background
      if (!highPriority[key.slice(0, -5)]) {
        const promise = new Promise<{ key: string, url: string, file: Blob }>(async resolve => {
          try {


            const file = await this.downloadFile(url, (progress, eta, total) => {

            })





            resolve({ key, url, file })
          } catch (err) {
            console.warn('error fetching vid ' + key)
          }

        })

        promise.then(download => {
          console.log('non priority added to videos')
          videos[download.key] = window.URL.createObjectURL(download.file);
        })
      }
    })






    return videos;



  }



  async getProjectEquis() {
    const equis = {};
    const promises = [];
    const list = await this.callHttpCloudFunction("getLightVersionUrls", {
      projectId: this.project.id
    })
    list.equis.forEach(({ key, url }) => {

      promises.push(new Promise(async resolve => {
        try {
          const file = await this.downloadFile(url, (progress, eta) => {

          })
          // const vid = await fetch(url);




          resolve({ key, url, file })
        } catch (err) {
          console.warn('error fetching vid ' + key)
        }

      }))

    })

    const downalods = await Promise.all(promises);
    downalods.forEach(download => {
      equis[download.key] = window.URL.createObjectURL(download.file);;
    })


    return equis;



  }

  async downloadProjectDBJSONForMigrate(pid) {

    const dict = {
      "1jteNq3ftthvnFrJ9aVqLmTi5VT2": "j6jkVv5RALh0RvuBqa2R8p1442w2",
      "xDhgHpXZt1VLMAoFllCEBAHLjjM2": "KQyguLmeVJX6LDFxa7MZ8PgZrlq1"
    }

    if (dict[this.user.id]) {
      await this.claimProjectOwner(pid);
      const json = (await firebase.database().ref('projects').child(pid).once("value")).val();
      const string = JSON.stringify(json);
      const replaced = string.replace(this.user.id, dict[this.user.id])
      var blob = new Blob([replaced], { type: "application/json" });
      const file = URL.createObjectURL(blob);

      this.appActionsService.downloadFile(file, pid + '.json')
    }



  }

  async downloadListMigrationJSONS() {
    const pQueryParameters =
      [
        "-O-6zeSdDrZoLC9guuXe",
        "-OCy-DtgN2-7qYrOmVzT",
        "-NpGnOyeTZWAKZWnJ4l1",
        "-Nw6YKnT-eYyUqG944Df",
        "-OAfjHbXHGT778BmJv1k",
        "-OD3PBjsbqj_xCWxpiJr",
        "-OB7ZmylTyM9lX1LRYV4",
        "-O6GmPM7FplXI3nx1U4R",
        "-O6FN3l71tdbeJ086Vdz",
        "-O6CAzJK1uwhI-mi2a48",
        "-O5WhjHK9FGjnxa4ip2y",
        "-O5YED1UylXB8g2E5Iut",
        "-O4xjVXpyroPdWWXSFJ5",
        "-NyZl78_B9zVk97ysi3l",
        "-NyPmHJTEQbX-sgfdd3V",
        "-NxhGjMvVX6uFBNRg09u",
        "-NxWsSufaiOrgDL0urFr",
        "-Nwz3fcfBc4MKXQGHxcB",
        "-Nv5vjekzxYV7jPpfk9Z",
        "-OCtzfI8u7XRYyNhiq-C",
        "-OC2iDvO9VWBlVSphJlo",
        "-O9DcaX-33T6Y6T0eOxe",
        "-O860OqFF1KS00JXn1fP",
        "-O6gSvNR7H0PALL97lyJ",
        "-O6Bz4Z1V359fKmdnD9M",
        "-NzjdUjzhtKbjv-Yy0OF",
        "-NvTEFzOrPb9VaQia9VQ",
        "-NuXyLqBuumVHZ1bieqN",
        "-O4jw8PbLGszkBVoPNrC",
        "-Ng_JqAUyLLUtvwRjrXJ",
        "-NuwejoOzSwmEI8iaBcd",
        "-NQA9DYLsxTafpAxfTwf",
        "-MxmsjTPmFuFesM58LmZ",
        "-NwilccWah2RCMjR2j3d"

      ];


    for (let pid of pQueryParameters) {
      await this.downloadProjectDBJSONForMigrate(pid);

    }

  }



  removeAllAssetMaterialChanges(modelId) {
    if (this.viewConfig.MaterialsMode.openedConfigKey) {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/materialsConfigs")
        .child(this.viewConfig.MaterialsMode.openedConfigKey)
        .child('assetsMaterialsChanges')
        .child(modelId)
        .remove()
    }
  }
  addAssetMaterialChange(modelId, changeData) {


    if (this.viewConfig.MaterialsMode.openedConfigKey) {
      firebase
        .database()
        .ref("projects/" + this.project.id + "/data/materialsConfigs")
        .child(this.viewConfig.MaterialsMode.openedConfigKey)
        .child('assetsMaterialsChanges')
        .child(modelId)
        .push(changeData)

    }

  }
}
