import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppActionsService } from '../../services/app-actions.service';
import { DataService } from "../../services/data.service";
import { ToolboxEvent } from '../../../models/toolbox-event';
import { Subscription } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-measure-mode-tool',
  templateUrl: './measure-mode-tool.component.html',
  styleUrls: ['./measure-mode-tool.component.scss']
})
export class MeasureModeToolComponent implements OnInit, OnDestroy {

  @ViewChild('getFocusLeave', { static: true }) getFocusLeaveElement: ElementRef;


  subscriptions: Subscription[] = [];

  createCollectionDimensioningName = 'newName';
  createCollectionDimensioningNCssColor = '#000000';
  createCollectionDimensioningNCssFontColor = '#000000';
  dimensioningCollections = null

  measures = null;
  selectedGroupId = '0defaultkey';
  toolOpen = false;


  constructor(
    private appActionsService: AppActionsService,
    private translate: TranslateService,
    public dataService: DataService) { }



  @HostListener("window:keyup", ["$event"])
  onKeyUp(event) {
    if (!this.toolOpen) {
      return
    }

    if (event.key == "Control") {
      this.createModeToggle();
    }

    if (event.key == "Shift") {
      this.editModelToggle();
    }

  }

  createModeToggle() {
    this.appActionsService.measureModeCreateMode = !this.appActionsService.measureModeCreateMode;

    if (this.appActionsService.measureModeCreateMode) {
      this.dataService.collectionDimensioning.editModeStatus = false

    } else {

    }
  }

  editModelToggle() {
    if(!this.toolOpen) {
      return;
    }
    this.dataService.collectionDimensioning.editModeStatus = !this.dataService.collectionDimensioning.editModeStatus;
    if (this.dataService.collectionDimensioning.editModeStatus) {

      this.appActionsService.measureModeCreateMode = false;
    }
  }



  ngOnInit() {

    this.measures = this.dataService.measures;

    this.setMeasureVisFromViewconfig();

    this.setDimensionings();





    this.subscriptions.push(
      this.appActionsService.currentMeasure.subscribe(measure => {
        if (measure && this.appActionsService.measureModeCreateMode && this.appActionsService.editorMode) {
          this.measures[this.selectedGroupId].visible = true;
          this.addMeasureToGroup(this.selectedGroupId, measure)
      
 
          this.createModeToggle();
    
        } else {

    
          //do what on null?

        }
      })
    )


    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(async () => {

        this.setMeasureVisFromViewconfig();
        this.updateDimensioningsVisibilityAndColor();
      })
    )

    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {

      if (event.tool == 'measureMode') {

        if (event.type == 'open') {
          this.initTool();
        }

        if (event.type == 'close') {
          this.closeTool();
        }

        if (event.type == 'updates') {
          if (event.emitter != this.constructor.name) {

            // ?

          }
        }
      }
      else {

        this.appActionsService.measureModeCreateMode = false
      }
    }))

    this.dataService.collectionDimensioning.addEventListener("change",this.dimensioningChangeHandler.bind(this))

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
    this.dataService.collectionDimensioning.removeEventListener("change",this.dimensioningChangeHandler)
  }

  dimensioningChangeHandler(e) {

    const measure = this.getMeasure(e.key);

    if (measure) {
      measure.directionNormal = e.directionNormal;
      measure.extensionLineLength = e.extensionLineLength
      measure.distanceTextSize = e.distanceTextSize
      this.saveInDB();
    }
  }

  initTool() {

    this.toolOpen = true;

  }

  closeTool() {
    this.toolOpen = false;
    this.appActionsService.measureModeCreateMode = false
    this.dataService.collectionDimensioning.editModeStatus = false
  }



  magentButtonToggled(e) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('measureMode', 'updates', this.constructor.name, { magnetOn: e.checked }))
  }







  addMeasureGroup() {
    const newId = UUID.UUID()
    this.measures[newId] = {
      name: this.translate.instant('measure.addGroup'),
      visible: true,
      color: '#000000',
      fontColor: '#000000',
      children: {}
    }
    this.selectMeasureGroup(newId);
    this.saveInDB();
  }

  addMeasureToGroup(groupId, measureData) {


    const group = this.measures[groupId];
    if (group) {
      if (!group.children) {
        group.children = {};
      }
      group.children[UUID.UUID()] = {
        pointA: JSON.parse(JSON.stringify(measureData.pointA)),
        pointB: JSON.parse(JSON.stringify(measureData.pointB)),
        color: group.color,
        fontColor: group.fontColor,
        name: measureData.distance.toFixed(2) + 'm',
        visible: true,
        distanceTextSize: measureData.distanceToCamera / 45,
        directionNormal: measureData.directionNormal,
        extensionLineLength: measureData.extensionLineLength

      }
    }
    this.setDimensionings()
    this.saveInDB();
     if(!this.measures[groupId].visible){this.measures[groupId].visible}
  }



  deleteMeasure(groupId, measureId) {
    const group = this.measures[groupId]
    if (group) {
      delete group.children[measureId];
    }
    this.setDimensionings()
    this.saveInDB();
  }

  getMeasure(key) {
    for (let groupId in this.measures) {
      for (let measureId in this.measures[groupId].children) {
        if (measureId == key) {
          return this.measures[groupId].children[measureId]
        }
      }
    }
  }

  deleteGroup(groupId) {
    if (groupId !== '0defaultkey') {
      delete this.measures[groupId]
      this.setDimensionings()
      this.saveInDB();
    }

    this.selectedGroupId = '0defaultkey';

  }

  selectMeasureGroup(groupId) {
    this.selectedGroupId = groupId;
  }


  toggleMeasureVisibility(groupId, measureId, event) {
    // event.stopPropagation();
    const group = this.measures[groupId]
    if (group) {
      group.children[measureId].visible = !group.children[measureId].visible;
    }
    this.updateDimensioningsVisibilityAndColor();
  }

  toggleMeasureGroupVisibility(groupId, event) {
    // event.stopPropagation();
    this.measures[groupId].visible = !this.measures[groupId].visible;
    this.updateDimensioningsVisibilityAndColor();
  }

  groupColorChanged(groupId, event) {
    const group = this.measures[groupId];
    if (group) {
      for (let key in group.children) {
        group.children[key].color = event;
      }
    }
    this.updateDimensioningsVisibilityAndColor();
  }

  groupFontColorChanged(groupId, event) {
    const group = this.measures[groupId];
    if (group) {
      for (let key in group.children) {
        group.children[key].fontColor = event;
      }
    }
    this.updateDimensioningsVisibilityAndColor();
  }


  measureNameUpdated(measure) {
    this.setDimensionings();
    this.dataService.saveMeasuresInDB()
  }

  resetMeasureName(measure) {
    const d = Math.sqrt(Math.pow(measure.pointA.x - measure.pointB.x, 2) + Math.pow(measure.pointA.y - measure.pointB.y, 2) + Math.pow(measure.pointA.z - measure.pointB.z, 2));
    measure.name = d.toFixed(2) + 'm'
    this.measureNameUpdated(measure);
  }

  generateDimensioningData() {
    const clonedMeasures = JSON.parse(JSON.stringify(this.measures));
    let flatMeasures = {};

    for (let groupId in clonedMeasures) {
      for (let measureId in clonedMeasures[groupId].children) {
        flatMeasures[measureId] = clonedMeasures[groupId].children[measureId]
        if (clonedMeasures[groupId].visible === false) {    //make sure to hide if parent is hidden.
          flatMeasures[measureId].visible = false
        }

      }
    }
    return flatMeasures;
  }

  async setDimensionings() {

    await this.dataService.collectionDimensioning.newSetDimensionings(this.generateDimensioningData())
    await this.updateDimensioningsVisibilityAndColor();
  }

  updateDimensioningsVisibilityAndColor() {
    this.dataService.collectionDimensioning.newUpdateVisibilityAndColor(this.generateDimensioningData())
    this.updateMeasureVisInViewConfig();
  }

  setMeasureVisFromViewconfig() {
    const measuresConfig = this.dataService.viewConfig.measuresConfig;

    for (let groupId in this.measures) {
      this.measures[groupId].visible = !!measuresConfig[groupId]
      // if(visConfig[groupId]) { // we set it to visible only if config define it to be visible

      // }

      for (let measureId in this.measures[groupId].children) {
        this.measures[groupId].children[measureId].visible = !!measuresConfig[measureId]
      }
    }
  }

  updateMeasureVisInViewConfig() {


    for (let groupId in this.measures) {
      this.dataService.viewConfig.measuresConfig[groupId] = this.measures[groupId].visible
      for (let measureId in this.measures[groupId].children) {
        this.dataService.viewConfig.measuresConfig[measureId] = this.measures[groupId].children[measureId].visible
      }
    }
  }



  saveInDB() {
    this.dataService.saveMeasuresInDB()
  }
}
