import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { ModelImportService } from '../model-import.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IfcAnalayzerComponent } from '../ifc-analayzer/ifc-analayzer.component';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  createProjectForm: FormGroup;
  @ViewChild('uploadFile', { static: false }) uploadFile: ElementRef;
  @ViewChild('newProjectName', { static: false }) newProjectName: ElementRef;
  @ViewChild('ifcAnalyzer', { static: false }) ifcAnalyzer: IfcAnalayzerComponent;
  ifcschema = 'ifc2x3tc1';
  fileName = null;
  createNewProjectMode = false;
  createProjectError = null;
  subscriptions: Subscription[] = [];
  fileType = 'ifc';
  creatingColladaProject = false;
  file = null;
  showOldProjects = false;
  maxFileSize = 100 * 1024 * 1024;
  filterString = '';
  @Output() closed = new EventEmitter();




  ownedProjects = []

  step = 'select-file';


  constructor(private dataService: DataService, private appActionsService: AppActionsService, private modelImporter: ModelImportService, private router: Router) { }

  ngOnInit() {
    this.ownedProjects = this.dataService.user.projects.filter(p => this.dataService.user.ownedProjects ? this.dataService.user.ownedProjects[p.id] : false)
    this.initCreateProjectForm()
    this.resetErrorMessage();
    this.appActionsService.removeTask("ifcUpload") //incase was not removed.
    this.appActionsService.removeTask("modelUpload") 
  }


  initCreateProjectForm() {
    this.createProjectForm = new FormGroup({
      'projectName': new FormControl(null, [Validators.required, this.forbiddenProjectNames.bind(this)]),
      'type': new FormControl(this.fileType, Validators.required),
      "file": new FormControl(null, Validators.required)
    })

    this.fileName = null;
  }


  forbiddenProjectNames(control: FormControl): { [s: string]: boolean } {

    for (let project of this.ownedProjects) {

      if (project.name == control.value) {
        return { 'nameAlreadyExist': true } // if forbined
      }


    }

    return null
  }


  onFileTypeChanged() {

    this.uploadFile.nativeElement.value = null;

    this.onFileSelected();
  }
  onFileSelected() {

    this.createProjectError = null;
    this.file = this.uploadFile.nativeElement.files[0];

    if (this.uploadFile.nativeElement.files.length > 0) {
      this.fileName = this.file.name;
      const realTypeCheck = this.fileName.split('.')[1];


      if (!(this.fileType == 'ifc' ? realTypeCheck == 'ifc' : realTypeCheck == 'zip')) {
        this.createProjectError = { message: 'file is not supported' }
        this.createProjectForm.controls.file.setValue(null);


        this.fileName = null;
        this.uploadFile.nativeElement.value = null;

      }

    } else {
      this.fileName = null;
    }





  }


  resetErrorMessage() {
    this.appActionsService.errorMessage = null;
  }






  async createIFCProject() {

    const file = this.ifcAnalyzer.anonymizeAndFilterFile();

 

    try {

      if (!file) {
        throw ('Problem loading file')
      }
  

      this.appActionsService.mainIfcLoading = true;
      // const ifcFile = this.uploadFile.nativeElement.files[0];
      const ifcFile = file;

      let name = this.newProjectName.nativeElement.value;
      const jsonModel = await this.modelImporter.loadIfcModel(ifcFile)

      console.log('CREATe Ifc project start')
      const projectId = await this.dataService.createIfcProject(jsonModel, name, ifcFile);
      this.router.navigate(['/'], { queryParams: { p: projectId } })
      console.log('CREATe Ifc project finished')


      this.dataService.localModel = jsonModel;

      // this.appActionsService.wizardOpen = true;

      //now load localy

      const sub = this.appActionsService.projectsDataChanged.subscribe(() => {

        const project = this.dataService.user.projects.find(p => p.id == projectId)

        this.appActionsService.openProjectWhileUploading.next(project)
        this.appActionsService.openCloseProjectsFolder.next('close');

        this.appActionsService.mainIfcLoading = false;
        sub.unsubscribe();

      })

      this.dataService.reloadUserData();

    } catch (err) {
      console.warn('error creating project', err)
      this.appActionsService.errorMessage = 'Error Creating Project'
    }

  }


  createDAEProject() {

    console.log('collada')
    this.creatingColladaProject = true;
  }


  onColladaImportedClosed() {
    this.creatingColladaProject = false;
    this.dataService.reloadUserData();
    this.close();
  }

  close() {
    this.closed.emit();
  }

}
