import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  subscriptions: Subscription[] = [];
  credits = {}
  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }
  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }
  ngOnInit() {

    
    for(let id in this.dataService.assetsCredits) {
      this.credits[id]  = this.urlify(this.dataService.assetsCredits[id])
    }
   

  }

  close() {
    this.appActionsService.showAssetsCredits = false;
  }


  urlify(text) {
    // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = (c == 'www.') ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    })
  }
}
