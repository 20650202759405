import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModelImportService } from '../model-import.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { AppActionsService } from '../services/app-actions.service';
import { IfcAnalayzerComponent } from '../ifc-analayzer/ifc-analayzer.component';

@Component({
  selector: 'app-project-update',
  templateUrl: './project-update.component.html',
  styleUrls: ['./project-update.component.scss']
})
export class ProjectUpdateComponent implements OnInit {
  fileType = 'ifc';
  maxFileSize = 100 * 1024 * 1024;
  fileName = null;
  file = null;
  @Input('project') project: any;
  step = "select-file";
  @ViewChild('uploadFile', { static: false }) uploadFile: ElementRef;
  @Output() closed = new EventEmitter();
  updatingFileInProcess = null;
  error = null;
  fileError = null;
  @ViewChild('ifcAnalyzer', { static: false }) ifcAnalyzer: IfcAnalayzerComponent;

  constructor(private modelImporter: ModelImportService, private dataService: DataService, private router: Router, private appActionsService:AppActionsService,    private translate: TranslateService) { }
  ngOnInit() {
    this.appActionsService.removeTask("ifcUpload") //incase was not removed.
    this.appActionsService.removeTask("modelUpload") //incase was not removed.
  }


  close() {
    this.closed.emit()
  }

  onFileSelected() {
    this.file = this.uploadFile.nativeElement.files[0];
    this.fileError = null
    if (this.uploadFile.nativeElement.files.length > 0) {
      this.fileName = this.file.name;
      const realTypeCheck = this.fileName.split('.')[1];
      if (!(this.fileType == 'ifc' ? realTypeCheck == 'ifc' : realTypeCheck == 'zip')) {
        this.fileError = { message: this.translate.instant('ifcAnalyzer.notsupported') }
    


        this.fileName = null;
        this.uploadFile.nativeElement.value = null;

      }
    }

    else {
      this.fileName = null;
    }

    

  }

  async updateIFCProject() {



    try {
      this.updatingFileInProcess = { message: 'Converting Project' };
      this.appActionsService.mainIfcLoading = true;
      const ifcFile = this.ifcAnalyzer.anonymizeAndFilterFile();
      const jsonModel = await this.modelImporter.loadIfcModel(ifcFile)
  
      await this.dataService.updateMaterialConfigsInUpdatedProject(this.project.id, jsonModel)
  
      this.dataService.updateIfcProject(this.project.id, jsonModel, ifcFile);
  
      this.updatingFileInProcess = null;
  
  
      this.dataService.localModel = jsonModel;
  
      this.appActionsService.mainIfcLoading = false;
  
  
      //now load localy
      this.router.navigate(['/'], { queryParams: { p: this.project.id } })
      this.appActionsService.openProjectWhileUploading.next(this.project)
      this.appActionsService.openCloseProjectsFolder.next('close');
      
  
      this.dataService.reloadUserData();
    } catch(err) {
      this.appActionsService.mainIfcLoading = false;
      this.error = 'updateFailed';
    }
  

  }




}
