// This is a generated file, please see: gen_functional_types.js
import * as ifc2x4 from "./ifc2x4";
export let FromRawLineData = {};
FromRawLineData[ifc2x4.IFCACTIONREQUEST] = (d) => {
	return IfcActionRequest.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCACTOR] = (d) => {
	return IfcActor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCACTORROLE] = (d) => {
	return IfcActorRole.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCACTUATOR] = (d) => {
	return IfcActuator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCACTUATORTYPE] = (d) => {
	return IfcActuatorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCADDRESS] = (d) => {
	return IfcAddress.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCADVANCEDBREP] = (d) => {
	return IfcAdvancedBrep.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCADVANCEDBREPWITHVOIDS] = (d) => {
	return IfcAdvancedBrepWithVoids.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCADVANCEDFACE] = (d) => {
	return IfcAdvancedFace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTERMINAL] = (d) => {
	return IfcAirTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTERMINALBOX] = (d) => {
	return IfcAirTerminalBox.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTERMINALBOXTYPE] = (d) => {
	return IfcAirTerminalBoxType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTERMINALTYPE] = (d) => {
	return IfcAirTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTOAIRHEATRECOVERY] = (d) => {
	return IfcAirToAirHeatRecovery.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAIRTOAIRHEATRECOVERYTYPE] = (d) => {
	return IfcAirToAirHeatRecoveryType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALARM] = (d) => {
	return IfcAlarm.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALARMTYPE] = (d) => {
	return IfcAlarmType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT] = (d) => {
	return IfcAlignment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DHORIZONTAL] = (d) => {
	return IfcAlignment2DHorizontal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DHORIZONTALSEGMENT] = (d) => {
	return IfcAlignment2DHorizontalSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DSEGMENT] = (d) => {
	return IfcAlignment2DSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DVERSEGCIRCULARARC] = (d) => {
	return IfcAlignment2DVerSegCircularArc.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DVERSEGLINE] = (d) => {
	return IfcAlignment2DVerSegLine.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DVERSEGPARABOLICARC] = (d) => {
	return IfcAlignment2DVerSegParabolicArc.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DVERTICAL] = (d) => {
	return IfcAlignment2DVertical.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENT2DVERTICALSEGMENT] = (d) => {
	return IfcAlignment2DVerticalSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCALIGNMENTCURVE] = (d) => {
	return IfcAlignmentCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCANNOTATION] = (d) => {
	return IfcAnnotation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCANNOTATIONFILLAREA] = (d) => {
	return IfcAnnotationFillArea.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAPPLICATION] = (d) => {
	return IfcApplication.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAPPLIEDVALUE] = (d) => {
	return IfcAppliedValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAPPROVAL] = (d) => {
	return IfcApproval.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAPPROVALRELATIONSHIP] = (d) => {
	return IfcApprovalRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCARBITRARYCLOSEDPROFILEDEF] = (d) => {
	return IfcArbitraryClosedProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCARBITRARYOPENPROFILEDEF] = (d) => {
	return IfcArbitraryOpenProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCARBITRARYPROFILEDEFWITHVOIDS] = (d) => {
	return IfcArbitraryProfileDefWithVoids.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCASSET] = (d) => {
	return IfcAsset.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCASYMMETRICISHAPEPROFILEDEF] = (d) => {
	return IfcAsymmetricIShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAUDIOVISUALAPPLIANCE] = (d) => {
	return IfcAudioVisualAppliance.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAUDIOVISUALAPPLIANCETYPE] = (d) => {
	return IfcAudioVisualApplianceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAXIS1PLACEMENT] = (d) => {
	return IfcAxis1Placement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAXIS2PLACEMENT2D] = (d) => {
	return IfcAxis2Placement2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCAXIS2PLACEMENT3D] = (d) => {
	return IfcAxis2Placement3D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBSPLINECURVE] = (d) => {
	return IfcBSplineCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBSPLINECURVEWITHKNOTS] = (d) => {
	return IfcBSplineCurveWithKnots.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBSPLINESURFACE] = (d) => {
	return IfcBSplineSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBSPLINESURFACEWITHKNOTS] = (d) => {
	return IfcBSplineSurfaceWithKnots.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBEAM] = (d) => {
	return IfcBeam.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBEAMSTANDARDCASE] = (d) => {
	return IfcBeamStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBEAMTYPE] = (d) => {
	return IfcBeamType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBEARING] = (d) => {
	return IfcBearing.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBEARINGTYPE] = (d) => {
	return IfcBearingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBLOBTEXTURE] = (d) => {
	return IfcBlobTexture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBLOCK] = (d) => {
	return IfcBlock.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOILER] = (d) => {
	return IfcBoiler.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOILERTYPE] = (d) => {
	return IfcBoilerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOOLEANCLIPPINGRESULT] = (d) => {
	return IfcBooleanClippingResult.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOOLEANRESULT] = (d) => {
	return IfcBooleanResult.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYCONDITION] = (d) => {
	return IfcBoundaryCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYCURVE] = (d) => {
	return IfcBoundaryCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYEDGECONDITION] = (d) => {
	return IfcBoundaryEdgeCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYFACECONDITION] = (d) => {
	return IfcBoundaryFaceCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYNODECONDITION] = (d) => {
	return IfcBoundaryNodeCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDARYNODECONDITIONWARPING] = (d) => {
	return IfcBoundaryNodeConditionWarping.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDEDCURVE] = (d) => {
	return IfcBoundedCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDEDSURFACE] = (d) => {
	return IfcBoundedSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOUNDINGBOX] = (d) => {
	return IfcBoundingBox.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBOXEDHALFSPACE] = (d) => {
	return IfcBoxedHalfSpace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBRIDGE] = (d) => {
	return IfcBridge.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBRIDGEPART] = (d) => {
	return IfcBridgePart.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDING] = (d) => {
	return IfcBuilding.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENT] = (d) => {
	return IfcBuildingElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENTPART] = (d) => {
	return IfcBuildingElementPart.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENTPARTTYPE] = (d) => {
	return IfcBuildingElementPartType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENTPROXY] = (d) => {
	return IfcBuildingElementProxy.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENTPROXYTYPE] = (d) => {
	return IfcBuildingElementProxyType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGELEMENTTYPE] = (d) => {
	return IfcBuildingElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGSTOREY] = (d) => {
	return IfcBuildingStorey.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBUILDINGSYSTEM] = (d) => {
	return IfcBuildingSystem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBURNER] = (d) => {
	return IfcBurner.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCBURNERTYPE] = (d) => {
	return IfcBurnerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCSHAPEPROFILEDEF] = (d) => {
	return IfcCShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLECARRIERFITTING] = (d) => {
	return IfcCableCarrierFitting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLECARRIERFITTINGTYPE] = (d) => {
	return IfcCableCarrierFittingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLECARRIERSEGMENT] = (d) => {
	return IfcCableCarrierSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLECARRIERSEGMENTTYPE] = (d) => {
	return IfcCableCarrierSegmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLEFITTING] = (d) => {
	return IfcCableFitting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLEFITTINGTYPE] = (d) => {
	return IfcCableFittingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLESEGMENT] = (d) => {
	return IfcCableSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCABLESEGMENTTYPE] = (d) => {
	return IfcCableSegmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCAISSONFOUNDATION] = (d) => {
	return IfcCaissonFoundation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCAISSONFOUNDATIONTYPE] = (d) => {
	return IfcCaissonFoundationType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANPOINT] = (d) => {
	return IfcCartesianPoint.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANPOINTLIST] = (d) => {
	return IfcCartesianPointList.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANPOINTLIST2D] = (d) => {
	return IfcCartesianPointList2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANPOINTLIST3D] = (d) => {
	return IfcCartesianPointList3D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANTRANSFORMATIONOPERATOR] = (d) => {
	return IfcCartesianTransformationOperator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANTRANSFORMATIONOPERATOR2D] = (d) => {
	return IfcCartesianTransformationOperator2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM] = (d) => {
	return IfcCartesianTransformationOperator2DnonUniform.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANTRANSFORMATIONOPERATOR3D] = (d) => {
	return IfcCartesianTransformationOperator3D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM] = (d) => {
	return IfcCartesianTransformationOperator3DnonUniform.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCENTERLINEPROFILEDEF] = (d) => {
	return IfcCenterLineProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCHILLER] = (d) => {
	return IfcChiller.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCHILLERTYPE] = (d) => {
	return IfcChillerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCHIMNEY] = (d) => {
	return IfcChimney.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCHIMNEYTYPE] = (d) => {
	return IfcChimneyType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIRCLE] = (d) => {
	return IfcCircle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIRCLEHOLLOWPROFILEDEF] = (d) => {
	return IfcCircleHollowProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIRCLEPROFILEDEF] = (d) => {
	return IfcCircleProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIRCULARARCSEGMENT2D] = (d) => {
	return IfcCircularArcSegment2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIVILELEMENT] = (d) => {
	return IfcCivilElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCIVILELEMENTTYPE] = (d) => {
	return IfcCivilElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCLASSIFICATION] = (d) => {
	return IfcClassification.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCLASSIFICATIONREFERENCE] = (d) => {
	return IfcClassificationReference.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCLOSEDSHELL] = (d) => {
	return IfcClosedShell.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOIL] = (d) => {
	return IfcCoil.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOILTYPE] = (d) => {
	return IfcCoilType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLOURRGB] = (d) => {
	return IfcColourRgb.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLOURRGBLIST] = (d) => {
	return IfcColourRgbList.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLOURSPECIFICATION] = (d) => {
	return IfcColourSpecification.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLUMN] = (d) => {
	return IfcColumn.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLUMNSTANDARDCASE] = (d) => {
	return IfcColumnStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOLUMNTYPE] = (d) => {
	return IfcColumnType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMMUNICATIONSAPPLIANCE] = (d) => {
	return IfcCommunicationsAppliance.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMMUNICATIONSAPPLIANCETYPE] = (d) => {
	return IfcCommunicationsApplianceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPLEXPROPERTY] = (d) => {
	return IfcComplexProperty.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPLEXPROPERTYTEMPLATE] = (d) => {
	return IfcComplexPropertyTemplate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPOSITECURVE] = (d) => {
	return IfcCompositeCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPOSITECURVEONSURFACE] = (d) => {
	return IfcCompositeCurveOnSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPOSITECURVESEGMENT] = (d) => {
	return IfcCompositeCurveSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPOSITEPROFILEDEF] = (d) => {
	return IfcCompositeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPRESSOR] = (d) => {
	return IfcCompressor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOMPRESSORTYPE] = (d) => {
	return IfcCompressorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONDENSER] = (d) => {
	return IfcCondenser.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONDENSERTYPE] = (d) => {
	return IfcCondenserType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONIC] = (d) => {
	return IfcConic.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTEDFACESET] = (d) => {
	return IfcConnectedFaceSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONCURVEGEOMETRY] = (d) => {
	return IfcConnectionCurveGeometry.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONGEOMETRY] = (d) => {
	return IfcConnectionGeometry.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONPOINTECCENTRICITY] = (d) => {
	return IfcConnectionPointEccentricity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONPOINTGEOMETRY] = (d) => {
	return IfcConnectionPointGeometry.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONSURFACEGEOMETRY] = (d) => {
	return IfcConnectionSurfaceGeometry.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONNECTIONVOLUMEGEOMETRY] = (d) => {
	return IfcConnectionVolumeGeometry.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRAINT] = (d) => {
	return IfcConstraint.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONEQUIPMENTRESOURCE] = (d) => {
	return IfcConstructionEquipmentResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE] = (d) => {
	return IfcConstructionEquipmentResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONMATERIALRESOURCE] = (d) => {
	return IfcConstructionMaterialResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONMATERIALRESOURCETYPE] = (d) => {
	return IfcConstructionMaterialResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONPRODUCTRESOURCE] = (d) => {
	return IfcConstructionProductResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONPRODUCTRESOURCETYPE] = (d) => {
	return IfcConstructionProductResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONRESOURCE] = (d) => {
	return IfcConstructionResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONSTRUCTIONRESOURCETYPE] = (d) => {
	return IfcConstructionResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONTEXT] = (d) => {
	return IfcContext.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONTEXTDEPENDENTUNIT] = (d) => {
	return IfcContextDependentUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONTROL] = (d) => {
	return IfcControl.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONTROLLER] = (d) => {
	return IfcController.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONTROLLERTYPE] = (d) => {
	return IfcControllerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONVERSIONBASEDUNIT] = (d) => {
	return IfcConversionBasedUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCONVERSIONBASEDUNITWITHOFFSET] = (d) => {
	return IfcConversionBasedUnitWithOffset.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOOLEDBEAM] = (d) => {
	return IfcCooledBeam.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOOLEDBEAMTYPE] = (d) => {
	return IfcCooledBeamType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOOLINGTOWER] = (d) => {
	return IfcCoolingTower.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOOLINGTOWERTYPE] = (d) => {
	return IfcCoolingTowerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOORDINATEOPERATION] = (d) => {
	return IfcCoordinateOperation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOORDINATEREFERENCESYSTEM] = (d) => {
	return IfcCoordinateReferenceSystem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOSTITEM] = (d) => {
	return IfcCostItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOSTSCHEDULE] = (d) => {
	return IfcCostSchedule.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOSTVALUE] = (d) => {
	return IfcCostValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOVERING] = (d) => {
	return IfcCovering.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCOVERINGTYPE] = (d) => {
	return IfcCoveringType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCREWRESOURCE] = (d) => {
	return IfcCrewResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCREWRESOURCETYPE] = (d) => {
	return IfcCrewResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCSGPRIMITIVE3D] = (d) => {
	return IfcCsgPrimitive3D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCSGSOLID] = (d) => {
	return IfcCsgSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURRENCYRELATIONSHIP] = (d) => {
	return IfcCurrencyRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURTAINWALL] = (d) => {
	return IfcCurtainWall.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURTAINWALLTYPE] = (d) => {
	return IfcCurtainWallType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVE] = (d) => {
	return IfcCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVEBOUNDEDPLANE] = (d) => {
	return IfcCurveBoundedPlane.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVEBOUNDEDSURFACE] = (d) => {
	return IfcCurveBoundedSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVESEGMENT2D] = (d) => {
	return IfcCurveSegment2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVESTYLE] = (d) => {
	return IfcCurveStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVESTYLEFONT] = (d) => {
	return IfcCurveStyleFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVESTYLEFONTANDSCALING] = (d) => {
	return IfcCurveStyleFontAndScaling.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCURVESTYLEFONTPATTERN] = (d) => {
	return IfcCurveStyleFontPattern.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCCYLINDRICALSURFACE] = (d) => {
	return IfcCylindricalSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDAMPER] = (d) => {
	return IfcDamper.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDAMPERTYPE] = (d) => {
	return IfcDamperType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDEEPFOUNDATION] = (d) => {
	return IfcDeepFoundation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDEEPFOUNDATIONTYPE] = (d) => {
	return IfcDeepFoundationType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDERIVEDPROFILEDEF] = (d) => {
	return IfcDerivedProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDERIVEDUNIT] = (d) => {
	return IfcDerivedUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDERIVEDUNITELEMENT] = (d) => {
	return IfcDerivedUnitElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDIMENSIONALEXPONENTS] = (d) => {
	return IfcDimensionalExponents.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDIRECTION] = (d) => {
	return IfcDirection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISCRETEACCESSORY] = (d) => {
	return IfcDiscreteAccessory.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISCRETEACCESSORYTYPE] = (d) => {
	return IfcDiscreteAccessoryType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTANCEEXPRESSION] = (d) => {
	return IfcDistanceExpression.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONCHAMBERELEMENT] = (d) => {
	return IfcDistributionChamberElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONCHAMBERELEMENTTYPE] = (d) => {
	return IfcDistributionChamberElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONCIRCUIT] = (d) => {
	return IfcDistributionCircuit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONCONTROLELEMENT] = (d) => {
	return IfcDistributionControlElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONCONTROLELEMENTTYPE] = (d) => {
	return IfcDistributionControlElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONELEMENT] = (d) => {
	return IfcDistributionElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONELEMENTTYPE] = (d) => {
	return IfcDistributionElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONFLOWELEMENT] = (d) => {
	return IfcDistributionFlowElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONFLOWELEMENTTYPE] = (d) => {
	return IfcDistributionFlowElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONPORT] = (d) => {
	return IfcDistributionPort.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDISTRIBUTIONSYSTEM] = (d) => {
	return IfcDistributionSystem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOCUMENTINFORMATION] = (d) => {
	return IfcDocumentInformation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOCUMENTINFORMATIONRELATIONSHIP] = (d) => {
	return IfcDocumentInformationRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOCUMENTREFERENCE] = (d) => {
	return IfcDocumentReference.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOOR] = (d) => {
	return IfcDoor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOORLININGPROPERTIES] = (d) => {
	return IfcDoorLiningProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOORPANELPROPERTIES] = (d) => {
	return IfcDoorPanelProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOORSTANDARDCASE] = (d) => {
	return IfcDoorStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOORSTYLE] = (d) => {
	return IfcDoorStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDOORTYPE] = (d) => {
	return IfcDoorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDRAUGHTINGPREDEFINEDCOLOUR] = (d) => {
	return IfcDraughtingPreDefinedColour.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDRAUGHTINGPREDEFINEDCURVEFONT] = (d) => {
	return IfcDraughtingPreDefinedCurveFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTFITTING] = (d) => {
	return IfcDuctFitting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTFITTINGTYPE] = (d) => {
	return IfcDuctFittingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTSEGMENT] = (d) => {
	return IfcDuctSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTSEGMENTTYPE] = (d) => {
	return IfcDuctSegmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTSILENCER] = (d) => {
	return IfcDuctSilencer.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCDUCTSILENCERTYPE] = (d) => {
	return IfcDuctSilencerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEDGE] = (d) => {
	return IfcEdge.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEDGECURVE] = (d) => {
	return IfcEdgeCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEDGELOOP] = (d) => {
	return IfcEdgeLoop.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICAPPLIANCE] = (d) => {
	return IfcElectricAppliance.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICAPPLIANCETYPE] = (d) => {
	return IfcElectricApplianceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICDISTRIBUTIONBOARD] = (d) => {
	return IfcElectricDistributionBoard.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICDISTRIBUTIONBOARDTYPE] = (d) => {
	return IfcElectricDistributionBoardType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICFLOWSTORAGEDEVICE] = (d) => {
	return IfcElectricFlowStorageDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICFLOWSTORAGEDEVICETYPE] = (d) => {
	return IfcElectricFlowStorageDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICGENERATOR] = (d) => {
	return IfcElectricGenerator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICGENERATORTYPE] = (d) => {
	return IfcElectricGeneratorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICMOTOR] = (d) => {
	return IfcElectricMotor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICMOTORTYPE] = (d) => {
	return IfcElectricMotorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICTIMECONTROL] = (d) => {
	return IfcElectricTimeControl.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELECTRICTIMECONTROLTYPE] = (d) => {
	return IfcElectricTimeControlType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENT] = (d) => {
	return IfcElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTASSEMBLY] = (d) => {
	return IfcElementAssembly.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTASSEMBLYTYPE] = (d) => {
	return IfcElementAssemblyType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTCOMPONENT] = (d) => {
	return IfcElementComponent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTCOMPONENTTYPE] = (d) => {
	return IfcElementComponentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTQUANTITY] = (d) => {
	return IfcElementQuantity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTTYPE] = (d) => {
	return IfcElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELEMENTARYSURFACE] = (d) => {
	return IfcElementarySurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELLIPSE] = (d) => {
	return IfcEllipse.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCELLIPSEPROFILEDEF] = (d) => {
	return IfcEllipseProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCENERGYCONVERSIONDEVICE] = (d) => {
	return IfcEnergyConversionDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCENERGYCONVERSIONDEVICETYPE] = (d) => {
	return IfcEnergyConversionDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCENGINE] = (d) => {
	return IfcEngine.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCENGINETYPE] = (d) => {
	return IfcEngineType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVAPORATIVECOOLER] = (d) => {
	return IfcEvaporativeCooler.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVAPORATIVECOOLERTYPE] = (d) => {
	return IfcEvaporativeCoolerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVAPORATOR] = (d) => {
	return IfcEvaporator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVAPORATORTYPE] = (d) => {
	return IfcEvaporatorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVENT] = (d) => {
	return IfcEvent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVENTTIME] = (d) => {
	return IfcEventTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEVENTTYPE] = (d) => {
	return IfcEventType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTENDEDPROPERTIES] = (d) => {
	return IfcExtendedProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALINFORMATION] = (d) => {
	return IfcExternalInformation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALREFERENCE] = (d) => {
	return IfcExternalReference.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALREFERENCERELATIONSHIP] = (d) => {
	return IfcExternalReferenceRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALSPATIALELEMENT] = (d) => {
	return IfcExternalSpatialElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALSPATIALSTRUCTUREELEMENT] = (d) => {
	return IfcExternalSpatialStructureElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALLYDEFINEDHATCHSTYLE] = (d) => {
	return IfcExternallyDefinedHatchStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALLYDEFINEDSURFACESTYLE] = (d) => {
	return IfcExternallyDefinedSurfaceStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTERNALLYDEFINEDTEXTFONT] = (d) => {
	return IfcExternallyDefinedTextFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTRUDEDAREASOLID] = (d) => {
	return IfcExtrudedAreaSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCEXTRUDEDAREASOLIDTAPERED] = (d) => {
	return IfcExtrudedAreaSolidTapered.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACE] = (d) => {
	return IfcFace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACEBASEDSURFACEMODEL] = (d) => {
	return IfcFaceBasedSurfaceModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACEBOUND] = (d) => {
	return IfcFaceBound.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACEOUTERBOUND] = (d) => {
	return IfcFaceOuterBound.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACESURFACE] = (d) => {
	return IfcFaceSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACETEDBREP] = (d) => {
	return IfcFacetedBrep.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACETEDBREPWITHVOIDS] = (d) => {
	return IfcFacetedBrepWithVoids.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACILITY] = (d) => {
	return IfcFacility.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFACILITYPART] = (d) => {
	return IfcFacilityPart.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFAILURECONNECTIONCONDITION] = (d) => {
	return IfcFailureConnectionCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFAN] = (d) => {
	return IfcFan.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFANTYPE] = (d) => {
	return IfcFanType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFASTENER] = (d) => {
	return IfcFastener.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFASTENERTYPE] = (d) => {
	return IfcFastenerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFEATUREELEMENT] = (d) => {
	return IfcFeatureElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFEATUREELEMENTADDITION] = (d) => {
	return IfcFeatureElementAddition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFEATUREELEMENTSUBTRACTION] = (d) => {
	return IfcFeatureElementSubtraction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFILLAREASTYLE] = (d) => {
	return IfcFillAreaStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFILLAREASTYLEHATCHING] = (d) => {
	return IfcFillAreaStyleHatching.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFILLAREASTYLETILES] = (d) => {
	return IfcFillAreaStyleTiles.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFILTER] = (d) => {
	return IfcFilter.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFILTERTYPE] = (d) => {
	return IfcFilterType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFIRESUPPRESSIONTERMINAL] = (d) => {
	return IfcFireSuppressionTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFIRESUPPRESSIONTERMINALTYPE] = (d) => {
	return IfcFireSuppressionTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFIXEDREFERENCESWEPTAREASOLID] = (d) => {
	return IfcFixedReferenceSweptAreaSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWCONTROLLER] = (d) => {
	return IfcFlowController.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWCONTROLLERTYPE] = (d) => {
	return IfcFlowControllerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWFITTING] = (d) => {
	return IfcFlowFitting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWFITTINGTYPE] = (d) => {
	return IfcFlowFittingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWINSTRUMENT] = (d) => {
	return IfcFlowInstrument.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWINSTRUMENTTYPE] = (d) => {
	return IfcFlowInstrumentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWMETER] = (d) => {
	return IfcFlowMeter.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWMETERTYPE] = (d) => {
	return IfcFlowMeterType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWMOVINGDEVICE] = (d) => {
	return IfcFlowMovingDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWMOVINGDEVICETYPE] = (d) => {
	return IfcFlowMovingDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWSEGMENT] = (d) => {
	return IfcFlowSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWSEGMENTTYPE] = (d) => {
	return IfcFlowSegmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWSTORAGEDEVICE] = (d) => {
	return IfcFlowStorageDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWSTORAGEDEVICETYPE] = (d) => {
	return IfcFlowStorageDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWTERMINAL] = (d) => {
	return IfcFlowTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWTERMINALTYPE] = (d) => {
	return IfcFlowTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWTREATMENTDEVICE] = (d) => {
	return IfcFlowTreatmentDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFLOWTREATMENTDEVICETYPE] = (d) => {
	return IfcFlowTreatmentDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFOOTING] = (d) => {
	return IfcFooting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFOOTINGTYPE] = (d) => {
	return IfcFootingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFURNISHINGELEMENT] = (d) => {
	return IfcFurnishingElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFURNISHINGELEMENTTYPE] = (d) => {
	return IfcFurnishingElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFURNITURE] = (d) => {
	return IfcFurniture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCFURNITURETYPE] = (d) => {
	return IfcFurnitureType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOGRAPHICELEMENT] = (d) => {
	return IfcGeographicElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOGRAPHICELEMENTTYPE] = (d) => {
	return IfcGeographicElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOMETRICCURVESET] = (d) => {
	return IfcGeometricCurveSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOMETRICREPRESENTATIONCONTEXT] = (d) => {
	return IfcGeometricRepresentationContext.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOMETRICREPRESENTATIONITEM] = (d) => {
	return IfcGeometricRepresentationItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOMETRICREPRESENTATIONSUBCONTEXT] = (d) => {
	return IfcGeometricRepresentationSubContext.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGEOMETRICSET] = (d) => {
	return IfcGeometricSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGRID] = (d) => {
	return IfcGrid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGRIDAXIS] = (d) => {
	return IfcGridAxis.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGRIDPLACEMENT] = (d) => {
	return IfcGridPlacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCGROUP] = (d) => {
	return IfcGroup.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCHALFSPACESOLID] = (d) => {
	return IfcHalfSpaceSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCHEATEXCHANGER] = (d) => {
	return IfcHeatExchanger.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCHEATEXCHANGERTYPE] = (d) => {
	return IfcHeatExchangerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCHUMIDIFIER] = (d) => {
	return IfcHumidifier.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCHUMIDIFIERTYPE] = (d) => {
	return IfcHumidifierType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCISHAPEPROFILEDEF] = (d) => {
	return IfcIShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCIMAGETEXTURE] = (d) => {
	return IfcImageTexture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDCOLOURMAP] = (d) => {
	return IfcIndexedColourMap.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDPOLYCURVE] = (d) => {
	return IfcIndexedPolyCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDPOLYGONALFACE] = (d) => {
	return IfcIndexedPolygonalFace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDPOLYGONALFACEWITHVOIDS] = (d) => {
	return IfcIndexedPolygonalFaceWithVoids.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDTEXTUREMAP] = (d) => {
	return IfcIndexedTextureMap.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINDEXEDTRIANGLETEXTUREMAP] = (d) => {
	return IfcIndexedTriangleTextureMap.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINTERCEPTOR] = (d) => {
	return IfcInterceptor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINTERCEPTORTYPE] = (d) => {
	return IfcInterceptorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINTERSECTIONCURVE] = (d) => {
	return IfcIntersectionCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCINVENTORY] = (d) => {
	return IfcInventory.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCIRREGULARTIMESERIES] = (d) => {
	return IfcIrregularTimeSeries.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCIRREGULARTIMESERIESVALUE] = (d) => {
	return IfcIrregularTimeSeriesValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCJUNCTIONBOX] = (d) => {
	return IfcJunctionBox.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCJUNCTIONBOXTYPE] = (d) => {
	return IfcJunctionBoxType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLSHAPEPROFILEDEF] = (d) => {
	return IfcLShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLABORRESOURCE] = (d) => {
	return IfcLaborResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLABORRESOURCETYPE] = (d) => {
	return IfcLaborResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLAGTIME] = (d) => {
	return IfcLagTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLAMP] = (d) => {
	return IfcLamp.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLAMPTYPE] = (d) => {
	return IfcLampType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIBRARYINFORMATION] = (d) => {
	return IfcLibraryInformation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIBRARYREFERENCE] = (d) => {
	return IfcLibraryReference.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTDISTRIBUTIONDATA] = (d) => {
	return IfcLightDistributionData.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTFIXTURE] = (d) => {
	return IfcLightFixture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTFIXTURETYPE] = (d) => {
	return IfcLightFixtureType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTINTENSITYDISTRIBUTION] = (d) => {
	return IfcLightIntensityDistribution.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCE] = (d) => {
	return IfcLightSource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCEAMBIENT] = (d) => {
	return IfcLightSourceAmbient.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCEDIRECTIONAL] = (d) => {
	return IfcLightSourceDirectional.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCEGONIOMETRIC] = (d) => {
	return IfcLightSourceGoniometric.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCEPOSITIONAL] = (d) => {
	return IfcLightSourcePositional.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLIGHTSOURCESPOT] = (d) => {
	return IfcLightSourceSpot.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLINE] = (d) => {
	return IfcLine.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLINESEGMENT2D] = (d) => {
	return IfcLineSegment2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLINEARPLACEMENT] = (d) => {
	return IfcLinearPlacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLINEARPOSITIONINGELEMENT] = (d) => {
	return IfcLinearPositioningElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLOCALPLACEMENT] = (d) => {
	return IfcLocalPlacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCLOOP] = (d) => {
	return IfcLoop.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMANIFOLDSOLIDBREP] = (d) => {
	return IfcManifoldSolidBrep.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMAPCONVERSION] = (d) => {
	return IfcMapConversion.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMAPPEDITEM] = (d) => {
	return IfcMappedItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIAL] = (d) => {
	return IfcMaterial.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALCLASSIFICATIONRELATIONSHIP] = (d) => {
	return IfcMaterialClassificationRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALCONSTITUENT] = (d) => {
	return IfcMaterialConstituent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALCONSTITUENTSET] = (d) => {
	return IfcMaterialConstituentSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALDEFINITION] = (d) => {
	return IfcMaterialDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALDEFINITIONREPRESENTATION] = (d) => {
	return IfcMaterialDefinitionRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALLAYER] = (d) => {
	return IfcMaterialLayer.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALLAYERSET] = (d) => {
	return IfcMaterialLayerSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALLAYERSETUSAGE] = (d) => {
	return IfcMaterialLayerSetUsage.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALLAYERWITHOFFSETS] = (d) => {
	return IfcMaterialLayerWithOffsets.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALLIST] = (d) => {
	return IfcMaterialList.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROFILE] = (d) => {
	return IfcMaterialProfile.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROFILESET] = (d) => {
	return IfcMaterialProfileSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROFILESETUSAGE] = (d) => {
	return IfcMaterialProfileSetUsage.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROFILESETUSAGETAPERING] = (d) => {
	return IfcMaterialProfileSetUsageTapering.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROFILEWITHOFFSETS] = (d) => {
	return IfcMaterialProfileWithOffsets.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALPROPERTIES] = (d) => {
	return IfcMaterialProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALRELATIONSHIP] = (d) => {
	return IfcMaterialRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMATERIALUSAGEDEFINITION] = (d) => {
	return IfcMaterialUsageDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEASUREWITHUNIT] = (d) => {
	return IfcMeasureWithUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMECHANICALFASTENER] = (d) => {
	return IfcMechanicalFastener.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMECHANICALFASTENERTYPE] = (d) => {
	return IfcMechanicalFastenerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEDICALDEVICE] = (d) => {
	return IfcMedicalDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEDICALDEVICETYPE] = (d) => {
	return IfcMedicalDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEMBER] = (d) => {
	return IfcMember.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEMBERSTANDARDCASE] = (d) => {
	return IfcMemberStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMEMBERTYPE] = (d) => {
	return IfcMemberType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMETRIC] = (d) => {
	return IfcMetric.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMIRROREDPROFILEDEF] = (d) => {
	return IfcMirroredProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMONETARYUNIT] = (d) => {
	return IfcMonetaryUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMOTORCONNECTION] = (d) => {
	return IfcMotorConnection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCMOTORCONNECTIONTYPE] = (d) => {
	return IfcMotorConnectionType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCNAMEDUNIT] = (d) => {
	return IfcNamedUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOBJECT] = (d) => {
	return IfcObject.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOBJECTDEFINITION] = (d) => {
	return IfcObjectDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOBJECTPLACEMENT] = (d) => {
	return IfcObjectPlacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOBJECTIVE] = (d) => {
	return IfcObjective.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOCCUPANT] = (d) => {
	return IfcOccupant.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOFFSETCURVE] = (d) => {
	return IfcOffsetCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOFFSETCURVE2D] = (d) => {
	return IfcOffsetCurve2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOFFSETCURVE3D] = (d) => {
	return IfcOffsetCurve3D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOFFSETCURVEBYDISTANCES] = (d) => {
	return IfcOffsetCurveByDistances.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOPENSHELL] = (d) => {
	return IfcOpenShell.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOPENINGELEMENT] = (d) => {
	return IfcOpeningElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOPENINGSTANDARDCASE] = (d) => {
	return IfcOpeningStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCORGANIZATION] = (d) => {
	return IfcOrganization.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCORGANIZATIONRELATIONSHIP] = (d) => {
	return IfcOrganizationRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCORIENTATIONEXPRESSION] = (d) => {
	return IfcOrientationExpression.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCORIENTEDEDGE] = (d) => {
	return IfcOrientedEdge.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOUTERBOUNDARYCURVE] = (d) => {
	return IfcOuterBoundaryCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOUTLET] = (d) => {
	return IfcOutlet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOUTLETTYPE] = (d) => {
	return IfcOutletType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCOWNERHISTORY] = (d) => {
	return IfcOwnerHistory.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPARAMETERIZEDPROFILEDEF] = (d) => {
	return IfcParameterizedProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPATH] = (d) => {
	return IfcPath.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPCURVE] = (d) => {
	return IfcPcurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPERFORMANCEHISTORY] = (d) => {
	return IfcPerformanceHistory.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPERMEABLECOVERINGPROPERTIES] = (d) => {
	return IfcPermeableCoveringProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPERMIT] = (d) => {
	return IfcPermit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPERSON] = (d) => {
	return IfcPerson.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPERSONANDORGANIZATION] = (d) => {
	return IfcPersonAndOrganization.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPHYSICALCOMPLEXQUANTITY] = (d) => {
	return IfcPhysicalComplexQuantity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPHYSICALQUANTITY] = (d) => {
	return IfcPhysicalQuantity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPHYSICALSIMPLEQUANTITY] = (d) => {
	return IfcPhysicalSimpleQuantity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPILE] = (d) => {
	return IfcPile.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPILETYPE] = (d) => {
	return IfcPileType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPIPEFITTING] = (d) => {
	return IfcPipeFitting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPIPEFITTINGTYPE] = (d) => {
	return IfcPipeFittingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPIPESEGMENT] = (d) => {
	return IfcPipeSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPIPESEGMENTTYPE] = (d) => {
	return IfcPipeSegmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPIXELTEXTURE] = (d) => {
	return IfcPixelTexture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLACEMENT] = (d) => {
	return IfcPlacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLANARBOX] = (d) => {
	return IfcPlanarBox.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLANAREXTENT] = (d) => {
	return IfcPlanarExtent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLANE] = (d) => {
	return IfcPlane.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLATE] = (d) => {
	return IfcPlate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLATESTANDARDCASE] = (d) => {
	return IfcPlateStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPLATETYPE] = (d) => {
	return IfcPlateType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOINT] = (d) => {
	return IfcPoint.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOINTONCURVE] = (d) => {
	return IfcPointOnCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOINTONSURFACE] = (d) => {
	return IfcPointOnSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOLYLOOP] = (d) => {
	return IfcPolyLoop.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOLYGONALBOUNDEDHALFSPACE] = (d) => {
	return IfcPolygonalBoundedHalfSpace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOLYGONALFACESET] = (d) => {
	return IfcPolygonalFaceSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOLYLINE] = (d) => {
	return IfcPolyline.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPORT] = (d) => {
	return IfcPort.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOSITIONINGELEMENT] = (d) => {
	return IfcPositioningElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPOSTALADDRESS] = (d) => {
	return IfcPostalAddress.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDCOLOUR] = (d) => {
	return IfcPreDefinedColour.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDCURVEFONT] = (d) => {
	return IfcPreDefinedCurveFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDITEM] = (d) => {
	return IfcPreDefinedItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDPROPERTIES] = (d) => {
	return IfcPreDefinedProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDPROPERTYSET] = (d) => {
	return IfcPreDefinedPropertySet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPREDEFINEDTEXTFONT] = (d) => {
	return IfcPreDefinedTextFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRESENTATIONITEM] = (d) => {
	return IfcPresentationItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRESENTATIONLAYERASSIGNMENT] = (d) => {
	return IfcPresentationLayerAssignment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRESENTATIONLAYERWITHSTYLE] = (d) => {
	return IfcPresentationLayerWithStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRESENTATIONSTYLE] = (d) => {
	return IfcPresentationStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRESENTATIONSTYLEASSIGNMENT] = (d) => {
	return IfcPresentationStyleAssignment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROCEDURE] = (d) => {
	return IfcProcedure.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROCEDURETYPE] = (d) => {
	return IfcProcedureType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROCESS] = (d) => {
	return IfcProcess.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRODUCT] = (d) => {
	return IfcProduct.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRODUCTDEFINITIONSHAPE] = (d) => {
	return IfcProductDefinitionShape.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPRODUCTREPRESENTATION] = (d) => {
	return IfcProductRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROFILEDEF] = (d) => {
	return IfcProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROFILEPROPERTIES] = (d) => {
	return IfcProfileProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROJECT] = (d) => {
	return IfcProject.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROJECTLIBRARY] = (d) => {
	return IfcProjectLibrary.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROJECTORDER] = (d) => {
	return IfcProjectOrder.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROJECTEDCRS] = (d) => {
	return IfcProjectedCRS.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROJECTIONELEMENT] = (d) => {
	return IfcProjectionElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTY] = (d) => {
	return IfcProperty.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYABSTRACTION] = (d) => {
	return IfcPropertyAbstraction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYBOUNDEDVALUE] = (d) => {
	return IfcPropertyBoundedValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYDEFINITION] = (d) => {
	return IfcPropertyDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYDEPENDENCYRELATIONSHIP] = (d) => {
	return IfcPropertyDependencyRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYENUMERATEDVALUE] = (d) => {
	return IfcPropertyEnumeratedValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYENUMERATION] = (d) => {
	return IfcPropertyEnumeration.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYLISTVALUE] = (d) => {
	return IfcPropertyListValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYREFERENCEVALUE] = (d) => {
	return IfcPropertyReferenceValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYSET] = (d) => {
	return IfcPropertySet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYSETDEFINITION] = (d) => {
	return IfcPropertySetDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYSETTEMPLATE] = (d) => {
	return IfcPropertySetTemplate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYSINGLEVALUE] = (d) => {
	return IfcPropertySingleValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYTABLEVALUE] = (d) => {
	return IfcPropertyTableValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYTEMPLATE] = (d) => {
	return IfcPropertyTemplate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROPERTYTEMPLATEDEFINITION] = (d) => {
	return IfcPropertyTemplateDefinition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROTECTIVEDEVICE] = (d) => {
	return IfcProtectiveDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROTECTIVEDEVICETRIPPINGUNIT] = (d) => {
	return IfcProtectiveDeviceTrippingUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROTECTIVEDEVICETRIPPINGUNITTYPE] = (d) => {
	return IfcProtectiveDeviceTrippingUnitType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROTECTIVEDEVICETYPE] = (d) => {
	return IfcProtectiveDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPROXY] = (d) => {
	return IfcProxy.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPUMP] = (d) => {
	return IfcPump.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCPUMPTYPE] = (d) => {
	return IfcPumpType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYAREA] = (d) => {
	return IfcQuantityArea.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYCOUNT] = (d) => {
	return IfcQuantityCount.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYLENGTH] = (d) => {
	return IfcQuantityLength.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYSET] = (d) => {
	return IfcQuantitySet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYTIME] = (d) => {
	return IfcQuantityTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYVOLUME] = (d) => {
	return IfcQuantityVolume.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCQUANTITYWEIGHT] = (d) => {
	return IfcQuantityWeight.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAILING] = (d) => {
	return IfcRailing.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAILINGTYPE] = (d) => {
	return IfcRailingType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAMP] = (d) => {
	return IfcRamp.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAMPFLIGHT] = (d) => {
	return IfcRampFlight.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAMPFLIGHTTYPE] = (d) => {
	return IfcRampFlightType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRAMPTYPE] = (d) => {
	return IfcRampType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRATIONALBSPLINECURVEWITHKNOTS] = (d) => {
	return IfcRationalBSplineCurveWithKnots.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRATIONALBSPLINESURFACEWITHKNOTS] = (d) => {
	return IfcRationalBSplineSurfaceWithKnots.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRECTANGLEHOLLOWPROFILEDEF] = (d) => {
	return IfcRectangleHollowProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRECTANGLEPROFILEDEF] = (d) => {
	return IfcRectangleProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRECTANGULARPYRAMID] = (d) => {
	return IfcRectangularPyramid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRECTANGULARTRIMMEDSURFACE] = (d) => {
	return IfcRectangularTrimmedSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRECURRENCEPATTERN] = (d) => {
	return IfcRecurrencePattern.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREFERENCE] = (d) => {
	return IfcReference.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREFERENT] = (d) => {
	return IfcReferent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREGULARTIMESERIES] = (d) => {
	return IfcRegularTimeSeries.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCEMENTBARPROPERTIES] = (d) => {
	return IfcReinforcementBarProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCEMENTDEFINITIONPROPERTIES] = (d) => {
	return IfcReinforcementDefinitionProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGBAR] = (d) => {
	return IfcReinforcingBar.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGBARTYPE] = (d) => {
	return IfcReinforcingBarType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGELEMENT] = (d) => {
	return IfcReinforcingElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGELEMENTTYPE] = (d) => {
	return IfcReinforcingElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGMESH] = (d) => {
	return IfcReinforcingMesh.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREINFORCINGMESHTYPE] = (d) => {
	return IfcReinforcingMeshType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELAGGREGATES] = (d) => {
	return IfcRelAggregates.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNS] = (d) => {
	return IfcRelAssigns.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOACTOR] = (d) => {
	return IfcRelAssignsToActor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOCONTROL] = (d) => {
	return IfcRelAssignsToControl.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOGROUP] = (d) => {
	return IfcRelAssignsToGroup.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOGROUPBYFACTOR] = (d) => {
	return IfcRelAssignsToGroupByFactor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOPROCESS] = (d) => {
	return IfcRelAssignsToProcess.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTOPRODUCT] = (d) => {
	return IfcRelAssignsToProduct.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSIGNSTORESOURCE] = (d) => {
	return IfcRelAssignsToResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATES] = (d) => {
	return IfcRelAssociates.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESAPPROVAL] = (d) => {
	return IfcRelAssociatesApproval.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESCLASSIFICATION] = (d) => {
	return IfcRelAssociatesClassification.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESCONSTRAINT] = (d) => {
	return IfcRelAssociatesConstraint.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESDOCUMENT] = (d) => {
	return IfcRelAssociatesDocument.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESLIBRARY] = (d) => {
	return IfcRelAssociatesLibrary.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELASSOCIATESMATERIAL] = (d) => {
	return IfcRelAssociatesMaterial.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTS] = (d) => {
	return IfcRelConnects.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSELEMENTS] = (d) => {
	return IfcRelConnectsElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSPATHELEMENTS] = (d) => {
	return IfcRelConnectsPathElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSPORTTOELEMENT] = (d) => {
	return IfcRelConnectsPortToElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSPORTS] = (d) => {
	return IfcRelConnectsPorts.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSSTRUCTURALACTIVITY] = (d) => {
	return IfcRelConnectsStructuralActivity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSSTRUCTURALMEMBER] = (d) => {
	return IfcRelConnectsStructuralMember.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSWITHECCENTRICITY] = (d) => {
	return IfcRelConnectsWithEccentricity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONNECTSWITHREALIZINGELEMENTS] = (d) => {
	return IfcRelConnectsWithRealizingElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCONTAINEDINSPATIALSTRUCTURE] = (d) => {
	return IfcRelContainedInSpatialStructure.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCOVERSBLDGELEMENTS] = (d) => {
	return IfcRelCoversBldgElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELCOVERSSPACES] = (d) => {
	return IfcRelCoversSpaces.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDECLARES] = (d) => {
	return IfcRelDeclares.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDECOMPOSES] = (d) => {
	return IfcRelDecomposes.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDEFINES] = (d) => {
	return IfcRelDefines.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDEFINESBYOBJECT] = (d) => {
	return IfcRelDefinesByObject.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDEFINESBYPROPERTIES] = (d) => {
	return IfcRelDefinesByProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDEFINESBYTEMPLATE] = (d) => {
	return IfcRelDefinesByTemplate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELDEFINESBYTYPE] = (d) => {
	return IfcRelDefinesByType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELFILLSELEMENT] = (d) => {
	return IfcRelFillsElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELFLOWCONTROLELEMENTS] = (d) => {
	return IfcRelFlowControlElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELINTERFERESELEMENTS] = (d) => {
	return IfcRelInterferesElements.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELNESTS] = (d) => {
	return IfcRelNests.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELPOSITIONS] = (d) => {
	return IfcRelPositions.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELPROJECTSELEMENT] = (d) => {
	return IfcRelProjectsElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELREFERENCEDINSPATIALSTRUCTURE] = (d) => {
	return IfcRelReferencedInSpatialStructure.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELSEQUENCE] = (d) => {
	return IfcRelSequence.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELSERVICESBUILDINGS] = (d) => {
	return IfcRelServicesBuildings.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELSPACEBOUNDARY] = (d) => {
	return IfcRelSpaceBoundary.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELSPACEBOUNDARY1STLEVEL] = (d) => {
	return IfcRelSpaceBoundary1stLevel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELSPACEBOUNDARY2NDLEVEL] = (d) => {
	return IfcRelSpaceBoundary2ndLevel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELVOIDSELEMENT] = (d) => {
	return IfcRelVoidsElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRELATIONSHIP] = (d) => {
	return IfcRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREPARAMETRISEDCOMPOSITECURVESEGMENT] = (d) => {
	return IfcReparametrisedCompositeCurveSegment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREPRESENTATION] = (d) => {
	return IfcRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREPRESENTATIONCONTEXT] = (d) => {
	return IfcRepresentationContext.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREPRESENTATIONITEM] = (d) => {
	return IfcRepresentationItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREPRESENTATIONMAP] = (d) => {
	return IfcRepresentationMap.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRESOURCE] = (d) => {
	return IfcResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRESOURCEAPPROVALRELATIONSHIP] = (d) => {
	return IfcResourceApprovalRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRESOURCECONSTRAINTRELATIONSHIP] = (d) => {
	return IfcResourceConstraintRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRESOURCELEVELRELATIONSHIP] = (d) => {
	return IfcResourceLevelRelationship.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRESOURCETIME] = (d) => {
	return IfcResourceTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREVOLVEDAREASOLID] = (d) => {
	return IfcRevolvedAreaSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCREVOLVEDAREASOLIDTAPERED] = (d) => {
	return IfcRevolvedAreaSolidTapered.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRIGHTCIRCULARCONE] = (d) => {
	return IfcRightCircularCone.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCRIGHTCIRCULARCYLINDER] = (d) => {
	return IfcRightCircularCylinder.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCROOF] = (d) => {
	return IfcRoof.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCROOFTYPE] = (d) => {
	return IfcRoofType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCROOT] = (d) => {
	return IfcRoot.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCROUNDEDRECTANGLEPROFILEDEF] = (d) => {
	return IfcRoundedRectangleProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSIUNIT] = (d) => {
	return IfcSIUnit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSANITARYTERMINAL] = (d) => {
	return IfcSanitaryTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSANITARYTERMINALTYPE] = (d) => {
	return IfcSanitaryTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSCHEDULINGTIME] = (d) => {
	return IfcSchedulingTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSEAMCURVE] = (d) => {
	return IfcSeamCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSECTIONPROPERTIES] = (d) => {
	return IfcSectionProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSECTIONREINFORCEMENTPROPERTIES] = (d) => {
	return IfcSectionReinforcementProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSECTIONEDSOLID] = (d) => {
	return IfcSectionedSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSECTIONEDSOLIDHORIZONTAL] = (d) => {
	return IfcSectionedSolidHorizontal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSECTIONEDSPINE] = (d) => {
	return IfcSectionedSpine.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSENSOR] = (d) => {
	return IfcSensor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSENSORTYPE] = (d) => {
	return IfcSensorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHADINGDEVICE] = (d) => {
	return IfcShadingDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHADINGDEVICETYPE] = (d) => {
	return IfcShadingDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHAPEASPECT] = (d) => {
	return IfcShapeAspect.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHAPEMODEL] = (d) => {
	return IfcShapeModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHAPEREPRESENTATION] = (d) => {
	return IfcShapeRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSHELLBASEDSURFACEMODEL] = (d) => {
	return IfcShellBasedSurfaceModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSIMPLEPROPERTY] = (d) => {
	return IfcSimpleProperty.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSIMPLEPROPERTYTEMPLATE] = (d) => {
	return IfcSimplePropertyTemplate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSITE] = (d) => {
	return IfcSite.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSLAB] = (d) => {
	return IfcSlab.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSLABELEMENTEDCASE] = (d) => {
	return IfcSlabElementedCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSLABSTANDARDCASE] = (d) => {
	return IfcSlabStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSLABTYPE] = (d) => {
	return IfcSlabType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSLIPPAGECONNECTIONCONDITION] = (d) => {
	return IfcSlippageConnectionCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSOLARDEVICE] = (d) => {
	return IfcSolarDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSOLARDEVICETYPE] = (d) => {
	return IfcSolarDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSOLIDMODEL] = (d) => {
	return IfcSolidModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPACE] = (d) => {
	return IfcSpace.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPACEHEATER] = (d) => {
	return IfcSpaceHeater.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPACEHEATERTYPE] = (d) => {
	return IfcSpaceHeaterType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPACETYPE] = (d) => {
	return IfcSpaceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALELEMENT] = (d) => {
	return IfcSpatialElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALELEMENTTYPE] = (d) => {
	return IfcSpatialElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALSTRUCTUREELEMENT] = (d) => {
	return IfcSpatialStructureElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALSTRUCTUREELEMENTTYPE] = (d) => {
	return IfcSpatialStructureElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALZONE] = (d) => {
	return IfcSpatialZone.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPATIALZONETYPE] = (d) => {
	return IfcSpatialZoneType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPHERE] = (d) => {
	return IfcSphere.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSPHERICALSURFACE] = (d) => {
	return IfcSphericalSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTACKTERMINAL] = (d) => {
	return IfcStackTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTACKTERMINALTYPE] = (d) => {
	return IfcStackTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTAIR] = (d) => {
	return IfcStair.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTAIRFLIGHT] = (d) => {
	return IfcStairFlight.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTAIRFLIGHTTYPE] = (d) => {
	return IfcStairFlightType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTAIRTYPE] = (d) => {
	return IfcStairType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALACTION] = (d) => {
	return IfcStructuralAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALACTIVITY] = (d) => {
	return IfcStructuralActivity.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALANALYSISMODEL] = (d) => {
	return IfcStructuralAnalysisModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCONNECTION] = (d) => {
	return IfcStructuralConnection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCONNECTIONCONDITION] = (d) => {
	return IfcStructuralConnectionCondition.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCURVEACTION] = (d) => {
	return IfcStructuralCurveAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCURVECONNECTION] = (d) => {
	return IfcStructuralCurveConnection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCURVEMEMBER] = (d) => {
	return IfcStructuralCurveMember.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCURVEMEMBERVARYING] = (d) => {
	return IfcStructuralCurveMemberVarying.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALCURVEREACTION] = (d) => {
	return IfcStructuralCurveReaction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALITEM] = (d) => {
	return IfcStructuralItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLINEARACTION] = (d) => {
	return IfcStructuralLinearAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOAD] = (d) => {
	return IfcStructuralLoad.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADCASE] = (d) => {
	return IfcStructuralLoadCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADCONFIGURATION] = (d) => {
	return IfcStructuralLoadConfiguration.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADGROUP] = (d) => {
	return IfcStructuralLoadGroup.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADLINEARFORCE] = (d) => {
	return IfcStructuralLoadLinearForce.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADORRESULT] = (d) => {
	return IfcStructuralLoadOrResult.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADPLANARFORCE] = (d) => {
	return IfcStructuralLoadPlanarForce.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADSINGLEDISPLACEMENT] = (d) => {
	return IfcStructuralLoadSingleDisplacement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION] = (d) => {
	return IfcStructuralLoadSingleDisplacementDistortion.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADSINGLEFORCE] = (d) => {
	return IfcStructuralLoadSingleForce.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADSINGLEFORCEWARPING] = (d) => {
	return IfcStructuralLoadSingleForceWarping.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADSTATIC] = (d) => {
	return IfcStructuralLoadStatic.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALLOADTEMPERATURE] = (d) => {
	return IfcStructuralLoadTemperature.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALMEMBER] = (d) => {
	return IfcStructuralMember.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALPLANARACTION] = (d) => {
	return IfcStructuralPlanarAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALPOINTACTION] = (d) => {
	return IfcStructuralPointAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALPOINTCONNECTION] = (d) => {
	return IfcStructuralPointConnection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALPOINTREACTION] = (d) => {
	return IfcStructuralPointReaction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALREACTION] = (d) => {
	return IfcStructuralReaction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALRESULTGROUP] = (d) => {
	return IfcStructuralResultGroup.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALSURFACEACTION] = (d) => {
	return IfcStructuralSurfaceAction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALSURFACECONNECTION] = (d) => {
	return IfcStructuralSurfaceConnection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALSURFACEMEMBER] = (d) => {
	return IfcStructuralSurfaceMember.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALSURFACEMEMBERVARYING] = (d) => {
	return IfcStructuralSurfaceMemberVarying.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTRUCTURALSURFACEREACTION] = (d) => {
	return IfcStructuralSurfaceReaction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTYLEMODEL] = (d) => {
	return IfcStyleModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTYLEDITEM] = (d) => {
	return IfcStyledItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSTYLEDREPRESENTATION] = (d) => {
	return IfcStyledRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSUBCONTRACTRESOURCE] = (d) => {
	return IfcSubContractResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSUBCONTRACTRESOURCETYPE] = (d) => {
	return IfcSubContractResourceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSUBEDGE] = (d) => {
	return IfcSubedge.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACE] = (d) => {
	return IfcSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACECURVE] = (d) => {
	return IfcSurfaceCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACECURVESWEPTAREASOLID] = (d) => {
	return IfcSurfaceCurveSweptAreaSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACEFEATURE] = (d) => {
	return IfcSurfaceFeature.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACEOFLINEAREXTRUSION] = (d) => {
	return IfcSurfaceOfLinearExtrusion.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACEOFREVOLUTION] = (d) => {
	return IfcSurfaceOfRevolution.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACEREINFORCEMENTAREA] = (d) => {
	return IfcSurfaceReinforcementArea.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLE] = (d) => {
	return IfcSurfaceStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLELIGHTING] = (d) => {
	return IfcSurfaceStyleLighting.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLEREFRACTION] = (d) => {
	return IfcSurfaceStyleRefraction.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLERENDERING] = (d) => {
	return IfcSurfaceStyleRendering.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLESHADING] = (d) => {
	return IfcSurfaceStyleShading.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACESTYLEWITHTEXTURES] = (d) => {
	return IfcSurfaceStyleWithTextures.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSURFACETEXTURE] = (d) => {
	return IfcSurfaceTexture.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWEPTAREASOLID] = (d) => {
	return IfcSweptAreaSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWEPTDISKSOLID] = (d) => {
	return IfcSweptDiskSolid.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWEPTDISKSOLIDPOLYGONAL] = (d) => {
	return IfcSweptDiskSolidPolygonal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWEPTSURFACE] = (d) => {
	return IfcSweptSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWITCHINGDEVICE] = (d) => {
	return IfcSwitchingDevice.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSWITCHINGDEVICETYPE] = (d) => {
	return IfcSwitchingDeviceType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSYSTEM] = (d) => {
	return IfcSystem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSYSTEMFURNITUREELEMENT] = (d) => {
	return IfcSystemFurnitureElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCSYSTEMFURNITUREELEMENTTYPE] = (d) => {
	return IfcSystemFurnitureElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTSHAPEPROFILEDEF] = (d) => {
	return IfcTShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTABLE] = (d) => {
	return IfcTable.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTABLECOLUMN] = (d) => {
	return IfcTableColumn.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTABLEROW] = (d) => {
	return IfcTableRow.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTANK] = (d) => {
	return IfcTank.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTANKTYPE] = (d) => {
	return IfcTankType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTASK] = (d) => {
	return IfcTask.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTASKTIME] = (d) => {
	return IfcTaskTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTASKTIMERECURRING] = (d) => {
	return IfcTaskTimeRecurring.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTASKTYPE] = (d) => {
	return IfcTaskType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTELECOMADDRESS] = (d) => {
	return IfcTelecomAddress.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDON] = (d) => {
	return IfcTendon.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDONANCHOR] = (d) => {
	return IfcTendonAnchor.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDONANCHORTYPE] = (d) => {
	return IfcTendonAnchorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDONCONDUIT] = (d) => {
	return IfcTendonConduit.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDONCONDUITTYPE] = (d) => {
	return IfcTendonConduitType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTENDONTYPE] = (d) => {
	return IfcTendonType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTESSELLATEDFACESET] = (d) => {
	return IfcTessellatedFaceSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTESSELLATEDITEM] = (d) => {
	return IfcTessellatedItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTLITERAL] = (d) => {
	return IfcTextLiteral.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTLITERALWITHEXTENT] = (d) => {
	return IfcTextLiteralWithExtent.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTSTYLE] = (d) => {
	return IfcTextStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTSTYLEFONTMODEL] = (d) => {
	return IfcTextStyleFontModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTSTYLEFORDEFINEDFONT] = (d) => {
	return IfcTextStyleForDefinedFont.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTSTYLETEXTMODEL] = (d) => {
	return IfcTextStyleTextModel.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTURECOORDINATE] = (d) => {
	return IfcTextureCoordinate.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTURECOORDINATEGENERATOR] = (d) => {
	return IfcTextureCoordinateGenerator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTUREMAP] = (d) => {
	return IfcTextureMap.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTUREVERTEX] = (d) => {
	return IfcTextureVertex.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTEXTUREVERTEXLIST] = (d) => {
	return IfcTextureVertexList.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTIMEPERIOD] = (d) => {
	return IfcTimePeriod.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTIMESERIES] = (d) => {
	return IfcTimeSeries.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTIMESERIESVALUE] = (d) => {
	return IfcTimeSeriesValue.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTOPOLOGICALREPRESENTATIONITEM] = (d) => {
	return IfcTopologicalRepresentationItem.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTOPOLOGYREPRESENTATION] = (d) => {
	return IfcTopologyRepresentation.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTOROIDALSURFACE] = (d) => {
	return IfcToroidalSurface.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRANSFORMER] = (d) => {
	return IfcTransformer.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRANSFORMERTYPE] = (d) => {
	return IfcTransformerType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRANSITIONCURVESEGMENT2D] = (d) => {
	return IfcTransitionCurveSegment2D.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRANSPORTELEMENT] = (d) => {
	return IfcTransportElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRANSPORTELEMENTTYPE] = (d) => {
	return IfcTransportElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRAPEZIUMPROFILEDEF] = (d) => {
	return IfcTrapeziumProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRIANGULATEDFACESET] = (d) => {
	return IfcTriangulatedFaceSet.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRIANGULATEDIRREGULARNETWORK] = (d) => {
	return IfcTriangulatedIrregularNetwork.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTRIMMEDCURVE] = (d) => {
	return IfcTrimmedCurve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTUBEBUNDLE] = (d) => {
	return IfcTubeBundle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTUBEBUNDLETYPE] = (d) => {
	return IfcTubeBundleType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTYPEOBJECT] = (d) => {
	return IfcTypeObject.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTYPEPROCESS] = (d) => {
	return IfcTypeProcess.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTYPEPRODUCT] = (d) => {
	return IfcTypeProduct.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCTYPERESOURCE] = (d) => {
	return IfcTypeResource.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUSHAPEPROFILEDEF] = (d) => {
	return IfcUShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUNITASSIGNMENT] = (d) => {
	return IfcUnitAssignment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUNITARYCONTROLELEMENT] = (d) => {
	return IfcUnitaryControlElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUNITARYCONTROLELEMENTTYPE] = (d) => {
	return IfcUnitaryControlElementType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUNITARYEQUIPMENT] = (d) => {
	return IfcUnitaryEquipment.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCUNITARYEQUIPMENTTYPE] = (d) => {
	return IfcUnitaryEquipmentType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVALVE] = (d) => {
	return IfcValve.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVALVETYPE] = (d) => {
	return IfcValveType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVECTOR] = (d) => {
	return IfcVector.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVERTEX] = (d) => {
	return IfcVertex.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVERTEXLOOP] = (d) => {
	return IfcVertexLoop.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVERTEXPOINT] = (d) => {
	return IfcVertexPoint.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIBRATIONDAMPER] = (d) => {
	return IfcVibrationDamper.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIBRATIONDAMPERTYPE] = (d) => {
	return IfcVibrationDamperType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIBRATIONISOLATOR] = (d) => {
	return IfcVibrationIsolator.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIBRATIONISOLATORTYPE] = (d) => {
	return IfcVibrationIsolatorType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIRTUALELEMENT] = (d) => {
	return IfcVirtualElement.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVIRTUALGRIDINTERSECTION] = (d) => {
	return IfcVirtualGridIntersection.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCVOIDINGFEATURE] = (d) => {
	return IfcVoidingFeature.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWALL] = (d) => {
	return IfcWall.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWALLELEMENTEDCASE] = (d) => {
	return IfcWallElementedCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWALLSTANDARDCASE] = (d) => {
	return IfcWallStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWALLTYPE] = (d) => {
	return IfcWallType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWASTETERMINAL] = (d) => {
	return IfcWasteTerminal.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWASTETERMINALTYPE] = (d) => {
	return IfcWasteTerminalType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOW] = (d) => {
	return IfcWindow.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOWLININGPROPERTIES] = (d) => {
	return IfcWindowLiningProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOWPANELPROPERTIES] = (d) => {
	return IfcWindowPanelProperties.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOWSTANDARDCASE] = (d) => {
	return IfcWindowStandardCase.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOWSTYLE] = (d) => {
	return IfcWindowStyle.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWINDOWTYPE] = (d) => {
	return IfcWindowType.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWORKCALENDAR] = (d) => {
	return IfcWorkCalendar.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWORKCONTROL] = (d) => {
	return IfcWorkControl.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWORKPLAN] = (d) => {
	return IfcWorkPlan.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWORKSCHEDULE] = (d) => {
	return IfcWorkSchedule.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCWORKTIME] = (d) => {
	return IfcWorkTime.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCZSHAPEPROFILEDEF] = (d) => {
	return IfcZShapeProfileDef.FromTape(d.ID, d.type, d.arguments);
};
FromRawLineData[ifc2x4.IFCZONE] = (d) => {
	return IfcZone.FromTape(d.ID, d.type, d.arguments);
};
export class Handle<T> {
	value: number;
	constructor(id: number) { this.value = id; }
	toTape(args: any[]){ args.push({ type: 5, value: this.value }); }
}

export function Value(type: string, value: any): any { return { t: type, v: value }; }
const UNKNOWN = 0;
const STRING = 1;
const LABEL = 2;
const ENUM = 3;
const REAL = 4;
const REF = 5;
const EMPTY = 6;
const SET_BEGIN = 7;
const SET_END = 8;
const LINE_END = 9;
export class IfcAbsorbedDoseMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcAccelerationMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcAmountOfSubstanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcAngularVelocityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export type IfcArcIndex = Array<IfcPositiveInteger>;
export class IfcAreaDensityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcAreaMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcBinary {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcBoolean {
	value: boolean;
	constructor(v: boolean) { this.value = v;}
};
export class IfcBoxAlignment {
	value: IfcLabel;
	constructor(v: IfcLabel) { this.value = v;}
};
export class IfcCardinalPointReference {
	value: number;
	constructor(v: number) { this.value = v;}
};
export type IfcComplexNumber = Array<number>;
export type IfcCompoundPlaneAngleMeasure = Array<number>;
export class IfcContextDependentMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcCountMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcCurvatureMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcDate {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcDateTime {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcDayInMonthNumber {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcDayInWeekNumber {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcDescriptiveMeasure {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcDimensionCount {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcDoseEquivalentMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcDuration {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcDynamicViscosityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricCapacitanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricChargeMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricConductanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricCurrentMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricResistanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcElectricVoltageMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcEnergyMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcFontStyle {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcFontVariant {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcFontWeight {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcForceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcFrequencyMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcGloballyUniqueId {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcHeatFluxDensityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcHeatingValueMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcIdentifier {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcIlluminanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcInductanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcInteger {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcIntegerCountRateMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcIonConcentrationMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcIsothermalMoistureCapacityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcKinematicViscosityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLabel {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcLanguageId {
	value: IfcIdentifier;
	constructor(v: IfcIdentifier) { this.value = v;}
};
export class IfcLengthMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export type IfcLineIndex = Array<IfcPositiveInteger>;
export class IfcLinearForceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLinearMomentMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLinearStiffnessMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLinearVelocityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLogical {
	value: boolean;
	constructor(v: boolean) { this.value = v;}
};
export class IfcLuminousFluxMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLuminousIntensityDistributionMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcLuminousIntensityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMagneticFluxDensityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMagneticFluxMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMassDensityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMassFlowRateMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMassMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMassPerLengthMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcModulusOfElasticityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcModulusOfLinearSubgradeReactionMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcModulusOfRotationalSubgradeReactionMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcModulusOfSubgradeReactionMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMoistureDiffusivityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMolecularWeightMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMomentOfInertiaMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMonetaryMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcMonthInYearNumber {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcNonNegativeLengthMeasure {
	value: IfcLengthMeasure;
	constructor(v: IfcLengthMeasure) { this.value = v;}
};
export class IfcNormalisedRatioMeasure {
	value: IfcRatioMeasure;
	constructor(v: IfcRatioMeasure) { this.value = v;}
};
export class IfcNumericMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcPHMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcParameterValue {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcPlanarForceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcPlaneAngleMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcPositiveInteger {
	value: IfcInteger;
	constructor(v: IfcInteger) { this.value = v;}
};
export class IfcPositiveLengthMeasure {
	value: IfcLengthMeasure;
	constructor(v: IfcLengthMeasure) { this.value = v;}
};
export class IfcPositivePlaneAngleMeasure {
	value: IfcPlaneAngleMeasure;
	constructor(v: IfcPlaneAngleMeasure) { this.value = v;}
};
export class IfcPositiveRatioMeasure {
	value: IfcRatioMeasure;
	constructor(v: IfcRatioMeasure) { this.value = v;}
};
export class IfcPowerMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcPresentableText {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcPressureMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export type IfcPropertySetDefinitionSet = Array<IfcPropertySetDefinition>;
export class IfcRadioActivityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcRatioMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcReal {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcRotationalFrequencyMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcRotationalMassMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcRotationalStiffnessMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSectionModulusMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSectionalAreaIntegralMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcShearModulusMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSolidAngleMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSoundPowerLevelMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSoundPowerMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSoundPressureLevelMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSoundPressureMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSpecificHeatCapacityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSpecularExponent {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcSpecularRoughness {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcTemperatureGradientMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcTemperatureRateOfChangeMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcText {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcTextAlignment {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcTextDecoration {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcTextFontName {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcTextTransformation {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcThermalAdmittanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcThermalConductivityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcThermalExpansionCoefficientMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcThermalResistanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcThermalTransmittanceMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcThermodynamicTemperatureMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcTime {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcTimeMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcTimeStamp {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcTorqueMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcURIReference {
	value: string;
	constructor(v: string) { this.value = v;}
};
export class IfcVaporPermeabilityMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcVolumeMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcVolumetricFlowRateMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcWarpingConstantMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcWarpingMomentMeasure {
	value: number;
	constructor(v: number) { this.value = v;}
};
export class IfcActionRequestTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static EMAIL = "EMAIL";
	static FAX = "FAX";
	static PHONE = "PHONE";
	static POST = "POST";
	static VERBAL = "VERBAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcActionSourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DEAD_LOAD_G = "DEAD_LOAD_G";
	static COMPLETION_G1 = "COMPLETION_G1";
	static LIVE_LOAD_Q = "LIVE_LOAD_Q";
	static SNOW_S = "SNOW_S";
	static WIND_W = "WIND_W";
	static PRESTRESSING_P = "PRESTRESSING_P";
	static SETTLEMENT_U = "SETTLEMENT_U";
	static TEMPERATURE_T = "TEMPERATURE_T";
	static EARTHQUAKE_E = "EARTHQUAKE_E";
	static FIRE = "FIRE";
	static IMPULSE = "IMPULSE";
	static IMPACT = "IMPACT";
	static TRANSPORT = "TRANSPORT";
	static ERECTION = "ERECTION";
	static PROPPING = "PROPPING";
	static SYSTEM_IMPERFECTION = "SYSTEM_IMPERFECTION";
	static SHRINKAGE = "SHRINKAGE";
	static CREEP = "CREEP";
	static LACK_OF_FIT = "LACK_OF_FIT";
	static BUOYANCY = "BUOYANCY";
	static ICE = "ICE";
	static CURRENT = "CURRENT";
	static WAVE = "WAVE";
	static RAIN = "RAIN";
	static BRAKES = "BRAKES";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcActionTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PERMANENT_G = "PERMANENT_G";
	static VARIABLE_Q = "VARIABLE_Q";
	static EXTRAORDINARY_A = "EXTRAORDINARY_A";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcActuatorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ELECTRICACTUATOR = "ELECTRICACTUATOR";
	static HANDOPERATEDACTUATOR = "HANDOPERATEDACTUATOR";
	static HYDRAULICACTUATOR = "HYDRAULICACTUATOR";
	static PNEUMATICACTUATOR = "PNEUMATICACTUATOR";
	static THERMOSTATICACTUATOR = "THERMOSTATICACTUATOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAddressTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static OFFICE = "OFFICE";
	static SITE = "SITE";
	static HOME = "HOME";
	static DISTRIBUTIONPOINT = "DISTRIBUTIONPOINT";
	static USERDEFINED = "USERDEFINED";
};
export class IfcAirTerminalBoxTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONSTANTFLOW = "CONSTANTFLOW";
	static VARIABLEFLOWPRESSUREDEPENDANT = "VARIABLEFLOWPRESSUREDEPENDANT";
	static VARIABLEFLOWPRESSUREINDEPENDANT = "VARIABLEFLOWPRESSUREINDEPENDANT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAirTerminalTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DIFFUSER = "DIFFUSER";
	static GRILLE = "GRILLE";
	static LOUVRE = "LOUVRE";
	static REGISTER = "REGISTER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAirToAirHeatRecoveryTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FIXEDPLATECOUNTERFLOWEXCHANGER = "FIXEDPLATECOUNTERFLOWEXCHANGER";
	static FIXEDPLATECROSSFLOWEXCHANGER = "FIXEDPLATECROSSFLOWEXCHANGER";
	static FIXEDPLATEPARALLELFLOWEXCHANGER = "FIXEDPLATEPARALLELFLOWEXCHANGER";
	static ROTARYWHEEL = "ROTARYWHEEL";
	static RUNAROUNDCOILLOOP = "RUNAROUNDCOILLOOP";
	static HEATPIPE = "HEATPIPE";
	static TWINTOWERENTHALPYRECOVERYLOOPS = "TWINTOWERENTHALPYRECOVERYLOOPS";
	static THERMOSIPHONSEALEDTUBEHEATEXCHANGERS = "THERMOSIPHONSEALEDTUBEHEATEXCHANGERS";
	static THERMOSIPHONCOILTYPEHEATEXCHANGERS = "THERMOSIPHONCOILTYPEHEATEXCHANGERS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAlarmTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BELL = "BELL";
	static BREAKGLASSBUTTON = "BREAKGLASSBUTTON";
	static LIGHT = "LIGHT";
	static MANUALPULLBOX = "MANUALPULLBOX";
	static SIREN = "SIREN";
	static WHISTLE = "WHISTLE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAlignmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAnalysisModelTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static IN_PLANE_LOADING_2D = "IN_PLANE_LOADING_2D";
	static OUT_PLANE_LOADING_2D = "OUT_PLANE_LOADING_2D";
	static LOADING_3D = "LOADING_3D";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAnalysisTheoryTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FIRST_ORDER_THEORY = "FIRST_ORDER_THEORY";
	static SECOND_ORDER_THEORY = "SECOND_ORDER_THEORY";
	static THIRD_ORDER_THEORY = "THIRD_ORDER_THEORY";
	static FULL_NONLINEAR_THEORY = "FULL_NONLINEAR_THEORY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcArithmeticOperatorEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ADD = "ADD";
	static DIVIDE = "DIVIDE";
	static MULTIPLY = "MULTIPLY";
	static SUBTRACT = "SUBTRACT";
};
export class IfcAssemblyPlaceEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SITE = "SITE";
	static FACTORY = "FACTORY";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcAudioVisualApplianceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AMPLIFIER = "AMPLIFIER";
	static CAMERA = "CAMERA";
	static DISPLAY = "DISPLAY";
	static MICROPHONE = "MICROPHONE";
	static PLAYER = "PLAYER";
	static PROJECTOR = "PROJECTOR";
	static RECEIVER = "RECEIVER";
	static SPEAKER = "SPEAKER";
	static SWITCHER = "SWITCHER";
	static TELEPHONE = "TELEPHONE";
	static TUNER = "TUNER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBSplineCurveForm {
	value: string;
	constructor(v: string) { this.value = v;}
	static POLYLINE_FORM = "POLYLINE_FORM";
	static CIRCULAR_ARC = "CIRCULAR_ARC";
	static ELLIPTIC_ARC = "ELLIPTIC_ARC";
	static PARABOLIC_ARC = "PARABOLIC_ARC";
	static HYPERBOLIC_ARC = "HYPERBOLIC_ARC";
	static UNSPECIFIED = "UNSPECIFIED";
};
export class IfcBSplineSurfaceForm {
	value: string;
	constructor(v: string) { this.value = v;}
	static PLANE_SURF = "PLANE_SURF";
	static CYLINDRICAL_SURF = "CYLINDRICAL_SURF";
	static CONICAL_SURF = "CONICAL_SURF";
	static SPHERICAL_SURF = "SPHERICAL_SURF";
	static TOROIDAL_SURF = "TOROIDAL_SURF";
	static SURF_OF_REVOLUTION = "SURF_OF_REVOLUTION";
	static RULED_SURF = "RULED_SURF";
	static GENERALISED_CONE = "GENERALISED_CONE";
	static QUADRIC_SURF = "QUADRIC_SURF";
	static SURF_OF_LINEAR_EXTRUSION = "SURF_OF_LINEAR_EXTRUSION";
	static UNSPECIFIED = "UNSPECIFIED";
};
export class IfcBeamTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BEAM = "BEAM";
	static JOIST = "JOIST";
	static HOLLOWCORE = "HOLLOWCORE";
	static LINTEL = "LINTEL";
	static SPANDREL = "SPANDREL";
	static T_BEAM = "T_BEAM";
	static GIRDER_SEGMENT = "GIRDER_SEGMENT";
	static DIAPHRAGM = "DIAPHRAGM";
	static PIERCAP = "PIERCAP";
	static HATSTONE = "HATSTONE";
	static CORNICE = "CORNICE";
	static EDGEBEAM = "EDGEBEAM";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBearingTypeDisplacementEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FIXED_MOVEMENT = "FIXED_MOVEMENT";
	static GUIDED_LONGITUDINAL = "GUIDED_LONGITUDINAL";
	static GUIDED_TRANSVERSAL = "GUIDED_TRANSVERSAL";
	static FREE_MOVEMENT = "FREE_MOVEMENT";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBearingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CYLINDRICAL = "CYLINDRICAL";
	static SPHERICAL = "SPHERICAL";
	static ELASTOMERIC = "ELASTOMERIC";
	static POT = "POT";
	static GUIDE = "GUIDE";
	static ROCKER = "ROCKER";
	static ROLLER = "ROLLER";
	static DISK = "DISK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBenchmarkEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static GREATERTHAN = "GREATERTHAN";
	static GREATERTHANOREQUALTO = "GREATERTHANOREQUALTO";
	static LESSTHAN = "LESSTHAN";
	static LESSTHANOREQUALTO = "LESSTHANOREQUALTO";
	static EQUALTO = "EQUALTO";
	static NOTEQUALTO = "NOTEQUALTO";
	static INCLUDES = "INCLUDES";
	static NOTINCLUDES = "NOTINCLUDES";
	static INCLUDEDIN = "INCLUDEDIN";
	static NOTINCLUDEDIN = "NOTINCLUDEDIN";
};
export class IfcBoilerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static WATER = "WATER";
	static STEAM = "STEAM";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBooleanOperator {
	value: string;
	constructor(v: string) { this.value = v;}
	static UNION = "UNION";
	static INTERSECTION = "INTERSECTION";
	static DIFFERENCE = "DIFFERENCE";
};
export class IfcBridgePartTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ABUTMENT = "ABUTMENT";
	static DECK = "DECK";
	static DECK_SEGMENT = "DECK_SEGMENT";
	static FOUNDATION = "FOUNDATION";
	static PIER = "PIER";
	static PIER_SEGMENT = "PIER_SEGMENT";
	static PYLON = "PYLON";
	static SUBSTRUCTURE = "SUBSTRUCTURE";
	static SUPERSTRUCTURE = "SUPERSTRUCTURE";
	static SURFACESTRUCTURE = "SURFACESTRUCTURE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBridgeTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ARCHED = "ARCHED";
	static CABLE_STAYED = "CABLE_STAYED";
	static CANTILEVER = "CANTILEVER";
	static CULVERT = "CULVERT";
	static FRAMEWORK = "FRAMEWORK";
	static GIRDER = "GIRDER";
	static SUSPENSION = "SUSPENSION";
	static TRUSS = "TRUSS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBuildingElementPartTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static INSULATION = "INSULATION";
	static PRECASTPANEL = "PRECASTPANEL";
	static APRON = "APRON";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBuildingElementProxyTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COMPLEX = "COMPLEX";
	static ELEMENT = "ELEMENT";
	static PARTIAL = "PARTIAL";
	static PROVISIONFORVOID = "PROVISIONFORVOID";
	static PROVISIONFORSPACE = "PROVISIONFORSPACE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBuildingSystemTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FENESTRATION = "FENESTRATION";
	static FOUNDATION = "FOUNDATION";
	static LOADBEARING = "LOADBEARING";
	static OUTERSHELL = "OUTERSHELL";
	static SHADING = "SHADING";
	static TRANSPORT = "TRANSPORT";
	static REINFORCING = "REINFORCING";
	static PRESTRESSING = "PRESTRESSING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcBurnerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCableCarrierFittingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BEND = "BEND";
	static CROSS = "CROSS";
	static REDUCER = "REDUCER";
	static TEE = "TEE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCableCarrierSegmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CABLELADDERSEGMENT = "CABLELADDERSEGMENT";
	static CABLETRAYSEGMENT = "CABLETRAYSEGMENT";
	static CABLETRUNKINGSEGMENT = "CABLETRUNKINGSEGMENT";
	static CONDUITSEGMENT = "CONDUITSEGMENT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCableFittingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONNECTOR = "CONNECTOR";
	static ENTRY = "ENTRY";
	static EXIT = "EXIT";
	static JUNCTION = "JUNCTION";
	static TRANSITION = "TRANSITION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCableSegmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BUSBARSEGMENT = "BUSBARSEGMENT";
	static CABLESEGMENT = "CABLESEGMENT";
	static CONDUCTORSEGMENT = "CONDUCTORSEGMENT";
	static CORESEGMENT = "CORESEGMENT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCaissonFoundationTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static WELL = "WELL";
	static CAISSON = "CAISSON";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcChangeActionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static NOCHANGE = "NOCHANGE";
	static MODIFIED = "MODIFIED";
	static ADDED = "ADDED";
	static DELETED = "DELETED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcChillerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRCOOLED = "AIRCOOLED";
	static WATERCOOLED = "WATERCOOLED";
	static HEATRECOVERY = "HEATRECOVERY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcChimneyTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCoilTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DXCOOLINGCOIL = "DXCOOLINGCOIL";
	static ELECTRICHEATINGCOIL = "ELECTRICHEATINGCOIL";
	static GASHEATINGCOIL = "GASHEATINGCOIL";
	static HYDRONICCOIL = "HYDRONICCOIL";
	static STEAMHEATINGCOIL = "STEAMHEATINGCOIL";
	static WATERCOOLINGCOIL = "WATERCOOLINGCOIL";
	static WATERHEATINGCOIL = "WATERHEATINGCOIL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcColumnTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COLUMN = "COLUMN";
	static PILASTER = "PILASTER";
	static PIERSTEM = "PIERSTEM";
	static PIERSTEM_SEGMENT = "PIERSTEM_SEGMENT";
	static STANDCOLUMN = "STANDCOLUMN";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCommunicationsApplianceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ANTENNA = "ANTENNA";
	static COMPUTER = "COMPUTER";
	static FAX = "FAX";
	static GATEWAY = "GATEWAY";
	static MODEM = "MODEM";
	static NETWORKAPPLIANCE = "NETWORKAPPLIANCE";
	static NETWORKBRIDGE = "NETWORKBRIDGE";
	static NETWORKHUB = "NETWORKHUB";
	static PRINTER = "PRINTER";
	static REPEATER = "REPEATER";
	static ROUTER = "ROUTER";
	static SCANNER = "SCANNER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcComplexPropertyTemplateTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static P_COMPLEX = "P_COMPLEX";
	static Q_COMPLEX = "Q_COMPLEX";
};
export class IfcCompressorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DYNAMIC = "DYNAMIC";
	static RECIPROCATING = "RECIPROCATING";
	static ROTARY = "ROTARY";
	static SCROLL = "SCROLL";
	static TROCHOIDAL = "TROCHOIDAL";
	static SINGLESTAGE = "SINGLESTAGE";
	static BOOSTER = "BOOSTER";
	static OPENTYPE = "OPENTYPE";
	static HERMETIC = "HERMETIC";
	static SEMIHERMETIC = "SEMIHERMETIC";
	static WELDEDSHELLHERMETIC = "WELDEDSHELLHERMETIC";
	static ROLLINGPISTON = "ROLLINGPISTON";
	static ROTARYVANE = "ROTARYVANE";
	static SINGLESCREW = "SINGLESCREW";
	static TWINSCREW = "TWINSCREW";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCondenserTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRCOOLED = "AIRCOOLED";
	static EVAPORATIVECOOLED = "EVAPORATIVECOOLED";
	static WATERCOOLED = "WATERCOOLED";
	static WATERCOOLEDBRAZEDPLATE = "WATERCOOLEDBRAZEDPLATE";
	static WATERCOOLEDSHELLCOIL = "WATERCOOLEDSHELLCOIL";
	static WATERCOOLEDSHELLTUBE = "WATERCOOLEDSHELLTUBE";
	static WATERCOOLEDTUBEINTUBE = "WATERCOOLEDTUBEINTUBE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcConnectionTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ATPATH = "ATPATH";
	static ATSTART = "ATSTART";
	static ATEND = "ATEND";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcConstraintEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static HARD = "HARD";
	static SOFT = "SOFT";
	static ADVISORY = "ADVISORY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcConstructionEquipmentResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DEMOLISHING = "DEMOLISHING";
	static EARTHMOVING = "EARTHMOVING";
	static ERECTING = "ERECTING";
	static HEATING = "HEATING";
	static LIGHTING = "LIGHTING";
	static PAVING = "PAVING";
	static PUMPING = "PUMPING";
	static TRANSPORTING = "TRANSPORTING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcConstructionMaterialResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AGGREGATES = "AGGREGATES";
	static CONCRETE = "CONCRETE";
	static DRYWALL = "DRYWALL";
	static FUEL = "FUEL";
	static GYPSUM = "GYPSUM";
	static MASONRY = "MASONRY";
	static METAL = "METAL";
	static PLASTIC = "PLASTIC";
	static WOOD = "WOOD";
	static NOTDEFINED = "NOTDEFINED";
	static USERDEFINED = "USERDEFINED";
};
export class IfcConstructionProductResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ASSEMBLY = "ASSEMBLY";
	static FORMWORK = "FORMWORK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcControllerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FLOATING = "FLOATING";
	static PROGRAMMABLE = "PROGRAMMABLE";
	static PROPORTIONAL = "PROPORTIONAL";
	static MULTIPOSITION = "MULTIPOSITION";
	static TWOPOSITION = "TWOPOSITION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCooledBeamTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ACTIVE = "ACTIVE";
	static PASSIVE = "PASSIVE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCoolingTowerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static NATURALDRAFT = "NATURALDRAFT";
	static MECHANICALINDUCEDDRAFT = "MECHANICALINDUCEDDRAFT";
	static MECHANICALFORCEDDRAFT = "MECHANICALFORCEDDRAFT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCostItemTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCostScheduleTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BUDGET = "BUDGET";
	static COSTPLAN = "COSTPLAN";
	static ESTIMATE = "ESTIMATE";
	static TENDER = "TENDER";
	static PRICEDBILLOFQUANTITIES = "PRICEDBILLOFQUANTITIES";
	static UNPRICEDBILLOFQUANTITIES = "UNPRICEDBILLOFQUANTITIES";
	static SCHEDULEOFRATES = "SCHEDULEOFRATES";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCoveringTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CEILING = "CEILING";
	static FLOORING = "FLOORING";
	static CLADDING = "CLADDING";
	static ROOFING = "ROOFING";
	static MOLDING = "MOLDING";
	static SKIRTINGBOARD = "SKIRTINGBOARD";
	static INSULATION = "INSULATION";
	static MEMBRANE = "MEMBRANE";
	static SLEEVING = "SLEEVING";
	static WRAPPING = "WRAPPING";
	static COPING = "COPING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCrewResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static OFFICE = "OFFICE";
	static SITE = "SITE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCurtainWallTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcCurveInterpolationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static LINEAR = "LINEAR";
	static LOG_LINEAR = "LOG_LINEAR";
	static LOG_LOG = "LOG_LOG";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDamperTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BACKDRAFTDAMPER = "BACKDRAFTDAMPER";
	static BALANCINGDAMPER = "BALANCINGDAMPER";
	static BLASTDAMPER = "BLASTDAMPER";
	static CONTROLDAMPER = "CONTROLDAMPER";
	static FIREDAMPER = "FIREDAMPER";
	static FIRESMOKEDAMPER = "FIRESMOKEDAMPER";
	static FUMEHOODEXHAUST = "FUMEHOODEXHAUST";
	static GRAVITYDAMPER = "GRAVITYDAMPER";
	static GRAVITYRELIEFDAMPER = "GRAVITYRELIEFDAMPER";
	static RELIEFDAMPER = "RELIEFDAMPER";
	static SMOKEDAMPER = "SMOKEDAMPER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDataOriginEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static MEASURED = "MEASURED";
	static PREDICTED = "PREDICTED";
	static SIMULATED = "SIMULATED";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDerivedUnitEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ANGULARVELOCITYUNIT = "ANGULARVELOCITYUNIT";
	static AREADENSITYUNIT = "AREADENSITYUNIT";
	static COMPOUNDPLANEANGLEUNIT = "COMPOUNDPLANEANGLEUNIT";
	static DYNAMICVISCOSITYUNIT = "DYNAMICVISCOSITYUNIT";
	static HEATFLUXDENSITYUNIT = "HEATFLUXDENSITYUNIT";
	static INTEGERCOUNTRATEUNIT = "INTEGERCOUNTRATEUNIT";
	static ISOTHERMALMOISTURECAPACITYUNIT = "ISOTHERMALMOISTURECAPACITYUNIT";
	static KINEMATICVISCOSITYUNIT = "KINEMATICVISCOSITYUNIT";
	static LINEARVELOCITYUNIT = "LINEARVELOCITYUNIT";
	static MASSDENSITYUNIT = "MASSDENSITYUNIT";
	static MASSFLOWRATEUNIT = "MASSFLOWRATEUNIT";
	static MOISTUREDIFFUSIVITYUNIT = "MOISTUREDIFFUSIVITYUNIT";
	static MOLECULARWEIGHTUNIT = "MOLECULARWEIGHTUNIT";
	static SPECIFICHEATCAPACITYUNIT = "SPECIFICHEATCAPACITYUNIT";
	static THERMALADMITTANCEUNIT = "THERMALADMITTANCEUNIT";
	static THERMALCONDUCTANCEUNIT = "THERMALCONDUCTANCEUNIT";
	static THERMALRESISTANCEUNIT = "THERMALRESISTANCEUNIT";
	static THERMALTRANSMITTANCEUNIT = "THERMALTRANSMITTANCEUNIT";
	static VAPORPERMEABILITYUNIT = "VAPORPERMEABILITYUNIT";
	static VOLUMETRICFLOWRATEUNIT = "VOLUMETRICFLOWRATEUNIT";
	static ROTATIONALFREQUENCYUNIT = "ROTATIONALFREQUENCYUNIT";
	static TORQUEUNIT = "TORQUEUNIT";
	static MOMENTOFINERTIAUNIT = "MOMENTOFINERTIAUNIT";
	static LINEARMOMENTUNIT = "LINEARMOMENTUNIT";
	static LINEARFORCEUNIT = "LINEARFORCEUNIT";
	static PLANARFORCEUNIT = "PLANARFORCEUNIT";
	static MODULUSOFELASTICITYUNIT = "MODULUSOFELASTICITYUNIT";
	static SHEARMODULUSUNIT = "SHEARMODULUSUNIT";
	static LINEARSTIFFNESSUNIT = "LINEARSTIFFNESSUNIT";
	static ROTATIONALSTIFFNESSUNIT = "ROTATIONALSTIFFNESSUNIT";
	static MODULUSOFSUBGRADEREACTIONUNIT = "MODULUSOFSUBGRADEREACTIONUNIT";
	static ACCELERATIONUNIT = "ACCELERATIONUNIT";
	static CURVATUREUNIT = "CURVATUREUNIT";
	static HEATINGVALUEUNIT = "HEATINGVALUEUNIT";
	static IONCONCENTRATIONUNIT = "IONCONCENTRATIONUNIT";
	static LUMINOUSINTENSITYDISTRIBUTIONUNIT = "LUMINOUSINTENSITYDISTRIBUTIONUNIT";
	static MASSPERLENGTHUNIT = "MASSPERLENGTHUNIT";
	static MODULUSOFLINEARSUBGRADEREACTIONUNIT = "MODULUSOFLINEARSUBGRADEREACTIONUNIT";
	static MODULUSOFROTATIONALSUBGRADEREACTIONUNIT = "MODULUSOFROTATIONALSUBGRADEREACTIONUNIT";
	static PHUNIT = "PHUNIT";
	static ROTATIONALMASSUNIT = "ROTATIONALMASSUNIT";
	static SECTIONAREAINTEGRALUNIT = "SECTIONAREAINTEGRALUNIT";
	static SECTIONMODULUSUNIT = "SECTIONMODULUSUNIT";
	static SOUNDPOWERLEVELUNIT = "SOUNDPOWERLEVELUNIT";
	static SOUNDPOWERUNIT = "SOUNDPOWERUNIT";
	static SOUNDPRESSURELEVELUNIT = "SOUNDPRESSURELEVELUNIT";
	static SOUNDPRESSUREUNIT = "SOUNDPRESSUREUNIT";
	static TEMPERATUREGRADIENTUNIT = "TEMPERATUREGRADIENTUNIT";
	static TEMPERATURERATEOFCHANGEUNIT = "TEMPERATURERATEOFCHANGEUNIT";
	static THERMALEXPANSIONCOEFFICIENTUNIT = "THERMALEXPANSIONCOEFFICIENTUNIT";
	static WARPINGCONSTANTUNIT = "WARPINGCONSTANTUNIT";
	static WARPINGMOMENTUNIT = "WARPINGMOMENTUNIT";
	static USERDEFINED = "USERDEFINED";
};
export class IfcDirectionSenseEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static POSITIVE = "POSITIVE";
	static NEGATIVE = "NEGATIVE";
};
export class IfcDiscreteAccessoryTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ANCHORPLATE = "ANCHORPLATE";
	static BRACKET = "BRACKET";
	static SHOE = "SHOE";
	static EXPANSION_JOINT_DEVICE = "EXPANSION_JOINT_DEVICE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDistributionChamberElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FORMEDDUCT = "FORMEDDUCT";
	static INSPECTIONCHAMBER = "INSPECTIONCHAMBER";
	static INSPECTIONPIT = "INSPECTIONPIT";
	static MANHOLE = "MANHOLE";
	static METERCHAMBER = "METERCHAMBER";
	static SUMP = "SUMP";
	static TRENCH = "TRENCH";
	static VALVECHAMBER = "VALVECHAMBER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDistributionPortTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CABLE = "CABLE";
	static CABLECARRIER = "CABLECARRIER";
	static DUCT = "DUCT";
	static PIPE = "PIPE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDistributionSystemEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRCONDITIONING = "AIRCONDITIONING";
	static AUDIOVISUAL = "AUDIOVISUAL";
	static CHEMICAL = "CHEMICAL";
	static CHILLEDWATER = "CHILLEDWATER";
	static COMMUNICATION = "COMMUNICATION";
	static COMPRESSEDAIR = "COMPRESSEDAIR";
	static CONDENSERWATER = "CONDENSERWATER";
	static CONTROL = "CONTROL";
	static CONVEYING = "CONVEYING";
	static DATA = "DATA";
	static DISPOSAL = "DISPOSAL";
	static DOMESTICCOLDWATER = "DOMESTICCOLDWATER";
	static DOMESTICHOTWATER = "DOMESTICHOTWATER";
	static DRAINAGE = "DRAINAGE";
	static EARTHING = "EARTHING";
	static ELECTRICAL = "ELECTRICAL";
	static ELECTROACOUSTIC = "ELECTROACOUSTIC";
	static EXHAUST = "EXHAUST";
	static FIREPROTECTION = "FIREPROTECTION";
	static FUEL = "FUEL";
	static GAS = "GAS";
	static HAZARDOUS = "HAZARDOUS";
	static HEATING = "HEATING";
	static LIGHTING = "LIGHTING";
	static LIGHTNINGPROTECTION = "LIGHTNINGPROTECTION";
	static MUNICIPALSOLIDWASTE = "MUNICIPALSOLIDWASTE";
	static OIL = "OIL";
	static OPERATIONAL = "OPERATIONAL";
	static POWERGENERATION = "POWERGENERATION";
	static RAINWATER = "RAINWATER";
	static REFRIGERATION = "REFRIGERATION";
	static SECURITY = "SECURITY";
	static SEWAGE = "SEWAGE";
	static SIGNAL = "SIGNAL";
	static STORMWATER = "STORMWATER";
	static TELEPHONE = "TELEPHONE";
	static TV = "TV";
	static VACUUM = "VACUUM";
	static VENT = "VENT";
	static VENTILATION = "VENTILATION";
	static WASTEWATER = "WASTEWATER";
	static WATERSUPPLY = "WATERSUPPLY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDocumentConfidentialityEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PUBLIC = "PUBLIC";
	static RESTRICTED = "RESTRICTED";
	static CONFIDENTIAL = "CONFIDENTIAL";
	static PERSONAL = "PERSONAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDocumentStatusEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DRAFT = "DRAFT";
	static FINALDRAFT = "FINALDRAFT";
	static FINAL = "FINAL";
	static REVISION = "REVISION";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorPanelOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SWINGING = "SWINGING";
	static DOUBLE_ACTING = "DOUBLE_ACTING";
	static SLIDING = "SLIDING";
	static FOLDING = "FOLDING";
	static REVOLVING = "REVOLVING";
	static ROLLINGUP = "ROLLINGUP";
	static FIXEDPANEL = "FIXEDPANEL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorPanelPositionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static LEFT = "LEFT";
	static MIDDLE = "MIDDLE";
	static RIGHT = "RIGHT";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorStyleConstructionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ALUMINIUM = "ALUMINIUM";
	static HIGH_GRADE_STEEL = "HIGH_GRADE_STEEL";
	static STEEL = "STEEL";
	static WOOD = "WOOD";
	static ALUMINIUM_WOOD = "ALUMINIUM_WOOD";
	static ALUMINIUM_PLASTIC = "ALUMINIUM_PLASTIC";
	static PLASTIC = "PLASTIC";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorStyleOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SINGLE_SWING_LEFT = "SINGLE_SWING_LEFT";
	static SINGLE_SWING_RIGHT = "SINGLE_SWING_RIGHT";
	static DOUBLE_DOOR_SINGLE_SWING = "DOUBLE_DOOR_SINGLE_SWING";
	static DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_LEFT = "DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_LEFT";
	static DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_RIGHT = "DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_RIGHT";
	static DOUBLE_SWING_LEFT = "DOUBLE_SWING_LEFT";
	static DOUBLE_SWING_RIGHT = "DOUBLE_SWING_RIGHT";
	static DOUBLE_DOOR_DOUBLE_SWING = "DOUBLE_DOOR_DOUBLE_SWING";
	static SLIDING_TO_LEFT = "SLIDING_TO_LEFT";
	static SLIDING_TO_RIGHT = "SLIDING_TO_RIGHT";
	static DOUBLE_DOOR_SLIDING = "DOUBLE_DOOR_SLIDING";
	static FOLDING_TO_LEFT = "FOLDING_TO_LEFT";
	static FOLDING_TO_RIGHT = "FOLDING_TO_RIGHT";
	static DOUBLE_DOOR_FOLDING = "DOUBLE_DOOR_FOLDING";
	static REVOLVING = "REVOLVING";
	static ROLLINGUP = "ROLLINGUP";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DOOR = "DOOR";
	static GATE = "GATE";
	static TRAPDOOR = "TRAPDOOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDoorTypeOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SINGLE_SWING_LEFT = "SINGLE_SWING_LEFT";
	static SINGLE_SWING_RIGHT = "SINGLE_SWING_RIGHT";
	static DOUBLE_DOOR_SINGLE_SWING = "DOUBLE_DOOR_SINGLE_SWING";
	static DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_LEFT = "DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_LEFT";
	static DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_RIGHT = "DOUBLE_DOOR_SINGLE_SWING_OPPOSITE_RIGHT";
	static DOUBLE_SWING_LEFT = "DOUBLE_SWING_LEFT";
	static DOUBLE_SWING_RIGHT = "DOUBLE_SWING_RIGHT";
	static DOUBLE_DOOR_DOUBLE_SWING = "DOUBLE_DOOR_DOUBLE_SWING";
	static SLIDING_TO_LEFT = "SLIDING_TO_LEFT";
	static SLIDING_TO_RIGHT = "SLIDING_TO_RIGHT";
	static DOUBLE_DOOR_SLIDING = "DOUBLE_DOOR_SLIDING";
	static FOLDING_TO_LEFT = "FOLDING_TO_LEFT";
	static FOLDING_TO_RIGHT = "FOLDING_TO_RIGHT";
	static DOUBLE_DOOR_FOLDING = "DOUBLE_DOOR_FOLDING";
	static REVOLVING = "REVOLVING";
	static ROLLINGUP = "ROLLINGUP";
	static SWING_FIXED_LEFT = "SWING_FIXED_LEFT";
	static SWING_FIXED_RIGHT = "SWING_FIXED_RIGHT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDuctFittingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BEND = "BEND";
	static CONNECTOR = "CONNECTOR";
	static ENTRY = "ENTRY";
	static EXIT = "EXIT";
	static JUNCTION = "JUNCTION";
	static OBSTRUCTION = "OBSTRUCTION";
	static TRANSITION = "TRANSITION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDuctSegmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static RIGIDSEGMENT = "RIGIDSEGMENT";
	static FLEXIBLESEGMENT = "FLEXIBLESEGMENT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcDuctSilencerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FLATOVAL = "FLATOVAL";
	static RECTANGULAR = "RECTANGULAR";
	static ROUND = "ROUND";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricApplianceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DISHWASHER = "DISHWASHER";
	static ELECTRICCOOKER = "ELECTRICCOOKER";
	static FREESTANDINGELECTRICHEATER = "FREESTANDINGELECTRICHEATER";
	static FREESTANDINGFAN = "FREESTANDINGFAN";
	static FREESTANDINGWATERHEATER = "FREESTANDINGWATERHEATER";
	static FREESTANDINGWATERCOOLER = "FREESTANDINGWATERCOOLER";
	static FREEZER = "FREEZER";
	static FRIDGE_FREEZER = "FRIDGE_FREEZER";
	static HANDDRYER = "HANDDRYER";
	static KITCHENMACHINE = "KITCHENMACHINE";
	static MICROWAVE = "MICROWAVE";
	static PHOTOCOPIER = "PHOTOCOPIER";
	static REFRIGERATOR = "REFRIGERATOR";
	static TUMBLEDRYER = "TUMBLEDRYER";
	static VENDINGMACHINE = "VENDINGMACHINE";
	static WASHINGMACHINE = "WASHINGMACHINE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricDistributionBoardTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONSUMERUNIT = "CONSUMERUNIT";
	static DISTRIBUTIONBOARD = "DISTRIBUTIONBOARD";
	static MOTORCONTROLCENTRE = "MOTORCONTROLCENTRE";
	static SWITCHBOARD = "SWITCHBOARD";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricFlowStorageDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BATTERY = "BATTERY";
	static CAPACITORBANK = "CAPACITORBANK";
	static HARMONICFILTER = "HARMONICFILTER";
	static INDUCTORBANK = "INDUCTORBANK";
	static UPS = "UPS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricGeneratorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CHP = "CHP";
	static ENGINEGENERATOR = "ENGINEGENERATOR";
	static STANDALONE = "STANDALONE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricMotorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DC = "DC";
	static INDUCTION = "INDUCTION";
	static POLYPHASE = "POLYPHASE";
	static RELUCTANCESYNCHRONOUS = "RELUCTANCESYNCHRONOUS";
	static SYNCHRONOUS = "SYNCHRONOUS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElectricTimeControlTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static TIMECLOCK = "TIMECLOCK";
	static TIMEDELAY = "TIMEDELAY";
	static RELAY = "RELAY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElementAssemblyTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ACCESSORY_ASSEMBLY = "ACCESSORY_ASSEMBLY";
	static ARCH = "ARCH";
	static BEAM_GRID = "BEAM_GRID";
	static BRACED_FRAME = "BRACED_FRAME";
	static GIRDER = "GIRDER";
	static REINFORCEMENT_UNIT = "REINFORCEMENT_UNIT";
	static RIGID_FRAME = "RIGID_FRAME";
	static SLAB_FIELD = "SLAB_FIELD";
	static TRUSS = "TRUSS";
	static ABUTMENT = "ABUTMENT";
	static PIER = "PIER";
	static PYLON = "PYLON";
	static CROSS_BRACING = "CROSS_BRACING";
	static DECK = "DECK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcElementCompositionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COMPLEX = "COMPLEX";
	static ELEMENT = "ELEMENT";
	static PARTIAL = "PARTIAL";
};
export class IfcEngineTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static EXTERNALCOMBUSTION = "EXTERNALCOMBUSTION";
	static INTERNALCOMBUSTION = "INTERNALCOMBUSTION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcEvaporativeCoolerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DIRECTEVAPORATIVERANDOMMEDIAAIRCOOLER = "DIRECTEVAPORATIVERANDOMMEDIAAIRCOOLER";
	static DIRECTEVAPORATIVERIGIDMEDIAAIRCOOLER = "DIRECTEVAPORATIVERIGIDMEDIAAIRCOOLER";
	static DIRECTEVAPORATIVESLINGERSPACKAGEDAIRCOOLER = "DIRECTEVAPORATIVESLINGERSPACKAGEDAIRCOOLER";
	static DIRECTEVAPORATIVEPACKAGEDROTARYAIRCOOLER = "DIRECTEVAPORATIVEPACKAGEDROTARYAIRCOOLER";
	static DIRECTEVAPORATIVEAIRWASHER = "DIRECTEVAPORATIVEAIRWASHER";
	static INDIRECTEVAPORATIVEPACKAGEAIRCOOLER = "INDIRECTEVAPORATIVEPACKAGEAIRCOOLER";
	static INDIRECTEVAPORATIVEWETCOIL = "INDIRECTEVAPORATIVEWETCOIL";
	static INDIRECTEVAPORATIVECOOLINGTOWERORCOILCOOLER = "INDIRECTEVAPORATIVECOOLINGTOWERORCOILCOOLER";
	static INDIRECTDIRECTCOMBINATION = "INDIRECTDIRECTCOMBINATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcEvaporatorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DIRECTEXPANSION = "DIRECTEXPANSION";
	static DIRECTEXPANSIONSHELLANDTUBE = "DIRECTEXPANSIONSHELLANDTUBE";
	static DIRECTEXPANSIONTUBEINTUBE = "DIRECTEXPANSIONTUBEINTUBE";
	static DIRECTEXPANSIONBRAZEDPLATE = "DIRECTEXPANSIONBRAZEDPLATE";
	static FLOODEDSHELLANDTUBE = "FLOODEDSHELLANDTUBE";
	static SHELLANDCOIL = "SHELLANDCOIL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcEventTriggerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static EVENTRULE = "EVENTRULE";
	static EVENTMESSAGE = "EVENTMESSAGE";
	static EVENTTIME = "EVENTTIME";
	static EVENTCOMPLEX = "EVENTCOMPLEX";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcEventTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STARTEVENT = "STARTEVENT";
	static ENDEVENT = "ENDEVENT";
	static INTERMEDIATEEVENT = "INTERMEDIATEEVENT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcExternalSpatialElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static EXTERNAL = "EXTERNAL";
	static EXTERNAL_EARTH = "EXTERNAL_EARTH";
	static EXTERNAL_WATER = "EXTERNAL_WATER";
	static EXTERNAL_FIRE = "EXTERNAL_FIRE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFanTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CENTRIFUGALFORWARDCURVED = "CENTRIFUGALFORWARDCURVED";
	static CENTRIFUGALRADIAL = "CENTRIFUGALRADIAL";
	static CENTRIFUGALBACKWARDINCLINEDCURVED = "CENTRIFUGALBACKWARDINCLINEDCURVED";
	static CENTRIFUGALAIRFOIL = "CENTRIFUGALAIRFOIL";
	static TUBEAXIAL = "TUBEAXIAL";
	static VANEAXIAL = "VANEAXIAL";
	static PROPELLORAXIAL = "PROPELLORAXIAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFastenerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static GLUE = "GLUE";
	static MORTAR = "MORTAR";
	static WELD = "WELD";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFilterTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRPARTICLEFILTER = "AIRPARTICLEFILTER";
	static COMPRESSEDAIRFILTER = "COMPRESSEDAIRFILTER";
	static ODORFILTER = "ODORFILTER";
	static OILFILTER = "OILFILTER";
	static STRAINER = "STRAINER";
	static WATERFILTER = "WATERFILTER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFireSuppressionTerminalTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BREECHINGINLET = "BREECHINGINLET";
	static FIREHYDRANT = "FIREHYDRANT";
	static HOSEREEL = "HOSEREEL";
	static SPRINKLER = "SPRINKLER";
	static SPRINKLERDEFLECTOR = "SPRINKLERDEFLECTOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFlowDirectionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SOURCE = "SOURCE";
	static SINK = "SINK";
	static SOURCEANDSINK = "SOURCEANDSINK";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFlowInstrumentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PRESSUREGAUGE = "PRESSUREGAUGE";
	static THERMOMETER = "THERMOMETER";
	static AMMETER = "AMMETER";
	static FREQUENCYMETER = "FREQUENCYMETER";
	static POWERFACTORMETER = "POWERFACTORMETER";
	static PHASEANGLEMETER = "PHASEANGLEMETER";
	static VOLTMETER_PEAK = "VOLTMETER_PEAK";
	static VOLTMETER_RMS = "VOLTMETER_RMS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFlowMeterTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ENERGYMETER = "ENERGYMETER";
	static GASMETER = "GASMETER";
	static OILMETER = "OILMETER";
	static WATERMETER = "WATERMETER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFootingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CAISSON_FOUNDATION = "CAISSON_FOUNDATION";
	static FOOTING_BEAM = "FOOTING_BEAM";
	static PAD_FOOTING = "PAD_FOOTING";
	static PILE_CAP = "PILE_CAP";
	static STRIP_FOOTING = "STRIP_FOOTING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcFurnitureTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CHAIR = "CHAIR";
	static TABLE = "TABLE";
	static DESK = "DESK";
	static BED = "BED";
	static FILECABINET = "FILECABINET";
	static SHELF = "SHELF";
	static SOFA = "SOFA";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcGeographicElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static TERRAIN = "TERRAIN";
	static SOIL_BORING_POINT = "SOIL_BORING_POINT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcGeometricProjectionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static GRAPH_VIEW = "GRAPH_VIEW";
	static SKETCH_VIEW = "SKETCH_VIEW";
	static MODEL_VIEW = "MODEL_VIEW";
	static PLAN_VIEW = "PLAN_VIEW";
	static REFLECTED_PLAN_VIEW = "REFLECTED_PLAN_VIEW";
	static SECTION_VIEW = "SECTION_VIEW";
	static ELEVATION_VIEW = "ELEVATION_VIEW";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcGlobalOrLocalEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static GLOBAL_COORDS = "GLOBAL_COORDS";
	static LOCAL_COORDS = "LOCAL_COORDS";
};
export class IfcGridTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static RECTANGULAR = "RECTANGULAR";
	static RADIAL = "RADIAL";
	static TRIANGULAR = "TRIANGULAR";
	static IRREGULAR = "IRREGULAR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcHeatExchangerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PLATE = "PLATE";
	static SHELLANDTUBE = "SHELLANDTUBE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcHumidifierTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STEAMINJECTION = "STEAMINJECTION";
	static ADIABATICAIRWASHER = "ADIABATICAIRWASHER";
	static ADIABATICPAN = "ADIABATICPAN";
	static ADIABATICWETTEDELEMENT = "ADIABATICWETTEDELEMENT";
	static ADIABATICATOMIZING = "ADIABATICATOMIZING";
	static ADIABATICULTRASONIC = "ADIABATICULTRASONIC";
	static ADIABATICRIGIDMEDIA = "ADIABATICRIGIDMEDIA";
	static ADIABATICCOMPRESSEDAIRNOZZLE = "ADIABATICCOMPRESSEDAIRNOZZLE";
	static ASSISTEDELECTRIC = "ASSISTEDELECTRIC";
	static ASSISTEDNATURALGAS = "ASSISTEDNATURALGAS";
	static ASSISTEDPROPANE = "ASSISTEDPROPANE";
	static ASSISTEDBUTANE = "ASSISTEDBUTANE";
	static ASSISTEDSTEAM = "ASSISTEDSTEAM";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcInterceptorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CYCLONIC = "CYCLONIC";
	static GREASE = "GREASE";
	static OIL = "OIL";
	static PETROL = "PETROL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcInternalOrExternalEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static INTERNAL = "INTERNAL";
	static EXTERNAL = "EXTERNAL";
	static EXTERNAL_EARTH = "EXTERNAL_EARTH";
	static EXTERNAL_WATER = "EXTERNAL_WATER";
	static EXTERNAL_FIRE = "EXTERNAL_FIRE";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcInventoryTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ASSETINVENTORY = "ASSETINVENTORY";
	static SPACEINVENTORY = "SPACEINVENTORY";
	static FURNITUREINVENTORY = "FURNITUREINVENTORY";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcJunctionBoxTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DATA = "DATA";
	static POWER = "POWER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcKnotType {
	value: string;
	constructor(v: string) { this.value = v;}
	static UNIFORM_KNOTS = "UNIFORM_KNOTS";
	static QUASI_UNIFORM_KNOTS = "QUASI_UNIFORM_KNOTS";
	static PIECEWISE_BEZIER_KNOTS = "PIECEWISE_BEZIER_KNOTS";
	static UNSPECIFIED = "UNSPECIFIED";
};
export class IfcLaborResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ADMINISTRATION = "ADMINISTRATION";
	static CARPENTRY = "CARPENTRY";
	static CLEANING = "CLEANING";
	static CONCRETE = "CONCRETE";
	static DRYWALL = "DRYWALL";
	static ELECTRIC = "ELECTRIC";
	static FINISHING = "FINISHING";
	static FLOORING = "FLOORING";
	static GENERAL = "GENERAL";
	static HVAC = "HVAC";
	static LANDSCAPING = "LANDSCAPING";
	static MASONRY = "MASONRY";
	static PAINTING = "PAINTING";
	static PAVING = "PAVING";
	static PLUMBING = "PLUMBING";
	static ROOFING = "ROOFING";
	static SITEGRADING = "SITEGRADING";
	static STEELWORK = "STEELWORK";
	static SURVEYING = "SURVEYING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLampTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COMPACTFLUORESCENT = "COMPACTFLUORESCENT";
	static FLUORESCENT = "FLUORESCENT";
	static HALOGEN = "HALOGEN";
	static HIGHPRESSUREMERCURY = "HIGHPRESSUREMERCURY";
	static HIGHPRESSURESODIUM = "HIGHPRESSURESODIUM";
	static LED = "LED";
	static METALHALIDE = "METALHALIDE";
	static OLED = "OLED";
	static TUNGSTENFILAMENT = "TUNGSTENFILAMENT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLayerSetDirectionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AXIS1 = "AXIS1";
	static AXIS2 = "AXIS2";
	static AXIS3 = "AXIS3";
};
export class IfcLightDistributionCurveEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static TYPE_A = "TYPE_A";
	static TYPE_B = "TYPE_B";
	static TYPE_C = "TYPE_C";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLightEmissionSourceEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COMPACTFLUORESCENT = "COMPACTFLUORESCENT";
	static FLUORESCENT = "FLUORESCENT";
	static HIGHPRESSUREMERCURY = "HIGHPRESSUREMERCURY";
	static HIGHPRESSURESODIUM = "HIGHPRESSURESODIUM";
	static LIGHTEMITTINGDIODE = "LIGHTEMITTINGDIODE";
	static LOWPRESSURESODIUM = "LOWPRESSURESODIUM";
	static LOWVOLTAGEHALOGEN = "LOWVOLTAGEHALOGEN";
	static MAINVOLTAGEHALOGEN = "MAINVOLTAGEHALOGEN";
	static METALHALIDE = "METALHALIDE";
	static TUNGSTENFILAMENT = "TUNGSTENFILAMENT";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLightFixtureTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static POINTSOURCE = "POINTSOURCE";
	static DIRECTIONSOURCE = "DIRECTIONSOURCE";
	static SECURITYLIGHTING = "SECURITYLIGHTING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLoadGroupTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static LOAD_GROUP = "LOAD_GROUP";
	static LOAD_CASE = "LOAD_CASE";
	static LOAD_COMBINATION = "LOAD_COMBINATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcLogicalOperatorEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static LOGICALAND = "LOGICALAND";
	static LOGICALOR = "LOGICALOR";
	static LOGICALXOR = "LOGICALXOR";
	static LOGICALNOTAND = "LOGICALNOTAND";
	static LOGICALNOTOR = "LOGICALNOTOR";
};
export class IfcMechanicalFastenerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ANCHORBOLT = "ANCHORBOLT";
	static BOLT = "BOLT";
	static DOWEL = "DOWEL";
	static NAIL = "NAIL";
	static NAILPLATE = "NAILPLATE";
	static RIVET = "RIVET";
	static SCREW = "SCREW";
	static SHEARCONNECTOR = "SHEARCONNECTOR";
	static STAPLE = "STAPLE";
	static STUDSHEARCONNECTOR = "STUDSHEARCONNECTOR";
	static COUPLER = "COUPLER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcMedicalDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRSTATION = "AIRSTATION";
	static FEEDAIRUNIT = "FEEDAIRUNIT";
	static OXYGENGENERATOR = "OXYGENGENERATOR";
	static OXYGENPLANT = "OXYGENPLANT";
	static VACUUMSTATION = "VACUUMSTATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcMemberTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BRACE = "BRACE";
	static CHORD = "CHORD";
	static COLLAR = "COLLAR";
	static MEMBER = "MEMBER";
	static MULLION = "MULLION";
	static PLATE = "PLATE";
	static POST = "POST";
	static PURLIN = "PURLIN";
	static RAFTER = "RAFTER";
	static STRINGER = "STRINGER";
	static STRUT = "STRUT";
	static STUD = "STUD";
	static STIFFENING_RIB = "STIFFENING_RIB";
	static ARCH_SEGMENT = "ARCH_SEGMENT";
	static SUSPENSION_CABLE = "SUSPENSION_CABLE";
	static SUSPENDER = "SUSPENDER";
	static STAY_CABLE = "STAY_CABLE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcMotorConnectionTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BELTDRIVE = "BELTDRIVE";
	static COUPLING = "COUPLING";
	static DIRECTDRIVE = "DIRECTDRIVE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcNullStyle {
	value: string;
	constructor(v: string) { this.value = v;}
	static NULL = "NULL";
};
export class IfcObjectTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PRODUCT = "PRODUCT";
	static PROCESS = "PROCESS";
	static CONTROL = "CONTROL";
	static RESOURCE = "RESOURCE";
	static ACTOR = "ACTOR";
	static GROUP = "GROUP";
	static PROJECT = "PROJECT";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcObjectiveEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CODECOMPLIANCE = "CODECOMPLIANCE";
	static CODEWAIVER = "CODEWAIVER";
	static DESIGNINTENT = "DESIGNINTENT";
	static EXTERNAL = "EXTERNAL";
	static HEALTHANDSAFETY = "HEALTHANDSAFETY";
	static MERGECONFLICT = "MERGECONFLICT";
	static MODELVIEW = "MODELVIEW";
	static PARAMETER = "PARAMETER";
	static REQUIREMENT = "REQUIREMENT";
	static SPECIFICATION = "SPECIFICATION";
	static TRIGGERCONDITION = "TRIGGERCONDITION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcOccupantTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ASSIGNEE = "ASSIGNEE";
	static ASSIGNOR = "ASSIGNOR";
	static LESSEE = "LESSEE";
	static LESSOR = "LESSOR";
	static LETTINGAGENT = "LETTINGAGENT";
	static OWNER = "OWNER";
	static TENANT = "TENANT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcOpeningElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static OPENING = "OPENING";
	static RECESS = "RECESS";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcOutletTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AUDIOVISUALOUTLET = "AUDIOVISUALOUTLET";
	static COMMUNICATIONSOUTLET = "COMMUNICATIONSOUTLET";
	static POWEROUTLET = "POWEROUTLET";
	static DATAOUTLET = "DATAOUTLET";
	static TELEPHONEOUTLET = "TELEPHONEOUTLET";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPerformanceHistoryTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPermeableCoveringOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static GRILL = "GRILL";
	static LOUVER = "LOUVER";
	static SCREEN = "SCREEN";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPermitTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ACCESS = "ACCESS";
	static BUILDING = "BUILDING";
	static WORK = "WORK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPhysicalOrVirtualEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PHYSICAL = "PHYSICAL";
	static VIRTUAL = "VIRTUAL";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPileConstructionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CAST_IN_PLACE = "CAST_IN_PLACE";
	static COMPOSITE = "COMPOSITE";
	static PRECAST_CONCRETE = "PRECAST_CONCRETE";
	static PREFAB_STEEL = "PREFAB_STEEL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPileTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BORED = "BORED";
	static DRIVEN = "DRIVEN";
	static JETGROUTING = "JETGROUTING";
	static COHESION = "COHESION";
	static FRICTION = "FRICTION";
	static SUPPORT = "SUPPORT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPipeFittingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BEND = "BEND";
	static CONNECTOR = "CONNECTOR";
	static ENTRY = "ENTRY";
	static EXIT = "EXIT";
	static JUNCTION = "JUNCTION";
	static OBSTRUCTION = "OBSTRUCTION";
	static TRANSITION = "TRANSITION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPipeSegmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CULVERT = "CULVERT";
	static FLEXIBLESEGMENT = "FLEXIBLESEGMENT";
	static RIGIDSEGMENT = "RIGIDSEGMENT";
	static GUTTER = "GUTTER";
	static SPOOL = "SPOOL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPlateTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CURTAIN_PANEL = "CURTAIN_PANEL";
	static SHEET = "SHEET";
	static FLANGE_PLATE = "FLANGE_PLATE";
	static WEB_PLATE = "WEB_PLATE";
	static STIFFENER_PLATE = "STIFFENER_PLATE";
	static GUSSET_PLATE = "GUSSET_PLATE";
	static COVER_PLATE = "COVER_PLATE";
	static SPLICE_PLATE = "SPLICE_PLATE";
	static BASE_PLATE = "BASE_PLATE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPreferredSurfaceCurveRepresentation {
	value: string;
	constructor(v: string) { this.value = v;}
	static CURVE3D = "CURVE3D";
	static PCURVE_S1 = "PCURVE_S1";
	static PCURVE_S2 = "PCURVE_S2";
};
export class IfcProcedureTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ADVICE_CAUTION = "ADVICE_CAUTION";
	static ADVICE_NOTE = "ADVICE_NOTE";
	static ADVICE_WARNING = "ADVICE_WARNING";
	static CALIBRATION = "CALIBRATION";
	static DIAGNOSTIC = "DIAGNOSTIC";
	static SHUTDOWN = "SHUTDOWN";
	static STARTUP = "STARTUP";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcProfileTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CURVE = "CURVE";
	static AREA = "AREA";
};
export class IfcProjectOrderTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CHANGEORDER = "CHANGEORDER";
	static MAINTENANCEWORKORDER = "MAINTENANCEWORKORDER";
	static MOVEORDER = "MOVEORDER";
	static PURCHASEORDER = "PURCHASEORDER";
	static WORKORDER = "WORKORDER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcProjectedOrTrueLengthEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PROJECTED_LENGTH = "PROJECTED_LENGTH";
	static TRUE_LENGTH = "TRUE_LENGTH";
};
export class IfcProjectionElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BLISTER = "BLISTER";
	static DEVIATOR = "DEVIATOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPropertySetTemplateTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PSET_TYPEDRIVENONLY = "PSET_TYPEDRIVENONLY";
	static PSET_TYPEDRIVENOVERRIDE = "PSET_TYPEDRIVENOVERRIDE";
	static PSET_OCCURRENCEDRIVEN = "PSET_OCCURRENCEDRIVEN";
	static PSET_PERFORMANCEDRIVEN = "PSET_PERFORMANCEDRIVEN";
	static QTO_TYPEDRIVENONLY = "QTO_TYPEDRIVENONLY";
	static QTO_TYPEDRIVENOVERRIDE = "QTO_TYPEDRIVENOVERRIDE";
	static QTO_OCCURRENCEDRIVEN = "QTO_OCCURRENCEDRIVEN";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcProtectiveDeviceTrippingUnitTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ELECTRONIC = "ELECTRONIC";
	static ELECTROMAGNETIC = "ELECTROMAGNETIC";
	static RESIDUALCURRENT = "RESIDUALCURRENT";
	static THERMAL = "THERMAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcProtectiveDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CIRCUITBREAKER = "CIRCUITBREAKER";
	static EARTHLEAKAGECIRCUITBREAKER = "EARTHLEAKAGECIRCUITBREAKER";
	static EARTHINGSWITCH = "EARTHINGSWITCH";
	static FUSEDISCONNECTOR = "FUSEDISCONNECTOR";
	static RESIDUALCURRENTCIRCUITBREAKER = "RESIDUALCURRENTCIRCUITBREAKER";
	static RESIDUALCURRENTSWITCH = "RESIDUALCURRENTSWITCH";
	static VARISTOR = "VARISTOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcPumpTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CIRCULATOR = "CIRCULATOR";
	static ENDSUCTION = "ENDSUCTION";
	static SPLITCASE = "SPLITCASE";
	static SUBMERSIBLEPUMP = "SUBMERSIBLEPUMP";
	static SUMPPUMP = "SUMPPUMP";
	static VERTICALINLINE = "VERTICALINLINE";
	static VERTICALTURBINE = "VERTICALTURBINE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcRailingTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static HANDRAIL = "HANDRAIL";
	static GUARDRAIL = "GUARDRAIL";
	static BALUSTRADE = "BALUSTRADE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcRampFlightTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STRAIGHT = "STRAIGHT";
	static SPIRAL = "SPIRAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcRampTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STRAIGHT_RUN_RAMP = "STRAIGHT_RUN_RAMP";
	static TWO_STRAIGHT_RUN_RAMP = "TWO_STRAIGHT_RUN_RAMP";
	static QUARTER_TURN_RAMP = "QUARTER_TURN_RAMP";
	static TWO_QUARTER_TURN_RAMP = "TWO_QUARTER_TURN_RAMP";
	static HALF_TURN_RAMP = "HALF_TURN_RAMP";
	static SPIRAL_RAMP = "SPIRAL_RAMP";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcRecurrenceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DAILY = "DAILY";
	static WEEKLY = "WEEKLY";
	static MONTHLY_BY_DAY_OF_MONTH = "MONTHLY_BY_DAY_OF_MONTH";
	static MONTHLY_BY_POSITION = "MONTHLY_BY_POSITION";
	static BY_DAY_COUNT = "BY_DAY_COUNT";
	static BY_WEEKDAY_COUNT = "BY_WEEKDAY_COUNT";
	static YEARLY_BY_DAY_OF_MONTH = "YEARLY_BY_DAY_OF_MONTH";
	static YEARLY_BY_POSITION = "YEARLY_BY_POSITION";
};
export class IfcReferentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static KILOPOINT = "KILOPOINT";
	static MILEPOINT = "MILEPOINT";
	static STATION = "STATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcReflectanceMethodEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BLINN = "BLINN";
	static FLAT = "FLAT";
	static GLASS = "GLASS";
	static MATT = "MATT";
	static METAL = "METAL";
	static MIRROR = "MIRROR";
	static PHONG = "PHONG";
	static PLASTIC = "PLASTIC";
	static STRAUSS = "STRAUSS";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcReinforcingBarRoleEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static MAIN = "MAIN";
	static SHEAR = "SHEAR";
	static LIGATURE = "LIGATURE";
	static STUD = "STUD";
	static PUNCHING = "PUNCHING";
	static EDGE = "EDGE";
	static RING = "RING";
	static ANCHORING = "ANCHORING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcReinforcingBarSurfaceEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PLAIN = "PLAIN";
	static TEXTURED = "TEXTURED";
};
export class IfcReinforcingBarTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ANCHORING = "ANCHORING";
	static EDGE = "EDGE";
	static LIGATURE = "LIGATURE";
	static MAIN = "MAIN";
	static PUNCHING = "PUNCHING";
	static RING = "RING";
	static SHEAR = "SHEAR";
	static STUD = "STUD";
	static SPACEBAR = "SPACEBAR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcReinforcingMeshTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcRoleEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SUPPLIER = "SUPPLIER";
	static MANUFACTURER = "MANUFACTURER";
	static CONTRACTOR = "CONTRACTOR";
	static SUBCONTRACTOR = "SUBCONTRACTOR";
	static ARCHITECT = "ARCHITECT";
	static STRUCTURALENGINEER = "STRUCTURALENGINEER";
	static COSTENGINEER = "COSTENGINEER";
	static CLIENT = "CLIENT";
	static BUILDINGOWNER = "BUILDINGOWNER";
	static BUILDINGOPERATOR = "BUILDINGOPERATOR";
	static MECHANICALENGINEER = "MECHANICALENGINEER";
	static ELECTRICALENGINEER = "ELECTRICALENGINEER";
	static PROJECTMANAGER = "PROJECTMANAGER";
	static FACILITIESMANAGER = "FACILITIESMANAGER";
	static CIVILENGINEER = "CIVILENGINEER";
	static COMMISSIONINGENGINEER = "COMMISSIONINGENGINEER";
	static ENGINEER = "ENGINEER";
	static OWNER = "OWNER";
	static CONSULTANT = "CONSULTANT";
	static CONSTRUCTIONMANAGER = "CONSTRUCTIONMANAGER";
	static FIELDCONSTRUCTIONMANAGER = "FIELDCONSTRUCTIONMANAGER";
	static RESELLER = "RESELLER";
	static USERDEFINED = "USERDEFINED";
};
export class IfcRoofTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FLAT_ROOF = "FLAT_ROOF";
	static SHED_ROOF = "SHED_ROOF";
	static GABLE_ROOF = "GABLE_ROOF";
	static HIP_ROOF = "HIP_ROOF";
	static HIPPED_GABLE_ROOF = "HIPPED_GABLE_ROOF";
	static GAMBREL_ROOF = "GAMBREL_ROOF";
	static MANSARD_ROOF = "MANSARD_ROOF";
	static BARREL_ROOF = "BARREL_ROOF";
	static RAINBOW_ROOF = "RAINBOW_ROOF";
	static BUTTERFLY_ROOF = "BUTTERFLY_ROOF";
	static PAVILION_ROOF = "PAVILION_ROOF";
	static DOME_ROOF = "DOME_ROOF";
	static FREEFORM = "FREEFORM";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSIPrefix {
	value: string;
	constructor(v: string) { this.value = v;}
	static EXA = "EXA";
	static PETA = "PETA";
	static TERA = "TERA";
	static GIGA = "GIGA";
	static MEGA = "MEGA";
	static KILO = "KILO";
	static HECTO = "HECTO";
	static DECA = "DECA";
	static DECI = "DECI";
	static CENTI = "CENTI";
	static MILLI = "MILLI";
	static MICRO = "MICRO";
	static NANO = "NANO";
	static PICO = "PICO";
	static FEMTO = "FEMTO";
	static ATTO = "ATTO";
};
export class IfcSIUnitName {
	value: string;
	constructor(v: string) { this.value = v;}
	static AMPERE = "AMPERE";
	static BECQUEREL = "BECQUEREL";
	static CANDELA = "CANDELA";
	static COULOMB = "COULOMB";
	static CUBIC_METRE = "CUBIC_METRE";
	static DEGREE_CELSIUS = "DEGREE_CELSIUS";
	static FARAD = "FARAD";
	static GRAM = "GRAM";
	static GRAY = "GRAY";
	static HENRY = "HENRY";
	static HERTZ = "HERTZ";
	static JOULE = "JOULE";
	static KELVIN = "KELVIN";
	static LUMEN = "LUMEN";
	static LUX = "LUX";
	static METRE = "METRE";
	static MOLE = "MOLE";
	static NEWTON = "NEWTON";
	static OHM = "OHM";
	static PASCAL = "PASCAL";
	static RADIAN = "RADIAN";
	static SECOND = "SECOND";
	static SIEMENS = "SIEMENS";
	static SIEVERT = "SIEVERT";
	static SQUARE_METRE = "SQUARE_METRE";
	static STERADIAN = "STERADIAN";
	static TESLA = "TESLA";
	static VOLT = "VOLT";
	static WATT = "WATT";
	static WEBER = "WEBER";
};
export class IfcSanitaryTerminalTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BATH = "BATH";
	static BIDET = "BIDET";
	static CISTERN = "CISTERN";
	static SHOWER = "SHOWER";
	static SINK = "SINK";
	static SANITARYFOUNTAIN = "SANITARYFOUNTAIN";
	static TOILETPAN = "TOILETPAN";
	static URINAL = "URINAL";
	static WASHHANDBASIN = "WASHHANDBASIN";
	static WCSEAT = "WCSEAT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSectionTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static UNIFORM = "UNIFORM";
	static TAPERED = "TAPERED";
};
export class IfcSensorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COSENSOR = "COSENSOR";
	static CO2SENSOR = "CO2SENSOR";
	static CONDUCTANCESENSOR = "CONDUCTANCESENSOR";
	static CONTACTSENSOR = "CONTACTSENSOR";
	static FIRESENSOR = "FIRESENSOR";
	static FLOWSENSOR = "FLOWSENSOR";
	static FROSTSENSOR = "FROSTSENSOR";
	static GASSENSOR = "GASSENSOR";
	static HEATSENSOR = "HEATSENSOR";
	static HUMIDITYSENSOR = "HUMIDITYSENSOR";
	static IDENTIFIERSENSOR = "IDENTIFIERSENSOR";
	static IONCONCENTRATIONSENSOR = "IONCONCENTRATIONSENSOR";
	static LEVELSENSOR = "LEVELSENSOR";
	static LIGHTSENSOR = "LIGHTSENSOR";
	static MOISTURESENSOR = "MOISTURESENSOR";
	static MOVEMENTSENSOR = "MOVEMENTSENSOR";
	static PHSENSOR = "PHSENSOR";
	static PRESSURESENSOR = "PRESSURESENSOR";
	static RADIATIONSENSOR = "RADIATIONSENSOR";
	static RADIOACTIVITYSENSOR = "RADIOACTIVITYSENSOR";
	static SMOKESENSOR = "SMOKESENSOR";
	static SOUNDSENSOR = "SOUNDSENSOR";
	static TEMPERATURESENSOR = "TEMPERATURESENSOR";
	static WINDSENSOR = "WINDSENSOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSequenceEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static START_START = "START_START";
	static START_FINISH = "START_FINISH";
	static FINISH_START = "FINISH_START";
	static FINISH_FINISH = "FINISH_FINISH";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcShadingDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static JALOUSIE = "JALOUSIE";
	static SHUTTER = "SHUTTER";
	static AWNING = "AWNING";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSimplePropertyTemplateTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static P_SINGLEVALUE = "P_SINGLEVALUE";
	static P_ENUMERATEDVALUE = "P_ENUMERATEDVALUE";
	static P_BOUNDEDVALUE = "P_BOUNDEDVALUE";
	static P_LISTVALUE = "P_LISTVALUE";
	static P_TABLEVALUE = "P_TABLEVALUE";
	static P_REFERENCEVALUE = "P_REFERENCEVALUE";
	static Q_LENGTH = "Q_LENGTH";
	static Q_AREA = "Q_AREA";
	static Q_VOLUME = "Q_VOLUME";
	static Q_COUNT = "Q_COUNT";
	static Q_WEIGHT = "Q_WEIGHT";
	static Q_TIME = "Q_TIME";
};
export class IfcSlabTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FLOOR = "FLOOR";
	static ROOF = "ROOF";
	static LANDING = "LANDING";
	static BASESLAB = "BASESLAB";
	static APPROACH_SLAB = "APPROACH_SLAB";
	static PAVING = "PAVING";
	static WEARING = "WEARING";
	static SIDEWALK = "SIDEWALK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSolarDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SOLARCOLLECTOR = "SOLARCOLLECTOR";
	static SOLARPANEL = "SOLARPANEL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSpaceHeaterTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONVECTOR = "CONVECTOR";
	static RADIATOR = "RADIATOR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSpaceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SPACE = "SPACE";
	static PARKING = "PARKING";
	static GFA = "GFA";
	static INTERNAL = "INTERNAL";
	static EXTERNAL = "EXTERNAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSpatialZoneTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONSTRUCTION = "CONSTRUCTION";
	static FIRESAFETY = "FIRESAFETY";
	static LIGHTING = "LIGHTING";
	static OCCUPANCY = "OCCUPANCY";
	static SECURITY = "SECURITY";
	static THERMAL = "THERMAL";
	static TRANSPORT = "TRANSPORT";
	static VENTILATION = "VENTILATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStackTerminalTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BIRDCAGE = "BIRDCAGE";
	static COWL = "COWL";
	static RAINWATERHOPPER = "RAINWATERHOPPER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStairFlightTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STRAIGHT = "STRAIGHT";
	static WINDER = "WINDER";
	static SPIRAL = "SPIRAL";
	static CURVED = "CURVED";
	static FREEFORM = "FREEFORM";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStairTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static STRAIGHT_RUN_STAIR = "STRAIGHT_RUN_STAIR";
	static TWO_STRAIGHT_RUN_STAIR = "TWO_STRAIGHT_RUN_STAIR";
	static QUARTER_WINDING_STAIR = "QUARTER_WINDING_STAIR";
	static QUARTER_TURN_STAIR = "QUARTER_TURN_STAIR";
	static HALF_WINDING_STAIR = "HALF_WINDING_STAIR";
	static HALF_TURN_STAIR = "HALF_TURN_STAIR";
	static TWO_QUARTER_WINDING_STAIR = "TWO_QUARTER_WINDING_STAIR";
	static TWO_QUARTER_TURN_STAIR = "TWO_QUARTER_TURN_STAIR";
	static THREE_QUARTER_WINDING_STAIR = "THREE_QUARTER_WINDING_STAIR";
	static THREE_QUARTER_TURN_STAIR = "THREE_QUARTER_TURN_STAIR";
	static SPIRAL_STAIR = "SPIRAL_STAIR";
	static DOUBLE_RETURN_STAIR = "DOUBLE_RETURN_STAIR";
	static CURVED_RUN_STAIR = "CURVED_RUN_STAIR";
	static TWO_CURVED_RUN_STAIR = "TWO_CURVED_RUN_STAIR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStateEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static READWRITE = "READWRITE";
	static READONLY = "READONLY";
	static LOCKED = "LOCKED";
	static READWRITELOCKED = "READWRITELOCKED";
	static READONLYLOCKED = "READONLYLOCKED";
};
export class IfcStructuralCurveActivityTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONST = "CONST";
	static LINEAR = "LINEAR";
	static POLYGONAL = "POLYGONAL";
	static EQUIDISTANT = "EQUIDISTANT";
	static SINUS = "SINUS";
	static PARABOLA = "PARABOLA";
	static DISCRETE = "DISCRETE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStructuralCurveMemberTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static RIGID_JOINED_MEMBER = "RIGID_JOINED_MEMBER";
	static PIN_JOINED_MEMBER = "PIN_JOINED_MEMBER";
	static CABLE = "CABLE";
	static TENSION_MEMBER = "TENSION_MEMBER";
	static COMPRESSION_MEMBER = "COMPRESSION_MEMBER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStructuralSurfaceActivityTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONST = "CONST";
	static BILINEAR = "BILINEAR";
	static DISCRETE = "DISCRETE";
	static ISOCONTOUR = "ISOCONTOUR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcStructuralSurfaceMemberTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BENDING_ELEMENT = "BENDING_ELEMENT";
	static MEMBRANE_ELEMENT = "MEMBRANE_ELEMENT";
	static SHELL = "SHELL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSubContractResourceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PURCHASE = "PURCHASE";
	static WORK = "WORK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSurfaceFeatureTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static MARK = "MARK";
	static TAG = "TAG";
	static TREATMENT = "TREATMENT";
	static DEFECT = "DEFECT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSurfaceSide {
	value: string;
	constructor(v: string) { this.value = v;}
	static POSITIVE = "POSITIVE";
	static NEGATIVE = "NEGATIVE";
	static BOTH = "BOTH";
};
export class IfcSwitchingDeviceTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONTACTOR = "CONTACTOR";
	static DIMMERSWITCH = "DIMMERSWITCH";
	static EMERGENCYSTOP = "EMERGENCYSTOP";
	static KEYPAD = "KEYPAD";
	static MOMENTARYSWITCH = "MOMENTARYSWITCH";
	static SELECTORSWITCH = "SELECTORSWITCH";
	static STARTER = "STARTER";
	static SWITCHDISCONNECTOR = "SWITCHDISCONNECTOR";
	static TOGGLESWITCH = "TOGGLESWITCH";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcSystemFurnitureElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static PANEL = "PANEL";
	static WORKSURFACE = "WORKSURFACE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTankTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BASIN = "BASIN";
	static BREAKPRESSURE = "BREAKPRESSURE";
	static EXPANSION = "EXPANSION";
	static FEEDANDEXPANSION = "FEEDANDEXPANSION";
	static PRESSUREVESSEL = "PRESSUREVESSEL";
	static STORAGE = "STORAGE";
	static VESSEL = "VESSEL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTaskDurationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ELAPSEDTIME = "ELAPSEDTIME";
	static WORKTIME = "WORKTIME";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTaskTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ATTENDANCE = "ATTENDANCE";
	static CONSTRUCTION = "CONSTRUCTION";
	static DEMOLITION = "DEMOLITION";
	static DISMANTLE = "DISMANTLE";
	static DISPOSAL = "DISPOSAL";
	static INSTALLATION = "INSTALLATION";
	static LOGISTIC = "LOGISTIC";
	static MAINTENANCE = "MAINTENANCE";
	static MOVE = "MOVE";
	static OPERATION = "OPERATION";
	static REMOVAL = "REMOVAL";
	static RENOVATION = "RENOVATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTendonAnchorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COUPLER = "COUPLER";
	static FIXED_END = "FIXED_END";
	static TENSIONING_END = "TENSIONING_END";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTendonConduitTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static DUCT = "DUCT";
	static COUPLER = "COUPLER";
	static GROUTING_DUCT = "GROUTING_DUCT";
	static TRUMPET = "TRUMPET";
	static DIABOLO = "DIABOLO";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTendonTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BAR = "BAR";
	static COATED = "COATED";
	static STRAND = "STRAND";
	static WIRE = "WIRE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTextPath {
	value: string;
	constructor(v: string) { this.value = v;}
	static LEFT = "LEFT";
	static RIGHT = "RIGHT";
	static UP = "UP";
	static DOWN = "DOWN";
};
export class IfcTimeSeriesDataTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CONTINUOUS = "CONTINUOUS";
	static DISCRETE = "DISCRETE";
	static DISCRETEBINARY = "DISCRETEBINARY";
	static PIECEWISEBINARY = "PIECEWISEBINARY";
	static PIECEWISECONSTANT = "PIECEWISECONSTANT";
	static PIECEWISECONTINUOUS = "PIECEWISECONTINUOUS";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTransformerTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CURRENT = "CURRENT";
	static FREQUENCY = "FREQUENCY";
	static INVERTER = "INVERTER";
	static RECTIFIER = "RECTIFIER";
	static VOLTAGE = "VOLTAGE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTransitionCode {
	value: string;
	constructor(v: string) { this.value = v;}
	static DISCONTINUOUS = "DISCONTINUOUS";
	static CONTINUOUS = "CONTINUOUS";
	static CONTSAMEGRADIENT = "CONTSAMEGRADIENT";
	static CONTSAMEGRADIENTSAMECURVATURE = "CONTSAMEGRADIENTSAMECURVATURE";
};
export class IfcTransitionCurveType {
	value: string;
	constructor(v: string) { this.value = v;}
	static BIQUADRATICPARABOLA = "BIQUADRATICPARABOLA";
	static BLOSSCURVE = "BLOSSCURVE";
	static CLOTHOIDCURVE = "CLOTHOIDCURVE";
	static COSINECURVE = "COSINECURVE";
	static CUBICPARABOLA = "CUBICPARABOLA";
	static SINECURVE = "SINECURVE";
};
export class IfcTransportElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ELEVATOR = "ELEVATOR";
	static ESCALATOR = "ESCALATOR";
	static MOVINGWALKWAY = "MOVINGWALKWAY";
	static CRANEWAY = "CRANEWAY";
	static LIFTINGGEAR = "LIFTINGGEAR";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcTrimmingPreference {
	value: string;
	constructor(v: string) { this.value = v;}
	static CARTESIAN = "CARTESIAN";
	static PARAMETER = "PARAMETER";
	static UNSPECIFIED = "UNSPECIFIED";
};
export class IfcTubeBundleTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FINNED = "FINNED";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcUnitEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ABSORBEDDOSEUNIT = "ABSORBEDDOSEUNIT";
	static AMOUNTOFSUBSTANCEUNIT = "AMOUNTOFSUBSTANCEUNIT";
	static AREAUNIT = "AREAUNIT";
	static DOSEEQUIVALENTUNIT = "DOSEEQUIVALENTUNIT";
	static ELECTRICCAPACITANCEUNIT = "ELECTRICCAPACITANCEUNIT";
	static ELECTRICCHARGEUNIT = "ELECTRICCHARGEUNIT";
	static ELECTRICCONDUCTANCEUNIT = "ELECTRICCONDUCTANCEUNIT";
	static ELECTRICCURRENTUNIT = "ELECTRICCURRENTUNIT";
	static ELECTRICRESISTANCEUNIT = "ELECTRICRESISTANCEUNIT";
	static ELECTRICVOLTAGEUNIT = "ELECTRICVOLTAGEUNIT";
	static ENERGYUNIT = "ENERGYUNIT";
	static FORCEUNIT = "FORCEUNIT";
	static FREQUENCYUNIT = "FREQUENCYUNIT";
	static ILLUMINANCEUNIT = "ILLUMINANCEUNIT";
	static INDUCTANCEUNIT = "INDUCTANCEUNIT";
	static LENGTHUNIT = "LENGTHUNIT";
	static LUMINOUSFLUXUNIT = "LUMINOUSFLUXUNIT";
	static LUMINOUSINTENSITYUNIT = "LUMINOUSINTENSITYUNIT";
	static MAGNETICFLUXDENSITYUNIT = "MAGNETICFLUXDENSITYUNIT";
	static MAGNETICFLUXUNIT = "MAGNETICFLUXUNIT";
	static MASSUNIT = "MASSUNIT";
	static PLANEANGLEUNIT = "PLANEANGLEUNIT";
	static POWERUNIT = "POWERUNIT";
	static PRESSUREUNIT = "PRESSUREUNIT";
	static RADIOACTIVITYUNIT = "RADIOACTIVITYUNIT";
	static SOLIDANGLEUNIT = "SOLIDANGLEUNIT";
	static THERMODYNAMICTEMPERATUREUNIT = "THERMODYNAMICTEMPERATUREUNIT";
	static TIMEUNIT = "TIMEUNIT";
	static VOLUMEUNIT = "VOLUMEUNIT";
	static USERDEFINED = "USERDEFINED";
};
export class IfcUnitaryControlElementTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ALARMPANEL = "ALARMPANEL";
	static CONTROLPANEL = "CONTROLPANEL";
	static GASDETECTIONPANEL = "GASDETECTIONPANEL";
	static INDICATORPANEL = "INDICATORPANEL";
	static MIMICPANEL = "MIMICPANEL";
	static HUMIDISTAT = "HUMIDISTAT";
	static THERMOSTAT = "THERMOSTAT";
	static WEATHERSTATION = "WEATHERSTATION";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcUnitaryEquipmentTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRHANDLER = "AIRHANDLER";
	static AIRCONDITIONINGUNIT = "AIRCONDITIONINGUNIT";
	static DEHUMIDIFIER = "DEHUMIDIFIER";
	static SPLITSYSTEM = "SPLITSYSTEM";
	static ROOFTOPUNIT = "ROOFTOPUNIT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcValveTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static AIRRELEASE = "AIRRELEASE";
	static ANTIVACUUM = "ANTIVACUUM";
	static CHANGEOVER = "CHANGEOVER";
	static CHECK = "CHECK";
	static COMMISSIONING = "COMMISSIONING";
	static DIVERTING = "DIVERTING";
	static DRAWOFFCOCK = "DRAWOFFCOCK";
	static DOUBLECHECK = "DOUBLECHECK";
	static DOUBLEREGULATING = "DOUBLEREGULATING";
	static FAUCET = "FAUCET";
	static FLUSHING = "FLUSHING";
	static GASCOCK = "GASCOCK";
	static GASTAP = "GASTAP";
	static ISOLATING = "ISOLATING";
	static MIXING = "MIXING";
	static PRESSUREREDUCING = "PRESSUREREDUCING";
	static PRESSURERELIEF = "PRESSURERELIEF";
	static REGULATING = "REGULATING";
	static SAFETYCUTOFF = "SAFETYCUTOFF";
	static STEAMTRAP = "STEAMTRAP";
	static STOPCOCK = "STOPCOCK";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcVibrationDamperTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static BENDING_YIELD = "BENDING_YIELD";
	static SHEAR_YIELD = "SHEAR_YIELD";
	static AXIAL_YIELD = "AXIAL_YIELD";
	static FRICTION = "FRICTION";
	static VISCOUS = "VISCOUS";
	static RUBBER = "RUBBER";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcVibrationIsolatorTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static COMPRESSION = "COMPRESSION";
	static SPRING = "SPRING";
	static BASE = "BASE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcVoidingFeatureTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static CUTOUT = "CUTOUT";
	static NOTCH = "NOTCH";
	static HOLE = "HOLE";
	static MITER = "MITER";
	static CHAMFER = "CHAMFER";
	static EDGE = "EDGE";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWallTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static MOVABLE = "MOVABLE";
	static PARAPET = "PARAPET";
	static PARTITIONING = "PARTITIONING";
	static PLUMBINGWALL = "PLUMBINGWALL";
	static SHEAR = "SHEAR";
	static SOLIDWALL = "SOLIDWALL";
	static STANDARD = "STANDARD";
	static POLYGONAL = "POLYGONAL";
	static ELEMENTEDWALL = "ELEMENTEDWALL";
	static RETAININGWALL = "RETAININGWALL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWasteTerminalTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FLOORTRAP = "FLOORTRAP";
	static FLOORWASTE = "FLOORWASTE";
	static GULLYSUMP = "GULLYSUMP";
	static GULLYTRAP = "GULLYTRAP";
	static ROOFDRAIN = "ROOFDRAIN";
	static WASTEDISPOSALUNIT = "WASTEDISPOSALUNIT";
	static WASTETRAP = "WASTETRAP";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowPanelOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SIDEHUNGRIGHTHAND = "SIDEHUNGRIGHTHAND";
	static SIDEHUNGLEFTHAND = "SIDEHUNGLEFTHAND";
	static TILTANDTURNRIGHTHAND = "TILTANDTURNRIGHTHAND";
	static TILTANDTURNLEFTHAND = "TILTANDTURNLEFTHAND";
	static TOPHUNG = "TOPHUNG";
	static BOTTOMHUNG = "BOTTOMHUNG";
	static PIVOTHORIZONTAL = "PIVOTHORIZONTAL";
	static PIVOTVERTICAL = "PIVOTVERTICAL";
	static SLIDINGHORIZONTAL = "SLIDINGHORIZONTAL";
	static SLIDINGVERTICAL = "SLIDINGVERTICAL";
	static REMOVABLECASEMENT = "REMOVABLECASEMENT";
	static FIXEDCASEMENT = "FIXEDCASEMENT";
	static OTHEROPERATION = "OTHEROPERATION";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowPanelPositionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static LEFT = "LEFT";
	static MIDDLE = "MIDDLE";
	static RIGHT = "RIGHT";
	static BOTTOM = "BOTTOM";
	static TOP = "TOP";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowStyleConstructionEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ALUMINIUM = "ALUMINIUM";
	static HIGH_GRADE_STEEL = "HIGH_GRADE_STEEL";
	static STEEL = "STEEL";
	static WOOD = "WOOD";
	static ALUMINIUM_WOOD = "ALUMINIUM_WOOD";
	static PLASTIC = "PLASTIC";
	static OTHER_CONSTRUCTION = "OTHER_CONSTRUCTION";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowStyleOperationEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SINGLE_PANEL = "SINGLE_PANEL";
	static DOUBLE_PANEL_VERTICAL = "DOUBLE_PANEL_VERTICAL";
	static DOUBLE_PANEL_HORIZONTAL = "DOUBLE_PANEL_HORIZONTAL";
	static TRIPLE_PANEL_VERTICAL = "TRIPLE_PANEL_VERTICAL";
	static TRIPLE_PANEL_BOTTOM = "TRIPLE_PANEL_BOTTOM";
	static TRIPLE_PANEL_TOP = "TRIPLE_PANEL_TOP";
	static TRIPLE_PANEL_LEFT = "TRIPLE_PANEL_LEFT";
	static TRIPLE_PANEL_RIGHT = "TRIPLE_PANEL_RIGHT";
	static TRIPLE_PANEL_HORIZONTAL = "TRIPLE_PANEL_HORIZONTAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static WINDOW = "WINDOW";
	static SKYLIGHT = "SKYLIGHT";
	static LIGHTDOME = "LIGHTDOME";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWindowTypePartitioningEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static SINGLE_PANEL = "SINGLE_PANEL";
	static DOUBLE_PANEL_VERTICAL = "DOUBLE_PANEL_VERTICAL";
	static DOUBLE_PANEL_HORIZONTAL = "DOUBLE_PANEL_HORIZONTAL";
	static TRIPLE_PANEL_VERTICAL = "TRIPLE_PANEL_VERTICAL";
	static TRIPLE_PANEL_BOTTOM = "TRIPLE_PANEL_BOTTOM";
	static TRIPLE_PANEL_TOP = "TRIPLE_PANEL_TOP";
	static TRIPLE_PANEL_LEFT = "TRIPLE_PANEL_LEFT";
	static TRIPLE_PANEL_RIGHT = "TRIPLE_PANEL_RIGHT";
	static TRIPLE_PANEL_HORIZONTAL = "TRIPLE_PANEL_HORIZONTAL";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWorkCalendarTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static FIRSTSHIFT = "FIRSTSHIFT";
	static SECONDSHIFT = "SECONDSHIFT";
	static THIRDSHIFT = "THIRDSHIFT";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWorkPlanTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ACTUAL = "ACTUAL";
	static BASELINE = "BASELINE";
	static PLANNED = "PLANNED";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export class IfcWorkScheduleTypeEnum {
	value: string;
	constructor(v: string) { this.value = v;}
	static ACTUAL = "ACTUAL";
	static BASELINE = "BASELINE";
	static PLANNED = "PLANNED";
	static USERDEFINED = "USERDEFINED";
	static NOTDEFINED = "NOTDEFINED";
};
export type IfcActorSelect = 
| (Handle<IfcOrganization> | IfcOrganization)
| (Handle<IfcPerson> | IfcPerson)
| (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization)
;
export type IfcAppliedValueSelect = 
| (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit)
| (Handle<IfcReference> | IfcReference)
| IfcValue
;
export type IfcAxis2Placement = 
| (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D)
| (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D)
;
export type IfcBendingParameterSelect = 
| IfcLengthMeasure
| IfcPlaneAngleMeasure
;
export type IfcBooleanOperand = 
| (Handle<IfcBooleanResult> | IfcBooleanResult)
| (Handle<IfcCsgPrimitive3D> | IfcCsgPrimitive3D)
| (Handle<IfcHalfSpaceSolid> | IfcHalfSpaceSolid)
| (Handle<IfcSolidModel> | IfcSolidModel)
| (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet)
;
export type IfcClassificationReferenceSelect = 
| (Handle<IfcClassification> | IfcClassification)
| (Handle<IfcClassificationReference> | IfcClassificationReference)
;
export type IfcClassificationSelect = 
| (Handle<IfcClassification> | IfcClassification)
| (Handle<IfcClassificationReference> | IfcClassificationReference)
;
export type IfcColour = 
| (Handle<IfcColourSpecification> | IfcColourSpecification)
| (Handle<IfcPreDefinedColour> | IfcPreDefinedColour)
;
export type IfcColourOrFactor = 
| (Handle<IfcColourRgb> | IfcColourRgb)
| IfcNormalisedRatioMeasure
;
export type IfcCoordinateReferenceSystemSelect = 
| (Handle<IfcCoordinateReferenceSystem> | IfcCoordinateReferenceSystem)
| (Handle<IfcGeometricRepresentationContext> | IfcGeometricRepresentationContext)
;
export type IfcCsgSelect = 
| (Handle<IfcBooleanResult> | IfcBooleanResult)
| (Handle<IfcCsgPrimitive3D> | IfcCsgPrimitive3D)
;
export type IfcCurveFontOrScaledCurveFontSelect = 
| (Handle<IfcCurveStyleFontAndScaling> | IfcCurveStyleFontAndScaling)
| IfcCurveStyleFontSelect
;
export type IfcCurveOnSurface = 
| (Handle<IfcCompositeCurveOnSurface> | IfcCompositeCurveOnSurface)
| (Handle<IfcPcurve> | IfcPcurve)
| (Handle<IfcSurfaceCurve> | IfcSurfaceCurve)
;
export type IfcCurveOrEdgeCurve = 
| (Handle<IfcBoundedCurve> | IfcBoundedCurve)
| (Handle<IfcEdgeCurve> | IfcEdgeCurve)
;
export type IfcCurveStyleFontSelect = 
| (Handle<IfcCurveStyleFont> | IfcCurveStyleFont)
| (Handle<IfcPreDefinedCurveFont> | IfcPreDefinedCurveFont)
;
export type IfcDefinitionSelect = 
| (Handle<IfcObjectDefinition> | IfcObjectDefinition)
| (Handle<IfcPropertyDefinition> | IfcPropertyDefinition)
;
export type IfcDerivedMeasureValue = 
| IfcAbsorbedDoseMeasure
| IfcAccelerationMeasure
| IfcAngularVelocityMeasure
| IfcAreaDensityMeasure
| IfcCompoundPlaneAngleMeasure
| IfcCurvatureMeasure
| IfcDoseEquivalentMeasure
| IfcDynamicViscosityMeasure
| IfcElectricCapacitanceMeasure
| IfcElectricChargeMeasure
| IfcElectricConductanceMeasure
| IfcElectricResistanceMeasure
| IfcElectricVoltageMeasure
| IfcEnergyMeasure
| IfcForceMeasure
| IfcFrequencyMeasure
| IfcHeatFluxDensityMeasure
| IfcHeatingValueMeasure
| IfcIlluminanceMeasure
| IfcInductanceMeasure
| IfcIntegerCountRateMeasure
| IfcIonConcentrationMeasure
| IfcIsothermalMoistureCapacityMeasure
| IfcKinematicViscosityMeasure
| IfcLinearForceMeasure
| IfcLinearMomentMeasure
| IfcLinearStiffnessMeasure
| IfcLinearVelocityMeasure
| IfcLuminousFluxMeasure
| IfcLuminousIntensityDistributionMeasure
| IfcMagneticFluxDensityMeasure
| IfcMagneticFluxMeasure
| IfcMassDensityMeasure
| IfcMassFlowRateMeasure
| IfcMassPerLengthMeasure
| IfcModulusOfElasticityMeasure
| IfcModulusOfLinearSubgradeReactionMeasure
| IfcModulusOfRotationalSubgradeReactionMeasure
| IfcModulusOfSubgradeReactionMeasure
| IfcMoistureDiffusivityMeasure
| IfcMolecularWeightMeasure
| IfcMomentOfInertiaMeasure
| IfcMonetaryMeasure
| IfcPHMeasure
| IfcPlanarForceMeasure
| IfcPowerMeasure
| IfcPressureMeasure
| IfcRadioActivityMeasure
| IfcRotationalFrequencyMeasure
| IfcRotationalMassMeasure
| IfcRotationalStiffnessMeasure
| IfcSectionModulusMeasure
| IfcSectionalAreaIntegralMeasure
| IfcShearModulusMeasure
| IfcSoundPowerLevelMeasure
| IfcSoundPowerMeasure
| IfcSoundPressureLevelMeasure
| IfcSoundPressureMeasure
| IfcSpecificHeatCapacityMeasure
| IfcTemperatureGradientMeasure
| IfcTemperatureRateOfChangeMeasure
| IfcThermalAdmittanceMeasure
| IfcThermalConductivityMeasure
| IfcThermalExpansionCoefficientMeasure
| IfcThermalResistanceMeasure
| IfcThermalTransmittanceMeasure
| IfcTorqueMeasure
| IfcVaporPermeabilityMeasure
| IfcVolumetricFlowRateMeasure
| IfcWarpingConstantMeasure
| IfcWarpingMomentMeasure
;
export type IfcDocumentSelect = 
| (Handle<IfcDocumentInformation> | IfcDocumentInformation)
| (Handle<IfcDocumentReference> | IfcDocumentReference)
;
export type IfcFillStyleSelect = 
| IfcColour
| (Handle<IfcExternallyDefinedHatchStyle> | IfcExternallyDefinedHatchStyle)
| (Handle<IfcFillAreaStyleHatching> | IfcFillAreaStyleHatching)
| (Handle<IfcFillAreaStyleTiles> | IfcFillAreaStyleTiles)
;
export type IfcGeometricSetSelect = 
| (Handle<IfcCurve> | IfcCurve)
| (Handle<IfcPoint> | IfcPoint)
| (Handle<IfcSurface> | IfcSurface)
;
export type IfcGridPlacementDirectionSelect = 
| (Handle<IfcDirection> | IfcDirection)
| (Handle<IfcVirtualGridIntersection> | IfcVirtualGridIntersection)
;
export type IfcHatchLineDistanceSelect = 
| IfcPositiveLengthMeasure
| (Handle<IfcVector> | IfcVector)
;
export type IfcLayeredItem = 
| (Handle<IfcRepresentation> | IfcRepresentation)
| (Handle<IfcRepresentationItem> | IfcRepresentationItem)
;
export type IfcLibrarySelect = 
| (Handle<IfcLibraryInformation> | IfcLibraryInformation)
| (Handle<IfcLibraryReference> | IfcLibraryReference)
;
export type IfcLightDistributionDataSourceSelect = 
| (Handle<IfcExternalReference> | IfcExternalReference)
| (Handle<IfcLightIntensityDistribution> | IfcLightIntensityDistribution)
;
export type IfcMaterialSelect = 
| (Handle<IfcMaterialDefinition> | IfcMaterialDefinition)
| (Handle<IfcMaterialList> | IfcMaterialList)
| (Handle<IfcMaterialUsageDefinition> | IfcMaterialUsageDefinition)
;
export type IfcMeasureValue = 
| IfcAmountOfSubstanceMeasure
| IfcAreaMeasure
| IfcComplexNumber
| IfcContextDependentMeasure
| IfcCountMeasure
| IfcDescriptiveMeasure
| IfcElectricCurrentMeasure
| IfcLengthMeasure
| IfcLuminousIntensityMeasure
| IfcMassMeasure
| IfcNonNegativeLengthMeasure
| IfcNormalisedRatioMeasure
| IfcNumericMeasure
| IfcParameterValue
| IfcPlaneAngleMeasure
| IfcPositiveLengthMeasure
| IfcPositivePlaneAngleMeasure
| IfcPositiveRatioMeasure
| IfcRatioMeasure
| IfcSolidAngleMeasure
| IfcThermodynamicTemperatureMeasure
| IfcTimeMeasure
| IfcVolumeMeasure
;
export type IfcMetricValueSelect = 
| (Handle<IfcAppliedValue> | IfcAppliedValue)
| (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit)
| (Handle<IfcReference> | IfcReference)
| (Handle<IfcTable> | IfcTable)
| (Handle<IfcTimeSeries> | IfcTimeSeries)
| IfcValue
;
export type IfcModulusOfRotationalSubgradeReactionSelect = 
| IfcBoolean
| IfcModulusOfRotationalSubgradeReactionMeasure
;
export type IfcModulusOfSubgradeReactionSelect = 
| IfcBoolean
| IfcModulusOfSubgradeReactionMeasure
;
export type IfcModulusOfTranslationalSubgradeReactionSelect = 
| IfcBoolean
| IfcModulusOfLinearSubgradeReactionMeasure
;
export type IfcObjectReferenceSelect = 
| (Handle<IfcAddress> | IfcAddress)
| (Handle<IfcAppliedValue> | IfcAppliedValue)
| (Handle<IfcExternalReference> | IfcExternalReference)
| (Handle<IfcMaterialDefinition> | IfcMaterialDefinition)
| (Handle<IfcOrganization> | IfcOrganization)
| (Handle<IfcPerson> | IfcPerson)
| (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization)
| (Handle<IfcTable> | IfcTable)
| (Handle<IfcTimeSeries> | IfcTimeSeries)
;
export type IfcPointOrVertexPoint = 
| (Handle<IfcPoint> | IfcPoint)
| (Handle<IfcVertexPoint> | IfcVertexPoint)
;
export type IfcPresentationStyleSelect = 
| (Handle<IfcCurveStyle> | IfcCurveStyle)
| (Handle<IfcFillAreaStyle> | IfcFillAreaStyle)
| IfcNullStyle
| (Handle<IfcSurfaceStyle> | IfcSurfaceStyle)
| (Handle<IfcTextStyle> | IfcTextStyle)
;
export type IfcProcessSelect = 
| (Handle<IfcProcess> | IfcProcess)
| (Handle<IfcTypeProcess> | IfcTypeProcess)
;
export type IfcProductRepresentationSelect = 
| (Handle<IfcProductDefinitionShape> | IfcProductDefinitionShape)
| (Handle<IfcRepresentationMap> | IfcRepresentationMap)
;
export type IfcProductSelect = 
| (Handle<IfcProduct> | IfcProduct)
| (Handle<IfcTypeProduct> | IfcTypeProduct)
;
export type IfcPropertySetDefinitionSelect = 
| (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)
| IfcPropertySetDefinitionSet
;
export type IfcResourceObjectSelect = 
| (Handle<IfcActorRole> | IfcActorRole)
| (Handle<IfcAppliedValue> | IfcAppliedValue)
| (Handle<IfcApproval> | IfcApproval)
| (Handle<IfcConstraint> | IfcConstraint)
| (Handle<IfcContextDependentUnit> | IfcContextDependentUnit)
| (Handle<IfcConversionBasedUnit> | IfcConversionBasedUnit)
| (Handle<IfcExternalInformation> | IfcExternalInformation)
| (Handle<IfcExternalReference> | IfcExternalReference)
| (Handle<IfcMaterialDefinition> | IfcMaterialDefinition)
| (Handle<IfcOrganization> | IfcOrganization)
| (Handle<IfcPerson> | IfcPerson)
| (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization)
| (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)
| (Handle<IfcProfileDef> | IfcProfileDef)
| (Handle<IfcPropertyAbstraction> | IfcPropertyAbstraction)
| (Handle<IfcShapeAspect> | IfcShapeAspect)
| (Handle<IfcTimeSeries> | IfcTimeSeries)
;
export type IfcResourceSelect = 
| (Handle<IfcResource> | IfcResource)
| (Handle<IfcTypeResource> | IfcTypeResource)
;
export type IfcRotationalStiffnessSelect = 
| IfcBoolean
| IfcRotationalStiffnessMeasure
;
export type IfcSegmentIndexSelect = 
| IfcArcIndex
| IfcLineIndex
;
export type IfcShell = 
| (Handle<IfcClosedShell> | IfcClosedShell)
| (Handle<IfcOpenShell> | IfcOpenShell)
;
export type IfcSimpleValue = 
| IfcBinary
| IfcBoolean
| IfcDate
| IfcDateTime
| IfcDuration
| IfcIdentifier
| IfcInteger
| IfcLabel
| IfcLogical
| IfcPositiveInteger
| IfcReal
| IfcText
| IfcTime
| IfcTimeStamp
;
export type IfcSizeSelect = 
| IfcDescriptiveMeasure
| IfcLengthMeasure
| IfcNormalisedRatioMeasure
| IfcPositiveLengthMeasure
| IfcPositiveRatioMeasure
| IfcRatioMeasure
;
export type IfcSolidOrShell = 
| (Handle<IfcClosedShell> | IfcClosedShell)
| (Handle<IfcSolidModel> | IfcSolidModel)
;
export type IfcSpaceBoundarySelect = 
| (Handle<IfcExternalSpatialElement> | IfcExternalSpatialElement)
| (Handle<IfcSpace> | IfcSpace)
;
export type IfcSpecularHighlightSelect = 
| IfcSpecularExponent
| IfcSpecularRoughness
;
export type IfcStructuralActivityAssignmentSelect = 
| (Handle<IfcElement> | IfcElement)
| (Handle<IfcStructuralItem> | IfcStructuralItem)
;
export type IfcStyleAssignmentSelect = 
| (Handle<IfcPresentationStyle> | IfcPresentationStyle)
| (Handle<IfcPresentationStyleAssignment> | IfcPresentationStyleAssignment)
;
export type IfcSurfaceOrFaceSurface = 
| (Handle<IfcFaceBasedSurfaceModel> | IfcFaceBasedSurfaceModel)
| (Handle<IfcFaceSurface> | IfcFaceSurface)
| (Handle<IfcSurface> | IfcSurface)
;
export type IfcSurfaceStyleElementSelect = 
| (Handle<IfcExternallyDefinedSurfaceStyle> | IfcExternallyDefinedSurfaceStyle)
| (Handle<IfcSurfaceStyleLighting> | IfcSurfaceStyleLighting)
| (Handle<IfcSurfaceStyleRefraction> | IfcSurfaceStyleRefraction)
| (Handle<IfcSurfaceStyleShading> | IfcSurfaceStyleShading)
| (Handle<IfcSurfaceStyleWithTextures> | IfcSurfaceStyleWithTextures)
;
export type IfcTextFontSelect = 
| (Handle<IfcExternallyDefinedTextFont> | IfcExternallyDefinedTextFont)
| (Handle<IfcPreDefinedTextFont> | IfcPreDefinedTextFont)
;
export type IfcTimeOrRatioSelect = 
| IfcDuration
| IfcRatioMeasure
;
export type IfcTranslationalStiffnessSelect = 
| IfcBoolean
| IfcLinearStiffnessMeasure
;
export type IfcTrimmingSelect = 
| (Handle<IfcCartesianPoint> | IfcCartesianPoint)
| IfcParameterValue
;
export type IfcUnit = 
| (Handle<IfcDerivedUnit> | IfcDerivedUnit)
| (Handle<IfcMonetaryUnit> | IfcMonetaryUnit)
| (Handle<IfcNamedUnit> | IfcNamedUnit)
;
export type IfcValue = 
| IfcDerivedMeasureValue
| IfcMeasureValue
| IfcSimpleValue
;
export type IfcVectorOrDirection = 
| (Handle<IfcDirection> | IfcDirection)
| (Handle<IfcVector> | IfcVector)
;
export type IfcWarpingStiffnessSelect = 
| IfcBoolean
| IfcWarpingMomentMeasure
;
function ParseType(obj: any): any {
	if (obj.type === 5) { return new Handle<any>(obj.expressID); }
	if (obj.type !== 2) { return obj; }
	if (obj.label === "IFCABSORBEDDOSEMEASURE") { return new IfcAbsorbedDoseMeasure(obj.value); }
	if (obj.label === "IFCACCELERATIONMEASURE") { return new IfcAccelerationMeasure(obj.value); }
	if (obj.label === "IFCAMOUNTOFSUBSTANCEMEASURE") { return new IfcAmountOfSubstanceMeasure(obj.value); }
	if (obj.label === "IFCANGULARVELOCITYMEASURE") { return new IfcAngularVelocityMeasure(obj.value); }
	if (obj.label === "IFCAREADENSITYMEASURE") { return new IfcAreaDensityMeasure(obj.value); }
	if (obj.label === "IFCAREAMEASURE") { return new IfcAreaMeasure(obj.value); }
	if (obj.label === "IFCBINARY") { return new IfcBinary(obj.value); }
	if (obj.label === "IFCBOOLEAN") { return new IfcBoolean(obj.value); }
	if (obj.label === "IFCBOXALIGNMENT") { return new IfcBoxAlignment(obj.value); }
	if (obj.label === "IFCCARDINALPOINTREFERENCE") { return new IfcCardinalPointReference(obj.value); }
	if (obj.label === "IFCCONTEXTDEPENDENTMEASURE") { return new IfcContextDependentMeasure(obj.value); }
	if (obj.label === "IFCCOUNTMEASURE") { return new IfcCountMeasure(obj.value); }
	if (obj.label === "IFCCURVATUREMEASURE") { return new IfcCurvatureMeasure(obj.value); }
	if (obj.label === "IFCDATE") { return new IfcDate(obj.value); }
	if (obj.label === "IFCDATETIME") { return new IfcDateTime(obj.value); }
	if (obj.label === "IFCDAYINMONTHNUMBER") { return new IfcDayInMonthNumber(obj.value); }
	if (obj.label === "IFCDAYINWEEKNUMBER") { return new IfcDayInWeekNumber(obj.value); }
	if (obj.label === "IFCDESCRIPTIVEMEASURE") { return new IfcDescriptiveMeasure(obj.value); }
	if (obj.label === "IFCDIMENSIONCOUNT") { return new IfcDimensionCount(obj.value); }
	if (obj.label === "IFCDOSEEQUIVALENTMEASURE") { return new IfcDoseEquivalentMeasure(obj.value); }
	if (obj.label === "IFCDURATION") { return new IfcDuration(obj.value); }
	if (obj.label === "IFCDYNAMICVISCOSITYMEASURE") { return new IfcDynamicViscosityMeasure(obj.value); }
	if (obj.label === "IFCELECTRICCAPACITANCEMEASURE") { return new IfcElectricCapacitanceMeasure(obj.value); }
	if (obj.label === "IFCELECTRICCHARGEMEASURE") { return new IfcElectricChargeMeasure(obj.value); }
	if (obj.label === "IFCELECTRICCONDUCTANCEMEASURE") { return new IfcElectricConductanceMeasure(obj.value); }
	if (obj.label === "IFCELECTRICCURRENTMEASURE") { return new IfcElectricCurrentMeasure(obj.value); }
	if (obj.label === "IFCELECTRICRESISTANCEMEASURE") { return new IfcElectricResistanceMeasure(obj.value); }
	if (obj.label === "IFCELECTRICVOLTAGEMEASURE") { return new IfcElectricVoltageMeasure(obj.value); }
	if (obj.label === "IFCENERGYMEASURE") { return new IfcEnergyMeasure(obj.value); }
	if (obj.label === "IFCFONTSTYLE") { return new IfcFontStyle(obj.value); }
	if (obj.label === "IFCFONTVARIANT") { return new IfcFontVariant(obj.value); }
	if (obj.label === "IFCFONTWEIGHT") { return new IfcFontWeight(obj.value); }
	if (obj.label === "IFCFORCEMEASURE") { return new IfcForceMeasure(obj.value); }
	if (obj.label === "IFCFREQUENCYMEASURE") { return new IfcFrequencyMeasure(obj.value); }
	if (obj.label === "IFCGLOBALLYUNIQUEID") { return new IfcGloballyUniqueId(obj.value); }
	if (obj.label === "IFCHEATFLUXDENSITYMEASURE") { return new IfcHeatFluxDensityMeasure(obj.value); }
	if (obj.label === "IFCHEATINGVALUEMEASURE") { return new IfcHeatingValueMeasure(obj.value); }
	if (obj.label === "IFCIDENTIFIER") { return new IfcIdentifier(obj.value); }
	if (obj.label === "IFCILLUMINANCEMEASURE") { return new IfcIlluminanceMeasure(obj.value); }
	if (obj.label === "IFCINDUCTANCEMEASURE") { return new IfcInductanceMeasure(obj.value); }
	if (obj.label === "IFCINTEGER") { return new IfcInteger(obj.value); }
	if (obj.label === "IFCINTEGERCOUNTRATEMEASURE") { return new IfcIntegerCountRateMeasure(obj.value); }
	if (obj.label === "IFCIONCONCENTRATIONMEASURE") { return new IfcIonConcentrationMeasure(obj.value); }
	if (obj.label === "IFCISOTHERMALMOISTURECAPACITYMEASURE") { return new IfcIsothermalMoistureCapacityMeasure(obj.value); }
	if (obj.label === "IFCKINEMATICVISCOSITYMEASURE") { return new IfcKinematicViscosityMeasure(obj.value); }
	if (obj.label === "IFCLABEL") { return new IfcLabel(obj.value); }
	if (obj.label === "IFCLANGUAGEID") { return new IfcLanguageId(obj.value); }
	if (obj.label === "IFCLENGTHMEASURE") { return new IfcLengthMeasure(obj.value); }
	if (obj.label === "IFCLINEARFORCEMEASURE") { return new IfcLinearForceMeasure(obj.value); }
	if (obj.label === "IFCLINEARMOMENTMEASURE") { return new IfcLinearMomentMeasure(obj.value); }
	if (obj.label === "IFCLINEARSTIFFNESSMEASURE") { return new IfcLinearStiffnessMeasure(obj.value); }
	if (obj.label === "IFCLINEARVELOCITYMEASURE") { return new IfcLinearVelocityMeasure(obj.value); }
	if (obj.label === "IFCLOGICAL") { return new IfcLogical(obj.value); }
	if (obj.label === "IFCLUMINOUSFLUXMEASURE") { return new IfcLuminousFluxMeasure(obj.value); }
	if (obj.label === "IFCLUMINOUSINTENSITYDISTRIBUTIONMEASURE") { return new IfcLuminousIntensityDistributionMeasure(obj.value); }
	if (obj.label === "IFCLUMINOUSINTENSITYMEASURE") { return new IfcLuminousIntensityMeasure(obj.value); }
	if (obj.label === "IFCMAGNETICFLUXDENSITYMEASURE") { return new IfcMagneticFluxDensityMeasure(obj.value); }
	if (obj.label === "IFCMAGNETICFLUXMEASURE") { return new IfcMagneticFluxMeasure(obj.value); }
	if (obj.label === "IFCMASSDENSITYMEASURE") { return new IfcMassDensityMeasure(obj.value); }
	if (obj.label === "IFCMASSFLOWRATEMEASURE") { return new IfcMassFlowRateMeasure(obj.value); }
	if (obj.label === "IFCMASSMEASURE") { return new IfcMassMeasure(obj.value); }
	if (obj.label === "IFCMASSPERLENGTHMEASURE") { return new IfcMassPerLengthMeasure(obj.value); }
	if (obj.label === "IFCMODULUSOFELASTICITYMEASURE") { return new IfcModulusOfElasticityMeasure(obj.value); }
	if (obj.label === "IFCMODULUSOFLINEARSUBGRADEREACTIONMEASURE") { return new IfcModulusOfLinearSubgradeReactionMeasure(obj.value); }
	if (obj.label === "IFCMODULUSOFROTATIONALSUBGRADEREACTIONMEASURE") { return new IfcModulusOfRotationalSubgradeReactionMeasure(obj.value); }
	if (obj.label === "IFCMODULUSOFSUBGRADEREACTIONMEASURE") { return new IfcModulusOfSubgradeReactionMeasure(obj.value); }
	if (obj.label === "IFCMOISTUREDIFFUSIVITYMEASURE") { return new IfcMoistureDiffusivityMeasure(obj.value); }
	if (obj.label === "IFCMOLECULARWEIGHTMEASURE") { return new IfcMolecularWeightMeasure(obj.value); }
	if (obj.label === "IFCMOMENTOFINERTIAMEASURE") { return new IfcMomentOfInertiaMeasure(obj.value); }
	if (obj.label === "IFCMONETARYMEASURE") { return new IfcMonetaryMeasure(obj.value); }
	if (obj.label === "IFCMONTHINYEARNUMBER") { return new IfcMonthInYearNumber(obj.value); }
	if (obj.label === "IFCNONNEGATIVELENGTHMEASURE") { return new IfcNonNegativeLengthMeasure(obj.value); }
	if (obj.label === "IFCNORMALISEDRATIOMEASURE") { return new IfcNormalisedRatioMeasure(obj.value); }
	if (obj.label === "IFCNUMERICMEASURE") { return new IfcNumericMeasure(obj.value); }
	if (obj.label === "IFCPHMEASURE") { return new IfcPHMeasure(obj.value); }
	if (obj.label === "IFCPARAMETERVALUE") { return new IfcParameterValue(obj.value); }
	if (obj.label === "IFCPLANARFORCEMEASURE") { return new IfcPlanarForceMeasure(obj.value); }
	if (obj.label === "IFCPLANEANGLEMEASURE") { return new IfcPlaneAngleMeasure(obj.value); }
	if (obj.label === "IFCPOSITIVEINTEGER") { return new IfcPositiveInteger(obj.value); }
	if (obj.label === "IFCPOSITIVELENGTHMEASURE") { return new IfcPositiveLengthMeasure(obj.value); }
	if (obj.label === "IFCPOSITIVEPLANEANGLEMEASURE") { return new IfcPositivePlaneAngleMeasure(obj.value); }
	if (obj.label === "IFCPOSITIVERATIOMEASURE") { return new IfcPositiveRatioMeasure(obj.value); }
	if (obj.label === "IFCPOWERMEASURE") { return new IfcPowerMeasure(obj.value); }
	if (obj.label === "IFCPRESENTABLETEXT") { return new IfcPresentableText(obj.value); }
	if (obj.label === "IFCPRESSUREMEASURE") { return new IfcPressureMeasure(obj.value); }
	if (obj.label === "IFCRADIOACTIVITYMEASURE") { return new IfcRadioActivityMeasure(obj.value); }
	if (obj.label === "IFCRATIOMEASURE") { return new IfcRatioMeasure(obj.value); }
	if (obj.label === "IFCREAL") { return new IfcReal(obj.value); }
	if (obj.label === "IFCROTATIONALFREQUENCYMEASURE") { return new IfcRotationalFrequencyMeasure(obj.value); }
	if (obj.label === "IFCROTATIONALMASSMEASURE") { return new IfcRotationalMassMeasure(obj.value); }
	if (obj.label === "IFCROTATIONALSTIFFNESSMEASURE") { return new IfcRotationalStiffnessMeasure(obj.value); }
	if (obj.label === "IFCSECTIONMODULUSMEASURE") { return new IfcSectionModulusMeasure(obj.value); }
	if (obj.label === "IFCSECTIONALAREAINTEGRALMEASURE") { return new IfcSectionalAreaIntegralMeasure(obj.value); }
	if (obj.label === "IFCSHEARMODULUSMEASURE") { return new IfcShearModulusMeasure(obj.value); }
	if (obj.label === "IFCSOLIDANGLEMEASURE") { return new IfcSolidAngleMeasure(obj.value); }
	if (obj.label === "IFCSOUNDPOWERLEVELMEASURE") { return new IfcSoundPowerLevelMeasure(obj.value); }
	if (obj.label === "IFCSOUNDPOWERMEASURE") { return new IfcSoundPowerMeasure(obj.value); }
	if (obj.label === "IFCSOUNDPRESSURELEVELMEASURE") { return new IfcSoundPressureLevelMeasure(obj.value); }
	if (obj.label === "IFCSOUNDPRESSUREMEASURE") { return new IfcSoundPressureMeasure(obj.value); }
	if (obj.label === "IFCSPECIFICHEATCAPACITYMEASURE") { return new IfcSpecificHeatCapacityMeasure(obj.value); }
	if (obj.label === "IFCSPECULAREXPONENT") { return new IfcSpecularExponent(obj.value); }
	if (obj.label === "IFCSPECULARROUGHNESS") { return new IfcSpecularRoughness(obj.value); }
	if (obj.label === "IFCTEMPERATUREGRADIENTMEASURE") { return new IfcTemperatureGradientMeasure(obj.value); }
	if (obj.label === "IFCTEMPERATURERATEOFCHANGEMEASURE") { return new IfcTemperatureRateOfChangeMeasure(obj.value); }
	if (obj.label === "IFCTEXT") { return new IfcText(obj.value); }
	if (obj.label === "IFCTEXTALIGNMENT") { return new IfcTextAlignment(obj.value); }
	if (obj.label === "IFCTEXTDECORATION") { return new IfcTextDecoration(obj.value); }
	if (obj.label === "IFCTEXTFONTNAME") { return new IfcTextFontName(obj.value); }
	if (obj.label === "IFCTEXTTRANSFORMATION") { return new IfcTextTransformation(obj.value); }
	if (obj.label === "IFCTHERMALADMITTANCEMEASURE") { return new IfcThermalAdmittanceMeasure(obj.value); }
	if (obj.label === "IFCTHERMALCONDUCTIVITYMEASURE") { return new IfcThermalConductivityMeasure(obj.value); }
	if (obj.label === "IFCTHERMALEXPANSIONCOEFFICIENTMEASURE") { return new IfcThermalExpansionCoefficientMeasure(obj.value); }
	if (obj.label === "IFCTHERMALRESISTANCEMEASURE") { return new IfcThermalResistanceMeasure(obj.value); }
	if (obj.label === "IFCTHERMALTRANSMITTANCEMEASURE") { return new IfcThermalTransmittanceMeasure(obj.value); }
	if (obj.label === "IFCTHERMODYNAMICTEMPERATUREMEASURE") { return new IfcThermodynamicTemperatureMeasure(obj.value); }
	if (obj.label === "IFCTIME") { return new IfcTime(obj.value); }
	if (obj.label === "IFCTIMEMEASURE") { return new IfcTimeMeasure(obj.value); }
	if (obj.label === "IFCTIMESTAMP") { return new IfcTimeStamp(obj.value); }
	if (obj.label === "IFCTORQUEMEASURE") { return new IfcTorqueMeasure(obj.value); }
	if (obj.label === "IFCURIREFERENCE") { return new IfcURIReference(obj.value); }
	if (obj.label === "IFCVAPORPERMEABILITYMEASURE") { return new IfcVaporPermeabilityMeasure(obj.value); }
	if (obj.label === "IFCVOLUMEMEASURE") { return new IfcVolumeMeasure(obj.value); }
	if (obj.label === "IFCVOLUMETRICFLOWRATEMEASURE") { return new IfcVolumetricFlowRateMeasure(obj.value); }
	if (obj.label === "IFCWARPINGCONSTANTMEASURE") { return new IfcWarpingConstantMeasure(obj.value); }
	if (obj.label === "IFCWARPINGMOMENTMEASURE") { return new IfcWarpingMomentMeasure(obj.value); }
	if (obj.label === "IFCACTIONREQUESTTYPEENUM") { return new IfcActionRequestTypeEnum(obj.value); }
	if (obj.label === "IFCACTIONSOURCETYPEENUM") { return new IfcActionSourceTypeEnum(obj.value); }
	if (obj.label === "IFCACTIONTYPEENUM") { return new IfcActionTypeEnum(obj.value); }
	if (obj.label === "IFCACTUATORTYPEENUM") { return new IfcActuatorTypeEnum(obj.value); }
	if (obj.label === "IFCADDRESSTYPEENUM") { return new IfcAddressTypeEnum(obj.value); }
	if (obj.label === "IFCAIRTERMINALBOXTYPEENUM") { return new IfcAirTerminalBoxTypeEnum(obj.value); }
	if (obj.label === "IFCAIRTERMINALTYPEENUM") { return new IfcAirTerminalTypeEnum(obj.value); }
	if (obj.label === "IFCAIRTOAIRHEATRECOVERYTYPEENUM") { return new IfcAirToAirHeatRecoveryTypeEnum(obj.value); }
	if (obj.label === "IFCALARMTYPEENUM") { return new IfcAlarmTypeEnum(obj.value); }
	if (obj.label === "IFCALIGNMENTTYPEENUM") { return new IfcAlignmentTypeEnum(obj.value); }
	if (obj.label === "IFCANALYSISMODELTYPEENUM") { return new IfcAnalysisModelTypeEnum(obj.value); }
	if (obj.label === "IFCANALYSISTHEORYTYPEENUM") { return new IfcAnalysisTheoryTypeEnum(obj.value); }
	if (obj.label === "IFCARITHMETICOPERATORENUM") { return new IfcArithmeticOperatorEnum(obj.value); }
	if (obj.label === "IFCASSEMBLYPLACEENUM") { return new IfcAssemblyPlaceEnum(obj.value); }
	if (obj.label === "IFCAUDIOVISUALAPPLIANCETYPEENUM") { return new IfcAudioVisualApplianceTypeEnum(obj.value); }
	if (obj.label === "IFCBSPLINECURVEFORM") { return new IfcBSplineCurveForm(obj.value); }
	if (obj.label === "IFCBSPLINESURFACEFORM") { return new IfcBSplineSurfaceForm(obj.value); }
	if (obj.label === "IFCBEAMTYPEENUM") { return new IfcBeamTypeEnum(obj.value); }
	if (obj.label === "IFCBEARINGTYPEDISPLACEMENTENUM") { return new IfcBearingTypeDisplacementEnum(obj.value); }
	if (obj.label === "IFCBEARINGTYPEENUM") { return new IfcBearingTypeEnum(obj.value); }
	if (obj.label === "IFCBENCHMARKENUM") { return new IfcBenchmarkEnum(obj.value); }
	if (obj.label === "IFCBOILERTYPEENUM") { return new IfcBoilerTypeEnum(obj.value); }
	if (obj.label === "IFCBOOLEANOPERATOR") { return new IfcBooleanOperator(obj.value); }
	if (obj.label === "IFCBRIDGEPARTTYPEENUM") { return new IfcBridgePartTypeEnum(obj.value); }
	if (obj.label === "IFCBRIDGETYPEENUM") { return new IfcBridgeTypeEnum(obj.value); }
	if (obj.label === "IFCBUILDINGELEMENTPARTTYPEENUM") { return new IfcBuildingElementPartTypeEnum(obj.value); }
	if (obj.label === "IFCBUILDINGELEMENTPROXYTYPEENUM") { return new IfcBuildingElementProxyTypeEnum(obj.value); }
	if (obj.label === "IFCBUILDINGSYSTEMTYPEENUM") { return new IfcBuildingSystemTypeEnum(obj.value); }
	if (obj.label === "IFCBURNERTYPEENUM") { return new IfcBurnerTypeEnum(obj.value); }
	if (obj.label === "IFCCABLECARRIERFITTINGTYPEENUM") { return new IfcCableCarrierFittingTypeEnum(obj.value); }
	if (obj.label === "IFCCABLECARRIERSEGMENTTYPEENUM") { return new IfcCableCarrierSegmentTypeEnum(obj.value); }
	if (obj.label === "IFCCABLEFITTINGTYPEENUM") { return new IfcCableFittingTypeEnum(obj.value); }
	if (obj.label === "IFCCABLESEGMENTTYPEENUM") { return new IfcCableSegmentTypeEnum(obj.value); }
	if (obj.label === "IFCCAISSONFOUNDATIONTYPEENUM") { return new IfcCaissonFoundationTypeEnum(obj.value); }
	if (obj.label === "IFCCHANGEACTIONENUM") { return new IfcChangeActionEnum(obj.value); }
	if (obj.label === "IFCCHILLERTYPEENUM") { return new IfcChillerTypeEnum(obj.value); }
	if (obj.label === "IFCCHIMNEYTYPEENUM") { return new IfcChimneyTypeEnum(obj.value); }
	if (obj.label === "IFCCOILTYPEENUM") { return new IfcCoilTypeEnum(obj.value); }
	if (obj.label === "IFCCOLUMNTYPEENUM") { return new IfcColumnTypeEnum(obj.value); }
	if (obj.label === "IFCCOMMUNICATIONSAPPLIANCETYPEENUM") { return new IfcCommunicationsApplianceTypeEnum(obj.value); }
	if (obj.label === "IFCCOMPLEXPROPERTYTEMPLATETYPEENUM") { return new IfcComplexPropertyTemplateTypeEnum(obj.value); }
	if (obj.label === "IFCCOMPRESSORTYPEENUM") { return new IfcCompressorTypeEnum(obj.value); }
	if (obj.label === "IFCCONDENSERTYPEENUM") { return new IfcCondenserTypeEnum(obj.value); }
	if (obj.label === "IFCCONNECTIONTYPEENUM") { return new IfcConnectionTypeEnum(obj.value); }
	if (obj.label === "IFCCONSTRAINTENUM") { return new IfcConstraintEnum(obj.value); }
	if (obj.label === "IFCCONSTRUCTIONEQUIPMENTRESOURCETYPEENUM") { return new IfcConstructionEquipmentResourceTypeEnum(obj.value); }
	if (obj.label === "IFCCONSTRUCTIONMATERIALRESOURCETYPEENUM") { return new IfcConstructionMaterialResourceTypeEnum(obj.value); }
	if (obj.label === "IFCCONSTRUCTIONPRODUCTRESOURCETYPEENUM") { return new IfcConstructionProductResourceTypeEnum(obj.value); }
	if (obj.label === "IFCCONTROLLERTYPEENUM") { return new IfcControllerTypeEnum(obj.value); }
	if (obj.label === "IFCCOOLEDBEAMTYPEENUM") { return new IfcCooledBeamTypeEnum(obj.value); }
	if (obj.label === "IFCCOOLINGTOWERTYPEENUM") { return new IfcCoolingTowerTypeEnum(obj.value); }
	if (obj.label === "IFCCOSTITEMTYPEENUM") { return new IfcCostItemTypeEnum(obj.value); }
	if (obj.label === "IFCCOSTSCHEDULETYPEENUM") { return new IfcCostScheduleTypeEnum(obj.value); }
	if (obj.label === "IFCCOVERINGTYPEENUM") { return new IfcCoveringTypeEnum(obj.value); }
	if (obj.label === "IFCCREWRESOURCETYPEENUM") { return new IfcCrewResourceTypeEnum(obj.value); }
	if (obj.label === "IFCCURTAINWALLTYPEENUM") { return new IfcCurtainWallTypeEnum(obj.value); }
	if (obj.label === "IFCCURVEINTERPOLATIONENUM") { return new IfcCurveInterpolationEnum(obj.value); }
	if (obj.label === "IFCDAMPERTYPEENUM") { return new IfcDamperTypeEnum(obj.value); }
	if (obj.label === "IFCDATAORIGINENUM") { return new IfcDataOriginEnum(obj.value); }
	if (obj.label === "IFCDERIVEDUNITENUM") { return new IfcDerivedUnitEnum(obj.value); }
	if (obj.label === "IFCDIRECTIONSENSEENUM") { return new IfcDirectionSenseEnum(obj.value); }
	if (obj.label === "IFCDISCRETEACCESSORYTYPEENUM") { return new IfcDiscreteAccessoryTypeEnum(obj.value); }
	if (obj.label === "IFCDISTRIBUTIONCHAMBERELEMENTTYPEENUM") { return new IfcDistributionChamberElementTypeEnum(obj.value); }
	if (obj.label === "IFCDISTRIBUTIONPORTTYPEENUM") { return new IfcDistributionPortTypeEnum(obj.value); }
	if (obj.label === "IFCDISTRIBUTIONSYSTEMENUM") { return new IfcDistributionSystemEnum(obj.value); }
	if (obj.label === "IFCDOCUMENTCONFIDENTIALITYENUM") { return new IfcDocumentConfidentialityEnum(obj.value); }
	if (obj.label === "IFCDOCUMENTSTATUSENUM") { return new IfcDocumentStatusEnum(obj.value); }
	if (obj.label === "IFCDOORPANELOPERATIONENUM") { return new IfcDoorPanelOperationEnum(obj.value); }
	if (obj.label === "IFCDOORPANELPOSITIONENUM") { return new IfcDoorPanelPositionEnum(obj.value); }
	if (obj.label === "IFCDOORSTYLECONSTRUCTIONENUM") { return new IfcDoorStyleConstructionEnum(obj.value); }
	if (obj.label === "IFCDOORSTYLEOPERATIONENUM") { return new IfcDoorStyleOperationEnum(obj.value); }
	if (obj.label === "IFCDOORTYPEENUM") { return new IfcDoorTypeEnum(obj.value); }
	if (obj.label === "IFCDOORTYPEOPERATIONENUM") { return new IfcDoorTypeOperationEnum(obj.value); }
	if (obj.label === "IFCDUCTFITTINGTYPEENUM") { return new IfcDuctFittingTypeEnum(obj.value); }
	if (obj.label === "IFCDUCTSEGMENTTYPEENUM") { return new IfcDuctSegmentTypeEnum(obj.value); }
	if (obj.label === "IFCDUCTSILENCERTYPEENUM") { return new IfcDuctSilencerTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICAPPLIANCETYPEENUM") { return new IfcElectricApplianceTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICDISTRIBUTIONBOARDTYPEENUM") { return new IfcElectricDistributionBoardTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICFLOWSTORAGEDEVICETYPEENUM") { return new IfcElectricFlowStorageDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICGENERATORTYPEENUM") { return new IfcElectricGeneratorTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICMOTORTYPEENUM") { return new IfcElectricMotorTypeEnum(obj.value); }
	if (obj.label === "IFCELECTRICTIMECONTROLTYPEENUM") { return new IfcElectricTimeControlTypeEnum(obj.value); }
	if (obj.label === "IFCELEMENTASSEMBLYTYPEENUM") { return new IfcElementAssemblyTypeEnum(obj.value); }
	if (obj.label === "IFCELEMENTCOMPOSITIONENUM") { return new IfcElementCompositionEnum(obj.value); }
	if (obj.label === "IFCENGINETYPEENUM") { return new IfcEngineTypeEnum(obj.value); }
	if (obj.label === "IFCEVAPORATIVECOOLERTYPEENUM") { return new IfcEvaporativeCoolerTypeEnum(obj.value); }
	if (obj.label === "IFCEVAPORATORTYPEENUM") { return new IfcEvaporatorTypeEnum(obj.value); }
	if (obj.label === "IFCEVENTTRIGGERTYPEENUM") { return new IfcEventTriggerTypeEnum(obj.value); }
	if (obj.label === "IFCEVENTTYPEENUM") { return new IfcEventTypeEnum(obj.value); }
	if (obj.label === "IFCEXTERNALSPATIALELEMENTTYPEENUM") { return new IfcExternalSpatialElementTypeEnum(obj.value); }
	if (obj.label === "IFCFANTYPEENUM") { return new IfcFanTypeEnum(obj.value); }
	if (obj.label === "IFCFASTENERTYPEENUM") { return new IfcFastenerTypeEnum(obj.value); }
	if (obj.label === "IFCFILTERTYPEENUM") { return new IfcFilterTypeEnum(obj.value); }
	if (obj.label === "IFCFIRESUPPRESSIONTERMINALTYPEENUM") { return new IfcFireSuppressionTerminalTypeEnum(obj.value); }
	if (obj.label === "IFCFLOWDIRECTIONENUM") { return new IfcFlowDirectionEnum(obj.value); }
	if (obj.label === "IFCFLOWINSTRUMENTTYPEENUM") { return new IfcFlowInstrumentTypeEnum(obj.value); }
	if (obj.label === "IFCFLOWMETERTYPEENUM") { return new IfcFlowMeterTypeEnum(obj.value); }
	if (obj.label === "IFCFOOTINGTYPEENUM") { return new IfcFootingTypeEnum(obj.value); }
	if (obj.label === "IFCFURNITURETYPEENUM") { return new IfcFurnitureTypeEnum(obj.value); }
	if (obj.label === "IFCGEOGRAPHICELEMENTTYPEENUM") { return new IfcGeographicElementTypeEnum(obj.value); }
	if (obj.label === "IFCGEOMETRICPROJECTIONENUM") { return new IfcGeometricProjectionEnum(obj.value); }
	if (obj.label === "IFCGLOBALORLOCALENUM") { return new IfcGlobalOrLocalEnum(obj.value); }
	if (obj.label === "IFCGRIDTYPEENUM") { return new IfcGridTypeEnum(obj.value); }
	if (obj.label === "IFCHEATEXCHANGERTYPEENUM") { return new IfcHeatExchangerTypeEnum(obj.value); }
	if (obj.label === "IFCHUMIDIFIERTYPEENUM") { return new IfcHumidifierTypeEnum(obj.value); }
	if (obj.label === "IFCINTERCEPTORTYPEENUM") { return new IfcInterceptorTypeEnum(obj.value); }
	if (obj.label === "IFCINTERNALOREXTERNALENUM") { return new IfcInternalOrExternalEnum(obj.value); }
	if (obj.label === "IFCINVENTORYTYPEENUM") { return new IfcInventoryTypeEnum(obj.value); }
	if (obj.label === "IFCJUNCTIONBOXTYPEENUM") { return new IfcJunctionBoxTypeEnum(obj.value); }
	if (obj.label === "IFCKNOTTYPE") { return new IfcKnotType(obj.value); }
	if (obj.label === "IFCLABORRESOURCETYPEENUM") { return new IfcLaborResourceTypeEnum(obj.value); }
	if (obj.label === "IFCLAMPTYPEENUM") { return new IfcLampTypeEnum(obj.value); }
	if (obj.label === "IFCLAYERSETDIRECTIONENUM") { return new IfcLayerSetDirectionEnum(obj.value); }
	if (obj.label === "IFCLIGHTDISTRIBUTIONCURVEENUM") { return new IfcLightDistributionCurveEnum(obj.value); }
	if (obj.label === "IFCLIGHTEMISSIONSOURCEENUM") { return new IfcLightEmissionSourceEnum(obj.value); }
	if (obj.label === "IFCLIGHTFIXTURETYPEENUM") { return new IfcLightFixtureTypeEnum(obj.value); }
	if (obj.label === "IFCLOADGROUPTYPEENUM") { return new IfcLoadGroupTypeEnum(obj.value); }
	if (obj.label === "IFCLOGICALOPERATORENUM") { return new IfcLogicalOperatorEnum(obj.value); }
	if (obj.label === "IFCMECHANICALFASTENERTYPEENUM") { return new IfcMechanicalFastenerTypeEnum(obj.value); }
	if (obj.label === "IFCMEDICALDEVICETYPEENUM") { return new IfcMedicalDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCMEMBERTYPEENUM") { return new IfcMemberTypeEnum(obj.value); }
	if (obj.label === "IFCMOTORCONNECTIONTYPEENUM") { return new IfcMotorConnectionTypeEnum(obj.value); }
	if (obj.label === "IFCNULLSTYLE") { return new IfcNullStyle(obj.value); }
	if (obj.label === "IFCOBJECTTYPEENUM") { return new IfcObjectTypeEnum(obj.value); }
	if (obj.label === "IFCOBJECTIVEENUM") { return new IfcObjectiveEnum(obj.value); }
	if (obj.label === "IFCOCCUPANTTYPEENUM") { return new IfcOccupantTypeEnum(obj.value); }
	if (obj.label === "IFCOPENINGELEMENTTYPEENUM") { return new IfcOpeningElementTypeEnum(obj.value); }
	if (obj.label === "IFCOUTLETTYPEENUM") { return new IfcOutletTypeEnum(obj.value); }
	if (obj.label === "IFCPERFORMANCEHISTORYTYPEENUM") { return new IfcPerformanceHistoryTypeEnum(obj.value); }
	if (obj.label === "IFCPERMEABLECOVERINGOPERATIONENUM") { return new IfcPermeableCoveringOperationEnum(obj.value); }
	if (obj.label === "IFCPERMITTYPEENUM") { return new IfcPermitTypeEnum(obj.value); }
	if (obj.label === "IFCPHYSICALORVIRTUALENUM") { return new IfcPhysicalOrVirtualEnum(obj.value); }
	if (obj.label === "IFCPILECONSTRUCTIONENUM") { return new IfcPileConstructionEnum(obj.value); }
	if (obj.label === "IFCPILETYPEENUM") { return new IfcPileTypeEnum(obj.value); }
	if (obj.label === "IFCPIPEFITTINGTYPEENUM") { return new IfcPipeFittingTypeEnum(obj.value); }
	if (obj.label === "IFCPIPESEGMENTTYPEENUM") { return new IfcPipeSegmentTypeEnum(obj.value); }
	if (obj.label === "IFCPLATETYPEENUM") { return new IfcPlateTypeEnum(obj.value); }
	if (obj.label === "IFCPREFERREDSURFACECURVEREPRESENTATION") { return new IfcPreferredSurfaceCurveRepresentation(obj.value); }
	if (obj.label === "IFCPROCEDURETYPEENUM") { return new IfcProcedureTypeEnum(obj.value); }
	if (obj.label === "IFCPROFILETYPEENUM") { return new IfcProfileTypeEnum(obj.value); }
	if (obj.label === "IFCPROJECTORDERTYPEENUM") { return new IfcProjectOrderTypeEnum(obj.value); }
	if (obj.label === "IFCPROJECTEDORTRUELENGTHENUM") { return new IfcProjectedOrTrueLengthEnum(obj.value); }
	if (obj.label === "IFCPROJECTIONELEMENTTYPEENUM") { return new IfcProjectionElementTypeEnum(obj.value); }
	if (obj.label === "IFCPROPERTYSETTEMPLATETYPEENUM") { return new IfcPropertySetTemplateTypeEnum(obj.value); }
	if (obj.label === "IFCPROTECTIVEDEVICETRIPPINGUNITTYPEENUM") { return new IfcProtectiveDeviceTrippingUnitTypeEnum(obj.value); }
	if (obj.label === "IFCPROTECTIVEDEVICETYPEENUM") { return new IfcProtectiveDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCPUMPTYPEENUM") { return new IfcPumpTypeEnum(obj.value); }
	if (obj.label === "IFCRAILINGTYPEENUM") { return new IfcRailingTypeEnum(obj.value); }
	if (obj.label === "IFCRAMPFLIGHTTYPEENUM") { return new IfcRampFlightTypeEnum(obj.value); }
	if (obj.label === "IFCRAMPTYPEENUM") { return new IfcRampTypeEnum(obj.value); }
	if (obj.label === "IFCRECURRENCETYPEENUM") { return new IfcRecurrenceTypeEnum(obj.value); }
	if (obj.label === "IFCREFERENTTYPEENUM") { return new IfcReferentTypeEnum(obj.value); }
	if (obj.label === "IFCREFLECTANCEMETHODENUM") { return new IfcReflectanceMethodEnum(obj.value); }
	if (obj.label === "IFCREINFORCINGBARROLEENUM") { return new IfcReinforcingBarRoleEnum(obj.value); }
	if (obj.label === "IFCREINFORCINGBARSURFACEENUM") { return new IfcReinforcingBarSurfaceEnum(obj.value); }
	if (obj.label === "IFCREINFORCINGBARTYPEENUM") { return new IfcReinforcingBarTypeEnum(obj.value); }
	if (obj.label === "IFCREINFORCINGMESHTYPEENUM") { return new IfcReinforcingMeshTypeEnum(obj.value); }
	if (obj.label === "IFCROLEENUM") { return new IfcRoleEnum(obj.value); }
	if (obj.label === "IFCROOFTYPEENUM") { return new IfcRoofTypeEnum(obj.value); }
	if (obj.label === "IFCSIPREFIX") { return new IfcSIPrefix(obj.value); }
	if (obj.label === "IFCSIUNITNAME") { return new IfcSIUnitName(obj.value); }
	if (obj.label === "IFCSANITARYTERMINALTYPEENUM") { return new IfcSanitaryTerminalTypeEnum(obj.value); }
	if (obj.label === "IFCSECTIONTYPEENUM") { return new IfcSectionTypeEnum(obj.value); }
	if (obj.label === "IFCSENSORTYPEENUM") { return new IfcSensorTypeEnum(obj.value); }
	if (obj.label === "IFCSEQUENCEENUM") { return new IfcSequenceEnum(obj.value); }
	if (obj.label === "IFCSHADINGDEVICETYPEENUM") { return new IfcShadingDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCSIMPLEPROPERTYTEMPLATETYPEENUM") { return new IfcSimplePropertyTemplateTypeEnum(obj.value); }
	if (obj.label === "IFCSLABTYPEENUM") { return new IfcSlabTypeEnum(obj.value); }
	if (obj.label === "IFCSOLARDEVICETYPEENUM") { return new IfcSolarDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCSPACEHEATERTYPEENUM") { return new IfcSpaceHeaterTypeEnum(obj.value); }
	if (obj.label === "IFCSPACETYPEENUM") { return new IfcSpaceTypeEnum(obj.value); }
	if (obj.label === "IFCSPATIALZONETYPEENUM") { return new IfcSpatialZoneTypeEnum(obj.value); }
	if (obj.label === "IFCSTACKTERMINALTYPEENUM") { return new IfcStackTerminalTypeEnum(obj.value); }
	if (obj.label === "IFCSTAIRFLIGHTTYPEENUM") { return new IfcStairFlightTypeEnum(obj.value); }
	if (obj.label === "IFCSTAIRTYPEENUM") { return new IfcStairTypeEnum(obj.value); }
	if (obj.label === "IFCSTATEENUM") { return new IfcStateEnum(obj.value); }
	if (obj.label === "IFCSTRUCTURALCURVEACTIVITYTYPEENUM") { return new IfcStructuralCurveActivityTypeEnum(obj.value); }
	if (obj.label === "IFCSTRUCTURALCURVEMEMBERTYPEENUM") { return new IfcStructuralCurveMemberTypeEnum(obj.value); }
	if (obj.label === "IFCSTRUCTURALSURFACEACTIVITYTYPEENUM") { return new IfcStructuralSurfaceActivityTypeEnum(obj.value); }
	if (obj.label === "IFCSTRUCTURALSURFACEMEMBERTYPEENUM") { return new IfcStructuralSurfaceMemberTypeEnum(obj.value); }
	if (obj.label === "IFCSUBCONTRACTRESOURCETYPEENUM") { return new IfcSubContractResourceTypeEnum(obj.value); }
	if (obj.label === "IFCSURFACEFEATURETYPEENUM") { return new IfcSurfaceFeatureTypeEnum(obj.value); }
	if (obj.label === "IFCSURFACESIDE") { return new IfcSurfaceSide(obj.value); }
	if (obj.label === "IFCSWITCHINGDEVICETYPEENUM") { return new IfcSwitchingDeviceTypeEnum(obj.value); }
	if (obj.label === "IFCSYSTEMFURNITUREELEMENTTYPEENUM") { return new IfcSystemFurnitureElementTypeEnum(obj.value); }
	if (obj.label === "IFCTANKTYPEENUM") { return new IfcTankTypeEnum(obj.value); }
	if (obj.label === "IFCTASKDURATIONENUM") { return new IfcTaskDurationEnum(obj.value); }
	if (obj.label === "IFCTASKTYPEENUM") { return new IfcTaskTypeEnum(obj.value); }
	if (obj.label === "IFCTENDONANCHORTYPEENUM") { return new IfcTendonAnchorTypeEnum(obj.value); }
	if (obj.label === "IFCTENDONCONDUITTYPEENUM") { return new IfcTendonConduitTypeEnum(obj.value); }
	if (obj.label === "IFCTENDONTYPEENUM") { return new IfcTendonTypeEnum(obj.value); }
	if (obj.label === "IFCTEXTPATH") { return new IfcTextPath(obj.value); }
	if (obj.label === "IFCTIMESERIESDATATYPEENUM") { return new IfcTimeSeriesDataTypeEnum(obj.value); }
	if (obj.label === "IFCTRANSFORMERTYPEENUM") { return new IfcTransformerTypeEnum(obj.value); }
	if (obj.label === "IFCTRANSITIONCODE") { return new IfcTransitionCode(obj.value); }
	if (obj.label === "IFCTRANSITIONCURVETYPE") { return new IfcTransitionCurveType(obj.value); }
	if (obj.label === "IFCTRANSPORTELEMENTTYPEENUM") { return new IfcTransportElementTypeEnum(obj.value); }
	if (obj.label === "IFCTRIMMINGPREFERENCE") { return new IfcTrimmingPreference(obj.value); }
	if (obj.label === "IFCTUBEBUNDLETYPEENUM") { return new IfcTubeBundleTypeEnum(obj.value); }
	if (obj.label === "IFCUNITENUM") { return new IfcUnitEnum(obj.value); }
	if (obj.label === "IFCUNITARYCONTROLELEMENTTYPEENUM") { return new IfcUnitaryControlElementTypeEnum(obj.value); }
	if (obj.label === "IFCUNITARYEQUIPMENTTYPEENUM") { return new IfcUnitaryEquipmentTypeEnum(obj.value); }
	if (obj.label === "IFCVALVETYPEENUM") { return new IfcValveTypeEnum(obj.value); }
	if (obj.label === "IFCVIBRATIONDAMPERTYPEENUM") { return new IfcVibrationDamperTypeEnum(obj.value); }
	if (obj.label === "IFCVIBRATIONISOLATORTYPEENUM") { return new IfcVibrationIsolatorTypeEnum(obj.value); }
	if (obj.label === "IFCVOIDINGFEATURETYPEENUM") { return new IfcVoidingFeatureTypeEnum(obj.value); }
	if (obj.label === "IFCWALLTYPEENUM") { return new IfcWallTypeEnum(obj.value); }
	if (obj.label === "IFCWASTETERMINALTYPEENUM") { return new IfcWasteTerminalTypeEnum(obj.value); }
	if (obj.label === "IFCWINDOWPANELOPERATIONENUM") { return new IfcWindowPanelOperationEnum(obj.value); }
	if (obj.label === "IFCWINDOWPANELPOSITIONENUM") { return new IfcWindowPanelPositionEnum(obj.value); }
	if (obj.label === "IFCWINDOWSTYLECONSTRUCTIONENUM") { return new IfcWindowStyleConstructionEnum(obj.value); }
	if (obj.label === "IFCWINDOWSTYLEOPERATIONENUM") { return new IfcWindowStyleOperationEnum(obj.value); }
	if (obj.label === "IFCWINDOWTYPEENUM") { return new IfcWindowTypeEnum(obj.value); }
	if (obj.label === "IFCWINDOWTYPEPARTITIONINGENUM") { return new IfcWindowTypePartitioningEnum(obj.value); }
	if (obj.label === "IFCWORKCALENDARTYPEENUM") { return new IfcWorkCalendarTypeEnum(obj.value); }
	if (obj.label === "IFCWORKPLANTYPEENUM") { return new IfcWorkPlanTypeEnum(obj.value); }
	if (obj.label === "IFCWORKSCHEDULETYPEENUM") { return new IfcWorkScheduleTypeEnum(obj.value); }
	console.log("Unknown type: " + name);
};
export class IfcActionRequest {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, PredefinedType: IfcActionRequestTypeEnum | null, Status: IfcLabel | null, LongDescription: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.PredefinedType = PredefinedType;
		this.Status = Status;
		this.LongDescription = LongDescription;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	PredefinedType: IfcActionRequestTypeEnum | null;
	Status: IfcLabel | null;
	LongDescription: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcActionRequest
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Status = tape[ptr++];
		let LongDescription = tape[ptr++];
		return new IfcActionRequest(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, PredefinedType, Status, LongDescription);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.PredefinedType);;
		args.push(this.Status);;
		args.push(this.LongDescription);;
		return args;
	}
};
export class IfcActor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, TheActor: IfcActorSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.TheActor = TheActor;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	TheActor: IfcActorSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcActor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let TheActor = tape[ptr++];
		return new IfcActor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, TheActor);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.TheActor);;
		return args;
	}
};
export class IfcActorRole {
	constructor(expressID: number, type: number, Role: IfcRoleEnum , UserDefinedRole: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Role = Role;
		this.UserDefinedRole = UserDefinedRole;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Role: IfcRoleEnum ;
	UserDefinedRole: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcActorRole
	{
		let ptr = 0;
		let Role = tape[ptr++];
		let UserDefinedRole = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcActorRole(expressID, type, Role, UserDefinedRole, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Role);;
		args.push(this.UserDefinedRole);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcActuator {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcActuatorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcActuatorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcActuator
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcActuator(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcActuatorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcActuatorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcActuatorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcActuatorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcActuatorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAddress {
	constructor(expressID: number, type: number, Purpose: IfcAddressTypeEnum | null, Description: IfcText | null, UserDefinedPurpose: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Purpose = Purpose;
		this.Description = Description;
		this.UserDefinedPurpose = UserDefinedPurpose;
	}
	expressID: number;
	type: number;
	Purpose: IfcAddressTypeEnum | null;
	Description: IfcText | null;
	UserDefinedPurpose: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAddress
	{
		let ptr = 0;
		let Purpose = tape[ptr++];
		let Description = tape[ptr++];
		let UserDefinedPurpose = tape[ptr++];
		return new IfcAddress(expressID, type, Purpose, Description, UserDefinedPurpose);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Purpose);;
		args.push(this.Description);;
		args.push(this.UserDefinedPurpose);;
		return args;
	}
};
export class IfcAdvancedBrep {
	constructor(expressID: number, type: number, Outer: (Handle<IfcClosedShell> | IfcClosedShell) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Outer = Outer;
	}
	expressID: number;
	type: number;
	Outer: (Handle<IfcClosedShell> | IfcClosedShell) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAdvancedBrep
	{
		let ptr = 0;
		let Outer = tape[ptr++];
		return new IfcAdvancedBrep(expressID, type, Outer);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Outer);;
		return args;
	}
};
export class IfcAdvancedBrepWithVoids {
	constructor(expressID: number, type: number, Outer: (Handle<IfcClosedShell> | IfcClosedShell) , Voids: (Handle<IfcClosedShell> | IfcClosedShell)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Outer = Outer;
		this.Voids = Voids;
	}
	expressID: number;
	type: number;
	Outer: (Handle<IfcClosedShell> | IfcClosedShell) ;
	Voids: (Handle<IfcClosedShell> | IfcClosedShell)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAdvancedBrepWithVoids
	{
		let ptr = 0;
		let Outer = tape[ptr++];
		let Voids = tape[ptr++];
		return new IfcAdvancedBrepWithVoids(expressID, type, Outer, Voids);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Outer);;
		args.push(this.Voids);;
		return args;
	}
};
export class IfcAdvancedFace {
	constructor(expressID: number, type: number, Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] , FaceSurface: (Handle<IfcSurface> | IfcSurface) , SameSense: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.Bounds = Bounds;
		this.FaceSurface = FaceSurface;
		this.SameSense = SameSense;
	}
	expressID: number;
	type: number;
	Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] ;
	FaceSurface: (Handle<IfcSurface> | IfcSurface) ;
	SameSense: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAdvancedFace
	{
		let ptr = 0;
		let Bounds = tape[ptr++];
		let FaceSurface = tape[ptr++];
		let SameSense = tape[ptr++];
		return new IfcAdvancedFace(expressID, type, Bounds, FaceSurface, SameSense);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Bounds);;
		args.push(this.FaceSurface);;
		args.push(this.SameSense);;
		return args;
	}
};
export class IfcAirTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcAirTerminalTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcAirTerminalTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAirTerminalBox {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcAirTerminalBoxTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcAirTerminalBoxTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirTerminalBox
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirTerminalBox(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAirTerminalBoxType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcAirTerminalBoxTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcAirTerminalBoxTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirTerminalBoxType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirTerminalBoxType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAirTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcAirTerminalTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcAirTerminalTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAirToAirHeatRecovery {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcAirToAirHeatRecoveryTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcAirToAirHeatRecoveryTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirToAirHeatRecovery
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirToAirHeatRecovery(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAirToAirHeatRecoveryType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcAirToAirHeatRecoveryTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcAirToAirHeatRecoveryTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAirToAirHeatRecoveryType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAirToAirHeatRecoveryType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAlarm {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcAlarmTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcAlarmTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlarm
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAlarm(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAlarmType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcAlarmTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcAlarmTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlarmType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAlarmType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAlignment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Axis: (Handle<IfcCurve> | IfcCurve) , PredefinedType: IfcAlignmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Axis = Axis;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Axis: (Handle<IfcCurve> | IfcCurve) ;
	PredefinedType: IfcAlignmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Axis = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAlignment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Axis, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Axis);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAlignment2DHorizontal {
	constructor(expressID: number, type: number, StartDistAlong: IfcLengthMeasure | null, Segments: (Handle<IfcAlignment2DHorizontalSegment> | IfcAlignment2DHorizontalSegment)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartDistAlong = StartDistAlong;
		this.Segments = Segments;
	}
	expressID: number;
	type: number;
	StartDistAlong: IfcLengthMeasure | null;
	Segments: (Handle<IfcAlignment2DHorizontalSegment> | IfcAlignment2DHorizontalSegment)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DHorizontal
	{
		let ptr = 0;
		let StartDistAlong = tape[ptr++];
		let Segments = tape[ptr++];
		return new IfcAlignment2DHorizontal(expressID, type, StartDistAlong, Segments);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartDistAlong);;
		args.push(this.Segments);;
		return args;
	}
};
export class IfcAlignment2DHorizontalSegment {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null, CurveGeometry: (Handle<IfcCurveSegment2D> | IfcCurveSegment2D) )
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
		this.CurveGeometry = CurveGeometry;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	CurveGeometry: (Handle<IfcCurveSegment2D> | IfcCurveSegment2D) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DHorizontalSegment
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		let CurveGeometry = tape[ptr++];
		return new IfcAlignment2DHorizontalSegment(expressID, type, TangentialContinuity, StartTag, EndTag, CurveGeometry);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		args.push(this.CurveGeometry);;
		return args;
	}
};
export class IfcAlignment2DSegment {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DSegment
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		return new IfcAlignment2DSegment(expressID, type, TangentialContinuity, StartTag, EndTag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		return args;
	}
};
export class IfcAlignment2DVerSegCircularArc {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null, StartDistAlong: IfcLengthMeasure , HorizontalLength: IfcPositiveLengthMeasure , StartHeight: IfcLengthMeasure , StartGradient: IfcRatioMeasure , Radius: IfcPositiveLengthMeasure , IsConvex: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
		this.StartDistAlong = StartDistAlong;
		this.HorizontalLength = HorizontalLength;
		this.StartHeight = StartHeight;
		this.StartGradient = StartGradient;
		this.Radius = Radius;
		this.IsConvex = IsConvex;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	StartDistAlong: IfcLengthMeasure ;
	HorizontalLength: IfcPositiveLengthMeasure ;
	StartHeight: IfcLengthMeasure ;
	StartGradient: IfcRatioMeasure ;
	Radius: IfcPositiveLengthMeasure ;
	IsConvex: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DVerSegCircularArc
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		let StartDistAlong = tape[ptr++];
		let HorizontalLength = tape[ptr++];
		let StartHeight = tape[ptr++];
		let StartGradient = tape[ptr++];
		let Radius = tape[ptr++];
		let IsConvex = tape[ptr++];
		return new IfcAlignment2DVerSegCircularArc(expressID, type, TangentialContinuity, StartTag, EndTag, StartDistAlong, HorizontalLength, StartHeight, StartGradient, Radius, IsConvex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		args.push(this.StartDistAlong);;
		args.push(this.HorizontalLength);;
		args.push(this.StartHeight);;
		args.push(this.StartGradient);;
		args.push(this.Radius);;
		args.push(this.IsConvex);;
		return args;
	}
};
export class IfcAlignment2DVerSegLine {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null, StartDistAlong: IfcLengthMeasure , HorizontalLength: IfcPositiveLengthMeasure , StartHeight: IfcLengthMeasure , StartGradient: IfcRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
		this.StartDistAlong = StartDistAlong;
		this.HorizontalLength = HorizontalLength;
		this.StartHeight = StartHeight;
		this.StartGradient = StartGradient;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	StartDistAlong: IfcLengthMeasure ;
	HorizontalLength: IfcPositiveLengthMeasure ;
	StartHeight: IfcLengthMeasure ;
	StartGradient: IfcRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DVerSegLine
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		let StartDistAlong = tape[ptr++];
		let HorizontalLength = tape[ptr++];
		let StartHeight = tape[ptr++];
		let StartGradient = tape[ptr++];
		return new IfcAlignment2DVerSegLine(expressID, type, TangentialContinuity, StartTag, EndTag, StartDistAlong, HorizontalLength, StartHeight, StartGradient);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		args.push(this.StartDistAlong);;
		args.push(this.HorizontalLength);;
		args.push(this.StartHeight);;
		args.push(this.StartGradient);;
		return args;
	}
};
export class IfcAlignment2DVerSegParabolicArc {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null, StartDistAlong: IfcLengthMeasure , HorizontalLength: IfcPositiveLengthMeasure , StartHeight: IfcLengthMeasure , StartGradient: IfcRatioMeasure , ParabolaConstant: IfcPositiveLengthMeasure , IsConvex: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
		this.StartDistAlong = StartDistAlong;
		this.HorizontalLength = HorizontalLength;
		this.StartHeight = StartHeight;
		this.StartGradient = StartGradient;
		this.ParabolaConstant = ParabolaConstant;
		this.IsConvex = IsConvex;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	StartDistAlong: IfcLengthMeasure ;
	HorizontalLength: IfcPositiveLengthMeasure ;
	StartHeight: IfcLengthMeasure ;
	StartGradient: IfcRatioMeasure ;
	ParabolaConstant: IfcPositiveLengthMeasure ;
	IsConvex: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DVerSegParabolicArc
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		let StartDistAlong = tape[ptr++];
		let HorizontalLength = tape[ptr++];
		let StartHeight = tape[ptr++];
		let StartGradient = tape[ptr++];
		let ParabolaConstant = tape[ptr++];
		let IsConvex = tape[ptr++];
		return new IfcAlignment2DVerSegParabolicArc(expressID, type, TangentialContinuity, StartTag, EndTag, StartDistAlong, HorizontalLength, StartHeight, StartGradient, ParabolaConstant, IsConvex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		args.push(this.StartDistAlong);;
		args.push(this.HorizontalLength);;
		args.push(this.StartHeight);;
		args.push(this.StartGradient);;
		args.push(this.ParabolaConstant);;
		args.push(this.IsConvex);;
		return args;
	}
};
export class IfcAlignment2DVertical {
	constructor(expressID: number, type: number, Segments: (Handle<IfcAlignment2DVerticalSegment> | IfcAlignment2DVerticalSegment)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Segments = Segments;
	}
	expressID: number;
	type: number;
	Segments: (Handle<IfcAlignment2DVerticalSegment> | IfcAlignment2DVerticalSegment)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DVertical
	{
		let ptr = 0;
		let Segments = tape[ptr++];
		return new IfcAlignment2DVertical(expressID, type, Segments);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Segments);;
		return args;
	}
};
export class IfcAlignment2DVerticalSegment {
	constructor(expressID: number, type: number, TangentialContinuity: IfcBoolean | null, StartTag: IfcLabel | null, EndTag: IfcLabel | null, StartDistAlong: IfcLengthMeasure , HorizontalLength: IfcPositiveLengthMeasure , StartHeight: IfcLengthMeasure , StartGradient: IfcRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.TangentialContinuity = TangentialContinuity;
		this.StartTag = StartTag;
		this.EndTag = EndTag;
		this.StartDistAlong = StartDistAlong;
		this.HorizontalLength = HorizontalLength;
		this.StartHeight = StartHeight;
		this.StartGradient = StartGradient;
	}
	expressID: number;
	type: number;
	TangentialContinuity: IfcBoolean | null;
	StartTag: IfcLabel | null;
	EndTag: IfcLabel | null;
	StartDistAlong: IfcLengthMeasure ;
	HorizontalLength: IfcPositiveLengthMeasure ;
	StartHeight: IfcLengthMeasure ;
	StartGradient: IfcRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignment2DVerticalSegment
	{
		let ptr = 0;
		let TangentialContinuity = tape[ptr++];
		let StartTag = tape[ptr++];
		let EndTag = tape[ptr++];
		let StartDistAlong = tape[ptr++];
		let HorizontalLength = tape[ptr++];
		let StartHeight = tape[ptr++];
		let StartGradient = tape[ptr++];
		return new IfcAlignment2DVerticalSegment(expressID, type, TangentialContinuity, StartTag, EndTag, StartDistAlong, HorizontalLength, StartHeight, StartGradient);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TangentialContinuity);;
		args.push(this.StartTag);;
		args.push(this.EndTag);;
		args.push(this.StartDistAlong);;
		args.push(this.HorizontalLength);;
		args.push(this.StartHeight);;
		args.push(this.StartGradient);;
		return args;
	}
};
export class IfcAlignmentCurve {
	constructor(expressID: number, type: number, Horizontal: (Handle<IfcAlignment2DHorizontal> | IfcAlignment2DHorizontal) , Vertical: (Handle<IfcAlignment2DVertical> | IfcAlignment2DVertical) | null, Tag: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Horizontal = Horizontal;
		this.Vertical = Vertical;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	Horizontal: (Handle<IfcAlignment2DHorizontal> | IfcAlignment2DHorizontal) ;
	Vertical: (Handle<IfcAlignment2DVertical> | IfcAlignment2DVertical) | null;
	Tag: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAlignmentCurve
	{
		let ptr = 0;
		let Horizontal = tape[ptr++];
		let Vertical = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcAlignmentCurve(expressID, type, Horizontal, Vertical, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Horizontal);;
		args.push(this.Vertical);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcAnnotation {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAnnotation
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcAnnotation(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcAnnotationFillArea {
	constructor(expressID: number, type: number, OuterBoundary: (Handle<IfcCurve> | IfcCurve) , InnerBoundaries: (Handle<IfcCurve> | IfcCurve)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.OuterBoundary = OuterBoundary;
		this.InnerBoundaries = InnerBoundaries;
	}
	expressID: number;
	type: number;
	OuterBoundary: (Handle<IfcCurve> | IfcCurve) ;
	InnerBoundaries: (Handle<IfcCurve> | IfcCurve)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAnnotationFillArea
	{
		let ptr = 0;
		let OuterBoundary = tape[ptr++];
		let InnerBoundaries = tape[ptr++];
		return new IfcAnnotationFillArea(expressID, type, OuterBoundary, InnerBoundaries);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.OuterBoundary);;
		args.push(this.InnerBoundaries);;
		return args;
	}
};
export class IfcApplication {
	constructor(expressID: number, type: number, ApplicationDeveloper: (Handle<IfcOrganization> | IfcOrganization) , Version: IfcLabel , ApplicationFullName: IfcLabel , ApplicationIdentifier: IfcIdentifier )
	{
		this.expressID = expressID;
		this.type = type;
		this.ApplicationDeveloper = ApplicationDeveloper;
		this.Version = Version;
		this.ApplicationFullName = ApplicationFullName;
		this.ApplicationIdentifier = ApplicationIdentifier;
	}
	expressID: number;
	type: number;
	ApplicationDeveloper: (Handle<IfcOrganization> | IfcOrganization) ;
	Version: IfcLabel ;
	ApplicationFullName: IfcLabel ;
	ApplicationIdentifier: IfcIdentifier ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcApplication
	{
		let ptr = 0;
		let ApplicationDeveloper = tape[ptr++];
		let Version = tape[ptr++];
		let ApplicationFullName = tape[ptr++];
		let ApplicationIdentifier = tape[ptr++];
		return new IfcApplication(expressID, type, ApplicationDeveloper, Version, ApplicationFullName, ApplicationIdentifier);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ApplicationDeveloper);;
		args.push(this.Version);;
		args.push(this.ApplicationFullName);;
		args.push(this.ApplicationIdentifier);;
		return args;
	}
};
export class IfcAppliedValue {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, AppliedValue: IfcAppliedValueSelect | null, UnitBasis: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null, ApplicableDate: IfcDate | null, FixedUntilDate: IfcDate | null, Category: IfcLabel | null, Condition: IfcLabel | null, ArithmeticOperator: IfcArithmeticOperatorEnum | null, Components: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.AppliedValue = AppliedValue;
		this.UnitBasis = UnitBasis;
		this.ApplicableDate = ApplicableDate;
		this.FixedUntilDate = FixedUntilDate;
		this.Category = Category;
		this.Condition = Condition;
		this.ArithmeticOperator = ArithmeticOperator;
		this.Components = Components;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	AppliedValue: IfcAppliedValueSelect | null;
	UnitBasis: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null;
	ApplicableDate: IfcDate | null;
	FixedUntilDate: IfcDate | null;
	Category: IfcLabel | null;
	Condition: IfcLabel | null;
	ArithmeticOperator: IfcArithmeticOperatorEnum | null;
	Components: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAppliedValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let AppliedValue = tape[ptr++];
		let UnitBasis = tape[ptr++];
		let ApplicableDate = tape[ptr++];
		let FixedUntilDate = tape[ptr++];
		let Category = tape[ptr++];
		let Condition = tape[ptr++];
		let ArithmeticOperator = tape[ptr++];
		let Components = tape[ptr++];
		return new IfcAppliedValue(expressID, type, Name, Description, AppliedValue, UnitBasis, ApplicableDate, FixedUntilDate, Category, Condition, ArithmeticOperator, Components);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.AppliedValue);;
		args.push(this.UnitBasis);;
		args.push(this.ApplicableDate);;
		args.push(this.FixedUntilDate);;
		args.push(this.Category);;
		args.push(this.Condition);;
		args.push(this.ArithmeticOperator);;
		args.push(this.Components);;
		return args;
	}
};
export class IfcApproval {
	constructor(expressID: number, type: number, Identifier: IfcIdentifier | null, Name: IfcLabel | null, Description: IfcText | null, TimeOfApproval: IfcDateTime | null, Status: IfcLabel | null, Level: IfcLabel | null, Qualifier: IfcText | null, RequestingApproval: IfcActorSelect | null, GivingApproval: IfcActorSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Identifier = Identifier;
		this.Name = Name;
		this.Description = Description;
		this.TimeOfApproval = TimeOfApproval;
		this.Status = Status;
		this.Level = Level;
		this.Qualifier = Qualifier;
		this.RequestingApproval = RequestingApproval;
		this.GivingApproval = GivingApproval;
	}
	expressID: number;
	type: number;
	Identifier: IfcIdentifier | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	TimeOfApproval: IfcDateTime | null;
	Status: IfcLabel | null;
	Level: IfcLabel | null;
	Qualifier: IfcText | null;
	RequestingApproval: IfcActorSelect | null;
	GivingApproval: IfcActorSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcApproval
	{
		let ptr = 0;
		let Identifier = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let TimeOfApproval = tape[ptr++];
		let Status = tape[ptr++];
		let Level = tape[ptr++];
		let Qualifier = tape[ptr++];
		let RequestingApproval = tape[ptr++];
		let GivingApproval = tape[ptr++];
		return new IfcApproval(expressID, type, Identifier, Name, Description, TimeOfApproval, Status, Level, Qualifier, RequestingApproval, GivingApproval);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Identifier);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.TimeOfApproval);;
		args.push(this.Status);;
		args.push(this.Level);;
		args.push(this.Qualifier);;
		args.push(this.RequestingApproval);;
		args.push(this.GivingApproval);;
		return args;
	}
};
export class IfcApprovalRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingApproval: (Handle<IfcApproval> | IfcApproval) , RelatedApprovals: (Handle<IfcApproval> | IfcApproval)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingApproval = RelatingApproval;
		this.RelatedApprovals = RelatedApprovals;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingApproval: (Handle<IfcApproval> | IfcApproval) ;
	RelatedApprovals: (Handle<IfcApproval> | IfcApproval)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcApprovalRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingApproval = tape[ptr++];
		let RelatedApprovals = tape[ptr++];
		return new IfcApprovalRelationship(expressID, type, Name, Description, RelatingApproval, RelatedApprovals);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingApproval);;
		args.push(this.RelatedApprovals);;
		return args;
	}
};
export class IfcArbitraryClosedProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, OuterCurve: (Handle<IfcCurve> | IfcCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.OuterCurve = OuterCurve;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	OuterCurve: (Handle<IfcCurve> | IfcCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcArbitraryClosedProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let OuterCurve = tape[ptr++];
		return new IfcArbitraryClosedProfileDef(expressID, type, ProfileType, ProfileName, OuterCurve);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.OuterCurve);;
		return args;
	}
};
export class IfcArbitraryOpenProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Curve: (Handle<IfcBoundedCurve> | IfcBoundedCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Curve = Curve;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Curve: (Handle<IfcBoundedCurve> | IfcBoundedCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcArbitraryOpenProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Curve = tape[ptr++];
		return new IfcArbitraryOpenProfileDef(expressID, type, ProfileType, ProfileName, Curve);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Curve);;
		return args;
	}
};
export class IfcArbitraryProfileDefWithVoids {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, OuterCurve: (Handle<IfcCurve> | IfcCurve) , InnerCurves: (Handle<IfcCurve> | IfcCurve)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.OuterCurve = OuterCurve;
		this.InnerCurves = InnerCurves;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	OuterCurve: (Handle<IfcCurve> | IfcCurve) ;
	InnerCurves: (Handle<IfcCurve> | IfcCurve)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcArbitraryProfileDefWithVoids
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let OuterCurve = tape[ptr++];
		let InnerCurves = tape[ptr++];
		return new IfcArbitraryProfileDefWithVoids(expressID, type, ProfileType, ProfileName, OuterCurve, InnerCurves);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.OuterCurve);;
		args.push(this.InnerCurves);;
		return args;
	}
};
export class IfcAsset {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, OriginalValue: (Handle<IfcCostValue> | IfcCostValue) | null, CurrentValue: (Handle<IfcCostValue> | IfcCostValue) | null, TotalReplacementCost: (Handle<IfcCostValue> | IfcCostValue) | null, Owner: IfcActorSelect | null, User: IfcActorSelect | null, ResponsiblePerson: (Handle<IfcPerson> | IfcPerson) | null, IncorporationDate: IfcDate | null, DepreciatedValue: (Handle<IfcCostValue> | IfcCostValue) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.OriginalValue = OriginalValue;
		this.CurrentValue = CurrentValue;
		this.TotalReplacementCost = TotalReplacementCost;
		this.Owner = Owner;
		this.User = User;
		this.ResponsiblePerson = ResponsiblePerson;
		this.IncorporationDate = IncorporationDate;
		this.DepreciatedValue = DepreciatedValue;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	OriginalValue: (Handle<IfcCostValue> | IfcCostValue) | null;
	CurrentValue: (Handle<IfcCostValue> | IfcCostValue) | null;
	TotalReplacementCost: (Handle<IfcCostValue> | IfcCostValue) | null;
	Owner: IfcActorSelect | null;
	User: IfcActorSelect | null;
	ResponsiblePerson: (Handle<IfcPerson> | IfcPerson) | null;
	IncorporationDate: IfcDate | null;
	DepreciatedValue: (Handle<IfcCostValue> | IfcCostValue) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAsset
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let OriginalValue = tape[ptr++];
		let CurrentValue = tape[ptr++];
		let TotalReplacementCost = tape[ptr++];
		let Owner = tape[ptr++];
		let User = tape[ptr++];
		let ResponsiblePerson = tape[ptr++];
		let IncorporationDate = tape[ptr++];
		let DepreciatedValue = tape[ptr++];
		return new IfcAsset(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, OriginalValue, CurrentValue, TotalReplacementCost, Owner, User, ResponsiblePerson, IncorporationDate, DepreciatedValue);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.OriginalValue);;
		args.push(this.CurrentValue);;
		args.push(this.TotalReplacementCost);;
		args.push(this.Owner);;
		args.push(this.User);;
		args.push(this.ResponsiblePerson);;
		args.push(this.IncorporationDate);;
		args.push(this.DepreciatedValue);;
		return args;
	}
};
export class IfcAsymmetricIShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, BottomFlangeWidth: IfcPositiveLengthMeasure , OverallDepth: IfcPositiveLengthMeasure , WebThickness: IfcPositiveLengthMeasure , BottomFlangeThickness: IfcPositiveLengthMeasure , BottomFlangeFilletRadius: IfcNonNegativeLengthMeasure | null, TopFlangeWidth: IfcPositiveLengthMeasure , TopFlangeThickness: IfcPositiveLengthMeasure | null, TopFlangeFilletRadius: IfcNonNegativeLengthMeasure | null, BottomFlangeEdgeRadius: IfcNonNegativeLengthMeasure | null, BottomFlangeSlope: IfcPlaneAngleMeasure | null, TopFlangeEdgeRadius: IfcNonNegativeLengthMeasure | null, TopFlangeSlope: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.BottomFlangeWidth = BottomFlangeWidth;
		this.OverallDepth = OverallDepth;
		this.WebThickness = WebThickness;
		this.BottomFlangeThickness = BottomFlangeThickness;
		this.BottomFlangeFilletRadius = BottomFlangeFilletRadius;
		this.TopFlangeWidth = TopFlangeWidth;
		this.TopFlangeThickness = TopFlangeThickness;
		this.TopFlangeFilletRadius = TopFlangeFilletRadius;
		this.BottomFlangeEdgeRadius = BottomFlangeEdgeRadius;
		this.BottomFlangeSlope = BottomFlangeSlope;
		this.TopFlangeEdgeRadius = TopFlangeEdgeRadius;
		this.TopFlangeSlope = TopFlangeSlope;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	BottomFlangeWidth: IfcPositiveLengthMeasure ;
	OverallDepth: IfcPositiveLengthMeasure ;
	WebThickness: IfcPositiveLengthMeasure ;
	BottomFlangeThickness: IfcPositiveLengthMeasure ;
	BottomFlangeFilletRadius: IfcNonNegativeLengthMeasure | null;
	TopFlangeWidth: IfcPositiveLengthMeasure ;
	TopFlangeThickness: IfcPositiveLengthMeasure | null;
	TopFlangeFilletRadius: IfcNonNegativeLengthMeasure | null;
	BottomFlangeEdgeRadius: IfcNonNegativeLengthMeasure | null;
	BottomFlangeSlope: IfcPlaneAngleMeasure | null;
	TopFlangeEdgeRadius: IfcNonNegativeLengthMeasure | null;
	TopFlangeSlope: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAsymmetricIShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let BottomFlangeWidth = tape[ptr++];
		let OverallDepth = tape[ptr++];
		let WebThickness = tape[ptr++];
		let BottomFlangeThickness = tape[ptr++];
		let BottomFlangeFilletRadius = tape[ptr++];
		let TopFlangeWidth = tape[ptr++];
		let TopFlangeThickness = tape[ptr++];
		let TopFlangeFilletRadius = tape[ptr++];
		let BottomFlangeEdgeRadius = tape[ptr++];
		let BottomFlangeSlope = tape[ptr++];
		let TopFlangeEdgeRadius = tape[ptr++];
		let TopFlangeSlope = tape[ptr++];
		return new IfcAsymmetricIShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, BottomFlangeWidth, OverallDepth, WebThickness, BottomFlangeThickness, BottomFlangeFilletRadius, TopFlangeWidth, TopFlangeThickness, TopFlangeFilletRadius, BottomFlangeEdgeRadius, BottomFlangeSlope, TopFlangeEdgeRadius, TopFlangeSlope);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.BottomFlangeWidth);;
		args.push(this.OverallDepth);;
		args.push(this.WebThickness);;
		args.push(this.BottomFlangeThickness);;
		args.push(this.BottomFlangeFilletRadius);;
		args.push(this.TopFlangeWidth);;
		args.push(this.TopFlangeThickness);;
		args.push(this.TopFlangeFilletRadius);;
		args.push(this.BottomFlangeEdgeRadius);;
		args.push(this.BottomFlangeSlope);;
		args.push(this.TopFlangeEdgeRadius);;
		args.push(this.TopFlangeSlope);;
		return args;
	}
};
export class IfcAudioVisualAppliance {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcAudioVisualApplianceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcAudioVisualApplianceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAudioVisualAppliance
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAudioVisualAppliance(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAudioVisualApplianceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcAudioVisualApplianceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcAudioVisualApplianceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAudioVisualApplianceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcAudioVisualApplianceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcAxis1Placement {
	constructor(expressID: number, type: number, Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Axis: (Handle<IfcDirection> | IfcDirection) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Axis = Axis;
	}
	expressID: number;
	type: number;
	Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Axis: (Handle<IfcDirection> | IfcDirection) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAxis1Placement
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Axis = tape[ptr++];
		return new IfcAxis1Placement(expressID, type, Location, Axis);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Axis);;
		return args;
	}
};
export class IfcAxis2Placement2D {
	constructor(expressID: number, type: number, Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , RefDirection: (Handle<IfcDirection> | IfcDirection) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.RefDirection = RefDirection;
	}
	expressID: number;
	type: number;
	Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	RefDirection: (Handle<IfcDirection> | IfcDirection) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAxis2Placement2D
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let RefDirection = tape[ptr++];
		return new IfcAxis2Placement2D(expressID, type, Location, RefDirection);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.RefDirection);;
		return args;
	}
};
export class IfcAxis2Placement3D {
	constructor(expressID: number, type: number, Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Axis: (Handle<IfcDirection> | IfcDirection) | null, RefDirection: (Handle<IfcDirection> | IfcDirection) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Axis = Axis;
		this.RefDirection = RefDirection;
	}
	expressID: number;
	type: number;
	Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Axis: (Handle<IfcDirection> | IfcDirection) | null;
	RefDirection: (Handle<IfcDirection> | IfcDirection) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcAxis2Placement3D
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Axis = tape[ptr++];
		let RefDirection = tape[ptr++];
		return new IfcAxis2Placement3D(expressID, type, Location, Axis, RefDirection);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Axis);;
		args.push(this.RefDirection);;
		return args;
	}
};
export class IfcBSplineCurve {
	constructor(expressID: number, type: number, Degree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , CurveForm: IfcBSplineCurveForm , ClosedCurve: IfcLogical , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.Degree = Degree;
		this.ControlPointsList = ControlPointsList;
		this.CurveForm = CurveForm;
		this.ClosedCurve = ClosedCurve;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Degree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	CurveForm: IfcBSplineCurveForm ;
	ClosedCurve: IfcLogical ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBSplineCurve
	{
		let ptr = 0;
		let Degree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let CurveForm = tape[ptr++];
		let ClosedCurve = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcBSplineCurve(expressID, type, Degree, ControlPointsList, CurveForm, ClosedCurve, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Degree);;
		args.push(this.ControlPointsList);;
		args.push(this.CurveForm);;
		args.push(this.ClosedCurve);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcBSplineCurveWithKnots {
	constructor(expressID: number, type: number, Degree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , CurveForm: IfcBSplineCurveForm , ClosedCurve: IfcLogical , SelfIntersect: IfcLogical , KnotMultiplicities: IfcInteger[] , Knots: IfcParameterValue[] , KnotSpec: IfcKnotType )
	{
		this.expressID = expressID;
		this.type = type;
		this.Degree = Degree;
		this.ControlPointsList = ControlPointsList;
		this.CurveForm = CurveForm;
		this.ClosedCurve = ClosedCurve;
		this.SelfIntersect = SelfIntersect;
		this.KnotMultiplicities = KnotMultiplicities;
		this.Knots = Knots;
		this.KnotSpec = KnotSpec;
	}
	expressID: number;
	type: number;
	Degree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	CurveForm: IfcBSplineCurveForm ;
	ClosedCurve: IfcLogical ;
	SelfIntersect: IfcLogical ;
	KnotMultiplicities: IfcInteger[] ;
	Knots: IfcParameterValue[] ;
	KnotSpec: IfcKnotType ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBSplineCurveWithKnots
	{
		let ptr = 0;
		let Degree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let CurveForm = tape[ptr++];
		let ClosedCurve = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		let KnotMultiplicities = tape[ptr++];
		let Knots = tape[ptr++];
		let KnotSpec = tape[ptr++];
		return new IfcBSplineCurveWithKnots(expressID, type, Degree, ControlPointsList, CurveForm, ClosedCurve, SelfIntersect, KnotMultiplicities, Knots, KnotSpec);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Degree);;
		args.push(this.ControlPointsList);;
		args.push(this.CurveForm);;
		args.push(this.ClosedCurve);;
		args.push(this.SelfIntersect);;
		args.push(this.KnotMultiplicities);;
		args.push(this.Knots);;
		args.push(this.KnotSpec);;
		return args;
	}
};
export class IfcBSplineSurface {
	constructor(expressID: number, type: number, UDegree: IfcInteger , VDegree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , SurfaceForm: IfcBSplineSurfaceForm , UClosed: IfcLogical , VClosed: IfcLogical , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.UDegree = UDegree;
		this.VDegree = VDegree;
		this.ControlPointsList = ControlPointsList;
		this.SurfaceForm = SurfaceForm;
		this.UClosed = UClosed;
		this.VClosed = VClosed;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	UDegree: IfcInteger ;
	VDegree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	SurfaceForm: IfcBSplineSurfaceForm ;
	UClosed: IfcLogical ;
	VClosed: IfcLogical ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBSplineSurface
	{
		let ptr = 0;
		let UDegree = tape[ptr++];
		let VDegree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let SurfaceForm = tape[ptr++];
		let UClosed = tape[ptr++];
		let VClosed = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcBSplineSurface(expressID, type, UDegree, VDegree, ControlPointsList, SurfaceForm, UClosed, VClosed, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.UDegree);;
		args.push(this.VDegree);;
		args.push(this.ControlPointsList);;
		args.push(this.SurfaceForm);;
		args.push(this.UClosed);;
		args.push(this.VClosed);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcBSplineSurfaceWithKnots {
	constructor(expressID: number, type: number, UDegree: IfcInteger , VDegree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , SurfaceForm: IfcBSplineSurfaceForm , UClosed: IfcLogical , VClosed: IfcLogical , SelfIntersect: IfcLogical , UMultiplicities: IfcInteger[] , VMultiplicities: IfcInteger[] , UKnots: IfcParameterValue[] , VKnots: IfcParameterValue[] , KnotSpec: IfcKnotType )
	{
		this.expressID = expressID;
		this.type = type;
		this.UDegree = UDegree;
		this.VDegree = VDegree;
		this.ControlPointsList = ControlPointsList;
		this.SurfaceForm = SurfaceForm;
		this.UClosed = UClosed;
		this.VClosed = VClosed;
		this.SelfIntersect = SelfIntersect;
		this.UMultiplicities = UMultiplicities;
		this.VMultiplicities = VMultiplicities;
		this.UKnots = UKnots;
		this.VKnots = VKnots;
		this.KnotSpec = KnotSpec;
	}
	expressID: number;
	type: number;
	UDegree: IfcInteger ;
	VDegree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	SurfaceForm: IfcBSplineSurfaceForm ;
	UClosed: IfcLogical ;
	VClosed: IfcLogical ;
	SelfIntersect: IfcLogical ;
	UMultiplicities: IfcInteger[] ;
	VMultiplicities: IfcInteger[] ;
	UKnots: IfcParameterValue[] ;
	VKnots: IfcParameterValue[] ;
	KnotSpec: IfcKnotType ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBSplineSurfaceWithKnots
	{
		let ptr = 0;
		let UDegree = tape[ptr++];
		let VDegree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let SurfaceForm = tape[ptr++];
		let UClosed = tape[ptr++];
		let VClosed = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		let UMultiplicities = tape[ptr++];
		let VMultiplicities = tape[ptr++];
		let UKnots = tape[ptr++];
		let VKnots = tape[ptr++];
		let KnotSpec = tape[ptr++];
		return new IfcBSplineSurfaceWithKnots(expressID, type, UDegree, VDegree, ControlPointsList, SurfaceForm, UClosed, VClosed, SelfIntersect, UMultiplicities, VMultiplicities, UKnots, VKnots, KnotSpec);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.UDegree);;
		args.push(this.VDegree);;
		args.push(this.ControlPointsList);;
		args.push(this.SurfaceForm);;
		args.push(this.UClosed);;
		args.push(this.VClosed);;
		args.push(this.SelfIntersect);;
		args.push(this.UMultiplicities);;
		args.push(this.VMultiplicities);;
		args.push(this.UKnots);;
		args.push(this.VKnots);;
		args.push(this.KnotSpec);;
		return args;
	}
};
export class IfcBeam {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBeamTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBeamTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBeam
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBeam(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBeamStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBeamTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBeamTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBeamStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBeamStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBeamType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBeamTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBeamTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBeamType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBeamType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBearing {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBearingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBearingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBearing
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBearing(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBearingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBearingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBearingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBearingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBearingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBlobTexture {
	constructor(expressID: number, type: number, RepeatS: IfcBoolean , RepeatT: IfcBoolean , Mode: IfcIdentifier | null, TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null, Parameter: IfcIdentifier[] | null, RasterFormat: IfcIdentifier , RasterCode: IfcBinary )
	{
		this.expressID = expressID;
		this.type = type;
		this.RepeatS = RepeatS;
		this.RepeatT = RepeatT;
		this.Mode = Mode;
		this.TextureTransform = TextureTransform;
		this.Parameter = Parameter;
		this.RasterFormat = RasterFormat;
		this.RasterCode = RasterCode;
	}
	expressID: number;
	type: number;
	RepeatS: IfcBoolean ;
	RepeatT: IfcBoolean ;
	Mode: IfcIdentifier | null;
	TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null;
	Parameter: IfcIdentifier[] | null;
	RasterFormat: IfcIdentifier ;
	RasterCode: IfcBinary ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBlobTexture
	{
		let ptr = 0;
		let RepeatS = tape[ptr++];
		let RepeatT = tape[ptr++];
		let Mode = tape[ptr++];
		let TextureTransform = tape[ptr++];
		let Parameter = tape[ptr++];
		let RasterFormat = tape[ptr++];
		let RasterCode = tape[ptr++];
		return new IfcBlobTexture(expressID, type, RepeatS, RepeatT, Mode, TextureTransform, Parameter, RasterFormat, RasterCode);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RepeatS);;
		args.push(this.RepeatT);;
		args.push(this.Mode);;
		args.push(this.TextureTransform);;
		args.push(this.Parameter);;
		args.push(this.RasterFormat);;
		args.push(this.RasterCode);;
		return args;
	}
};
export class IfcBlock {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , XLength: IfcPositiveLengthMeasure , YLength: IfcPositiveLengthMeasure , ZLength: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.XLength = XLength;
		this.YLength = YLength;
		this.ZLength = ZLength;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	XLength: IfcPositiveLengthMeasure ;
	YLength: IfcPositiveLengthMeasure ;
	ZLength: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBlock
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let XLength = tape[ptr++];
		let YLength = tape[ptr++];
		let ZLength = tape[ptr++];
		return new IfcBlock(expressID, type, Position, XLength, YLength, ZLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.XLength);;
		args.push(this.YLength);;
		args.push(this.ZLength);;
		return args;
	}
};
export class IfcBoiler {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBoilerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBoilerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoiler
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBoiler(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBoilerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBoilerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBoilerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoilerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBoilerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBooleanClippingResult {
	constructor(expressID: number, type: number, Operator: IfcBooleanOperator , FirstOperand: IfcBooleanOperand , SecondOperand: IfcBooleanOperand )
	{
		this.expressID = expressID;
		this.type = type;
		this.Operator = Operator;
		this.FirstOperand = FirstOperand;
		this.SecondOperand = SecondOperand;
	}
	expressID: number;
	type: number;
	Operator: IfcBooleanOperator ;
	FirstOperand: IfcBooleanOperand ;
	SecondOperand: IfcBooleanOperand ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBooleanClippingResult
	{
		let ptr = 0;
		let Operator = tape[ptr++];
		let FirstOperand = tape[ptr++];
		let SecondOperand = tape[ptr++];
		return new IfcBooleanClippingResult(expressID, type, Operator, FirstOperand, SecondOperand);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Operator);;
		args.push(this.FirstOperand);;
		args.push(this.SecondOperand);;
		return args;
	}
};
export class IfcBooleanResult {
	constructor(expressID: number, type: number, Operator: IfcBooleanOperator , FirstOperand: IfcBooleanOperand , SecondOperand: IfcBooleanOperand )
	{
		this.expressID = expressID;
		this.type = type;
		this.Operator = Operator;
		this.FirstOperand = FirstOperand;
		this.SecondOperand = SecondOperand;
	}
	expressID: number;
	type: number;
	Operator: IfcBooleanOperator ;
	FirstOperand: IfcBooleanOperand ;
	SecondOperand: IfcBooleanOperand ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBooleanResult
	{
		let ptr = 0;
		let Operator = tape[ptr++];
		let FirstOperand = tape[ptr++];
		let SecondOperand = tape[ptr++];
		return new IfcBooleanResult(expressID, type, Operator, FirstOperand, SecondOperand);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Operator);;
		args.push(this.FirstOperand);;
		args.push(this.SecondOperand);;
		return args;
	}
};
export class IfcBoundaryCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcBoundaryCondition(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcBoundaryCurve {
	constructor(expressID: number, type: number, Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.Segments = Segments;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryCurve
	{
		let ptr = 0;
		let Segments = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcBoundaryCurve(expressID, type, Segments, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Segments);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcBoundaryEdgeCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TranslationalStiffnessByLengthX: IfcModulusOfTranslationalSubgradeReactionSelect | null, TranslationalStiffnessByLengthY: IfcModulusOfTranslationalSubgradeReactionSelect | null, TranslationalStiffnessByLengthZ: IfcModulusOfTranslationalSubgradeReactionSelect | null, RotationalStiffnessByLengthX: IfcModulusOfRotationalSubgradeReactionSelect | null, RotationalStiffnessByLengthY: IfcModulusOfRotationalSubgradeReactionSelect | null, RotationalStiffnessByLengthZ: IfcModulusOfRotationalSubgradeReactionSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TranslationalStiffnessByLengthX = TranslationalStiffnessByLengthX;
		this.TranslationalStiffnessByLengthY = TranslationalStiffnessByLengthY;
		this.TranslationalStiffnessByLengthZ = TranslationalStiffnessByLengthZ;
		this.RotationalStiffnessByLengthX = RotationalStiffnessByLengthX;
		this.RotationalStiffnessByLengthY = RotationalStiffnessByLengthY;
		this.RotationalStiffnessByLengthZ = RotationalStiffnessByLengthZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TranslationalStiffnessByLengthX: IfcModulusOfTranslationalSubgradeReactionSelect | null;
	TranslationalStiffnessByLengthY: IfcModulusOfTranslationalSubgradeReactionSelect | null;
	TranslationalStiffnessByLengthZ: IfcModulusOfTranslationalSubgradeReactionSelect | null;
	RotationalStiffnessByLengthX: IfcModulusOfRotationalSubgradeReactionSelect | null;
	RotationalStiffnessByLengthY: IfcModulusOfRotationalSubgradeReactionSelect | null;
	RotationalStiffnessByLengthZ: IfcModulusOfRotationalSubgradeReactionSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryEdgeCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TranslationalStiffnessByLengthX = tape[ptr++];
		let TranslationalStiffnessByLengthY = tape[ptr++];
		let TranslationalStiffnessByLengthZ = tape[ptr++];
		let RotationalStiffnessByLengthX = tape[ptr++];
		let RotationalStiffnessByLengthY = tape[ptr++];
		let RotationalStiffnessByLengthZ = tape[ptr++];
		return new IfcBoundaryEdgeCondition(expressID, type, Name, TranslationalStiffnessByLengthX, TranslationalStiffnessByLengthY, TranslationalStiffnessByLengthZ, RotationalStiffnessByLengthX, RotationalStiffnessByLengthY, RotationalStiffnessByLengthZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TranslationalStiffnessByLengthX);;
		args.push(this.TranslationalStiffnessByLengthY);;
		args.push(this.TranslationalStiffnessByLengthZ);;
		args.push(this.RotationalStiffnessByLengthX);;
		args.push(this.RotationalStiffnessByLengthY);;
		args.push(this.RotationalStiffnessByLengthZ);;
		return args;
	}
};
export class IfcBoundaryFaceCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TranslationalStiffnessByAreaX: IfcModulusOfSubgradeReactionSelect | null, TranslationalStiffnessByAreaY: IfcModulusOfSubgradeReactionSelect | null, TranslationalStiffnessByAreaZ: IfcModulusOfSubgradeReactionSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TranslationalStiffnessByAreaX = TranslationalStiffnessByAreaX;
		this.TranslationalStiffnessByAreaY = TranslationalStiffnessByAreaY;
		this.TranslationalStiffnessByAreaZ = TranslationalStiffnessByAreaZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TranslationalStiffnessByAreaX: IfcModulusOfSubgradeReactionSelect | null;
	TranslationalStiffnessByAreaY: IfcModulusOfSubgradeReactionSelect | null;
	TranslationalStiffnessByAreaZ: IfcModulusOfSubgradeReactionSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryFaceCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TranslationalStiffnessByAreaX = tape[ptr++];
		let TranslationalStiffnessByAreaY = tape[ptr++];
		let TranslationalStiffnessByAreaZ = tape[ptr++];
		return new IfcBoundaryFaceCondition(expressID, type, Name, TranslationalStiffnessByAreaX, TranslationalStiffnessByAreaY, TranslationalStiffnessByAreaZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TranslationalStiffnessByAreaX);;
		args.push(this.TranslationalStiffnessByAreaY);;
		args.push(this.TranslationalStiffnessByAreaZ);;
		return args;
	}
};
export class IfcBoundaryNodeCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TranslationalStiffnessX: IfcTranslationalStiffnessSelect | null, TranslationalStiffnessY: IfcTranslationalStiffnessSelect | null, TranslationalStiffnessZ: IfcTranslationalStiffnessSelect | null, RotationalStiffnessX: IfcRotationalStiffnessSelect | null, RotationalStiffnessY: IfcRotationalStiffnessSelect | null, RotationalStiffnessZ: IfcRotationalStiffnessSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TranslationalStiffnessX = TranslationalStiffnessX;
		this.TranslationalStiffnessY = TranslationalStiffnessY;
		this.TranslationalStiffnessZ = TranslationalStiffnessZ;
		this.RotationalStiffnessX = RotationalStiffnessX;
		this.RotationalStiffnessY = RotationalStiffnessY;
		this.RotationalStiffnessZ = RotationalStiffnessZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TranslationalStiffnessX: IfcTranslationalStiffnessSelect | null;
	TranslationalStiffnessY: IfcTranslationalStiffnessSelect | null;
	TranslationalStiffnessZ: IfcTranslationalStiffnessSelect | null;
	RotationalStiffnessX: IfcRotationalStiffnessSelect | null;
	RotationalStiffnessY: IfcRotationalStiffnessSelect | null;
	RotationalStiffnessZ: IfcRotationalStiffnessSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryNodeCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TranslationalStiffnessX = tape[ptr++];
		let TranslationalStiffnessY = tape[ptr++];
		let TranslationalStiffnessZ = tape[ptr++];
		let RotationalStiffnessX = tape[ptr++];
		let RotationalStiffnessY = tape[ptr++];
		let RotationalStiffnessZ = tape[ptr++];
		return new IfcBoundaryNodeCondition(expressID, type, Name, TranslationalStiffnessX, TranslationalStiffnessY, TranslationalStiffnessZ, RotationalStiffnessX, RotationalStiffnessY, RotationalStiffnessZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TranslationalStiffnessX);;
		args.push(this.TranslationalStiffnessY);;
		args.push(this.TranslationalStiffnessZ);;
		args.push(this.RotationalStiffnessX);;
		args.push(this.RotationalStiffnessY);;
		args.push(this.RotationalStiffnessZ);;
		return args;
	}
};
export class IfcBoundaryNodeConditionWarping {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TranslationalStiffnessX: IfcTranslationalStiffnessSelect | null, TranslationalStiffnessY: IfcTranslationalStiffnessSelect | null, TranslationalStiffnessZ: IfcTranslationalStiffnessSelect | null, RotationalStiffnessX: IfcRotationalStiffnessSelect | null, RotationalStiffnessY: IfcRotationalStiffnessSelect | null, RotationalStiffnessZ: IfcRotationalStiffnessSelect | null, WarpingStiffness: IfcWarpingStiffnessSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TranslationalStiffnessX = TranslationalStiffnessX;
		this.TranslationalStiffnessY = TranslationalStiffnessY;
		this.TranslationalStiffnessZ = TranslationalStiffnessZ;
		this.RotationalStiffnessX = RotationalStiffnessX;
		this.RotationalStiffnessY = RotationalStiffnessY;
		this.RotationalStiffnessZ = RotationalStiffnessZ;
		this.WarpingStiffness = WarpingStiffness;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TranslationalStiffnessX: IfcTranslationalStiffnessSelect | null;
	TranslationalStiffnessY: IfcTranslationalStiffnessSelect | null;
	TranslationalStiffnessZ: IfcTranslationalStiffnessSelect | null;
	RotationalStiffnessX: IfcRotationalStiffnessSelect | null;
	RotationalStiffnessY: IfcRotationalStiffnessSelect | null;
	RotationalStiffnessZ: IfcRotationalStiffnessSelect | null;
	WarpingStiffness: IfcWarpingStiffnessSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundaryNodeConditionWarping
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TranslationalStiffnessX = tape[ptr++];
		let TranslationalStiffnessY = tape[ptr++];
		let TranslationalStiffnessZ = tape[ptr++];
		let RotationalStiffnessX = tape[ptr++];
		let RotationalStiffnessY = tape[ptr++];
		let RotationalStiffnessZ = tape[ptr++];
		let WarpingStiffness = tape[ptr++];
		return new IfcBoundaryNodeConditionWarping(expressID, type, Name, TranslationalStiffnessX, TranslationalStiffnessY, TranslationalStiffnessZ, RotationalStiffnessX, RotationalStiffnessY, RotationalStiffnessZ, WarpingStiffness);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TranslationalStiffnessX);;
		args.push(this.TranslationalStiffnessY);;
		args.push(this.TranslationalStiffnessZ);;
		args.push(this.RotationalStiffnessX);;
		args.push(this.RotationalStiffnessY);;
		args.push(this.RotationalStiffnessZ);;
		args.push(this.WarpingStiffness);;
		return args;
	}
};
export class IfcBoundedCurve {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundedCurve
	{
		let ptr = 0;
		return new IfcBoundedCurve(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcBoundedSurface {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundedSurface
	{
		let ptr = 0;
		return new IfcBoundedSurface(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcBoundingBox {
	constructor(expressID: number, type: number, Corner: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , XDim: IfcPositiveLengthMeasure , YDim: IfcPositiveLengthMeasure , ZDim: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Corner = Corner;
		this.XDim = XDim;
		this.YDim = YDim;
		this.ZDim = ZDim;
	}
	expressID: number;
	type: number;
	Corner: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	XDim: IfcPositiveLengthMeasure ;
	YDim: IfcPositiveLengthMeasure ;
	ZDim: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoundingBox
	{
		let ptr = 0;
		let Corner = tape[ptr++];
		let XDim = tape[ptr++];
		let YDim = tape[ptr++];
		let ZDim = tape[ptr++];
		return new IfcBoundingBox(expressID, type, Corner, XDim, YDim, ZDim);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Corner);;
		args.push(this.XDim);;
		args.push(this.YDim);;
		args.push(this.ZDim);;
		return args;
	}
};
export class IfcBoxedHalfSpace {
	constructor(expressID: number, type: number, BaseSurface: (Handle<IfcSurface> | IfcSurface) , AgreementFlag: IfcBoolean , Enclosure: (Handle<IfcBoundingBox> | IfcBoundingBox) )
	{
		this.expressID = expressID;
		this.type = type;
		this.BaseSurface = BaseSurface;
		this.AgreementFlag = AgreementFlag;
		this.Enclosure = Enclosure;
	}
	expressID: number;
	type: number;
	BaseSurface: (Handle<IfcSurface> | IfcSurface) ;
	AgreementFlag: IfcBoolean ;
	Enclosure: (Handle<IfcBoundingBox> | IfcBoundingBox) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBoxedHalfSpace
	{
		let ptr = 0;
		let BaseSurface = tape[ptr++];
		let AgreementFlag = tape[ptr++];
		let Enclosure = tape[ptr++];
		return new IfcBoxedHalfSpace(expressID, type, BaseSurface, AgreementFlag, Enclosure);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BaseSurface);;
		args.push(this.AgreementFlag);;
		args.push(this.Enclosure);;
		return args;
	}
};
export class IfcBridge {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, PredefinedType: IfcBridgeTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	PredefinedType: IfcBridgeTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBridge
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBridge(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBridgePart {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, PredefinedType: IfcBridgePartTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	PredefinedType: IfcBridgePartTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBridgePart
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBridgePart(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBuilding {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, ElevationOfRefHeight: IfcLengthMeasure | null, ElevationOfTerrain: IfcLengthMeasure | null, BuildingAddress: (Handle<IfcPostalAddress> | IfcPostalAddress) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.ElevationOfRefHeight = ElevationOfRefHeight;
		this.ElevationOfTerrain = ElevationOfTerrain;
		this.BuildingAddress = BuildingAddress;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	ElevationOfRefHeight: IfcLengthMeasure | null;
	ElevationOfTerrain: IfcLengthMeasure | null;
	BuildingAddress: (Handle<IfcPostalAddress> | IfcPostalAddress) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuilding
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let ElevationOfRefHeight = tape[ptr++];
		let ElevationOfTerrain = tape[ptr++];
		let BuildingAddress = tape[ptr++];
		return new IfcBuilding(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, ElevationOfRefHeight, ElevationOfTerrain, BuildingAddress);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.ElevationOfRefHeight);;
		args.push(this.ElevationOfTerrain);;
		args.push(this.BuildingAddress);;
		return args;
	}
};
export class IfcBuildingElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcBuildingElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcBuildingElementPart {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBuildingElementPartTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBuildingElementPartTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElementPart
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBuildingElementPart(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBuildingElementPartType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBuildingElementPartTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBuildingElementPartTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElementPartType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBuildingElementPartType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBuildingElementProxy {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBuildingElementProxyTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBuildingElementProxyTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElementProxy
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBuildingElementProxy(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBuildingElementProxyType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBuildingElementProxyTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBuildingElementProxyTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElementProxyType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBuildingElementProxyType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBuildingElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcBuildingElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcBuildingStorey {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, Elevation: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.Elevation = Elevation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	Elevation: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingStorey
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let Elevation = tape[ptr++];
		return new IfcBuildingStorey(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, Elevation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.Elevation);;
		return args;
	}
};
export class IfcBuildingSystem {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, PredefinedType: IfcBuildingSystemTypeEnum | null, LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.PredefinedType = PredefinedType;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	PredefinedType: IfcBuildingSystemTypeEnum | null;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBuildingSystem
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcBuildingSystem(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, PredefinedType, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.PredefinedType);;
		args.push(this.LongName);;
		return args;
	}
};
export class IfcBurner {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcBurnerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcBurnerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBurner
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBurner(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcBurnerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcBurnerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcBurnerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcBurnerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcBurnerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Depth: IfcPositiveLengthMeasure , Width: IfcPositiveLengthMeasure , WallThickness: IfcPositiveLengthMeasure , Girth: IfcPositiveLengthMeasure , InternalFilletRadius: IfcNonNegativeLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Depth = Depth;
		this.Width = Width;
		this.WallThickness = WallThickness;
		this.Girth = Girth;
		this.InternalFilletRadius = InternalFilletRadius;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Depth: IfcPositiveLengthMeasure ;
	Width: IfcPositiveLengthMeasure ;
	WallThickness: IfcPositiveLengthMeasure ;
	Girth: IfcPositiveLengthMeasure ;
	InternalFilletRadius: IfcNonNegativeLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Depth = tape[ptr++];
		let Width = tape[ptr++];
		let WallThickness = tape[ptr++];
		let Girth = tape[ptr++];
		let InternalFilletRadius = tape[ptr++];
		return new IfcCShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, Depth, Width, WallThickness, Girth, InternalFilletRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Depth);;
		args.push(this.Width);;
		args.push(this.WallThickness);;
		args.push(this.Girth);;
		args.push(this.InternalFilletRadius);;
		return args;
	}
};
export class IfcCableCarrierFitting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCableCarrierFittingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCableCarrierFittingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableCarrierFitting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableCarrierFitting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableCarrierFittingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCableCarrierFittingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCableCarrierFittingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableCarrierFittingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableCarrierFittingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableCarrierSegment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCableCarrierSegmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCableCarrierSegmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableCarrierSegment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableCarrierSegment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableCarrierSegmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCableCarrierSegmentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCableCarrierSegmentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableCarrierSegmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableCarrierSegmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableFitting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCableFittingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCableFittingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableFitting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableFitting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableFittingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCableFittingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCableFittingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableFittingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableFittingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableSegment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCableSegmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCableSegmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableSegment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableSegment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCableSegmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCableSegmentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCableSegmentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCableSegmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCableSegmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCaissonFoundation {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCaissonFoundationTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCaissonFoundationTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCaissonFoundation
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCaissonFoundation(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCaissonFoundationType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCaissonFoundationTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCaissonFoundationTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCaissonFoundationType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCaissonFoundationType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCartesianPoint {
	constructor(expressID: number, type: number, Coordinates: IfcLengthMeasure[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
	}
	expressID: number;
	type: number;
	Coordinates: IfcLengthMeasure[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianPoint
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		return new IfcCartesianPoint(expressID, type, Coordinates);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		return args;
	}
};
export class IfcCartesianPointList {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianPointList
	{
		let ptr = 0;
		return new IfcCartesianPointList(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcCartesianPointList2D {
	constructor(expressID: number, type: number, CoordList: IfcLengthMeasure[] , TagList: IfcLabel[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.CoordList = CoordList;
		this.TagList = TagList;
	}
	expressID: number;
	type: number;
	CoordList: IfcLengthMeasure[] ;
	TagList: IfcLabel[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianPointList2D
	{
		let ptr = 0;
		let CoordList = tape[ptr++];
		let TagList = tape[ptr++];
		return new IfcCartesianPointList2D(expressID, type, CoordList, TagList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CoordList);;
		args.push(this.TagList);;
		return args;
	}
};
export class IfcCartesianPointList3D {
	constructor(expressID: number, type: number, CoordList: IfcLengthMeasure[] , TagList: IfcLabel[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.CoordList = CoordList;
		this.TagList = TagList;
	}
	expressID: number;
	type: number;
	CoordList: IfcLengthMeasure[] ;
	TagList: IfcLabel[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianPointList3D
	{
		let ptr = 0;
		let CoordList = tape[ptr++];
		let TagList = tape[ptr++];
		return new IfcCartesianPointList3D(expressID, type, CoordList, TagList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CoordList);;
		args.push(this.TagList);;
		return args;
	}
};
export class IfcCartesianTransformationOperator {
	constructor(expressID: number, type: number, Axis1: (Handle<IfcDirection> | IfcDirection) | null, Axis2: (Handle<IfcDirection> | IfcDirection) | null, LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Scale: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Axis1 = Axis1;
		this.Axis2 = Axis2;
		this.LocalOrigin = LocalOrigin;
		this.Scale = Scale;
	}
	expressID: number;
	type: number;
	Axis1: (Handle<IfcDirection> | IfcDirection) | null;
	Axis2: (Handle<IfcDirection> | IfcDirection) | null;
	LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Scale: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianTransformationOperator
	{
		let ptr = 0;
		let Axis1 = tape[ptr++];
		let Axis2 = tape[ptr++];
		let LocalOrigin = tape[ptr++];
		let Scale = tape[ptr++];
		return new IfcCartesianTransformationOperator(expressID, type, Axis1, Axis2, LocalOrigin, Scale);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Axis1);;
		args.push(this.Axis2);;
		args.push(this.LocalOrigin);;
		args.push(this.Scale);;
		return args;
	}
};
export class IfcCartesianTransformationOperator2D {
	constructor(expressID: number, type: number, Axis1: (Handle<IfcDirection> | IfcDirection) | null, Axis2: (Handle<IfcDirection> | IfcDirection) | null, LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Scale: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Axis1 = Axis1;
		this.Axis2 = Axis2;
		this.LocalOrigin = LocalOrigin;
		this.Scale = Scale;
	}
	expressID: number;
	type: number;
	Axis1: (Handle<IfcDirection> | IfcDirection) | null;
	Axis2: (Handle<IfcDirection> | IfcDirection) | null;
	LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Scale: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianTransformationOperator2D
	{
		let ptr = 0;
		let Axis1 = tape[ptr++];
		let Axis2 = tape[ptr++];
		let LocalOrigin = tape[ptr++];
		let Scale = tape[ptr++];
		return new IfcCartesianTransformationOperator2D(expressID, type, Axis1, Axis2, LocalOrigin, Scale);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Axis1);;
		args.push(this.Axis2);;
		args.push(this.LocalOrigin);;
		args.push(this.Scale);;
		return args;
	}
};
export class IfcCartesianTransformationOperator2DnonUniform {
	constructor(expressID: number, type: number, Axis1: (Handle<IfcDirection> | IfcDirection) | null, Axis2: (Handle<IfcDirection> | IfcDirection) | null, LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Scale: IfcReal | null, Scale2: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Axis1 = Axis1;
		this.Axis2 = Axis2;
		this.LocalOrigin = LocalOrigin;
		this.Scale = Scale;
		this.Scale2 = Scale2;
	}
	expressID: number;
	type: number;
	Axis1: (Handle<IfcDirection> | IfcDirection) | null;
	Axis2: (Handle<IfcDirection> | IfcDirection) | null;
	LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Scale: IfcReal | null;
	Scale2: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianTransformationOperator2DnonUniform
	{
		let ptr = 0;
		let Axis1 = tape[ptr++];
		let Axis2 = tape[ptr++];
		let LocalOrigin = tape[ptr++];
		let Scale = tape[ptr++];
		let Scale2 = tape[ptr++];
		return new IfcCartesianTransformationOperator2DnonUniform(expressID, type, Axis1, Axis2, LocalOrigin, Scale, Scale2);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Axis1);;
		args.push(this.Axis2);;
		args.push(this.LocalOrigin);;
		args.push(this.Scale);;
		args.push(this.Scale2);;
		return args;
	}
};
export class IfcCartesianTransformationOperator3D {
	constructor(expressID: number, type: number, Axis1: (Handle<IfcDirection> | IfcDirection) | null, Axis2: (Handle<IfcDirection> | IfcDirection) | null, LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Scale: IfcReal | null, Axis3: (Handle<IfcDirection> | IfcDirection) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Axis1 = Axis1;
		this.Axis2 = Axis2;
		this.LocalOrigin = LocalOrigin;
		this.Scale = Scale;
		this.Axis3 = Axis3;
	}
	expressID: number;
	type: number;
	Axis1: (Handle<IfcDirection> | IfcDirection) | null;
	Axis2: (Handle<IfcDirection> | IfcDirection) | null;
	LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Scale: IfcReal | null;
	Axis3: (Handle<IfcDirection> | IfcDirection) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianTransformationOperator3D
	{
		let ptr = 0;
		let Axis1 = tape[ptr++];
		let Axis2 = tape[ptr++];
		let LocalOrigin = tape[ptr++];
		let Scale = tape[ptr++];
		let Axis3 = tape[ptr++];
		return new IfcCartesianTransformationOperator3D(expressID, type, Axis1, Axis2, LocalOrigin, Scale, Axis3);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Axis1);;
		args.push(this.Axis2);;
		args.push(this.LocalOrigin);;
		args.push(this.Scale);;
		args.push(this.Axis3);;
		return args;
	}
};
export class IfcCartesianTransformationOperator3DnonUniform {
	constructor(expressID: number, type: number, Axis1: (Handle<IfcDirection> | IfcDirection) | null, Axis2: (Handle<IfcDirection> | IfcDirection) | null, LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Scale: IfcReal | null, Axis3: (Handle<IfcDirection> | IfcDirection) | null, Scale2: IfcReal | null, Scale3: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Axis1 = Axis1;
		this.Axis2 = Axis2;
		this.LocalOrigin = LocalOrigin;
		this.Scale = Scale;
		this.Axis3 = Axis3;
		this.Scale2 = Scale2;
		this.Scale3 = Scale3;
	}
	expressID: number;
	type: number;
	Axis1: (Handle<IfcDirection> | IfcDirection) | null;
	Axis2: (Handle<IfcDirection> | IfcDirection) | null;
	LocalOrigin: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Scale: IfcReal | null;
	Axis3: (Handle<IfcDirection> | IfcDirection) | null;
	Scale2: IfcReal | null;
	Scale3: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCartesianTransformationOperator3DnonUniform
	{
		let ptr = 0;
		let Axis1 = tape[ptr++];
		let Axis2 = tape[ptr++];
		let LocalOrigin = tape[ptr++];
		let Scale = tape[ptr++];
		let Axis3 = tape[ptr++];
		let Scale2 = tape[ptr++];
		let Scale3 = tape[ptr++];
		return new IfcCartesianTransformationOperator3DnonUniform(expressID, type, Axis1, Axis2, LocalOrigin, Scale, Axis3, Scale2, Scale3);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Axis1);;
		args.push(this.Axis2);;
		args.push(this.LocalOrigin);;
		args.push(this.Scale);;
		args.push(this.Axis3);;
		args.push(this.Scale2);;
		args.push(this.Scale3);;
		return args;
	}
};
export class IfcCenterLineProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Curve: (Handle<IfcBoundedCurve> | IfcBoundedCurve) , Thickness: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Curve = Curve;
		this.Thickness = Thickness;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Curve: (Handle<IfcBoundedCurve> | IfcBoundedCurve) ;
	Thickness: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCenterLineProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Curve = tape[ptr++];
		let Thickness = tape[ptr++];
		return new IfcCenterLineProfileDef(expressID, type, ProfileType, ProfileName, Curve, Thickness);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Curve);;
		args.push(this.Thickness);;
		return args;
	}
};
export class IfcChiller {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcChillerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcChillerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcChiller
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcChiller(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcChillerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcChillerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcChillerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcChillerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcChillerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcChimney {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcChimneyTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcChimneyTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcChimney
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcChimney(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcChimneyType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcChimneyTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcChimneyTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcChimneyType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcChimneyType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCircle {
	constructor(expressID: number, type: number, Position: IfcAxis2Placement , Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	Position: IfcAxis2Placement ;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCircle
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcCircle(expressID, type, Position, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcCircleHollowProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Radius: IfcPositiveLengthMeasure , WallThickness: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Radius = Radius;
		this.WallThickness = WallThickness;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Radius: IfcPositiveLengthMeasure ;
	WallThickness: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCircleHollowProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		let WallThickness = tape[ptr++];
		return new IfcCircleHollowProfileDef(expressID, type, ProfileType, ProfileName, Position, Radius, WallThickness);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Radius);;
		args.push(this.WallThickness);;
		return args;
	}
};
export class IfcCircleProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCircleProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcCircleProfileDef(expressID, type, ProfileType, ProfileName, Position, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcCircularArcSegment2D {
	constructor(expressID: number, type: number, StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , StartDirection: IfcPlaneAngleMeasure , SegmentLength: IfcPositiveLengthMeasure , Radius: IfcPositiveLengthMeasure , IsCCW: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartPoint = StartPoint;
		this.StartDirection = StartDirection;
		this.SegmentLength = SegmentLength;
		this.Radius = Radius;
		this.IsCCW = IsCCW;
	}
	expressID: number;
	type: number;
	StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	StartDirection: IfcPlaneAngleMeasure ;
	SegmentLength: IfcPositiveLengthMeasure ;
	Radius: IfcPositiveLengthMeasure ;
	IsCCW: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCircularArcSegment2D
	{
		let ptr = 0;
		let StartPoint = tape[ptr++];
		let StartDirection = tape[ptr++];
		let SegmentLength = tape[ptr++];
		let Radius = tape[ptr++];
		let IsCCW = tape[ptr++];
		return new IfcCircularArcSegment2D(expressID, type, StartPoint, StartDirection, SegmentLength, Radius, IsCCW);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartPoint);;
		args.push(this.StartDirection);;
		args.push(this.SegmentLength);;
		args.push(this.Radius);;
		args.push(this.IsCCW);;
		return args;
	}
};
export class IfcCivilElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCivilElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcCivilElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcCivilElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCivilElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcCivilElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcClassification {
	constructor(expressID: number, type: number, Source: IfcLabel | null, Edition: IfcLabel | null, EditionDate: IfcDate | null, Name: IfcLabel , Description: IfcText | null, Location: IfcURIReference | null, ReferenceTokens: IfcIdentifier[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Source = Source;
		this.Edition = Edition;
		this.EditionDate = EditionDate;
		this.Name = Name;
		this.Description = Description;
		this.Location = Location;
		this.ReferenceTokens = ReferenceTokens;
	}
	expressID: number;
	type: number;
	Source: IfcLabel | null;
	Edition: IfcLabel | null;
	EditionDate: IfcDate | null;
	Name: IfcLabel ;
	Description: IfcText | null;
	Location: IfcURIReference | null;
	ReferenceTokens: IfcIdentifier[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcClassification
	{
		let ptr = 0;
		let Source = tape[ptr++];
		let Edition = tape[ptr++];
		let EditionDate = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Location = tape[ptr++];
		let ReferenceTokens = tape[ptr++];
		return new IfcClassification(expressID, type, Source, Edition, EditionDate, Name, Description, Location, ReferenceTokens);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Source);;
		args.push(this.Edition);;
		args.push(this.EditionDate);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Location);;
		args.push(this.ReferenceTokens);;
		return args;
	}
};
export class IfcClassificationReference {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null, ReferencedSource: IfcClassificationReferenceSelect | null, Description: IfcText | null, Sort: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
		this.ReferencedSource = ReferencedSource;
		this.Description = Description;
		this.Sort = Sort;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	ReferencedSource: IfcClassificationReferenceSelect | null;
	Description: IfcText | null;
	Sort: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcClassificationReference
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		let ReferencedSource = tape[ptr++];
		let Description = tape[ptr++];
		let Sort = tape[ptr++];
		return new IfcClassificationReference(expressID, type, Location, Identification, Name, ReferencedSource, Description, Sort);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		args.push(this.ReferencedSource);;
		args.push(this.Description);;
		args.push(this.Sort);;
		return args;
	}
};
export class IfcClosedShell {
	constructor(expressID: number, type: number, CfsFaces: (Handle<IfcFace> | IfcFace)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.CfsFaces = CfsFaces;
	}
	expressID: number;
	type: number;
	CfsFaces: (Handle<IfcFace> | IfcFace)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcClosedShell
	{
		let ptr = 0;
		let CfsFaces = tape[ptr++];
		return new IfcClosedShell(expressID, type, CfsFaces);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CfsFaces);;
		return args;
	}
};
export class IfcCoil {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCoilTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCoilTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoil
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCoil(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCoilType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCoilTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCoilTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoilType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCoilType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcColourRgb {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Red: IfcNormalisedRatioMeasure , Green: IfcNormalisedRatioMeasure , Blue: IfcNormalisedRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Red = Red;
		this.Green = Green;
		this.Blue = Blue;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Red: IfcNormalisedRatioMeasure ;
	Green: IfcNormalisedRatioMeasure ;
	Blue: IfcNormalisedRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColourRgb
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Red = tape[ptr++];
		let Green = tape[ptr++];
		let Blue = tape[ptr++];
		return new IfcColourRgb(expressID, type, Name, Red, Green, Blue);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Red);;
		args.push(this.Green);;
		args.push(this.Blue);;
		return args;
	}
};
export class IfcColourRgbList {
	constructor(expressID: number, type: number, ColourList: IfcNormalisedRatioMeasure[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ColourList = ColourList;
	}
	expressID: number;
	type: number;
	ColourList: IfcNormalisedRatioMeasure[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColourRgbList
	{
		let ptr = 0;
		let ColourList = tape[ptr++];
		return new IfcColourRgbList(expressID, type, ColourList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ColourList);;
		return args;
	}
};
export class IfcColourSpecification {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColourSpecification
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcColourSpecification(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcColumn {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcColumnTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcColumnTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColumn
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcColumn(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcColumnStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcColumnTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcColumnTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColumnStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcColumnStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcColumnType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcColumnTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcColumnTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcColumnType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcColumnType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCommunicationsAppliance {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCommunicationsApplianceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCommunicationsApplianceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCommunicationsAppliance
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCommunicationsAppliance(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCommunicationsApplianceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCommunicationsApplianceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCommunicationsApplianceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCommunicationsApplianceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCommunicationsApplianceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcComplexProperty {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, UsageName: IfcIdentifier , HasProperties: (Handle<IfcProperty> | IfcProperty)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.UsageName = UsageName;
		this.HasProperties = HasProperties;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	UsageName: IfcIdentifier ;
	HasProperties: (Handle<IfcProperty> | IfcProperty)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcComplexProperty
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let UsageName = tape[ptr++];
		let HasProperties = tape[ptr++];
		return new IfcComplexProperty(expressID, type, Name, Description, UsageName, HasProperties);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.UsageName);;
		args.push(this.HasProperties);;
		return args;
	}
};
export class IfcComplexPropertyTemplate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, UsageName: IfcLabel | null, TemplateType: IfcComplexPropertyTemplateTypeEnum | null, HasPropertyTemplates: (Handle<IfcPropertyTemplate> | IfcPropertyTemplate)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.UsageName = UsageName;
		this.TemplateType = TemplateType;
		this.HasPropertyTemplates = HasPropertyTemplates;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	UsageName: IfcLabel | null;
	TemplateType: IfcComplexPropertyTemplateTypeEnum | null;
	HasPropertyTemplates: (Handle<IfcPropertyTemplate> | IfcPropertyTemplate)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcComplexPropertyTemplate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let UsageName = tape[ptr++];
		let TemplateType = tape[ptr++];
		let HasPropertyTemplates = tape[ptr++];
		return new IfcComplexPropertyTemplate(expressID, type, GlobalId, OwnerHistory, Name, Description, UsageName, TemplateType, HasPropertyTemplates);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.UsageName);;
		args.push(this.TemplateType);;
		args.push(this.HasPropertyTemplates);;
		return args;
	}
};
export class IfcCompositeCurve {
	constructor(expressID: number, type: number, Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.Segments = Segments;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompositeCurve
	{
		let ptr = 0;
		let Segments = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcCompositeCurve(expressID, type, Segments, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Segments);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcCompositeCurveOnSurface {
	constructor(expressID: number, type: number, Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.Segments = Segments;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompositeCurveOnSurface
	{
		let ptr = 0;
		let Segments = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcCompositeCurveOnSurface(expressID, type, Segments, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Segments);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcCompositeCurveSegment {
	constructor(expressID: number, type: number, Transition: IfcTransitionCode , SameSense: IfcBoolean , ParentCurve: (Handle<IfcCurve> | IfcCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Transition = Transition;
		this.SameSense = SameSense;
		this.ParentCurve = ParentCurve;
	}
	expressID: number;
	type: number;
	Transition: IfcTransitionCode ;
	SameSense: IfcBoolean ;
	ParentCurve: (Handle<IfcCurve> | IfcCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompositeCurveSegment
	{
		let ptr = 0;
		let Transition = tape[ptr++];
		let SameSense = tape[ptr++];
		let ParentCurve = tape[ptr++];
		return new IfcCompositeCurveSegment(expressID, type, Transition, SameSense, ParentCurve);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Transition);;
		args.push(this.SameSense);;
		args.push(this.ParentCurve);;
		return args;
	}
};
export class IfcCompositeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Profiles: (Handle<IfcProfileDef> | IfcProfileDef)[] , Label: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Profiles = Profiles;
		this.Label = Label;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Profiles: (Handle<IfcProfileDef> | IfcProfileDef)[] ;
	Label: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompositeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Profiles = tape[ptr++];
		let Label = tape[ptr++];
		return new IfcCompositeProfileDef(expressID, type, ProfileType, ProfileName, Profiles, Label);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Profiles);;
		args.push(this.Label);;
		return args;
	}
};
export class IfcCompressor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCompressorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCompressorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompressor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCompressor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCompressorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCompressorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCompressorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCompressorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCompressorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCondenser {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCondenserTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCondenserTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCondenser
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCondenser(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCondenserType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCondenserTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCondenserTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCondenserType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCondenserType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConic {
	constructor(expressID: number, type: number, Position: IfcAxis2Placement )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	Position: IfcAxis2Placement ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConic
	{
		let ptr = 0;
		let Position = tape[ptr++];
		return new IfcConic(expressID, type, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		return args;
	}
};
export class IfcConnectedFaceSet {
	constructor(expressID: number, type: number, CfsFaces: (Handle<IfcFace> | IfcFace)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.CfsFaces = CfsFaces;
	}
	expressID: number;
	type: number;
	CfsFaces: (Handle<IfcFace> | IfcFace)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectedFaceSet
	{
		let ptr = 0;
		let CfsFaces = tape[ptr++];
		return new IfcConnectedFaceSet(expressID, type, CfsFaces);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CfsFaces);;
		return args;
	}
};
export class IfcConnectionCurveGeometry {
	constructor(expressID: number, type: number, CurveOnRelatingElement: IfcCurveOrEdgeCurve , CurveOnRelatedElement: IfcCurveOrEdgeCurve | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.CurveOnRelatingElement = CurveOnRelatingElement;
		this.CurveOnRelatedElement = CurveOnRelatedElement;
	}
	expressID: number;
	type: number;
	CurveOnRelatingElement: IfcCurveOrEdgeCurve ;
	CurveOnRelatedElement: IfcCurveOrEdgeCurve | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionCurveGeometry
	{
		let ptr = 0;
		let CurveOnRelatingElement = tape[ptr++];
		let CurveOnRelatedElement = tape[ptr++];
		return new IfcConnectionCurveGeometry(expressID, type, CurveOnRelatingElement, CurveOnRelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CurveOnRelatingElement);;
		args.push(this.CurveOnRelatedElement);;
		return args;
	}
};
export class IfcConnectionGeometry {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionGeometry
	{
		let ptr = 0;
		return new IfcConnectionGeometry(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcConnectionPointEccentricity {
	constructor(expressID: number, type: number, PointOnRelatingElement: IfcPointOrVertexPoint , PointOnRelatedElement: IfcPointOrVertexPoint | null, EccentricityInX: IfcLengthMeasure | null, EccentricityInY: IfcLengthMeasure | null, EccentricityInZ: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.PointOnRelatingElement = PointOnRelatingElement;
		this.PointOnRelatedElement = PointOnRelatedElement;
		this.EccentricityInX = EccentricityInX;
		this.EccentricityInY = EccentricityInY;
		this.EccentricityInZ = EccentricityInZ;
	}
	expressID: number;
	type: number;
	PointOnRelatingElement: IfcPointOrVertexPoint ;
	PointOnRelatedElement: IfcPointOrVertexPoint | null;
	EccentricityInX: IfcLengthMeasure | null;
	EccentricityInY: IfcLengthMeasure | null;
	EccentricityInZ: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionPointEccentricity
	{
		let ptr = 0;
		let PointOnRelatingElement = tape[ptr++];
		let PointOnRelatedElement = tape[ptr++];
		let EccentricityInX = tape[ptr++];
		let EccentricityInY = tape[ptr++];
		let EccentricityInZ = tape[ptr++];
		return new IfcConnectionPointEccentricity(expressID, type, PointOnRelatingElement, PointOnRelatedElement, EccentricityInX, EccentricityInY, EccentricityInZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PointOnRelatingElement);;
		args.push(this.PointOnRelatedElement);;
		args.push(this.EccentricityInX);;
		args.push(this.EccentricityInY);;
		args.push(this.EccentricityInZ);;
		return args;
	}
};
export class IfcConnectionPointGeometry {
	constructor(expressID: number, type: number, PointOnRelatingElement: IfcPointOrVertexPoint , PointOnRelatedElement: IfcPointOrVertexPoint | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.PointOnRelatingElement = PointOnRelatingElement;
		this.PointOnRelatedElement = PointOnRelatedElement;
	}
	expressID: number;
	type: number;
	PointOnRelatingElement: IfcPointOrVertexPoint ;
	PointOnRelatedElement: IfcPointOrVertexPoint | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionPointGeometry
	{
		let ptr = 0;
		let PointOnRelatingElement = tape[ptr++];
		let PointOnRelatedElement = tape[ptr++];
		return new IfcConnectionPointGeometry(expressID, type, PointOnRelatingElement, PointOnRelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PointOnRelatingElement);;
		args.push(this.PointOnRelatedElement);;
		return args;
	}
};
export class IfcConnectionSurfaceGeometry {
	constructor(expressID: number, type: number, SurfaceOnRelatingElement: IfcSurfaceOrFaceSurface , SurfaceOnRelatedElement: IfcSurfaceOrFaceSurface | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SurfaceOnRelatingElement = SurfaceOnRelatingElement;
		this.SurfaceOnRelatedElement = SurfaceOnRelatedElement;
	}
	expressID: number;
	type: number;
	SurfaceOnRelatingElement: IfcSurfaceOrFaceSurface ;
	SurfaceOnRelatedElement: IfcSurfaceOrFaceSurface | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionSurfaceGeometry
	{
		let ptr = 0;
		let SurfaceOnRelatingElement = tape[ptr++];
		let SurfaceOnRelatedElement = tape[ptr++];
		return new IfcConnectionSurfaceGeometry(expressID, type, SurfaceOnRelatingElement, SurfaceOnRelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SurfaceOnRelatingElement);;
		args.push(this.SurfaceOnRelatedElement);;
		return args;
	}
};
export class IfcConnectionVolumeGeometry {
	constructor(expressID: number, type: number, VolumeOnRelatingElement: IfcSolidOrShell , VolumeOnRelatedElement: IfcSolidOrShell | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.VolumeOnRelatingElement = VolumeOnRelatingElement;
		this.VolumeOnRelatedElement = VolumeOnRelatedElement;
	}
	expressID: number;
	type: number;
	VolumeOnRelatingElement: IfcSolidOrShell ;
	VolumeOnRelatedElement: IfcSolidOrShell | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConnectionVolumeGeometry
	{
		let ptr = 0;
		let VolumeOnRelatingElement = tape[ptr++];
		let VolumeOnRelatedElement = tape[ptr++];
		return new IfcConnectionVolumeGeometry(expressID, type, VolumeOnRelatingElement, VolumeOnRelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.VolumeOnRelatingElement);;
		args.push(this.VolumeOnRelatedElement);;
		return args;
	}
};
export class IfcConstraint {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, ConstraintGrade: IfcConstraintEnum , ConstraintSource: IfcLabel | null, CreatingActor: IfcActorSelect | null, CreationTime: IfcDateTime | null, UserDefinedGrade: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.ConstraintGrade = ConstraintGrade;
		this.ConstraintSource = ConstraintSource;
		this.CreatingActor = CreatingActor;
		this.CreationTime = CreationTime;
		this.UserDefinedGrade = UserDefinedGrade;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	ConstraintGrade: IfcConstraintEnum ;
	ConstraintSource: IfcLabel | null;
	CreatingActor: IfcActorSelect | null;
	CreationTime: IfcDateTime | null;
	UserDefinedGrade: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstraint
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConstraintGrade = tape[ptr++];
		let ConstraintSource = tape[ptr++];
		let CreatingActor = tape[ptr++];
		let CreationTime = tape[ptr++];
		let UserDefinedGrade = tape[ptr++];
		return new IfcConstraint(expressID, type, Name, Description, ConstraintGrade, ConstraintSource, CreatingActor, CreationTime, UserDefinedGrade);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConstraintGrade);;
		args.push(this.ConstraintSource);;
		args.push(this.CreatingActor);;
		args.push(this.CreationTime);;
		args.push(this.UserDefinedGrade);;
		return args;
	}
};
export class IfcConstructionEquipmentResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionEquipmentResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionEquipmentResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionEquipmentResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionEquipmentResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionEquipmentResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionEquipmentResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionEquipmentResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionEquipmentResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionEquipmentResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionMaterialResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionMaterialResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionMaterialResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionMaterialResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionMaterialResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionMaterialResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionMaterialResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionMaterialResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionMaterialResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionMaterialResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionProductResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionProductResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionProductResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionProductResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionProductResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionProductResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcConstructionProductResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcConstructionProductResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionProductResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcConstructionProductResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConstructionResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		return new IfcConstructionResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		return args;
	}
};
export class IfcConstructionResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConstructionResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		return new IfcConstructionResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		return args;
	}
};
export class IfcContext {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null, Phase: IfcLabel | null, RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null, UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
		this.Phase = Phase;
		this.RepresentationContexts = RepresentationContexts;
		this.UnitsInContext = UnitsInContext;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	Phase: IfcLabel | null;
	RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null;
	UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcContext
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		let Phase = tape[ptr++];
		let RepresentationContexts = tape[ptr++];
		let UnitsInContext = tape[ptr++];
		return new IfcContext(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName, Phase, RepresentationContexts, UnitsInContext);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		args.push(this.Phase);;
		args.push(this.RepresentationContexts);;
		args.push(this.UnitsInContext);;
		return args;
	}
};
export class IfcContextDependentUnit {
	constructor(expressID: number, type: number, Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) , UnitType: IfcUnitEnum , Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Dimensions = Dimensions;
		this.UnitType = UnitType;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) ;
	UnitType: IfcUnitEnum ;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcContextDependentUnit
	{
		let ptr = 0;
		let Dimensions = tape[ptr++];
		let UnitType = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcContextDependentUnit(expressID, type, Dimensions, UnitType, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Dimensions);;
		args.push(this.UnitType);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcControl {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcControl
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		return new IfcControl(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		return args;
	}
};
export class IfcController {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcControllerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcControllerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcController
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcController(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcControllerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcControllerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcControllerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcControllerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcControllerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcConversionBasedUnit {
	constructor(expressID: number, type: number, Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) , UnitType: IfcUnitEnum , Name: IfcLabel , ConversionFactor: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Dimensions = Dimensions;
		this.UnitType = UnitType;
		this.Name = Name;
		this.ConversionFactor = ConversionFactor;
	}
	expressID: number;
	type: number;
	Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) ;
	UnitType: IfcUnitEnum ;
	Name: IfcLabel ;
	ConversionFactor: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConversionBasedUnit
	{
		let ptr = 0;
		let Dimensions = tape[ptr++];
		let UnitType = tape[ptr++];
		let Name = tape[ptr++];
		let ConversionFactor = tape[ptr++];
		return new IfcConversionBasedUnit(expressID, type, Dimensions, UnitType, Name, ConversionFactor);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Dimensions);;
		args.push(this.UnitType);;
		args.push(this.Name);;
		args.push(this.ConversionFactor);;
		return args;
	}
};
export class IfcConversionBasedUnitWithOffset {
	constructor(expressID: number, type: number, Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) , UnitType: IfcUnitEnum , Name: IfcLabel , ConversionFactor: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) , ConversionOffset: IfcReal )
	{
		this.expressID = expressID;
		this.type = type;
		this.Dimensions = Dimensions;
		this.UnitType = UnitType;
		this.Name = Name;
		this.ConversionFactor = ConversionFactor;
		this.ConversionOffset = ConversionOffset;
	}
	expressID: number;
	type: number;
	Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) ;
	UnitType: IfcUnitEnum ;
	Name: IfcLabel ;
	ConversionFactor: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) ;
	ConversionOffset: IfcReal ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcConversionBasedUnitWithOffset
	{
		let ptr = 0;
		let Dimensions = tape[ptr++];
		let UnitType = tape[ptr++];
		let Name = tape[ptr++];
		let ConversionFactor = tape[ptr++];
		let ConversionOffset = tape[ptr++];
		return new IfcConversionBasedUnitWithOffset(expressID, type, Dimensions, UnitType, Name, ConversionFactor, ConversionOffset);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Dimensions);;
		args.push(this.UnitType);;
		args.push(this.Name);;
		args.push(this.ConversionFactor);;
		args.push(this.ConversionOffset);;
		return args;
	}
};
export class IfcCooledBeam {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCooledBeamTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCooledBeamTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCooledBeam
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCooledBeam(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCooledBeamType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCooledBeamTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCooledBeamTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCooledBeamType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCooledBeamType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCoolingTower {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCoolingTowerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCoolingTowerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoolingTower
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCoolingTower(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCoolingTowerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCoolingTowerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCoolingTowerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoolingTowerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCoolingTowerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCoordinateOperation {
	constructor(expressID: number, type: number, SourceCRS: IfcCoordinateReferenceSystemSelect , TargetCRS: (Handle<IfcCoordinateReferenceSystem> | IfcCoordinateReferenceSystem) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SourceCRS = SourceCRS;
		this.TargetCRS = TargetCRS;
	}
	expressID: number;
	type: number;
	SourceCRS: IfcCoordinateReferenceSystemSelect ;
	TargetCRS: (Handle<IfcCoordinateReferenceSystem> | IfcCoordinateReferenceSystem) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoordinateOperation
	{
		let ptr = 0;
		let SourceCRS = tape[ptr++];
		let TargetCRS = tape[ptr++];
		return new IfcCoordinateOperation(expressID, type, SourceCRS, TargetCRS);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SourceCRS);;
		args.push(this.TargetCRS);;
		return args;
	}
};
export class IfcCoordinateReferenceSystem {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, GeodeticDatum: IfcIdentifier | null, VerticalDatum: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.GeodeticDatum = GeodeticDatum;
		this.VerticalDatum = VerticalDatum;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	GeodeticDatum: IfcIdentifier | null;
	VerticalDatum: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoordinateReferenceSystem
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let GeodeticDatum = tape[ptr++];
		let VerticalDatum = tape[ptr++];
		return new IfcCoordinateReferenceSystem(expressID, type, Name, Description, GeodeticDatum, VerticalDatum);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.GeodeticDatum);;
		args.push(this.VerticalDatum);;
		return args;
	}
};
export class IfcCostItem {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, PredefinedType: IfcCostItemTypeEnum | null, CostValues: (Handle<IfcCostValue> | IfcCostValue)[] | null, CostQuantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.PredefinedType = PredefinedType;
		this.CostValues = CostValues;
		this.CostQuantities = CostQuantities;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	PredefinedType: IfcCostItemTypeEnum | null;
	CostValues: (Handle<IfcCostValue> | IfcCostValue)[] | null;
	CostQuantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCostItem
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let CostValues = tape[ptr++];
		let CostQuantities = tape[ptr++];
		return new IfcCostItem(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, PredefinedType, CostValues, CostQuantities);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.PredefinedType);;
		args.push(this.CostValues);;
		args.push(this.CostQuantities);;
		return args;
	}
};
export class IfcCostSchedule {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, PredefinedType: IfcCostScheduleTypeEnum | null, Status: IfcLabel | null, SubmittedOn: IfcDateTime | null, UpdateDate: IfcDateTime | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.PredefinedType = PredefinedType;
		this.Status = Status;
		this.SubmittedOn = SubmittedOn;
		this.UpdateDate = UpdateDate;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	PredefinedType: IfcCostScheduleTypeEnum | null;
	Status: IfcLabel | null;
	SubmittedOn: IfcDateTime | null;
	UpdateDate: IfcDateTime | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCostSchedule
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Status = tape[ptr++];
		let SubmittedOn = tape[ptr++];
		let UpdateDate = tape[ptr++];
		return new IfcCostSchedule(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, PredefinedType, Status, SubmittedOn, UpdateDate);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.PredefinedType);;
		args.push(this.Status);;
		args.push(this.SubmittedOn);;
		args.push(this.UpdateDate);;
		return args;
	}
};
export class IfcCostValue {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, AppliedValue: IfcAppliedValueSelect | null, UnitBasis: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null, ApplicableDate: IfcDate | null, FixedUntilDate: IfcDate | null, Category: IfcLabel | null, Condition: IfcLabel | null, ArithmeticOperator: IfcArithmeticOperatorEnum | null, Components: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.AppliedValue = AppliedValue;
		this.UnitBasis = UnitBasis;
		this.ApplicableDate = ApplicableDate;
		this.FixedUntilDate = FixedUntilDate;
		this.Category = Category;
		this.Condition = Condition;
		this.ArithmeticOperator = ArithmeticOperator;
		this.Components = Components;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	AppliedValue: IfcAppliedValueSelect | null;
	UnitBasis: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null;
	ApplicableDate: IfcDate | null;
	FixedUntilDate: IfcDate | null;
	Category: IfcLabel | null;
	Condition: IfcLabel | null;
	ArithmeticOperator: IfcArithmeticOperatorEnum | null;
	Components: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCostValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let AppliedValue = tape[ptr++];
		let UnitBasis = tape[ptr++];
		let ApplicableDate = tape[ptr++];
		let FixedUntilDate = tape[ptr++];
		let Category = tape[ptr++];
		let Condition = tape[ptr++];
		let ArithmeticOperator = tape[ptr++];
		let Components = tape[ptr++];
		return new IfcCostValue(expressID, type, Name, Description, AppliedValue, UnitBasis, ApplicableDate, FixedUntilDate, Category, Condition, ArithmeticOperator, Components);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.AppliedValue);;
		args.push(this.UnitBasis);;
		args.push(this.ApplicableDate);;
		args.push(this.FixedUntilDate);;
		args.push(this.Category);;
		args.push(this.Condition);;
		args.push(this.ArithmeticOperator);;
		args.push(this.Components);;
		return args;
	}
};
export class IfcCovering {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCoveringTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCoveringTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCovering
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCovering(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCoveringType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCoveringTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCoveringTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCoveringType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCoveringType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCrewResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcCrewResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcCrewResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCrewResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCrewResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCrewResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcCrewResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcCrewResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCrewResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCrewResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCsgPrimitive3D {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCsgPrimitive3D
	{
		let ptr = 0;
		let Position = tape[ptr++];
		return new IfcCsgPrimitive3D(expressID, type, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		return args;
	}
};
export class IfcCsgSolid {
	constructor(expressID: number, type: number, TreeRootExpression: IfcCsgSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.TreeRootExpression = TreeRootExpression;
	}
	expressID: number;
	type: number;
	TreeRootExpression: IfcCsgSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCsgSolid
	{
		let ptr = 0;
		let TreeRootExpression = tape[ptr++];
		return new IfcCsgSolid(expressID, type, TreeRootExpression);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TreeRootExpression);;
		return args;
	}
};
export class IfcCurrencyRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingMonetaryUnit: (Handle<IfcMonetaryUnit> | IfcMonetaryUnit) , RelatedMonetaryUnit: (Handle<IfcMonetaryUnit> | IfcMonetaryUnit) , ExchangeRate: IfcPositiveRatioMeasure , RateDateTime: IfcDateTime | null, RateSource: (Handle<IfcLibraryInformation> | IfcLibraryInformation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingMonetaryUnit = RelatingMonetaryUnit;
		this.RelatedMonetaryUnit = RelatedMonetaryUnit;
		this.ExchangeRate = ExchangeRate;
		this.RateDateTime = RateDateTime;
		this.RateSource = RateSource;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingMonetaryUnit: (Handle<IfcMonetaryUnit> | IfcMonetaryUnit) ;
	RelatedMonetaryUnit: (Handle<IfcMonetaryUnit> | IfcMonetaryUnit) ;
	ExchangeRate: IfcPositiveRatioMeasure ;
	RateDateTime: IfcDateTime | null;
	RateSource: (Handle<IfcLibraryInformation> | IfcLibraryInformation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurrencyRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingMonetaryUnit = tape[ptr++];
		let RelatedMonetaryUnit = tape[ptr++];
		let ExchangeRate = tape[ptr++];
		let RateDateTime = tape[ptr++];
		let RateSource = tape[ptr++];
		return new IfcCurrencyRelationship(expressID, type, Name, Description, RelatingMonetaryUnit, RelatedMonetaryUnit, ExchangeRate, RateDateTime, RateSource);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingMonetaryUnit);;
		args.push(this.RelatedMonetaryUnit);;
		args.push(this.ExchangeRate);;
		args.push(this.RateDateTime);;
		args.push(this.RateSource);;
		return args;
	}
};
export class IfcCurtainWall {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcCurtainWallTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcCurtainWallTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurtainWall
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCurtainWall(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCurtainWallType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcCurtainWallTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcCurtainWallTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurtainWallType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcCurtainWallType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcCurve {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurve
	{
		let ptr = 0;
		return new IfcCurve(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcCurveBoundedPlane {
	constructor(expressID: number, type: number, BasisSurface: (Handle<IfcPlane> | IfcPlane) , OuterBoundary: (Handle<IfcCurve> | IfcCurve) , InnerBoundaries: (Handle<IfcCurve> | IfcCurve)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisSurface = BasisSurface;
		this.OuterBoundary = OuterBoundary;
		this.InnerBoundaries = InnerBoundaries;
	}
	expressID: number;
	type: number;
	BasisSurface: (Handle<IfcPlane> | IfcPlane) ;
	OuterBoundary: (Handle<IfcCurve> | IfcCurve) ;
	InnerBoundaries: (Handle<IfcCurve> | IfcCurve)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveBoundedPlane
	{
		let ptr = 0;
		let BasisSurface = tape[ptr++];
		let OuterBoundary = tape[ptr++];
		let InnerBoundaries = tape[ptr++];
		return new IfcCurveBoundedPlane(expressID, type, BasisSurface, OuterBoundary, InnerBoundaries);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisSurface);;
		args.push(this.OuterBoundary);;
		args.push(this.InnerBoundaries);;
		return args;
	}
};
export class IfcCurveBoundedSurface {
	constructor(expressID: number, type: number, BasisSurface: (Handle<IfcSurface> | IfcSurface) , Boundaries: (Handle<IfcBoundaryCurve> | IfcBoundaryCurve)[] , ImplicitOuter: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisSurface = BasisSurface;
		this.Boundaries = Boundaries;
		this.ImplicitOuter = ImplicitOuter;
	}
	expressID: number;
	type: number;
	BasisSurface: (Handle<IfcSurface> | IfcSurface) ;
	Boundaries: (Handle<IfcBoundaryCurve> | IfcBoundaryCurve)[] ;
	ImplicitOuter: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveBoundedSurface
	{
		let ptr = 0;
		let BasisSurface = tape[ptr++];
		let Boundaries = tape[ptr++];
		let ImplicitOuter = tape[ptr++];
		return new IfcCurveBoundedSurface(expressID, type, BasisSurface, Boundaries, ImplicitOuter);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisSurface);;
		args.push(this.Boundaries);;
		args.push(this.ImplicitOuter);;
		return args;
	}
};
export class IfcCurveSegment2D {
	constructor(expressID: number, type: number, StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , StartDirection: IfcPlaneAngleMeasure , SegmentLength: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartPoint = StartPoint;
		this.StartDirection = StartDirection;
		this.SegmentLength = SegmentLength;
	}
	expressID: number;
	type: number;
	StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	StartDirection: IfcPlaneAngleMeasure ;
	SegmentLength: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveSegment2D
	{
		let ptr = 0;
		let StartPoint = tape[ptr++];
		let StartDirection = tape[ptr++];
		let SegmentLength = tape[ptr++];
		return new IfcCurveSegment2D(expressID, type, StartPoint, StartDirection, SegmentLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartPoint);;
		args.push(this.StartDirection);;
		args.push(this.SegmentLength);;
		return args;
	}
};
export class IfcCurveStyle {
	constructor(expressID: number, type: number, Name: IfcLabel | null, CurveFont: IfcCurveFontOrScaledCurveFontSelect | null, CurveWidth: IfcSizeSelect | null, CurveColour: IfcColour | null, ModelOrDraughting: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.CurveFont = CurveFont;
		this.CurveWidth = CurveWidth;
		this.CurveColour = CurveColour;
		this.ModelOrDraughting = ModelOrDraughting;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	CurveFont: IfcCurveFontOrScaledCurveFontSelect | null;
	CurveWidth: IfcSizeSelect | null;
	CurveColour: IfcColour | null;
	ModelOrDraughting: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let CurveFont = tape[ptr++];
		let CurveWidth = tape[ptr++];
		let CurveColour = tape[ptr++];
		let ModelOrDraughting = tape[ptr++];
		return new IfcCurveStyle(expressID, type, Name, CurveFont, CurveWidth, CurveColour, ModelOrDraughting);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.CurveFont);;
		args.push(this.CurveWidth);;
		args.push(this.CurveColour);;
		args.push(this.ModelOrDraughting);;
		return args;
	}
};
export class IfcCurveStyleFont {
	constructor(expressID: number, type: number, Name: IfcLabel | null, PatternList: (Handle<IfcCurveStyleFontPattern> | IfcCurveStyleFontPattern)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.PatternList = PatternList;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	PatternList: (Handle<IfcCurveStyleFontPattern> | IfcCurveStyleFontPattern)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveStyleFont
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let PatternList = tape[ptr++];
		return new IfcCurveStyleFont(expressID, type, Name, PatternList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.PatternList);;
		return args;
	}
};
export class IfcCurveStyleFontAndScaling {
	constructor(expressID: number, type: number, Name: IfcLabel | null, CurveFont: IfcCurveStyleFontSelect , CurveFontScaling: IfcPositiveRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.CurveFont = CurveFont;
		this.CurveFontScaling = CurveFontScaling;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	CurveFont: IfcCurveStyleFontSelect ;
	CurveFontScaling: IfcPositiveRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveStyleFontAndScaling
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let CurveFont = tape[ptr++];
		let CurveFontScaling = tape[ptr++];
		return new IfcCurveStyleFontAndScaling(expressID, type, Name, CurveFont, CurveFontScaling);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.CurveFont);;
		args.push(this.CurveFontScaling);;
		return args;
	}
};
export class IfcCurveStyleFontPattern {
	constructor(expressID: number, type: number, VisibleSegmentLength: IfcLengthMeasure , InvisibleSegmentLength: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.VisibleSegmentLength = VisibleSegmentLength;
		this.InvisibleSegmentLength = InvisibleSegmentLength;
	}
	expressID: number;
	type: number;
	VisibleSegmentLength: IfcLengthMeasure ;
	InvisibleSegmentLength: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCurveStyleFontPattern
	{
		let ptr = 0;
		let VisibleSegmentLength = tape[ptr++];
		let InvisibleSegmentLength = tape[ptr++];
		return new IfcCurveStyleFontPattern(expressID, type, VisibleSegmentLength, InvisibleSegmentLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.VisibleSegmentLength);;
		args.push(this.InvisibleSegmentLength);;
		return args;
	}
};
export class IfcCylindricalSurface {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcCylindricalSurface
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcCylindricalSurface(expressID, type, Position, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcDamper {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDamperTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDamperTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDamper
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDamper(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDamperType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDamperTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDamperTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDamperType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDamperType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDeepFoundation {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDeepFoundation
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcDeepFoundation(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcDeepFoundationType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDeepFoundationType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcDeepFoundationType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcDerivedProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, ParentProfile: (Handle<IfcProfileDef> | IfcProfileDef) , Operator: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) , Label: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.ParentProfile = ParentProfile;
		this.Operator = Operator;
		this.Label = Label;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	ParentProfile: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Operator: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) ;
	Label: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDerivedProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let ParentProfile = tape[ptr++];
		let Operator = tape[ptr++];
		let Label = tape[ptr++];
		return new IfcDerivedProfileDef(expressID, type, ProfileType, ProfileName, ParentProfile, Operator, Label);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.ParentProfile);;
		args.push(this.Operator);;
		args.push(this.Label);;
		return args;
	}
};
export class IfcDerivedUnit {
	constructor(expressID: number, type: number, Elements: (Handle<IfcDerivedUnitElement> | IfcDerivedUnitElement)[] , UnitType: IfcDerivedUnitEnum , UserDefinedType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Elements = Elements;
		this.UnitType = UnitType;
		this.UserDefinedType = UserDefinedType;
	}
	expressID: number;
	type: number;
	Elements: (Handle<IfcDerivedUnitElement> | IfcDerivedUnitElement)[] ;
	UnitType: IfcDerivedUnitEnum ;
	UserDefinedType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDerivedUnit
	{
		let ptr = 0;
		let Elements = tape[ptr++];
		let UnitType = tape[ptr++];
		let UserDefinedType = tape[ptr++];
		return new IfcDerivedUnit(expressID, type, Elements, UnitType, UserDefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Elements);;
		args.push(this.UnitType);;
		args.push(this.UserDefinedType);;
		return args;
	}
};
export class IfcDerivedUnitElement {
	constructor(expressID: number, type: number, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) , Exponent: number )
	{
		this.expressID = expressID;
		this.type = type;
		this.Unit = Unit;
		this.Exponent = Exponent;
	}
	expressID: number;
	type: number;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) ;
	Exponent: number ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDerivedUnitElement
	{
		let ptr = 0;
		let Unit = tape[ptr++];
		let Exponent = tape[ptr++];
		return new IfcDerivedUnitElement(expressID, type, Unit, Exponent);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Unit);;
		args.push(this.Exponent);;
		return args;
	}
};
export class IfcDimensionalExponents {
	constructor(expressID: number, type: number, LengthExponent: number , MassExponent: number , TimeExponent: number , ElectricCurrentExponent: number , ThermodynamicTemperatureExponent: number , AmountOfSubstanceExponent: number , LuminousIntensityExponent: number )
	{
		this.expressID = expressID;
		this.type = type;
		this.LengthExponent = LengthExponent;
		this.MassExponent = MassExponent;
		this.TimeExponent = TimeExponent;
		this.ElectricCurrentExponent = ElectricCurrentExponent;
		this.ThermodynamicTemperatureExponent = ThermodynamicTemperatureExponent;
		this.AmountOfSubstanceExponent = AmountOfSubstanceExponent;
		this.LuminousIntensityExponent = LuminousIntensityExponent;
	}
	expressID: number;
	type: number;
	LengthExponent: number ;
	MassExponent: number ;
	TimeExponent: number ;
	ElectricCurrentExponent: number ;
	ThermodynamicTemperatureExponent: number ;
	AmountOfSubstanceExponent: number ;
	LuminousIntensityExponent: number ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDimensionalExponents
	{
		let ptr = 0;
		let LengthExponent = tape[ptr++];
		let MassExponent = tape[ptr++];
		let TimeExponent = tape[ptr++];
		let ElectricCurrentExponent = tape[ptr++];
		let ThermodynamicTemperatureExponent = tape[ptr++];
		let AmountOfSubstanceExponent = tape[ptr++];
		let LuminousIntensityExponent = tape[ptr++];
		return new IfcDimensionalExponents(expressID, type, LengthExponent, MassExponent, TimeExponent, ElectricCurrentExponent, ThermodynamicTemperatureExponent, AmountOfSubstanceExponent, LuminousIntensityExponent);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.LengthExponent);;
		args.push(this.MassExponent);;
		args.push(this.TimeExponent);;
		args.push(this.ElectricCurrentExponent);;
		args.push(this.ThermodynamicTemperatureExponent);;
		args.push(this.AmountOfSubstanceExponent);;
		args.push(this.LuminousIntensityExponent);;
		return args;
	}
};
export class IfcDirection {
	constructor(expressID: number, type: number, DirectionRatios: IfcReal[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.DirectionRatios = DirectionRatios;
	}
	expressID: number;
	type: number;
	DirectionRatios: IfcReal[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDirection
	{
		let ptr = 0;
		let DirectionRatios = tape[ptr++];
		return new IfcDirection(expressID, type, DirectionRatios);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.DirectionRatios);;
		return args;
	}
};
export class IfcDiscreteAccessory {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDiscreteAccessoryTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDiscreteAccessoryTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDiscreteAccessory
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDiscreteAccessory(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDiscreteAccessoryType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDiscreteAccessoryTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDiscreteAccessoryTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDiscreteAccessoryType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDiscreteAccessoryType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDistanceExpression {
	constructor(expressID: number, type: number, DistanceAlong: IfcLengthMeasure , OffsetLateral: IfcLengthMeasure | null, OffsetVertical: IfcLengthMeasure | null, OffsetLongitudinal: IfcLengthMeasure | null, AlongHorizontal: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.DistanceAlong = DistanceAlong;
		this.OffsetLateral = OffsetLateral;
		this.OffsetVertical = OffsetVertical;
		this.OffsetLongitudinal = OffsetLongitudinal;
		this.AlongHorizontal = AlongHorizontal;
	}
	expressID: number;
	type: number;
	DistanceAlong: IfcLengthMeasure ;
	OffsetLateral: IfcLengthMeasure | null;
	OffsetVertical: IfcLengthMeasure | null;
	OffsetLongitudinal: IfcLengthMeasure | null;
	AlongHorizontal: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistanceExpression
	{
		let ptr = 0;
		let DistanceAlong = tape[ptr++];
		let OffsetLateral = tape[ptr++];
		let OffsetVertical = tape[ptr++];
		let OffsetLongitudinal = tape[ptr++];
		let AlongHorizontal = tape[ptr++];
		return new IfcDistanceExpression(expressID, type, DistanceAlong, OffsetLateral, OffsetVertical, OffsetLongitudinal, AlongHorizontal);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.DistanceAlong);;
		args.push(this.OffsetLateral);;
		args.push(this.OffsetVertical);;
		args.push(this.OffsetLongitudinal);;
		args.push(this.AlongHorizontal);;
		return args;
	}
};
export class IfcDistributionChamberElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDistributionChamberElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDistributionChamberElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionChamberElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDistributionChamberElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDistributionChamberElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDistributionChamberElementTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDistributionChamberElementTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionChamberElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDistributionChamberElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDistributionCircuit {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null, PredefinedType: IfcDistributionSystemEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	PredefinedType: IfcDistributionSystemEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionCircuit
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDistributionCircuit(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDistributionControlElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionControlElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcDistributionControlElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcDistributionControlElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionControlElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcDistributionControlElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcDistributionElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcDistributionElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcDistributionElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcDistributionElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcDistributionFlowElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionFlowElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcDistributionFlowElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcDistributionFlowElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionFlowElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcDistributionFlowElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcDistributionPort {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, FlowDirection: IfcFlowDirectionEnum | null, PredefinedType: IfcDistributionPortTypeEnum | null, SystemType: IfcDistributionSystemEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.FlowDirection = FlowDirection;
		this.PredefinedType = PredefinedType;
		this.SystemType = SystemType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	FlowDirection: IfcFlowDirectionEnum | null;
	PredefinedType: IfcDistributionPortTypeEnum | null;
	SystemType: IfcDistributionSystemEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionPort
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let FlowDirection = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let SystemType = tape[ptr++];
		return new IfcDistributionPort(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, FlowDirection, PredefinedType, SystemType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.FlowDirection);;
		args.push(this.PredefinedType);;
		args.push(this.SystemType);;
		return args;
	}
};
export class IfcDistributionSystem {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null, PredefinedType: IfcDistributionSystemEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	PredefinedType: IfcDistributionSystemEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDistributionSystem
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDistributionSystem(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDocumentInformation {
	constructor(expressID: number, type: number, Identification: IfcIdentifier , Name: IfcLabel , Description: IfcText | null, Location: IfcURIReference | null, Purpose: IfcText | null, IntendedUse: IfcText | null, Scope: IfcText | null, Revision: IfcLabel | null, DocumentOwner: IfcActorSelect | null, Editors: IfcActorSelect[] | null, CreationTime: IfcDateTime | null, LastRevisionTime: IfcDateTime | null, ElectronicFormat: IfcIdentifier | null, ValidFrom: IfcDate | null, ValidUntil: IfcDate | null, Confidentiality: IfcDocumentConfidentialityEnum | null, Status: IfcDocumentStatusEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Identification = Identification;
		this.Name = Name;
		this.Description = Description;
		this.Location = Location;
		this.Purpose = Purpose;
		this.IntendedUse = IntendedUse;
		this.Scope = Scope;
		this.Revision = Revision;
		this.DocumentOwner = DocumentOwner;
		this.Editors = Editors;
		this.CreationTime = CreationTime;
		this.LastRevisionTime = LastRevisionTime;
		this.ElectronicFormat = ElectronicFormat;
		this.ValidFrom = ValidFrom;
		this.ValidUntil = ValidUntil;
		this.Confidentiality = Confidentiality;
		this.Status = Status;
	}
	expressID: number;
	type: number;
	Identification: IfcIdentifier ;
	Name: IfcLabel ;
	Description: IfcText | null;
	Location: IfcURIReference | null;
	Purpose: IfcText | null;
	IntendedUse: IfcText | null;
	Scope: IfcText | null;
	Revision: IfcLabel | null;
	DocumentOwner: IfcActorSelect | null;
	Editors: IfcActorSelect[] | null;
	CreationTime: IfcDateTime | null;
	LastRevisionTime: IfcDateTime | null;
	ElectronicFormat: IfcIdentifier | null;
	ValidFrom: IfcDate | null;
	ValidUntil: IfcDate | null;
	Confidentiality: IfcDocumentConfidentialityEnum | null;
	Status: IfcDocumentStatusEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDocumentInformation
	{
		let ptr = 0;
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Location = tape[ptr++];
		let Purpose = tape[ptr++];
		let IntendedUse = tape[ptr++];
		let Scope = tape[ptr++];
		let Revision = tape[ptr++];
		let DocumentOwner = tape[ptr++];
		let Editors = tape[ptr++];
		let CreationTime = tape[ptr++];
		let LastRevisionTime = tape[ptr++];
		let ElectronicFormat = tape[ptr++];
		let ValidFrom = tape[ptr++];
		let ValidUntil = tape[ptr++];
		let Confidentiality = tape[ptr++];
		let Status = tape[ptr++];
		return new IfcDocumentInformation(expressID, type, Identification, Name, Description, Location, Purpose, IntendedUse, Scope, Revision, DocumentOwner, Editors, CreationTime, LastRevisionTime, ElectronicFormat, ValidFrom, ValidUntil, Confidentiality, Status);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Identification);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Location);;
		args.push(this.Purpose);;
		args.push(this.IntendedUse);;
		args.push(this.Scope);;
		args.push(this.Revision);;
		args.push(this.DocumentOwner);;
		args.push(this.Editors);;
		args.push(this.CreationTime);;
		args.push(this.LastRevisionTime);;
		args.push(this.ElectronicFormat);;
		args.push(this.ValidFrom);;
		args.push(this.ValidUntil);;
		args.push(this.Confidentiality);;
		args.push(this.Status);;
		return args;
	}
};
export class IfcDocumentInformationRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingDocument: (Handle<IfcDocumentInformation> | IfcDocumentInformation) , RelatedDocuments: (Handle<IfcDocumentInformation> | IfcDocumentInformation)[] , RelationshipType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingDocument = RelatingDocument;
		this.RelatedDocuments = RelatedDocuments;
		this.RelationshipType = RelationshipType;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingDocument: (Handle<IfcDocumentInformation> | IfcDocumentInformation) ;
	RelatedDocuments: (Handle<IfcDocumentInformation> | IfcDocumentInformation)[] ;
	RelationshipType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDocumentInformationRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingDocument = tape[ptr++];
		let RelatedDocuments = tape[ptr++];
		let RelationshipType = tape[ptr++];
		return new IfcDocumentInformationRelationship(expressID, type, Name, Description, RelatingDocument, RelatedDocuments, RelationshipType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingDocument);;
		args.push(this.RelatedDocuments);;
		args.push(this.RelationshipType);;
		return args;
	}
};
export class IfcDocumentReference {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null, Description: IfcText | null, ReferencedDocument: (Handle<IfcDocumentInformation> | IfcDocumentInformation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
		this.Description = Description;
		this.ReferencedDocument = ReferencedDocument;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ReferencedDocument: (Handle<IfcDocumentInformation> | IfcDocumentInformation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDocumentReference
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ReferencedDocument = tape[ptr++];
		return new IfcDocumentReference(expressID, type, Location, Identification, Name, Description, ReferencedDocument);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ReferencedDocument);;
		return args;
	}
};
export class IfcDoor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, OverallHeight: IfcPositiveLengthMeasure | null, OverallWidth: IfcPositiveLengthMeasure | null, PredefinedType: IfcDoorTypeEnum | null, OperationType: IfcDoorTypeOperationEnum | null, UserDefinedOperationType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.OverallHeight = OverallHeight;
		this.OverallWidth = OverallWidth;
		this.PredefinedType = PredefinedType;
		this.OperationType = OperationType;
		this.UserDefinedOperationType = UserDefinedOperationType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	OverallHeight: IfcPositiveLengthMeasure | null;
	OverallWidth: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcDoorTypeEnum | null;
	OperationType: IfcDoorTypeOperationEnum | null;
	UserDefinedOperationType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let OverallHeight = tape[ptr++];
		let OverallWidth = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let OperationType = tape[ptr++];
		let UserDefinedOperationType = tape[ptr++];
		return new IfcDoor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, OverallHeight, OverallWidth, PredefinedType, OperationType, UserDefinedOperationType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.OverallHeight);;
		args.push(this.OverallWidth);;
		args.push(this.PredefinedType);;
		args.push(this.OperationType);;
		args.push(this.UserDefinedOperationType);;
		return args;
	}
};
export class IfcDoorLiningProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, LiningDepth: IfcPositiveLengthMeasure | null, LiningThickness: IfcNonNegativeLengthMeasure | null, ThresholdDepth: IfcPositiveLengthMeasure | null, ThresholdThickness: IfcNonNegativeLengthMeasure | null, TransomThickness: IfcNonNegativeLengthMeasure | null, TransomOffset: IfcLengthMeasure | null, LiningOffset: IfcLengthMeasure | null, ThresholdOffset: IfcLengthMeasure | null, CasingThickness: IfcPositiveLengthMeasure | null, CasingDepth: IfcPositiveLengthMeasure | null, ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null, LiningToPanelOffsetX: IfcLengthMeasure | null, LiningToPanelOffsetY: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.LiningDepth = LiningDepth;
		this.LiningThickness = LiningThickness;
		this.ThresholdDepth = ThresholdDepth;
		this.ThresholdThickness = ThresholdThickness;
		this.TransomThickness = TransomThickness;
		this.TransomOffset = TransomOffset;
		this.LiningOffset = LiningOffset;
		this.ThresholdOffset = ThresholdOffset;
		this.CasingThickness = CasingThickness;
		this.CasingDepth = CasingDepth;
		this.ShapeAspectStyle = ShapeAspectStyle;
		this.LiningToPanelOffsetX = LiningToPanelOffsetX;
		this.LiningToPanelOffsetY = LiningToPanelOffsetY;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	LiningDepth: IfcPositiveLengthMeasure | null;
	LiningThickness: IfcNonNegativeLengthMeasure | null;
	ThresholdDepth: IfcPositiveLengthMeasure | null;
	ThresholdThickness: IfcNonNegativeLengthMeasure | null;
	TransomThickness: IfcNonNegativeLengthMeasure | null;
	TransomOffset: IfcLengthMeasure | null;
	LiningOffset: IfcLengthMeasure | null;
	ThresholdOffset: IfcLengthMeasure | null;
	CasingThickness: IfcPositiveLengthMeasure | null;
	CasingDepth: IfcPositiveLengthMeasure | null;
	ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null;
	LiningToPanelOffsetX: IfcLengthMeasure | null;
	LiningToPanelOffsetY: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoorLiningProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let LiningDepth = tape[ptr++];
		let LiningThickness = tape[ptr++];
		let ThresholdDepth = tape[ptr++];
		let ThresholdThickness = tape[ptr++];
		let TransomThickness = tape[ptr++];
		let TransomOffset = tape[ptr++];
		let LiningOffset = tape[ptr++];
		let ThresholdOffset = tape[ptr++];
		let CasingThickness = tape[ptr++];
		let CasingDepth = tape[ptr++];
		let ShapeAspectStyle = tape[ptr++];
		let LiningToPanelOffsetX = tape[ptr++];
		let LiningToPanelOffsetY = tape[ptr++];
		return new IfcDoorLiningProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, LiningDepth, LiningThickness, ThresholdDepth, ThresholdThickness, TransomThickness, TransomOffset, LiningOffset, ThresholdOffset, CasingThickness, CasingDepth, ShapeAspectStyle, LiningToPanelOffsetX, LiningToPanelOffsetY);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.LiningDepth);;
		args.push(this.LiningThickness);;
		args.push(this.ThresholdDepth);;
		args.push(this.ThresholdThickness);;
		args.push(this.TransomThickness);;
		args.push(this.TransomOffset);;
		args.push(this.LiningOffset);;
		args.push(this.ThresholdOffset);;
		args.push(this.CasingThickness);;
		args.push(this.CasingDepth);;
		args.push(this.ShapeAspectStyle);;
		args.push(this.LiningToPanelOffsetX);;
		args.push(this.LiningToPanelOffsetY);;
		return args;
	}
};
export class IfcDoorPanelProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, PanelDepth: IfcPositiveLengthMeasure | null, PanelOperation: IfcDoorPanelOperationEnum , PanelWidth: IfcNormalisedRatioMeasure | null, PanelPosition: IfcDoorPanelPositionEnum , ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.PanelDepth = PanelDepth;
		this.PanelOperation = PanelOperation;
		this.PanelWidth = PanelWidth;
		this.PanelPosition = PanelPosition;
		this.ShapeAspectStyle = ShapeAspectStyle;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	PanelDepth: IfcPositiveLengthMeasure | null;
	PanelOperation: IfcDoorPanelOperationEnum ;
	PanelWidth: IfcNormalisedRatioMeasure | null;
	PanelPosition: IfcDoorPanelPositionEnum ;
	ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoorPanelProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let PanelDepth = tape[ptr++];
		let PanelOperation = tape[ptr++];
		let PanelWidth = tape[ptr++];
		let PanelPosition = tape[ptr++];
		let ShapeAspectStyle = tape[ptr++];
		return new IfcDoorPanelProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, PanelDepth, PanelOperation, PanelWidth, PanelPosition, ShapeAspectStyle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.PanelDepth);;
		args.push(this.PanelOperation);;
		args.push(this.PanelWidth);;
		args.push(this.PanelPosition);;
		args.push(this.ShapeAspectStyle);;
		return args;
	}
};
export class IfcDoorStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, OverallHeight: IfcPositiveLengthMeasure | null, OverallWidth: IfcPositiveLengthMeasure | null, PredefinedType: IfcDoorTypeEnum | null, OperationType: IfcDoorTypeOperationEnum | null, UserDefinedOperationType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.OverallHeight = OverallHeight;
		this.OverallWidth = OverallWidth;
		this.PredefinedType = PredefinedType;
		this.OperationType = OperationType;
		this.UserDefinedOperationType = UserDefinedOperationType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	OverallHeight: IfcPositiveLengthMeasure | null;
	OverallWidth: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcDoorTypeEnum | null;
	OperationType: IfcDoorTypeOperationEnum | null;
	UserDefinedOperationType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoorStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let OverallHeight = tape[ptr++];
		let OverallWidth = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let OperationType = tape[ptr++];
		let UserDefinedOperationType = tape[ptr++];
		return new IfcDoorStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, OverallHeight, OverallWidth, PredefinedType, OperationType, UserDefinedOperationType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.OverallHeight);;
		args.push(this.OverallWidth);;
		args.push(this.PredefinedType);;
		args.push(this.OperationType);;
		args.push(this.UserDefinedOperationType);;
		return args;
	}
};
export class IfcDoorStyle {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, OperationType: IfcDoorStyleOperationEnum , ConstructionType: IfcDoorStyleConstructionEnum , ParameterTakesPrecedence: IfcBoolean , Sizeable: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.OperationType = OperationType;
		this.ConstructionType = ConstructionType;
		this.ParameterTakesPrecedence = ParameterTakesPrecedence;
		this.Sizeable = Sizeable;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	OperationType: IfcDoorStyleOperationEnum ;
	ConstructionType: IfcDoorStyleConstructionEnum ;
	ParameterTakesPrecedence: IfcBoolean ;
	Sizeable: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoorStyle
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let OperationType = tape[ptr++];
		let ConstructionType = tape[ptr++];
		let ParameterTakesPrecedence = tape[ptr++];
		let Sizeable = tape[ptr++];
		return new IfcDoorStyle(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, OperationType, ConstructionType, ParameterTakesPrecedence, Sizeable);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.OperationType);;
		args.push(this.ConstructionType);;
		args.push(this.ParameterTakesPrecedence);;
		args.push(this.Sizeable);;
		return args;
	}
};
export class IfcDoorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDoorTypeEnum , OperationType: IfcDoorTypeOperationEnum , ParameterTakesPrecedence: IfcBoolean | null, UserDefinedOperationType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.OperationType = OperationType;
		this.ParameterTakesPrecedence = ParameterTakesPrecedence;
		this.UserDefinedOperationType = UserDefinedOperationType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDoorTypeEnum ;
	OperationType: IfcDoorTypeOperationEnum ;
	ParameterTakesPrecedence: IfcBoolean | null;
	UserDefinedOperationType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDoorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let OperationType = tape[ptr++];
		let ParameterTakesPrecedence = tape[ptr++];
		let UserDefinedOperationType = tape[ptr++];
		return new IfcDoorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, OperationType, ParameterTakesPrecedence, UserDefinedOperationType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.OperationType);;
		args.push(this.ParameterTakesPrecedence);;
		args.push(this.UserDefinedOperationType);;
		return args;
	}
};
export class IfcDraughtingPreDefinedColour {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDraughtingPreDefinedColour
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcDraughtingPreDefinedColour(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcDraughtingPreDefinedCurveFont {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDraughtingPreDefinedCurveFont
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcDraughtingPreDefinedCurveFont(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcDuctFitting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDuctFittingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDuctFittingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctFitting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctFitting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDuctFittingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDuctFittingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDuctFittingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctFittingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctFittingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDuctSegment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDuctSegmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDuctSegmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctSegment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctSegment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDuctSegmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDuctSegmentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDuctSegmentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctSegmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctSegmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDuctSilencer {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcDuctSilencerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcDuctSilencerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctSilencer
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctSilencer(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcDuctSilencerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcDuctSilencerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcDuctSilencerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcDuctSilencerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcDuctSilencerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEdge {
	constructor(expressID: number, type: number, EdgeStart: (Handle<IfcVertex> | IfcVertex) , EdgeEnd: (Handle<IfcVertex> | IfcVertex) )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeStart = EdgeStart;
		this.EdgeEnd = EdgeEnd;
	}
	expressID: number;
	type: number;
	EdgeStart: (Handle<IfcVertex> | IfcVertex) ;
	EdgeEnd: (Handle<IfcVertex> | IfcVertex) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEdge
	{
		let ptr = 0;
		let EdgeStart = tape[ptr++];
		let EdgeEnd = tape[ptr++];
		return new IfcEdge(expressID, type, EdgeStart, EdgeEnd);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeStart);;
		args.push(this.EdgeEnd);;
		return args;
	}
};
export class IfcEdgeCurve {
	constructor(expressID: number, type: number, EdgeStart: (Handle<IfcVertex> | IfcVertex) , EdgeEnd: (Handle<IfcVertex> | IfcVertex) , EdgeGeometry: (Handle<IfcCurve> | IfcCurve) , SameSense: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeStart = EdgeStart;
		this.EdgeEnd = EdgeEnd;
		this.EdgeGeometry = EdgeGeometry;
		this.SameSense = SameSense;
	}
	expressID: number;
	type: number;
	EdgeStart: (Handle<IfcVertex> | IfcVertex) ;
	EdgeEnd: (Handle<IfcVertex> | IfcVertex) ;
	EdgeGeometry: (Handle<IfcCurve> | IfcCurve) ;
	SameSense: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEdgeCurve
	{
		let ptr = 0;
		let EdgeStart = tape[ptr++];
		let EdgeEnd = tape[ptr++];
		let EdgeGeometry = tape[ptr++];
		let SameSense = tape[ptr++];
		return new IfcEdgeCurve(expressID, type, EdgeStart, EdgeEnd, EdgeGeometry, SameSense);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeStart);;
		args.push(this.EdgeEnd);;
		args.push(this.EdgeGeometry);;
		args.push(this.SameSense);;
		return args;
	}
};
export class IfcEdgeLoop {
	constructor(expressID: number, type: number, EdgeList: (Handle<IfcOrientedEdge> | IfcOrientedEdge)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeList = EdgeList;
	}
	expressID: number;
	type: number;
	EdgeList: (Handle<IfcOrientedEdge> | IfcOrientedEdge)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEdgeLoop
	{
		let ptr = 0;
		let EdgeList = tape[ptr++];
		return new IfcEdgeLoop(expressID, type, EdgeList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeList);;
		return args;
	}
};
export class IfcElectricAppliance {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricApplianceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricApplianceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricAppliance
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricAppliance(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricApplianceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricApplianceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricApplianceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricApplianceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricApplianceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricDistributionBoard {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricDistributionBoardTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricDistributionBoardTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricDistributionBoard
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricDistributionBoard(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricDistributionBoardType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricDistributionBoardTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricDistributionBoardTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricDistributionBoardType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricDistributionBoardType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricFlowStorageDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricFlowStorageDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricFlowStorageDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricFlowStorageDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricFlowStorageDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricFlowStorageDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricFlowStorageDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricFlowStorageDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricFlowStorageDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricFlowStorageDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricGenerator {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricGeneratorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricGeneratorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricGenerator
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricGenerator(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricGeneratorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricGeneratorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricGeneratorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricGeneratorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricGeneratorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricMotor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricMotorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricMotorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricMotor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricMotor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricMotorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricMotorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricMotorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricMotorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricMotorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricTimeControl {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcElectricTimeControlTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcElectricTimeControlTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricTimeControl
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricTimeControl(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElectricTimeControlType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElectricTimeControlTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElectricTimeControlTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElectricTimeControlType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElectricTimeControlType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcElementAssembly {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, AssemblyPlace: IfcAssemblyPlaceEnum | null, PredefinedType: IfcElementAssemblyTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.AssemblyPlace = AssemblyPlace;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	AssemblyPlace: IfcAssemblyPlaceEnum | null;
	PredefinedType: IfcElementAssemblyTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementAssembly
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let AssemblyPlace = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElementAssembly(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, AssemblyPlace, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.AssemblyPlace);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElementAssemblyType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcElementAssemblyTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcElementAssemblyTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementAssemblyType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcElementAssemblyType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcElementComponent {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementComponent
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcElementComponent(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcElementComponentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementComponentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcElementComponentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcElementQuantity {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, MethodOfMeasurement: IfcLabel | null, Quantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.MethodOfMeasurement = MethodOfMeasurement;
		this.Quantities = Quantities;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	MethodOfMeasurement: IfcLabel | null;
	Quantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementQuantity
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let MethodOfMeasurement = tape[ptr++];
		let Quantities = tape[ptr++];
		return new IfcElementQuantity(expressID, type, GlobalId, OwnerHistory, Name, Description, MethodOfMeasurement, Quantities);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.MethodOfMeasurement);;
		args.push(this.Quantities);;
		return args;
	}
};
export class IfcElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcElementarySurface {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcElementarySurface
	{
		let ptr = 0;
		let Position = tape[ptr++];
		return new IfcElementarySurface(expressID, type, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		return args;
	}
};
export class IfcEllipse {
	constructor(expressID: number, type: number, Position: IfcAxis2Placement , SemiAxis1: IfcPositiveLengthMeasure , SemiAxis2: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.SemiAxis1 = SemiAxis1;
		this.SemiAxis2 = SemiAxis2;
	}
	expressID: number;
	type: number;
	Position: IfcAxis2Placement ;
	SemiAxis1: IfcPositiveLengthMeasure ;
	SemiAxis2: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEllipse
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let SemiAxis1 = tape[ptr++];
		let SemiAxis2 = tape[ptr++];
		return new IfcEllipse(expressID, type, Position, SemiAxis1, SemiAxis2);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.SemiAxis1);;
		args.push(this.SemiAxis2);;
		return args;
	}
};
export class IfcEllipseProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, SemiAxis1: IfcPositiveLengthMeasure , SemiAxis2: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.SemiAxis1 = SemiAxis1;
		this.SemiAxis2 = SemiAxis2;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	SemiAxis1: IfcPositiveLengthMeasure ;
	SemiAxis2: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEllipseProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let SemiAxis1 = tape[ptr++];
		let SemiAxis2 = tape[ptr++];
		return new IfcEllipseProfileDef(expressID, type, ProfileType, ProfileName, Position, SemiAxis1, SemiAxis2);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.SemiAxis1);;
		args.push(this.SemiAxis2);;
		return args;
	}
};
export class IfcEnergyConversionDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEnergyConversionDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcEnergyConversionDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcEnergyConversionDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEnergyConversionDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcEnergyConversionDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcEngine {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcEngineTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcEngineTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEngine
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEngine(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEngineType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcEngineTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcEngineTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEngineType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEngineType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEvaporativeCooler {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcEvaporativeCoolerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcEvaporativeCoolerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEvaporativeCooler
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEvaporativeCooler(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEvaporativeCoolerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcEvaporativeCoolerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcEvaporativeCoolerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEvaporativeCoolerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEvaporativeCoolerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEvaporator {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcEvaporatorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcEvaporatorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEvaporator
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEvaporator(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEvaporatorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcEvaporatorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcEvaporatorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEvaporatorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcEvaporatorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcEvent {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, PredefinedType: IfcEventTypeEnum | null, EventTriggerType: IfcEventTriggerTypeEnum | null, UserDefinedEventTriggerType: IfcLabel | null, EventOccurenceTime: (Handle<IfcEventTime> | IfcEventTime) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.PredefinedType = PredefinedType;
		this.EventTriggerType = EventTriggerType;
		this.UserDefinedEventTriggerType = UserDefinedEventTriggerType;
		this.EventOccurenceTime = EventOccurenceTime;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	PredefinedType: IfcEventTypeEnum | null;
	EventTriggerType: IfcEventTriggerTypeEnum | null;
	UserDefinedEventTriggerType: IfcLabel | null;
	EventOccurenceTime: (Handle<IfcEventTime> | IfcEventTime) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEvent
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let EventTriggerType = tape[ptr++];
		let UserDefinedEventTriggerType = tape[ptr++];
		let EventOccurenceTime = tape[ptr++];
		return new IfcEvent(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, PredefinedType, EventTriggerType, UserDefinedEventTriggerType, EventOccurenceTime);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.PredefinedType);;
		args.push(this.EventTriggerType);;
		args.push(this.UserDefinedEventTriggerType);;
		args.push(this.EventOccurenceTime);;
		return args;
	}
};
export class IfcEventTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, ActualDate: IfcDateTime | null, EarlyDate: IfcDateTime | null, LateDate: IfcDateTime | null, ScheduleDate: IfcDateTime | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.ActualDate = ActualDate;
		this.EarlyDate = EarlyDate;
		this.LateDate = LateDate;
		this.ScheduleDate = ScheduleDate;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	ActualDate: IfcDateTime | null;
	EarlyDate: IfcDateTime | null;
	LateDate: IfcDateTime | null;
	ScheduleDate: IfcDateTime | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEventTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let ActualDate = tape[ptr++];
		let EarlyDate = tape[ptr++];
		let LateDate = tape[ptr++];
		let ScheduleDate = tape[ptr++];
		return new IfcEventTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, ActualDate, EarlyDate, LateDate, ScheduleDate);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.ActualDate);;
		args.push(this.EarlyDate);;
		args.push(this.LateDate);;
		args.push(this.ScheduleDate);;
		return args;
	}
};
export class IfcEventType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ProcessType: IfcLabel | null, PredefinedType: IfcEventTypeEnum , EventTriggerType: IfcEventTriggerTypeEnum , UserDefinedEventTriggerType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ProcessType = ProcessType;
		this.PredefinedType = PredefinedType;
		this.EventTriggerType = EventTriggerType;
		this.UserDefinedEventTriggerType = UserDefinedEventTriggerType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ProcessType: IfcLabel | null;
	PredefinedType: IfcEventTypeEnum ;
	EventTriggerType: IfcEventTriggerTypeEnum ;
	UserDefinedEventTriggerType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcEventType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ProcessType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let EventTriggerType = tape[ptr++];
		let UserDefinedEventTriggerType = tape[ptr++];
		return new IfcEventType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ProcessType, PredefinedType, EventTriggerType, UserDefinedEventTriggerType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ProcessType);;
		args.push(this.PredefinedType);;
		args.push(this.EventTriggerType);;
		args.push(this.UserDefinedEventTriggerType);;
		return args;
	}
};
export class IfcExtendedProperties {
	constructor(expressID: number, type: number, Name: IfcIdentifier | null, Description: IfcText | null, Properties: (Handle<IfcProperty> | IfcProperty)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Properties = Properties;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier | null;
	Description: IfcText | null;
	Properties: (Handle<IfcProperty> | IfcProperty)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExtendedProperties
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Properties = tape[ptr++];
		return new IfcExtendedProperties(expressID, type, Name, Description, Properties);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Properties);;
		return args;
	}
};
export class IfcExternalInformation {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternalInformation
	{
		let ptr = 0;
		return new IfcExternalInformation(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcExternalReference {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternalReference
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcExternalReference(expressID, type, Location, Identification, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcExternalReferenceRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingReference: (Handle<IfcExternalReference> | IfcExternalReference) , RelatedResourceObjects: IfcResourceObjectSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingReference = RelatingReference;
		this.RelatedResourceObjects = RelatedResourceObjects;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingReference: (Handle<IfcExternalReference> | IfcExternalReference) ;
	RelatedResourceObjects: IfcResourceObjectSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternalReferenceRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingReference = tape[ptr++];
		let RelatedResourceObjects = tape[ptr++];
		return new IfcExternalReferenceRelationship(expressID, type, Name, Description, RelatingReference, RelatedResourceObjects);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingReference);;
		args.push(this.RelatedResourceObjects);;
		return args;
	}
};
export class IfcExternalSpatialElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, PredefinedType: IfcExternalSpatialElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	PredefinedType: IfcExternalSpatialElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternalSpatialElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcExternalSpatialElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcExternalSpatialStructureElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternalSpatialStructureElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcExternalSpatialStructureElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		return args;
	}
};
export class IfcExternallyDefinedHatchStyle {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternallyDefinedHatchStyle
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcExternallyDefinedHatchStyle(expressID, type, Location, Identification, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcExternallyDefinedSurfaceStyle {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternallyDefinedSurfaceStyle
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcExternallyDefinedSurfaceStyle(expressID, type, Location, Identification, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcExternallyDefinedTextFont {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExternallyDefinedTextFont
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcExternallyDefinedTextFont(expressID, type, Location, Identification, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcExtrudedAreaSolid {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) , Depth: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.ExtrudedDirection = ExtrudedDirection;
		this.Depth = Depth;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) ;
	Depth: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExtrudedAreaSolid
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let ExtrudedDirection = tape[ptr++];
		let Depth = tape[ptr++];
		return new IfcExtrudedAreaSolid(expressID, type, SweptArea, Position, ExtrudedDirection, Depth);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.ExtrudedDirection);;
		args.push(this.Depth);;
		return args;
	}
};
export class IfcExtrudedAreaSolidTapered {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) , Depth: IfcPositiveLengthMeasure , EndSweptArea: (Handle<IfcProfileDef> | IfcProfileDef) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.ExtrudedDirection = ExtrudedDirection;
		this.Depth = Depth;
		this.EndSweptArea = EndSweptArea;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) ;
	Depth: IfcPositiveLengthMeasure ;
	EndSweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcExtrudedAreaSolidTapered
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let ExtrudedDirection = tape[ptr++];
		let Depth = tape[ptr++];
		let EndSweptArea = tape[ptr++];
		return new IfcExtrudedAreaSolidTapered(expressID, type, SweptArea, Position, ExtrudedDirection, Depth, EndSweptArea);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.ExtrudedDirection);;
		args.push(this.Depth);;
		args.push(this.EndSweptArea);;
		return args;
	}
};
export class IfcFace {
	constructor(expressID: number, type: number, Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Bounds = Bounds;
	}
	expressID: number;
	type: number;
	Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFace
	{
		let ptr = 0;
		let Bounds = tape[ptr++];
		return new IfcFace(expressID, type, Bounds);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Bounds);;
		return args;
	}
};
export class IfcFaceBasedSurfaceModel {
	constructor(expressID: number, type: number, FbsmFaces: (Handle<IfcConnectedFaceSet> | IfcConnectedFaceSet)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.FbsmFaces = FbsmFaces;
	}
	expressID: number;
	type: number;
	FbsmFaces: (Handle<IfcConnectedFaceSet> | IfcConnectedFaceSet)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFaceBasedSurfaceModel
	{
		let ptr = 0;
		let FbsmFaces = tape[ptr++];
		return new IfcFaceBasedSurfaceModel(expressID, type, FbsmFaces);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.FbsmFaces);;
		return args;
	}
};
export class IfcFaceBound {
	constructor(expressID: number, type: number, Bound: (Handle<IfcLoop> | IfcLoop) , Orientation: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.Bound = Bound;
		this.Orientation = Orientation;
	}
	expressID: number;
	type: number;
	Bound: (Handle<IfcLoop> | IfcLoop) ;
	Orientation: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFaceBound
	{
		let ptr = 0;
		let Bound = tape[ptr++];
		let Orientation = tape[ptr++];
		return new IfcFaceBound(expressID, type, Bound, Orientation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Bound);;
		args.push(this.Orientation);;
		return args;
	}
};
export class IfcFaceOuterBound {
	constructor(expressID: number, type: number, Bound: (Handle<IfcLoop> | IfcLoop) , Orientation: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.Bound = Bound;
		this.Orientation = Orientation;
	}
	expressID: number;
	type: number;
	Bound: (Handle<IfcLoop> | IfcLoop) ;
	Orientation: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFaceOuterBound
	{
		let ptr = 0;
		let Bound = tape[ptr++];
		let Orientation = tape[ptr++];
		return new IfcFaceOuterBound(expressID, type, Bound, Orientation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Bound);;
		args.push(this.Orientation);;
		return args;
	}
};
export class IfcFaceSurface {
	constructor(expressID: number, type: number, Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] , FaceSurface: (Handle<IfcSurface> | IfcSurface) , SameSense: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.Bounds = Bounds;
		this.FaceSurface = FaceSurface;
		this.SameSense = SameSense;
	}
	expressID: number;
	type: number;
	Bounds: (Handle<IfcFaceBound> | IfcFaceBound)[] ;
	FaceSurface: (Handle<IfcSurface> | IfcSurface) ;
	SameSense: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFaceSurface
	{
		let ptr = 0;
		let Bounds = tape[ptr++];
		let FaceSurface = tape[ptr++];
		let SameSense = tape[ptr++];
		return new IfcFaceSurface(expressID, type, Bounds, FaceSurface, SameSense);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Bounds);;
		args.push(this.FaceSurface);;
		args.push(this.SameSense);;
		return args;
	}
};
export class IfcFacetedBrep {
	constructor(expressID: number, type: number, Outer: (Handle<IfcClosedShell> | IfcClosedShell) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Outer = Outer;
	}
	expressID: number;
	type: number;
	Outer: (Handle<IfcClosedShell> | IfcClosedShell) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFacetedBrep
	{
		let ptr = 0;
		let Outer = tape[ptr++];
		return new IfcFacetedBrep(expressID, type, Outer);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Outer);;
		return args;
	}
};
export class IfcFacetedBrepWithVoids {
	constructor(expressID: number, type: number, Outer: (Handle<IfcClosedShell> | IfcClosedShell) , Voids: (Handle<IfcClosedShell> | IfcClosedShell)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Outer = Outer;
		this.Voids = Voids;
	}
	expressID: number;
	type: number;
	Outer: (Handle<IfcClosedShell> | IfcClosedShell) ;
	Voids: (Handle<IfcClosedShell> | IfcClosedShell)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFacetedBrepWithVoids
	{
		let ptr = 0;
		let Outer = tape[ptr++];
		let Voids = tape[ptr++];
		return new IfcFacetedBrepWithVoids(expressID, type, Outer, Voids);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Outer);;
		args.push(this.Voids);;
		return args;
	}
};
export class IfcFacility {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFacility
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		return new IfcFacility(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		return args;
	}
};
export class IfcFacilityPart {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFacilityPart
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		return new IfcFacilityPart(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		return args;
	}
};
export class IfcFailureConnectionCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TensionFailureX: IfcForceMeasure | null, TensionFailureY: IfcForceMeasure | null, TensionFailureZ: IfcForceMeasure | null, CompressionFailureX: IfcForceMeasure | null, CompressionFailureY: IfcForceMeasure | null, CompressionFailureZ: IfcForceMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TensionFailureX = TensionFailureX;
		this.TensionFailureY = TensionFailureY;
		this.TensionFailureZ = TensionFailureZ;
		this.CompressionFailureX = CompressionFailureX;
		this.CompressionFailureY = CompressionFailureY;
		this.CompressionFailureZ = CompressionFailureZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TensionFailureX: IfcForceMeasure | null;
	TensionFailureY: IfcForceMeasure | null;
	TensionFailureZ: IfcForceMeasure | null;
	CompressionFailureX: IfcForceMeasure | null;
	CompressionFailureY: IfcForceMeasure | null;
	CompressionFailureZ: IfcForceMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFailureConnectionCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TensionFailureX = tape[ptr++];
		let TensionFailureY = tape[ptr++];
		let TensionFailureZ = tape[ptr++];
		let CompressionFailureX = tape[ptr++];
		let CompressionFailureY = tape[ptr++];
		let CompressionFailureZ = tape[ptr++];
		return new IfcFailureConnectionCondition(expressID, type, Name, TensionFailureX, TensionFailureY, TensionFailureZ, CompressionFailureX, CompressionFailureY, CompressionFailureZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TensionFailureX);;
		args.push(this.TensionFailureY);;
		args.push(this.TensionFailureZ);;
		args.push(this.CompressionFailureX);;
		args.push(this.CompressionFailureY);;
		args.push(this.CompressionFailureZ);;
		return args;
	}
};
export class IfcFan {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFanTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFanTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFan
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFan(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFanType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFanTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFanTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFanType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFanType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFastener {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFastenerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFastenerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFastener
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFastener(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFastenerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFastenerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFastenerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFastenerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFastenerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFeatureElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFeatureElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFeatureElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFeatureElementAddition {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFeatureElementAddition
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFeatureElementAddition(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFeatureElementSubtraction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFeatureElementSubtraction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFeatureElementSubtraction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFillAreaStyle {
	constructor(expressID: number, type: number, Name: IfcLabel | null, FillStyles: IfcFillStyleSelect[] , ModelorDraughting: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.FillStyles = FillStyles;
		this.ModelorDraughting = ModelorDraughting;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	FillStyles: IfcFillStyleSelect[] ;
	ModelorDraughting: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFillAreaStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let FillStyles = tape[ptr++];
		let ModelorDraughting = tape[ptr++];
		return new IfcFillAreaStyle(expressID, type, Name, FillStyles, ModelorDraughting);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.FillStyles);;
		args.push(this.ModelorDraughting);;
		return args;
	}
};
export class IfcFillAreaStyleHatching {
	constructor(expressID: number, type: number, HatchLineAppearance: (Handle<IfcCurveStyle> | IfcCurveStyle) , StartOfNextHatchLine: IfcHatchLineDistanceSelect , PointOfReferenceHatchLine: (Handle<IfcCartesianPoint> | IfcCartesianPoint) | null, PatternStart: (Handle<IfcCartesianPoint> | IfcCartesianPoint) | null, HatchLineAngle: IfcPlaneAngleMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.HatchLineAppearance = HatchLineAppearance;
		this.StartOfNextHatchLine = StartOfNextHatchLine;
		this.PointOfReferenceHatchLine = PointOfReferenceHatchLine;
		this.PatternStart = PatternStart;
		this.HatchLineAngle = HatchLineAngle;
	}
	expressID: number;
	type: number;
	HatchLineAppearance: (Handle<IfcCurveStyle> | IfcCurveStyle) ;
	StartOfNextHatchLine: IfcHatchLineDistanceSelect ;
	PointOfReferenceHatchLine: (Handle<IfcCartesianPoint> | IfcCartesianPoint) | null;
	PatternStart: (Handle<IfcCartesianPoint> | IfcCartesianPoint) | null;
	HatchLineAngle: IfcPlaneAngleMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFillAreaStyleHatching
	{
		let ptr = 0;
		let HatchLineAppearance = tape[ptr++];
		let StartOfNextHatchLine = tape[ptr++];
		let PointOfReferenceHatchLine = tape[ptr++];
		let PatternStart = tape[ptr++];
		let HatchLineAngle = tape[ptr++];
		return new IfcFillAreaStyleHatching(expressID, type, HatchLineAppearance, StartOfNextHatchLine, PointOfReferenceHatchLine, PatternStart, HatchLineAngle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.HatchLineAppearance);;
		args.push(this.StartOfNextHatchLine);;
		args.push(this.PointOfReferenceHatchLine);;
		args.push(this.PatternStart);;
		args.push(this.HatchLineAngle);;
		return args;
	}
};
export class IfcFillAreaStyleTiles {
	constructor(expressID: number, type: number, TilingPattern: (Handle<IfcVector> | IfcVector)[] , Tiles: (Handle<IfcStyledItem> | IfcStyledItem)[] , TilingScale: IfcPositiveRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.TilingPattern = TilingPattern;
		this.Tiles = Tiles;
		this.TilingScale = TilingScale;
	}
	expressID: number;
	type: number;
	TilingPattern: (Handle<IfcVector> | IfcVector)[] ;
	Tiles: (Handle<IfcStyledItem> | IfcStyledItem)[] ;
	TilingScale: IfcPositiveRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFillAreaStyleTiles
	{
		let ptr = 0;
		let TilingPattern = tape[ptr++];
		let Tiles = tape[ptr++];
		let TilingScale = tape[ptr++];
		return new IfcFillAreaStyleTiles(expressID, type, TilingPattern, Tiles, TilingScale);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TilingPattern);;
		args.push(this.Tiles);;
		args.push(this.TilingScale);;
		return args;
	}
};
export class IfcFilter {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFilterTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFilterTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFilter
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFilter(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFilterType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFilterTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFilterTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFilterType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFilterType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFireSuppressionTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFireSuppressionTerminalTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFireSuppressionTerminalTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFireSuppressionTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFireSuppressionTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFireSuppressionTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFireSuppressionTerminalTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFireSuppressionTerminalTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFireSuppressionTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFireSuppressionTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFixedReferenceSweptAreaSolid {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, Directrix: (Handle<IfcCurve> | IfcCurve) , StartParam: IfcParameterValue | null, EndParam: IfcParameterValue | null, FixedReference: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.Directrix = Directrix;
		this.StartParam = StartParam;
		this.EndParam = EndParam;
		this.FixedReference = FixedReference;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	StartParam: IfcParameterValue | null;
	EndParam: IfcParameterValue | null;
	FixedReference: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFixedReferenceSweptAreaSolid
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let Directrix = tape[ptr++];
		let StartParam = tape[ptr++];
		let EndParam = tape[ptr++];
		let FixedReference = tape[ptr++];
		return new IfcFixedReferenceSweptAreaSolid(expressID, type, SweptArea, Position, Directrix, StartParam, EndParam, FixedReference);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.Directrix);;
		args.push(this.StartParam);;
		args.push(this.EndParam);;
		args.push(this.FixedReference);;
		return args;
	}
};
export class IfcFlowController {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowController
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowController(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowControllerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowControllerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowControllerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowFitting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowFitting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowFitting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowFittingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowFittingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowFittingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowInstrument {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFlowInstrumentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFlowInstrumentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowInstrument
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFlowInstrument(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFlowInstrumentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFlowInstrumentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFlowInstrumentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowInstrumentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFlowInstrumentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFlowMeter {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFlowMeterTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFlowMeterTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowMeter
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFlowMeter(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFlowMeterType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFlowMeterTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFlowMeterTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowMeterType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFlowMeterType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFlowMovingDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowMovingDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowMovingDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowMovingDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowMovingDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowMovingDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowSegment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowSegment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowSegment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowSegmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowSegmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowSegmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowStorageDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowStorageDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowStorageDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowStorageDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowStorageDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowStorageDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFlowTreatmentDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowTreatmentDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFlowTreatmentDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFlowTreatmentDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFlowTreatmentDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFlowTreatmentDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFooting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFootingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFootingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFooting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFooting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFootingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcFootingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcFootingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFootingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFootingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFurnishingElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFurnishingElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcFurnishingElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcFurnishingElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFurnishingElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcFurnishingElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcFurniture {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcFurnitureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcFurnitureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFurniture
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFurniture(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcFurnitureType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, AssemblyPlace: IfcAssemblyPlaceEnum , PredefinedType: IfcFurnitureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.AssemblyPlace = AssemblyPlace;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	AssemblyPlace: IfcAssemblyPlaceEnum ;
	PredefinedType: IfcFurnitureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcFurnitureType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let AssemblyPlace = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcFurnitureType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, AssemblyPlace, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.AssemblyPlace);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcGeographicElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcGeographicElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcGeographicElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeographicElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcGeographicElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcGeographicElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcGeographicElementTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcGeographicElementTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeographicElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcGeographicElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcGeometricCurveSet {
	constructor(expressID: number, type: number, Elements: IfcGeometricSetSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Elements = Elements;
	}
	expressID: number;
	type: number;
	Elements: IfcGeometricSetSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeometricCurveSet
	{
		let ptr = 0;
		let Elements = tape[ptr++];
		return new IfcGeometricCurveSet(expressID, type, Elements);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Elements);;
		return args;
	}
};
export class IfcGeometricRepresentationContext {
	constructor(expressID: number, type: number, ContextIdentifier: IfcLabel | null, ContextType: IfcLabel | null, CoordinateSpaceDimension: IfcDimensionCount , Precision: IfcReal | null, WorldCoordinateSystem: IfcAxis2Placement , TrueNorth: (Handle<IfcDirection> | IfcDirection) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextIdentifier = ContextIdentifier;
		this.ContextType = ContextType;
		this.CoordinateSpaceDimension = CoordinateSpaceDimension;
		this.Precision = Precision;
		this.WorldCoordinateSystem = WorldCoordinateSystem;
		this.TrueNorth = TrueNorth;
	}
	expressID: number;
	type: number;
	ContextIdentifier: IfcLabel | null;
	ContextType: IfcLabel | null;
	CoordinateSpaceDimension: IfcDimensionCount ;
	Precision: IfcReal | null;
	WorldCoordinateSystem: IfcAxis2Placement ;
	TrueNorth: (Handle<IfcDirection> | IfcDirection) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeometricRepresentationContext
	{
		let ptr = 0;
		let ContextIdentifier = tape[ptr++];
		let ContextType = tape[ptr++];
		let CoordinateSpaceDimension = tape[ptr++];
		let Precision = tape[ptr++];
		let WorldCoordinateSystem = tape[ptr++];
		let TrueNorth = tape[ptr++];
		return new IfcGeometricRepresentationContext(expressID, type, ContextIdentifier, ContextType, CoordinateSpaceDimension, Precision, WorldCoordinateSystem, TrueNorth);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextIdentifier);;
		args.push(this.ContextType);;
		args.push(this.CoordinateSpaceDimension);;
		args.push(this.Precision);;
		args.push(this.WorldCoordinateSystem);;
		args.push(this.TrueNorth);;
		return args;
	}
};
export class IfcGeometricRepresentationItem {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeometricRepresentationItem
	{
		let ptr = 0;
		return new IfcGeometricRepresentationItem(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcGeometricRepresentationSubContext {
	constructor(expressID: number, type: number, ContextIdentifier: IfcLabel | null, ContextType: IfcLabel | null, CoordinateSpaceDimension: IfcDimensionCount , Precision: IfcReal | null, WorldCoordinateSystem: IfcAxis2Placement , TrueNorth: (Handle<IfcDirection> | IfcDirection) | null, ParentContext: (Handle<IfcGeometricRepresentationContext> | IfcGeometricRepresentationContext) , TargetScale: IfcPositiveRatioMeasure | null, TargetView: IfcGeometricProjectionEnum , UserDefinedTargetView: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextIdentifier = ContextIdentifier;
		this.ContextType = ContextType;
		this.CoordinateSpaceDimension = CoordinateSpaceDimension;
		this.Precision = Precision;
		this.WorldCoordinateSystem = WorldCoordinateSystem;
		this.TrueNorth = TrueNorth;
		this.ParentContext = ParentContext;
		this.TargetScale = TargetScale;
		this.TargetView = TargetView;
		this.UserDefinedTargetView = UserDefinedTargetView;
	}
	expressID: number;
	type: number;
	ContextIdentifier: IfcLabel | null;
	ContextType: IfcLabel | null;
	CoordinateSpaceDimension: IfcDimensionCount ;
	Precision: IfcReal | null;
	WorldCoordinateSystem: IfcAxis2Placement ;
	TrueNorth: (Handle<IfcDirection> | IfcDirection) | null;
	ParentContext: (Handle<IfcGeometricRepresentationContext> | IfcGeometricRepresentationContext) ;
	TargetScale: IfcPositiveRatioMeasure | null;
	TargetView: IfcGeometricProjectionEnum ;
	UserDefinedTargetView: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeometricRepresentationSubContext
	{
		let ptr = 0;
		let ContextIdentifier = tape[ptr++];
		let ContextType = tape[ptr++];
		let CoordinateSpaceDimension = tape[ptr++];
		let Precision = tape[ptr++];
		let WorldCoordinateSystem = tape[ptr++];
		let TrueNorth = tape[ptr++];
		let ParentContext = tape[ptr++];
		let TargetScale = tape[ptr++];
		let TargetView = tape[ptr++];
		let UserDefinedTargetView = tape[ptr++];
		return new IfcGeometricRepresentationSubContext(expressID, type, ContextIdentifier, ContextType, CoordinateSpaceDimension, Precision, WorldCoordinateSystem, TrueNorth, ParentContext, TargetScale, TargetView, UserDefinedTargetView);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextIdentifier);;
		args.push(this.ContextType);;
		args.push(this.CoordinateSpaceDimension);;
		args.push(this.Precision);;
		args.push(this.WorldCoordinateSystem);;
		args.push(this.TrueNorth);;
		args.push(this.ParentContext);;
		args.push(this.TargetScale);;
		args.push(this.TargetView);;
		args.push(this.UserDefinedTargetView);;
		return args;
	}
};
export class IfcGeometricSet {
	constructor(expressID: number, type: number, Elements: IfcGeometricSetSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Elements = Elements;
	}
	expressID: number;
	type: number;
	Elements: IfcGeometricSetSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGeometricSet
	{
		let ptr = 0;
		let Elements = tape[ptr++];
		return new IfcGeometricSet(expressID, type, Elements);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Elements);;
		return args;
	}
};
export class IfcGrid {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, UAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] , VAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] , WAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] | null, PredefinedType: IfcGridTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.UAxes = UAxes;
		this.VAxes = VAxes;
		this.WAxes = WAxes;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	UAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] ;
	VAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] ;
	WAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] | null;
	PredefinedType: IfcGridTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGrid
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let UAxes = tape[ptr++];
		let VAxes = tape[ptr++];
		let WAxes = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcGrid(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, UAxes, VAxes, WAxes, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.UAxes);;
		args.push(this.VAxes);;
		args.push(this.WAxes);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcGridAxis {
	constructor(expressID: number, type: number, AxisTag: IfcLabel | null, AxisCurve: (Handle<IfcCurve> | IfcCurve) , SameSense: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.AxisTag = AxisTag;
		this.AxisCurve = AxisCurve;
		this.SameSense = SameSense;
	}
	expressID: number;
	type: number;
	AxisTag: IfcLabel | null;
	AxisCurve: (Handle<IfcCurve> | IfcCurve) ;
	SameSense: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGridAxis
	{
		let ptr = 0;
		let AxisTag = tape[ptr++];
		let AxisCurve = tape[ptr++];
		let SameSense = tape[ptr++];
		return new IfcGridAxis(expressID, type, AxisTag, AxisCurve, SameSense);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.AxisTag);;
		args.push(this.AxisCurve);;
		args.push(this.SameSense);;
		return args;
	}
};
export class IfcGridPlacement {
	constructor(expressID: number, type: number, PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, PlacementLocation: (Handle<IfcVirtualGridIntersection> | IfcVirtualGridIntersection) , PlacementRefDirection: IfcGridPlacementDirectionSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.PlacementRelTo = PlacementRelTo;
		this.PlacementLocation = PlacementLocation;
		this.PlacementRefDirection = PlacementRefDirection;
	}
	expressID: number;
	type: number;
	PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	PlacementLocation: (Handle<IfcVirtualGridIntersection> | IfcVirtualGridIntersection) ;
	PlacementRefDirection: IfcGridPlacementDirectionSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGridPlacement
	{
		let ptr = 0;
		let PlacementRelTo = tape[ptr++];
		let PlacementLocation = tape[ptr++];
		let PlacementRefDirection = tape[ptr++];
		return new IfcGridPlacement(expressID, type, PlacementRelTo, PlacementLocation, PlacementRefDirection);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PlacementRelTo);;
		args.push(this.PlacementLocation);;
		args.push(this.PlacementRefDirection);;
		return args;
	}
};
export class IfcGroup {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcGroup
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		return new IfcGroup(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		return args;
	}
};
export class IfcHalfSpaceSolid {
	constructor(expressID: number, type: number, BaseSurface: (Handle<IfcSurface> | IfcSurface) , AgreementFlag: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.BaseSurface = BaseSurface;
		this.AgreementFlag = AgreementFlag;
	}
	expressID: number;
	type: number;
	BaseSurface: (Handle<IfcSurface> | IfcSurface) ;
	AgreementFlag: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcHalfSpaceSolid
	{
		let ptr = 0;
		let BaseSurface = tape[ptr++];
		let AgreementFlag = tape[ptr++];
		return new IfcHalfSpaceSolid(expressID, type, BaseSurface, AgreementFlag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BaseSurface);;
		args.push(this.AgreementFlag);;
		return args;
	}
};
export class IfcHeatExchanger {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcHeatExchangerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcHeatExchangerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcHeatExchanger
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcHeatExchanger(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcHeatExchangerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcHeatExchangerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcHeatExchangerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcHeatExchangerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcHeatExchangerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcHumidifier {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcHumidifierTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcHumidifierTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcHumidifier
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcHumidifier(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcHumidifierType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcHumidifierTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcHumidifierTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcHumidifierType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcHumidifierType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcIShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, OverallWidth: IfcPositiveLengthMeasure , OverallDepth: IfcPositiveLengthMeasure , WebThickness: IfcPositiveLengthMeasure , FlangeThickness: IfcPositiveLengthMeasure , FilletRadius: IfcNonNegativeLengthMeasure | null, FlangeEdgeRadius: IfcNonNegativeLengthMeasure | null, FlangeSlope: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.OverallWidth = OverallWidth;
		this.OverallDepth = OverallDepth;
		this.WebThickness = WebThickness;
		this.FlangeThickness = FlangeThickness;
		this.FilletRadius = FilletRadius;
		this.FlangeEdgeRadius = FlangeEdgeRadius;
		this.FlangeSlope = FlangeSlope;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	OverallWidth: IfcPositiveLengthMeasure ;
	OverallDepth: IfcPositiveLengthMeasure ;
	WebThickness: IfcPositiveLengthMeasure ;
	FlangeThickness: IfcPositiveLengthMeasure ;
	FilletRadius: IfcNonNegativeLengthMeasure | null;
	FlangeEdgeRadius: IfcNonNegativeLengthMeasure | null;
	FlangeSlope: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let OverallWidth = tape[ptr++];
		let OverallDepth = tape[ptr++];
		let WebThickness = tape[ptr++];
		let FlangeThickness = tape[ptr++];
		let FilletRadius = tape[ptr++];
		let FlangeEdgeRadius = tape[ptr++];
		let FlangeSlope = tape[ptr++];
		return new IfcIShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, OverallWidth, OverallDepth, WebThickness, FlangeThickness, FilletRadius, FlangeEdgeRadius, FlangeSlope);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.OverallWidth);;
		args.push(this.OverallDepth);;
		args.push(this.WebThickness);;
		args.push(this.FlangeThickness);;
		args.push(this.FilletRadius);;
		args.push(this.FlangeEdgeRadius);;
		args.push(this.FlangeSlope);;
		return args;
	}
};
export class IfcImageTexture {
	constructor(expressID: number, type: number, RepeatS: IfcBoolean , RepeatT: IfcBoolean , Mode: IfcIdentifier | null, TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null, Parameter: IfcIdentifier[] | null, URLReference: IfcURIReference )
	{
		this.expressID = expressID;
		this.type = type;
		this.RepeatS = RepeatS;
		this.RepeatT = RepeatT;
		this.Mode = Mode;
		this.TextureTransform = TextureTransform;
		this.Parameter = Parameter;
		this.URLReference = URLReference;
	}
	expressID: number;
	type: number;
	RepeatS: IfcBoolean ;
	RepeatT: IfcBoolean ;
	Mode: IfcIdentifier | null;
	TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null;
	Parameter: IfcIdentifier[] | null;
	URLReference: IfcURIReference ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcImageTexture
	{
		let ptr = 0;
		let RepeatS = tape[ptr++];
		let RepeatT = tape[ptr++];
		let Mode = tape[ptr++];
		let TextureTransform = tape[ptr++];
		let Parameter = tape[ptr++];
		let URLReference = tape[ptr++];
		return new IfcImageTexture(expressID, type, RepeatS, RepeatT, Mode, TextureTransform, Parameter, URLReference);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RepeatS);;
		args.push(this.RepeatT);;
		args.push(this.Mode);;
		args.push(this.TextureTransform);;
		args.push(this.Parameter);;
		args.push(this.URLReference);;
		return args;
	}
};
export class IfcIndexedColourMap {
	constructor(expressID: number, type: number, MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) , Opacity: IfcNormalisedRatioMeasure | null, Colours: (Handle<IfcColourRgbList> | IfcColourRgbList) , ColourIndex: IfcPositiveInteger[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.MappedTo = MappedTo;
		this.Opacity = Opacity;
		this.Colours = Colours;
		this.ColourIndex = ColourIndex;
	}
	expressID: number;
	type: number;
	MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) ;
	Opacity: IfcNormalisedRatioMeasure | null;
	Colours: (Handle<IfcColourRgbList> | IfcColourRgbList) ;
	ColourIndex: IfcPositiveInteger[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedColourMap
	{
		let ptr = 0;
		let MappedTo = tape[ptr++];
		let Opacity = tape[ptr++];
		let Colours = tape[ptr++];
		let ColourIndex = tape[ptr++];
		return new IfcIndexedColourMap(expressID, type, MappedTo, Opacity, Colours, ColourIndex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MappedTo);;
		args.push(this.Opacity);;
		args.push(this.Colours);;
		args.push(this.ColourIndex);;
		return args;
	}
};
export class IfcIndexedPolyCurve {
	constructor(expressID: number, type: number, Points: (Handle<IfcCartesianPointList> | IfcCartesianPointList) , Segments: IfcSegmentIndexSelect[] | null, SelfIntersect: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Points = Points;
		this.Segments = Segments;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Points: (Handle<IfcCartesianPointList> | IfcCartesianPointList) ;
	Segments: IfcSegmentIndexSelect[] | null;
	SelfIntersect: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedPolyCurve
	{
		let ptr = 0;
		let Points = tape[ptr++];
		let Segments = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcIndexedPolyCurve(expressID, type, Points, Segments, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Points);;
		args.push(this.Segments);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcIndexedPolygonalFace {
	constructor(expressID: number, type: number, CoordIndex: IfcPositiveInteger[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.CoordIndex = CoordIndex;
	}
	expressID: number;
	type: number;
	CoordIndex: IfcPositiveInteger[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedPolygonalFace
	{
		let ptr = 0;
		let CoordIndex = tape[ptr++];
		return new IfcIndexedPolygonalFace(expressID, type, CoordIndex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CoordIndex);;
		return args;
	}
};
export class IfcIndexedPolygonalFaceWithVoids {
	constructor(expressID: number, type: number, CoordIndex: IfcPositiveInteger[] , InnerCoordIndices: IfcPositiveInteger[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.CoordIndex = CoordIndex;
		this.InnerCoordIndices = InnerCoordIndices;
	}
	expressID: number;
	type: number;
	CoordIndex: IfcPositiveInteger[] ;
	InnerCoordIndices: IfcPositiveInteger[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedPolygonalFaceWithVoids
	{
		let ptr = 0;
		let CoordIndex = tape[ptr++];
		let InnerCoordIndices = tape[ptr++];
		return new IfcIndexedPolygonalFaceWithVoids(expressID, type, CoordIndex, InnerCoordIndices);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CoordIndex);;
		args.push(this.InnerCoordIndices);;
		return args;
	}
};
export class IfcIndexedTextureMap {
	constructor(expressID: number, type: number, Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] , MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) , TexCoords: (Handle<IfcTextureVertexList> | IfcTextureVertexList) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Maps = Maps;
		this.MappedTo = MappedTo;
		this.TexCoords = TexCoords;
	}
	expressID: number;
	type: number;
	Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) ;
	TexCoords: (Handle<IfcTextureVertexList> | IfcTextureVertexList) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedTextureMap
	{
		let ptr = 0;
		let Maps = tape[ptr++];
		let MappedTo = tape[ptr++];
		let TexCoords = tape[ptr++];
		return new IfcIndexedTextureMap(expressID, type, Maps, MappedTo, TexCoords);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Maps);;
		args.push(this.MappedTo);;
		args.push(this.TexCoords);;
		return args;
	}
};
export class IfcIndexedTriangleTextureMap {
	constructor(expressID: number, type: number, Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] , MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) , TexCoords: (Handle<IfcTextureVertexList> | IfcTextureVertexList) , TexCoordIndex: IfcPositiveInteger[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Maps = Maps;
		this.MappedTo = MappedTo;
		this.TexCoords = TexCoords;
		this.TexCoordIndex = TexCoordIndex;
	}
	expressID: number;
	type: number;
	Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	MappedTo: (Handle<IfcTessellatedFaceSet> | IfcTessellatedFaceSet) ;
	TexCoords: (Handle<IfcTextureVertexList> | IfcTextureVertexList) ;
	TexCoordIndex: IfcPositiveInteger[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIndexedTriangleTextureMap
	{
		let ptr = 0;
		let Maps = tape[ptr++];
		let MappedTo = tape[ptr++];
		let TexCoords = tape[ptr++];
		let TexCoordIndex = tape[ptr++];
		return new IfcIndexedTriangleTextureMap(expressID, type, Maps, MappedTo, TexCoords, TexCoordIndex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Maps);;
		args.push(this.MappedTo);;
		args.push(this.TexCoords);;
		args.push(this.TexCoordIndex);;
		return args;
	}
};
export class IfcInterceptor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcInterceptorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcInterceptorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcInterceptor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcInterceptor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcInterceptorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcInterceptorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcInterceptorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcInterceptorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcInterceptorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcIntersectionCurve {
	constructor(expressID: number, type: number, Curve3D: (Handle<IfcCurve> | IfcCurve) , AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] , MasterRepresentation: IfcPreferredSurfaceCurveRepresentation )
	{
		this.expressID = expressID;
		this.type = type;
		this.Curve3D = Curve3D;
		this.AssociatedGeometry = AssociatedGeometry;
		this.MasterRepresentation = MasterRepresentation;
	}
	expressID: number;
	type: number;
	Curve3D: (Handle<IfcCurve> | IfcCurve) ;
	AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] ;
	MasterRepresentation: IfcPreferredSurfaceCurveRepresentation ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIntersectionCurve
	{
		let ptr = 0;
		let Curve3D = tape[ptr++];
		let AssociatedGeometry = tape[ptr++];
		let MasterRepresentation = tape[ptr++];
		return new IfcIntersectionCurve(expressID, type, Curve3D, AssociatedGeometry, MasterRepresentation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Curve3D);;
		args.push(this.AssociatedGeometry);;
		args.push(this.MasterRepresentation);;
		return args;
	}
};
export class IfcInventory {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, PredefinedType: IfcInventoryTypeEnum | null, Jurisdiction: IfcActorSelect | null, ResponsiblePersons: (Handle<IfcPerson> | IfcPerson)[] | null, LastUpdateDate: IfcDate | null, CurrentValue: (Handle<IfcCostValue> | IfcCostValue) | null, OriginalValue: (Handle<IfcCostValue> | IfcCostValue) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.PredefinedType = PredefinedType;
		this.Jurisdiction = Jurisdiction;
		this.ResponsiblePersons = ResponsiblePersons;
		this.LastUpdateDate = LastUpdateDate;
		this.CurrentValue = CurrentValue;
		this.OriginalValue = OriginalValue;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	PredefinedType: IfcInventoryTypeEnum | null;
	Jurisdiction: IfcActorSelect | null;
	ResponsiblePersons: (Handle<IfcPerson> | IfcPerson)[] | null;
	LastUpdateDate: IfcDate | null;
	CurrentValue: (Handle<IfcCostValue> | IfcCostValue) | null;
	OriginalValue: (Handle<IfcCostValue> | IfcCostValue) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcInventory
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Jurisdiction = tape[ptr++];
		let ResponsiblePersons = tape[ptr++];
		let LastUpdateDate = tape[ptr++];
		let CurrentValue = tape[ptr++];
		let OriginalValue = tape[ptr++];
		return new IfcInventory(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, PredefinedType, Jurisdiction, ResponsiblePersons, LastUpdateDate, CurrentValue, OriginalValue);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.PredefinedType);;
		args.push(this.Jurisdiction);;
		args.push(this.ResponsiblePersons);;
		args.push(this.LastUpdateDate);;
		args.push(this.CurrentValue);;
		args.push(this.OriginalValue);;
		return args;
	}
};
export class IfcIrregularTimeSeries {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, StartTime: IfcDateTime , EndTime: IfcDateTime , TimeSeriesDataType: IfcTimeSeriesDataTypeEnum , DataOrigin: IfcDataOriginEnum , UserDefinedDataOrigin: IfcLabel | null, Unit: IfcUnit | null, Values: (Handle<IfcIrregularTimeSeriesValue> | IfcIrregularTimeSeriesValue)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.StartTime = StartTime;
		this.EndTime = EndTime;
		this.TimeSeriesDataType = TimeSeriesDataType;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.Unit = Unit;
		this.Values = Values;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	StartTime: IfcDateTime ;
	EndTime: IfcDateTime ;
	TimeSeriesDataType: IfcTimeSeriesDataTypeEnum ;
	DataOrigin: IfcDataOriginEnum ;
	UserDefinedDataOrigin: IfcLabel | null;
	Unit: IfcUnit | null;
	Values: (Handle<IfcIrregularTimeSeriesValue> | IfcIrregularTimeSeriesValue)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIrregularTimeSeries
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let StartTime = tape[ptr++];
		let EndTime = tape[ptr++];
		let TimeSeriesDataType = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let Unit = tape[ptr++];
		let Values = tape[ptr++];
		return new IfcIrregularTimeSeries(expressID, type, Name, Description, StartTime, EndTime, TimeSeriesDataType, DataOrigin, UserDefinedDataOrigin, Unit, Values);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.StartTime);;
		args.push(this.EndTime);;
		args.push(this.TimeSeriesDataType);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.Unit);;
		args.push(this.Values);;
		return args;
	}
};
export class IfcIrregularTimeSeriesValue {
	constructor(expressID: number, type: number, TimeStamp: IfcDateTime , ListValues: IfcValue[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.TimeStamp = TimeStamp;
		this.ListValues = ListValues;
	}
	expressID: number;
	type: number;
	TimeStamp: IfcDateTime ;
	ListValues: IfcValue[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcIrregularTimeSeriesValue
	{
		let ptr = 0;
		let TimeStamp = tape[ptr++];
		let ListValues = tape[ptr++];
		return new IfcIrregularTimeSeriesValue(expressID, type, TimeStamp, ListValues);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TimeStamp);;
		args.push(this.ListValues);;
		return args;
	}
};
export class IfcJunctionBox {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcJunctionBoxTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcJunctionBoxTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcJunctionBox
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcJunctionBox(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcJunctionBoxType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcJunctionBoxTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcJunctionBoxTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcJunctionBoxType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcJunctionBoxType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Depth: IfcPositiveLengthMeasure , Width: IfcPositiveLengthMeasure | null, Thickness: IfcPositiveLengthMeasure , FilletRadius: IfcNonNegativeLengthMeasure | null, EdgeRadius: IfcNonNegativeLengthMeasure | null, LegSlope: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Depth = Depth;
		this.Width = Width;
		this.Thickness = Thickness;
		this.FilletRadius = FilletRadius;
		this.EdgeRadius = EdgeRadius;
		this.LegSlope = LegSlope;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Depth: IfcPositiveLengthMeasure ;
	Width: IfcPositiveLengthMeasure | null;
	Thickness: IfcPositiveLengthMeasure ;
	FilletRadius: IfcNonNegativeLengthMeasure | null;
	EdgeRadius: IfcNonNegativeLengthMeasure | null;
	LegSlope: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Depth = tape[ptr++];
		let Width = tape[ptr++];
		let Thickness = tape[ptr++];
		let FilletRadius = tape[ptr++];
		let EdgeRadius = tape[ptr++];
		let LegSlope = tape[ptr++];
		return new IfcLShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, Depth, Width, Thickness, FilletRadius, EdgeRadius, LegSlope);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Depth);;
		args.push(this.Width);;
		args.push(this.Thickness);;
		args.push(this.FilletRadius);;
		args.push(this.EdgeRadius);;
		args.push(this.LegSlope);;
		return args;
	}
};
export class IfcLaborResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcLaborResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcLaborResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLaborResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLaborResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLaborResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcLaborResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcLaborResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLaborResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLaborResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLagTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, LagValue: IfcTimeOrRatioSelect , DurationType: IfcTaskDurationEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.LagValue = LagValue;
		this.DurationType = DurationType;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	LagValue: IfcTimeOrRatioSelect ;
	DurationType: IfcTaskDurationEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLagTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let LagValue = tape[ptr++];
		let DurationType = tape[ptr++];
		return new IfcLagTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, LagValue, DurationType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.LagValue);;
		args.push(this.DurationType);;
		return args;
	}
};
export class IfcLamp {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcLampTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcLampTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLamp
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLamp(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLampType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcLampTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcLampTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLampType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLampType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLibraryInformation {
	constructor(expressID: number, type: number, Name: IfcLabel , Version: IfcLabel | null, Publisher: IfcActorSelect | null, VersionDate: IfcDateTime | null, Location: IfcURIReference | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Version = Version;
		this.Publisher = Publisher;
		this.VersionDate = VersionDate;
		this.Location = Location;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Version: IfcLabel | null;
	Publisher: IfcActorSelect | null;
	VersionDate: IfcDateTime | null;
	Location: IfcURIReference | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLibraryInformation
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Version = tape[ptr++];
		let Publisher = tape[ptr++];
		let VersionDate = tape[ptr++];
		let Location = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcLibraryInformation(expressID, type, Name, Version, Publisher, VersionDate, Location, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Version);;
		args.push(this.Publisher);;
		args.push(this.VersionDate);;
		args.push(this.Location);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcLibraryReference {
	constructor(expressID: number, type: number, Location: IfcURIReference | null, Identification: IfcIdentifier | null, Name: IfcLabel | null, Description: IfcText | null, Language: IfcLanguageId | null, ReferencedLibrary: (Handle<IfcLibraryInformation> | IfcLibraryInformation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
		this.Identification = Identification;
		this.Name = Name;
		this.Description = Description;
		this.Language = Language;
		this.ReferencedLibrary = ReferencedLibrary;
	}
	expressID: number;
	type: number;
	Location: IfcURIReference | null;
	Identification: IfcIdentifier | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Language: IfcLanguageId | null;
	ReferencedLibrary: (Handle<IfcLibraryInformation> | IfcLibraryInformation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLibraryReference
	{
		let ptr = 0;
		let Location = tape[ptr++];
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Language = tape[ptr++];
		let ReferencedLibrary = tape[ptr++];
		return new IfcLibraryReference(expressID, type, Location, Identification, Name, Description, Language, ReferencedLibrary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		args.push(this.Identification);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Language);;
		args.push(this.ReferencedLibrary);;
		return args;
	}
};
export class IfcLightDistributionData {
	constructor(expressID: number, type: number, MainPlaneAngle: IfcPlaneAngleMeasure , SecondaryPlaneAngle: IfcPlaneAngleMeasure[] , LuminousIntensity: IfcLuminousIntensityDistributionMeasure[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.MainPlaneAngle = MainPlaneAngle;
		this.SecondaryPlaneAngle = SecondaryPlaneAngle;
		this.LuminousIntensity = LuminousIntensity;
	}
	expressID: number;
	type: number;
	MainPlaneAngle: IfcPlaneAngleMeasure ;
	SecondaryPlaneAngle: IfcPlaneAngleMeasure[] ;
	LuminousIntensity: IfcLuminousIntensityDistributionMeasure[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightDistributionData
	{
		let ptr = 0;
		let MainPlaneAngle = tape[ptr++];
		let SecondaryPlaneAngle = tape[ptr++];
		let LuminousIntensity = tape[ptr++];
		return new IfcLightDistributionData(expressID, type, MainPlaneAngle, SecondaryPlaneAngle, LuminousIntensity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MainPlaneAngle);;
		args.push(this.SecondaryPlaneAngle);;
		args.push(this.LuminousIntensity);;
		return args;
	}
};
export class IfcLightFixture {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcLightFixtureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcLightFixtureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightFixture
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLightFixture(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLightFixtureType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcLightFixtureTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcLightFixtureTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightFixtureType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcLightFixtureType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcLightIntensityDistribution {
	constructor(expressID: number, type: number, LightDistributionCurve: IfcLightDistributionCurveEnum , DistributionData: (Handle<IfcLightDistributionData> | IfcLightDistributionData)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.LightDistributionCurve = LightDistributionCurve;
		this.DistributionData = DistributionData;
	}
	expressID: number;
	type: number;
	LightDistributionCurve: IfcLightDistributionCurveEnum ;
	DistributionData: (Handle<IfcLightDistributionData> | IfcLightDistributionData)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightIntensityDistribution
	{
		let ptr = 0;
		let LightDistributionCurve = tape[ptr++];
		let DistributionData = tape[ptr++];
		return new IfcLightIntensityDistribution(expressID, type, LightDistributionCurve, DistributionData);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.LightDistributionCurve);;
		args.push(this.DistributionData);;
		return args;
	}
};
export class IfcLightSource {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSource
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		return new IfcLightSource(expressID, type, Name, LightColour, AmbientIntensity, Intensity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		return args;
	}
};
export class IfcLightSourceAmbient {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSourceAmbient
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		return new IfcLightSourceAmbient(expressID, type, Name, LightColour, AmbientIntensity, Intensity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		return args;
	}
};
export class IfcLightSourceDirectional {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null, Orientation: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
		this.Orientation = Orientation;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	Orientation: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSourceDirectional
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		let Orientation = tape[ptr++];
		return new IfcLightSourceDirectional(expressID, type, Name, LightColour, AmbientIntensity, Intensity, Orientation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		args.push(this.Orientation);;
		return args;
	}
};
export class IfcLightSourceGoniometric {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , ColourAppearance: (Handle<IfcColourRgb> | IfcColourRgb) | null, ColourTemperature: IfcThermodynamicTemperatureMeasure , LuminousFlux: IfcLuminousFluxMeasure , LightEmissionSource: IfcLightEmissionSourceEnum , LightDistributionDataSource: IfcLightDistributionDataSourceSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
		this.Position = Position;
		this.ColourAppearance = ColourAppearance;
		this.ColourTemperature = ColourTemperature;
		this.LuminousFlux = LuminousFlux;
		this.LightEmissionSource = LightEmissionSource;
		this.LightDistributionDataSource = LightDistributionDataSource;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	ColourAppearance: (Handle<IfcColourRgb> | IfcColourRgb) | null;
	ColourTemperature: IfcThermodynamicTemperatureMeasure ;
	LuminousFlux: IfcLuminousFluxMeasure ;
	LightEmissionSource: IfcLightEmissionSourceEnum ;
	LightDistributionDataSource: IfcLightDistributionDataSourceSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSourceGoniometric
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		let Position = tape[ptr++];
		let ColourAppearance = tape[ptr++];
		let ColourTemperature = tape[ptr++];
		let LuminousFlux = tape[ptr++];
		let LightEmissionSource = tape[ptr++];
		let LightDistributionDataSource = tape[ptr++];
		return new IfcLightSourceGoniometric(expressID, type, Name, LightColour, AmbientIntensity, Intensity, Position, ColourAppearance, ColourTemperature, LuminousFlux, LightEmissionSource, LightDistributionDataSource);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		args.push(this.Position);;
		args.push(this.ColourAppearance);;
		args.push(this.ColourTemperature);;
		args.push(this.LuminousFlux);;
		args.push(this.LightEmissionSource);;
		args.push(this.LightDistributionDataSource);;
		return args;
	}
};
export class IfcLightSourcePositional {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null, Position: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Radius: IfcPositiveLengthMeasure , ConstantAttenuation: IfcReal , DistanceAttenuation: IfcReal , QuadricAttenuation: IfcReal )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
		this.Position = Position;
		this.Radius = Radius;
		this.ConstantAttenuation = ConstantAttenuation;
		this.DistanceAttenuation = DistanceAttenuation;
		this.QuadricAttenuation = QuadricAttenuation;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	Position: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Radius: IfcPositiveLengthMeasure ;
	ConstantAttenuation: IfcReal ;
	DistanceAttenuation: IfcReal ;
	QuadricAttenuation: IfcReal ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSourcePositional
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		let ConstantAttenuation = tape[ptr++];
		let DistanceAttenuation = tape[ptr++];
		let QuadricAttenuation = tape[ptr++];
		return new IfcLightSourcePositional(expressID, type, Name, LightColour, AmbientIntensity, Intensity, Position, Radius, ConstantAttenuation, DistanceAttenuation, QuadricAttenuation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		args.push(this.Position);;
		args.push(this.Radius);;
		args.push(this.ConstantAttenuation);;
		args.push(this.DistanceAttenuation);;
		args.push(this.QuadricAttenuation);;
		return args;
	}
};
export class IfcLightSourceSpot {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LightColour: (Handle<IfcColourRgb> | IfcColourRgb) , AmbientIntensity: IfcNormalisedRatioMeasure | null, Intensity: IfcNormalisedRatioMeasure | null, Position: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Radius: IfcPositiveLengthMeasure , ConstantAttenuation: IfcReal , DistanceAttenuation: IfcReal , QuadricAttenuation: IfcReal , Orientation: (Handle<IfcDirection> | IfcDirection) , ConcentrationExponent: IfcReal | null, SpreadAngle: IfcPositivePlaneAngleMeasure , BeamWidthAngle: IfcPositivePlaneAngleMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LightColour = LightColour;
		this.AmbientIntensity = AmbientIntensity;
		this.Intensity = Intensity;
		this.Position = Position;
		this.Radius = Radius;
		this.ConstantAttenuation = ConstantAttenuation;
		this.DistanceAttenuation = DistanceAttenuation;
		this.QuadricAttenuation = QuadricAttenuation;
		this.Orientation = Orientation;
		this.ConcentrationExponent = ConcentrationExponent;
		this.SpreadAngle = SpreadAngle;
		this.BeamWidthAngle = BeamWidthAngle;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LightColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	AmbientIntensity: IfcNormalisedRatioMeasure | null;
	Intensity: IfcNormalisedRatioMeasure | null;
	Position: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Radius: IfcPositiveLengthMeasure ;
	ConstantAttenuation: IfcReal ;
	DistanceAttenuation: IfcReal ;
	QuadricAttenuation: IfcReal ;
	Orientation: (Handle<IfcDirection> | IfcDirection) ;
	ConcentrationExponent: IfcReal | null;
	SpreadAngle: IfcPositivePlaneAngleMeasure ;
	BeamWidthAngle: IfcPositivePlaneAngleMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLightSourceSpot
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LightColour = tape[ptr++];
		let AmbientIntensity = tape[ptr++];
		let Intensity = tape[ptr++];
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		let ConstantAttenuation = tape[ptr++];
		let DistanceAttenuation = tape[ptr++];
		let QuadricAttenuation = tape[ptr++];
		let Orientation = tape[ptr++];
		let ConcentrationExponent = tape[ptr++];
		let SpreadAngle = tape[ptr++];
		let BeamWidthAngle = tape[ptr++];
		return new IfcLightSourceSpot(expressID, type, Name, LightColour, AmbientIntensity, Intensity, Position, Radius, ConstantAttenuation, DistanceAttenuation, QuadricAttenuation, Orientation, ConcentrationExponent, SpreadAngle, BeamWidthAngle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LightColour);;
		args.push(this.AmbientIntensity);;
		args.push(this.Intensity);;
		args.push(this.Position);;
		args.push(this.Radius);;
		args.push(this.ConstantAttenuation);;
		args.push(this.DistanceAttenuation);;
		args.push(this.QuadricAttenuation);;
		args.push(this.Orientation);;
		args.push(this.ConcentrationExponent);;
		args.push(this.SpreadAngle);;
		args.push(this.BeamWidthAngle);;
		return args;
	}
};
export class IfcLine {
	constructor(expressID: number, type: number, Pnt: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , Dir: (Handle<IfcVector> | IfcVector) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Pnt = Pnt;
		this.Dir = Dir;
	}
	expressID: number;
	type: number;
	Pnt: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	Dir: (Handle<IfcVector> | IfcVector) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLine
	{
		let ptr = 0;
		let Pnt = tape[ptr++];
		let Dir = tape[ptr++];
		return new IfcLine(expressID, type, Pnt, Dir);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Pnt);;
		args.push(this.Dir);;
		return args;
	}
};
export class IfcLineSegment2D {
	constructor(expressID: number, type: number, StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , StartDirection: IfcPlaneAngleMeasure , SegmentLength: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartPoint = StartPoint;
		this.StartDirection = StartDirection;
		this.SegmentLength = SegmentLength;
	}
	expressID: number;
	type: number;
	StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	StartDirection: IfcPlaneAngleMeasure ;
	SegmentLength: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLineSegment2D
	{
		let ptr = 0;
		let StartPoint = tape[ptr++];
		let StartDirection = tape[ptr++];
		let SegmentLength = tape[ptr++];
		return new IfcLineSegment2D(expressID, type, StartPoint, StartDirection, SegmentLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartPoint);;
		args.push(this.StartDirection);;
		args.push(this.SegmentLength);;
		return args;
	}
};
export class IfcLinearPlacement {
	constructor(expressID: number, type: number, PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, PlacementMeasuredAlong: (Handle<IfcCurve> | IfcCurve) , Distance: (Handle<IfcDistanceExpression> | IfcDistanceExpression) , Orientation: (Handle<IfcOrientationExpression> | IfcOrientationExpression) | null, CartesianPosition: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.PlacementRelTo = PlacementRelTo;
		this.PlacementMeasuredAlong = PlacementMeasuredAlong;
		this.Distance = Distance;
		this.Orientation = Orientation;
		this.CartesianPosition = CartesianPosition;
	}
	expressID: number;
	type: number;
	PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	PlacementMeasuredAlong: (Handle<IfcCurve> | IfcCurve) ;
	Distance: (Handle<IfcDistanceExpression> | IfcDistanceExpression) ;
	Orientation: (Handle<IfcOrientationExpression> | IfcOrientationExpression) | null;
	CartesianPosition: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLinearPlacement
	{
		let ptr = 0;
		let PlacementRelTo = tape[ptr++];
		let PlacementMeasuredAlong = tape[ptr++];
		let Distance = tape[ptr++];
		let Orientation = tape[ptr++];
		let CartesianPosition = tape[ptr++];
		return new IfcLinearPlacement(expressID, type, PlacementRelTo, PlacementMeasuredAlong, Distance, Orientation, CartesianPosition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PlacementRelTo);;
		args.push(this.PlacementMeasuredAlong);;
		args.push(this.Distance);;
		args.push(this.Orientation);;
		args.push(this.CartesianPosition);;
		return args;
	}
};
export class IfcLinearPositioningElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Axis: (Handle<IfcCurve> | IfcCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Axis = Axis;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Axis: (Handle<IfcCurve> | IfcCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLinearPositioningElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Axis = tape[ptr++];
		return new IfcLinearPositioningElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Axis);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Axis);;
		return args;
	}
};
export class IfcLocalPlacement {
	constructor(expressID: number, type: number, PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, RelativePlacement: IfcAxis2Placement )
	{
		this.expressID = expressID;
		this.type = type;
		this.PlacementRelTo = PlacementRelTo;
		this.RelativePlacement = RelativePlacement;
	}
	expressID: number;
	type: number;
	PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	RelativePlacement: IfcAxis2Placement ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLocalPlacement
	{
		let ptr = 0;
		let PlacementRelTo = tape[ptr++];
		let RelativePlacement = tape[ptr++];
		return new IfcLocalPlacement(expressID, type, PlacementRelTo, RelativePlacement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PlacementRelTo);;
		args.push(this.RelativePlacement);;
		return args;
	}
};
export class IfcLoop {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcLoop
	{
		let ptr = 0;
		return new IfcLoop(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcManifoldSolidBrep {
	constructor(expressID: number, type: number, Outer: (Handle<IfcClosedShell> | IfcClosedShell) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Outer = Outer;
	}
	expressID: number;
	type: number;
	Outer: (Handle<IfcClosedShell> | IfcClosedShell) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcManifoldSolidBrep
	{
		let ptr = 0;
		let Outer = tape[ptr++];
		return new IfcManifoldSolidBrep(expressID, type, Outer);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Outer);;
		return args;
	}
};
export class IfcMapConversion {
	constructor(expressID: number, type: number, SourceCRS: IfcCoordinateReferenceSystemSelect , TargetCRS: (Handle<IfcCoordinateReferenceSystem> | IfcCoordinateReferenceSystem) , Eastings: IfcLengthMeasure , Northings: IfcLengthMeasure , OrthogonalHeight: IfcLengthMeasure , XAxisAbscissa: IfcReal | null, XAxisOrdinate: IfcReal | null, Scale: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SourceCRS = SourceCRS;
		this.TargetCRS = TargetCRS;
		this.Eastings = Eastings;
		this.Northings = Northings;
		this.OrthogonalHeight = OrthogonalHeight;
		this.XAxisAbscissa = XAxisAbscissa;
		this.XAxisOrdinate = XAxisOrdinate;
		this.Scale = Scale;
	}
	expressID: number;
	type: number;
	SourceCRS: IfcCoordinateReferenceSystemSelect ;
	TargetCRS: (Handle<IfcCoordinateReferenceSystem> | IfcCoordinateReferenceSystem) ;
	Eastings: IfcLengthMeasure ;
	Northings: IfcLengthMeasure ;
	OrthogonalHeight: IfcLengthMeasure ;
	XAxisAbscissa: IfcReal | null;
	XAxisOrdinate: IfcReal | null;
	Scale: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMapConversion
	{
		let ptr = 0;
		let SourceCRS = tape[ptr++];
		let TargetCRS = tape[ptr++];
		let Eastings = tape[ptr++];
		let Northings = tape[ptr++];
		let OrthogonalHeight = tape[ptr++];
		let XAxisAbscissa = tape[ptr++];
		let XAxisOrdinate = tape[ptr++];
		let Scale = tape[ptr++];
		return new IfcMapConversion(expressID, type, SourceCRS, TargetCRS, Eastings, Northings, OrthogonalHeight, XAxisAbscissa, XAxisOrdinate, Scale);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SourceCRS);;
		args.push(this.TargetCRS);;
		args.push(this.Eastings);;
		args.push(this.Northings);;
		args.push(this.OrthogonalHeight);;
		args.push(this.XAxisAbscissa);;
		args.push(this.XAxisOrdinate);;
		args.push(this.Scale);;
		return args;
	}
};
export class IfcMappedItem {
	constructor(expressID: number, type: number, MappingSource: (Handle<IfcRepresentationMap> | IfcRepresentationMap) , MappingTarget: (Handle<IfcCartesianTransformationOperator> | IfcCartesianTransformationOperator) )
	{
		this.expressID = expressID;
		this.type = type;
		this.MappingSource = MappingSource;
		this.MappingTarget = MappingTarget;
	}
	expressID: number;
	type: number;
	MappingSource: (Handle<IfcRepresentationMap> | IfcRepresentationMap) ;
	MappingTarget: (Handle<IfcCartesianTransformationOperator> | IfcCartesianTransformationOperator) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMappedItem
	{
		let ptr = 0;
		let MappingSource = tape[ptr++];
		let MappingTarget = tape[ptr++];
		return new IfcMappedItem(expressID, type, MappingSource, MappingTarget);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MappingSource);;
		args.push(this.MappingTarget);;
		return args;
	}
};
export class IfcMaterial {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Category: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Category = Category;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Category: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterial
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Category = tape[ptr++];
		return new IfcMaterial(expressID, type, Name, Description, Category);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Category);;
		return args;
	}
};
export class IfcMaterialClassificationRelationship {
	constructor(expressID: number, type: number, MaterialClassifications: IfcClassificationSelect[] , ClassifiedMaterial: (Handle<IfcMaterial> | IfcMaterial) )
	{
		this.expressID = expressID;
		this.type = type;
		this.MaterialClassifications = MaterialClassifications;
		this.ClassifiedMaterial = ClassifiedMaterial;
	}
	expressID: number;
	type: number;
	MaterialClassifications: IfcClassificationSelect[] ;
	ClassifiedMaterial: (Handle<IfcMaterial> | IfcMaterial) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialClassificationRelationship
	{
		let ptr = 0;
		let MaterialClassifications = tape[ptr++];
		let ClassifiedMaterial = tape[ptr++];
		return new IfcMaterialClassificationRelationship(expressID, type, MaterialClassifications, ClassifiedMaterial);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MaterialClassifications);;
		args.push(this.ClassifiedMaterial);;
		return args;
	}
};
export class IfcMaterialConstituent {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Material: (Handle<IfcMaterial> | IfcMaterial) , Fraction: IfcNormalisedRatioMeasure | null, Category: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Material = Material;
		this.Fraction = Fraction;
		this.Category = Category;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Material: (Handle<IfcMaterial> | IfcMaterial) ;
	Fraction: IfcNormalisedRatioMeasure | null;
	Category: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialConstituent
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Material = tape[ptr++];
		let Fraction = tape[ptr++];
		let Category = tape[ptr++];
		return new IfcMaterialConstituent(expressID, type, Name, Description, Material, Fraction, Category);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Material);;
		args.push(this.Fraction);;
		args.push(this.Category);;
		return args;
	}
};
export class IfcMaterialConstituentSet {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, MaterialConstituents: (Handle<IfcMaterialConstituent> | IfcMaterialConstituent)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.MaterialConstituents = MaterialConstituents;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	MaterialConstituents: (Handle<IfcMaterialConstituent> | IfcMaterialConstituent)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialConstituentSet
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let MaterialConstituents = tape[ptr++];
		return new IfcMaterialConstituentSet(expressID, type, Name, Description, MaterialConstituents);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.MaterialConstituents);;
		return args;
	}
};
export class IfcMaterialDefinition {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialDefinition
	{
		let ptr = 0;
		return new IfcMaterialDefinition(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcMaterialDefinitionRepresentation {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] , RepresentedMaterial: (Handle<IfcMaterial> | IfcMaterial) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Representations = Representations;
		this.RepresentedMaterial = RepresentedMaterial;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] ;
	RepresentedMaterial: (Handle<IfcMaterial> | IfcMaterial) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialDefinitionRepresentation
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Representations = tape[ptr++];
		let RepresentedMaterial = tape[ptr++];
		return new IfcMaterialDefinitionRepresentation(expressID, type, Name, Description, Representations, RepresentedMaterial);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Representations);;
		args.push(this.RepresentedMaterial);;
		return args;
	}
};
export class IfcMaterialLayer {
	constructor(expressID: number, type: number, Material: (Handle<IfcMaterial> | IfcMaterial) | null, LayerThickness: IfcNonNegativeLengthMeasure , IsVentilated: IfcLogical | null, Name: IfcLabel | null, Description: IfcText | null, Category: IfcLabel | null, Priority: IfcInteger | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Material = Material;
		this.LayerThickness = LayerThickness;
		this.IsVentilated = IsVentilated;
		this.Name = Name;
		this.Description = Description;
		this.Category = Category;
		this.Priority = Priority;
	}
	expressID: number;
	type: number;
	Material: (Handle<IfcMaterial> | IfcMaterial) | null;
	LayerThickness: IfcNonNegativeLengthMeasure ;
	IsVentilated: IfcLogical | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Category: IfcLabel | null;
	Priority: IfcInteger | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialLayer
	{
		let ptr = 0;
		let Material = tape[ptr++];
		let LayerThickness = tape[ptr++];
		let IsVentilated = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Category = tape[ptr++];
		let Priority = tape[ptr++];
		return new IfcMaterialLayer(expressID, type, Material, LayerThickness, IsVentilated, Name, Description, Category, Priority);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Material);;
		args.push(this.LayerThickness);;
		args.push(this.IsVentilated);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Category);;
		args.push(this.Priority);;
		return args;
	}
};
export class IfcMaterialLayerSet {
	constructor(expressID: number, type: number, MaterialLayers: (Handle<IfcMaterialLayer> | IfcMaterialLayer)[] , LayerSetName: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.MaterialLayers = MaterialLayers;
		this.LayerSetName = LayerSetName;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	MaterialLayers: (Handle<IfcMaterialLayer> | IfcMaterialLayer)[] ;
	LayerSetName: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialLayerSet
	{
		let ptr = 0;
		let MaterialLayers = tape[ptr++];
		let LayerSetName = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcMaterialLayerSet(expressID, type, MaterialLayers, LayerSetName, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MaterialLayers);;
		args.push(this.LayerSetName);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcMaterialLayerSetUsage {
	constructor(expressID: number, type: number, ForLayerSet: (Handle<IfcMaterialLayerSet> | IfcMaterialLayerSet) , LayerSetDirection: IfcLayerSetDirectionEnum , DirectionSense: IfcDirectionSenseEnum , OffsetFromReferenceLine: IfcLengthMeasure , ReferenceExtent: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ForLayerSet = ForLayerSet;
		this.LayerSetDirection = LayerSetDirection;
		this.DirectionSense = DirectionSense;
		this.OffsetFromReferenceLine = OffsetFromReferenceLine;
		this.ReferenceExtent = ReferenceExtent;
	}
	expressID: number;
	type: number;
	ForLayerSet: (Handle<IfcMaterialLayerSet> | IfcMaterialLayerSet) ;
	LayerSetDirection: IfcLayerSetDirectionEnum ;
	DirectionSense: IfcDirectionSenseEnum ;
	OffsetFromReferenceLine: IfcLengthMeasure ;
	ReferenceExtent: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialLayerSetUsage
	{
		let ptr = 0;
		let ForLayerSet = tape[ptr++];
		let LayerSetDirection = tape[ptr++];
		let DirectionSense = tape[ptr++];
		let OffsetFromReferenceLine = tape[ptr++];
		let ReferenceExtent = tape[ptr++];
		return new IfcMaterialLayerSetUsage(expressID, type, ForLayerSet, LayerSetDirection, DirectionSense, OffsetFromReferenceLine, ReferenceExtent);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ForLayerSet);;
		args.push(this.LayerSetDirection);;
		args.push(this.DirectionSense);;
		args.push(this.OffsetFromReferenceLine);;
		args.push(this.ReferenceExtent);;
		return args;
	}
};
export class IfcMaterialLayerWithOffsets {
	constructor(expressID: number, type: number, Material: (Handle<IfcMaterial> | IfcMaterial) | null, LayerThickness: IfcNonNegativeLengthMeasure , IsVentilated: IfcLogical | null, Name: IfcLabel | null, Description: IfcText | null, Category: IfcLabel | null, Priority: IfcInteger | null, OffsetDirection: IfcLayerSetDirectionEnum , OffsetValues: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Material = Material;
		this.LayerThickness = LayerThickness;
		this.IsVentilated = IsVentilated;
		this.Name = Name;
		this.Description = Description;
		this.Category = Category;
		this.Priority = Priority;
		this.OffsetDirection = OffsetDirection;
		this.OffsetValues = OffsetValues;
	}
	expressID: number;
	type: number;
	Material: (Handle<IfcMaterial> | IfcMaterial) | null;
	LayerThickness: IfcNonNegativeLengthMeasure ;
	IsVentilated: IfcLogical | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Category: IfcLabel | null;
	Priority: IfcInteger | null;
	OffsetDirection: IfcLayerSetDirectionEnum ;
	OffsetValues: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialLayerWithOffsets
	{
		let ptr = 0;
		let Material = tape[ptr++];
		let LayerThickness = tape[ptr++];
		let IsVentilated = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Category = tape[ptr++];
		let Priority = tape[ptr++];
		let OffsetDirection = tape[ptr++];
		let OffsetValues = tape[ptr++];
		return new IfcMaterialLayerWithOffsets(expressID, type, Material, LayerThickness, IsVentilated, Name, Description, Category, Priority, OffsetDirection, OffsetValues);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Material);;
		args.push(this.LayerThickness);;
		args.push(this.IsVentilated);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Category);;
		args.push(this.Priority);;
		args.push(this.OffsetDirection);;
		args.push(this.OffsetValues);;
		return args;
	}
};
export class IfcMaterialList {
	constructor(expressID: number, type: number, Materials: (Handle<IfcMaterial> | IfcMaterial)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Materials = Materials;
	}
	expressID: number;
	type: number;
	Materials: (Handle<IfcMaterial> | IfcMaterial)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialList
	{
		let ptr = 0;
		let Materials = tape[ptr++];
		return new IfcMaterialList(expressID, type, Materials);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Materials);;
		return args;
	}
};
export class IfcMaterialProfile {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Material: (Handle<IfcMaterial> | IfcMaterial) | null, Profile: (Handle<IfcProfileDef> | IfcProfileDef) , Priority: IfcInteger | null, Category: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Material = Material;
		this.Profile = Profile;
		this.Priority = Priority;
		this.Category = Category;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Material: (Handle<IfcMaterial> | IfcMaterial) | null;
	Profile: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Priority: IfcInteger | null;
	Category: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProfile
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Material = tape[ptr++];
		let Profile = tape[ptr++];
		let Priority = tape[ptr++];
		let Category = tape[ptr++];
		return new IfcMaterialProfile(expressID, type, Name, Description, Material, Profile, Priority, Category);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Material);;
		args.push(this.Profile);;
		args.push(this.Priority);;
		args.push(this.Category);;
		return args;
	}
};
export class IfcMaterialProfileSet {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, MaterialProfiles: (Handle<IfcMaterialProfile> | IfcMaterialProfile)[] , CompositeProfile: (Handle<IfcCompositeProfileDef> | IfcCompositeProfileDef) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.MaterialProfiles = MaterialProfiles;
		this.CompositeProfile = CompositeProfile;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	MaterialProfiles: (Handle<IfcMaterialProfile> | IfcMaterialProfile)[] ;
	CompositeProfile: (Handle<IfcCompositeProfileDef> | IfcCompositeProfileDef) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProfileSet
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let MaterialProfiles = tape[ptr++];
		let CompositeProfile = tape[ptr++];
		return new IfcMaterialProfileSet(expressID, type, Name, Description, MaterialProfiles, CompositeProfile);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.MaterialProfiles);;
		args.push(this.CompositeProfile);;
		return args;
	}
};
export class IfcMaterialProfileSetUsage {
	constructor(expressID: number, type: number, ForProfileSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) , CardinalPoint: IfcCardinalPointReference | null, ReferenceExtent: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ForProfileSet = ForProfileSet;
		this.CardinalPoint = CardinalPoint;
		this.ReferenceExtent = ReferenceExtent;
	}
	expressID: number;
	type: number;
	ForProfileSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) ;
	CardinalPoint: IfcCardinalPointReference | null;
	ReferenceExtent: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProfileSetUsage
	{
		let ptr = 0;
		let ForProfileSet = tape[ptr++];
		let CardinalPoint = tape[ptr++];
		let ReferenceExtent = tape[ptr++];
		return new IfcMaterialProfileSetUsage(expressID, type, ForProfileSet, CardinalPoint, ReferenceExtent);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ForProfileSet);;
		args.push(this.CardinalPoint);;
		args.push(this.ReferenceExtent);;
		return args;
	}
};
export class IfcMaterialProfileSetUsageTapering {
	constructor(expressID: number, type: number, ForProfileSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) , CardinalPoint: IfcCardinalPointReference | null, ReferenceExtent: IfcPositiveLengthMeasure | null, ForProfileEndSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) , CardinalEndPoint: IfcCardinalPointReference | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ForProfileSet = ForProfileSet;
		this.CardinalPoint = CardinalPoint;
		this.ReferenceExtent = ReferenceExtent;
		this.ForProfileEndSet = ForProfileEndSet;
		this.CardinalEndPoint = CardinalEndPoint;
	}
	expressID: number;
	type: number;
	ForProfileSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) ;
	CardinalPoint: IfcCardinalPointReference | null;
	ReferenceExtent: IfcPositiveLengthMeasure | null;
	ForProfileEndSet: (Handle<IfcMaterialProfileSet> | IfcMaterialProfileSet) ;
	CardinalEndPoint: IfcCardinalPointReference | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProfileSetUsageTapering
	{
		let ptr = 0;
		let ForProfileSet = tape[ptr++];
		let CardinalPoint = tape[ptr++];
		let ReferenceExtent = tape[ptr++];
		let ForProfileEndSet = tape[ptr++];
		let CardinalEndPoint = tape[ptr++];
		return new IfcMaterialProfileSetUsageTapering(expressID, type, ForProfileSet, CardinalPoint, ReferenceExtent, ForProfileEndSet, CardinalEndPoint);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ForProfileSet);;
		args.push(this.CardinalPoint);;
		args.push(this.ReferenceExtent);;
		args.push(this.ForProfileEndSet);;
		args.push(this.CardinalEndPoint);;
		return args;
	}
};
export class IfcMaterialProfileWithOffsets {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Material: (Handle<IfcMaterial> | IfcMaterial) | null, Profile: (Handle<IfcProfileDef> | IfcProfileDef) , Priority: IfcInteger | null, Category: IfcLabel | null, OffsetValues: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Material = Material;
		this.Profile = Profile;
		this.Priority = Priority;
		this.Category = Category;
		this.OffsetValues = OffsetValues;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Material: (Handle<IfcMaterial> | IfcMaterial) | null;
	Profile: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Priority: IfcInteger | null;
	Category: IfcLabel | null;
	OffsetValues: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProfileWithOffsets
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Material = tape[ptr++];
		let Profile = tape[ptr++];
		let Priority = tape[ptr++];
		let Category = tape[ptr++];
		let OffsetValues = tape[ptr++];
		return new IfcMaterialProfileWithOffsets(expressID, type, Name, Description, Material, Profile, Priority, Category, OffsetValues);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Material);;
		args.push(this.Profile);;
		args.push(this.Priority);;
		args.push(this.Category);;
		args.push(this.OffsetValues);;
		return args;
	}
};
export class IfcMaterialProperties {
	constructor(expressID: number, type: number, Name: IfcIdentifier | null, Description: IfcText | null, Properties: (Handle<IfcProperty> | IfcProperty)[] , Material: (Handle<IfcMaterialDefinition> | IfcMaterialDefinition) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Properties = Properties;
		this.Material = Material;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier | null;
	Description: IfcText | null;
	Properties: (Handle<IfcProperty> | IfcProperty)[] ;
	Material: (Handle<IfcMaterialDefinition> | IfcMaterialDefinition) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialProperties
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Properties = tape[ptr++];
		let Material = tape[ptr++];
		return new IfcMaterialProperties(expressID, type, Name, Description, Properties, Material);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Properties);;
		args.push(this.Material);;
		return args;
	}
};
export class IfcMaterialRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingMaterial: (Handle<IfcMaterial> | IfcMaterial) , RelatedMaterials: (Handle<IfcMaterial> | IfcMaterial)[] , Expression: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingMaterial = RelatingMaterial;
		this.RelatedMaterials = RelatedMaterials;
		this.Expression = Expression;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingMaterial: (Handle<IfcMaterial> | IfcMaterial) ;
	RelatedMaterials: (Handle<IfcMaterial> | IfcMaterial)[] ;
	Expression: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingMaterial = tape[ptr++];
		let RelatedMaterials = tape[ptr++];
		let Expression = tape[ptr++];
		return new IfcMaterialRelationship(expressID, type, Name, Description, RelatingMaterial, RelatedMaterials, Expression);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingMaterial);;
		args.push(this.RelatedMaterials);;
		args.push(this.Expression);;
		return args;
	}
};
export class IfcMaterialUsageDefinition {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMaterialUsageDefinition
	{
		let ptr = 0;
		return new IfcMaterialUsageDefinition(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcMeasureWithUnit {
	constructor(expressID: number, type: number, ValueComponent: IfcValue , UnitComponent: IfcUnit )
	{
		this.expressID = expressID;
		this.type = type;
		this.ValueComponent = ValueComponent;
		this.UnitComponent = UnitComponent;
	}
	expressID: number;
	type: number;
	ValueComponent: IfcValue ;
	UnitComponent: IfcUnit ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMeasureWithUnit
	{
		let ptr = 0;
		let ValueComponent = tape[ptr++];
		let UnitComponent = tape[ptr++];
		return new IfcMeasureWithUnit(expressID, type, ValueComponent, UnitComponent);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ValueComponent);;
		args.push(this.UnitComponent);;
		return args;
	}
};
export class IfcMechanicalFastener {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, NominalDiameter: IfcPositiveLengthMeasure | null, NominalLength: IfcPositiveLengthMeasure | null, PredefinedType: IfcMechanicalFastenerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.NominalDiameter = NominalDiameter;
		this.NominalLength = NominalLength;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	NominalLength: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcMechanicalFastenerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMechanicalFastener
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let NominalLength = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMechanicalFastener(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, NominalDiameter, NominalLength, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.NominalDiameter);;
		args.push(this.NominalLength);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMechanicalFastenerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcMechanicalFastenerTypeEnum , NominalDiameter: IfcPositiveLengthMeasure | null, NominalLength: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.NominalDiameter = NominalDiameter;
		this.NominalLength = NominalLength;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcMechanicalFastenerTypeEnum ;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	NominalLength: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMechanicalFastenerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let NominalLength = tape[ptr++];
		return new IfcMechanicalFastenerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, NominalDiameter, NominalLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.NominalDiameter);;
		args.push(this.NominalLength);;
		return args;
	}
};
export class IfcMedicalDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcMedicalDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcMedicalDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMedicalDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMedicalDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMedicalDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcMedicalDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcMedicalDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMedicalDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMedicalDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMember {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcMemberTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcMemberTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMember
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMember(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMemberStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcMemberTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcMemberTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMemberStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMemberStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMemberType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcMemberTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcMemberTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMemberType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMemberType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMetric {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, ConstraintGrade: IfcConstraintEnum , ConstraintSource: IfcLabel | null, CreatingActor: IfcActorSelect | null, CreationTime: IfcDateTime | null, UserDefinedGrade: IfcLabel | null, Benchmark: IfcBenchmarkEnum , ValueSource: IfcLabel | null, DataValue: IfcMetricValueSelect | null, ReferencePath: (Handle<IfcReference> | IfcReference) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.ConstraintGrade = ConstraintGrade;
		this.ConstraintSource = ConstraintSource;
		this.CreatingActor = CreatingActor;
		this.CreationTime = CreationTime;
		this.UserDefinedGrade = UserDefinedGrade;
		this.Benchmark = Benchmark;
		this.ValueSource = ValueSource;
		this.DataValue = DataValue;
		this.ReferencePath = ReferencePath;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	ConstraintGrade: IfcConstraintEnum ;
	ConstraintSource: IfcLabel | null;
	CreatingActor: IfcActorSelect | null;
	CreationTime: IfcDateTime | null;
	UserDefinedGrade: IfcLabel | null;
	Benchmark: IfcBenchmarkEnum ;
	ValueSource: IfcLabel | null;
	DataValue: IfcMetricValueSelect | null;
	ReferencePath: (Handle<IfcReference> | IfcReference) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMetric
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConstraintGrade = tape[ptr++];
		let ConstraintSource = tape[ptr++];
		let CreatingActor = tape[ptr++];
		let CreationTime = tape[ptr++];
		let UserDefinedGrade = tape[ptr++];
		let Benchmark = tape[ptr++];
		let ValueSource = tape[ptr++];
		let DataValue = tape[ptr++];
		let ReferencePath = tape[ptr++];
		return new IfcMetric(expressID, type, Name, Description, ConstraintGrade, ConstraintSource, CreatingActor, CreationTime, UserDefinedGrade, Benchmark, ValueSource, DataValue, ReferencePath);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConstraintGrade);;
		args.push(this.ConstraintSource);;
		args.push(this.CreatingActor);;
		args.push(this.CreationTime);;
		args.push(this.UserDefinedGrade);;
		args.push(this.Benchmark);;
		args.push(this.ValueSource);;
		args.push(this.DataValue);;
		args.push(this.ReferencePath);;
		return args;
	}
};
export class IfcMirroredProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, ParentProfile: (Handle<IfcProfileDef> | IfcProfileDef) , Operator: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) , Label: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.ParentProfile = ParentProfile;
		this.Operator = Operator;
		this.Label = Label;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	ParentProfile: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Operator: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) ;
	Label: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMirroredProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let ParentProfile = tape[ptr++];
		let Operator = tape[ptr++];
		let Label = tape[ptr++];
		return new IfcMirroredProfileDef(expressID, type, ProfileType, ProfileName, ParentProfile, Operator, Label);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.ParentProfile);;
		args.push(this.Operator);;
		args.push(this.Label);;
		return args;
	}
};
export class IfcMonetaryUnit {
	constructor(expressID: number, type: number, Currency: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Currency = Currency;
	}
	expressID: number;
	type: number;
	Currency: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMonetaryUnit
	{
		let ptr = 0;
		let Currency = tape[ptr++];
		return new IfcMonetaryUnit(expressID, type, Currency);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Currency);;
		return args;
	}
};
export class IfcMotorConnection {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcMotorConnectionTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcMotorConnectionTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMotorConnection
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMotorConnection(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcMotorConnectionType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcMotorConnectionTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcMotorConnectionTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcMotorConnectionType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcMotorConnectionType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcNamedUnit {
	constructor(expressID: number, type: number, Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) , UnitType: IfcUnitEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.Dimensions = Dimensions;
		this.UnitType = UnitType;
	}
	expressID: number;
	type: number;
	Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) ;
	UnitType: IfcUnitEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcNamedUnit
	{
		let ptr = 0;
		let Dimensions = tape[ptr++];
		let UnitType = tape[ptr++];
		return new IfcNamedUnit(expressID, type, Dimensions, UnitType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Dimensions);;
		args.push(this.UnitType);;
		return args;
	}
};
export class IfcObject {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcObject
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		return new IfcObject(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		return args;
	}
};
export class IfcObjectDefinition {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcObjectDefinition
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcObjectDefinition(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcObjectPlacement {
	constructor(expressID: number, type: number, PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.PlacementRelTo = PlacementRelTo;
	}
	expressID: number;
	type: number;
	PlacementRelTo: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcObjectPlacement
	{
		let ptr = 0;
		let PlacementRelTo = tape[ptr++];
		return new IfcObjectPlacement(expressID, type, PlacementRelTo);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.PlacementRelTo);;
		return args;
	}
};
export class IfcObjective {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, ConstraintGrade: IfcConstraintEnum , ConstraintSource: IfcLabel | null, CreatingActor: IfcActorSelect | null, CreationTime: IfcDateTime | null, UserDefinedGrade: IfcLabel | null, BenchmarkValues: (Handle<IfcConstraint> | IfcConstraint)[] | null, LogicalAggregator: IfcLogicalOperatorEnum | null, ObjectiveQualifier: IfcObjectiveEnum , UserDefinedQualifier: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.ConstraintGrade = ConstraintGrade;
		this.ConstraintSource = ConstraintSource;
		this.CreatingActor = CreatingActor;
		this.CreationTime = CreationTime;
		this.UserDefinedGrade = UserDefinedGrade;
		this.BenchmarkValues = BenchmarkValues;
		this.LogicalAggregator = LogicalAggregator;
		this.ObjectiveQualifier = ObjectiveQualifier;
		this.UserDefinedQualifier = UserDefinedQualifier;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	ConstraintGrade: IfcConstraintEnum ;
	ConstraintSource: IfcLabel | null;
	CreatingActor: IfcActorSelect | null;
	CreationTime: IfcDateTime | null;
	UserDefinedGrade: IfcLabel | null;
	BenchmarkValues: (Handle<IfcConstraint> | IfcConstraint)[] | null;
	LogicalAggregator: IfcLogicalOperatorEnum | null;
	ObjectiveQualifier: IfcObjectiveEnum ;
	UserDefinedQualifier: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcObjective
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConstraintGrade = tape[ptr++];
		let ConstraintSource = tape[ptr++];
		let CreatingActor = tape[ptr++];
		let CreationTime = tape[ptr++];
		let UserDefinedGrade = tape[ptr++];
		let BenchmarkValues = tape[ptr++];
		let LogicalAggregator = tape[ptr++];
		let ObjectiveQualifier = tape[ptr++];
		let UserDefinedQualifier = tape[ptr++];
		return new IfcObjective(expressID, type, Name, Description, ConstraintGrade, ConstraintSource, CreatingActor, CreationTime, UserDefinedGrade, BenchmarkValues, LogicalAggregator, ObjectiveQualifier, UserDefinedQualifier);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConstraintGrade);;
		args.push(this.ConstraintSource);;
		args.push(this.CreatingActor);;
		args.push(this.CreationTime);;
		args.push(this.UserDefinedGrade);;
		args.push(this.BenchmarkValues);;
		args.push(this.LogicalAggregator);;
		args.push(this.ObjectiveQualifier);;
		args.push(this.UserDefinedQualifier);;
		return args;
	}
};
export class IfcOccupant {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, TheActor: IfcActorSelect , PredefinedType: IfcOccupantTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.TheActor = TheActor;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	TheActor: IfcActorSelect ;
	PredefinedType: IfcOccupantTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOccupant
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let TheActor = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcOccupant(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, TheActor, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.TheActor);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcOffsetCurve {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOffsetCurve
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		return new IfcOffsetCurve(expressID, type, BasisCurve);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		return args;
	}
};
export class IfcOffsetCurve2D {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) , Distance: IfcLengthMeasure , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
		this.Distance = Distance;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	Distance: IfcLengthMeasure ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOffsetCurve2D
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		let Distance = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcOffsetCurve2D(expressID, type, BasisCurve, Distance, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		args.push(this.Distance);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcOffsetCurve3D {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) , Distance: IfcLengthMeasure , SelfIntersect: IfcLogical , RefDirection: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
		this.Distance = Distance;
		this.SelfIntersect = SelfIntersect;
		this.RefDirection = RefDirection;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	Distance: IfcLengthMeasure ;
	SelfIntersect: IfcLogical ;
	RefDirection: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOffsetCurve3D
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		let Distance = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		let RefDirection = tape[ptr++];
		return new IfcOffsetCurve3D(expressID, type, BasisCurve, Distance, SelfIntersect, RefDirection);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		args.push(this.Distance);;
		args.push(this.SelfIntersect);;
		args.push(this.RefDirection);;
		return args;
	}
};
export class IfcOffsetCurveByDistances {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) , OffsetValues: (Handle<IfcDistanceExpression> | IfcDistanceExpression)[] , Tag: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
		this.OffsetValues = OffsetValues;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	OffsetValues: (Handle<IfcDistanceExpression> | IfcDistanceExpression)[] ;
	Tag: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOffsetCurveByDistances
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		let OffsetValues = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcOffsetCurveByDistances(expressID, type, BasisCurve, OffsetValues, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		args.push(this.OffsetValues);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcOpenShell {
	constructor(expressID: number, type: number, CfsFaces: (Handle<IfcFace> | IfcFace)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.CfsFaces = CfsFaces;
	}
	expressID: number;
	type: number;
	CfsFaces: (Handle<IfcFace> | IfcFace)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOpenShell
	{
		let ptr = 0;
		let CfsFaces = tape[ptr++];
		return new IfcOpenShell(expressID, type, CfsFaces);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.CfsFaces);;
		return args;
	}
};
export class IfcOpeningElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcOpeningElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcOpeningElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOpeningElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcOpeningElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcOpeningStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcOpeningElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcOpeningElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOpeningStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcOpeningStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcOrganization {
	constructor(expressID: number, type: number, Identification: IfcIdentifier | null, Name: IfcLabel , Description: IfcText | null, Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null, Addresses: (Handle<IfcAddress> | IfcAddress)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Identification = Identification;
		this.Name = Name;
		this.Description = Description;
		this.Roles = Roles;
		this.Addresses = Addresses;
	}
	expressID: number;
	type: number;
	Identification: IfcIdentifier | null;
	Name: IfcLabel ;
	Description: IfcText | null;
	Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null;
	Addresses: (Handle<IfcAddress> | IfcAddress)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOrganization
	{
		let ptr = 0;
		let Identification = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Roles = tape[ptr++];
		let Addresses = tape[ptr++];
		return new IfcOrganization(expressID, type, Identification, Name, Description, Roles, Addresses);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Identification);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Roles);;
		args.push(this.Addresses);;
		return args;
	}
};
export class IfcOrganizationRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingOrganization: (Handle<IfcOrganization> | IfcOrganization) , RelatedOrganizations: (Handle<IfcOrganization> | IfcOrganization)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingOrganization = RelatingOrganization;
		this.RelatedOrganizations = RelatedOrganizations;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingOrganization: (Handle<IfcOrganization> | IfcOrganization) ;
	RelatedOrganizations: (Handle<IfcOrganization> | IfcOrganization)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOrganizationRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingOrganization = tape[ptr++];
		let RelatedOrganizations = tape[ptr++];
		return new IfcOrganizationRelationship(expressID, type, Name, Description, RelatingOrganization, RelatedOrganizations);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingOrganization);;
		args.push(this.RelatedOrganizations);;
		return args;
	}
};
export class IfcOrientationExpression {
	constructor(expressID: number, type: number, LateralAxisDirection: (Handle<IfcDirection> | IfcDirection) , VerticalAxisDirection: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.LateralAxisDirection = LateralAxisDirection;
		this.VerticalAxisDirection = VerticalAxisDirection;
	}
	expressID: number;
	type: number;
	LateralAxisDirection: (Handle<IfcDirection> | IfcDirection) ;
	VerticalAxisDirection: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOrientationExpression
	{
		let ptr = 0;
		let LateralAxisDirection = tape[ptr++];
		let VerticalAxisDirection = tape[ptr++];
		return new IfcOrientationExpression(expressID, type, LateralAxisDirection, VerticalAxisDirection);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.LateralAxisDirection);;
		args.push(this.VerticalAxisDirection);;
		return args;
	}
};
export class IfcOrientedEdge {
	constructor(expressID: number, type: number, EdgeStart: (Handle<IfcVertex> | IfcVertex) , EdgeEnd: (Handle<IfcVertex> | IfcVertex) , EdgeElement: (Handle<IfcEdge> | IfcEdge) , Orientation: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeStart = EdgeStart;
		this.EdgeEnd = EdgeEnd;
		this.EdgeElement = EdgeElement;
		this.Orientation = Orientation;
	}
	expressID: number;
	type: number;
	EdgeStart: (Handle<IfcVertex> | IfcVertex) ;
	EdgeEnd: (Handle<IfcVertex> | IfcVertex) ;
	EdgeElement: (Handle<IfcEdge> | IfcEdge) ;
	Orientation: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOrientedEdge
	{
		let ptr = 0;
		let EdgeStart = tape[ptr++];
		let EdgeEnd = tape[ptr++];
		let EdgeElement = tape[ptr++];
		let Orientation = tape[ptr++];
		return new IfcOrientedEdge(expressID, type, EdgeStart, EdgeEnd, EdgeElement, Orientation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeStart);;
		args.push(this.EdgeEnd);;
		args.push(this.EdgeElement);;
		args.push(this.Orientation);;
		return args;
	}
};
export class IfcOuterBoundaryCurve {
	constructor(expressID: number, type: number, Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] , SelfIntersect: IfcLogical )
	{
		this.expressID = expressID;
		this.type = type;
		this.Segments = Segments;
		this.SelfIntersect = SelfIntersect;
	}
	expressID: number;
	type: number;
	Segments: (Handle<IfcCompositeCurveSegment> | IfcCompositeCurveSegment)[] ;
	SelfIntersect: IfcLogical ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOuterBoundaryCurve
	{
		let ptr = 0;
		let Segments = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		return new IfcOuterBoundaryCurve(expressID, type, Segments, SelfIntersect);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Segments);;
		args.push(this.SelfIntersect);;
		return args;
	}
};
export class IfcOutlet {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcOutletTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcOutletTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOutlet
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcOutlet(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcOutletType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcOutletTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcOutletTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOutletType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcOutletType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcOwnerHistory {
	constructor(expressID: number, type: number, OwningUser: (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization) , OwningApplication: (Handle<IfcApplication> | IfcApplication) , State: IfcStateEnum | null, ChangeAction: IfcChangeActionEnum | null, LastModifiedDate: IfcTimeStamp | null, LastModifyingUser: (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization) | null, LastModifyingApplication: (Handle<IfcApplication> | IfcApplication) | null, CreationDate: IfcTimeStamp )
	{
		this.expressID = expressID;
		this.type = type;
		this.OwningUser = OwningUser;
		this.OwningApplication = OwningApplication;
		this.State = State;
		this.ChangeAction = ChangeAction;
		this.LastModifiedDate = LastModifiedDate;
		this.LastModifyingUser = LastModifyingUser;
		this.LastModifyingApplication = LastModifyingApplication;
		this.CreationDate = CreationDate;
	}
	expressID: number;
	type: number;
	OwningUser: (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization) ;
	OwningApplication: (Handle<IfcApplication> | IfcApplication) ;
	State: IfcStateEnum | null;
	ChangeAction: IfcChangeActionEnum | null;
	LastModifiedDate: IfcTimeStamp | null;
	LastModifyingUser: (Handle<IfcPersonAndOrganization> | IfcPersonAndOrganization) | null;
	LastModifyingApplication: (Handle<IfcApplication> | IfcApplication) | null;
	CreationDate: IfcTimeStamp ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcOwnerHistory
	{
		let ptr = 0;
		let OwningUser = tape[ptr++];
		let OwningApplication = tape[ptr++];
		let State = tape[ptr++];
		let ChangeAction = tape[ptr++];
		let LastModifiedDate = tape[ptr++];
		let LastModifyingUser = tape[ptr++];
		let LastModifyingApplication = tape[ptr++];
		let CreationDate = tape[ptr++];
		return new IfcOwnerHistory(expressID, type, OwningUser, OwningApplication, State, ChangeAction, LastModifiedDate, LastModifyingUser, LastModifyingApplication, CreationDate);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.OwningUser);;
		args.push(this.OwningApplication);;
		args.push(this.State);;
		args.push(this.ChangeAction);;
		args.push(this.LastModifiedDate);;
		args.push(this.LastModifyingUser);;
		args.push(this.LastModifyingApplication);;
		args.push(this.CreationDate);;
		return args;
	}
};
export class IfcParameterizedProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcParameterizedProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		return new IfcParameterizedProfileDef(expressID, type, ProfileType, ProfileName, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		return args;
	}
};
export class IfcPath {
	constructor(expressID: number, type: number, EdgeList: (Handle<IfcOrientedEdge> | IfcOrientedEdge)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeList = EdgeList;
	}
	expressID: number;
	type: number;
	EdgeList: (Handle<IfcOrientedEdge> | IfcOrientedEdge)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPath
	{
		let ptr = 0;
		let EdgeList = tape[ptr++];
		return new IfcPath(expressID, type, EdgeList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeList);;
		return args;
	}
};
export class IfcPcurve {
	constructor(expressID: number, type: number, BasisSurface: (Handle<IfcSurface> | IfcSurface) , ReferenceCurve: (Handle<IfcCurve> | IfcCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisSurface = BasisSurface;
		this.ReferenceCurve = ReferenceCurve;
	}
	expressID: number;
	type: number;
	BasisSurface: (Handle<IfcSurface> | IfcSurface) ;
	ReferenceCurve: (Handle<IfcCurve> | IfcCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPcurve
	{
		let ptr = 0;
		let BasisSurface = tape[ptr++];
		let ReferenceCurve = tape[ptr++];
		return new IfcPcurve(expressID, type, BasisSurface, ReferenceCurve);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisSurface);;
		args.push(this.ReferenceCurve);;
		return args;
	}
};
export class IfcPerformanceHistory {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LifeCyclePhase: IfcLabel , PredefinedType: IfcPerformanceHistoryTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LifeCyclePhase = LifeCyclePhase;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LifeCyclePhase: IfcLabel ;
	PredefinedType: IfcPerformanceHistoryTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPerformanceHistory
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LifeCyclePhase = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPerformanceHistory(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LifeCyclePhase, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LifeCyclePhase);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPermeableCoveringProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, OperationType: IfcPermeableCoveringOperationEnum , PanelPosition: IfcWindowPanelPositionEnum , FrameDepth: IfcPositiveLengthMeasure | null, FrameThickness: IfcPositiveLengthMeasure | null, ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.OperationType = OperationType;
		this.PanelPosition = PanelPosition;
		this.FrameDepth = FrameDepth;
		this.FrameThickness = FrameThickness;
		this.ShapeAspectStyle = ShapeAspectStyle;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	OperationType: IfcPermeableCoveringOperationEnum ;
	PanelPosition: IfcWindowPanelPositionEnum ;
	FrameDepth: IfcPositiveLengthMeasure | null;
	FrameThickness: IfcPositiveLengthMeasure | null;
	ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPermeableCoveringProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let OperationType = tape[ptr++];
		let PanelPosition = tape[ptr++];
		let FrameDepth = tape[ptr++];
		let FrameThickness = tape[ptr++];
		let ShapeAspectStyle = tape[ptr++];
		return new IfcPermeableCoveringProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, OperationType, PanelPosition, FrameDepth, FrameThickness, ShapeAspectStyle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.OperationType);;
		args.push(this.PanelPosition);;
		args.push(this.FrameDepth);;
		args.push(this.FrameThickness);;
		args.push(this.ShapeAspectStyle);;
		return args;
	}
};
export class IfcPermit {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, PredefinedType: IfcPermitTypeEnum | null, Status: IfcLabel | null, LongDescription: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.PredefinedType = PredefinedType;
		this.Status = Status;
		this.LongDescription = LongDescription;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	PredefinedType: IfcPermitTypeEnum | null;
	Status: IfcLabel | null;
	LongDescription: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPermit
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Status = tape[ptr++];
		let LongDescription = tape[ptr++];
		return new IfcPermit(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, PredefinedType, Status, LongDescription);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.PredefinedType);;
		args.push(this.Status);;
		args.push(this.LongDescription);;
		return args;
	}
};
export class IfcPerson {
	constructor(expressID: number, type: number, Identification: IfcIdentifier | null, FamilyName: IfcLabel | null, GivenName: IfcLabel | null, MiddleNames: IfcLabel[] | null, PrefixTitles: IfcLabel[] | null, SuffixTitles: IfcLabel[] | null, Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null, Addresses: (Handle<IfcAddress> | IfcAddress)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Identification = Identification;
		this.FamilyName = FamilyName;
		this.GivenName = GivenName;
		this.MiddleNames = MiddleNames;
		this.PrefixTitles = PrefixTitles;
		this.SuffixTitles = SuffixTitles;
		this.Roles = Roles;
		this.Addresses = Addresses;
	}
	expressID: number;
	type: number;
	Identification: IfcIdentifier | null;
	FamilyName: IfcLabel | null;
	GivenName: IfcLabel | null;
	MiddleNames: IfcLabel[] | null;
	PrefixTitles: IfcLabel[] | null;
	SuffixTitles: IfcLabel[] | null;
	Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null;
	Addresses: (Handle<IfcAddress> | IfcAddress)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPerson
	{
		let ptr = 0;
		let Identification = tape[ptr++];
		let FamilyName = tape[ptr++];
		let GivenName = tape[ptr++];
		let MiddleNames = tape[ptr++];
		let PrefixTitles = tape[ptr++];
		let SuffixTitles = tape[ptr++];
		let Roles = tape[ptr++];
		let Addresses = tape[ptr++];
		return new IfcPerson(expressID, type, Identification, FamilyName, GivenName, MiddleNames, PrefixTitles, SuffixTitles, Roles, Addresses);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Identification);;
		args.push(this.FamilyName);;
		args.push(this.GivenName);;
		args.push(this.MiddleNames);;
		args.push(this.PrefixTitles);;
		args.push(this.SuffixTitles);;
		args.push(this.Roles);;
		args.push(this.Addresses);;
		return args;
	}
};
export class IfcPersonAndOrganization {
	constructor(expressID: number, type: number, ThePerson: (Handle<IfcPerson> | IfcPerson) , TheOrganization: (Handle<IfcOrganization> | IfcOrganization) , Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ThePerson = ThePerson;
		this.TheOrganization = TheOrganization;
		this.Roles = Roles;
	}
	expressID: number;
	type: number;
	ThePerson: (Handle<IfcPerson> | IfcPerson) ;
	TheOrganization: (Handle<IfcOrganization> | IfcOrganization) ;
	Roles: (Handle<IfcActorRole> | IfcActorRole)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPersonAndOrganization
	{
		let ptr = 0;
		let ThePerson = tape[ptr++];
		let TheOrganization = tape[ptr++];
		let Roles = tape[ptr++];
		return new IfcPersonAndOrganization(expressID, type, ThePerson, TheOrganization, Roles);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ThePerson);;
		args.push(this.TheOrganization);;
		args.push(this.Roles);;
		return args;
	}
};
export class IfcPhysicalComplexQuantity {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, HasQuantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] , Discrimination: IfcLabel , Quality: IfcLabel | null, Usage: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.HasQuantities = HasQuantities;
		this.Discrimination = Discrimination;
		this.Quality = Quality;
		this.Usage = Usage;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	HasQuantities: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity)[] ;
	Discrimination: IfcLabel ;
	Quality: IfcLabel | null;
	Usage: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPhysicalComplexQuantity
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let HasQuantities = tape[ptr++];
		let Discrimination = tape[ptr++];
		let Quality = tape[ptr++];
		let Usage = tape[ptr++];
		return new IfcPhysicalComplexQuantity(expressID, type, Name, Description, HasQuantities, Discrimination, Quality, Usage);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.HasQuantities);;
		args.push(this.Discrimination);;
		args.push(this.Quality);;
		args.push(this.Usage);;
		return args;
	}
};
export class IfcPhysicalQuantity {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPhysicalQuantity
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPhysicalQuantity(expressID, type, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPhysicalSimpleQuantity {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPhysicalSimpleQuantity
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		return new IfcPhysicalSimpleQuantity(expressID, type, Name, Description, Unit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		return args;
	}
};
export class IfcPile {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPileTypeEnum | null, ConstructionType: IfcPileConstructionEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
		this.ConstructionType = ConstructionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPileTypeEnum | null;
	ConstructionType: IfcPileConstructionEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPile
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let ConstructionType = tape[ptr++];
		return new IfcPile(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType, ConstructionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		args.push(this.ConstructionType);;
		return args;
	}
};
export class IfcPileType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcPileTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcPileTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPileType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPileType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPipeFitting {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPipeFittingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPipeFittingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPipeFitting
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPipeFitting(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPipeFittingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcPipeFittingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcPipeFittingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPipeFittingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPipeFittingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPipeSegment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPipeSegmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPipeSegmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPipeSegment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPipeSegment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPipeSegmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcPipeSegmentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcPipeSegmentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPipeSegmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPipeSegmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPixelTexture {
	constructor(expressID: number, type: number, RepeatS: IfcBoolean , RepeatT: IfcBoolean , Mode: IfcIdentifier | null, TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null, Parameter: IfcIdentifier[] | null, Width: IfcInteger , Height: IfcInteger , ColourComponents: IfcInteger , Pixel: IfcBinary[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.RepeatS = RepeatS;
		this.RepeatT = RepeatT;
		this.Mode = Mode;
		this.TextureTransform = TextureTransform;
		this.Parameter = Parameter;
		this.Width = Width;
		this.Height = Height;
		this.ColourComponents = ColourComponents;
		this.Pixel = Pixel;
	}
	expressID: number;
	type: number;
	RepeatS: IfcBoolean ;
	RepeatT: IfcBoolean ;
	Mode: IfcIdentifier | null;
	TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null;
	Parameter: IfcIdentifier[] | null;
	Width: IfcInteger ;
	Height: IfcInteger ;
	ColourComponents: IfcInteger ;
	Pixel: IfcBinary[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPixelTexture
	{
		let ptr = 0;
		let RepeatS = tape[ptr++];
		let RepeatT = tape[ptr++];
		let Mode = tape[ptr++];
		let TextureTransform = tape[ptr++];
		let Parameter = tape[ptr++];
		let Width = tape[ptr++];
		let Height = tape[ptr++];
		let ColourComponents = tape[ptr++];
		let Pixel = tape[ptr++];
		return new IfcPixelTexture(expressID, type, RepeatS, RepeatT, Mode, TextureTransform, Parameter, Width, Height, ColourComponents, Pixel);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RepeatS);;
		args.push(this.RepeatT);;
		args.push(this.Mode);;
		args.push(this.TextureTransform);;
		args.push(this.Parameter);;
		args.push(this.Width);;
		args.push(this.Height);;
		args.push(this.ColourComponents);;
		args.push(this.Pixel);;
		return args;
	}
};
export class IfcPlacement {
	constructor(expressID: number, type: number, Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Location = Location;
	}
	expressID: number;
	type: number;
	Location: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlacement
	{
		let ptr = 0;
		let Location = tape[ptr++];
		return new IfcPlacement(expressID, type, Location);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Location);;
		return args;
	}
};
export class IfcPlanarBox {
	constructor(expressID: number, type: number, SizeInX: IfcLengthMeasure , SizeInY: IfcLengthMeasure , Placement: IfcAxis2Placement )
	{
		this.expressID = expressID;
		this.type = type;
		this.SizeInX = SizeInX;
		this.SizeInY = SizeInY;
		this.Placement = Placement;
	}
	expressID: number;
	type: number;
	SizeInX: IfcLengthMeasure ;
	SizeInY: IfcLengthMeasure ;
	Placement: IfcAxis2Placement ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlanarBox
	{
		let ptr = 0;
		let SizeInX = tape[ptr++];
		let SizeInY = tape[ptr++];
		let Placement = tape[ptr++];
		return new IfcPlanarBox(expressID, type, SizeInX, SizeInY, Placement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SizeInX);;
		args.push(this.SizeInY);;
		args.push(this.Placement);;
		return args;
	}
};
export class IfcPlanarExtent {
	constructor(expressID: number, type: number, SizeInX: IfcLengthMeasure , SizeInY: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.SizeInX = SizeInX;
		this.SizeInY = SizeInY;
	}
	expressID: number;
	type: number;
	SizeInX: IfcLengthMeasure ;
	SizeInY: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlanarExtent
	{
		let ptr = 0;
		let SizeInX = tape[ptr++];
		let SizeInY = tape[ptr++];
		return new IfcPlanarExtent(expressID, type, SizeInX, SizeInY);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SizeInX);;
		args.push(this.SizeInY);;
		return args;
	}
};
export class IfcPlane {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlane
	{
		let ptr = 0;
		let Position = tape[ptr++];
		return new IfcPlane(expressID, type, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		return args;
	}
};
export class IfcPlate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPlateTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPlateTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPlate(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPlateStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPlateTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPlateTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlateStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPlateStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPlateType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcPlateTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcPlateTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPlateType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPlateType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPoint {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPoint
	{
		let ptr = 0;
		return new IfcPoint(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcPointOnCurve {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) , PointParameter: IfcParameterValue )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
		this.PointParameter = PointParameter;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	PointParameter: IfcParameterValue ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPointOnCurve
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		let PointParameter = tape[ptr++];
		return new IfcPointOnCurve(expressID, type, BasisCurve, PointParameter);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		args.push(this.PointParameter);;
		return args;
	}
};
export class IfcPointOnSurface {
	constructor(expressID: number, type: number, BasisSurface: (Handle<IfcSurface> | IfcSurface) , PointParameterU: IfcParameterValue , PointParameterV: IfcParameterValue )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisSurface = BasisSurface;
		this.PointParameterU = PointParameterU;
		this.PointParameterV = PointParameterV;
	}
	expressID: number;
	type: number;
	BasisSurface: (Handle<IfcSurface> | IfcSurface) ;
	PointParameterU: IfcParameterValue ;
	PointParameterV: IfcParameterValue ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPointOnSurface
	{
		let ptr = 0;
		let BasisSurface = tape[ptr++];
		let PointParameterU = tape[ptr++];
		let PointParameterV = tape[ptr++];
		return new IfcPointOnSurface(expressID, type, BasisSurface, PointParameterU, PointParameterV);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisSurface);;
		args.push(this.PointParameterU);;
		args.push(this.PointParameterV);;
		return args;
	}
};
export class IfcPolyLoop {
	constructor(expressID: number, type: number, Polygon: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Polygon = Polygon;
	}
	expressID: number;
	type: number;
	Polygon: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPolyLoop
	{
		let ptr = 0;
		let Polygon = tape[ptr++];
		return new IfcPolyLoop(expressID, type, Polygon);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Polygon);;
		return args;
	}
};
export class IfcPolygonalBoundedHalfSpace {
	constructor(expressID: number, type: number, BaseSurface: (Handle<IfcSurface> | IfcSurface) , AgreementFlag: IfcBoolean , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , PolygonalBoundary: (Handle<IfcBoundedCurve> | IfcBoundedCurve) )
	{
		this.expressID = expressID;
		this.type = type;
		this.BaseSurface = BaseSurface;
		this.AgreementFlag = AgreementFlag;
		this.Position = Position;
		this.PolygonalBoundary = PolygonalBoundary;
	}
	expressID: number;
	type: number;
	BaseSurface: (Handle<IfcSurface> | IfcSurface) ;
	AgreementFlag: IfcBoolean ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	PolygonalBoundary: (Handle<IfcBoundedCurve> | IfcBoundedCurve) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPolygonalBoundedHalfSpace
	{
		let ptr = 0;
		let BaseSurface = tape[ptr++];
		let AgreementFlag = tape[ptr++];
		let Position = tape[ptr++];
		let PolygonalBoundary = tape[ptr++];
		return new IfcPolygonalBoundedHalfSpace(expressID, type, BaseSurface, AgreementFlag, Position, PolygonalBoundary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BaseSurface);;
		args.push(this.AgreementFlag);;
		args.push(this.Position);;
		args.push(this.PolygonalBoundary);;
		return args;
	}
};
export class IfcPolygonalFaceSet {
	constructor(expressID: number, type: number, Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) , Closed: IfcBoolean | null, Faces: (Handle<IfcIndexedPolygonalFace> | IfcIndexedPolygonalFace)[] , PnIndex: IfcPositiveInteger[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
		this.Closed = Closed;
		this.Faces = Faces;
		this.PnIndex = PnIndex;
	}
	expressID: number;
	type: number;
	Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) ;
	Closed: IfcBoolean | null;
	Faces: (Handle<IfcIndexedPolygonalFace> | IfcIndexedPolygonalFace)[] ;
	PnIndex: IfcPositiveInteger[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPolygonalFaceSet
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		let Closed = tape[ptr++];
		let Faces = tape[ptr++];
		let PnIndex = tape[ptr++];
		return new IfcPolygonalFaceSet(expressID, type, Coordinates, Closed, Faces, PnIndex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		args.push(this.Closed);;
		args.push(this.Faces);;
		args.push(this.PnIndex);;
		return args;
	}
};
export class IfcPolyline {
	constructor(expressID: number, type: number, Points: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Points = Points;
	}
	expressID: number;
	type: number;
	Points: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPolyline
	{
		let ptr = 0;
		let Points = tape[ptr++];
		return new IfcPolyline(expressID, type, Points);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Points);;
		return args;
	}
};
export class IfcPort {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPort
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcPort(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcPositioningElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPositioningElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcPositioningElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcPostalAddress {
	constructor(expressID: number, type: number, Purpose: IfcAddressTypeEnum | null, Description: IfcText | null, UserDefinedPurpose: IfcLabel | null, InternalLocation: IfcLabel | null, AddressLines: IfcLabel[] | null, PostalBox: IfcLabel | null, Town: IfcLabel | null, Region: IfcLabel | null, PostalCode: IfcLabel | null, Country: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Purpose = Purpose;
		this.Description = Description;
		this.UserDefinedPurpose = UserDefinedPurpose;
		this.InternalLocation = InternalLocation;
		this.AddressLines = AddressLines;
		this.PostalBox = PostalBox;
		this.Town = Town;
		this.Region = Region;
		this.PostalCode = PostalCode;
		this.Country = Country;
	}
	expressID: number;
	type: number;
	Purpose: IfcAddressTypeEnum | null;
	Description: IfcText | null;
	UserDefinedPurpose: IfcLabel | null;
	InternalLocation: IfcLabel | null;
	AddressLines: IfcLabel[] | null;
	PostalBox: IfcLabel | null;
	Town: IfcLabel | null;
	Region: IfcLabel | null;
	PostalCode: IfcLabel | null;
	Country: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPostalAddress
	{
		let ptr = 0;
		let Purpose = tape[ptr++];
		let Description = tape[ptr++];
		let UserDefinedPurpose = tape[ptr++];
		let InternalLocation = tape[ptr++];
		let AddressLines = tape[ptr++];
		let PostalBox = tape[ptr++];
		let Town = tape[ptr++];
		let Region = tape[ptr++];
		let PostalCode = tape[ptr++];
		let Country = tape[ptr++];
		return new IfcPostalAddress(expressID, type, Purpose, Description, UserDefinedPurpose, InternalLocation, AddressLines, PostalBox, Town, Region, PostalCode, Country);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Purpose);;
		args.push(this.Description);;
		args.push(this.UserDefinedPurpose);;
		args.push(this.InternalLocation);;
		args.push(this.AddressLines);;
		args.push(this.PostalBox);;
		args.push(this.Town);;
		args.push(this.Region);;
		args.push(this.PostalCode);;
		args.push(this.Country);;
		return args;
	}
};
export class IfcPreDefinedColour {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedColour
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcPreDefinedColour(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcPreDefinedCurveFont {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedCurveFont
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcPreDefinedCurveFont(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcPreDefinedItem {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedItem
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcPreDefinedItem(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcPreDefinedProperties {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedProperties
	{
		let ptr = 0;
		return new IfcPreDefinedProperties(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcPreDefinedPropertySet {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedPropertySet
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPreDefinedPropertySet(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPreDefinedTextFont {
	constructor(expressID: number, type: number, Name: IfcLabel )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPreDefinedTextFont
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcPreDefinedTextFont(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcPresentationItem {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPresentationItem
	{
		let ptr = 0;
		return new IfcPresentationItem(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcPresentationLayerAssignment {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, AssignedItems: IfcLayeredItem[] , Identifier: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.AssignedItems = AssignedItems;
		this.Identifier = Identifier;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	AssignedItems: IfcLayeredItem[] ;
	Identifier: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPresentationLayerAssignment
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let AssignedItems = tape[ptr++];
		let Identifier = tape[ptr++];
		return new IfcPresentationLayerAssignment(expressID, type, Name, Description, AssignedItems, Identifier);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.AssignedItems);;
		args.push(this.Identifier);;
		return args;
	}
};
export class IfcPresentationLayerWithStyle {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, AssignedItems: IfcLayeredItem[] , Identifier: IfcIdentifier | null, LayerOn: IfcLogical , LayerFrozen: IfcLogical , LayerBlocked: IfcLogical , LayerStyles: (Handle<IfcPresentationStyle> | IfcPresentationStyle)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.AssignedItems = AssignedItems;
		this.Identifier = Identifier;
		this.LayerOn = LayerOn;
		this.LayerFrozen = LayerFrozen;
		this.LayerBlocked = LayerBlocked;
		this.LayerStyles = LayerStyles;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	AssignedItems: IfcLayeredItem[] ;
	Identifier: IfcIdentifier | null;
	LayerOn: IfcLogical ;
	LayerFrozen: IfcLogical ;
	LayerBlocked: IfcLogical ;
	LayerStyles: (Handle<IfcPresentationStyle> | IfcPresentationStyle)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPresentationLayerWithStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let AssignedItems = tape[ptr++];
		let Identifier = tape[ptr++];
		let LayerOn = tape[ptr++];
		let LayerFrozen = tape[ptr++];
		let LayerBlocked = tape[ptr++];
		let LayerStyles = tape[ptr++];
		return new IfcPresentationLayerWithStyle(expressID, type, Name, Description, AssignedItems, Identifier, LayerOn, LayerFrozen, LayerBlocked, LayerStyles);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.AssignedItems);;
		args.push(this.Identifier);;
		args.push(this.LayerOn);;
		args.push(this.LayerFrozen);;
		args.push(this.LayerBlocked);;
		args.push(this.LayerStyles);;
		return args;
	}
};
export class IfcPresentationStyle {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPresentationStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcPresentationStyle(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcPresentationStyleAssignment {
	constructor(expressID: number, type: number, Styles: IfcPresentationStyleSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Styles = Styles;
	}
	expressID: number;
	type: number;
	Styles: IfcPresentationStyleSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPresentationStyleAssignment
	{
		let ptr = 0;
		let Styles = tape[ptr++];
		return new IfcPresentationStyleAssignment(expressID, type, Styles);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Styles);;
		return args;
	}
};
export class IfcProcedure {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, PredefinedType: IfcProcedureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	PredefinedType: IfcProcedureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProcedure
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProcedure(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProcedureType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ProcessType: IfcLabel | null, PredefinedType: IfcProcedureTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ProcessType = ProcessType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ProcessType: IfcLabel | null;
	PredefinedType: IfcProcedureTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProcedureType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ProcessType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProcedureType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ProcessType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ProcessType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProcess {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProcess
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		return new IfcProcess(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		return args;
	}
};
export class IfcProduct {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProduct
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcProduct(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcProductDefinitionShape {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Representations = Representations;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProductDefinitionShape
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Representations = tape[ptr++];
		return new IfcProductDefinitionShape(expressID, type, Name, Description, Representations);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Representations);;
		return args;
	}
};
export class IfcProductRepresentation {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Representations = Representations;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Representations: (Handle<IfcRepresentation> | IfcRepresentation)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProductRepresentation
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Representations = tape[ptr++];
		return new IfcProductRepresentation(expressID, type, Name, Description, Representations);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Representations);;
		return args;
	}
};
export class IfcProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		return new IfcProfileDef(expressID, type, ProfileType, ProfileName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		return args;
	}
};
export class IfcProfileProperties {
	constructor(expressID: number, type: number, Name: IfcIdentifier | null, Description: IfcText | null, Properties: (Handle<IfcProperty> | IfcProperty)[] , ProfileDefinition: (Handle<IfcProfileDef> | IfcProfileDef) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Properties = Properties;
		this.ProfileDefinition = ProfileDefinition;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier | null;
	Description: IfcText | null;
	Properties: (Handle<IfcProperty> | IfcProperty)[] ;
	ProfileDefinition: (Handle<IfcProfileDef> | IfcProfileDef) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProfileProperties
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Properties = tape[ptr++];
		let ProfileDefinition = tape[ptr++];
		return new IfcProfileProperties(expressID, type, Name, Description, Properties, ProfileDefinition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Properties);;
		args.push(this.ProfileDefinition);;
		return args;
	}
};
export class IfcProject {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null, Phase: IfcLabel | null, RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null, UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
		this.Phase = Phase;
		this.RepresentationContexts = RepresentationContexts;
		this.UnitsInContext = UnitsInContext;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	Phase: IfcLabel | null;
	RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null;
	UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProject
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		let Phase = tape[ptr++];
		let RepresentationContexts = tape[ptr++];
		let UnitsInContext = tape[ptr++];
		return new IfcProject(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName, Phase, RepresentationContexts, UnitsInContext);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		args.push(this.Phase);;
		args.push(this.RepresentationContexts);;
		args.push(this.UnitsInContext);;
		return args;
	}
};
export class IfcProjectLibrary {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null, Phase: IfcLabel | null, RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null, UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
		this.Phase = Phase;
		this.RepresentationContexts = RepresentationContexts;
		this.UnitsInContext = UnitsInContext;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	Phase: IfcLabel | null;
	RepresentationContexts: (Handle<IfcRepresentationContext> | IfcRepresentationContext)[] | null;
	UnitsInContext: (Handle<IfcUnitAssignment> | IfcUnitAssignment) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProjectLibrary
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		let Phase = tape[ptr++];
		let RepresentationContexts = tape[ptr++];
		let UnitsInContext = tape[ptr++];
		return new IfcProjectLibrary(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName, Phase, RepresentationContexts, UnitsInContext);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		args.push(this.Phase);;
		args.push(this.RepresentationContexts);;
		args.push(this.UnitsInContext);;
		return args;
	}
};
export class IfcProjectOrder {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, PredefinedType: IfcProjectOrderTypeEnum | null, Status: IfcLabel | null, LongDescription: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.PredefinedType = PredefinedType;
		this.Status = Status;
		this.LongDescription = LongDescription;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	PredefinedType: IfcProjectOrderTypeEnum | null;
	Status: IfcLabel | null;
	LongDescription: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProjectOrder
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Status = tape[ptr++];
		let LongDescription = tape[ptr++];
		return new IfcProjectOrder(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, PredefinedType, Status, LongDescription);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.PredefinedType);;
		args.push(this.Status);;
		args.push(this.LongDescription);;
		return args;
	}
};
export class IfcProjectedCRS {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, GeodeticDatum: IfcIdentifier | null, VerticalDatum: IfcIdentifier | null, MapProjection: IfcIdentifier | null, MapZone: IfcIdentifier | null, MapUnit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.GeodeticDatum = GeodeticDatum;
		this.VerticalDatum = VerticalDatum;
		this.MapProjection = MapProjection;
		this.MapZone = MapZone;
		this.MapUnit = MapUnit;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	GeodeticDatum: IfcIdentifier | null;
	VerticalDatum: IfcIdentifier | null;
	MapProjection: IfcIdentifier | null;
	MapZone: IfcIdentifier | null;
	MapUnit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProjectedCRS
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let GeodeticDatum = tape[ptr++];
		let VerticalDatum = tape[ptr++];
		let MapProjection = tape[ptr++];
		let MapZone = tape[ptr++];
		let MapUnit = tape[ptr++];
		return new IfcProjectedCRS(expressID, type, Name, Description, GeodeticDatum, VerticalDatum, MapProjection, MapZone, MapUnit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.GeodeticDatum);;
		args.push(this.VerticalDatum);;
		args.push(this.MapProjection);;
		args.push(this.MapZone);;
		args.push(this.MapUnit);;
		return args;
	}
};
export class IfcProjectionElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcProjectionElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcProjectionElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProjectionElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProjectionElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProperty {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProperty
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcProperty(expressID, type, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPropertyAbstraction {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyAbstraction
	{
		let ptr = 0;
		return new IfcPropertyAbstraction(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcPropertyBoundedValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, UpperBoundValue: IfcValue | null, LowerBoundValue: IfcValue | null, Unit: IfcUnit | null, SetPointValue: IfcValue | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.UpperBoundValue = UpperBoundValue;
		this.LowerBoundValue = LowerBoundValue;
		this.Unit = Unit;
		this.SetPointValue = SetPointValue;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	UpperBoundValue: IfcValue | null;
	LowerBoundValue: IfcValue | null;
	Unit: IfcUnit | null;
	SetPointValue: IfcValue | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyBoundedValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let UpperBoundValue = tape[ptr++];
		let LowerBoundValue = tape[ptr++];
		let Unit = tape[ptr++];
		let SetPointValue = tape[ptr++];
		return new IfcPropertyBoundedValue(expressID, type, Name, Description, UpperBoundValue, LowerBoundValue, Unit, SetPointValue);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.UpperBoundValue);;
		args.push(this.LowerBoundValue);;
		args.push(this.Unit);;
		args.push(this.SetPointValue);;
		return args;
	}
};
export class IfcPropertyDefinition {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyDefinition
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPropertyDefinition(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPropertyDependencyRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, DependingProperty: (Handle<IfcProperty> | IfcProperty) , DependantProperty: (Handle<IfcProperty> | IfcProperty) , Expression: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.DependingProperty = DependingProperty;
		this.DependantProperty = DependantProperty;
		this.Expression = Expression;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	DependingProperty: (Handle<IfcProperty> | IfcProperty) ;
	DependantProperty: (Handle<IfcProperty> | IfcProperty) ;
	Expression: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyDependencyRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let DependingProperty = tape[ptr++];
		let DependantProperty = tape[ptr++];
		let Expression = tape[ptr++];
		return new IfcPropertyDependencyRelationship(expressID, type, Name, Description, DependingProperty, DependantProperty, Expression);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.DependingProperty);;
		args.push(this.DependantProperty);;
		args.push(this.Expression);;
		return args;
	}
};
export class IfcPropertyEnumeratedValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, EnumerationValues: IfcValue[] | null, EnumerationReference: (Handle<IfcPropertyEnumeration> | IfcPropertyEnumeration) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.EnumerationValues = EnumerationValues;
		this.EnumerationReference = EnumerationReference;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	EnumerationValues: IfcValue[] | null;
	EnumerationReference: (Handle<IfcPropertyEnumeration> | IfcPropertyEnumeration) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyEnumeratedValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let EnumerationValues = tape[ptr++];
		let EnumerationReference = tape[ptr++];
		return new IfcPropertyEnumeratedValue(expressID, type, Name, Description, EnumerationValues, EnumerationReference);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.EnumerationValues);;
		args.push(this.EnumerationReference);;
		return args;
	}
};
export class IfcPropertyEnumeration {
	constructor(expressID: number, type: number, Name: IfcLabel , EnumerationValues: IfcValue[] , Unit: IfcUnit | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.EnumerationValues = EnumerationValues;
		this.Unit = Unit;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	EnumerationValues: IfcValue[] ;
	Unit: IfcUnit | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyEnumeration
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let EnumerationValues = tape[ptr++];
		let Unit = tape[ptr++];
		return new IfcPropertyEnumeration(expressID, type, Name, EnumerationValues, Unit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.EnumerationValues);;
		args.push(this.Unit);;
		return args;
	}
};
export class IfcPropertyListValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, ListValues: IfcValue[] | null, Unit: IfcUnit | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.ListValues = ListValues;
		this.Unit = Unit;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	ListValues: IfcValue[] | null;
	Unit: IfcUnit | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyListValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ListValues = tape[ptr++];
		let Unit = tape[ptr++];
		return new IfcPropertyListValue(expressID, type, Name, Description, ListValues, Unit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ListValues);;
		args.push(this.Unit);;
		return args;
	}
};
export class IfcPropertyReferenceValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, UsageName: IfcText | null, PropertyReference: IfcObjectReferenceSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.UsageName = UsageName;
		this.PropertyReference = PropertyReference;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	UsageName: IfcText | null;
	PropertyReference: IfcObjectReferenceSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyReferenceValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let UsageName = tape[ptr++];
		let PropertyReference = tape[ptr++];
		return new IfcPropertyReferenceValue(expressID, type, Name, Description, UsageName, PropertyReference);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.UsageName);;
		args.push(this.PropertyReference);;
		return args;
	}
};
export class IfcPropertySet {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, HasProperties: (Handle<IfcProperty> | IfcProperty)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.HasProperties = HasProperties;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	HasProperties: (Handle<IfcProperty> | IfcProperty)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertySet
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let HasProperties = tape[ptr++];
		return new IfcPropertySet(expressID, type, GlobalId, OwnerHistory, Name, Description, HasProperties);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.HasProperties);;
		return args;
	}
};
export class IfcPropertySetDefinition {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertySetDefinition
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPropertySetDefinition(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPropertySetTemplate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, TemplateType: IfcPropertySetTemplateTypeEnum | null, ApplicableEntity: IfcIdentifier | null, HasPropertyTemplates: (Handle<IfcPropertyTemplate> | IfcPropertyTemplate)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.TemplateType = TemplateType;
		this.ApplicableEntity = ApplicableEntity;
		this.HasPropertyTemplates = HasPropertyTemplates;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	TemplateType: IfcPropertySetTemplateTypeEnum | null;
	ApplicableEntity: IfcIdentifier | null;
	HasPropertyTemplates: (Handle<IfcPropertyTemplate> | IfcPropertyTemplate)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertySetTemplate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let TemplateType = tape[ptr++];
		let ApplicableEntity = tape[ptr++];
		let HasPropertyTemplates = tape[ptr++];
		return new IfcPropertySetTemplate(expressID, type, GlobalId, OwnerHistory, Name, Description, TemplateType, ApplicableEntity, HasPropertyTemplates);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.TemplateType);;
		args.push(this.ApplicableEntity);;
		args.push(this.HasPropertyTemplates);;
		return args;
	}
};
export class IfcPropertySingleValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, NominalValue: IfcValue | null, Unit: IfcUnit | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.NominalValue = NominalValue;
		this.Unit = Unit;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	NominalValue: IfcValue | null;
	Unit: IfcUnit | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertySingleValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let NominalValue = tape[ptr++];
		let Unit = tape[ptr++];
		return new IfcPropertySingleValue(expressID, type, Name, Description, NominalValue, Unit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.NominalValue);;
		args.push(this.Unit);;
		return args;
	}
};
export class IfcPropertyTableValue {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null, DefiningValues: IfcValue[] | null, DefinedValues: IfcValue[] | null, Expression: IfcText | null, DefiningUnit: IfcUnit | null, DefinedUnit: IfcUnit | null, CurveInterpolation: IfcCurveInterpolationEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.DefiningValues = DefiningValues;
		this.DefinedValues = DefinedValues;
		this.Expression = Expression;
		this.DefiningUnit = DefiningUnit;
		this.DefinedUnit = DefinedUnit;
		this.CurveInterpolation = CurveInterpolation;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	DefiningValues: IfcValue[] | null;
	DefinedValues: IfcValue[] | null;
	Expression: IfcText | null;
	DefiningUnit: IfcUnit | null;
	DefinedUnit: IfcUnit | null;
	CurveInterpolation: IfcCurveInterpolationEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyTableValue
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let DefiningValues = tape[ptr++];
		let DefinedValues = tape[ptr++];
		let Expression = tape[ptr++];
		let DefiningUnit = tape[ptr++];
		let DefinedUnit = tape[ptr++];
		let CurveInterpolation = tape[ptr++];
		return new IfcPropertyTableValue(expressID, type, Name, Description, DefiningValues, DefinedValues, Expression, DefiningUnit, DefinedUnit, CurveInterpolation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.DefiningValues);;
		args.push(this.DefinedValues);;
		args.push(this.Expression);;
		args.push(this.DefiningUnit);;
		args.push(this.DefinedUnit);;
		args.push(this.CurveInterpolation);;
		return args;
	}
};
export class IfcPropertyTemplate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyTemplate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPropertyTemplate(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcPropertyTemplateDefinition {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPropertyTemplateDefinition
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcPropertyTemplateDefinition(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcProtectiveDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcProtectiveDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcProtectiveDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProtectiveDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProtectiveDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProtectiveDeviceTrippingUnit {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcProtectiveDeviceTrippingUnitTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcProtectiveDeviceTrippingUnitTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProtectiveDeviceTrippingUnit
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProtectiveDeviceTrippingUnit(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProtectiveDeviceTrippingUnitType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcProtectiveDeviceTrippingUnitTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcProtectiveDeviceTrippingUnitTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProtectiveDeviceTrippingUnitType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProtectiveDeviceTrippingUnitType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProtectiveDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcProtectiveDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcProtectiveDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProtectiveDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcProtectiveDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcProxy {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, ProxyType: IfcObjectTypeEnum , Tag: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.ProxyType = ProxyType;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	ProxyType: IfcObjectTypeEnum ;
	Tag: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcProxy
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let ProxyType = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcProxy(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, ProxyType, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.ProxyType);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcPump {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcPumpTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcPumpTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPump
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPump(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcPumpType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcPumpTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcPumpTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcPumpType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcPumpType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcQuantityArea {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, AreaValue: IfcAreaMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.AreaValue = AreaValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	AreaValue: IfcAreaMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityArea
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let AreaValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityArea(expressID, type, Name, Description, Unit, AreaValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.AreaValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcQuantityCount {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, CountValue: IfcCountMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.CountValue = CountValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	CountValue: IfcCountMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityCount
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let CountValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityCount(expressID, type, Name, Description, Unit, CountValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.CountValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcQuantityLength {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, LengthValue: IfcLengthMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.LengthValue = LengthValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	LengthValue: IfcLengthMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityLength
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let LengthValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityLength(expressID, type, Name, Description, Unit, LengthValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.LengthValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcQuantitySet {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantitySet
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcQuantitySet(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcQuantityTime {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, TimeValue: IfcTimeMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.TimeValue = TimeValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	TimeValue: IfcTimeMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let TimeValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityTime(expressID, type, Name, Description, Unit, TimeValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.TimeValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcQuantityVolume {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, VolumeValue: IfcVolumeMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.VolumeValue = VolumeValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	VolumeValue: IfcVolumeMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityVolume
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let VolumeValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityVolume(expressID, type, Name, Description, Unit, VolumeValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.VolumeValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcQuantityWeight {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null, WeightValue: IfcMassMeasure , Formula: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.WeightValue = WeightValue;
		this.Formula = Formula;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	Unit: (Handle<IfcNamedUnit> | IfcNamedUnit) | null;
	WeightValue: IfcMassMeasure ;
	Formula: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcQuantityWeight
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let WeightValue = tape[ptr++];
		let Formula = tape[ptr++];
		return new IfcQuantityWeight(expressID, type, Name, Description, Unit, WeightValue, Formula);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.WeightValue);;
		args.push(this.Formula);;
		return args;
	}
};
export class IfcRailing {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcRailingTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcRailingTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRailing
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRailing(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRailingType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcRailingTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcRailingTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRailingType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRailingType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRamp {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcRampTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcRampTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRamp
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRamp(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRampFlight {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcRampFlightTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcRampFlightTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRampFlight
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRampFlight(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRampFlightType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcRampFlightTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcRampFlightTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRampFlightType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRampFlightType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRampType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcRampTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcRampTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRampType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRampType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRationalBSplineCurveWithKnots {
	constructor(expressID: number, type: number, Degree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , CurveForm: IfcBSplineCurveForm , ClosedCurve: IfcLogical , SelfIntersect: IfcLogical , KnotMultiplicities: IfcInteger[] , Knots: IfcParameterValue[] , KnotSpec: IfcKnotType , WeightsData: IfcReal[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Degree = Degree;
		this.ControlPointsList = ControlPointsList;
		this.CurveForm = CurveForm;
		this.ClosedCurve = ClosedCurve;
		this.SelfIntersect = SelfIntersect;
		this.KnotMultiplicities = KnotMultiplicities;
		this.Knots = Knots;
		this.KnotSpec = KnotSpec;
		this.WeightsData = WeightsData;
	}
	expressID: number;
	type: number;
	Degree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	CurveForm: IfcBSplineCurveForm ;
	ClosedCurve: IfcLogical ;
	SelfIntersect: IfcLogical ;
	KnotMultiplicities: IfcInteger[] ;
	Knots: IfcParameterValue[] ;
	KnotSpec: IfcKnotType ;
	WeightsData: IfcReal[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRationalBSplineCurveWithKnots
	{
		let ptr = 0;
		let Degree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let CurveForm = tape[ptr++];
		let ClosedCurve = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		let KnotMultiplicities = tape[ptr++];
		let Knots = tape[ptr++];
		let KnotSpec = tape[ptr++];
		let WeightsData = tape[ptr++];
		return new IfcRationalBSplineCurveWithKnots(expressID, type, Degree, ControlPointsList, CurveForm, ClosedCurve, SelfIntersect, KnotMultiplicities, Knots, KnotSpec, WeightsData);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Degree);;
		args.push(this.ControlPointsList);;
		args.push(this.CurveForm);;
		args.push(this.ClosedCurve);;
		args.push(this.SelfIntersect);;
		args.push(this.KnotMultiplicities);;
		args.push(this.Knots);;
		args.push(this.KnotSpec);;
		args.push(this.WeightsData);;
		return args;
	}
};
export class IfcRationalBSplineSurfaceWithKnots {
	constructor(expressID: number, type: number, UDegree: IfcInteger , VDegree: IfcInteger , ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] , SurfaceForm: IfcBSplineSurfaceForm , UClosed: IfcLogical , VClosed: IfcLogical , SelfIntersect: IfcLogical , UMultiplicities: IfcInteger[] , VMultiplicities: IfcInteger[] , UKnots: IfcParameterValue[] , VKnots: IfcParameterValue[] , KnotSpec: IfcKnotType , WeightsData: IfcReal[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.UDegree = UDegree;
		this.VDegree = VDegree;
		this.ControlPointsList = ControlPointsList;
		this.SurfaceForm = SurfaceForm;
		this.UClosed = UClosed;
		this.VClosed = VClosed;
		this.SelfIntersect = SelfIntersect;
		this.UMultiplicities = UMultiplicities;
		this.VMultiplicities = VMultiplicities;
		this.UKnots = UKnots;
		this.VKnots = VKnots;
		this.KnotSpec = KnotSpec;
		this.WeightsData = WeightsData;
	}
	expressID: number;
	type: number;
	UDegree: IfcInteger ;
	VDegree: IfcInteger ;
	ControlPointsList: (Handle<IfcCartesianPoint> | IfcCartesianPoint)[] ;
	SurfaceForm: IfcBSplineSurfaceForm ;
	UClosed: IfcLogical ;
	VClosed: IfcLogical ;
	SelfIntersect: IfcLogical ;
	UMultiplicities: IfcInteger[] ;
	VMultiplicities: IfcInteger[] ;
	UKnots: IfcParameterValue[] ;
	VKnots: IfcParameterValue[] ;
	KnotSpec: IfcKnotType ;
	WeightsData: IfcReal[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRationalBSplineSurfaceWithKnots
	{
		let ptr = 0;
		let UDegree = tape[ptr++];
		let VDegree = tape[ptr++];
		let ControlPointsList = tape[ptr++];
		let SurfaceForm = tape[ptr++];
		let UClosed = tape[ptr++];
		let VClosed = tape[ptr++];
		let SelfIntersect = tape[ptr++];
		let UMultiplicities = tape[ptr++];
		let VMultiplicities = tape[ptr++];
		let UKnots = tape[ptr++];
		let VKnots = tape[ptr++];
		let KnotSpec = tape[ptr++];
		let WeightsData = tape[ptr++];
		return new IfcRationalBSplineSurfaceWithKnots(expressID, type, UDegree, VDegree, ControlPointsList, SurfaceForm, UClosed, VClosed, SelfIntersect, UMultiplicities, VMultiplicities, UKnots, VKnots, KnotSpec, WeightsData);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.UDegree);;
		args.push(this.VDegree);;
		args.push(this.ControlPointsList);;
		args.push(this.SurfaceForm);;
		args.push(this.UClosed);;
		args.push(this.VClosed);;
		args.push(this.SelfIntersect);;
		args.push(this.UMultiplicities);;
		args.push(this.VMultiplicities);;
		args.push(this.UKnots);;
		args.push(this.VKnots);;
		args.push(this.KnotSpec);;
		args.push(this.WeightsData);;
		return args;
	}
};
export class IfcRectangleHollowProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, XDim: IfcPositiveLengthMeasure , YDim: IfcPositiveLengthMeasure , WallThickness: IfcPositiveLengthMeasure , InnerFilletRadius: IfcNonNegativeLengthMeasure | null, OuterFilletRadius: IfcNonNegativeLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.XDim = XDim;
		this.YDim = YDim;
		this.WallThickness = WallThickness;
		this.InnerFilletRadius = InnerFilletRadius;
		this.OuterFilletRadius = OuterFilletRadius;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	XDim: IfcPositiveLengthMeasure ;
	YDim: IfcPositiveLengthMeasure ;
	WallThickness: IfcPositiveLengthMeasure ;
	InnerFilletRadius: IfcNonNegativeLengthMeasure | null;
	OuterFilletRadius: IfcNonNegativeLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRectangleHollowProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let XDim = tape[ptr++];
		let YDim = tape[ptr++];
		let WallThickness = tape[ptr++];
		let InnerFilletRadius = tape[ptr++];
		let OuterFilletRadius = tape[ptr++];
		return new IfcRectangleHollowProfileDef(expressID, type, ProfileType, ProfileName, Position, XDim, YDim, WallThickness, InnerFilletRadius, OuterFilletRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.XDim);;
		args.push(this.YDim);;
		args.push(this.WallThickness);;
		args.push(this.InnerFilletRadius);;
		args.push(this.OuterFilletRadius);;
		return args;
	}
};
export class IfcRectangleProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, XDim: IfcPositiveLengthMeasure , YDim: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.XDim = XDim;
		this.YDim = YDim;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	XDim: IfcPositiveLengthMeasure ;
	YDim: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRectangleProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let XDim = tape[ptr++];
		let YDim = tape[ptr++];
		return new IfcRectangleProfileDef(expressID, type, ProfileType, ProfileName, Position, XDim, YDim);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.XDim);;
		args.push(this.YDim);;
		return args;
	}
};
export class IfcRectangularPyramid {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , XLength: IfcPositiveLengthMeasure , YLength: IfcPositiveLengthMeasure , Height: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.XLength = XLength;
		this.YLength = YLength;
		this.Height = Height;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	XLength: IfcPositiveLengthMeasure ;
	YLength: IfcPositiveLengthMeasure ;
	Height: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRectangularPyramid
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let XLength = tape[ptr++];
		let YLength = tape[ptr++];
		let Height = tape[ptr++];
		return new IfcRectangularPyramid(expressID, type, Position, XLength, YLength, Height);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.XLength);;
		args.push(this.YLength);;
		args.push(this.Height);;
		return args;
	}
};
export class IfcRectangularTrimmedSurface {
	constructor(expressID: number, type: number, BasisSurface: (Handle<IfcSurface> | IfcSurface) , U1: IfcParameterValue , V1: IfcParameterValue , U2: IfcParameterValue , V2: IfcParameterValue , Usense: IfcBoolean , Vsense: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisSurface = BasisSurface;
		this.U1 = U1;
		this.V1 = V1;
		this.U2 = U2;
		this.V2 = V2;
		this.Usense = Usense;
		this.Vsense = Vsense;
	}
	expressID: number;
	type: number;
	BasisSurface: (Handle<IfcSurface> | IfcSurface) ;
	U1: IfcParameterValue ;
	V1: IfcParameterValue ;
	U2: IfcParameterValue ;
	V2: IfcParameterValue ;
	Usense: IfcBoolean ;
	Vsense: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRectangularTrimmedSurface
	{
		let ptr = 0;
		let BasisSurface = tape[ptr++];
		let U1 = tape[ptr++];
		let V1 = tape[ptr++];
		let U2 = tape[ptr++];
		let V2 = tape[ptr++];
		let Usense = tape[ptr++];
		let Vsense = tape[ptr++];
		return new IfcRectangularTrimmedSurface(expressID, type, BasisSurface, U1, V1, U2, V2, Usense, Vsense);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisSurface);;
		args.push(this.U1);;
		args.push(this.V1);;
		args.push(this.U2);;
		args.push(this.V2);;
		args.push(this.Usense);;
		args.push(this.Vsense);;
		return args;
	}
};
export class IfcRecurrencePattern {
	constructor(expressID: number, type: number, RecurrenceType: IfcRecurrenceTypeEnum , DayComponent: IfcDayInMonthNumber[] | null, WeekdayComponent: IfcDayInWeekNumber[] | null, MonthComponent: IfcMonthInYearNumber[] | null, Position: IfcInteger | null, Interval: IfcInteger | null, Occurrences: IfcInteger | null, TimePeriods: (Handle<IfcTimePeriod> | IfcTimePeriod)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.RecurrenceType = RecurrenceType;
		this.DayComponent = DayComponent;
		this.WeekdayComponent = WeekdayComponent;
		this.MonthComponent = MonthComponent;
		this.Position = Position;
		this.Interval = Interval;
		this.Occurrences = Occurrences;
		this.TimePeriods = TimePeriods;
	}
	expressID: number;
	type: number;
	RecurrenceType: IfcRecurrenceTypeEnum ;
	DayComponent: IfcDayInMonthNumber[] | null;
	WeekdayComponent: IfcDayInWeekNumber[] | null;
	MonthComponent: IfcMonthInYearNumber[] | null;
	Position: IfcInteger | null;
	Interval: IfcInteger | null;
	Occurrences: IfcInteger | null;
	TimePeriods: (Handle<IfcTimePeriod> | IfcTimePeriod)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRecurrencePattern
	{
		let ptr = 0;
		let RecurrenceType = tape[ptr++];
		let DayComponent = tape[ptr++];
		let WeekdayComponent = tape[ptr++];
		let MonthComponent = tape[ptr++];
		let Position = tape[ptr++];
		let Interval = tape[ptr++];
		let Occurrences = tape[ptr++];
		let TimePeriods = tape[ptr++];
		return new IfcRecurrencePattern(expressID, type, RecurrenceType, DayComponent, WeekdayComponent, MonthComponent, Position, Interval, Occurrences, TimePeriods);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RecurrenceType);;
		args.push(this.DayComponent);;
		args.push(this.WeekdayComponent);;
		args.push(this.MonthComponent);;
		args.push(this.Position);;
		args.push(this.Interval);;
		args.push(this.Occurrences);;
		args.push(this.TimePeriods);;
		return args;
	}
};
export class IfcReference {
	constructor(expressID: number, type: number, TypeIdentifier: IfcIdentifier | null, AttributeIdentifier: IfcIdentifier | null, InstanceName: IfcLabel | null, ListPositions: IfcInteger[] | null, InnerReference: (Handle<IfcReference> | IfcReference) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.TypeIdentifier = TypeIdentifier;
		this.AttributeIdentifier = AttributeIdentifier;
		this.InstanceName = InstanceName;
		this.ListPositions = ListPositions;
		this.InnerReference = InnerReference;
	}
	expressID: number;
	type: number;
	TypeIdentifier: IfcIdentifier | null;
	AttributeIdentifier: IfcIdentifier | null;
	InstanceName: IfcLabel | null;
	ListPositions: IfcInteger[] | null;
	InnerReference: (Handle<IfcReference> | IfcReference) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReference
	{
		let ptr = 0;
		let TypeIdentifier = tape[ptr++];
		let AttributeIdentifier = tape[ptr++];
		let InstanceName = tape[ptr++];
		let ListPositions = tape[ptr++];
		let InnerReference = tape[ptr++];
		return new IfcReference(expressID, type, TypeIdentifier, AttributeIdentifier, InstanceName, ListPositions, InnerReference);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TypeIdentifier);;
		args.push(this.AttributeIdentifier);;
		args.push(this.InstanceName);;
		args.push(this.ListPositions);;
		args.push(this.InnerReference);;
		return args;
	}
};
export class IfcReferent {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, PredefinedType: IfcReferentTypeEnum | null, RestartDistance: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.PredefinedType = PredefinedType;
		this.RestartDistance = RestartDistance;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	PredefinedType: IfcReferentTypeEnum | null;
	RestartDistance: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReferent
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let RestartDistance = tape[ptr++];
		return new IfcReferent(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, PredefinedType, RestartDistance);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.PredefinedType);;
		args.push(this.RestartDistance);;
		return args;
	}
};
export class IfcRegularTimeSeries {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, StartTime: IfcDateTime , EndTime: IfcDateTime , TimeSeriesDataType: IfcTimeSeriesDataTypeEnum , DataOrigin: IfcDataOriginEnum , UserDefinedDataOrigin: IfcLabel | null, Unit: IfcUnit | null, TimeStep: IfcTimeMeasure , Values: (Handle<IfcTimeSeriesValue> | IfcTimeSeriesValue)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.StartTime = StartTime;
		this.EndTime = EndTime;
		this.TimeSeriesDataType = TimeSeriesDataType;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.Unit = Unit;
		this.TimeStep = TimeStep;
		this.Values = Values;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	StartTime: IfcDateTime ;
	EndTime: IfcDateTime ;
	TimeSeriesDataType: IfcTimeSeriesDataTypeEnum ;
	DataOrigin: IfcDataOriginEnum ;
	UserDefinedDataOrigin: IfcLabel | null;
	Unit: IfcUnit | null;
	TimeStep: IfcTimeMeasure ;
	Values: (Handle<IfcTimeSeriesValue> | IfcTimeSeriesValue)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRegularTimeSeries
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let StartTime = tape[ptr++];
		let EndTime = tape[ptr++];
		let TimeSeriesDataType = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let Unit = tape[ptr++];
		let TimeStep = tape[ptr++];
		let Values = tape[ptr++];
		return new IfcRegularTimeSeries(expressID, type, Name, Description, StartTime, EndTime, TimeSeriesDataType, DataOrigin, UserDefinedDataOrigin, Unit, TimeStep, Values);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.StartTime);;
		args.push(this.EndTime);;
		args.push(this.TimeSeriesDataType);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.Unit);;
		args.push(this.TimeStep);;
		args.push(this.Values);;
		return args;
	}
};
export class IfcReinforcementBarProperties {
	constructor(expressID: number, type: number, TotalCrossSectionArea: IfcAreaMeasure , SteelGrade: IfcLabel , BarSurface: IfcReinforcingBarSurfaceEnum | null, EffectiveDepth: IfcLengthMeasure | null, NominalBarDiameter: IfcPositiveLengthMeasure | null, BarCount: IfcCountMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.TotalCrossSectionArea = TotalCrossSectionArea;
		this.SteelGrade = SteelGrade;
		this.BarSurface = BarSurface;
		this.EffectiveDepth = EffectiveDepth;
		this.NominalBarDiameter = NominalBarDiameter;
		this.BarCount = BarCount;
	}
	expressID: number;
	type: number;
	TotalCrossSectionArea: IfcAreaMeasure ;
	SteelGrade: IfcLabel ;
	BarSurface: IfcReinforcingBarSurfaceEnum | null;
	EffectiveDepth: IfcLengthMeasure | null;
	NominalBarDiameter: IfcPositiveLengthMeasure | null;
	BarCount: IfcCountMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcementBarProperties
	{
		let ptr = 0;
		let TotalCrossSectionArea = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let BarSurface = tape[ptr++];
		let EffectiveDepth = tape[ptr++];
		let NominalBarDiameter = tape[ptr++];
		let BarCount = tape[ptr++];
		return new IfcReinforcementBarProperties(expressID, type, TotalCrossSectionArea, SteelGrade, BarSurface, EffectiveDepth, NominalBarDiameter, BarCount);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TotalCrossSectionArea);;
		args.push(this.SteelGrade);;
		args.push(this.BarSurface);;
		args.push(this.EffectiveDepth);;
		args.push(this.NominalBarDiameter);;
		args.push(this.BarCount);;
		return args;
	}
};
export class IfcReinforcementDefinitionProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, DefinitionType: IfcLabel | null, ReinforcementSectionDefinitions: (Handle<IfcSectionReinforcementProperties> | IfcSectionReinforcementProperties)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.DefinitionType = DefinitionType;
		this.ReinforcementSectionDefinitions = ReinforcementSectionDefinitions;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	DefinitionType: IfcLabel | null;
	ReinforcementSectionDefinitions: (Handle<IfcSectionReinforcementProperties> | IfcSectionReinforcementProperties)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcementDefinitionProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let DefinitionType = tape[ptr++];
		let ReinforcementSectionDefinitions = tape[ptr++];
		return new IfcReinforcementDefinitionProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, DefinitionType, ReinforcementSectionDefinitions);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.DefinitionType);;
		args.push(this.ReinforcementSectionDefinitions);;
		return args;
	}
};
export class IfcReinforcingBar {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null, NominalDiameter: IfcPositiveLengthMeasure | null, CrossSectionArea: IfcAreaMeasure | null, BarLength: IfcPositiveLengthMeasure | null, PredefinedType: IfcReinforcingBarTypeEnum | null, BarSurface: IfcReinforcingBarSurfaceEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
		this.NominalDiameter = NominalDiameter;
		this.CrossSectionArea = CrossSectionArea;
		this.BarLength = BarLength;
		this.PredefinedType = PredefinedType;
		this.BarSurface = BarSurface;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	CrossSectionArea: IfcAreaMeasure | null;
	BarLength: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcReinforcingBarTypeEnum | null;
	BarSurface: IfcReinforcingBarSurfaceEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingBar
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let CrossSectionArea = tape[ptr++];
		let BarLength = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let BarSurface = tape[ptr++];
		return new IfcReinforcingBar(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade, NominalDiameter, CrossSectionArea, BarLength, PredefinedType, BarSurface);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		args.push(this.NominalDiameter);;
		args.push(this.CrossSectionArea);;
		args.push(this.BarLength);;
		args.push(this.PredefinedType);;
		args.push(this.BarSurface);;
		return args;
	}
};
export class IfcReinforcingBarType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcReinforcingBarTypeEnum , NominalDiameter: IfcPositiveLengthMeasure | null, CrossSectionArea: IfcAreaMeasure | null, BarLength: IfcPositiveLengthMeasure | null, BarSurface: IfcReinforcingBarSurfaceEnum | null, BendingShapeCode: IfcLabel | null, BendingParameters: IfcBendingParameterSelect[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.NominalDiameter = NominalDiameter;
		this.CrossSectionArea = CrossSectionArea;
		this.BarLength = BarLength;
		this.BarSurface = BarSurface;
		this.BendingShapeCode = BendingShapeCode;
		this.BendingParameters = BendingParameters;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcReinforcingBarTypeEnum ;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	CrossSectionArea: IfcAreaMeasure | null;
	BarLength: IfcPositiveLengthMeasure | null;
	BarSurface: IfcReinforcingBarSurfaceEnum | null;
	BendingShapeCode: IfcLabel | null;
	BendingParameters: IfcBendingParameterSelect[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingBarType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let CrossSectionArea = tape[ptr++];
		let BarLength = tape[ptr++];
		let BarSurface = tape[ptr++];
		let BendingShapeCode = tape[ptr++];
		let BendingParameters = tape[ptr++];
		return new IfcReinforcingBarType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, NominalDiameter, CrossSectionArea, BarLength, BarSurface, BendingShapeCode, BendingParameters);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.NominalDiameter);;
		args.push(this.CrossSectionArea);;
		args.push(this.BarLength);;
		args.push(this.BarSurface);;
		args.push(this.BendingShapeCode);;
		args.push(this.BendingParameters);;
		return args;
	}
};
export class IfcReinforcingElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		return new IfcReinforcingElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		return args;
	}
};
export class IfcReinforcingElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcReinforcingElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcReinforcingMesh {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null, MeshLength: IfcPositiveLengthMeasure | null, MeshWidth: IfcPositiveLengthMeasure | null, LongitudinalBarNominalDiameter: IfcPositiveLengthMeasure | null, TransverseBarNominalDiameter: IfcPositiveLengthMeasure | null, LongitudinalBarCrossSectionArea: IfcAreaMeasure | null, TransverseBarCrossSectionArea: IfcAreaMeasure | null, LongitudinalBarSpacing: IfcPositiveLengthMeasure | null, TransverseBarSpacing: IfcPositiveLengthMeasure | null, PredefinedType: IfcReinforcingMeshTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
		this.MeshLength = MeshLength;
		this.MeshWidth = MeshWidth;
		this.LongitudinalBarNominalDiameter = LongitudinalBarNominalDiameter;
		this.TransverseBarNominalDiameter = TransverseBarNominalDiameter;
		this.LongitudinalBarCrossSectionArea = LongitudinalBarCrossSectionArea;
		this.TransverseBarCrossSectionArea = TransverseBarCrossSectionArea;
		this.LongitudinalBarSpacing = LongitudinalBarSpacing;
		this.TransverseBarSpacing = TransverseBarSpacing;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	MeshLength: IfcPositiveLengthMeasure | null;
	MeshWidth: IfcPositiveLengthMeasure | null;
	LongitudinalBarNominalDiameter: IfcPositiveLengthMeasure | null;
	TransverseBarNominalDiameter: IfcPositiveLengthMeasure | null;
	LongitudinalBarCrossSectionArea: IfcAreaMeasure | null;
	TransverseBarCrossSectionArea: IfcAreaMeasure | null;
	LongitudinalBarSpacing: IfcPositiveLengthMeasure | null;
	TransverseBarSpacing: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcReinforcingMeshTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingMesh
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let MeshLength = tape[ptr++];
		let MeshWidth = tape[ptr++];
		let LongitudinalBarNominalDiameter = tape[ptr++];
		let TransverseBarNominalDiameter = tape[ptr++];
		let LongitudinalBarCrossSectionArea = tape[ptr++];
		let TransverseBarCrossSectionArea = tape[ptr++];
		let LongitudinalBarSpacing = tape[ptr++];
		let TransverseBarSpacing = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcReinforcingMesh(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade, MeshLength, MeshWidth, LongitudinalBarNominalDiameter, TransverseBarNominalDiameter, LongitudinalBarCrossSectionArea, TransverseBarCrossSectionArea, LongitudinalBarSpacing, TransverseBarSpacing, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		args.push(this.MeshLength);;
		args.push(this.MeshWidth);;
		args.push(this.LongitudinalBarNominalDiameter);;
		args.push(this.TransverseBarNominalDiameter);;
		args.push(this.LongitudinalBarCrossSectionArea);;
		args.push(this.TransverseBarCrossSectionArea);;
		args.push(this.LongitudinalBarSpacing);;
		args.push(this.TransverseBarSpacing);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcReinforcingMeshType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcReinforcingMeshTypeEnum , MeshLength: IfcPositiveLengthMeasure | null, MeshWidth: IfcPositiveLengthMeasure | null, LongitudinalBarNominalDiameter: IfcPositiveLengthMeasure | null, TransverseBarNominalDiameter: IfcPositiveLengthMeasure | null, LongitudinalBarCrossSectionArea: IfcAreaMeasure | null, TransverseBarCrossSectionArea: IfcAreaMeasure | null, LongitudinalBarSpacing: IfcPositiveLengthMeasure | null, TransverseBarSpacing: IfcPositiveLengthMeasure | null, BendingShapeCode: IfcLabel | null, BendingParameters: IfcBendingParameterSelect[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.MeshLength = MeshLength;
		this.MeshWidth = MeshWidth;
		this.LongitudinalBarNominalDiameter = LongitudinalBarNominalDiameter;
		this.TransverseBarNominalDiameter = TransverseBarNominalDiameter;
		this.LongitudinalBarCrossSectionArea = LongitudinalBarCrossSectionArea;
		this.TransverseBarCrossSectionArea = TransverseBarCrossSectionArea;
		this.LongitudinalBarSpacing = LongitudinalBarSpacing;
		this.TransverseBarSpacing = TransverseBarSpacing;
		this.BendingShapeCode = BendingShapeCode;
		this.BendingParameters = BendingParameters;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcReinforcingMeshTypeEnum ;
	MeshLength: IfcPositiveLengthMeasure | null;
	MeshWidth: IfcPositiveLengthMeasure | null;
	LongitudinalBarNominalDiameter: IfcPositiveLengthMeasure | null;
	TransverseBarNominalDiameter: IfcPositiveLengthMeasure | null;
	LongitudinalBarCrossSectionArea: IfcAreaMeasure | null;
	TransverseBarCrossSectionArea: IfcAreaMeasure | null;
	LongitudinalBarSpacing: IfcPositiveLengthMeasure | null;
	TransverseBarSpacing: IfcPositiveLengthMeasure | null;
	BendingShapeCode: IfcLabel | null;
	BendingParameters: IfcBendingParameterSelect[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReinforcingMeshType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let MeshLength = tape[ptr++];
		let MeshWidth = tape[ptr++];
		let LongitudinalBarNominalDiameter = tape[ptr++];
		let TransverseBarNominalDiameter = tape[ptr++];
		let LongitudinalBarCrossSectionArea = tape[ptr++];
		let TransverseBarCrossSectionArea = tape[ptr++];
		let LongitudinalBarSpacing = tape[ptr++];
		let TransverseBarSpacing = tape[ptr++];
		let BendingShapeCode = tape[ptr++];
		let BendingParameters = tape[ptr++];
		return new IfcReinforcingMeshType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, MeshLength, MeshWidth, LongitudinalBarNominalDiameter, TransverseBarNominalDiameter, LongitudinalBarCrossSectionArea, TransverseBarCrossSectionArea, LongitudinalBarSpacing, TransverseBarSpacing, BendingShapeCode, BendingParameters);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.MeshLength);;
		args.push(this.MeshWidth);;
		args.push(this.LongitudinalBarNominalDiameter);;
		args.push(this.TransverseBarNominalDiameter);;
		args.push(this.LongitudinalBarCrossSectionArea);;
		args.push(this.TransverseBarCrossSectionArea);;
		args.push(this.LongitudinalBarSpacing);;
		args.push(this.TransverseBarSpacing);;
		args.push(this.BendingShapeCode);;
		args.push(this.BendingParameters);;
		return args;
	}
};
export class IfcRelAggregates {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingObject: (Handle<IfcObjectDefinition> | IfcObjectDefinition) , RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingObject = RelatingObject;
		this.RelatedObjects = RelatedObjects;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingObject: (Handle<IfcObjectDefinition> | IfcObjectDefinition) ;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAggregates
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingObject = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		return new IfcRelAggregates(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingObject, RelatedObjects);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingObject);;
		args.push(this.RelatedObjects);;
		return args;
	}
};
export class IfcRelAssigns {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssigns
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		return new IfcRelAssigns(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		return args;
	}
};
export class IfcRelAssignsToActor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingActor: (Handle<IfcActor> | IfcActor) , ActingRole: (Handle<IfcActorRole> | IfcActorRole) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingActor = RelatingActor;
		this.ActingRole = ActingRole;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingActor: (Handle<IfcActor> | IfcActor) ;
	ActingRole: (Handle<IfcActorRole> | IfcActorRole) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToActor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingActor = tape[ptr++];
		let ActingRole = tape[ptr++];
		return new IfcRelAssignsToActor(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingActor, ActingRole);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingActor);;
		args.push(this.ActingRole);;
		return args;
	}
};
export class IfcRelAssignsToControl {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingControl: (Handle<IfcControl> | IfcControl) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingControl = RelatingControl;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingControl: (Handle<IfcControl> | IfcControl) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToControl
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingControl = tape[ptr++];
		return new IfcRelAssignsToControl(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingControl);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingControl);;
		return args;
	}
};
export class IfcRelAssignsToGroup {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingGroup: (Handle<IfcGroup> | IfcGroup) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingGroup = RelatingGroup;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingGroup: (Handle<IfcGroup> | IfcGroup) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToGroup
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingGroup = tape[ptr++];
		return new IfcRelAssignsToGroup(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingGroup);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingGroup);;
		return args;
	}
};
export class IfcRelAssignsToGroupByFactor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingGroup: (Handle<IfcGroup> | IfcGroup) , Factor: IfcRatioMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingGroup = RelatingGroup;
		this.Factor = Factor;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingGroup: (Handle<IfcGroup> | IfcGroup) ;
	Factor: IfcRatioMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToGroupByFactor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingGroup = tape[ptr++];
		let Factor = tape[ptr++];
		return new IfcRelAssignsToGroupByFactor(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingGroup, Factor);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingGroup);;
		args.push(this.Factor);;
		return args;
	}
};
export class IfcRelAssignsToProcess {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingProcess: IfcProcessSelect , QuantityInProcess: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingProcess = RelatingProcess;
		this.QuantityInProcess = QuantityInProcess;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingProcess: IfcProcessSelect ;
	QuantityInProcess: (Handle<IfcMeasureWithUnit> | IfcMeasureWithUnit) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToProcess
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingProcess = tape[ptr++];
		let QuantityInProcess = tape[ptr++];
		return new IfcRelAssignsToProcess(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingProcess, QuantityInProcess);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingProcess);;
		args.push(this.QuantityInProcess);;
		return args;
	}
};
export class IfcRelAssignsToProduct {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingProduct: IfcProductSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingProduct = RelatingProduct;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingProduct: IfcProductSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToProduct
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingProduct = tape[ptr++];
		return new IfcRelAssignsToProduct(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingProduct);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingProduct);;
		return args;
	}
};
export class IfcRelAssignsToResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatedObjectsType: IfcObjectTypeEnum | null, RelatingResource: IfcResourceSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatedObjectsType = RelatedObjectsType;
		this.RelatingResource = RelatingResource;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatedObjectsType: IfcObjectTypeEnum | null;
	RelatingResource: IfcResourceSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssignsToResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatedObjectsType = tape[ptr++];
		let RelatingResource = tape[ptr++];
		return new IfcRelAssignsToResource(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatedObjectsType, RelatingResource);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatedObjectsType);;
		args.push(this.RelatingResource);;
		return args;
	}
};
export class IfcRelAssociates {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociates
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		return new IfcRelAssociates(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		return args;
	}
};
export class IfcRelAssociatesApproval {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , RelatingApproval: (Handle<IfcApproval> | IfcApproval) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingApproval = RelatingApproval;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	RelatingApproval: (Handle<IfcApproval> | IfcApproval) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesApproval
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingApproval = tape[ptr++];
		return new IfcRelAssociatesApproval(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingApproval);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingApproval);;
		return args;
	}
};
export class IfcRelAssociatesClassification {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , RelatingClassification: IfcClassificationSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingClassification = RelatingClassification;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	RelatingClassification: IfcClassificationSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesClassification
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingClassification = tape[ptr++];
		return new IfcRelAssociatesClassification(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingClassification);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingClassification);;
		return args;
	}
};
export class IfcRelAssociatesConstraint {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , Intent: IfcLabel | null, RelatingConstraint: (Handle<IfcConstraint> | IfcConstraint) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.Intent = Intent;
		this.RelatingConstraint = RelatingConstraint;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	Intent: IfcLabel | null;
	RelatingConstraint: (Handle<IfcConstraint> | IfcConstraint) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesConstraint
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let Intent = tape[ptr++];
		let RelatingConstraint = tape[ptr++];
		return new IfcRelAssociatesConstraint(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, Intent, RelatingConstraint);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.Intent);;
		args.push(this.RelatingConstraint);;
		return args;
	}
};
export class IfcRelAssociatesDocument {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , RelatingDocument: IfcDocumentSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingDocument = RelatingDocument;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	RelatingDocument: IfcDocumentSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesDocument
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingDocument = tape[ptr++];
		return new IfcRelAssociatesDocument(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingDocument);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingDocument);;
		return args;
	}
};
export class IfcRelAssociatesLibrary {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , RelatingLibrary: IfcLibrarySelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingLibrary = RelatingLibrary;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	RelatingLibrary: IfcLibrarySelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesLibrary
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingLibrary = tape[ptr++];
		return new IfcRelAssociatesLibrary(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingLibrary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingLibrary);;
		return args;
	}
};
export class IfcRelAssociatesMaterial {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: IfcDefinitionSelect[] , RelatingMaterial: IfcMaterialSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingMaterial = RelatingMaterial;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: IfcDefinitionSelect[] ;
	RelatingMaterial: IfcMaterialSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelAssociatesMaterial
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingMaterial = tape[ptr++];
		return new IfcRelAssociatesMaterial(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingMaterial);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingMaterial);;
		return args;
	}
};
export class IfcRelConnects {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnects
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcRelConnects(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcRelConnectsElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, RelatingElement: (Handle<IfcElement> | IfcElement) , RelatedElement: (Handle<IfcElement> | IfcElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ConnectionGeometry = ConnectionGeometry;
		this.RelatingElement = RelatingElement;
		this.RelatedElement = RelatedElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	RelatingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedElement: (Handle<IfcElement> | IfcElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedElement = tape[ptr++];
		return new IfcRelConnectsElements(expressID, type, GlobalId, OwnerHistory, Name, Description, ConnectionGeometry, RelatingElement, RelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConnectionGeometry);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedElement);;
		return args;
	}
};
export class IfcRelConnectsPathElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, RelatingElement: (Handle<IfcElement> | IfcElement) , RelatedElement: (Handle<IfcElement> | IfcElement) , RelatingPriorities: IfcInteger[] , RelatedPriorities: IfcInteger[] , RelatedConnectionType: IfcConnectionTypeEnum , RelatingConnectionType: IfcConnectionTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ConnectionGeometry = ConnectionGeometry;
		this.RelatingElement = RelatingElement;
		this.RelatedElement = RelatedElement;
		this.RelatingPriorities = RelatingPriorities;
		this.RelatedPriorities = RelatedPriorities;
		this.RelatedConnectionType = RelatedConnectionType;
		this.RelatingConnectionType = RelatingConnectionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	RelatingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedElement: (Handle<IfcElement> | IfcElement) ;
	RelatingPriorities: IfcInteger[] ;
	RelatedPriorities: IfcInteger[] ;
	RelatedConnectionType: IfcConnectionTypeEnum ;
	RelatingConnectionType: IfcConnectionTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsPathElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedElement = tape[ptr++];
		let RelatingPriorities = tape[ptr++];
		let RelatedPriorities = tape[ptr++];
		let RelatedConnectionType = tape[ptr++];
		let RelatingConnectionType = tape[ptr++];
		return new IfcRelConnectsPathElements(expressID, type, GlobalId, OwnerHistory, Name, Description, ConnectionGeometry, RelatingElement, RelatedElement, RelatingPriorities, RelatedPriorities, RelatedConnectionType, RelatingConnectionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConnectionGeometry);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedElement);;
		args.push(this.RelatingPriorities);;
		args.push(this.RelatedPriorities);;
		args.push(this.RelatedConnectionType);;
		args.push(this.RelatingConnectionType);;
		return args;
	}
};
export class IfcRelConnectsPortToElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingPort: (Handle<IfcPort> | IfcPort) , RelatedElement: (Handle<IfcDistributionElement> | IfcDistributionElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingPort = RelatingPort;
		this.RelatedElement = RelatedElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingPort: (Handle<IfcPort> | IfcPort) ;
	RelatedElement: (Handle<IfcDistributionElement> | IfcDistributionElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsPortToElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingPort = tape[ptr++];
		let RelatedElement = tape[ptr++];
		return new IfcRelConnectsPortToElement(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingPort, RelatedElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingPort);;
		args.push(this.RelatedElement);;
		return args;
	}
};
export class IfcRelConnectsPorts {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingPort: (Handle<IfcPort> | IfcPort) , RelatedPort: (Handle<IfcPort> | IfcPort) , RealizingElement: (Handle<IfcElement> | IfcElement) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingPort = RelatingPort;
		this.RelatedPort = RelatedPort;
		this.RealizingElement = RealizingElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingPort: (Handle<IfcPort> | IfcPort) ;
	RelatedPort: (Handle<IfcPort> | IfcPort) ;
	RealizingElement: (Handle<IfcElement> | IfcElement) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsPorts
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingPort = tape[ptr++];
		let RelatedPort = tape[ptr++];
		let RealizingElement = tape[ptr++];
		return new IfcRelConnectsPorts(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingPort, RelatedPort, RealizingElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingPort);;
		args.push(this.RelatedPort);;
		args.push(this.RealizingElement);;
		return args;
	}
};
export class IfcRelConnectsStructuralActivity {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingElement: IfcStructuralActivityAssignmentSelect , RelatedStructuralActivity: (Handle<IfcStructuralActivity> | IfcStructuralActivity) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingElement = RelatingElement;
		this.RelatedStructuralActivity = RelatedStructuralActivity;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingElement: IfcStructuralActivityAssignmentSelect ;
	RelatedStructuralActivity: (Handle<IfcStructuralActivity> | IfcStructuralActivity) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsStructuralActivity
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedStructuralActivity = tape[ptr++];
		return new IfcRelConnectsStructuralActivity(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingElement, RelatedStructuralActivity);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedStructuralActivity);;
		return args;
	}
};
export class IfcRelConnectsStructuralMember {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingStructuralMember: (Handle<IfcStructuralMember> | IfcStructuralMember) , RelatedStructuralConnection: (Handle<IfcStructuralConnection> | IfcStructuralConnection) , AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null, AdditionalConditions: (Handle<IfcStructuralConnectionCondition> | IfcStructuralConnectionCondition) | null, SupportedLength: IfcLengthMeasure | null, ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingStructuralMember = RelatingStructuralMember;
		this.RelatedStructuralConnection = RelatedStructuralConnection;
		this.AppliedCondition = AppliedCondition;
		this.AdditionalConditions = AdditionalConditions;
		this.SupportedLength = SupportedLength;
		this.ConditionCoordinateSystem = ConditionCoordinateSystem;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingStructuralMember: (Handle<IfcStructuralMember> | IfcStructuralMember) ;
	RelatedStructuralConnection: (Handle<IfcStructuralConnection> | IfcStructuralConnection) ;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	AdditionalConditions: (Handle<IfcStructuralConnectionCondition> | IfcStructuralConnectionCondition) | null;
	SupportedLength: IfcLengthMeasure | null;
	ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsStructuralMember
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingStructuralMember = tape[ptr++];
		let RelatedStructuralConnection = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		let AdditionalConditions = tape[ptr++];
		let SupportedLength = tape[ptr++];
		let ConditionCoordinateSystem = tape[ptr++];
		return new IfcRelConnectsStructuralMember(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingStructuralMember, RelatedStructuralConnection, AppliedCondition, AdditionalConditions, SupportedLength, ConditionCoordinateSystem);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingStructuralMember);;
		args.push(this.RelatedStructuralConnection);;
		args.push(this.AppliedCondition);;
		args.push(this.AdditionalConditions);;
		args.push(this.SupportedLength);;
		args.push(this.ConditionCoordinateSystem);;
		return args;
	}
};
export class IfcRelConnectsWithEccentricity {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingStructuralMember: (Handle<IfcStructuralMember> | IfcStructuralMember) , RelatedStructuralConnection: (Handle<IfcStructuralConnection> | IfcStructuralConnection) , AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null, AdditionalConditions: (Handle<IfcStructuralConnectionCondition> | IfcStructuralConnectionCondition) | null, SupportedLength: IfcLengthMeasure | null, ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, ConnectionConstraint: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingStructuralMember = RelatingStructuralMember;
		this.RelatedStructuralConnection = RelatedStructuralConnection;
		this.AppliedCondition = AppliedCondition;
		this.AdditionalConditions = AdditionalConditions;
		this.SupportedLength = SupportedLength;
		this.ConditionCoordinateSystem = ConditionCoordinateSystem;
		this.ConnectionConstraint = ConnectionConstraint;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingStructuralMember: (Handle<IfcStructuralMember> | IfcStructuralMember) ;
	RelatedStructuralConnection: (Handle<IfcStructuralConnection> | IfcStructuralConnection) ;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	AdditionalConditions: (Handle<IfcStructuralConnectionCondition> | IfcStructuralConnectionCondition) | null;
	SupportedLength: IfcLengthMeasure | null;
	ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	ConnectionConstraint: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsWithEccentricity
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingStructuralMember = tape[ptr++];
		let RelatedStructuralConnection = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		let AdditionalConditions = tape[ptr++];
		let SupportedLength = tape[ptr++];
		let ConditionCoordinateSystem = tape[ptr++];
		let ConnectionConstraint = tape[ptr++];
		return new IfcRelConnectsWithEccentricity(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingStructuralMember, RelatedStructuralConnection, AppliedCondition, AdditionalConditions, SupportedLength, ConditionCoordinateSystem, ConnectionConstraint);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingStructuralMember);;
		args.push(this.RelatedStructuralConnection);;
		args.push(this.AppliedCondition);;
		args.push(this.AdditionalConditions);;
		args.push(this.SupportedLength);;
		args.push(this.ConditionCoordinateSystem);;
		args.push(this.ConnectionConstraint);;
		return args;
	}
};
export class IfcRelConnectsWithRealizingElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, RelatingElement: (Handle<IfcElement> | IfcElement) , RelatedElement: (Handle<IfcElement> | IfcElement) , RealizingElements: (Handle<IfcElement> | IfcElement)[] , ConnectionType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ConnectionGeometry = ConnectionGeometry;
		this.RelatingElement = RelatingElement;
		this.RelatedElement = RelatedElement;
		this.RealizingElements = RealizingElements;
		this.ConnectionType = ConnectionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	RelatingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedElement: (Handle<IfcElement> | IfcElement) ;
	RealizingElements: (Handle<IfcElement> | IfcElement)[] ;
	ConnectionType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelConnectsWithRealizingElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedElement = tape[ptr++];
		let RealizingElements = tape[ptr++];
		let ConnectionType = tape[ptr++];
		return new IfcRelConnectsWithRealizingElements(expressID, type, GlobalId, OwnerHistory, Name, Description, ConnectionGeometry, RelatingElement, RelatedElement, RealizingElements, ConnectionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ConnectionGeometry);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedElement);;
		args.push(this.RealizingElements);;
		args.push(this.ConnectionType);;
		return args;
	}
};
export class IfcRelContainedInSpatialStructure {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedElements: (Handle<IfcProduct> | IfcProduct)[] , RelatingStructure: (Handle<IfcSpatialElement> | IfcSpatialElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedElements = RelatedElements;
		this.RelatingStructure = RelatingStructure;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedElements: (Handle<IfcProduct> | IfcProduct)[] ;
	RelatingStructure: (Handle<IfcSpatialElement> | IfcSpatialElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelContainedInSpatialStructure
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedElements = tape[ptr++];
		let RelatingStructure = tape[ptr++];
		return new IfcRelContainedInSpatialStructure(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedElements, RelatingStructure);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedElements);;
		args.push(this.RelatingStructure);;
		return args;
	}
};
export class IfcRelCoversBldgElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingBuildingElement: (Handle<IfcElement> | IfcElement) , RelatedCoverings: (Handle<IfcCovering> | IfcCovering)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingBuildingElement = RelatingBuildingElement;
		this.RelatedCoverings = RelatedCoverings;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingBuildingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedCoverings: (Handle<IfcCovering> | IfcCovering)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelCoversBldgElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingBuildingElement = tape[ptr++];
		let RelatedCoverings = tape[ptr++];
		return new IfcRelCoversBldgElements(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingBuildingElement, RelatedCoverings);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingBuildingElement);;
		args.push(this.RelatedCoverings);;
		return args;
	}
};
export class IfcRelCoversSpaces {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingSpace: (Handle<IfcSpace> | IfcSpace) , RelatedCoverings: (Handle<IfcCovering> | IfcCovering)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingSpace = RelatingSpace;
		this.RelatedCoverings = RelatedCoverings;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingSpace: (Handle<IfcSpace> | IfcSpace) ;
	RelatedCoverings: (Handle<IfcCovering> | IfcCovering)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelCoversSpaces
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingSpace = tape[ptr++];
		let RelatedCoverings = tape[ptr++];
		return new IfcRelCoversSpaces(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingSpace, RelatedCoverings);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingSpace);;
		args.push(this.RelatedCoverings);;
		return args;
	}
};
export class IfcRelDeclares {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingContext: (Handle<IfcContext> | IfcContext) , RelatedDefinitions: IfcDefinitionSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingContext = RelatingContext;
		this.RelatedDefinitions = RelatedDefinitions;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingContext: (Handle<IfcContext> | IfcContext) ;
	RelatedDefinitions: IfcDefinitionSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDeclares
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingContext = tape[ptr++];
		let RelatedDefinitions = tape[ptr++];
		return new IfcRelDeclares(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingContext, RelatedDefinitions);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingContext);;
		args.push(this.RelatedDefinitions);;
		return args;
	}
};
export class IfcRelDecomposes {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDecomposes
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcRelDecomposes(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcRelDefines {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDefines
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcRelDefines(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcRelDefinesByObject {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObject> | IfcObject)[] , RelatingObject: (Handle<IfcObject> | IfcObject) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingObject = RelatingObject;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObject> | IfcObject)[] ;
	RelatingObject: (Handle<IfcObject> | IfcObject) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDefinesByObject
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingObject = tape[ptr++];
		return new IfcRelDefinesByObject(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingObject);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingObject);;
		return args;
	}
};
export class IfcRelDefinesByProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] , RelatingPropertyDefinition: IfcPropertySetDefinitionSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingPropertyDefinition = RelatingPropertyDefinition;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	RelatingPropertyDefinition: IfcPropertySetDefinitionSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDefinesByProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingPropertyDefinition = tape[ptr++];
		return new IfcRelDefinesByProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingPropertyDefinition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingPropertyDefinition);;
		return args;
	}
};
export class IfcRelDefinesByTemplate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] , RelatingTemplate: (Handle<IfcPropertySetTemplate> | IfcPropertySetTemplate) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedPropertySets = RelatedPropertySets;
		this.RelatingTemplate = RelatingTemplate;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] ;
	RelatingTemplate: (Handle<IfcPropertySetTemplate> | IfcPropertySetTemplate) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDefinesByTemplate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedPropertySets = tape[ptr++];
		let RelatingTemplate = tape[ptr++];
		return new IfcRelDefinesByTemplate(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedPropertySets, RelatingTemplate);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedPropertySets);;
		args.push(this.RelatingTemplate);;
		return args;
	}
};
export class IfcRelDefinesByType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedObjects: (Handle<IfcObject> | IfcObject)[] , RelatingType: (Handle<IfcTypeObject> | IfcTypeObject) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedObjects = RelatedObjects;
		this.RelatingType = RelatingType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedObjects: (Handle<IfcObject> | IfcObject)[] ;
	RelatingType: (Handle<IfcTypeObject> | IfcTypeObject) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelDefinesByType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		let RelatingType = tape[ptr++];
		return new IfcRelDefinesByType(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedObjects, RelatingType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedObjects);;
		args.push(this.RelatingType);;
		return args;
	}
};
export class IfcRelFillsElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingOpeningElement: (Handle<IfcOpeningElement> | IfcOpeningElement) , RelatedBuildingElement: (Handle<IfcElement> | IfcElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingOpeningElement = RelatingOpeningElement;
		this.RelatedBuildingElement = RelatedBuildingElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingOpeningElement: (Handle<IfcOpeningElement> | IfcOpeningElement) ;
	RelatedBuildingElement: (Handle<IfcElement> | IfcElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelFillsElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingOpeningElement = tape[ptr++];
		let RelatedBuildingElement = tape[ptr++];
		return new IfcRelFillsElement(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingOpeningElement, RelatedBuildingElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingOpeningElement);;
		args.push(this.RelatedBuildingElement);;
		return args;
	}
};
export class IfcRelFlowControlElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedControlElements: (Handle<IfcDistributionControlElement> | IfcDistributionControlElement)[] , RelatingFlowElement: (Handle<IfcDistributionFlowElement> | IfcDistributionFlowElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedControlElements = RelatedControlElements;
		this.RelatingFlowElement = RelatingFlowElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedControlElements: (Handle<IfcDistributionControlElement> | IfcDistributionControlElement)[] ;
	RelatingFlowElement: (Handle<IfcDistributionFlowElement> | IfcDistributionFlowElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelFlowControlElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedControlElements = tape[ptr++];
		let RelatingFlowElement = tape[ptr++];
		return new IfcRelFlowControlElements(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedControlElements, RelatingFlowElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedControlElements);;
		args.push(this.RelatingFlowElement);;
		return args;
	}
};
export class IfcRelInterferesElements {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingElement: (Handle<IfcElement> | IfcElement) , RelatedElement: (Handle<IfcElement> | IfcElement) , InterferenceGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, InterferenceType: IfcIdentifier | null, ImpliedOrder: boolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingElement = RelatingElement;
		this.RelatedElement = RelatedElement;
		this.InterferenceGeometry = InterferenceGeometry;
		this.InterferenceType = InterferenceType;
		this.ImpliedOrder = ImpliedOrder;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedElement: (Handle<IfcElement> | IfcElement) ;
	InterferenceGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	InterferenceType: IfcIdentifier | null;
	ImpliedOrder: boolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelInterferesElements
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedElement = tape[ptr++];
		let InterferenceGeometry = tape[ptr++];
		let InterferenceType = tape[ptr++];
		let ImpliedOrder = tape[ptr++];
		return new IfcRelInterferesElements(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingElement, RelatedElement, InterferenceGeometry, InterferenceType, ImpliedOrder);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedElement);;
		args.push(this.InterferenceGeometry);;
		args.push(this.InterferenceType);;
		args.push(this.ImpliedOrder);;
		return args;
	}
};
export class IfcRelNests {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingObject: (Handle<IfcObjectDefinition> | IfcObjectDefinition) , RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingObject = RelatingObject;
		this.RelatedObjects = RelatedObjects;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingObject: (Handle<IfcObjectDefinition> | IfcObjectDefinition) ;
	RelatedObjects: (Handle<IfcObjectDefinition> | IfcObjectDefinition)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelNests
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingObject = tape[ptr++];
		let RelatedObjects = tape[ptr++];
		return new IfcRelNests(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingObject, RelatedObjects);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingObject);;
		args.push(this.RelatedObjects);;
		return args;
	}
};
export class IfcRelPositions {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingPositioningElement: (Handle<IfcPositioningElement> | IfcPositioningElement) , RelatedProducts: (Handle<IfcProduct> | IfcProduct)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingPositioningElement = RelatingPositioningElement;
		this.RelatedProducts = RelatedProducts;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingPositioningElement: (Handle<IfcPositioningElement> | IfcPositioningElement) ;
	RelatedProducts: (Handle<IfcProduct> | IfcProduct)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelPositions
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingPositioningElement = tape[ptr++];
		let RelatedProducts = tape[ptr++];
		return new IfcRelPositions(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingPositioningElement, RelatedProducts);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingPositioningElement);;
		args.push(this.RelatedProducts);;
		return args;
	}
};
export class IfcRelProjectsElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingElement: (Handle<IfcElement> | IfcElement) , RelatedFeatureElement: (Handle<IfcFeatureElementAddition> | IfcFeatureElementAddition) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingElement = RelatingElement;
		this.RelatedFeatureElement = RelatedFeatureElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedFeatureElement: (Handle<IfcFeatureElementAddition> | IfcFeatureElementAddition) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelProjectsElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingElement = tape[ptr++];
		let RelatedFeatureElement = tape[ptr++];
		return new IfcRelProjectsElement(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingElement, RelatedFeatureElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingElement);;
		args.push(this.RelatedFeatureElement);;
		return args;
	}
};
export class IfcRelReferencedInSpatialStructure {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatedElements: (Handle<IfcProduct> | IfcProduct)[] , RelatingStructure: (Handle<IfcSpatialElement> | IfcSpatialElement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatedElements = RelatedElements;
		this.RelatingStructure = RelatingStructure;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedElements: (Handle<IfcProduct> | IfcProduct)[] ;
	RelatingStructure: (Handle<IfcSpatialElement> | IfcSpatialElement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelReferencedInSpatialStructure
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedElements = tape[ptr++];
		let RelatingStructure = tape[ptr++];
		return new IfcRelReferencedInSpatialStructure(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatedElements, RelatingStructure);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedElements);;
		args.push(this.RelatingStructure);;
		return args;
	}
};
export class IfcRelSequence {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingProcess: (Handle<IfcProcess> | IfcProcess) , RelatedProcess: (Handle<IfcProcess> | IfcProcess) , TimeLag: (Handle<IfcLagTime> | IfcLagTime) | null, SequenceType: IfcSequenceEnum | null, UserDefinedSequenceType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingProcess = RelatingProcess;
		this.RelatedProcess = RelatedProcess;
		this.TimeLag = TimeLag;
		this.SequenceType = SequenceType;
		this.UserDefinedSequenceType = UserDefinedSequenceType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingProcess: (Handle<IfcProcess> | IfcProcess) ;
	RelatedProcess: (Handle<IfcProcess> | IfcProcess) ;
	TimeLag: (Handle<IfcLagTime> | IfcLagTime) | null;
	SequenceType: IfcSequenceEnum | null;
	UserDefinedSequenceType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelSequence
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingProcess = tape[ptr++];
		let RelatedProcess = tape[ptr++];
		let TimeLag = tape[ptr++];
		let SequenceType = tape[ptr++];
		let UserDefinedSequenceType = tape[ptr++];
		return new IfcRelSequence(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingProcess, RelatedProcess, TimeLag, SequenceType, UserDefinedSequenceType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingProcess);;
		args.push(this.RelatedProcess);;
		args.push(this.TimeLag);;
		args.push(this.SequenceType);;
		args.push(this.UserDefinedSequenceType);;
		return args;
	}
};
export class IfcRelServicesBuildings {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingSystem: (Handle<IfcSystem> | IfcSystem) , RelatedBuildings: (Handle<IfcSpatialElement> | IfcSpatialElement)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingSystem = RelatingSystem;
		this.RelatedBuildings = RelatedBuildings;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingSystem: (Handle<IfcSystem> | IfcSystem) ;
	RelatedBuildings: (Handle<IfcSpatialElement> | IfcSpatialElement)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelServicesBuildings
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingSystem = tape[ptr++];
		let RelatedBuildings = tape[ptr++];
		return new IfcRelServicesBuildings(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingSystem, RelatedBuildings);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingSystem);;
		args.push(this.RelatedBuildings);;
		return args;
	}
};
export class IfcRelSpaceBoundary {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingSpace: IfcSpaceBoundarySelect , RelatedBuildingElement: (Handle<IfcElement> | IfcElement) , ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum , InternalOrExternalBoundary: IfcInternalOrExternalEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingSpace = RelatingSpace;
		this.RelatedBuildingElement = RelatedBuildingElement;
		this.ConnectionGeometry = ConnectionGeometry;
		this.PhysicalOrVirtualBoundary = PhysicalOrVirtualBoundary;
		this.InternalOrExternalBoundary = InternalOrExternalBoundary;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingSpace: IfcSpaceBoundarySelect ;
	RelatedBuildingElement: (Handle<IfcElement> | IfcElement) ;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum ;
	InternalOrExternalBoundary: IfcInternalOrExternalEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelSpaceBoundary
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingSpace = tape[ptr++];
		let RelatedBuildingElement = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let PhysicalOrVirtualBoundary = tape[ptr++];
		let InternalOrExternalBoundary = tape[ptr++];
		return new IfcRelSpaceBoundary(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingSpace, RelatedBuildingElement, ConnectionGeometry, PhysicalOrVirtualBoundary, InternalOrExternalBoundary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingSpace);;
		args.push(this.RelatedBuildingElement);;
		args.push(this.ConnectionGeometry);;
		args.push(this.PhysicalOrVirtualBoundary);;
		args.push(this.InternalOrExternalBoundary);;
		return args;
	}
};
export class IfcRelSpaceBoundary1stLevel {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingSpace: IfcSpaceBoundarySelect , RelatedBuildingElement: (Handle<IfcElement> | IfcElement) , ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum , InternalOrExternalBoundary: IfcInternalOrExternalEnum , ParentBoundary: (Handle<IfcRelSpaceBoundary1stLevel> | IfcRelSpaceBoundary1stLevel) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingSpace = RelatingSpace;
		this.RelatedBuildingElement = RelatedBuildingElement;
		this.ConnectionGeometry = ConnectionGeometry;
		this.PhysicalOrVirtualBoundary = PhysicalOrVirtualBoundary;
		this.InternalOrExternalBoundary = InternalOrExternalBoundary;
		this.ParentBoundary = ParentBoundary;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingSpace: IfcSpaceBoundarySelect ;
	RelatedBuildingElement: (Handle<IfcElement> | IfcElement) ;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum ;
	InternalOrExternalBoundary: IfcInternalOrExternalEnum ;
	ParentBoundary: (Handle<IfcRelSpaceBoundary1stLevel> | IfcRelSpaceBoundary1stLevel) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelSpaceBoundary1stLevel
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingSpace = tape[ptr++];
		let RelatedBuildingElement = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let PhysicalOrVirtualBoundary = tape[ptr++];
		let InternalOrExternalBoundary = tape[ptr++];
		let ParentBoundary = tape[ptr++];
		return new IfcRelSpaceBoundary1stLevel(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingSpace, RelatedBuildingElement, ConnectionGeometry, PhysicalOrVirtualBoundary, InternalOrExternalBoundary, ParentBoundary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingSpace);;
		args.push(this.RelatedBuildingElement);;
		args.push(this.ConnectionGeometry);;
		args.push(this.PhysicalOrVirtualBoundary);;
		args.push(this.InternalOrExternalBoundary);;
		args.push(this.ParentBoundary);;
		return args;
	}
};
export class IfcRelSpaceBoundary2ndLevel {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingSpace: IfcSpaceBoundarySelect , RelatedBuildingElement: (Handle<IfcElement> | IfcElement) , ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null, PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum , InternalOrExternalBoundary: IfcInternalOrExternalEnum , ParentBoundary: (Handle<IfcRelSpaceBoundary1stLevel> | IfcRelSpaceBoundary1stLevel) | null, CorrespondingBoundary: (Handle<IfcRelSpaceBoundary2ndLevel> | IfcRelSpaceBoundary2ndLevel) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingSpace = RelatingSpace;
		this.RelatedBuildingElement = RelatedBuildingElement;
		this.ConnectionGeometry = ConnectionGeometry;
		this.PhysicalOrVirtualBoundary = PhysicalOrVirtualBoundary;
		this.InternalOrExternalBoundary = InternalOrExternalBoundary;
		this.ParentBoundary = ParentBoundary;
		this.CorrespondingBoundary = CorrespondingBoundary;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingSpace: IfcSpaceBoundarySelect ;
	RelatedBuildingElement: (Handle<IfcElement> | IfcElement) ;
	ConnectionGeometry: (Handle<IfcConnectionGeometry> | IfcConnectionGeometry) | null;
	PhysicalOrVirtualBoundary: IfcPhysicalOrVirtualEnum ;
	InternalOrExternalBoundary: IfcInternalOrExternalEnum ;
	ParentBoundary: (Handle<IfcRelSpaceBoundary1stLevel> | IfcRelSpaceBoundary1stLevel) | null;
	CorrespondingBoundary: (Handle<IfcRelSpaceBoundary2ndLevel> | IfcRelSpaceBoundary2ndLevel) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelSpaceBoundary2ndLevel
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingSpace = tape[ptr++];
		let RelatedBuildingElement = tape[ptr++];
		let ConnectionGeometry = tape[ptr++];
		let PhysicalOrVirtualBoundary = tape[ptr++];
		let InternalOrExternalBoundary = tape[ptr++];
		let ParentBoundary = tape[ptr++];
		let CorrespondingBoundary = tape[ptr++];
		return new IfcRelSpaceBoundary2ndLevel(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingSpace, RelatedBuildingElement, ConnectionGeometry, PhysicalOrVirtualBoundary, InternalOrExternalBoundary, ParentBoundary, CorrespondingBoundary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingSpace);;
		args.push(this.RelatedBuildingElement);;
		args.push(this.ConnectionGeometry);;
		args.push(this.PhysicalOrVirtualBoundary);;
		args.push(this.InternalOrExternalBoundary);;
		args.push(this.ParentBoundary);;
		args.push(this.CorrespondingBoundary);;
		return args;
	}
};
export class IfcRelVoidsElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, RelatingBuildingElement: (Handle<IfcElement> | IfcElement) , RelatedOpeningElement: (Handle<IfcFeatureElementSubtraction> | IfcFeatureElementSubtraction) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.RelatingBuildingElement = RelatingBuildingElement;
		this.RelatedOpeningElement = RelatedOpeningElement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingBuildingElement: (Handle<IfcElement> | IfcElement) ;
	RelatedOpeningElement: (Handle<IfcFeatureElementSubtraction> | IfcFeatureElementSubtraction) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelVoidsElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingBuildingElement = tape[ptr++];
		let RelatedOpeningElement = tape[ptr++];
		return new IfcRelVoidsElement(expressID, type, GlobalId, OwnerHistory, Name, Description, RelatingBuildingElement, RelatedOpeningElement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingBuildingElement);;
		args.push(this.RelatedOpeningElement);;
		return args;
	}
};
export class IfcRelationship {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRelationship
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcRelationship(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcReparametrisedCompositeCurveSegment {
	constructor(expressID: number, type: number, Transition: IfcTransitionCode , SameSense: IfcBoolean , ParentCurve: (Handle<IfcCurve> | IfcCurve) , ParamLength: IfcParameterValue )
	{
		this.expressID = expressID;
		this.type = type;
		this.Transition = Transition;
		this.SameSense = SameSense;
		this.ParentCurve = ParentCurve;
		this.ParamLength = ParamLength;
	}
	expressID: number;
	type: number;
	Transition: IfcTransitionCode ;
	SameSense: IfcBoolean ;
	ParentCurve: (Handle<IfcCurve> | IfcCurve) ;
	ParamLength: IfcParameterValue ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcReparametrisedCompositeCurveSegment
	{
		let ptr = 0;
		let Transition = tape[ptr++];
		let SameSense = tape[ptr++];
		let ParentCurve = tape[ptr++];
		let ParamLength = tape[ptr++];
		return new IfcReparametrisedCompositeCurveSegment(expressID, type, Transition, SameSense, ParentCurve, ParamLength);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Transition);;
		args.push(this.SameSense);;
		args.push(this.ParentCurve);;
		args.push(this.ParamLength);;
		return args;
	}
};
export class IfcRepresentation {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRepresentation
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcRepresentation(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcRepresentationContext {
	constructor(expressID: number, type: number, ContextIdentifier: IfcLabel | null, ContextType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextIdentifier = ContextIdentifier;
		this.ContextType = ContextType;
	}
	expressID: number;
	type: number;
	ContextIdentifier: IfcLabel | null;
	ContextType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRepresentationContext
	{
		let ptr = 0;
		let ContextIdentifier = tape[ptr++];
		let ContextType = tape[ptr++];
		return new IfcRepresentationContext(expressID, type, ContextIdentifier, ContextType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextIdentifier);;
		args.push(this.ContextType);;
		return args;
	}
};
export class IfcRepresentationItem {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRepresentationItem
	{
		let ptr = 0;
		return new IfcRepresentationItem(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcRepresentationMap {
	constructor(expressID: number, type: number, MappingOrigin: IfcAxis2Placement , MappedRepresentation: (Handle<IfcRepresentation> | IfcRepresentation) )
	{
		this.expressID = expressID;
		this.type = type;
		this.MappingOrigin = MappingOrigin;
		this.MappedRepresentation = MappedRepresentation;
	}
	expressID: number;
	type: number;
	MappingOrigin: IfcAxis2Placement ;
	MappedRepresentation: (Handle<IfcRepresentation> | IfcRepresentation) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRepresentationMap
	{
		let ptr = 0;
		let MappingOrigin = tape[ptr++];
		let MappedRepresentation = tape[ptr++];
		return new IfcRepresentationMap(expressID, type, MappingOrigin, MappedRepresentation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.MappingOrigin);;
		args.push(this.MappedRepresentation);;
		return args;
	}
};
export class IfcResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		return new IfcResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		return args;
	}
};
export class IfcResourceApprovalRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatedResourceObjects: IfcResourceObjectSelect[] , RelatingApproval: (Handle<IfcApproval> | IfcApproval) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatedResourceObjects = RelatedResourceObjects;
		this.RelatingApproval = RelatingApproval;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatedResourceObjects: IfcResourceObjectSelect[] ;
	RelatingApproval: (Handle<IfcApproval> | IfcApproval) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcResourceApprovalRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatedResourceObjects = tape[ptr++];
		let RelatingApproval = tape[ptr++];
		return new IfcResourceApprovalRelationship(expressID, type, Name, Description, RelatedResourceObjects, RelatingApproval);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatedResourceObjects);;
		args.push(this.RelatingApproval);;
		return args;
	}
};
export class IfcResourceConstraintRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null, RelatingConstraint: (Handle<IfcConstraint> | IfcConstraint) , RelatedResourceObjects: IfcResourceObjectSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.RelatingConstraint = RelatingConstraint;
		this.RelatedResourceObjects = RelatedResourceObjects;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	RelatingConstraint: (Handle<IfcConstraint> | IfcConstraint) ;
	RelatedResourceObjects: IfcResourceObjectSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcResourceConstraintRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let RelatingConstraint = tape[ptr++];
		let RelatedResourceObjects = tape[ptr++];
		return new IfcResourceConstraintRelationship(expressID, type, Name, Description, RelatingConstraint, RelatedResourceObjects);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.RelatingConstraint);;
		args.push(this.RelatedResourceObjects);;
		return args;
	}
};
export class IfcResourceLevelRelationship {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcResourceLevelRelationship
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcResourceLevelRelationship(expressID, type, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcResourceTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, ScheduleWork: IfcDuration | null, ScheduleUsage: IfcPositiveRatioMeasure | null, ScheduleStart: IfcDateTime | null, ScheduleFinish: IfcDateTime | null, ScheduleContour: IfcLabel | null, LevelingDelay: IfcDuration | null, IsOverAllocated: IfcBoolean | null, StatusTime: IfcDateTime | null, ActualWork: IfcDuration | null, ActualUsage: IfcPositiveRatioMeasure | null, ActualStart: IfcDateTime | null, ActualFinish: IfcDateTime | null, RemainingWork: IfcDuration | null, RemainingUsage: IfcPositiveRatioMeasure | null, Completion: IfcPositiveRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.ScheduleWork = ScheduleWork;
		this.ScheduleUsage = ScheduleUsage;
		this.ScheduleStart = ScheduleStart;
		this.ScheduleFinish = ScheduleFinish;
		this.ScheduleContour = ScheduleContour;
		this.LevelingDelay = LevelingDelay;
		this.IsOverAllocated = IsOverAllocated;
		this.StatusTime = StatusTime;
		this.ActualWork = ActualWork;
		this.ActualUsage = ActualUsage;
		this.ActualStart = ActualStart;
		this.ActualFinish = ActualFinish;
		this.RemainingWork = RemainingWork;
		this.RemainingUsage = RemainingUsage;
		this.Completion = Completion;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	ScheduleWork: IfcDuration | null;
	ScheduleUsage: IfcPositiveRatioMeasure | null;
	ScheduleStart: IfcDateTime | null;
	ScheduleFinish: IfcDateTime | null;
	ScheduleContour: IfcLabel | null;
	LevelingDelay: IfcDuration | null;
	IsOverAllocated: IfcBoolean | null;
	StatusTime: IfcDateTime | null;
	ActualWork: IfcDuration | null;
	ActualUsage: IfcPositiveRatioMeasure | null;
	ActualStart: IfcDateTime | null;
	ActualFinish: IfcDateTime | null;
	RemainingWork: IfcDuration | null;
	RemainingUsage: IfcPositiveRatioMeasure | null;
	Completion: IfcPositiveRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcResourceTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let ScheduleWork = tape[ptr++];
		let ScheduleUsage = tape[ptr++];
		let ScheduleStart = tape[ptr++];
		let ScheduleFinish = tape[ptr++];
		let ScheduleContour = tape[ptr++];
		let LevelingDelay = tape[ptr++];
		let IsOverAllocated = tape[ptr++];
		let StatusTime = tape[ptr++];
		let ActualWork = tape[ptr++];
		let ActualUsage = tape[ptr++];
		let ActualStart = tape[ptr++];
		let ActualFinish = tape[ptr++];
		let RemainingWork = tape[ptr++];
		let RemainingUsage = tape[ptr++];
		let Completion = tape[ptr++];
		return new IfcResourceTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, ScheduleWork, ScheduleUsage, ScheduleStart, ScheduleFinish, ScheduleContour, LevelingDelay, IsOverAllocated, StatusTime, ActualWork, ActualUsage, ActualStart, ActualFinish, RemainingWork, RemainingUsage, Completion);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.ScheduleWork);;
		args.push(this.ScheduleUsage);;
		args.push(this.ScheduleStart);;
		args.push(this.ScheduleFinish);;
		args.push(this.ScheduleContour);;
		args.push(this.LevelingDelay);;
		args.push(this.IsOverAllocated);;
		args.push(this.StatusTime);;
		args.push(this.ActualWork);;
		args.push(this.ActualUsage);;
		args.push(this.ActualStart);;
		args.push(this.ActualFinish);;
		args.push(this.RemainingWork);;
		args.push(this.RemainingUsage);;
		args.push(this.Completion);;
		return args;
	}
};
export class IfcRevolvedAreaSolid {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, Axis: (Handle<IfcAxis1Placement> | IfcAxis1Placement) , Angle: IfcPlaneAngleMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.Axis = Axis;
		this.Angle = Angle;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	Axis: (Handle<IfcAxis1Placement> | IfcAxis1Placement) ;
	Angle: IfcPlaneAngleMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRevolvedAreaSolid
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let Axis = tape[ptr++];
		let Angle = tape[ptr++];
		return new IfcRevolvedAreaSolid(expressID, type, SweptArea, Position, Axis, Angle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.Axis);;
		args.push(this.Angle);;
		return args;
	}
};
export class IfcRevolvedAreaSolidTapered {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, Axis: (Handle<IfcAxis1Placement> | IfcAxis1Placement) , Angle: IfcPlaneAngleMeasure , EndSweptArea: (Handle<IfcProfileDef> | IfcProfileDef) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.Axis = Axis;
		this.Angle = Angle;
		this.EndSweptArea = EndSweptArea;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	Axis: (Handle<IfcAxis1Placement> | IfcAxis1Placement) ;
	Angle: IfcPlaneAngleMeasure ;
	EndSweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRevolvedAreaSolidTapered
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let Axis = tape[ptr++];
		let Angle = tape[ptr++];
		let EndSweptArea = tape[ptr++];
		return new IfcRevolvedAreaSolidTapered(expressID, type, SweptArea, Position, Axis, Angle, EndSweptArea);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.Axis);;
		args.push(this.Angle);;
		args.push(this.EndSweptArea);;
		return args;
	}
};
export class IfcRightCircularCone {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , Height: IfcPositiveLengthMeasure , BottomRadius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Height = Height;
		this.BottomRadius = BottomRadius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	Height: IfcPositiveLengthMeasure ;
	BottomRadius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRightCircularCone
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Height = tape[ptr++];
		let BottomRadius = tape[ptr++];
		return new IfcRightCircularCone(expressID, type, Position, Height, BottomRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Height);;
		args.push(this.BottomRadius);;
		return args;
	}
};
export class IfcRightCircularCylinder {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , Height: IfcPositiveLengthMeasure , Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Height = Height;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	Height: IfcPositiveLengthMeasure ;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRightCircularCylinder
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Height = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcRightCircularCylinder(expressID, type, Position, Height, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Height);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcRoof {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcRoofTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcRoofTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRoof
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRoof(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRoofType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcRoofTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcRoofTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRoofType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcRoofType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcRoot {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRoot
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcRoot(expressID, type, GlobalId, OwnerHistory, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcRoundedRectangleProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, XDim: IfcPositiveLengthMeasure , YDim: IfcPositiveLengthMeasure , RoundingRadius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.XDim = XDim;
		this.YDim = YDim;
		this.RoundingRadius = RoundingRadius;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	XDim: IfcPositiveLengthMeasure ;
	YDim: IfcPositiveLengthMeasure ;
	RoundingRadius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcRoundedRectangleProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let XDim = tape[ptr++];
		let YDim = tape[ptr++];
		let RoundingRadius = tape[ptr++];
		return new IfcRoundedRectangleProfileDef(expressID, type, ProfileType, ProfileName, Position, XDim, YDim, RoundingRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.XDim);;
		args.push(this.YDim);;
		args.push(this.RoundingRadius);;
		return args;
	}
};
export class IfcSIUnit {
	constructor(expressID: number, type: number, Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) , UnitType: IfcUnitEnum , Prefix: IfcSIPrefix | null, Name: IfcSIUnitName )
	{
		this.expressID = expressID;
		this.type = type;
		this.Dimensions = Dimensions;
		this.UnitType = UnitType;
		this.Prefix = Prefix;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Dimensions: (Handle<IfcDimensionalExponents> | IfcDimensionalExponents) ;
	UnitType: IfcUnitEnum ;
	Prefix: IfcSIPrefix | null;
	Name: IfcSIUnitName ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSIUnit
	{
		let ptr = 0;
		let Dimensions = tape[ptr++];
		let UnitType = tape[ptr++];
		let Prefix = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcSIUnit(expressID, type, Dimensions, UnitType, Prefix, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Dimensions);;
		args.push(this.UnitType);;
		args.push(this.Prefix);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcSanitaryTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSanitaryTerminalTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSanitaryTerminalTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSanitaryTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSanitaryTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSanitaryTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSanitaryTerminalTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSanitaryTerminalTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSanitaryTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSanitaryTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSchedulingTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSchedulingTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		return new IfcSchedulingTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		return args;
	}
};
export class IfcSeamCurve {
	constructor(expressID: number, type: number, Curve3D: (Handle<IfcCurve> | IfcCurve) , AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] , MasterRepresentation: IfcPreferredSurfaceCurveRepresentation )
	{
		this.expressID = expressID;
		this.type = type;
		this.Curve3D = Curve3D;
		this.AssociatedGeometry = AssociatedGeometry;
		this.MasterRepresentation = MasterRepresentation;
	}
	expressID: number;
	type: number;
	Curve3D: (Handle<IfcCurve> | IfcCurve) ;
	AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] ;
	MasterRepresentation: IfcPreferredSurfaceCurveRepresentation ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSeamCurve
	{
		let ptr = 0;
		let Curve3D = tape[ptr++];
		let AssociatedGeometry = tape[ptr++];
		let MasterRepresentation = tape[ptr++];
		return new IfcSeamCurve(expressID, type, Curve3D, AssociatedGeometry, MasterRepresentation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Curve3D);;
		args.push(this.AssociatedGeometry);;
		args.push(this.MasterRepresentation);;
		return args;
	}
};
export class IfcSectionProperties {
	constructor(expressID: number, type: number, SectionType: IfcSectionTypeEnum , StartProfile: (Handle<IfcProfileDef> | IfcProfileDef) , EndProfile: (Handle<IfcProfileDef> | IfcProfileDef) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SectionType = SectionType;
		this.StartProfile = StartProfile;
		this.EndProfile = EndProfile;
	}
	expressID: number;
	type: number;
	SectionType: IfcSectionTypeEnum ;
	StartProfile: (Handle<IfcProfileDef> | IfcProfileDef) ;
	EndProfile: (Handle<IfcProfileDef> | IfcProfileDef) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSectionProperties
	{
		let ptr = 0;
		let SectionType = tape[ptr++];
		let StartProfile = tape[ptr++];
		let EndProfile = tape[ptr++];
		return new IfcSectionProperties(expressID, type, SectionType, StartProfile, EndProfile);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SectionType);;
		args.push(this.StartProfile);;
		args.push(this.EndProfile);;
		return args;
	}
};
export class IfcSectionReinforcementProperties {
	constructor(expressID: number, type: number, LongitudinalStartPosition: IfcLengthMeasure , LongitudinalEndPosition: IfcLengthMeasure , TransversePosition: IfcLengthMeasure | null, ReinforcementRole: IfcReinforcingBarRoleEnum , SectionDefinition: (Handle<IfcSectionProperties> | IfcSectionProperties) , CrossSectionReinforcementDefinitions: (Handle<IfcReinforcementBarProperties> | IfcReinforcementBarProperties)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.LongitudinalStartPosition = LongitudinalStartPosition;
		this.LongitudinalEndPosition = LongitudinalEndPosition;
		this.TransversePosition = TransversePosition;
		this.ReinforcementRole = ReinforcementRole;
		this.SectionDefinition = SectionDefinition;
		this.CrossSectionReinforcementDefinitions = CrossSectionReinforcementDefinitions;
	}
	expressID: number;
	type: number;
	LongitudinalStartPosition: IfcLengthMeasure ;
	LongitudinalEndPosition: IfcLengthMeasure ;
	TransversePosition: IfcLengthMeasure | null;
	ReinforcementRole: IfcReinforcingBarRoleEnum ;
	SectionDefinition: (Handle<IfcSectionProperties> | IfcSectionProperties) ;
	CrossSectionReinforcementDefinitions: (Handle<IfcReinforcementBarProperties> | IfcReinforcementBarProperties)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSectionReinforcementProperties
	{
		let ptr = 0;
		let LongitudinalStartPosition = tape[ptr++];
		let LongitudinalEndPosition = tape[ptr++];
		let TransversePosition = tape[ptr++];
		let ReinforcementRole = tape[ptr++];
		let SectionDefinition = tape[ptr++];
		let CrossSectionReinforcementDefinitions = tape[ptr++];
		return new IfcSectionReinforcementProperties(expressID, type, LongitudinalStartPosition, LongitudinalEndPosition, TransversePosition, ReinforcementRole, SectionDefinition, CrossSectionReinforcementDefinitions);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.LongitudinalStartPosition);;
		args.push(this.LongitudinalEndPosition);;
		args.push(this.TransversePosition);;
		args.push(this.ReinforcementRole);;
		args.push(this.SectionDefinition);;
		args.push(this.CrossSectionReinforcementDefinitions);;
		return args;
	}
};
export class IfcSectionedSolid {
	constructor(expressID: number, type: number, Directrix: (Handle<IfcCurve> | IfcCurve) , CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Directrix = Directrix;
		this.CrossSections = CrossSections;
	}
	expressID: number;
	type: number;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSectionedSolid
	{
		let ptr = 0;
		let Directrix = tape[ptr++];
		let CrossSections = tape[ptr++];
		return new IfcSectionedSolid(expressID, type, Directrix, CrossSections);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Directrix);;
		args.push(this.CrossSections);;
		return args;
	}
};
export class IfcSectionedSolidHorizontal {
	constructor(expressID: number, type: number, Directrix: (Handle<IfcCurve> | IfcCurve) , CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] , CrossSectionPositions: (Handle<IfcDistanceExpression> | IfcDistanceExpression)[] , FixedAxisVertical: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.Directrix = Directrix;
		this.CrossSections = CrossSections;
		this.CrossSectionPositions = CrossSectionPositions;
		this.FixedAxisVertical = FixedAxisVertical;
	}
	expressID: number;
	type: number;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] ;
	CrossSectionPositions: (Handle<IfcDistanceExpression> | IfcDistanceExpression)[] ;
	FixedAxisVertical: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSectionedSolidHorizontal
	{
		let ptr = 0;
		let Directrix = tape[ptr++];
		let CrossSections = tape[ptr++];
		let CrossSectionPositions = tape[ptr++];
		let FixedAxisVertical = tape[ptr++];
		return new IfcSectionedSolidHorizontal(expressID, type, Directrix, CrossSections, CrossSectionPositions, FixedAxisVertical);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Directrix);;
		args.push(this.CrossSections);;
		args.push(this.CrossSectionPositions);;
		args.push(this.FixedAxisVertical);;
		return args;
	}
};
export class IfcSectionedSpine {
	constructor(expressID: number, type: number, SpineCurve: (Handle<IfcCompositeCurve> | IfcCompositeCurve) , CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] , CrossSectionPositions: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.SpineCurve = SpineCurve;
		this.CrossSections = CrossSections;
		this.CrossSectionPositions = CrossSectionPositions;
	}
	expressID: number;
	type: number;
	SpineCurve: (Handle<IfcCompositeCurve> | IfcCompositeCurve) ;
	CrossSections: (Handle<IfcProfileDef> | IfcProfileDef)[] ;
	CrossSectionPositions: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSectionedSpine
	{
		let ptr = 0;
		let SpineCurve = tape[ptr++];
		let CrossSections = tape[ptr++];
		let CrossSectionPositions = tape[ptr++];
		return new IfcSectionedSpine(expressID, type, SpineCurve, CrossSections, CrossSectionPositions);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SpineCurve);;
		args.push(this.CrossSections);;
		args.push(this.CrossSectionPositions);;
		return args;
	}
};
export class IfcSensor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSensorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSensorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSensor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSensor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSensorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSensorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSensorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSensorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSensorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcShadingDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcShadingDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcShadingDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShadingDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcShadingDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcShadingDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcShadingDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcShadingDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShadingDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcShadingDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcShapeAspect {
	constructor(expressID: number, type: number, ShapeRepresentations: (Handle<IfcShapeModel> | IfcShapeModel)[] , Name: IfcLabel | null, Description: IfcText | null, ProductDefinitional: IfcLogical , PartOfProductDefinitionShape: IfcProductRepresentationSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ShapeRepresentations = ShapeRepresentations;
		this.Name = Name;
		this.Description = Description;
		this.ProductDefinitional = ProductDefinitional;
		this.PartOfProductDefinitionShape = PartOfProductDefinitionShape;
	}
	expressID: number;
	type: number;
	ShapeRepresentations: (Handle<IfcShapeModel> | IfcShapeModel)[] ;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ProductDefinitional: IfcLogical ;
	PartOfProductDefinitionShape: IfcProductRepresentationSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShapeAspect
	{
		let ptr = 0;
		let ShapeRepresentations = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ProductDefinitional = tape[ptr++];
		let PartOfProductDefinitionShape = tape[ptr++];
		return new IfcShapeAspect(expressID, type, ShapeRepresentations, Name, Description, ProductDefinitional, PartOfProductDefinitionShape);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ShapeRepresentations);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ProductDefinitional);;
		args.push(this.PartOfProductDefinitionShape);;
		return args;
	}
};
export class IfcShapeModel {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShapeModel
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcShapeModel(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcShapeRepresentation {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShapeRepresentation
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcShapeRepresentation(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcShellBasedSurfaceModel {
	constructor(expressID: number, type: number, SbsmBoundary: IfcShell[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.SbsmBoundary = SbsmBoundary;
	}
	expressID: number;
	type: number;
	SbsmBoundary: IfcShell[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcShellBasedSurfaceModel
	{
		let ptr = 0;
		let SbsmBoundary = tape[ptr++];
		return new IfcShellBasedSurfaceModel(expressID, type, SbsmBoundary);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SbsmBoundary);;
		return args;
	}
};
export class IfcSimpleProperty {
	constructor(expressID: number, type: number, Name: IfcIdentifier , Description: IfcText | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
	}
	expressID: number;
	type: number;
	Name: IfcIdentifier ;
	Description: IfcText | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSimpleProperty
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		return new IfcSimpleProperty(expressID, type, Name, Description);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		return args;
	}
};
export class IfcSimplePropertyTemplate {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, TemplateType: IfcSimplePropertyTemplateTypeEnum | null, PrimaryMeasureType: IfcLabel | null, SecondaryMeasureType: IfcLabel | null, Enumerators: (Handle<IfcPropertyEnumeration> | IfcPropertyEnumeration) | null, PrimaryUnit: IfcUnit | null, SecondaryUnit: IfcUnit | null, Expression: IfcLabel | null, AccessState: IfcStateEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.TemplateType = TemplateType;
		this.PrimaryMeasureType = PrimaryMeasureType;
		this.SecondaryMeasureType = SecondaryMeasureType;
		this.Enumerators = Enumerators;
		this.PrimaryUnit = PrimaryUnit;
		this.SecondaryUnit = SecondaryUnit;
		this.Expression = Expression;
		this.AccessState = AccessState;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	TemplateType: IfcSimplePropertyTemplateTypeEnum | null;
	PrimaryMeasureType: IfcLabel | null;
	SecondaryMeasureType: IfcLabel | null;
	Enumerators: (Handle<IfcPropertyEnumeration> | IfcPropertyEnumeration) | null;
	PrimaryUnit: IfcUnit | null;
	SecondaryUnit: IfcUnit | null;
	Expression: IfcLabel | null;
	AccessState: IfcStateEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSimplePropertyTemplate
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let TemplateType = tape[ptr++];
		let PrimaryMeasureType = tape[ptr++];
		let SecondaryMeasureType = tape[ptr++];
		let Enumerators = tape[ptr++];
		let PrimaryUnit = tape[ptr++];
		let SecondaryUnit = tape[ptr++];
		let Expression = tape[ptr++];
		let AccessState = tape[ptr++];
		return new IfcSimplePropertyTemplate(expressID, type, GlobalId, OwnerHistory, Name, Description, TemplateType, PrimaryMeasureType, SecondaryMeasureType, Enumerators, PrimaryUnit, SecondaryUnit, Expression, AccessState);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.TemplateType);;
		args.push(this.PrimaryMeasureType);;
		args.push(this.SecondaryMeasureType);;
		args.push(this.Enumerators);;
		args.push(this.PrimaryUnit);;
		args.push(this.SecondaryUnit);;
		args.push(this.Expression);;
		args.push(this.AccessState);;
		return args;
	}
};
export class IfcSite {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, RefLatitude: IfcCompoundPlaneAngleMeasure | null, RefLongitude: IfcCompoundPlaneAngleMeasure | null, RefElevation: IfcLengthMeasure | null, LandTitleNumber: IfcLabel | null, SiteAddress: (Handle<IfcPostalAddress> | IfcPostalAddress) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.RefLatitude = RefLatitude;
		this.RefLongitude = RefLongitude;
		this.RefElevation = RefElevation;
		this.LandTitleNumber = LandTitleNumber;
		this.SiteAddress = SiteAddress;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	RefLatitude: IfcCompoundPlaneAngleMeasure | null;
	RefLongitude: IfcCompoundPlaneAngleMeasure | null;
	RefElevation: IfcLengthMeasure | null;
	LandTitleNumber: IfcLabel | null;
	SiteAddress: (Handle<IfcPostalAddress> | IfcPostalAddress) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSite
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let RefLatitude = tape[ptr++];
		let RefLongitude = tape[ptr++];
		let RefElevation = tape[ptr++];
		let LandTitleNumber = tape[ptr++];
		let SiteAddress = tape[ptr++];
		return new IfcSite(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, RefLatitude, RefLongitude, RefElevation, LandTitleNumber, SiteAddress);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.RefLatitude);;
		args.push(this.RefLongitude);;
		args.push(this.RefElevation);;
		args.push(this.LandTitleNumber);;
		args.push(this.SiteAddress);;
		return args;
	}
};
export class IfcSlab {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSlabTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSlabTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSlab
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSlab(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSlabElementedCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSlabTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSlabTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSlabElementedCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSlabElementedCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSlabStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSlabTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSlabTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSlabStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSlabStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSlabType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSlabTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSlabTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSlabType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSlabType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSlippageConnectionCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null, SlippageX: IfcLengthMeasure | null, SlippageY: IfcLengthMeasure | null, SlippageZ: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.SlippageX = SlippageX;
		this.SlippageY = SlippageY;
		this.SlippageZ = SlippageZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	SlippageX: IfcLengthMeasure | null;
	SlippageY: IfcLengthMeasure | null;
	SlippageZ: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSlippageConnectionCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let SlippageX = tape[ptr++];
		let SlippageY = tape[ptr++];
		let SlippageZ = tape[ptr++];
		return new IfcSlippageConnectionCondition(expressID, type, Name, SlippageX, SlippageY, SlippageZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.SlippageX);;
		args.push(this.SlippageY);;
		args.push(this.SlippageZ);;
		return args;
	}
};
export class IfcSolarDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSolarDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSolarDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSolarDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSolarDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSolarDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSolarDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSolarDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSolarDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSolarDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSolidModel {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSolidModel
	{
		let ptr = 0;
		return new IfcSolidModel(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcSpace {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null, PredefinedType: IfcSpaceTypeEnum | null, ElevationWithFlooring: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
		this.PredefinedType = PredefinedType;
		this.ElevationWithFlooring = ElevationWithFlooring;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	PredefinedType: IfcSpaceTypeEnum | null;
	ElevationWithFlooring: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpace
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let ElevationWithFlooring = tape[ptr++];
		return new IfcSpace(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType, PredefinedType, ElevationWithFlooring);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		args.push(this.PredefinedType);;
		args.push(this.ElevationWithFlooring);;
		return args;
	}
};
export class IfcSpaceHeater {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSpaceHeaterTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSpaceHeaterTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpaceHeater
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSpaceHeater(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSpaceHeaterType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSpaceHeaterTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSpaceHeaterTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpaceHeaterType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSpaceHeaterType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSpaceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSpaceTypeEnum , LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSpaceTypeEnum ;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpaceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcSpaceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.LongName);;
		return args;
	}
};
export class IfcSpatialElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcSpatialElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		return args;
	}
};
export class IfcSpatialElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcSpatialElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcSpatialStructureElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, CompositionType: IfcElementCompositionEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.CompositionType = CompositionType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	CompositionType: IfcElementCompositionEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialStructureElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let CompositionType = tape[ptr++];
		return new IfcSpatialStructureElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, CompositionType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.CompositionType);;
		return args;
	}
};
export class IfcSpatialStructureElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialStructureElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		return new IfcSpatialStructureElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		return args;
	}
};
export class IfcSpatialZone {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, LongName: IfcLabel | null, PredefinedType: IfcSpatialZoneTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.LongName = LongName;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	LongName: IfcLabel | null;
	PredefinedType: IfcSpatialZoneTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialZone
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let LongName = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSpatialZone(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, LongName, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.LongName);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSpatialZoneType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSpatialZoneTypeEnum , LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSpatialZoneTypeEnum ;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSpatialZoneType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcSpatialZoneType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.LongName);;
		return args;
	}
};
export class IfcSphere {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSphere
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcSphere(expressID, type, Position, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcSphericalSurface {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , Radius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.Radius = Radius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	Radius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSphericalSurface
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let Radius = tape[ptr++];
		return new IfcSphericalSurface(expressID, type, Position, Radius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.Radius);;
		return args;
	}
};
export class IfcStackTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcStackTerminalTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcStackTerminalTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStackTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStackTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStackTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcStackTerminalTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcStackTerminalTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStackTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStackTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStair {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcStairTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcStairTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStair
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStair(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStairFlight {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, NumberOfRisers: IfcInteger | null, NumberOfTreads: IfcInteger | null, RiserHeight: IfcPositiveLengthMeasure | null, TreadLength: IfcPositiveLengthMeasure | null, PredefinedType: IfcStairFlightTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.NumberOfRisers = NumberOfRisers;
		this.NumberOfTreads = NumberOfTreads;
		this.RiserHeight = RiserHeight;
		this.TreadLength = TreadLength;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	NumberOfRisers: IfcInteger | null;
	NumberOfTreads: IfcInteger | null;
	RiserHeight: IfcPositiveLengthMeasure | null;
	TreadLength: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcStairFlightTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStairFlight
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let NumberOfRisers = tape[ptr++];
		let NumberOfTreads = tape[ptr++];
		let RiserHeight = tape[ptr++];
		let TreadLength = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStairFlight(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, NumberOfRisers, NumberOfTreads, RiserHeight, TreadLength, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.NumberOfRisers);;
		args.push(this.NumberOfTreads);;
		args.push(this.RiserHeight);;
		args.push(this.TreadLength);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStairFlightType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcStairFlightTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcStairFlightTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStairFlightType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStairFlightType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStairType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcStairTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcStairTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStairType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStairType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		return new IfcStructuralAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		return args;
	}
};
export class IfcStructuralActivity {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralActivity
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		return new IfcStructuralActivity(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		return args;
	}
};
export class IfcStructuralAnalysisModel {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, PredefinedType: IfcAnalysisModelTypeEnum , OrientationOf2DPlane: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, LoadedBy: (Handle<IfcStructuralLoadGroup> | IfcStructuralLoadGroup)[] | null, HasResults: (Handle<IfcStructuralResultGroup> | IfcStructuralResultGroup)[] | null, SharedPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.PredefinedType = PredefinedType;
		this.OrientationOf2DPlane = OrientationOf2DPlane;
		this.LoadedBy = LoadedBy;
		this.HasResults = HasResults;
		this.SharedPlacement = SharedPlacement;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	PredefinedType: IfcAnalysisModelTypeEnum ;
	OrientationOf2DPlane: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	LoadedBy: (Handle<IfcStructuralLoadGroup> | IfcStructuralLoadGroup)[] | null;
	HasResults: (Handle<IfcStructuralResultGroup> | IfcStructuralResultGroup)[] | null;
	SharedPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralAnalysisModel
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let OrientationOf2DPlane = tape[ptr++];
		let LoadedBy = tape[ptr++];
		let HasResults = tape[ptr++];
		let SharedPlacement = tape[ptr++];
		return new IfcStructuralAnalysisModel(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, PredefinedType, OrientationOf2DPlane, LoadedBy, HasResults, SharedPlacement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.PredefinedType);;
		args.push(this.OrientationOf2DPlane);;
		args.push(this.LoadedBy);;
		args.push(this.HasResults);;
		args.push(this.SharedPlacement);;
		return args;
	}
};
export class IfcStructuralConnection {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedCondition = AppliedCondition;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralConnection
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		return new IfcStructuralConnection(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedCondition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedCondition);;
		return args;
	}
};
export class IfcStructuralConnectionCondition {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralConnectionCondition
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcStructuralConnectionCondition(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcStructuralCurveAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null, ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null, PredefinedType: IfcStructuralCurveActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
		this.ProjectedOrTrue = ProjectedOrTrue;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null;
	PredefinedType: IfcStructuralCurveActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralCurveAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		let ProjectedOrTrue = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralCurveAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad, ProjectedOrTrue, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		args.push(this.ProjectedOrTrue);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralCurveConnection {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null, Axis: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedCondition = AppliedCondition;
		this.Axis = Axis;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	Axis: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralCurveConnection
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		let Axis = tape[ptr++];
		return new IfcStructuralCurveConnection(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedCondition, Axis);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedCondition);;
		args.push(this.Axis);;
		return args;
	}
};
export class IfcStructuralCurveMember {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, PredefinedType: IfcStructuralCurveMemberTypeEnum , Axis: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.PredefinedType = PredefinedType;
		this.Axis = Axis;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	PredefinedType: IfcStructuralCurveMemberTypeEnum ;
	Axis: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralCurveMember
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Axis = tape[ptr++];
		return new IfcStructuralCurveMember(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, PredefinedType, Axis);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.PredefinedType);;
		args.push(this.Axis);;
		return args;
	}
};
export class IfcStructuralCurveMemberVarying {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, PredefinedType: IfcStructuralCurveMemberTypeEnum , Axis: (Handle<IfcDirection> | IfcDirection) )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.PredefinedType = PredefinedType;
		this.Axis = Axis;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	PredefinedType: IfcStructuralCurveMemberTypeEnum ;
	Axis: (Handle<IfcDirection> | IfcDirection) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralCurveMemberVarying
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Axis = tape[ptr++];
		return new IfcStructuralCurveMemberVarying(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, PredefinedType, Axis);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.PredefinedType);;
		args.push(this.Axis);;
		return args;
	}
};
export class IfcStructuralCurveReaction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , PredefinedType: IfcStructuralCurveActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	PredefinedType: IfcStructuralCurveActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralCurveReaction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralCurveReaction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralItem {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralItem
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcStructuralItem(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcStructuralLinearAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null, ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null, PredefinedType: IfcStructuralCurveActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
		this.ProjectedOrTrue = ProjectedOrTrue;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null;
	PredefinedType: IfcStructuralCurveActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLinearAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		let ProjectedOrTrue = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralLinearAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad, ProjectedOrTrue, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		args.push(this.ProjectedOrTrue);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralLoad {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoad
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcStructuralLoad(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcStructuralLoadCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, PredefinedType: IfcLoadGroupTypeEnum , ActionType: IfcActionTypeEnum , ActionSource: IfcActionSourceTypeEnum , Coefficient: IfcRatioMeasure | null, Purpose: IfcLabel | null, SelfWeightCoefficients: IfcRatioMeasure[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.PredefinedType = PredefinedType;
		this.ActionType = ActionType;
		this.ActionSource = ActionSource;
		this.Coefficient = Coefficient;
		this.Purpose = Purpose;
		this.SelfWeightCoefficients = SelfWeightCoefficients;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	PredefinedType: IfcLoadGroupTypeEnum ;
	ActionType: IfcActionTypeEnum ;
	ActionSource: IfcActionSourceTypeEnum ;
	Coefficient: IfcRatioMeasure | null;
	Purpose: IfcLabel | null;
	SelfWeightCoefficients: IfcRatioMeasure[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let ActionType = tape[ptr++];
		let ActionSource = tape[ptr++];
		let Coefficient = tape[ptr++];
		let Purpose = tape[ptr++];
		let SelfWeightCoefficients = tape[ptr++];
		return new IfcStructuralLoadCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, PredefinedType, ActionType, ActionSource, Coefficient, Purpose, SelfWeightCoefficients);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.PredefinedType);;
		args.push(this.ActionType);;
		args.push(this.ActionSource);;
		args.push(this.Coefficient);;
		args.push(this.Purpose);;
		args.push(this.SelfWeightCoefficients);;
		return args;
	}
};
export class IfcStructuralLoadConfiguration {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Values: (Handle<IfcStructuralLoadOrResult> | IfcStructuralLoadOrResult)[] , Locations: IfcLengthMeasure[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Values = Values;
		this.Locations = Locations;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Values: (Handle<IfcStructuralLoadOrResult> | IfcStructuralLoadOrResult)[] ;
	Locations: IfcLengthMeasure[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadConfiguration
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Values = tape[ptr++];
		let Locations = tape[ptr++];
		return new IfcStructuralLoadConfiguration(expressID, type, Name, Values, Locations);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Values);;
		args.push(this.Locations);;
		return args;
	}
};
export class IfcStructuralLoadGroup {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, PredefinedType: IfcLoadGroupTypeEnum , ActionType: IfcActionTypeEnum , ActionSource: IfcActionSourceTypeEnum , Coefficient: IfcRatioMeasure | null, Purpose: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.PredefinedType = PredefinedType;
		this.ActionType = ActionType;
		this.ActionSource = ActionSource;
		this.Coefficient = Coefficient;
		this.Purpose = Purpose;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	PredefinedType: IfcLoadGroupTypeEnum ;
	ActionType: IfcActionTypeEnum ;
	ActionSource: IfcActionSourceTypeEnum ;
	Coefficient: IfcRatioMeasure | null;
	Purpose: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadGroup
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let ActionType = tape[ptr++];
		let ActionSource = tape[ptr++];
		let Coefficient = tape[ptr++];
		let Purpose = tape[ptr++];
		return new IfcStructuralLoadGroup(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, PredefinedType, ActionType, ActionSource, Coefficient, Purpose);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.PredefinedType);;
		args.push(this.ActionType);;
		args.push(this.ActionSource);;
		args.push(this.Coefficient);;
		args.push(this.Purpose);;
		return args;
	}
};
export class IfcStructuralLoadLinearForce {
	constructor(expressID: number, type: number, Name: IfcLabel | null, LinearForceX: IfcLinearForceMeasure | null, LinearForceY: IfcLinearForceMeasure | null, LinearForceZ: IfcLinearForceMeasure | null, LinearMomentX: IfcLinearMomentMeasure | null, LinearMomentY: IfcLinearMomentMeasure | null, LinearMomentZ: IfcLinearMomentMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.LinearForceX = LinearForceX;
		this.LinearForceY = LinearForceY;
		this.LinearForceZ = LinearForceZ;
		this.LinearMomentX = LinearMomentX;
		this.LinearMomentY = LinearMomentY;
		this.LinearMomentZ = LinearMomentZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	LinearForceX: IfcLinearForceMeasure | null;
	LinearForceY: IfcLinearForceMeasure | null;
	LinearForceZ: IfcLinearForceMeasure | null;
	LinearMomentX: IfcLinearMomentMeasure | null;
	LinearMomentY: IfcLinearMomentMeasure | null;
	LinearMomentZ: IfcLinearMomentMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadLinearForce
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let LinearForceX = tape[ptr++];
		let LinearForceY = tape[ptr++];
		let LinearForceZ = tape[ptr++];
		let LinearMomentX = tape[ptr++];
		let LinearMomentY = tape[ptr++];
		let LinearMomentZ = tape[ptr++];
		return new IfcStructuralLoadLinearForce(expressID, type, Name, LinearForceX, LinearForceY, LinearForceZ, LinearMomentX, LinearMomentY, LinearMomentZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.LinearForceX);;
		args.push(this.LinearForceY);;
		args.push(this.LinearForceZ);;
		args.push(this.LinearMomentX);;
		args.push(this.LinearMomentY);;
		args.push(this.LinearMomentZ);;
		return args;
	}
};
export class IfcStructuralLoadOrResult {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadOrResult
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcStructuralLoadOrResult(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcStructuralLoadPlanarForce {
	constructor(expressID: number, type: number, Name: IfcLabel | null, PlanarForceX: IfcPlanarForceMeasure | null, PlanarForceY: IfcPlanarForceMeasure | null, PlanarForceZ: IfcPlanarForceMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.PlanarForceX = PlanarForceX;
		this.PlanarForceY = PlanarForceY;
		this.PlanarForceZ = PlanarForceZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	PlanarForceX: IfcPlanarForceMeasure | null;
	PlanarForceY: IfcPlanarForceMeasure | null;
	PlanarForceZ: IfcPlanarForceMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadPlanarForce
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let PlanarForceX = tape[ptr++];
		let PlanarForceY = tape[ptr++];
		let PlanarForceZ = tape[ptr++];
		return new IfcStructuralLoadPlanarForce(expressID, type, Name, PlanarForceX, PlanarForceY, PlanarForceZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.PlanarForceX);;
		args.push(this.PlanarForceY);;
		args.push(this.PlanarForceZ);;
		return args;
	}
};
export class IfcStructuralLoadSingleDisplacement {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DisplacementX: IfcLengthMeasure | null, DisplacementY: IfcLengthMeasure | null, DisplacementZ: IfcLengthMeasure | null, RotationalDisplacementRX: IfcPlaneAngleMeasure | null, RotationalDisplacementRY: IfcPlaneAngleMeasure | null, RotationalDisplacementRZ: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DisplacementX = DisplacementX;
		this.DisplacementY = DisplacementY;
		this.DisplacementZ = DisplacementZ;
		this.RotationalDisplacementRX = RotationalDisplacementRX;
		this.RotationalDisplacementRY = RotationalDisplacementRY;
		this.RotationalDisplacementRZ = RotationalDisplacementRZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DisplacementX: IfcLengthMeasure | null;
	DisplacementY: IfcLengthMeasure | null;
	DisplacementZ: IfcLengthMeasure | null;
	RotationalDisplacementRX: IfcPlaneAngleMeasure | null;
	RotationalDisplacementRY: IfcPlaneAngleMeasure | null;
	RotationalDisplacementRZ: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadSingleDisplacement
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DisplacementX = tape[ptr++];
		let DisplacementY = tape[ptr++];
		let DisplacementZ = tape[ptr++];
		let RotationalDisplacementRX = tape[ptr++];
		let RotationalDisplacementRY = tape[ptr++];
		let RotationalDisplacementRZ = tape[ptr++];
		return new IfcStructuralLoadSingleDisplacement(expressID, type, Name, DisplacementX, DisplacementY, DisplacementZ, RotationalDisplacementRX, RotationalDisplacementRY, RotationalDisplacementRZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DisplacementX);;
		args.push(this.DisplacementY);;
		args.push(this.DisplacementZ);;
		args.push(this.RotationalDisplacementRX);;
		args.push(this.RotationalDisplacementRY);;
		args.push(this.RotationalDisplacementRZ);;
		return args;
	}
};
export class IfcStructuralLoadSingleDisplacementDistortion {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DisplacementX: IfcLengthMeasure | null, DisplacementY: IfcLengthMeasure | null, DisplacementZ: IfcLengthMeasure | null, RotationalDisplacementRX: IfcPlaneAngleMeasure | null, RotationalDisplacementRY: IfcPlaneAngleMeasure | null, RotationalDisplacementRZ: IfcPlaneAngleMeasure | null, Distortion: IfcCurvatureMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DisplacementX = DisplacementX;
		this.DisplacementY = DisplacementY;
		this.DisplacementZ = DisplacementZ;
		this.RotationalDisplacementRX = RotationalDisplacementRX;
		this.RotationalDisplacementRY = RotationalDisplacementRY;
		this.RotationalDisplacementRZ = RotationalDisplacementRZ;
		this.Distortion = Distortion;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DisplacementX: IfcLengthMeasure | null;
	DisplacementY: IfcLengthMeasure | null;
	DisplacementZ: IfcLengthMeasure | null;
	RotationalDisplacementRX: IfcPlaneAngleMeasure | null;
	RotationalDisplacementRY: IfcPlaneAngleMeasure | null;
	RotationalDisplacementRZ: IfcPlaneAngleMeasure | null;
	Distortion: IfcCurvatureMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadSingleDisplacementDistortion
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DisplacementX = tape[ptr++];
		let DisplacementY = tape[ptr++];
		let DisplacementZ = tape[ptr++];
		let RotationalDisplacementRX = tape[ptr++];
		let RotationalDisplacementRY = tape[ptr++];
		let RotationalDisplacementRZ = tape[ptr++];
		let Distortion = tape[ptr++];
		return new IfcStructuralLoadSingleDisplacementDistortion(expressID, type, Name, DisplacementX, DisplacementY, DisplacementZ, RotationalDisplacementRX, RotationalDisplacementRY, RotationalDisplacementRZ, Distortion);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DisplacementX);;
		args.push(this.DisplacementY);;
		args.push(this.DisplacementZ);;
		args.push(this.RotationalDisplacementRX);;
		args.push(this.RotationalDisplacementRY);;
		args.push(this.RotationalDisplacementRZ);;
		args.push(this.Distortion);;
		return args;
	}
};
export class IfcStructuralLoadSingleForce {
	constructor(expressID: number, type: number, Name: IfcLabel | null, ForceX: IfcForceMeasure | null, ForceY: IfcForceMeasure | null, ForceZ: IfcForceMeasure | null, MomentX: IfcTorqueMeasure | null, MomentY: IfcTorqueMeasure | null, MomentZ: IfcTorqueMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.ForceX = ForceX;
		this.ForceY = ForceY;
		this.ForceZ = ForceZ;
		this.MomentX = MomentX;
		this.MomentY = MomentY;
		this.MomentZ = MomentZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	ForceX: IfcForceMeasure | null;
	ForceY: IfcForceMeasure | null;
	ForceZ: IfcForceMeasure | null;
	MomentX: IfcTorqueMeasure | null;
	MomentY: IfcTorqueMeasure | null;
	MomentZ: IfcTorqueMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadSingleForce
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let ForceX = tape[ptr++];
		let ForceY = tape[ptr++];
		let ForceZ = tape[ptr++];
		let MomentX = tape[ptr++];
		let MomentY = tape[ptr++];
		let MomentZ = tape[ptr++];
		return new IfcStructuralLoadSingleForce(expressID, type, Name, ForceX, ForceY, ForceZ, MomentX, MomentY, MomentZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.ForceX);;
		args.push(this.ForceY);;
		args.push(this.ForceZ);;
		args.push(this.MomentX);;
		args.push(this.MomentY);;
		args.push(this.MomentZ);;
		return args;
	}
};
export class IfcStructuralLoadSingleForceWarping {
	constructor(expressID: number, type: number, Name: IfcLabel | null, ForceX: IfcForceMeasure | null, ForceY: IfcForceMeasure | null, ForceZ: IfcForceMeasure | null, MomentX: IfcTorqueMeasure | null, MomentY: IfcTorqueMeasure | null, MomentZ: IfcTorqueMeasure | null, WarpingMoment: IfcWarpingMomentMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.ForceX = ForceX;
		this.ForceY = ForceY;
		this.ForceZ = ForceZ;
		this.MomentX = MomentX;
		this.MomentY = MomentY;
		this.MomentZ = MomentZ;
		this.WarpingMoment = WarpingMoment;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	ForceX: IfcForceMeasure | null;
	ForceY: IfcForceMeasure | null;
	ForceZ: IfcForceMeasure | null;
	MomentX: IfcTorqueMeasure | null;
	MomentY: IfcTorqueMeasure | null;
	MomentZ: IfcTorqueMeasure | null;
	WarpingMoment: IfcWarpingMomentMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadSingleForceWarping
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let ForceX = tape[ptr++];
		let ForceY = tape[ptr++];
		let ForceZ = tape[ptr++];
		let MomentX = tape[ptr++];
		let MomentY = tape[ptr++];
		let MomentZ = tape[ptr++];
		let WarpingMoment = tape[ptr++];
		return new IfcStructuralLoadSingleForceWarping(expressID, type, Name, ForceX, ForceY, ForceZ, MomentX, MomentY, MomentZ, WarpingMoment);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.ForceX);;
		args.push(this.ForceY);;
		args.push(this.ForceZ);;
		args.push(this.MomentX);;
		args.push(this.MomentY);;
		args.push(this.MomentZ);;
		args.push(this.WarpingMoment);;
		return args;
	}
};
export class IfcStructuralLoadStatic {
	constructor(expressID: number, type: number, Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadStatic
	{
		let ptr = 0;
		let Name = tape[ptr++];
		return new IfcStructuralLoadStatic(expressID, type, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		return args;
	}
};
export class IfcStructuralLoadTemperature {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DeltaTConstant: IfcThermodynamicTemperatureMeasure | null, DeltaTY: IfcThermodynamicTemperatureMeasure | null, DeltaTZ: IfcThermodynamicTemperatureMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DeltaTConstant = DeltaTConstant;
		this.DeltaTY = DeltaTY;
		this.DeltaTZ = DeltaTZ;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DeltaTConstant: IfcThermodynamicTemperatureMeasure | null;
	DeltaTY: IfcThermodynamicTemperatureMeasure | null;
	DeltaTZ: IfcThermodynamicTemperatureMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralLoadTemperature
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DeltaTConstant = tape[ptr++];
		let DeltaTY = tape[ptr++];
		let DeltaTZ = tape[ptr++];
		return new IfcStructuralLoadTemperature(expressID, type, Name, DeltaTConstant, DeltaTY, DeltaTZ);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DeltaTConstant);;
		args.push(this.DeltaTY);;
		args.push(this.DeltaTZ);;
		return args;
	}
};
export class IfcStructuralMember {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralMember
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		return new IfcStructuralMember(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		return args;
	}
};
export class IfcStructuralPlanarAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null, ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null, PredefinedType: IfcStructuralSurfaceActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
		this.ProjectedOrTrue = ProjectedOrTrue;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null;
	PredefinedType: IfcStructuralSurfaceActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralPlanarAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		let ProjectedOrTrue = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralPlanarAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad, ProjectedOrTrue, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		args.push(this.ProjectedOrTrue);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralPointAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralPointAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		return new IfcStructuralPointAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		return args;
	}
};
export class IfcStructuralPointConnection {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null, ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedCondition = AppliedCondition;
		this.ConditionCoordinateSystem = ConditionCoordinateSystem;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	ConditionCoordinateSystem: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralPointConnection
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		let ConditionCoordinateSystem = tape[ptr++];
		return new IfcStructuralPointConnection(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedCondition, ConditionCoordinateSystem);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedCondition);;
		args.push(this.ConditionCoordinateSystem);;
		return args;
	}
};
export class IfcStructuralPointReaction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralPointReaction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		return new IfcStructuralPointReaction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		return args;
	}
};
export class IfcStructuralReaction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralReaction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		return new IfcStructuralReaction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		return args;
	}
};
export class IfcStructuralResultGroup {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, TheoryType: IfcAnalysisTheoryTypeEnum , ResultForLoadGroup: (Handle<IfcStructuralLoadGroup> | IfcStructuralLoadGroup) | null, IsLinear: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.TheoryType = TheoryType;
		this.ResultForLoadGroup = ResultForLoadGroup;
		this.IsLinear = IsLinear;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	TheoryType: IfcAnalysisTheoryTypeEnum ;
	ResultForLoadGroup: (Handle<IfcStructuralLoadGroup> | IfcStructuralLoadGroup) | null;
	IsLinear: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralResultGroup
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let TheoryType = tape[ptr++];
		let ResultForLoadGroup = tape[ptr++];
		let IsLinear = tape[ptr++];
		return new IfcStructuralResultGroup(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, TheoryType, ResultForLoadGroup, IsLinear);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.TheoryType);;
		args.push(this.ResultForLoadGroup);;
		args.push(this.IsLinear);;
		return args;
	}
};
export class IfcStructuralSurfaceAction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , DestabilizingLoad: IfcBoolean | null, ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null, PredefinedType: IfcStructuralSurfaceActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.DestabilizingLoad = DestabilizingLoad;
		this.ProjectedOrTrue = ProjectedOrTrue;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	DestabilizingLoad: IfcBoolean | null;
	ProjectedOrTrue: IfcProjectedOrTrueLengthEnum | null;
	PredefinedType: IfcStructuralSurfaceActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralSurfaceAction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let DestabilizingLoad = tape[ptr++];
		let ProjectedOrTrue = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralSurfaceAction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, DestabilizingLoad, ProjectedOrTrue, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.DestabilizingLoad);;
		args.push(this.ProjectedOrTrue);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStructuralSurfaceConnection {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedCondition = AppliedCondition;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedCondition: (Handle<IfcBoundaryCondition> | IfcBoundaryCondition) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralSurfaceConnection
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedCondition = tape[ptr++];
		return new IfcStructuralSurfaceConnection(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedCondition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedCondition);;
		return args;
	}
};
export class IfcStructuralSurfaceMember {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, PredefinedType: IfcStructuralSurfaceMemberTypeEnum , Thickness: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.PredefinedType = PredefinedType;
		this.Thickness = Thickness;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	PredefinedType: IfcStructuralSurfaceMemberTypeEnum ;
	Thickness: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralSurfaceMember
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Thickness = tape[ptr++];
		return new IfcStructuralSurfaceMember(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, PredefinedType, Thickness);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.PredefinedType);;
		args.push(this.Thickness);;
		return args;
	}
};
export class IfcStructuralSurfaceMemberVarying {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, PredefinedType: IfcStructuralSurfaceMemberTypeEnum , Thickness: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.PredefinedType = PredefinedType;
		this.Thickness = Thickness;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	PredefinedType: IfcStructuralSurfaceMemberTypeEnum ;
	Thickness: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralSurfaceMemberVarying
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let Thickness = tape[ptr++];
		return new IfcStructuralSurfaceMemberVarying(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, PredefinedType, Thickness);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.PredefinedType);;
		args.push(this.Thickness);;
		return args;
	}
};
export class IfcStructuralSurfaceReaction {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) , GlobalOrLocal: IfcGlobalOrLocalEnum , PredefinedType: IfcStructuralSurfaceActivityTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.AppliedLoad = AppliedLoad;
		this.GlobalOrLocal = GlobalOrLocal;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	AppliedLoad: (Handle<IfcStructuralLoad> | IfcStructuralLoad) ;
	GlobalOrLocal: IfcGlobalOrLocalEnum ;
	PredefinedType: IfcStructuralSurfaceActivityTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStructuralSurfaceReaction
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let AppliedLoad = tape[ptr++];
		let GlobalOrLocal = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcStructuralSurfaceReaction(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, AppliedLoad, GlobalOrLocal, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.AppliedLoad);;
		args.push(this.GlobalOrLocal);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcStyleModel {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStyleModel
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcStyleModel(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcStyledItem {
	constructor(expressID: number, type: number, Item: (Handle<IfcRepresentationItem> | IfcRepresentationItem) | null, Styles: IfcStyleAssignmentSelect[] , Name: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Item = Item;
		this.Styles = Styles;
		this.Name = Name;
	}
	expressID: number;
	type: number;
	Item: (Handle<IfcRepresentationItem> | IfcRepresentationItem) | null;
	Styles: IfcStyleAssignmentSelect[] ;
	Name: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStyledItem
	{
		let ptr = 0;
		let Item = tape[ptr++];
		let Styles = tape[ptr++];
		let Name = tape[ptr++];
		return new IfcStyledItem(expressID, type, Item, Styles, Name);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Item);;
		args.push(this.Styles);;
		args.push(this.Name);;
		return args;
	}
};
export class IfcStyledRepresentation {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcStyledRepresentation
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcStyledRepresentation(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcSubContractResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcSubContractResourceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Usage = Usage;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Usage: (Handle<IfcResourceTime> | IfcResourceTime) | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcSubContractResourceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSubContractResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Usage = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSubContractResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Usage, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Usage);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSubContractResourceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null, BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null, BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null, PredefinedType: IfcSubContractResourceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
		this.BaseCosts = BaseCosts;
		this.BaseQuantity = BaseQuantity;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	BaseCosts: (Handle<IfcAppliedValue> | IfcAppliedValue)[] | null;
	BaseQuantity: (Handle<IfcPhysicalQuantity> | IfcPhysicalQuantity) | null;
	PredefinedType: IfcSubContractResourceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSubContractResourceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		let BaseCosts = tape[ptr++];
		let BaseQuantity = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSubContractResourceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType, BaseCosts, BaseQuantity, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		args.push(this.BaseCosts);;
		args.push(this.BaseQuantity);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSubedge {
	constructor(expressID: number, type: number, EdgeStart: (Handle<IfcVertex> | IfcVertex) , EdgeEnd: (Handle<IfcVertex> | IfcVertex) , ParentEdge: (Handle<IfcEdge> | IfcEdge) )
	{
		this.expressID = expressID;
		this.type = type;
		this.EdgeStart = EdgeStart;
		this.EdgeEnd = EdgeEnd;
		this.ParentEdge = ParentEdge;
	}
	expressID: number;
	type: number;
	EdgeStart: (Handle<IfcVertex> | IfcVertex) ;
	EdgeEnd: (Handle<IfcVertex> | IfcVertex) ;
	ParentEdge: (Handle<IfcEdge> | IfcEdge) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSubedge
	{
		let ptr = 0;
		let EdgeStart = tape[ptr++];
		let EdgeEnd = tape[ptr++];
		let ParentEdge = tape[ptr++];
		return new IfcSubedge(expressID, type, EdgeStart, EdgeEnd, ParentEdge);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.EdgeStart);;
		args.push(this.EdgeEnd);;
		args.push(this.ParentEdge);;
		return args;
	}
};
export class IfcSurface {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurface
	{
		let ptr = 0;
		return new IfcSurface(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcSurfaceCurve {
	constructor(expressID: number, type: number, Curve3D: (Handle<IfcCurve> | IfcCurve) , AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] , MasterRepresentation: IfcPreferredSurfaceCurveRepresentation )
	{
		this.expressID = expressID;
		this.type = type;
		this.Curve3D = Curve3D;
		this.AssociatedGeometry = AssociatedGeometry;
		this.MasterRepresentation = MasterRepresentation;
	}
	expressID: number;
	type: number;
	Curve3D: (Handle<IfcCurve> | IfcCurve) ;
	AssociatedGeometry: (Handle<IfcPcurve> | IfcPcurve)[] ;
	MasterRepresentation: IfcPreferredSurfaceCurveRepresentation ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceCurve
	{
		let ptr = 0;
		let Curve3D = tape[ptr++];
		let AssociatedGeometry = tape[ptr++];
		let MasterRepresentation = tape[ptr++];
		return new IfcSurfaceCurve(expressID, type, Curve3D, AssociatedGeometry, MasterRepresentation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Curve3D);;
		args.push(this.AssociatedGeometry);;
		args.push(this.MasterRepresentation);;
		return args;
	}
};
export class IfcSurfaceCurveSweptAreaSolid {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, Directrix: (Handle<IfcCurve> | IfcCurve) , StartParam: IfcParameterValue | null, EndParam: IfcParameterValue | null, ReferenceSurface: (Handle<IfcSurface> | IfcSurface) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
		this.Directrix = Directrix;
		this.StartParam = StartParam;
		this.EndParam = EndParam;
		this.ReferenceSurface = ReferenceSurface;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	StartParam: IfcParameterValue | null;
	EndParam: IfcParameterValue | null;
	ReferenceSurface: (Handle<IfcSurface> | IfcSurface) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceCurveSweptAreaSolid
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		let Directrix = tape[ptr++];
		let StartParam = tape[ptr++];
		let EndParam = tape[ptr++];
		let ReferenceSurface = tape[ptr++];
		return new IfcSurfaceCurveSweptAreaSolid(expressID, type, SweptArea, Position, Directrix, StartParam, EndParam, ReferenceSurface);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		args.push(this.Directrix);;
		args.push(this.StartParam);;
		args.push(this.EndParam);;
		args.push(this.ReferenceSurface);;
		return args;
	}
};
export class IfcSurfaceFeature {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSurfaceFeatureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSurfaceFeatureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceFeature
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSurfaceFeature(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSurfaceOfLinearExtrusion {
	constructor(expressID: number, type: number, SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) , Depth: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptCurve = SweptCurve;
		this.Position = Position;
		this.ExtrudedDirection = ExtrudedDirection;
		this.Depth = Depth;
	}
	expressID: number;
	type: number;
	SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	ExtrudedDirection: (Handle<IfcDirection> | IfcDirection) ;
	Depth: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceOfLinearExtrusion
	{
		let ptr = 0;
		let SweptCurve = tape[ptr++];
		let Position = tape[ptr++];
		let ExtrudedDirection = tape[ptr++];
		let Depth = tape[ptr++];
		return new IfcSurfaceOfLinearExtrusion(expressID, type, SweptCurve, Position, ExtrudedDirection, Depth);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptCurve);;
		args.push(this.Position);;
		args.push(this.ExtrudedDirection);;
		args.push(this.Depth);;
		return args;
	}
};
export class IfcSurfaceOfRevolution {
	constructor(expressID: number, type: number, SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null, AxisPosition: (Handle<IfcAxis1Placement> | IfcAxis1Placement) )
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptCurve = SweptCurve;
		this.Position = Position;
		this.AxisPosition = AxisPosition;
	}
	expressID: number;
	type: number;
	SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	AxisPosition: (Handle<IfcAxis1Placement> | IfcAxis1Placement) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceOfRevolution
	{
		let ptr = 0;
		let SweptCurve = tape[ptr++];
		let Position = tape[ptr++];
		let AxisPosition = tape[ptr++];
		return new IfcSurfaceOfRevolution(expressID, type, SweptCurve, Position, AxisPosition);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptCurve);;
		args.push(this.Position);;
		args.push(this.AxisPosition);;
		return args;
	}
};
export class IfcSurfaceReinforcementArea {
	constructor(expressID: number, type: number, Name: IfcLabel | null, SurfaceReinforcement1: IfcLengthMeasure[] | null, SurfaceReinforcement2: IfcLengthMeasure[] | null, ShearReinforcement: IfcRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.SurfaceReinforcement1 = SurfaceReinforcement1;
		this.SurfaceReinforcement2 = SurfaceReinforcement2;
		this.ShearReinforcement = ShearReinforcement;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	SurfaceReinforcement1: IfcLengthMeasure[] | null;
	SurfaceReinforcement2: IfcLengthMeasure[] | null;
	ShearReinforcement: IfcRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceReinforcementArea
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let SurfaceReinforcement1 = tape[ptr++];
		let SurfaceReinforcement2 = tape[ptr++];
		let ShearReinforcement = tape[ptr++];
		return new IfcSurfaceReinforcementArea(expressID, type, Name, SurfaceReinforcement1, SurfaceReinforcement2, ShearReinforcement);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.SurfaceReinforcement1);;
		args.push(this.SurfaceReinforcement2);;
		args.push(this.ShearReinforcement);;
		return args;
	}
};
export class IfcSurfaceStyle {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Side: IfcSurfaceSide , Styles: IfcSurfaceStyleElementSelect[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Side = Side;
		this.Styles = Styles;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Side: IfcSurfaceSide ;
	Styles: IfcSurfaceStyleElementSelect[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Side = tape[ptr++];
		let Styles = tape[ptr++];
		return new IfcSurfaceStyle(expressID, type, Name, Side, Styles);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Side);;
		args.push(this.Styles);;
		return args;
	}
};
export class IfcSurfaceStyleLighting {
	constructor(expressID: number, type: number, DiffuseTransmissionColour: (Handle<IfcColourRgb> | IfcColourRgb) , DiffuseReflectionColour: (Handle<IfcColourRgb> | IfcColourRgb) , TransmissionColour: (Handle<IfcColourRgb> | IfcColourRgb) , ReflectanceColour: (Handle<IfcColourRgb> | IfcColourRgb) )
	{
		this.expressID = expressID;
		this.type = type;
		this.DiffuseTransmissionColour = DiffuseTransmissionColour;
		this.DiffuseReflectionColour = DiffuseReflectionColour;
		this.TransmissionColour = TransmissionColour;
		this.ReflectanceColour = ReflectanceColour;
	}
	expressID: number;
	type: number;
	DiffuseTransmissionColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	DiffuseReflectionColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	TransmissionColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	ReflectanceColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyleLighting
	{
		let ptr = 0;
		let DiffuseTransmissionColour = tape[ptr++];
		let DiffuseReflectionColour = tape[ptr++];
		let TransmissionColour = tape[ptr++];
		let ReflectanceColour = tape[ptr++];
		return new IfcSurfaceStyleLighting(expressID, type, DiffuseTransmissionColour, DiffuseReflectionColour, TransmissionColour, ReflectanceColour);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.DiffuseTransmissionColour);;
		args.push(this.DiffuseReflectionColour);;
		args.push(this.TransmissionColour);;
		args.push(this.ReflectanceColour);;
		return args;
	}
};
export class IfcSurfaceStyleRefraction {
	constructor(expressID: number, type: number, RefractionIndex: IfcReal | null, DispersionFactor: IfcReal | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.RefractionIndex = RefractionIndex;
		this.DispersionFactor = DispersionFactor;
	}
	expressID: number;
	type: number;
	RefractionIndex: IfcReal | null;
	DispersionFactor: IfcReal | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyleRefraction
	{
		let ptr = 0;
		let RefractionIndex = tape[ptr++];
		let DispersionFactor = tape[ptr++];
		return new IfcSurfaceStyleRefraction(expressID, type, RefractionIndex, DispersionFactor);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RefractionIndex);;
		args.push(this.DispersionFactor);;
		return args;
	}
};
export class IfcSurfaceStyleRendering {
	constructor(expressID: number, type: number, SurfaceColour: (Handle<IfcColourRgb> | IfcColourRgb) , Transparency: IfcNormalisedRatioMeasure | null, DiffuseColour: IfcColourOrFactor | null, TransmissionColour: IfcColourOrFactor | null, DiffuseTransmissionColour: IfcColourOrFactor | null, ReflectionColour: IfcColourOrFactor | null, SpecularColour: IfcColourOrFactor | null, SpecularHighlight: IfcSpecularHighlightSelect | null, ReflectanceMethod: IfcReflectanceMethodEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.SurfaceColour = SurfaceColour;
		this.Transparency = Transparency;
		this.DiffuseColour = DiffuseColour;
		this.TransmissionColour = TransmissionColour;
		this.DiffuseTransmissionColour = DiffuseTransmissionColour;
		this.ReflectionColour = ReflectionColour;
		this.SpecularColour = SpecularColour;
		this.SpecularHighlight = SpecularHighlight;
		this.ReflectanceMethod = ReflectanceMethod;
	}
	expressID: number;
	type: number;
	SurfaceColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	Transparency: IfcNormalisedRatioMeasure | null;
	DiffuseColour: IfcColourOrFactor | null;
	TransmissionColour: IfcColourOrFactor | null;
	DiffuseTransmissionColour: IfcColourOrFactor | null;
	ReflectionColour: IfcColourOrFactor | null;
	SpecularColour: IfcColourOrFactor | null;
	SpecularHighlight: IfcSpecularHighlightSelect | null;
	ReflectanceMethod: IfcReflectanceMethodEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyleRendering
	{
		let ptr = 0;
		let SurfaceColour = tape[ptr++];
		let Transparency = tape[ptr++];
		let DiffuseColour = tape[ptr++];
		let TransmissionColour = tape[ptr++];
		let DiffuseTransmissionColour = tape[ptr++];
		let ReflectionColour = tape[ptr++];
		let SpecularColour = tape[ptr++];
		let SpecularHighlight = tape[ptr++];
		let ReflectanceMethod = tape[ptr++];
		return new IfcSurfaceStyleRendering(expressID, type, SurfaceColour, Transparency, DiffuseColour, TransmissionColour, DiffuseTransmissionColour, ReflectionColour, SpecularColour, SpecularHighlight, ReflectanceMethod);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SurfaceColour);;
		args.push(this.Transparency);;
		args.push(this.DiffuseColour);;
		args.push(this.TransmissionColour);;
		args.push(this.DiffuseTransmissionColour);;
		args.push(this.ReflectionColour);;
		args.push(this.SpecularColour);;
		args.push(this.SpecularHighlight);;
		args.push(this.ReflectanceMethod);;
		return args;
	}
};
export class IfcSurfaceStyleShading {
	constructor(expressID: number, type: number, SurfaceColour: (Handle<IfcColourRgb> | IfcColourRgb) , Transparency: IfcNormalisedRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SurfaceColour = SurfaceColour;
		this.Transparency = Transparency;
	}
	expressID: number;
	type: number;
	SurfaceColour: (Handle<IfcColourRgb> | IfcColourRgb) ;
	Transparency: IfcNormalisedRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyleShading
	{
		let ptr = 0;
		let SurfaceColour = tape[ptr++];
		let Transparency = tape[ptr++];
		return new IfcSurfaceStyleShading(expressID, type, SurfaceColour, Transparency);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SurfaceColour);;
		args.push(this.Transparency);;
		return args;
	}
};
export class IfcSurfaceStyleWithTextures {
	constructor(expressID: number, type: number, Textures: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Textures = Textures;
	}
	expressID: number;
	type: number;
	Textures: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceStyleWithTextures
	{
		let ptr = 0;
		let Textures = tape[ptr++];
		return new IfcSurfaceStyleWithTextures(expressID, type, Textures);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Textures);;
		return args;
	}
};
export class IfcSurfaceTexture {
	constructor(expressID: number, type: number, RepeatS: IfcBoolean , RepeatT: IfcBoolean , Mode: IfcIdentifier | null, TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null, Parameter: IfcIdentifier[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.RepeatS = RepeatS;
		this.RepeatT = RepeatT;
		this.Mode = Mode;
		this.TextureTransform = TextureTransform;
		this.Parameter = Parameter;
	}
	expressID: number;
	type: number;
	RepeatS: IfcBoolean ;
	RepeatT: IfcBoolean ;
	Mode: IfcIdentifier | null;
	TextureTransform: (Handle<IfcCartesianTransformationOperator2D> | IfcCartesianTransformationOperator2D) | null;
	Parameter: IfcIdentifier[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSurfaceTexture
	{
		let ptr = 0;
		let RepeatS = tape[ptr++];
		let RepeatT = tape[ptr++];
		let Mode = tape[ptr++];
		let TextureTransform = tape[ptr++];
		let Parameter = tape[ptr++];
		return new IfcSurfaceTexture(expressID, type, RepeatS, RepeatT, Mode, TextureTransform, Parameter);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RepeatS);;
		args.push(this.RepeatT);;
		args.push(this.Mode);;
		args.push(this.TextureTransform);;
		args.push(this.Parameter);;
		return args;
	}
};
export class IfcSweptAreaSolid {
	constructor(expressID: number, type: number, SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptArea = SweptArea;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	SweptArea: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSweptAreaSolid
	{
		let ptr = 0;
		let SweptArea = tape[ptr++];
		let Position = tape[ptr++];
		return new IfcSweptAreaSolid(expressID, type, SweptArea, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptArea);;
		args.push(this.Position);;
		return args;
	}
};
export class IfcSweptDiskSolid {
	constructor(expressID: number, type: number, Directrix: (Handle<IfcCurve> | IfcCurve) , Radius: IfcPositiveLengthMeasure , InnerRadius: IfcPositiveLengthMeasure | null, StartParam: IfcParameterValue | null, EndParam: IfcParameterValue | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Directrix = Directrix;
		this.Radius = Radius;
		this.InnerRadius = InnerRadius;
		this.StartParam = StartParam;
		this.EndParam = EndParam;
	}
	expressID: number;
	type: number;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	Radius: IfcPositiveLengthMeasure ;
	InnerRadius: IfcPositiveLengthMeasure | null;
	StartParam: IfcParameterValue | null;
	EndParam: IfcParameterValue | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSweptDiskSolid
	{
		let ptr = 0;
		let Directrix = tape[ptr++];
		let Radius = tape[ptr++];
		let InnerRadius = tape[ptr++];
		let StartParam = tape[ptr++];
		let EndParam = tape[ptr++];
		return new IfcSweptDiskSolid(expressID, type, Directrix, Radius, InnerRadius, StartParam, EndParam);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Directrix);;
		args.push(this.Radius);;
		args.push(this.InnerRadius);;
		args.push(this.StartParam);;
		args.push(this.EndParam);;
		return args;
	}
};
export class IfcSweptDiskSolidPolygonal {
	constructor(expressID: number, type: number, Directrix: (Handle<IfcCurve> | IfcCurve) , Radius: IfcPositiveLengthMeasure , InnerRadius: IfcPositiveLengthMeasure | null, StartParam: IfcParameterValue | null, EndParam: IfcParameterValue | null, FilletRadius: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Directrix = Directrix;
		this.Radius = Radius;
		this.InnerRadius = InnerRadius;
		this.StartParam = StartParam;
		this.EndParam = EndParam;
		this.FilletRadius = FilletRadius;
	}
	expressID: number;
	type: number;
	Directrix: (Handle<IfcCurve> | IfcCurve) ;
	Radius: IfcPositiveLengthMeasure ;
	InnerRadius: IfcPositiveLengthMeasure | null;
	StartParam: IfcParameterValue | null;
	EndParam: IfcParameterValue | null;
	FilletRadius: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSweptDiskSolidPolygonal
	{
		let ptr = 0;
		let Directrix = tape[ptr++];
		let Radius = tape[ptr++];
		let InnerRadius = tape[ptr++];
		let StartParam = tape[ptr++];
		let EndParam = tape[ptr++];
		let FilletRadius = tape[ptr++];
		return new IfcSweptDiskSolidPolygonal(expressID, type, Directrix, Radius, InnerRadius, StartParam, EndParam, FilletRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Directrix);;
		args.push(this.Radius);;
		args.push(this.InnerRadius);;
		args.push(this.StartParam);;
		args.push(this.EndParam);;
		args.push(this.FilletRadius);;
		return args;
	}
};
export class IfcSweptSurface {
	constructor(expressID: number, type: number, SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) , Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.SweptCurve = SweptCurve;
		this.Position = Position;
	}
	expressID: number;
	type: number;
	SweptCurve: (Handle<IfcProfileDef> | IfcProfileDef) ;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSweptSurface
	{
		let ptr = 0;
		let SweptCurve = tape[ptr++];
		let Position = tape[ptr++];
		return new IfcSweptSurface(expressID, type, SweptCurve, Position);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.SweptCurve);;
		args.push(this.Position);;
		return args;
	}
};
export class IfcSwitchingDevice {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSwitchingDeviceTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSwitchingDeviceTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSwitchingDevice
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSwitchingDevice(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSwitchingDeviceType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSwitchingDeviceTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSwitchingDeviceTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSwitchingDeviceType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSwitchingDeviceType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSystem {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSystem
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		return new IfcSystem(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		return args;
	}
};
export class IfcSystemFurnitureElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcSystemFurnitureElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcSystemFurnitureElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSystemFurnitureElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSystemFurnitureElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcSystemFurnitureElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcSystemFurnitureElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcSystemFurnitureElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcSystemFurnitureElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcSystemFurnitureElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Depth: IfcPositiveLengthMeasure , FlangeWidth: IfcPositiveLengthMeasure , WebThickness: IfcPositiveLengthMeasure , FlangeThickness: IfcPositiveLengthMeasure , FilletRadius: IfcNonNegativeLengthMeasure | null, FlangeEdgeRadius: IfcNonNegativeLengthMeasure | null, WebEdgeRadius: IfcNonNegativeLengthMeasure | null, WebSlope: IfcPlaneAngleMeasure | null, FlangeSlope: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Depth = Depth;
		this.FlangeWidth = FlangeWidth;
		this.WebThickness = WebThickness;
		this.FlangeThickness = FlangeThickness;
		this.FilletRadius = FilletRadius;
		this.FlangeEdgeRadius = FlangeEdgeRadius;
		this.WebEdgeRadius = WebEdgeRadius;
		this.WebSlope = WebSlope;
		this.FlangeSlope = FlangeSlope;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Depth: IfcPositiveLengthMeasure ;
	FlangeWidth: IfcPositiveLengthMeasure ;
	WebThickness: IfcPositiveLengthMeasure ;
	FlangeThickness: IfcPositiveLengthMeasure ;
	FilletRadius: IfcNonNegativeLengthMeasure | null;
	FlangeEdgeRadius: IfcNonNegativeLengthMeasure | null;
	WebEdgeRadius: IfcNonNegativeLengthMeasure | null;
	WebSlope: IfcPlaneAngleMeasure | null;
	FlangeSlope: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Depth = tape[ptr++];
		let FlangeWidth = tape[ptr++];
		let WebThickness = tape[ptr++];
		let FlangeThickness = tape[ptr++];
		let FilletRadius = tape[ptr++];
		let FlangeEdgeRadius = tape[ptr++];
		let WebEdgeRadius = tape[ptr++];
		let WebSlope = tape[ptr++];
		let FlangeSlope = tape[ptr++];
		return new IfcTShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, Depth, FlangeWidth, WebThickness, FlangeThickness, FilletRadius, FlangeEdgeRadius, WebEdgeRadius, WebSlope, FlangeSlope);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Depth);;
		args.push(this.FlangeWidth);;
		args.push(this.WebThickness);;
		args.push(this.FlangeThickness);;
		args.push(this.FilletRadius);;
		args.push(this.FlangeEdgeRadius);;
		args.push(this.WebEdgeRadius);;
		args.push(this.WebSlope);;
		args.push(this.FlangeSlope);;
		return args;
	}
};
export class IfcTable {
	constructor(expressID: number, type: number, Name: IfcLabel | null, Rows: (Handle<IfcTableRow> | IfcTableRow)[] | null, Columns: (Handle<IfcTableColumn> | IfcTableColumn)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Rows = Rows;
		this.Columns = Columns;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	Rows: (Handle<IfcTableRow> | IfcTableRow)[] | null;
	Columns: (Handle<IfcTableColumn> | IfcTableColumn)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTable
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Rows = tape[ptr++];
		let Columns = tape[ptr++];
		return new IfcTable(expressID, type, Name, Rows, Columns);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Rows);;
		args.push(this.Columns);;
		return args;
	}
};
export class IfcTableColumn {
	constructor(expressID: number, type: number, Identifier: IfcIdentifier | null, Name: IfcLabel | null, Description: IfcText | null, Unit: IfcUnit | null, ReferencePath: (Handle<IfcReference> | IfcReference) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Identifier = Identifier;
		this.Name = Name;
		this.Description = Description;
		this.Unit = Unit;
		this.ReferencePath = ReferencePath;
	}
	expressID: number;
	type: number;
	Identifier: IfcIdentifier | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	Unit: IfcUnit | null;
	ReferencePath: (Handle<IfcReference> | IfcReference) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTableColumn
	{
		let ptr = 0;
		let Identifier = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let Unit = tape[ptr++];
		let ReferencePath = tape[ptr++];
		return new IfcTableColumn(expressID, type, Identifier, Name, Description, Unit, ReferencePath);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Identifier);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.Unit);;
		args.push(this.ReferencePath);;
		return args;
	}
};
export class IfcTableRow {
	constructor(expressID: number, type: number, RowCells: IfcValue[] | null, IsHeading: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.RowCells = RowCells;
		this.IsHeading = IsHeading;
	}
	expressID: number;
	type: number;
	RowCells: IfcValue[] | null;
	IsHeading: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTableRow
	{
		let ptr = 0;
		let RowCells = tape[ptr++];
		let IsHeading = tape[ptr++];
		return new IfcTableRow(expressID, type, RowCells, IsHeading);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.RowCells);;
		args.push(this.IsHeading);;
		return args;
	}
};
export class IfcTank {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcTankTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcTankTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTank
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTank(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTankType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTankTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTankTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTankType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTankType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTask {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, Status: IfcLabel | null, WorkMethod: IfcLabel | null, IsMilestone: IfcBoolean , Priority: IfcInteger | null, TaskTime: (Handle<IfcTaskTime> | IfcTaskTime) | null, PredefinedType: IfcTaskTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.Status = Status;
		this.WorkMethod = WorkMethod;
		this.IsMilestone = IsMilestone;
		this.Priority = Priority;
		this.TaskTime = TaskTime;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	Status: IfcLabel | null;
	WorkMethod: IfcLabel | null;
	IsMilestone: IfcBoolean ;
	Priority: IfcInteger | null;
	TaskTime: (Handle<IfcTaskTime> | IfcTaskTime) | null;
	PredefinedType: IfcTaskTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTask
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let Status = tape[ptr++];
		let WorkMethod = tape[ptr++];
		let IsMilestone = tape[ptr++];
		let Priority = tape[ptr++];
		let TaskTime = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTask(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, LongDescription, Status, WorkMethod, IsMilestone, Priority, TaskTime, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.Status);;
		args.push(this.WorkMethod);;
		args.push(this.IsMilestone);;
		args.push(this.Priority);;
		args.push(this.TaskTime);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTaskTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, DurationType: IfcTaskDurationEnum | null, ScheduleDuration: IfcDuration | null, ScheduleStart: IfcDateTime | null, ScheduleFinish: IfcDateTime | null, EarlyStart: IfcDateTime | null, EarlyFinish: IfcDateTime | null, LateStart: IfcDateTime | null, LateFinish: IfcDateTime | null, FreeFloat: IfcDuration | null, TotalFloat: IfcDuration | null, IsCritical: IfcBoolean | null, StatusTime: IfcDateTime | null, ActualDuration: IfcDuration | null, ActualStart: IfcDateTime | null, ActualFinish: IfcDateTime | null, RemainingTime: IfcDuration | null, Completion: IfcPositiveRatioMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.DurationType = DurationType;
		this.ScheduleDuration = ScheduleDuration;
		this.ScheduleStart = ScheduleStart;
		this.ScheduleFinish = ScheduleFinish;
		this.EarlyStart = EarlyStart;
		this.EarlyFinish = EarlyFinish;
		this.LateStart = LateStart;
		this.LateFinish = LateFinish;
		this.FreeFloat = FreeFloat;
		this.TotalFloat = TotalFloat;
		this.IsCritical = IsCritical;
		this.StatusTime = StatusTime;
		this.ActualDuration = ActualDuration;
		this.ActualStart = ActualStart;
		this.ActualFinish = ActualFinish;
		this.RemainingTime = RemainingTime;
		this.Completion = Completion;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	DurationType: IfcTaskDurationEnum | null;
	ScheduleDuration: IfcDuration | null;
	ScheduleStart: IfcDateTime | null;
	ScheduleFinish: IfcDateTime | null;
	EarlyStart: IfcDateTime | null;
	EarlyFinish: IfcDateTime | null;
	LateStart: IfcDateTime | null;
	LateFinish: IfcDateTime | null;
	FreeFloat: IfcDuration | null;
	TotalFloat: IfcDuration | null;
	IsCritical: IfcBoolean | null;
	StatusTime: IfcDateTime | null;
	ActualDuration: IfcDuration | null;
	ActualStart: IfcDateTime | null;
	ActualFinish: IfcDateTime | null;
	RemainingTime: IfcDuration | null;
	Completion: IfcPositiveRatioMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTaskTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let DurationType = tape[ptr++];
		let ScheduleDuration = tape[ptr++];
		let ScheduleStart = tape[ptr++];
		let ScheduleFinish = tape[ptr++];
		let EarlyStart = tape[ptr++];
		let EarlyFinish = tape[ptr++];
		let LateStart = tape[ptr++];
		let LateFinish = tape[ptr++];
		let FreeFloat = tape[ptr++];
		let TotalFloat = tape[ptr++];
		let IsCritical = tape[ptr++];
		let StatusTime = tape[ptr++];
		let ActualDuration = tape[ptr++];
		let ActualStart = tape[ptr++];
		let ActualFinish = tape[ptr++];
		let RemainingTime = tape[ptr++];
		let Completion = tape[ptr++];
		return new IfcTaskTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, DurationType, ScheduleDuration, ScheduleStart, ScheduleFinish, EarlyStart, EarlyFinish, LateStart, LateFinish, FreeFloat, TotalFloat, IsCritical, StatusTime, ActualDuration, ActualStart, ActualFinish, RemainingTime, Completion);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.DurationType);;
		args.push(this.ScheduleDuration);;
		args.push(this.ScheduleStart);;
		args.push(this.ScheduleFinish);;
		args.push(this.EarlyStart);;
		args.push(this.EarlyFinish);;
		args.push(this.LateStart);;
		args.push(this.LateFinish);;
		args.push(this.FreeFloat);;
		args.push(this.TotalFloat);;
		args.push(this.IsCritical);;
		args.push(this.StatusTime);;
		args.push(this.ActualDuration);;
		args.push(this.ActualStart);;
		args.push(this.ActualFinish);;
		args.push(this.RemainingTime);;
		args.push(this.Completion);;
		return args;
	}
};
export class IfcTaskTimeRecurring {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, DurationType: IfcTaskDurationEnum | null, ScheduleDuration: IfcDuration | null, ScheduleStart: IfcDateTime | null, ScheduleFinish: IfcDateTime | null, EarlyStart: IfcDateTime | null, EarlyFinish: IfcDateTime | null, LateStart: IfcDateTime | null, LateFinish: IfcDateTime | null, FreeFloat: IfcDuration | null, TotalFloat: IfcDuration | null, IsCritical: IfcBoolean | null, StatusTime: IfcDateTime | null, ActualDuration: IfcDuration | null, ActualStart: IfcDateTime | null, ActualFinish: IfcDateTime | null, RemainingTime: IfcDuration | null, Completion: IfcPositiveRatioMeasure | null, Recurrence: (Handle<IfcRecurrencePattern> | IfcRecurrencePattern) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.DurationType = DurationType;
		this.ScheduleDuration = ScheduleDuration;
		this.ScheduleStart = ScheduleStart;
		this.ScheduleFinish = ScheduleFinish;
		this.EarlyStart = EarlyStart;
		this.EarlyFinish = EarlyFinish;
		this.LateStart = LateStart;
		this.LateFinish = LateFinish;
		this.FreeFloat = FreeFloat;
		this.TotalFloat = TotalFloat;
		this.IsCritical = IsCritical;
		this.StatusTime = StatusTime;
		this.ActualDuration = ActualDuration;
		this.ActualStart = ActualStart;
		this.ActualFinish = ActualFinish;
		this.RemainingTime = RemainingTime;
		this.Completion = Completion;
		this.Recurrence = Recurrence;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	DurationType: IfcTaskDurationEnum | null;
	ScheduleDuration: IfcDuration | null;
	ScheduleStart: IfcDateTime | null;
	ScheduleFinish: IfcDateTime | null;
	EarlyStart: IfcDateTime | null;
	EarlyFinish: IfcDateTime | null;
	LateStart: IfcDateTime | null;
	LateFinish: IfcDateTime | null;
	FreeFloat: IfcDuration | null;
	TotalFloat: IfcDuration | null;
	IsCritical: IfcBoolean | null;
	StatusTime: IfcDateTime | null;
	ActualDuration: IfcDuration | null;
	ActualStart: IfcDateTime | null;
	ActualFinish: IfcDateTime | null;
	RemainingTime: IfcDuration | null;
	Completion: IfcPositiveRatioMeasure | null;
	Recurrence: (Handle<IfcRecurrencePattern> | IfcRecurrencePattern) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTaskTimeRecurring
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let DurationType = tape[ptr++];
		let ScheduleDuration = tape[ptr++];
		let ScheduleStart = tape[ptr++];
		let ScheduleFinish = tape[ptr++];
		let EarlyStart = tape[ptr++];
		let EarlyFinish = tape[ptr++];
		let LateStart = tape[ptr++];
		let LateFinish = tape[ptr++];
		let FreeFloat = tape[ptr++];
		let TotalFloat = tape[ptr++];
		let IsCritical = tape[ptr++];
		let StatusTime = tape[ptr++];
		let ActualDuration = tape[ptr++];
		let ActualStart = tape[ptr++];
		let ActualFinish = tape[ptr++];
		let RemainingTime = tape[ptr++];
		let Completion = tape[ptr++];
		let Recurrence = tape[ptr++];
		return new IfcTaskTimeRecurring(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, DurationType, ScheduleDuration, ScheduleStart, ScheduleFinish, EarlyStart, EarlyFinish, LateStart, LateFinish, FreeFloat, TotalFloat, IsCritical, StatusTime, ActualDuration, ActualStart, ActualFinish, RemainingTime, Completion, Recurrence);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.DurationType);;
		args.push(this.ScheduleDuration);;
		args.push(this.ScheduleStart);;
		args.push(this.ScheduleFinish);;
		args.push(this.EarlyStart);;
		args.push(this.EarlyFinish);;
		args.push(this.LateStart);;
		args.push(this.LateFinish);;
		args.push(this.FreeFloat);;
		args.push(this.TotalFloat);;
		args.push(this.IsCritical);;
		args.push(this.StatusTime);;
		args.push(this.ActualDuration);;
		args.push(this.ActualStart);;
		args.push(this.ActualFinish);;
		args.push(this.RemainingTime);;
		args.push(this.Completion);;
		args.push(this.Recurrence);;
		return args;
	}
};
export class IfcTaskType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ProcessType: IfcLabel | null, PredefinedType: IfcTaskTypeEnum , WorkMethod: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ProcessType = ProcessType;
		this.PredefinedType = PredefinedType;
		this.WorkMethod = WorkMethod;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ProcessType: IfcLabel | null;
	PredefinedType: IfcTaskTypeEnum ;
	WorkMethod: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTaskType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ProcessType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let WorkMethod = tape[ptr++];
		return new IfcTaskType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ProcessType, PredefinedType, WorkMethod);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ProcessType);;
		args.push(this.PredefinedType);;
		args.push(this.WorkMethod);;
		return args;
	}
};
export class IfcTelecomAddress {
	constructor(expressID: number, type: number, Purpose: IfcAddressTypeEnum | null, Description: IfcText | null, UserDefinedPurpose: IfcLabel | null, TelephoneNumbers: IfcLabel[] | null, FacsimileNumbers: IfcLabel[] | null, PagerNumber: IfcLabel | null, ElectronicMailAddresses: IfcLabel[] | null, WWWHomePageURL: IfcURIReference | null, MessagingIDs: IfcURIReference[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Purpose = Purpose;
		this.Description = Description;
		this.UserDefinedPurpose = UserDefinedPurpose;
		this.TelephoneNumbers = TelephoneNumbers;
		this.FacsimileNumbers = FacsimileNumbers;
		this.PagerNumber = PagerNumber;
		this.ElectronicMailAddresses = ElectronicMailAddresses;
		this.WWWHomePageURL = WWWHomePageURL;
		this.MessagingIDs = MessagingIDs;
	}
	expressID: number;
	type: number;
	Purpose: IfcAddressTypeEnum | null;
	Description: IfcText | null;
	UserDefinedPurpose: IfcLabel | null;
	TelephoneNumbers: IfcLabel[] | null;
	FacsimileNumbers: IfcLabel[] | null;
	PagerNumber: IfcLabel | null;
	ElectronicMailAddresses: IfcLabel[] | null;
	WWWHomePageURL: IfcURIReference | null;
	MessagingIDs: IfcURIReference[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTelecomAddress
	{
		let ptr = 0;
		let Purpose = tape[ptr++];
		let Description = tape[ptr++];
		let UserDefinedPurpose = tape[ptr++];
		let TelephoneNumbers = tape[ptr++];
		let FacsimileNumbers = tape[ptr++];
		let PagerNumber = tape[ptr++];
		let ElectronicMailAddresses = tape[ptr++];
		let WWWHomePageURL = tape[ptr++];
		let MessagingIDs = tape[ptr++];
		return new IfcTelecomAddress(expressID, type, Purpose, Description, UserDefinedPurpose, TelephoneNumbers, FacsimileNumbers, PagerNumber, ElectronicMailAddresses, WWWHomePageURL, MessagingIDs);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Purpose);;
		args.push(this.Description);;
		args.push(this.UserDefinedPurpose);;
		args.push(this.TelephoneNumbers);;
		args.push(this.FacsimileNumbers);;
		args.push(this.PagerNumber);;
		args.push(this.ElectronicMailAddresses);;
		args.push(this.WWWHomePageURL);;
		args.push(this.MessagingIDs);;
		return args;
	}
};
export class IfcTendon {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null, PredefinedType: IfcTendonTypeEnum | null, NominalDiameter: IfcPositiveLengthMeasure | null, CrossSectionArea: IfcAreaMeasure | null, TensionForce: IfcForceMeasure | null, PreStress: IfcPressureMeasure | null, FrictionCoefficient: IfcNormalisedRatioMeasure | null, AnchorageSlip: IfcPositiveLengthMeasure | null, MinCurvatureRadius: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
		this.PredefinedType = PredefinedType;
		this.NominalDiameter = NominalDiameter;
		this.CrossSectionArea = CrossSectionArea;
		this.TensionForce = TensionForce;
		this.PreStress = PreStress;
		this.FrictionCoefficient = FrictionCoefficient;
		this.AnchorageSlip = AnchorageSlip;
		this.MinCurvatureRadius = MinCurvatureRadius;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	PredefinedType: IfcTendonTypeEnum | null;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	CrossSectionArea: IfcAreaMeasure | null;
	TensionForce: IfcForceMeasure | null;
	PreStress: IfcPressureMeasure | null;
	FrictionCoefficient: IfcNormalisedRatioMeasure | null;
	AnchorageSlip: IfcPositiveLengthMeasure | null;
	MinCurvatureRadius: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendon
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let CrossSectionArea = tape[ptr++];
		let TensionForce = tape[ptr++];
		let PreStress = tape[ptr++];
		let FrictionCoefficient = tape[ptr++];
		let AnchorageSlip = tape[ptr++];
		let MinCurvatureRadius = tape[ptr++];
		return new IfcTendon(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade, PredefinedType, NominalDiameter, CrossSectionArea, TensionForce, PreStress, FrictionCoefficient, AnchorageSlip, MinCurvatureRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		args.push(this.PredefinedType);;
		args.push(this.NominalDiameter);;
		args.push(this.CrossSectionArea);;
		args.push(this.TensionForce);;
		args.push(this.PreStress);;
		args.push(this.FrictionCoefficient);;
		args.push(this.AnchorageSlip);;
		args.push(this.MinCurvatureRadius);;
		return args;
	}
};
export class IfcTendonAnchor {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null, PredefinedType: IfcTendonAnchorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	PredefinedType: IfcTendonAnchorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendonAnchor
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTendonAnchor(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTendonAnchorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTendonAnchorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTendonAnchorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendonAnchorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTendonAnchorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTendonConduit {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, SteelGrade: IfcLabel | null, PredefinedType: IfcTendonConduitTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.SteelGrade = SteelGrade;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	SteelGrade: IfcLabel | null;
	PredefinedType: IfcTendonConduitTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendonConduit
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let SteelGrade = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTendonConduit(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, SteelGrade, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.SteelGrade);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTendonConduitType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTendonConduitTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTendonConduitTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendonConduitType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTendonConduitType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTendonType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTendonTypeEnum , NominalDiameter: IfcPositiveLengthMeasure | null, CrossSectionArea: IfcAreaMeasure | null, SheathDiameter: IfcPositiveLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.NominalDiameter = NominalDiameter;
		this.CrossSectionArea = CrossSectionArea;
		this.SheathDiameter = SheathDiameter;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTendonTypeEnum ;
	NominalDiameter: IfcPositiveLengthMeasure | null;
	CrossSectionArea: IfcAreaMeasure | null;
	SheathDiameter: IfcPositiveLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTendonType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let NominalDiameter = tape[ptr++];
		let CrossSectionArea = tape[ptr++];
		let SheathDiameter = tape[ptr++];
		return new IfcTendonType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, NominalDiameter, CrossSectionArea, SheathDiameter);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.NominalDiameter);;
		args.push(this.CrossSectionArea);;
		args.push(this.SheathDiameter);;
		return args;
	}
};
export class IfcTessellatedFaceSet {
	constructor(expressID: number, type: number, Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
	}
	expressID: number;
	type: number;
	Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTessellatedFaceSet
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		return new IfcTessellatedFaceSet(expressID, type, Coordinates);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		return args;
	}
};
export class IfcTessellatedItem {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTessellatedItem
	{
		let ptr = 0;
		return new IfcTessellatedItem(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcTextLiteral {
	constructor(expressID: number, type: number, Literal: IfcPresentableText , Placement: IfcAxis2Placement , Path: IfcTextPath )
	{
		this.expressID = expressID;
		this.type = type;
		this.Literal = Literal;
		this.Placement = Placement;
		this.Path = Path;
	}
	expressID: number;
	type: number;
	Literal: IfcPresentableText ;
	Placement: IfcAxis2Placement ;
	Path: IfcTextPath ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextLiteral
	{
		let ptr = 0;
		let Literal = tape[ptr++];
		let Placement = tape[ptr++];
		let Path = tape[ptr++];
		return new IfcTextLiteral(expressID, type, Literal, Placement, Path);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Literal);;
		args.push(this.Placement);;
		args.push(this.Path);;
		return args;
	}
};
export class IfcTextLiteralWithExtent {
	constructor(expressID: number, type: number, Literal: IfcPresentableText , Placement: IfcAxis2Placement , Path: IfcTextPath , Extent: (Handle<IfcPlanarExtent> | IfcPlanarExtent) , BoxAlignment: IfcBoxAlignment )
	{
		this.expressID = expressID;
		this.type = type;
		this.Literal = Literal;
		this.Placement = Placement;
		this.Path = Path;
		this.Extent = Extent;
		this.BoxAlignment = BoxAlignment;
	}
	expressID: number;
	type: number;
	Literal: IfcPresentableText ;
	Placement: IfcAxis2Placement ;
	Path: IfcTextPath ;
	Extent: (Handle<IfcPlanarExtent> | IfcPlanarExtent) ;
	BoxAlignment: IfcBoxAlignment ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextLiteralWithExtent
	{
		let ptr = 0;
		let Literal = tape[ptr++];
		let Placement = tape[ptr++];
		let Path = tape[ptr++];
		let Extent = tape[ptr++];
		let BoxAlignment = tape[ptr++];
		return new IfcTextLiteralWithExtent(expressID, type, Literal, Placement, Path, Extent, BoxAlignment);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Literal);;
		args.push(this.Placement);;
		args.push(this.Path);;
		args.push(this.Extent);;
		args.push(this.BoxAlignment);;
		return args;
	}
};
export class IfcTextStyle {
	constructor(expressID: number, type: number, Name: IfcLabel | null, TextCharacterAppearance: (Handle<IfcTextStyleForDefinedFont> | IfcTextStyleForDefinedFont) | null, TextStyle: (Handle<IfcTextStyleTextModel> | IfcTextStyleTextModel) | null, TextFontStyle: IfcTextFontSelect , ModelOrDraughting: IfcBoolean | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.TextCharacterAppearance = TextCharacterAppearance;
		this.TextStyle = TextStyle;
		this.TextFontStyle = TextFontStyle;
		this.ModelOrDraughting = ModelOrDraughting;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	TextCharacterAppearance: (Handle<IfcTextStyleForDefinedFont> | IfcTextStyleForDefinedFont) | null;
	TextStyle: (Handle<IfcTextStyleTextModel> | IfcTextStyleTextModel) | null;
	TextFontStyle: IfcTextFontSelect ;
	ModelOrDraughting: IfcBoolean | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextStyle
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let TextCharacterAppearance = tape[ptr++];
		let TextStyle = tape[ptr++];
		let TextFontStyle = tape[ptr++];
		let ModelOrDraughting = tape[ptr++];
		return new IfcTextStyle(expressID, type, Name, TextCharacterAppearance, TextStyle, TextFontStyle, ModelOrDraughting);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.TextCharacterAppearance);;
		args.push(this.TextStyle);;
		args.push(this.TextFontStyle);;
		args.push(this.ModelOrDraughting);;
		return args;
	}
};
export class IfcTextStyleFontModel {
	constructor(expressID: number, type: number, Name: IfcLabel , FontFamily: IfcTextFontName[] , FontStyle: IfcFontStyle | null, FontVariant: IfcFontVariant | null, FontWeight: IfcFontWeight | null, FontSize: IfcSizeSelect )
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.FontFamily = FontFamily;
		this.FontStyle = FontStyle;
		this.FontVariant = FontVariant;
		this.FontWeight = FontWeight;
		this.FontSize = FontSize;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	FontFamily: IfcTextFontName[] ;
	FontStyle: IfcFontStyle | null;
	FontVariant: IfcFontVariant | null;
	FontWeight: IfcFontWeight | null;
	FontSize: IfcSizeSelect ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextStyleFontModel
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let FontFamily = tape[ptr++];
		let FontStyle = tape[ptr++];
		let FontVariant = tape[ptr++];
		let FontWeight = tape[ptr++];
		let FontSize = tape[ptr++];
		return new IfcTextStyleFontModel(expressID, type, Name, FontFamily, FontStyle, FontVariant, FontWeight, FontSize);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.FontFamily);;
		args.push(this.FontStyle);;
		args.push(this.FontVariant);;
		args.push(this.FontWeight);;
		args.push(this.FontSize);;
		return args;
	}
};
export class IfcTextStyleForDefinedFont {
	constructor(expressID: number, type: number, Colour: IfcColour , BackgroundColour: IfcColour | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Colour = Colour;
		this.BackgroundColour = BackgroundColour;
	}
	expressID: number;
	type: number;
	Colour: IfcColour ;
	BackgroundColour: IfcColour | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextStyleForDefinedFont
	{
		let ptr = 0;
		let Colour = tape[ptr++];
		let BackgroundColour = tape[ptr++];
		return new IfcTextStyleForDefinedFont(expressID, type, Colour, BackgroundColour);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Colour);;
		args.push(this.BackgroundColour);;
		return args;
	}
};
export class IfcTextStyleTextModel {
	constructor(expressID: number, type: number, TextIndent: IfcSizeSelect | null, TextAlign: IfcTextAlignment | null, TextDecoration: IfcTextDecoration | null, LetterSpacing: IfcSizeSelect | null, WordSpacing: IfcSizeSelect | null, TextTransform: IfcTextTransformation | null, LineHeight: IfcSizeSelect | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.TextIndent = TextIndent;
		this.TextAlign = TextAlign;
		this.TextDecoration = TextDecoration;
		this.LetterSpacing = LetterSpacing;
		this.WordSpacing = WordSpacing;
		this.TextTransform = TextTransform;
		this.LineHeight = LineHeight;
	}
	expressID: number;
	type: number;
	TextIndent: IfcSizeSelect | null;
	TextAlign: IfcTextAlignment | null;
	TextDecoration: IfcTextDecoration | null;
	LetterSpacing: IfcSizeSelect | null;
	WordSpacing: IfcSizeSelect | null;
	TextTransform: IfcTextTransformation | null;
	LineHeight: IfcSizeSelect | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextStyleTextModel
	{
		let ptr = 0;
		let TextIndent = tape[ptr++];
		let TextAlign = tape[ptr++];
		let TextDecoration = tape[ptr++];
		let LetterSpacing = tape[ptr++];
		let WordSpacing = tape[ptr++];
		let TextTransform = tape[ptr++];
		let LineHeight = tape[ptr++];
		return new IfcTextStyleTextModel(expressID, type, TextIndent, TextAlign, TextDecoration, LetterSpacing, WordSpacing, TextTransform, LineHeight);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TextIndent);;
		args.push(this.TextAlign);;
		args.push(this.TextDecoration);;
		args.push(this.LetterSpacing);;
		args.push(this.WordSpacing);;
		args.push(this.TextTransform);;
		args.push(this.LineHeight);;
		return args;
	}
};
export class IfcTextureCoordinate {
	constructor(expressID: number, type: number, Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Maps = Maps;
	}
	expressID: number;
	type: number;
	Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextureCoordinate
	{
		let ptr = 0;
		let Maps = tape[ptr++];
		return new IfcTextureCoordinate(expressID, type, Maps);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Maps);;
		return args;
	}
};
export class IfcTextureCoordinateGenerator {
	constructor(expressID: number, type: number, Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] , Mode: IfcLabel , Parameter: IfcReal[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Maps = Maps;
		this.Mode = Mode;
		this.Parameter = Parameter;
	}
	expressID: number;
	type: number;
	Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	Mode: IfcLabel ;
	Parameter: IfcReal[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextureCoordinateGenerator
	{
		let ptr = 0;
		let Maps = tape[ptr++];
		let Mode = tape[ptr++];
		let Parameter = tape[ptr++];
		return new IfcTextureCoordinateGenerator(expressID, type, Maps, Mode, Parameter);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Maps);;
		args.push(this.Mode);;
		args.push(this.Parameter);;
		return args;
	}
};
export class IfcTextureMap {
	constructor(expressID: number, type: number, Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] , Vertices: (Handle<IfcTextureVertex> | IfcTextureVertex)[] , MappedTo: (Handle<IfcFace> | IfcFace) )
	{
		this.expressID = expressID;
		this.type = type;
		this.Maps = Maps;
		this.Vertices = Vertices;
		this.MappedTo = MappedTo;
	}
	expressID: number;
	type: number;
	Maps: (Handle<IfcSurfaceTexture> | IfcSurfaceTexture)[] ;
	Vertices: (Handle<IfcTextureVertex> | IfcTextureVertex)[] ;
	MappedTo: (Handle<IfcFace> | IfcFace) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextureMap
	{
		let ptr = 0;
		let Maps = tape[ptr++];
		let Vertices = tape[ptr++];
		let MappedTo = tape[ptr++];
		return new IfcTextureMap(expressID, type, Maps, Vertices, MappedTo);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Maps);;
		args.push(this.Vertices);;
		args.push(this.MappedTo);;
		return args;
	}
};
export class IfcTextureVertex {
	constructor(expressID: number, type: number, Coordinates: IfcParameterValue[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
	}
	expressID: number;
	type: number;
	Coordinates: IfcParameterValue[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextureVertex
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		return new IfcTextureVertex(expressID, type, Coordinates);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		return args;
	}
};
export class IfcTextureVertexList {
	constructor(expressID: number, type: number, TexCoordsList: IfcParameterValue[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.TexCoordsList = TexCoordsList;
	}
	expressID: number;
	type: number;
	TexCoordsList: IfcParameterValue[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTextureVertexList
	{
		let ptr = 0;
		let TexCoordsList = tape[ptr++];
		return new IfcTextureVertexList(expressID, type, TexCoordsList);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.TexCoordsList);;
		return args;
	}
};
export class IfcTimePeriod {
	constructor(expressID: number, type: number, StartTime: IfcTime , EndTime: IfcTime )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartTime = StartTime;
		this.EndTime = EndTime;
	}
	expressID: number;
	type: number;
	StartTime: IfcTime ;
	EndTime: IfcTime ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTimePeriod
	{
		let ptr = 0;
		let StartTime = tape[ptr++];
		let EndTime = tape[ptr++];
		return new IfcTimePeriod(expressID, type, StartTime, EndTime);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartTime);;
		args.push(this.EndTime);;
		return args;
	}
};
export class IfcTimeSeries {
	constructor(expressID: number, type: number, Name: IfcLabel , Description: IfcText | null, StartTime: IfcDateTime , EndTime: IfcDateTime , TimeSeriesDataType: IfcTimeSeriesDataTypeEnum , DataOrigin: IfcDataOriginEnum , UserDefinedDataOrigin: IfcLabel | null, Unit: IfcUnit | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.Description = Description;
		this.StartTime = StartTime;
		this.EndTime = EndTime;
		this.TimeSeriesDataType = TimeSeriesDataType;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.Unit = Unit;
	}
	expressID: number;
	type: number;
	Name: IfcLabel ;
	Description: IfcText | null;
	StartTime: IfcDateTime ;
	EndTime: IfcDateTime ;
	TimeSeriesDataType: IfcTimeSeriesDataTypeEnum ;
	DataOrigin: IfcDataOriginEnum ;
	UserDefinedDataOrigin: IfcLabel | null;
	Unit: IfcUnit | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTimeSeries
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let StartTime = tape[ptr++];
		let EndTime = tape[ptr++];
		let TimeSeriesDataType = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let Unit = tape[ptr++];
		return new IfcTimeSeries(expressID, type, Name, Description, StartTime, EndTime, TimeSeriesDataType, DataOrigin, UserDefinedDataOrigin, Unit);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.StartTime);;
		args.push(this.EndTime);;
		args.push(this.TimeSeriesDataType);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.Unit);;
		return args;
	}
};
export class IfcTimeSeriesValue {
	constructor(expressID: number, type: number, ListValues: IfcValue[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ListValues = ListValues;
	}
	expressID: number;
	type: number;
	ListValues: IfcValue[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTimeSeriesValue
	{
		let ptr = 0;
		let ListValues = tape[ptr++];
		return new IfcTimeSeriesValue(expressID, type, ListValues);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ListValues);;
		return args;
	}
};
export class IfcTopologicalRepresentationItem {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTopologicalRepresentationItem
	{
		let ptr = 0;
		return new IfcTopologicalRepresentationItem(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcTopologyRepresentation {
	constructor(expressID: number, type: number, ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) , RepresentationIdentifier: IfcLabel | null, RepresentationType: IfcLabel | null, Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.ContextOfItems = ContextOfItems;
		this.RepresentationIdentifier = RepresentationIdentifier;
		this.RepresentationType = RepresentationType;
		this.Items = Items;
	}
	expressID: number;
	type: number;
	ContextOfItems: (Handle<IfcRepresentationContext> | IfcRepresentationContext) ;
	RepresentationIdentifier: IfcLabel | null;
	RepresentationType: IfcLabel | null;
	Items: (Handle<IfcRepresentationItem> | IfcRepresentationItem)[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTopologyRepresentation
	{
		let ptr = 0;
		let ContextOfItems = tape[ptr++];
		let RepresentationIdentifier = tape[ptr++];
		let RepresentationType = tape[ptr++];
		let Items = tape[ptr++];
		return new IfcTopologyRepresentation(expressID, type, ContextOfItems, RepresentationIdentifier, RepresentationType, Items);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ContextOfItems);;
		args.push(this.RepresentationIdentifier);;
		args.push(this.RepresentationType);;
		args.push(this.Items);;
		return args;
	}
};
export class IfcToroidalSurface {
	constructor(expressID: number, type: number, Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) , MajorRadius: IfcPositiveLengthMeasure , MinorRadius: IfcPositiveLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Position = Position;
		this.MajorRadius = MajorRadius;
		this.MinorRadius = MinorRadius;
	}
	expressID: number;
	type: number;
	Position: (Handle<IfcAxis2Placement3D> | IfcAxis2Placement3D) ;
	MajorRadius: IfcPositiveLengthMeasure ;
	MinorRadius: IfcPositiveLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcToroidalSurface
	{
		let ptr = 0;
		let Position = tape[ptr++];
		let MajorRadius = tape[ptr++];
		let MinorRadius = tape[ptr++];
		return new IfcToroidalSurface(expressID, type, Position, MajorRadius, MinorRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Position);;
		args.push(this.MajorRadius);;
		args.push(this.MinorRadius);;
		return args;
	}
};
export class IfcTransformer {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcTransformerTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcTransformerTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTransformer
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTransformer(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTransformerType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTransformerTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTransformerTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTransformerType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTransformerType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTransitionCurveSegment2D {
	constructor(expressID: number, type: number, StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) , StartDirection: IfcPlaneAngleMeasure , SegmentLength: IfcPositiveLengthMeasure , StartRadius: IfcPositiveLengthMeasure | null, EndRadius: IfcPositiveLengthMeasure | null, IsStartRadiusCCW: IfcBoolean , IsEndRadiusCCW: IfcBoolean , TransitionCurveType: IfcTransitionCurveType )
	{
		this.expressID = expressID;
		this.type = type;
		this.StartPoint = StartPoint;
		this.StartDirection = StartDirection;
		this.SegmentLength = SegmentLength;
		this.StartRadius = StartRadius;
		this.EndRadius = EndRadius;
		this.IsStartRadiusCCW = IsStartRadiusCCW;
		this.IsEndRadiusCCW = IsEndRadiusCCW;
		this.TransitionCurveType = TransitionCurveType;
	}
	expressID: number;
	type: number;
	StartPoint: (Handle<IfcCartesianPoint> | IfcCartesianPoint) ;
	StartDirection: IfcPlaneAngleMeasure ;
	SegmentLength: IfcPositiveLengthMeasure ;
	StartRadius: IfcPositiveLengthMeasure | null;
	EndRadius: IfcPositiveLengthMeasure | null;
	IsStartRadiusCCW: IfcBoolean ;
	IsEndRadiusCCW: IfcBoolean ;
	TransitionCurveType: IfcTransitionCurveType ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTransitionCurveSegment2D
	{
		let ptr = 0;
		let StartPoint = tape[ptr++];
		let StartDirection = tape[ptr++];
		let SegmentLength = tape[ptr++];
		let StartRadius = tape[ptr++];
		let EndRadius = tape[ptr++];
		let IsStartRadiusCCW = tape[ptr++];
		let IsEndRadiusCCW = tape[ptr++];
		let TransitionCurveType = tape[ptr++];
		return new IfcTransitionCurveSegment2D(expressID, type, StartPoint, StartDirection, SegmentLength, StartRadius, EndRadius, IsStartRadiusCCW, IsEndRadiusCCW, TransitionCurveType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.StartPoint);;
		args.push(this.StartDirection);;
		args.push(this.SegmentLength);;
		args.push(this.StartRadius);;
		args.push(this.EndRadius);;
		args.push(this.IsStartRadiusCCW);;
		args.push(this.IsEndRadiusCCW);;
		args.push(this.TransitionCurveType);;
		return args;
	}
};
export class IfcTransportElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcTransportElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcTransportElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTransportElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTransportElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTransportElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTransportElementTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTransportElementTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTransportElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTransportElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTrapeziumProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, BottomXDim: IfcPositiveLengthMeasure , TopXDim: IfcPositiveLengthMeasure , YDim: IfcPositiveLengthMeasure , TopXOffset: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.BottomXDim = BottomXDim;
		this.TopXDim = TopXDim;
		this.YDim = YDim;
		this.TopXOffset = TopXOffset;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	BottomXDim: IfcPositiveLengthMeasure ;
	TopXDim: IfcPositiveLengthMeasure ;
	YDim: IfcPositiveLengthMeasure ;
	TopXOffset: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTrapeziumProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let BottomXDim = tape[ptr++];
		let TopXDim = tape[ptr++];
		let YDim = tape[ptr++];
		let TopXOffset = tape[ptr++];
		return new IfcTrapeziumProfileDef(expressID, type, ProfileType, ProfileName, Position, BottomXDim, TopXDim, YDim, TopXOffset);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.BottomXDim);;
		args.push(this.TopXDim);;
		args.push(this.YDim);;
		args.push(this.TopXOffset);;
		return args;
	}
};
export class IfcTriangulatedFaceSet {
	constructor(expressID: number, type: number, Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) , Normals: IfcParameterValue[] | null, Closed: IfcBoolean | null, CoordIndex: IfcPositiveInteger[] , PnIndex: IfcPositiveInteger[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
		this.Normals = Normals;
		this.Closed = Closed;
		this.CoordIndex = CoordIndex;
		this.PnIndex = PnIndex;
	}
	expressID: number;
	type: number;
	Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) ;
	Normals: IfcParameterValue[] | null;
	Closed: IfcBoolean | null;
	CoordIndex: IfcPositiveInteger[] ;
	PnIndex: IfcPositiveInteger[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTriangulatedFaceSet
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		let Normals = tape[ptr++];
		let Closed = tape[ptr++];
		let CoordIndex = tape[ptr++];
		let PnIndex = tape[ptr++];
		return new IfcTriangulatedFaceSet(expressID, type, Coordinates, Normals, Closed, CoordIndex, PnIndex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		args.push(this.Normals);;
		args.push(this.Closed);;
		args.push(this.CoordIndex);;
		args.push(this.PnIndex);;
		return args;
	}
};
export class IfcTriangulatedIrregularNetwork {
	constructor(expressID: number, type: number, Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) , Normals: IfcParameterValue[] | null, Closed: IfcBoolean | null, CoordIndex: IfcPositiveInteger[] , PnIndex: IfcPositiveInteger[] | null, Flags: IfcInteger[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Coordinates = Coordinates;
		this.Normals = Normals;
		this.Closed = Closed;
		this.CoordIndex = CoordIndex;
		this.PnIndex = PnIndex;
		this.Flags = Flags;
	}
	expressID: number;
	type: number;
	Coordinates: (Handle<IfcCartesianPointList3D> | IfcCartesianPointList3D) ;
	Normals: IfcParameterValue[] | null;
	Closed: IfcBoolean | null;
	CoordIndex: IfcPositiveInteger[] ;
	PnIndex: IfcPositiveInteger[] | null;
	Flags: IfcInteger[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTriangulatedIrregularNetwork
	{
		let ptr = 0;
		let Coordinates = tape[ptr++];
		let Normals = tape[ptr++];
		let Closed = tape[ptr++];
		let CoordIndex = tape[ptr++];
		let PnIndex = tape[ptr++];
		let Flags = tape[ptr++];
		return new IfcTriangulatedIrregularNetwork(expressID, type, Coordinates, Normals, Closed, CoordIndex, PnIndex, Flags);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Coordinates);;
		args.push(this.Normals);;
		args.push(this.Closed);;
		args.push(this.CoordIndex);;
		args.push(this.PnIndex);;
		args.push(this.Flags);;
		return args;
	}
};
export class IfcTrimmedCurve {
	constructor(expressID: number, type: number, BasisCurve: (Handle<IfcCurve> | IfcCurve) , Trim1: IfcTrimmingSelect[] , Trim2: IfcTrimmingSelect[] , SenseAgreement: IfcBoolean , MasterRepresentation: IfcTrimmingPreference )
	{
		this.expressID = expressID;
		this.type = type;
		this.BasisCurve = BasisCurve;
		this.Trim1 = Trim1;
		this.Trim2 = Trim2;
		this.SenseAgreement = SenseAgreement;
		this.MasterRepresentation = MasterRepresentation;
	}
	expressID: number;
	type: number;
	BasisCurve: (Handle<IfcCurve> | IfcCurve) ;
	Trim1: IfcTrimmingSelect[] ;
	Trim2: IfcTrimmingSelect[] ;
	SenseAgreement: IfcBoolean ;
	MasterRepresentation: IfcTrimmingPreference ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTrimmedCurve
	{
		let ptr = 0;
		let BasisCurve = tape[ptr++];
		let Trim1 = tape[ptr++];
		let Trim2 = tape[ptr++];
		let SenseAgreement = tape[ptr++];
		let MasterRepresentation = tape[ptr++];
		return new IfcTrimmedCurve(expressID, type, BasisCurve, Trim1, Trim2, SenseAgreement, MasterRepresentation);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.BasisCurve);;
		args.push(this.Trim1);;
		args.push(this.Trim2);;
		args.push(this.SenseAgreement);;
		args.push(this.MasterRepresentation);;
		return args;
	}
};
export class IfcTubeBundle {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcTubeBundleTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcTubeBundleTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTubeBundle
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTubeBundle(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTubeBundleType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcTubeBundleTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcTubeBundleTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTubeBundleType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcTubeBundleType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcTypeObject {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTypeObject
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		return new IfcTypeObject(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		return args;
	}
};
export class IfcTypeProcess {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ProcessType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ProcessType = ProcessType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ProcessType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTypeProcess
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ProcessType = tape[ptr++];
		return new IfcTypeProcess(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ProcessType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ProcessType);;
		return args;
	}
};
export class IfcTypeProduct {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTypeProduct
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcTypeProduct(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcTypeResource {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, Identification: IfcIdentifier | null, LongDescription: IfcText | null, ResourceType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.Identification = Identification;
		this.LongDescription = LongDescription;
		this.ResourceType = ResourceType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	Identification: IfcIdentifier | null;
	LongDescription: IfcText | null;
	ResourceType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcTypeResource
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let Identification = tape[ptr++];
		let LongDescription = tape[ptr++];
		let ResourceType = tape[ptr++];
		return new IfcTypeResource(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, Identification, LongDescription, ResourceType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.Identification);;
		args.push(this.LongDescription);;
		args.push(this.ResourceType);;
		return args;
	}
};
export class IfcUShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Depth: IfcPositiveLengthMeasure , FlangeWidth: IfcPositiveLengthMeasure , WebThickness: IfcPositiveLengthMeasure , FlangeThickness: IfcPositiveLengthMeasure , FilletRadius: IfcNonNegativeLengthMeasure | null, EdgeRadius: IfcNonNegativeLengthMeasure | null, FlangeSlope: IfcPlaneAngleMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Depth = Depth;
		this.FlangeWidth = FlangeWidth;
		this.WebThickness = WebThickness;
		this.FlangeThickness = FlangeThickness;
		this.FilletRadius = FilletRadius;
		this.EdgeRadius = EdgeRadius;
		this.FlangeSlope = FlangeSlope;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Depth: IfcPositiveLengthMeasure ;
	FlangeWidth: IfcPositiveLengthMeasure ;
	WebThickness: IfcPositiveLengthMeasure ;
	FlangeThickness: IfcPositiveLengthMeasure ;
	FilletRadius: IfcNonNegativeLengthMeasure | null;
	EdgeRadius: IfcNonNegativeLengthMeasure | null;
	FlangeSlope: IfcPlaneAngleMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Depth = tape[ptr++];
		let FlangeWidth = tape[ptr++];
		let WebThickness = tape[ptr++];
		let FlangeThickness = tape[ptr++];
		let FilletRadius = tape[ptr++];
		let EdgeRadius = tape[ptr++];
		let FlangeSlope = tape[ptr++];
		return new IfcUShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, Depth, FlangeWidth, WebThickness, FlangeThickness, FilletRadius, EdgeRadius, FlangeSlope);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Depth);;
		args.push(this.FlangeWidth);;
		args.push(this.WebThickness);;
		args.push(this.FlangeThickness);;
		args.push(this.FilletRadius);;
		args.push(this.EdgeRadius);;
		args.push(this.FlangeSlope);;
		return args;
	}
};
export class IfcUnitAssignment {
	constructor(expressID: number, type: number, Units: IfcUnit[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.Units = Units;
	}
	expressID: number;
	type: number;
	Units: IfcUnit[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUnitAssignment
	{
		let ptr = 0;
		let Units = tape[ptr++];
		return new IfcUnitAssignment(expressID, type, Units);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Units);;
		return args;
	}
};
export class IfcUnitaryControlElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcUnitaryControlElementTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcUnitaryControlElementTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUnitaryControlElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcUnitaryControlElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcUnitaryControlElementType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcUnitaryControlElementTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcUnitaryControlElementTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUnitaryControlElementType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcUnitaryControlElementType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcUnitaryEquipment {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcUnitaryEquipmentTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcUnitaryEquipmentTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUnitaryEquipment
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcUnitaryEquipment(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcUnitaryEquipmentType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcUnitaryEquipmentTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcUnitaryEquipmentTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcUnitaryEquipmentType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcUnitaryEquipmentType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcValve {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcValveTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcValveTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcValve
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcValve(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcValveType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcValveTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcValveTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcValveType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcValveType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcVector {
	constructor(expressID: number, type: number, Orientation: (Handle<IfcDirection> | IfcDirection) , Magnitude: IfcLengthMeasure )
	{
		this.expressID = expressID;
		this.type = type;
		this.Orientation = Orientation;
		this.Magnitude = Magnitude;
	}
	expressID: number;
	type: number;
	Orientation: (Handle<IfcDirection> | IfcDirection) ;
	Magnitude: IfcLengthMeasure ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVector
	{
		let ptr = 0;
		let Orientation = tape[ptr++];
		let Magnitude = tape[ptr++];
		return new IfcVector(expressID, type, Orientation, Magnitude);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Orientation);;
		args.push(this.Magnitude);;
		return args;
	}
};
export class IfcVertex {
	constructor(expressID: number, type: number, )
	{
		this.expressID = expressID;
		this.type = type;
	}
	expressID: number;
	type: number;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVertex
	{
		let ptr = 0;
		return new IfcVertex(expressID, type, );
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		return args;
	}
};
export class IfcVertexLoop {
	constructor(expressID: number, type: number, LoopVertex: (Handle<IfcVertex> | IfcVertex) )
	{
		this.expressID = expressID;
		this.type = type;
		this.LoopVertex = LoopVertex;
	}
	expressID: number;
	type: number;
	LoopVertex: (Handle<IfcVertex> | IfcVertex) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVertexLoop
	{
		let ptr = 0;
		let LoopVertex = tape[ptr++];
		return new IfcVertexLoop(expressID, type, LoopVertex);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.LoopVertex);;
		return args;
	}
};
export class IfcVertexPoint {
	constructor(expressID: number, type: number, VertexGeometry: (Handle<IfcPoint> | IfcPoint) )
	{
		this.expressID = expressID;
		this.type = type;
		this.VertexGeometry = VertexGeometry;
	}
	expressID: number;
	type: number;
	VertexGeometry: (Handle<IfcPoint> | IfcPoint) ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVertexPoint
	{
		let ptr = 0;
		let VertexGeometry = tape[ptr++];
		return new IfcVertexPoint(expressID, type, VertexGeometry);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.VertexGeometry);;
		return args;
	}
};
export class IfcVibrationDamper {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcVibrationDamperTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcVibrationDamperTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVibrationDamper
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcVibrationDamper(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcVibrationDamperType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcVibrationDamperTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcVibrationDamperTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVibrationDamperType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcVibrationDamperType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcVibrationIsolator {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcVibrationIsolatorTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcVibrationIsolatorTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVibrationIsolator
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcVibrationIsolator(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcVibrationIsolatorType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcVibrationIsolatorTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcVibrationIsolatorTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVibrationIsolatorType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcVibrationIsolatorType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcVirtualElement {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVirtualElement
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		return new IfcVirtualElement(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		return args;
	}
};
export class IfcVirtualGridIntersection {
	constructor(expressID: number, type: number, IntersectingAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] , OffsetDistances: IfcLengthMeasure[] )
	{
		this.expressID = expressID;
		this.type = type;
		this.IntersectingAxes = IntersectingAxes;
		this.OffsetDistances = OffsetDistances;
	}
	expressID: number;
	type: number;
	IntersectingAxes: (Handle<IfcGridAxis> | IfcGridAxis)[] ;
	OffsetDistances: IfcLengthMeasure[] ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVirtualGridIntersection
	{
		let ptr = 0;
		let IntersectingAxes = tape[ptr++];
		let OffsetDistances = tape[ptr++];
		return new IfcVirtualGridIntersection(expressID, type, IntersectingAxes, OffsetDistances);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.IntersectingAxes);;
		args.push(this.OffsetDistances);;
		return args;
	}
};
export class IfcVoidingFeature {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcVoidingFeatureTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcVoidingFeatureTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcVoidingFeature
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcVoidingFeature(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWall {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcWallTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcWallTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWall
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWall(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWallElementedCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcWallTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcWallTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWallElementedCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWallElementedCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWallStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcWallTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcWallTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWallStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWallStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWallType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcWallTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcWallTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWallType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWallType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWasteTerminal {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, PredefinedType: IfcWasteTerminalTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	PredefinedType: IfcWasteTerminalTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWasteTerminal
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWasteTerminal(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWasteTerminalType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcWasteTerminalTypeEnum )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcWasteTerminalTypeEnum ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWasteTerminalType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWasteTerminalType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWindow {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, OverallHeight: IfcPositiveLengthMeasure | null, OverallWidth: IfcPositiveLengthMeasure | null, PredefinedType: IfcWindowTypeEnum | null, PartitioningType: IfcWindowTypePartitioningEnum | null, UserDefinedPartitioningType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.OverallHeight = OverallHeight;
		this.OverallWidth = OverallWidth;
		this.PredefinedType = PredefinedType;
		this.PartitioningType = PartitioningType;
		this.UserDefinedPartitioningType = UserDefinedPartitioningType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	OverallHeight: IfcPositiveLengthMeasure | null;
	OverallWidth: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcWindowTypeEnum | null;
	PartitioningType: IfcWindowTypePartitioningEnum | null;
	UserDefinedPartitioningType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindow
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let OverallHeight = tape[ptr++];
		let OverallWidth = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let PartitioningType = tape[ptr++];
		let UserDefinedPartitioningType = tape[ptr++];
		return new IfcWindow(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, OverallHeight, OverallWidth, PredefinedType, PartitioningType, UserDefinedPartitioningType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.OverallHeight);;
		args.push(this.OverallWidth);;
		args.push(this.PredefinedType);;
		args.push(this.PartitioningType);;
		args.push(this.UserDefinedPartitioningType);;
		return args;
	}
};
export class IfcWindowLiningProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, LiningDepth: IfcPositiveLengthMeasure | null, LiningThickness: IfcNonNegativeLengthMeasure | null, TransomThickness: IfcNonNegativeLengthMeasure | null, MullionThickness: IfcNonNegativeLengthMeasure | null, FirstTransomOffset: IfcNormalisedRatioMeasure | null, SecondTransomOffset: IfcNormalisedRatioMeasure | null, FirstMullionOffset: IfcNormalisedRatioMeasure | null, SecondMullionOffset: IfcNormalisedRatioMeasure | null, ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null, LiningOffset: IfcLengthMeasure | null, LiningToPanelOffsetX: IfcLengthMeasure | null, LiningToPanelOffsetY: IfcLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.LiningDepth = LiningDepth;
		this.LiningThickness = LiningThickness;
		this.TransomThickness = TransomThickness;
		this.MullionThickness = MullionThickness;
		this.FirstTransomOffset = FirstTransomOffset;
		this.SecondTransomOffset = SecondTransomOffset;
		this.FirstMullionOffset = FirstMullionOffset;
		this.SecondMullionOffset = SecondMullionOffset;
		this.ShapeAspectStyle = ShapeAspectStyle;
		this.LiningOffset = LiningOffset;
		this.LiningToPanelOffsetX = LiningToPanelOffsetX;
		this.LiningToPanelOffsetY = LiningToPanelOffsetY;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	LiningDepth: IfcPositiveLengthMeasure | null;
	LiningThickness: IfcNonNegativeLengthMeasure | null;
	TransomThickness: IfcNonNegativeLengthMeasure | null;
	MullionThickness: IfcNonNegativeLengthMeasure | null;
	FirstTransomOffset: IfcNormalisedRatioMeasure | null;
	SecondTransomOffset: IfcNormalisedRatioMeasure | null;
	FirstMullionOffset: IfcNormalisedRatioMeasure | null;
	SecondMullionOffset: IfcNormalisedRatioMeasure | null;
	ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null;
	LiningOffset: IfcLengthMeasure | null;
	LiningToPanelOffsetX: IfcLengthMeasure | null;
	LiningToPanelOffsetY: IfcLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindowLiningProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let LiningDepth = tape[ptr++];
		let LiningThickness = tape[ptr++];
		let TransomThickness = tape[ptr++];
		let MullionThickness = tape[ptr++];
		let FirstTransomOffset = tape[ptr++];
		let SecondTransomOffset = tape[ptr++];
		let FirstMullionOffset = tape[ptr++];
		let SecondMullionOffset = tape[ptr++];
		let ShapeAspectStyle = tape[ptr++];
		let LiningOffset = tape[ptr++];
		let LiningToPanelOffsetX = tape[ptr++];
		let LiningToPanelOffsetY = tape[ptr++];
		return new IfcWindowLiningProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, LiningDepth, LiningThickness, TransomThickness, MullionThickness, FirstTransomOffset, SecondTransomOffset, FirstMullionOffset, SecondMullionOffset, ShapeAspectStyle, LiningOffset, LiningToPanelOffsetX, LiningToPanelOffsetY);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.LiningDepth);;
		args.push(this.LiningThickness);;
		args.push(this.TransomThickness);;
		args.push(this.MullionThickness);;
		args.push(this.FirstTransomOffset);;
		args.push(this.SecondTransomOffset);;
		args.push(this.FirstMullionOffset);;
		args.push(this.SecondMullionOffset);;
		args.push(this.ShapeAspectStyle);;
		args.push(this.LiningOffset);;
		args.push(this.LiningToPanelOffsetX);;
		args.push(this.LiningToPanelOffsetY);;
		return args;
	}
};
export class IfcWindowPanelProperties {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, OperationType: IfcWindowPanelOperationEnum , PanelPosition: IfcWindowPanelPositionEnum , FrameDepth: IfcPositiveLengthMeasure | null, FrameThickness: IfcPositiveLengthMeasure | null, ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.OperationType = OperationType;
		this.PanelPosition = PanelPosition;
		this.FrameDepth = FrameDepth;
		this.FrameThickness = FrameThickness;
		this.ShapeAspectStyle = ShapeAspectStyle;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	OperationType: IfcWindowPanelOperationEnum ;
	PanelPosition: IfcWindowPanelPositionEnum ;
	FrameDepth: IfcPositiveLengthMeasure | null;
	FrameThickness: IfcPositiveLengthMeasure | null;
	ShapeAspectStyle: (Handle<IfcShapeAspect> | IfcShapeAspect) | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindowPanelProperties
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let OperationType = tape[ptr++];
		let PanelPosition = tape[ptr++];
		let FrameDepth = tape[ptr++];
		let FrameThickness = tape[ptr++];
		let ShapeAspectStyle = tape[ptr++];
		return new IfcWindowPanelProperties(expressID, type, GlobalId, OwnerHistory, Name, Description, OperationType, PanelPosition, FrameDepth, FrameThickness, ShapeAspectStyle);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.OperationType);;
		args.push(this.PanelPosition);;
		args.push(this.FrameDepth);;
		args.push(this.FrameThickness);;
		args.push(this.ShapeAspectStyle);;
		return args;
	}
};
export class IfcWindowStandardCase {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null, Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null, Tag: IfcIdentifier | null, OverallHeight: IfcPositiveLengthMeasure | null, OverallWidth: IfcPositiveLengthMeasure | null, PredefinedType: IfcWindowTypeEnum | null, PartitioningType: IfcWindowTypePartitioningEnum | null, UserDefinedPartitioningType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.ObjectPlacement = ObjectPlacement;
		this.Representation = Representation;
		this.Tag = Tag;
		this.OverallHeight = OverallHeight;
		this.OverallWidth = OverallWidth;
		this.PredefinedType = PredefinedType;
		this.PartitioningType = PartitioningType;
		this.UserDefinedPartitioningType = UserDefinedPartitioningType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	ObjectPlacement: (Handle<IfcObjectPlacement> | IfcObjectPlacement) | null;
	Representation: (Handle<IfcProductRepresentation> | IfcProductRepresentation) | null;
	Tag: IfcIdentifier | null;
	OverallHeight: IfcPositiveLengthMeasure | null;
	OverallWidth: IfcPositiveLengthMeasure | null;
	PredefinedType: IfcWindowTypeEnum | null;
	PartitioningType: IfcWindowTypePartitioningEnum | null;
	UserDefinedPartitioningType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindowStandardCase
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let ObjectPlacement = tape[ptr++];
		let Representation = tape[ptr++];
		let Tag = tape[ptr++];
		let OverallHeight = tape[ptr++];
		let OverallWidth = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let PartitioningType = tape[ptr++];
		let UserDefinedPartitioningType = tape[ptr++];
		return new IfcWindowStandardCase(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, ObjectPlacement, Representation, Tag, OverallHeight, OverallWidth, PredefinedType, PartitioningType, UserDefinedPartitioningType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.ObjectPlacement);;
		args.push(this.Representation);;
		args.push(this.Tag);;
		args.push(this.OverallHeight);;
		args.push(this.OverallWidth);;
		args.push(this.PredefinedType);;
		args.push(this.PartitioningType);;
		args.push(this.UserDefinedPartitioningType);;
		return args;
	}
};
export class IfcWindowStyle {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ConstructionType: IfcWindowStyleConstructionEnum , OperationType: IfcWindowStyleOperationEnum , ParameterTakesPrecedence: IfcBoolean , Sizeable: IfcBoolean )
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ConstructionType = ConstructionType;
		this.OperationType = OperationType;
		this.ParameterTakesPrecedence = ParameterTakesPrecedence;
		this.Sizeable = Sizeable;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ConstructionType: IfcWindowStyleConstructionEnum ;
	OperationType: IfcWindowStyleOperationEnum ;
	ParameterTakesPrecedence: IfcBoolean ;
	Sizeable: IfcBoolean ;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindowStyle
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ConstructionType = tape[ptr++];
		let OperationType = tape[ptr++];
		let ParameterTakesPrecedence = tape[ptr++];
		let Sizeable = tape[ptr++];
		return new IfcWindowStyle(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ConstructionType, OperationType, ParameterTakesPrecedence, Sizeable);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ConstructionType);;
		args.push(this.OperationType);;
		args.push(this.ParameterTakesPrecedence);;
		args.push(this.Sizeable);;
		return args;
	}
};
export class IfcWindowType {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ApplicableOccurrence: IfcIdentifier | null, HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null, RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null, Tag: IfcLabel | null, ElementType: IfcLabel | null, PredefinedType: IfcWindowTypeEnum , PartitioningType: IfcWindowTypePartitioningEnum , ParameterTakesPrecedence: IfcBoolean | null, UserDefinedPartitioningType: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ApplicableOccurrence = ApplicableOccurrence;
		this.HasPropertySets = HasPropertySets;
		this.RepresentationMaps = RepresentationMaps;
		this.Tag = Tag;
		this.ElementType = ElementType;
		this.PredefinedType = PredefinedType;
		this.PartitioningType = PartitioningType;
		this.ParameterTakesPrecedence = ParameterTakesPrecedence;
		this.UserDefinedPartitioningType = UserDefinedPartitioningType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ApplicableOccurrence: IfcIdentifier | null;
	HasPropertySets: (Handle<IfcPropertySetDefinition> | IfcPropertySetDefinition)[] | null;
	RepresentationMaps: (Handle<IfcRepresentationMap> | IfcRepresentationMap)[] | null;
	Tag: IfcLabel | null;
	ElementType: IfcLabel | null;
	PredefinedType: IfcWindowTypeEnum ;
	PartitioningType: IfcWindowTypePartitioningEnum ;
	ParameterTakesPrecedence: IfcBoolean | null;
	UserDefinedPartitioningType: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWindowType
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ApplicableOccurrence = tape[ptr++];
		let HasPropertySets = tape[ptr++];
		let RepresentationMaps = tape[ptr++];
		let Tag = tape[ptr++];
		let ElementType = tape[ptr++];
		let PredefinedType = tape[ptr++];
		let PartitioningType = tape[ptr++];
		let ParameterTakesPrecedence = tape[ptr++];
		let UserDefinedPartitioningType = tape[ptr++];
		return new IfcWindowType(expressID, type, GlobalId, OwnerHistory, Name, Description, ApplicableOccurrence, HasPropertySets, RepresentationMaps, Tag, ElementType, PredefinedType, PartitioningType, ParameterTakesPrecedence, UserDefinedPartitioningType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ApplicableOccurrence);;
		args.push(this.HasPropertySets);;
		args.push(this.RepresentationMaps);;
		args.push(this.Tag);;
		args.push(this.ElementType);;
		args.push(this.PredefinedType);;
		args.push(this.PartitioningType);;
		args.push(this.ParameterTakesPrecedence);;
		args.push(this.UserDefinedPartitioningType);;
		return args;
	}
};
export class IfcWorkCalendar {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, WorkingTimes: (Handle<IfcWorkTime> | IfcWorkTime)[] | null, ExceptionTimes: (Handle<IfcWorkTime> | IfcWorkTime)[] | null, PredefinedType: IfcWorkCalendarTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.WorkingTimes = WorkingTimes;
		this.ExceptionTimes = ExceptionTimes;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	WorkingTimes: (Handle<IfcWorkTime> | IfcWorkTime)[] | null;
	ExceptionTimes: (Handle<IfcWorkTime> | IfcWorkTime)[] | null;
	PredefinedType: IfcWorkCalendarTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWorkCalendar
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let WorkingTimes = tape[ptr++];
		let ExceptionTimes = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWorkCalendar(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, WorkingTimes, ExceptionTimes, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.WorkingTimes);;
		args.push(this.ExceptionTimes);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWorkControl {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, CreationDate: IfcDateTime , Creators: (Handle<IfcPerson> | IfcPerson)[] | null, Purpose: IfcLabel | null, Duration: IfcDuration | null, TotalFloat: IfcDuration | null, StartTime: IfcDateTime , FinishTime: IfcDateTime | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.CreationDate = CreationDate;
		this.Creators = Creators;
		this.Purpose = Purpose;
		this.Duration = Duration;
		this.TotalFloat = TotalFloat;
		this.StartTime = StartTime;
		this.FinishTime = FinishTime;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	CreationDate: IfcDateTime ;
	Creators: (Handle<IfcPerson> | IfcPerson)[] | null;
	Purpose: IfcLabel | null;
	Duration: IfcDuration | null;
	TotalFloat: IfcDuration | null;
	StartTime: IfcDateTime ;
	FinishTime: IfcDateTime | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWorkControl
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let CreationDate = tape[ptr++];
		let Creators = tape[ptr++];
		let Purpose = tape[ptr++];
		let Duration = tape[ptr++];
		let TotalFloat = tape[ptr++];
		let StartTime = tape[ptr++];
		let FinishTime = tape[ptr++];
		return new IfcWorkControl(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, CreationDate, Creators, Purpose, Duration, TotalFloat, StartTime, FinishTime);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.CreationDate);;
		args.push(this.Creators);;
		args.push(this.Purpose);;
		args.push(this.Duration);;
		args.push(this.TotalFloat);;
		args.push(this.StartTime);;
		args.push(this.FinishTime);;
		return args;
	}
};
export class IfcWorkPlan {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, CreationDate: IfcDateTime , Creators: (Handle<IfcPerson> | IfcPerson)[] | null, Purpose: IfcLabel | null, Duration: IfcDuration | null, TotalFloat: IfcDuration | null, StartTime: IfcDateTime , FinishTime: IfcDateTime | null, PredefinedType: IfcWorkPlanTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.CreationDate = CreationDate;
		this.Creators = Creators;
		this.Purpose = Purpose;
		this.Duration = Duration;
		this.TotalFloat = TotalFloat;
		this.StartTime = StartTime;
		this.FinishTime = FinishTime;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	CreationDate: IfcDateTime ;
	Creators: (Handle<IfcPerson> | IfcPerson)[] | null;
	Purpose: IfcLabel | null;
	Duration: IfcDuration | null;
	TotalFloat: IfcDuration | null;
	StartTime: IfcDateTime ;
	FinishTime: IfcDateTime | null;
	PredefinedType: IfcWorkPlanTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWorkPlan
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let CreationDate = tape[ptr++];
		let Creators = tape[ptr++];
		let Purpose = tape[ptr++];
		let Duration = tape[ptr++];
		let TotalFloat = tape[ptr++];
		let StartTime = tape[ptr++];
		let FinishTime = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWorkPlan(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, CreationDate, Creators, Purpose, Duration, TotalFloat, StartTime, FinishTime, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.CreationDate);;
		args.push(this.Creators);;
		args.push(this.Purpose);;
		args.push(this.Duration);;
		args.push(this.TotalFloat);;
		args.push(this.StartTime);;
		args.push(this.FinishTime);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWorkSchedule {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, Identification: IfcIdentifier | null, CreationDate: IfcDateTime , Creators: (Handle<IfcPerson> | IfcPerson)[] | null, Purpose: IfcLabel | null, Duration: IfcDuration | null, TotalFloat: IfcDuration | null, StartTime: IfcDateTime , FinishTime: IfcDateTime | null, PredefinedType: IfcWorkScheduleTypeEnum | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.Identification = Identification;
		this.CreationDate = CreationDate;
		this.Creators = Creators;
		this.Purpose = Purpose;
		this.Duration = Duration;
		this.TotalFloat = TotalFloat;
		this.StartTime = StartTime;
		this.FinishTime = FinishTime;
		this.PredefinedType = PredefinedType;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	Identification: IfcIdentifier | null;
	CreationDate: IfcDateTime ;
	Creators: (Handle<IfcPerson> | IfcPerson)[] | null;
	Purpose: IfcLabel | null;
	Duration: IfcDuration | null;
	TotalFloat: IfcDuration | null;
	StartTime: IfcDateTime ;
	FinishTime: IfcDateTime | null;
	PredefinedType: IfcWorkScheduleTypeEnum | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWorkSchedule
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let Identification = tape[ptr++];
		let CreationDate = tape[ptr++];
		let Creators = tape[ptr++];
		let Purpose = tape[ptr++];
		let Duration = tape[ptr++];
		let TotalFloat = tape[ptr++];
		let StartTime = tape[ptr++];
		let FinishTime = tape[ptr++];
		let PredefinedType = tape[ptr++];
		return new IfcWorkSchedule(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, Identification, CreationDate, Creators, Purpose, Duration, TotalFloat, StartTime, FinishTime, PredefinedType);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.Identification);;
		args.push(this.CreationDate);;
		args.push(this.Creators);;
		args.push(this.Purpose);;
		args.push(this.Duration);;
		args.push(this.TotalFloat);;
		args.push(this.StartTime);;
		args.push(this.FinishTime);;
		args.push(this.PredefinedType);;
		return args;
	}
};
export class IfcWorkTime {
	constructor(expressID: number, type: number, Name: IfcLabel | null, DataOrigin: IfcDataOriginEnum | null, UserDefinedDataOrigin: IfcLabel | null, RecurrencePattern: (Handle<IfcRecurrencePattern> | IfcRecurrencePattern) | null, Start: IfcDate | null, Finish: IfcDate | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.Name = Name;
		this.DataOrigin = DataOrigin;
		this.UserDefinedDataOrigin = UserDefinedDataOrigin;
		this.RecurrencePattern = RecurrencePattern;
		this.Start = Start;
		this.Finish = Finish;
	}
	expressID: number;
	type: number;
	Name: IfcLabel | null;
	DataOrigin: IfcDataOriginEnum | null;
	UserDefinedDataOrigin: IfcLabel | null;
	RecurrencePattern: (Handle<IfcRecurrencePattern> | IfcRecurrencePattern) | null;
	Start: IfcDate | null;
	Finish: IfcDate | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcWorkTime
	{
		let ptr = 0;
		let Name = tape[ptr++];
		let DataOrigin = tape[ptr++];
		let UserDefinedDataOrigin = tape[ptr++];
		let RecurrencePattern = tape[ptr++];
		let Start = tape[ptr++];
		let Finish = tape[ptr++];
		return new IfcWorkTime(expressID, type, Name, DataOrigin, UserDefinedDataOrigin, RecurrencePattern, Start, Finish);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.Name);;
		args.push(this.DataOrigin);;
		args.push(this.UserDefinedDataOrigin);;
		args.push(this.RecurrencePattern);;
		args.push(this.Start);;
		args.push(this.Finish);;
		return args;
	}
};
export class IfcZShapeProfileDef {
	constructor(expressID: number, type: number, ProfileType: IfcProfileTypeEnum , ProfileName: IfcLabel | null, Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null, Depth: IfcPositiveLengthMeasure , FlangeWidth: IfcPositiveLengthMeasure , WebThickness: IfcPositiveLengthMeasure , FlangeThickness: IfcPositiveLengthMeasure , FilletRadius: IfcNonNegativeLengthMeasure | null, EdgeRadius: IfcNonNegativeLengthMeasure | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.ProfileType = ProfileType;
		this.ProfileName = ProfileName;
		this.Position = Position;
		this.Depth = Depth;
		this.FlangeWidth = FlangeWidth;
		this.WebThickness = WebThickness;
		this.FlangeThickness = FlangeThickness;
		this.FilletRadius = FilletRadius;
		this.EdgeRadius = EdgeRadius;
	}
	expressID: number;
	type: number;
	ProfileType: IfcProfileTypeEnum ;
	ProfileName: IfcLabel | null;
	Position: (Handle<IfcAxis2Placement2D> | IfcAxis2Placement2D) | null;
	Depth: IfcPositiveLengthMeasure ;
	FlangeWidth: IfcPositiveLengthMeasure ;
	WebThickness: IfcPositiveLengthMeasure ;
	FlangeThickness: IfcPositiveLengthMeasure ;
	FilletRadius: IfcNonNegativeLengthMeasure | null;
	EdgeRadius: IfcNonNegativeLengthMeasure | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcZShapeProfileDef
	{
		let ptr = 0;
		let ProfileType = tape[ptr++];
		let ProfileName = tape[ptr++];
		let Position = tape[ptr++];
		let Depth = tape[ptr++];
		let FlangeWidth = tape[ptr++];
		let WebThickness = tape[ptr++];
		let FlangeThickness = tape[ptr++];
		let FilletRadius = tape[ptr++];
		let EdgeRadius = tape[ptr++];
		return new IfcZShapeProfileDef(expressID, type, ProfileType, ProfileName, Position, Depth, FlangeWidth, WebThickness, FlangeThickness, FilletRadius, EdgeRadius);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.ProfileType);;
		args.push(this.ProfileName);;
		args.push(this.Position);;
		args.push(this.Depth);;
		args.push(this.FlangeWidth);;
		args.push(this.WebThickness);;
		args.push(this.FlangeThickness);;
		args.push(this.FilletRadius);;
		args.push(this.EdgeRadius);;
		return args;
	}
};
export class IfcZone {
	constructor(expressID: number, type: number, GlobalId: IfcGloballyUniqueId , OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null, Name: IfcLabel | null, Description: IfcText | null, ObjectType: IfcLabel | null, LongName: IfcLabel | null)
	{
		this.expressID = expressID;
		this.type = type;
		this.GlobalId = GlobalId;
		this.OwnerHistory = OwnerHistory;
		this.Name = Name;
		this.Description = Description;
		this.ObjectType = ObjectType;
		this.LongName = LongName;
	}
	expressID: number;
	type: number;
	GlobalId: IfcGloballyUniqueId ;
	OwnerHistory: (Handle<IfcOwnerHistory> | IfcOwnerHistory) | null;
	Name: IfcLabel | null;
	Description: IfcText | null;
	ObjectType: IfcLabel | null;
	LongName: IfcLabel | null;
	static FromTape(expressID: number, type: number, tape: any[]): IfcZone
	{
		let ptr = 0;
		let GlobalId = tape[ptr++];
		let OwnerHistory = tape[ptr++];
		let Name = tape[ptr++];
		let Description = tape[ptr++];
		let ObjectType = tape[ptr++];
		let LongName = tape[ptr++];
		return new IfcZone(expressID, type, GlobalId, OwnerHistory, Name, Description, ObjectType, LongName);
	}
	ToTape(): any[]
	{
		let args: any[] = [];
		args.push(this.GlobalId);;
		args.push(this.OwnerHistory);;
		args.push(this.Name);;
		args.push(this.Description);;
		args.push(this.ObjectType);;
		args.push(this.LongName);;
		return args;
	}
};