import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { DataService } from '../services/data.service';
import {APP_BASE_HREF} from '@angular/common';

@Injectable()
export class ManagedInterceptor implements HttpInterceptor {

  constructor(
    private dataService: DataService,
    @Inject(APP_BASE_HREF) private baseHref: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes("ifc2collada") ||
      request.url.includes("firebase-access-api") ||
      request.url.includes("bimshow.io/textures") ||
      request.url.includes("bimshow.io/projects") ||
      request.url.includes("bimshow.io/users")
    ) {

      return from( this.dataService.getToken()).pipe(
            switchMap (
              token => {
                  request = request.clone({
                      setHeaders: {
                        Authorization: 'Bearer '+token
                      }
                  });

                  return next.handle(request);
              }
            )
          );
    }
    else {
      if (request.url.includes("assets/i18n")){
        var pathnamePrefix = this.baseHref;
        var newUrl = request.url.replace("/assets/i18n",pathnamePrefix+"assets/i18n")
        const dupReq = request.clone({ url: newUrl });
        return next.handle(dupReq);
      }
      else {
        return next.handle(request);
      }
    }
  }
}
