
export type OnNewFrameCallback = (alpha, currentFrame, totalFrames) => void;
export type OnAnimationFinishedCallback = (canceled:boolean) => void;

export class Animation {
    public name: string;
    public totalFrames: number;
    public currentFrame: number;
    public finished: boolean;

    public onNewFrame: OnNewFrameCallback;
    public onAnimationFinished: OnAnimationFinishedCallback


    constructor(totalFrames, onNewFrame: OnNewFrameCallback, onAnimationFinished?: OnAnimationFinishedCallback, name?) {

        this.finished = false;
        this.name = name;
        this.totalFrames = totalFrames;
        this.currentFrame = 1;
        this.onNewFrame = onNewFrame;
        this.onAnimationFinished = onAnimationFinished;



    }



    runAnimation() {

        if (this.currentFrame <= this.totalFrames) {
            const alpha = this.currentFrame / this.totalFrames;
            this.onNewFrame(alpha, this.currentFrame, this.totalFrames)
            this.currentFrame++;
        } else {
            this.finished = true;
            this.finishAnimation();
            return false;
        }



    }

    finishAnimation(canceled = false) {
        this.finished = true;
        if (this.onAnimationFinished) {
            this.onAnimationFinished(canceled)
        }

    }





}
