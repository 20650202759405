import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { ModelImportService } from '../model-import.service';

import { MatSelect } from '@angular/material/select';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router'
import { P } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-projects-folder',
  templateUrl: './projects-folder.component.html',
  styleUrls: ['./projects-folder.component.scss']
})
export class ProjectsFolderComponent implements OnInit, OnDestroy, AfterViewInit {

  createProjectForm: FormGroup;
  ownedProjects = [];
  publicProjects = [];
  selectedProject = null;
  @ViewChild('uploadFile', { static: false }) uploadFile: ElementRef;
  @ViewChild('newProjectName', { static: false }) newProjectName: ElementRef;
  ifcschema = 'ifc2x3tc1';
  fileName = null;
  createNewProjectMode = false;
  creatingProjectInProcess = null;
  createProjectError = null;
  subscriptions: Subscription[] = [];
  fileType = 'ifc';
  creatingColladaProject = false;
  file = null;
  showOldProjects = false;
  maxFileSize = 100 * 1024 * 1024;
  filterString = '';
  

  errorMessage: string | null = null;

  constructor(private router: Router, private dataService: DataService, public appActionsService: AppActionsService, private http: HttpClient, private route: ActivatedRoute, private modelImporter: ModelImportService, private translate: TranslateService,) { }

  ngOnInit() {
  
    this.initCreateProjectForm();
    this.initProjectsData();
    this.subscriptions.push(this.appActionsService.projectsDataChanged.subscribe(() => {
      this.initProjectsData();
    }))
  }

  ngAfterViewInit() {
    this.loadProjectFromUrl()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadProjectFromUrl() {

    let projectId = this.route.snapshot.queryParams.p;
    
    if (!projectId) {
      console.warn('no project url')
      return;
    }
    if (this.dataService.project == null) {
      let projectWasListed = false;
      this.dataService.user.projects.forEach(project => {
        if (project.id == projectId) {
          this.appActionsService.openProject.next(project);
          this.appActionsService.openCloseProjectsFolder.next('close');
          projectWasListed = true;

        }
      })

      if (!projectWasListed) {
        this.dataService.fetchUnlistedProject(projectId).then(
          (project) => {
        
            this.appActionsService.openProject.next(project);
            this.appActionsService.openCloseProjectsFolder.next('close');
          },
          rejected => {
            this.appActionsService.notify(this.translate.instant('projectListPage.cantLoadUnlistedProject'),"error","top");
          })
      }
    } else {
      // console.log('project is already loaded')
    }
  }

  initCreateProjectForm() {
    this.createProjectForm = new FormGroup({
      'projectName': new FormControl(null, [Validators.required, this.forbiddenProjectNames.bind(this)]),
      'type': new FormControl(this.fileType, Validators.required),
      "file": new FormControl(null, Validators.required)
    })

    this.fileName = null;
  }

  initProjectsData() {

    this.publicProjects = [];
    this.ownedProjects = [];
    let selectedPidBeforeInit = null;
    if (this.selectedProject) {
      selectedPidBeforeInit = this.selectedProject['id'];
    }

    this.selectedProject = null;



    for (let i = 0; i < this.dataService.user.projects.length; i++) {
      let project = this.dataService.user.projects[i];

      if (project.privacy == 'public' && !project.unlisted) {
        this.publicProjects.push(project)
      }

      if (project.roles[this.dataService.user.id] == 'owner' && project.modelZip) {
        this.ownedProjects.push(project);

      }

      if (selectedPidBeforeInit == project.id) {
        this.selectedProject = project;

      }
    }

    this.sortByDate(this.ownedProjects)



  }

  sortByDate(projects) {
    projects.sort((a, b) => new Date(b.dateCreated || 0).getTime() - new Date(a.dateCreated || 0).getTime())
  }



  selectProject(project) {
    if (this.selectedProject == project) {
      // this.selectedProject = null;
    } else {
      if ((project.daeFileName != null && project.ifcDataReady) || project.type == 'threejs') {
        this.createNewProjectMode = false;
        this.selectedProject = project;
      }
    }
  }

  close() {
    this.appActionsService.openCloseProjectsFolder.next('close');
  }

  createNewProjectClicked() {

    this.createNewProjectMode = true;
    if (this.creatingProjectInProcess == null) {
      this.initCreateProjectForm()
    }

    this.selectedProject = null;
  }

  closeCreateNewProjectClicked() {
    this.createNewProjectMode = false;
    this.selectedProject = null;
  }



  onFileSelected() {
    this.file = this.uploadFile.nativeElement.files[0];
    if (this.uploadFile.nativeElement.files.length > 0) {
      this.fileName = this.file.name;
    }

    else {
      this.fileName = null;
    }

    this.createProjectError = null;


    // this.readFile(this.file)
  }

  readFile(file) {


    var fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      var text = event.target.result.replace(/\s/g, '');;


      // if (text.indexOf("FILE_SCHEMA(('IFC2X3'))") != -1) {
      //   console.log('Ifc versioin detected: ' + 'IFC2X3')
      // }

      // if (text.indexOf("FILE_SCHEMA(('IFC4'))") != -1) {
      //   console.log('Ifc versioin detected: ' + 'IFC4')
      // }
    };
    fileReader.readAsText(file);
  }

  resetErrorMessage() {
    this.appActionsService.errorMessage = null;
  }







  loadProjectClicked(project) {
    this.router.navigate(['/'], { queryParams: { p: project.id } })
    this.appActionsService.chosenObjectOid.next(null);
    this.dataService.cancelAllExistingUploadTasks();
    this.appActionsService.cleanAllUserBlockTaks();
    this.appActionsService.openProject.next(project);
    this.appActionsService.openCloseProjectsFolder.next('close');
    this.dataService.lastOpenPhoto.next(null);


  }


  forbiddenProjectNames(control: FormControl): { [s: string]: boolean } {
    for (let project of this.ownedProjects) {
      

      if (project.name == control.value) {
        return { 'nameAlreadyExist': true } // if forbined
      }


    }

    return null
  }

  hasSpaceForMoreProjects() {
    if(this.dataService.user.subscription.maxProjects ) {
      return this.ownedProjects.length < this.dataService.user.subscription.maxProjects
    } else {
      return false;
    }
   
  }

  onSelectedTabChanged() {

    this.selectedProject = null;
  }
  onColladaImportedClosed() {
    this.creatingColladaProject = false;
    this.dataService.reloadUserData();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
     event.stopImmediatePropagation();
    }
  }

  nevigateToSubscriptionPageInAccountApp() {
    const url = window.location.origin + '/account/subscription';
    var a = document.createElement("a");
    a.href = url;
    a.setAttribute('target', '_blank');
    a.click();
  }
  
  onProjectCreateFinished(event) {
    this.createNewProjectMode = false
  }
  


}
