import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { DataToolboxComponent } from './data-toolbox/data-toolbox.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ObjectDataComponent } from './object-data/object-data.component';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { LayerViewComponent } from './layer-view/layer-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomDate } from './pipes/custom-date.pipe';
import { DatePipe } from '@angular/common';
import { FixSpecialChars } from './pipes/fixSpecialChars.pipe';
import { StringToHslPipe } from './pipes/string-to-hsl-pipe';
import { ValuesFromKeyValue } from './pipes/values-from-key-value.pipe'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { NotesViewerComponent } from './notes-viewer/notes-viewer.component';
import { NoteComponent, DeleteNoteDialog } from './note/note.component';
import { MatDividerModule } from '@angular/material/divider';
import { NoteCommentsComponent } from './note/note-comments/note-comments.component';
import { CommentComponent } from './note/note-comments/comment/comment.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelatedObjectsComponent } from './note/related-objects/related-objects.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToolTipDirective } from './directives/toolTip.directive';
import { ThreejsComponent } from './threejs/threejs.component';
import { AddNoteToolComponent } from './toolbox/add-note-tool/add-note-tool.component';
import { TransparentModeToolComponent } from './toolbox/transparent-mode-tool/transparent-mode-tool.component';
import { MeasureModeToolComponent } from './toolbox/measure-mode-tool/measure-mode-tool.component';
import { PdfCompareToolComponent } from './toolbox/pdf-compare-tool/pdf-compare-tool.component';
import { PlaceOnMapToolComponent } from './toolbox/place-on-map-tool/place-on-map-tool.component';
import { GalleryModeToolComponent } from './toolbox/gallery-mode-tool/gallery-mode-tool.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ProjectsFolderComponent } from './projects-folder/projects-folder.component';
import { LoginComponent } from './login/login.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProjectDetailsComponent, DeleteProjectDialog } from './projects-folder/project-details/project-details.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GalleryComponent } from './toolbox/gallery-mode-tool/gallery/gallery.component';
import { FilterItemsByCreatedBy } from './pipes/filter-items-by-created-by.pipe';
import { PhotoComponent } from './photo/photo.component';
import { ZonesViewerComponent } from './zones-viewer/zones-viewer.component';
import { SearchInValuePipe } from './pipes/searchInValue.pipe';
import { RelatedPhotosComponent } from './note/related-photos/related-photos.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MatGridListModule } from '@angular/material/grid-list';
import { MapboxComponent } from './mapbox/mapbox.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ViewEditorComponent } from './toolbox/view-editor/view-editor.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonEditorComponent } from './toolbox/view-editor/button-editor/button-editor.component';
import { ViewMenuComponent } from './view-menu/view-menu.component';
import { RouterModule } from '@angular/router';
import { ViewGalleryEditorComponent } from './toolbox/view-editor/view-gallery-editor/view-gallery-editor.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ViewGalleryComponent } from './view-gallery/view-gallery.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LabelsComponent } from './threejs/labels/labels.component';
import { LabelsEditorComponent } from './labels-editor/labels-editor.component';
import { filterInPipe } from './pipes/filter-in.pipe';
import { MaterialsEditorComponent, EditNameDialog } from './materials-editor/materials-editor.component';
import { BimlogoSpinnerComponent } from './bimlogo-spinner/bimlogo-spinner.component';
import { ClippingPlanesEditorComponent } from './clipping-planes-editor/clipping-planes-editor.component';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { UserAlertComponent } from './user-alert/user-alert.component';
import { CookiebarComponent } from './cookiebar/cookiebar.component';
import { httpInterceptorProviders } from './http-interceptors/index';
import { PhotospheresToolComponent } from './photospheres-tool/photospheres-tool.component';
import { PhotospheresLabelsComponent } from './threejs/photospheres-labels/photospheres-labels.component';
import { ColorPickerComponent } from './color-picker/color-picker.component'
import { ColorPickerModule } from 'ngx-color-picker';
import { APP_BASE_HREF } from '@angular/common';
import { ColladaComponent } from './importers/collada/collada.component';
import { CameraComponent } from './camera/camera.component';
import { WizardComponent } from './wizard/wizard.component';
import { InputRangeComponent } from './input-range/input-range.component';
import { TasksViewerComponent } from './tasks-viewer/tasks-viewer.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { IfcAnalayzerComponent } from './ifc-analayzer/ifc-analayzer.component';
import { ProjectUpdateComponent } from './project-update/project-update.component';
import { ModelLibraryComponent } from './model-library/model-library.component';
import { MaterialsLibraryComponent } from './materials-library/materials-library.component';
import { CreditsComponent } from './credits/credits.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { CustomMaterialCreateComponent } from './custom-material-create/custom-material-create.component';
import { LightWebsiteComponent } from './light-website/light-website.component';
import { PhotosphereViewerComponent } from './photosphere-viewer/photosphere-viewer.component';
import { RoomsEditorComponent } from './view-editor/button-editor/rooms-editor/rooms-editor.component';
import { AssetDataComponent } from './asset-data/asset-data.component';
import { ChangeMaterialMenuComponent } from './materials-editor/change-material-menu/change-material-menu.component';


export class MyErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error: Error) {
    if (Error) {
      console.error(error);
      //alert("Error occured: \n" + error.message)
    }
    else {
      console.warn('handle error',error)
    }

  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    UserMenuComponent,
    DataToolboxComponent,
    ToolboxComponent,
    TooltipComponent,
    ObjectDataComponent,
    TreeViewComponent,
    LayerViewComponent,
    NotesViewerComponent,
    NoteComponent,
    NoteCommentsComponent,
    CommentComponent,
    CustomDate,
    FixSpecialChars,
    StringToHslPipe,
    ValuesFromKeyValue,
    FilterItemsByCreatedBy,
    RelatedObjectsComponent,
    DeleteNoteDialog,
    DeleteProjectDialog,
    EditNameDialog,
    ToolTipDirective,
    ThreejsComponent,
    AddNoteToolComponent,
    TransparentModeToolComponent,
    GalleryModeToolComponent,
    MeasureModeToolComponent,
    PdfCompareToolComponent,
    PlaceOnMapToolComponent,
    UserSettingsComponent,
    ProjectsFolderComponent,
    LoginComponent,
    ProjectDetailsComponent,
    GalleryComponent,
    PhotoComponent,
    ZonesViewerComponent,
    SearchInValuePipe,
    RelatedPhotosComponent,
    MapboxComponent,
    ViewEditorComponent,
    ButtonEditorComponent,
    ViewMenuComponent,
    ViewGalleryEditorComponent,
    DragDropDirective,
    ViewGalleryComponent,
    ProfileEditorComponent,
    LabelsComponent,
    LabelsEditorComponent,
    filterInPipe,
    MaterialsEditorComponent,
    BimlogoSpinnerComponent,
    ClippingPlanesEditorComponent,
    UserAlertComponent,
    CookiebarComponent,
    PhotospheresToolComponent,
    PhotospheresLabelsComponent,
    ColorPickerComponent,
    ColladaComponent,
    CameraComponent,
    WizardComponent,
    InputRangeComponent,
    TasksViewerComponent,
    ProjectCreateComponent,
    IfcAnalayzerComponent,
    ProjectUpdateComponent,
    ModelLibraryComponent,
    MaterialsLibraryComponent,
    CreditsComponent,
    ImageViewerComponent,
    CustomMaterialCreateComponent,
    LightWebsiteComponent,
    PhotosphereViewerComponent,
    RoomsEditorComponent,
    AssetDataComponent,
    ChangeMaterialMenuComponent,

  ],

  entryComponents: [
    DeleteNoteDialog,
    DeleteProjectDialog,
    EditNameDialog
  ],
  imports: [
    ColorPickerModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatCardModule,
    MatRadioModule,
    MatTreeModule,
    MatListModule,
    MatDividerModule,
    MatInputModule,
    FormsModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSliderModule,
    MatSlideToggleModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    PdfJsViewerModule,
    MatGridListModule,
    MatTabsModule,
    MatProgressBarModule,
    RouterModule.forRoot([]),
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    SearchInValuePipe,
    FixSpecialChars,
    {
      provide: ErrorHandler,
      useClass: MyErrorHandler,
    },
    httpInterceptorProviders,
    {
  			provide: APP_BASE_HREF,
  			useValue: window.location.pathname.startsWith('/app/') ? '/app/' : '/'
		}
  ],
  bootstrap: [AppComponent]
})




export class AppModule { }
