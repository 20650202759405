import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, ChangeDetectorRef, Output, EventEmitter, HostListener  } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppActionsService } from '../../services/app-actions.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { AppConfig } from 'src/models/appConfig.model';
import { ModelImportService } from 'src/app/model-import.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnChanges {

  @ViewChild('deleteButton', { static: false }) deleteButton: ElementRef;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() project; //storing project.details of firebase
  sharedUsersControl = new FormControl();
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('sharedUserInput', { static: false }) sharedUserInput: ElementRef<HTMLInputElement>;
  ownerAsync: Observable<any>;
  filteredUsers;
  deletingProject = false;
  editName = false;
  @ViewChild('nameEditor', { static: false }) nameEditor: ElementRef;
  @Output() openProjectClicked = new EventEmitter<any>();

  sharedWith = []

  @ViewChild('uploadIfcFile', { static: false }) uploadIfcFile: ElementRef;
  updateFileForm: FormGroup;
  ifcschema = 'ifc2x3tc1';
  fileName = null;
  updateFileError = null
  updatingFileInProcess = null;
  updateFile = false;



  constructor(private modelImporter: ModelImportService, private dataService: DataService, private appActionsService: AppActionsService, public dialog: MatDialog, private http: HttpClient, private router: Router, private cdr: ChangeDetectorRef) {


  }

  ngOnInit() {



    this.appActionsService.usersDataChanged.subscribe(() => {
      this.resetData();
      this.loadProjectDetails();
    })

    this.sharedUsersControl.valueChanges.subscribe((value) => {
      console.log(value)
      console.log(this.dataService.USERS)
      if (typeof value != 'string') {
        return;

      }


      this.filteredUsers = this.dataService.USERS.filter((user, index, array) => {

        let withoutSpaces = value.replace(/\s/g, '');
        if (withoutSpaces == '') {
          return false;
        }
        let str = value.toLowerCase();
        let strings = str.split(" ")
        let stringsNoSpaces = [];
        for (let i = 0; i < strings.length; i++) {
          if (strings[i] != '') {
            stringsNoSpaces.push(strings[i])
          }
        }


        let firstName = user.firstName ? user.firstName : '';
        let lastName = user.lastName ? user.lastName : '';
        let email = user.email ? user.email : '';

        if (stringsNoSpaces.length > 1) {

          return (
            stringsNoSpaces.some(el => firstName.toLowerCase().includes(el)) && stringsNoSpaces.some(el => lastName.toLowerCase().includes(el))
          );
        }

        else {
          console.log(user)
          if (stringsNoSpaces[0]) {
            return (firstName.toLowerCase().includes(stringsNoSpaces[0]) || lastName.toLowerCase().includes(stringsNoSpaces[0]) || email.toLowerCase().includes(stringsNoSpaces[0]))
          }

          return false;
        }



      }).filter(user => {

        return (user.id != this.dataService.user.id)
      }).filter(user => {
        let alreadyShared = false;
        for (let i = 0; i < this.sharedWith.length; i++) {
          if (this.sharedWith[i].id == user.id) { alreadyShared = true }
        }
        return !alreadyShared;

      })
    })

  }



  ngOnChanges(changes) {

    this.resetData();
    this.loadProjectDetails();
    this.updateFile = false;

  }



  resetData() {
    this.sharedWith = [];
    this.ownerAsync = null
    this.initUpdateFileForm();

  }

  loadProjectDetails() {

    if (this.project == null) {
      return;
    }
    for (let roleId in this.project.roles) {

      if (this.project.roles[roleId] == 'owner') {
        this.dataService.getUserById(roleId).then(user => {
          this.ownerAsync = of(user);
        })


      }

      if (this.project.roles[roleId] == "viewer") {
        this.dataService.getUserById(roleId).then(user => {
          this.sharedWith.push(user);
        })


      }

    }
  }






  setProjectPrivacy(privacy) {
    let unlisted = false;
    return new Promise<any>((resolve, reject) => {
      let promises = [];

      if (this.project.privacy == 'public') {
        promises.push(this.dataService.removeFromPublicProjectsIds(this.project.id));
        unlisted = false;
      }


      if (privacy == 'public') {
        promises.push(this.dataService.pushToPublicProjectsIds(this.project.id));
        unlisted = false;

      }

      if (privacy == 'public_unlisted') {
        promises.push(this.dataService.removeFromPublicProjectsIds(this.project.id));
        privacy = "public";
        unlisted = true;
        this.copyUrlToClipboard();

      }

      promises.push(this.dataService.updateProject(this.project.id, { privacy: privacy, unlisted: unlisted }));


      Promise.all(promises.map(p => p.catch((err) => err)))
        .then(
          resolved => {
            console.log(this.project)
            this.dataService.reloadProject(this.project)
            resolve()
          },
          rejected => {
            console.warn(rejected)
            reject(rejected)
          }
        );
    })






  }

  deleteProjectClicked() {


    const dialogRef = this.dialog.open(DeleteProjectDialog, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {

      //was required for a button
      // this.deleteButton['_elementRef'].nativeElement.classList.remove('cdk-focused')
      // this.deleteButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused')
      if (result) {
        this.deleteProject();
      }



    });


  }

  deleteProject() {
    this.deletingProject = true;
    let projectToDelete = this.project;

    this.setProjectPrivacy('private').then(
      resolved => {

        this.dataService.deleteProject(projectToDelete).then(
          ok => {



            this.dataService.reloadUserData().then(() => {
              this.deletingProject = false;
            }

            )


          },
          err => {
            console.warn(err)
            this.deletingProject = false;

          }
        )
      },
      rejected => {
        console.warn(rejected)
        this.deletingProject = false;

      })

  }

  onLoadProjectClicked() {
    this.appActionsService.removeTask("ifcUpload") //incase was not removed.
    this.appActionsService.removeTask("modelUpload") //incase was not removed.
    this.openProjectClicked.emit(this.project)
  }

  //updateIfcFile:
  initUpdateFileForm() {
    this.updateFileForm = new FormGroup({

      'ifcSchema': new FormControl(this.ifcschema, Validators.required),
      "ifcFile": new FormControl(null, Validators.required)
    })

    this.fileName = null;


  }


  onFileSelected() {
    if (this.uploadIfcFile.nativeElement.files.length > 0) {
      this.fileName = this.uploadIfcFile.nativeElement.files[0].name;

    }

    else {
      this.fileName = null;
    }

    this.updateFileError = null;

  }

  uploadFileClicked() {


    //new project:
    if (this.project.type == 'threejs') {

      this.updateIfcThreejsProject();
      return;
    }


  }

  updateFileButtonClicked() {
    this.updateFile = !this.updateFile;
  }

  startEditingName() {

    this.editName = true;
    this.cdr.detectChanges()
    this.nameEditor.nativeElement.focus();
  }

  projectNameInputBlurred(name) {
    console.log('blurred')
    this.editName = false;

    if (!(/\S/.test(name))) {
      name = 'Unnamed project';
    }



    this.project.name = name;
    this.dataService.changeProjectName(this.project, name)
  }

  async updateIfcThreejsProject() {

    this.updatingFileInProcess = { message: 'Converting Project' };
    const ifcFile = this.uploadIfcFile.nativeElement.files[0]
    const jsonModel = await this.modelImporter.loadIfcModel(ifcFile)

    await this.dataService.updateMaterialConfigsInUpdatedProject(this.project.id, jsonModel)

    this.dataService.updateIfcProject(this.project.id, jsonModel,ifcFile);

    this.updatingFileInProcess = null;
    this.updateFile = false;
    this.updateFileForm.controls.ifcFile.setValue(null);

    console.log('Ifc project updated')

    this.dataService.localModel = jsonModel;

    //now load localy
    this.router.navigate(['/'], { queryParams: { p: this.project.id } })

    this.appActionsService.openProjectWhileUploading.next(this.project)
    this.appActionsService.openCloseProjectsFolder.next('close');


  }

  copyUrlToClipboard(){

    const url = `${window.location.origin}/app/?p=${this.project.id}`;
    navigator.clipboard.writeText(url);
    this.appActionsService.notify('Project URL copied to clipboard',"success",3)
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
     event.stopImmediatePropagation();
    }
  }

}



@Component({
  selector: 'deleteProjectDialog',
  templateUrl: 'deleteProjectDialog.html',
})
export class DeleteProjectDialog { }
