import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-tasks-viewer',
  templateUrl: './tasks-viewer.component.html',
  styleUrls: ['./tasks-viewer.component.scss']
})
export class TasksViewerComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  tasks = {};
  enter = {}
  constructor(private appActionsService: AppActionsService, private cdr:ChangeDetectorRef) { }


  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe()
    });

  }
  ngOnInit() {
    this.tasks = this.appActionsService.tasks;
    this.subscriptions.push(
      this.appActionsService.tasksUpdated.subscribe(() => {
       
        for(let key in this.tasks) {
         

          if(this.enter[key] === undefined){
            this.enter[key] = true;
          } else {
            this.enter[key] = false;
          }
         
        
        }
        this.cdr.detectChanges();
        

      })

    );
  }

  removeTask(taskName,element) {
    element.classList.add('exit')
    setTimeout(() => {
      this.appActionsService.removeTask(taskName)   
    }, 150);

  }

}
