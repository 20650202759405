import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MatChipInputEvent } from '@angular/material/chips';

import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Note } from '../../../models/note.model';
import { Subscription } from 'rxjs';
import { AppActionsService } from '../../services/app-actions.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-related-objects',
  templateUrl: './related-objects.component.html',
  styleUrls: ['./related-objects.component.scss']
})


export class RelatedObjectsComponent implements OnInit, OnDestroy {



  @Input('note') note: Note;
  @ViewChild('chipList', { static: true }) chipList;
  @ViewChild('objectGuid', { static: false }) objectGuid;


  matcher = new MyErrorStateMatcher();

  addObjectForm: FormGroup;
  relatedObjects = [];
  selectable = true;
  removable = true;
  addOnBlur = true;

  showAddButton = true;
  guidError = null;
  submitInProcess = false;

  subscriptions: Subscription[] = [];


  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {
    this.getRelatedObjects();
    this.initForm();

    this.subscriptions.push(this.dataService.notesObjectsRelationsDataFetched.subscribe(() => {
      this.getRelatedObjects();
      this.initForm();
    }))

  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  ngOnChanges() {

    this.getRelatedObjects();
    this.initForm();
  }

  initForm() {

    this.addObjectForm = new FormGroup({
      'objectGuid': new FormControl(null, [this.guidErrors.bind(this)])
    })
    this.guidError = null;
    this.addObjectForm.controls.objectGuid.updateValueAndValidity();
  }
  getRelatedObjects() {
    this.relatedObjects = this.dataService.getRelatedObjectsOfNote(this.note.id)
  }

  onInput() {
    this.guidError = null;
    this.addObjectForm.controls.objectGuid.updateValueAndValidity();
  }


  onSubmit() {

    if (this.submitInProcess) {

      return;
    }

    let value = this.addObjectForm.value.objectGuid


    if ((value || '').trim()) {
      this.submitInProcess = true;
      this.dataService.addRelatedObjectToNote(this.note.id, value.trim()).then(
        () => {
          this.getRelatedObjects();
          this.addObjectForm.controls.objectGuid.setValue('');
          this.guidError = null;
          this.addObjectForm.controls.objectGuid.updateValueAndValidity();
          this.objectGuid.nativeElement.blur();
          this.submitInProcess = false;


        },
        (err) => {
          this.submitInProcess = false;
          this.guidError = err;
          this.addObjectForm.controls.objectGuid.updateValueAndValidity();
        })
    }
  }

  remove(relatedObject): void {
    this.dataService.removeRelatedObjectToNote(relatedObject.id).then(() => {
      this.getRelatedObjects();

    }).catch(err => {
      console.warn(err)
    })
  }

  guidErrors(control: FormControl): { [s: string]: boolean } {
    if (this.guidError != null) {
      return { 'guidErrors': true }
    }

    return null;
  }

  relatedObjectClicked(ro) {
    const oid = this.dataService.getObjectIdOfGuid(ro.objectGuid)
    this.appActionsService.chosenObjectOid.next(oid);
    this.appActionsService.selectAndFlyToObjectClicked.next(oid)
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

}
