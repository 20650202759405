import { Component, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DataService } from '../services/data.service';
import { Note } from '../../models/note.model';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notes-viewer',
  templateUrl: './notes-viewer.component.html',
  styleUrls: ['./notes-viewer.component.scss']
})
export class NotesViewerComponent implements OnInit, OnDestroy {

  allowDrag = false;
  lastMovedPath = null;
  notes: Note[] = [];
  openNoteId: String = null;
  subscriptions: Subscription[] = [];
  constructor(
    private dataService: DataService,
    private appActionsService: AppActionsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(this.dataService.notesDataFetched.subscribe(notes => {
      this.notes = notes;
    }))

    this.subscriptions.push(this.appActionsService.openNote.subscribe((note) => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = '';
      }

    }))

    this.subscriptions.push(this.appActionsService.buttonEditorOpened.subscribe(() => {
      this.allowDrag = true;
      this.cdr.detectChanges();
    }))

    this.subscriptions.push(this.appActionsService.closeButtonEditor.subscribe(() => {
      this.allowDrag = false;
      this.cdr.detectChanges();
    }))



  }


  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }



  noteClickedOnList(note: Note) {
    this.appActionsService.openNote.next(note);
  }

  createNoteClicked() {
    const newName = this.translate.instant('note.newName');
    const newDescription = this.translate.instant('note.newDescription');
    const newType = this.translate.instant('note.newType');

    //this.dataService.createNote('New unpinned note', 'type your description here', 'none')
    this.dataService.createNote(newName, newDescription, newType)
      .then(
        resolved => {

          this.appActionsService.openNote.next(resolved);

        },
        rejected => {
          console.log('note was not created!');


        }
      ).catch(err => { console.warn(err) })
  }


  dragReleased(note) {


    if (this.lastMovedPath == "noteDropZone-button") {


      this.appActionsService.itemDroppedOnButton.next({ type: 'note', value: note.id })
    }


    this.lastMovedPath = null;



  }

  dragMoved(e) {
    if (e.event.target && e.event.target.dataset && e.event.target.dataset.targetRef) {
      this.lastMovedPath = e.event.target.dataset.targetRef
    }
    else {
      console.log('Error drag moved event content')
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }



}
