import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  Host,
  NgZone,
} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

import { AppActionsService } from "../services/app-actions.service";
import { DataService } from "../services/data.service";
import { Note } from "../../models/note.model";
import { ToolboxEvent } from "../../models/toolbox-event";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { ZipService } from "../services/zip.service";
import { Tictac } from "src/models/tictac.model";
import { TransformControls } from "../../assets/utils/TransformControls.js";
import { DirectionalLightBimshow } from "../../assets/utils/LightShadowsBimshow.js";
import { EdgeBimshow } from "../../assets/utils/EdgeBimshow.js";
import { DelaySetValueBimshow } from "../../assets/utils/Semaphore.js";
import { RGBELoader } from "../../assets/threejs/loaders/RGBELoader.js";
import { debounce } from 'lodash';
import { applyBoxUV, discardHiddenOnBeforeCompileFunc, generateSingleMeshFromColladaObjet } from "../../assets/utils/ThreejsCalcFunctions.js";

import { WebGLRenderer } from "../../assets/threejs/three.module.js";
import * as THREE from "../../assets/threejs/three.module.js";
import { OrbitControls } from "../../assets/threejs/controls/OrbitControls.js"
import { PointerLockControls } from "../../assets/threejs/controls/PointerLockControls.js"
import { EffectComposer } from "../../assets/threejs/postprocessing/EffectComposer.js"
import { SAOPass } from "../../assets/threejs/postprocessing/SAOPass.js"
import { RenderPass } from "../../assets/threejs/postprocessing/RenderPass.js"
import { OutlinePass } from "../../assets/threejs/postprocessing/OutlinePass.js"
import { SSAARenderPass } from "../../assets/threejs/postprocessing/SSAARenderPass.js"
import { OutputPass } from "../../assets/threejs/postprocessing/OutputPass.js"
import { ColladaLoader } from "../../assets/threejs/loaders/ColladaLoader.js"
import { Y } from "@angular/cdk/keycodes";
import { UUID } from "angular2-uuid";
import { InOutQuadBlend, waitSeconds } from "../modules/HelperFunctions";
import { AnimationsManager } from "src/models/animationsManager.model";
import * as CubemapToEquirectangular from "../../assets/threejs/CubemapToEquirectangular.js";

declare var Stats: any;
declare var VirtualJoystick: any;
declare var Whammy: any;
declare var dat: any;


@Component({
  selector: "app-threejs",
  templateUrl: "./threejs.component.html",
  styleUrls: ["./threejs.component.scss"],
})
export class ThreejsComponent implements OnInit, OnDestroy {
  @Input("master") masterName: string;

  @ViewChild("rendererContainer", { static: true })
  rendererContainer: ElementRef;
  @ViewChild("loadingContainer", { static: true }) loadingContainer: ElementRef;
  @ViewChild("primaryColorDiv", { static: true }) primaryColorDiv: ElementRef;
  @ViewChild("accentColorDiv", { static: true }) accentColorDiv: ElementRef;
  @ViewChild("warnColorDiv", { static: true }) warnColorDiv: ElementRef;
  @ViewChild("okColorDiv", { static: true }) okColorDiv: ElementRef;
  @ViewChild("problemColorDiv", { static: true }) problemColorDiv: ElementRef;
  @ViewChild("noneColorDiv", { static: true }) noneColorDiv: ElementRef;
  @ViewChild("smallMap", { static: true }) smallMap: ElementRef;
  @ViewChild("threeJsStats", { static: true }) threeJsStats: ElementRef;
  @ViewChild("mobileFpsControls", { static: true })
  mobileFpsControls: ElementRef;

  @ViewChild("guiContainer", { static: true }) guiContainer: ElementRef;

  @Input() project;
  d: any = document;
  fullscreen = false;

  lastPLCExist = performance.now();
  debugGUI: any = null;

  firstViewLoad = true;

  originalMaterials = {};

  cameraSound = new Audio("assets/sounds/camera-shutter-click-03.mp3");

  takingPhoto = false;

  objectsGuidQuery = {
    threejs: {},
    mapbox: {},
  };
  EidToGuid = {}
  GuidToEid = {};
  labels = {};
  photosphereLabels = {};

  //materials control!:
  materialsList = [];
  //end materials control

  //debugs
  debugWarning = null;
  helper = null;
  testCam = null;
  renderDebuggerOn = false;
  //

  visibilityFilters = {};

  mobileLog = "";

  debuggerMinimized = true;

  subscriptions: Subscription[] = [];
  debug = false;
  zones = [];
  zonesLabels = {};
  zoneLabel = null;
  lastZoneHovered = null;
  zoneHoverTimer = 0;
  hoveredZoneGuid = null;
  mapboxCam = new THREE.Camera();

  //detect Zone

  currentContainedZone = null;
  // zoneCounter = 0;

  //
  clippingPlanes = [];
  clippingPlanesMeshes = [];
  clippingCube = null;

  clippingOn = false;
  clippingStencilsOn = false;
  guidingPlane = null;
  guidingPlane2 = null;
  guidingPlaneZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
  clippingPlaneConstantOffset = 0.05;
  clippingModeState = null;

  intersectedPlane = {
    material: null,
    normal: null,
    index: null,
  };

  mousePressedButtons = {
    0: false,
    1: false,
    2: false,
  };

  planeDragStartingPoint = null;
  //

  edges = [];
  compassAngle = 0;
  firstTimePlaceOnMapMode = false; //this is false because i wanted to disabled the 1st time animation options
  flightId = 0;
  inFlight = false;
  primaryColor: string;
  warnColor: string;
  accentColor: string;
  okColor: string;
  problemColor: string;
  noneColor: string;
  loadingFinished = false;
  stats;
  mouseOutOfScene = false;
  renderer = new WebGLRenderer({ antialias: true, alpha: true, powerPreference: "high-performance" });
  renderRequired = false;
  checkRenderRequireFrameCount = 5;
  frameCount = 0;
  flightInterferdByControls = false;


  //SAO
  composer = null;
  SAOOn = false;
  MixedSAOMode = false;
  saoPass = null;
  controlsChangeStarted = false;

  saoParams = {
    saoBias: 0.35,
    saoIntensity: 0.24,
    saoScale: 20000,
    saoKernelRadius: 50,
    saoBlur: true,
    saoBlurRadius: 8,
    saoBlurStdDev: 4,
    saoBlurDepthCutoff: 2.5e-7,
  };


  lastCameraPosition = new THREE.Vector3();
  lastCameraTarget = new THREE.Vector3();

  xy_plane = new THREE.Plane(new THREE.Vector3(0, 0, 1));



  contrastLight = new THREE.Group();
  scene = null;
  camera = null;
  cameraDirection = new THREE.Vector3();
  mouse = new THREE.Vector2(-100 - 100);
  controls = null;
  transformControls = null;
  transformControlOn = false;
  plcControls = null;
  activePhotosphere = null;
  beforePhotosphereCameraProperties = null;
  dragControls = null;
  collisionStopDistance = 0.5;
  leftJoystick;
  rightJoystick;
  joysticksGenerated = false;
  joysticksOn = false;
  lastCameraEuler = {
    x: 0,
    z: 0
  }

  moveRight = 0;
  moveForward = 0;
  moveLeft = 0;
  moveBack = 0;
  speedFactor = 1;
  desiredZ = 0;
  walkMode = true;


  plcOn = false;

  collidableObjects = [];

  loadingManager = null;
  loader = null;
  projectColladaObject = null;
  INTERSECTED = null;
  INTERSECTED_POINT = null;
  INTERSECTED_NORMAL = null;
  INTERSECTED_MATERIAL = null
  raycaster = new THREE.Raycaster();
  raycasterDblClick = new THREE.Raycaster();
  raycasterPhotospheres = new THREE.Raycaster();
  raycasterLabels = new THREE.Raycaster();

  lastSelectedObjectOid = null;
  selectedObject = null;
  selectedMaterial = new THREE.MeshBasicMaterial({
    name: "selectedMaterial",
    color: 0x00ffff,
    side: THREE.DoubleSide,
  });
  selectedEdges = null;
  hoveredEdges = null;
  selectedEdgesMaterial = new THREE.LineBasicMaterial({
    color: 0x424242,
    linewidth: 2,
  });
  hoveredEdgesMaterial = new THREE.LineBasicMaterial({
    color: 0x000000,
    linewidth: 4,
    transparent: true,
    opacity: 0.1,
  });

  clippingPlanesHoverMat = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.7,
  });
  nonInteractiveObjects = [];
  selectedMode = null;

  newNoteObject = new THREE.Mesh(
    new THREE.SphereGeometry(0.3, 32, 32),
    new THREE.MeshPhongMaterial({ color: 0xb4b4b4 })
  );
  openedPinnedNoteObject = new THREE.Mesh(
    new THREE.SphereGeometry(0.3, 32, 32),
    new THREE.MeshPhongMaterial({ color: 0x00ffff })
  );
  pinnedNoteScale = null;

  newNoteSize = null;

  pinnedNotes: Note[] = [];
  labelsGroup = new THREE.Group();
  pinnedNotesGroup = new THREE.Group();
  intersectPoint = null;
  measureObject = null;
  measureIntersected = null;
  measurePointGeometry = new THREE.SphereGeometry(0.01 / 2, 16, 16);
  measurePointChosenGeometry = new THREE.SphereGeometry(0.011 / 2, 16, 16);
  measurePointMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000 });
  measurePointChosenMaterial = new THREE.MeshPhongMaterial({ color: 0xa4ffa8 });
  magnetOn = true;

  measurePointA = new THREE.Mesh(
    this.measurePointChosenGeometry.clone(),
    this.measurePointChosenMaterial.clone()
  );

  measurePointB = new THREE.Mesh(
    this.measurePointChosenGeometry.clone(),
    this.measurePointChosenMaterial.clone()
  );

  measurePoints = [];
  measureOffset = new THREE.Vector3();
  measureOffsetPlane = new THREE.Plane();

  measureOffsetPlaneHelper = new THREE.Mesh(
    new THREE.CircleGeometry(1, 32),
    new THREE.MeshPhongMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.6,
    })
  ).add(new THREE.Mesh(
    new THREE.CircleGeometry(1, 32),
    new THREE.MeshPhongMaterial({
      color: 0xfffffff,
      transparent: true,
      opacity: 0.4,
      side: THREE.DoubleSide,
      wireframe: true
    }))
  )

  measureArray = [];

  meter = new THREE.Line(
    new THREE.BufferGeometry(),
    new THREE.LineDashedMaterial({
      color: 0xa4ffa8,
      linewidth: 1,
      scale: 1,
      dashSize: 3,
      gapSize: 1,

      // ,depthTest:false
    })
  );

  //ground:
  mapboxOn = false;
  mapboxMode = 'off';

  flyToSphere = new THREE.Mesh(
    new THREE.SphereGeometry(1, 8, 8),
    new THREE.MeshBasicMaterial({ color: 0x000000 })
  );

  //image layers:
  imageLayers = new THREE.Group();

  //photospheres:
  photospheres = new THREE.Group();

  //download loading info
  downloadingModel = null;

  //materials in colors:
  transparentModeColorGray = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.8,
  });
  transparentModeColorGreen = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.8,
  });
  transparentModeColorOrange = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.8,
  });
  transparentGrayMaterial = new THREE.MeshPhongMaterial({
    name: "transparentModeMaterial",
    color: 0xf0f0f0,
    transparent: true,
    opacity: 0.1,
    depthWrite: false,
  });

  photosphereGeometry = new THREE.SphereGeometry(1, 128, 128).scale(-1, 1, 1);

  timestamp = performance.now();
  destroyed = false;

  sun = null;
  sunMonth = 0;
  sunHour = 0;
  firstRenderedImageTaken = false;
  meshParts: any;

  photoAmbientOcclusion = false;
  ambientLight = null;

  directionalLightBimshow = null

  //to help set objects based on the project Object3D
  modelBoxSize = null;
  targetCenterProject = null; //required for directional light sun target

  //galery
  navToolsCssBottom = 0;

  //stencils: 

  stencils: any = {}
  planeMat =
    new THREE.MeshStandardMaterial({

      color: 0xE91E63,
      metalness: 0.1,
      roughness: 0.75,
      clippingPlanes: [],
      stencilWrite: true,
      stencilRef: 0,
      stencilFunc: THREE.NotEqualStencilFunc,
      stencilFail: THREE.ReplaceStencilOp,
      stencilZFail: THREE.ReplaceStencilOp,
      stencilZPass: THREE.ReplaceStencilOp,

    });

  planeNormalMat =
    new THREE.MeshNormalMaterial({


      clippingPlanes: [],
      stencilWrite: true,
      stencilRef: 0,
      stencilFunc: THREE.NotEqualStencilFunc,
      stencilFail: THREE.ReplaceStencilOp,
      stencilZFail: THREE.ReplaceStencilOp,
      stencilZPass: THREE.ReplaceStencilOp,

    });


  //@mg
  gridHelper = new THREE.GridHelper(400, 40, 0x0044ff, 0x808080);
  blockOrbitControls = false;
  blockZControls = true;
  closestCollisionFront = 0;
  sunsphere;


  photosphereCubeCamera = null; //to be clean?
  photosphereCubeCameraRenderTarget = null; //to be clean?

  //envmap
  sceneNoProjectCubeCameraRenderTarget = null;
  sceneNoProjectCubeCamera = null;

  //bulk
  isLoading = false;
  objectToWireframe: boolean = false;
  halfMeter = new THREE.Vector3(0, 0, 0)
  displayShortNameLabelsIsOn = true;



  //concurrent access to variable
  delaySetValueBimshowSaoon: DelaySetValueBimshow = new DelaySetValueBimshow(1000, this, 'SAOOn', true)
  debounceSSAAOn = debounce(this.turnOnSSAA, 200);
  debounceUpdateSingleMeshPasslightFromMaterials = debounce(this.updateSingleMeshPasslightFromMaterials, 200)
  debounceUpdateLabelsAndPhotosphereVis = debounce(this.updateLabelsAndPhotosphereVis, 50)

  //to display edge on meshes
  edgeBimshow = null

  singleMesh = null;
  hoverUniform = { value: 0 }
  selectedUniform = { value: 0 }
  selectedMaterailColorUniform = { type: "c", value: new THREE.Color() }
  timeUniform = { value: 0 }
  zonesColors = {}
  zonesColorsUniform = null;
  zoneMat = null

  drwaCalls = 0;

  modelsMaterials = {};
  models = {};
  hoveredModel = null;

  hiddenModelMateiral = new THREE.MeshPhongMaterial({
    name: "hiddenModelMateiral",
    color: 0xf0f0f0,
    transparent: true,
    opacity: 0.4,
    depthWrite: false,
  });
  hoverModelEdges = null;
  modelIdToAdd = null;
  previewModel = null
  previewModelRotation = 0;
  previewModelScale = 1;
  transformCloneModel = null
  originaltransformCloneModel = null;

  contrastLightAngle = 0;
  contrastLightIntensity = 0.3;

  lastViewConfig = null;

  currentTransition: Promise<any> | null = null;

  animationsManager = new AnimationsManager(() => { this.renderRequired = true });

  outlinePass = null;

  activeMeasure = {
    x: 0,
    y: 0,
    distance: 0
  };

  recordingMode = false;
  recordingTransition: any = null;
  recordings = {};
  labelsPositions = {};
  photospherePositions = {};
  recordingTransitionFinished: Subject<any> = new Subject();
  transitionFinished: Subject<any> = new Subject();

  sequenceTime = 0.5;





  imagePlacementPointsObject = new THREE.Group();


  constructor(
    private ngZone: NgZone,
    private zipService: ZipService,
    private domRenderer: Renderer2,
    public appActionsService: AppActionsService,
    public dataService: DataService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  // to output a message to parent when the threeJS is loaded and rdy
  statusThree = false;
  @Output() eventStatusThree = new EventEmitter<any>();

  ngOnInit() {

    this.ngZone.runOutsideAngular(() => {
      this.rendererContainer.nativeElement.addEventListener("mousemove", (e) => {
        this.onMouseMove(e)
      })

    })

    this.measureOffsetPlaneHelper.userData.ambientOcclusion = true;


    this.renderer.info.autoReset = false;
    this.dataService.threejsComp = this;

    this.stats = new Stats();
    this.stats.showPanel(1); // 0: fps, 1: ms, 2: mb, 3+: custom
    this.threeJsStats.nativeElement.appendChild(this.stats.dom);

    this.renderer.domElement.id = "renderer";


    this.setColorsFromTheme();

    Promise.all(
      [this.loadProjectModel(), this.dataService.predownloadAllUsedMaterials()].map((p) => p.catch((err) => err))
    ).then(async (data) => {


      await this.setCameraInitPosition()

      this.initClippingCube();


      this.generateCollisionObjectsArray();

      this.loadingFinished = true;
      //new views count:
      this.dataService.addViewCount().catch(err => { console.warn('add view count failed') });
      this.domRenderer.addClass(
        this.loadingContainer.nativeElement,
        "finished"
      );


      this.scanAllMaterials();

      this.initSingleMesh()
      this.initAppSubscriptions();

      this.appActionsService.project3dObjectGenerated.next(1);

      this.appActionsService.materialsGenerated.next();
      this.appActionsService.threeJsIsReady = true;



      // send message to parent to inform three is loaded
      this.statusThree = true;
      this.sendMessage();
      //use to set the right position the directional light sun, at least, and not recompute each time
      this.initProjectCenterAndSize();

      //lights

      this.directionalLightBimshow = new DirectionalLightBimshow(this);


      this.resetShadowCameraSize()
      this.resetShadowTarget();

      //edges
      if (this.edgeBimshow) {
        await this.edgeBimshow.remove()
      }
      else {
        this.edgeBimshow = new EdgeBimshow(this)
      }

      //dimensionings
      this.dataService.collectionDimensioning.scene = this.scene
      this.dataService.collectionDimensioning.removeDimensionings()
      this.dataService.collectionDimensioning.set3JSComponent(this)

      //envmap
      this.initCurrentSceneEnvMapNoProject()




      this.ngZone.runOutsideAngular(() => {
        this.animate();
      })




    });

    this.initSceneAndLight();
    this.initPlacementPoints();
    this.initCameraAndControls();
    this.initPinnedNotes();



  }

  ngOnDestroy() {

    //this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))  **caused error, as the view is somehow destroyed and there is still subscription to null that try to do something.. anyway i think this line is from some old logic and not necessery
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    // this.debugGUI.destroy();

    this.plcControls.dispose();
    this.destroyed = true;
    this.appActionsService.project3dObjectGenerated.next(0);

    if (this.edgeBimshow) this.edgeBimshow.dispose()
  }

  async setupView() {
    this.isLoading = true;
    this.isLoading = false;

  }



  initAppSubscriptions() {

    this.subscriptions.push(this.appActionsService.resize.subscribe(() => {
      this.onWindowResize();
    }))

    this.subscriptions.push(this.appActionsService.openCloseViewsEditor.subscribe(() => {
      this.appActionsService.selectedModelId.next();
      this.appActionsService.modelIdToAdd.next();
    }))

    this.subscriptions.push(this.appActionsService.startPreRendering.subscribe(() => {
      this.appActionsService.preRenderingInProcess = {
        progress: 0,
        eta: null,
        step: 'rendering'
      }
      this.generateSequances();
    }))
    this.subscriptions.push(this.appActionsService.selectAndFlyToObjectClicked.subscribe((oid) => {
      this.unchooseOnScene();
      this.chooseOnScene(oid);
      this.flyTo(this.selectedObject)
    }))


    this.subscriptions.push(this.dataService.photosDataFetched.subscribe(() => {
      this.updateLabelsWorldPositions();
    }))


    this.subscriptions.push(this.appActionsService.modelIdToAdd.subscribe((id) => {
      this.modelIdToAdd = id;

      this.loadPreviewModel()
    }))
    this.subscriptions.push(this.appActionsService.updateModelsVisFromConfig.subscribe(() => {
      this.updateModelsVisibilityFromConfig()
    }))
    this.subscriptions.push(this.dataService.modelsUpdated.subscribe(() => {
      this.updateModelsFromData();
    }))

    this.subscriptions.push(this.appActionsService.debugShadowCamera.subscribe((value) => {
      if (!this.directionalLightBimshow) { return }
      if (value) {
        this.directionalLightBimshow.showHelpers();
      } else {
        this.directionalLightBimshow.hideHelpers();
      }
    }));

    this.subscriptions.push(
      this.appActionsService.directionalLightOptionsChanged.subscribe(() => {
        if (this.directionalLightBimshow) {
          // if(this.appActionsService.directionalLightOptions.advancedCameraOptions) {
          //   this.resetShadowCameraSize();
          //   this.resetShadowTarget()
          // }

          this.directionalLightBimshow.update(this.appActionsService.directionalLightOptions)
          this.updateSkyAndBackgroundBasedOnMode();
          this.turnOffSSAA();
        }
      })
    )
    this.subscriptions.push(
      this.dataService.stencilsColor.subscribe((color: string) => {
        this.planeMat.color.set(color);
        if (this.stencils.planeObjects) {
          this.stencils.planeObjects.forEach(po => {
            po.material.color.set(color)
          });
        }

      })
    );

    this.subscriptions.push(
      this.appActionsService.cookieOk.subscribe(() => {
        setTimeout(() => {
          this.onWindowResize();
        }, 100);
      })
    );

    this.subscriptions.push(
      this.appActionsService.viewLoaded.subscribe((view) => {

        this.updateSkyAndBackgroundBasedOnMode();
      })
    );

    this.subscriptions.push(
      this.appActionsService.viewUpdated.subscribe(() => {

        this.updateSkyAndBackgroundBasedOnMode();

      })
    );
    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(async (viewConfig) => {

        await this.waitForMaterialReady();

        if (this.recordingTransition) {
          this.loadViewConfigForSeq(viewConfig, this.sequenceTime)
          return;
        }



        this.lastViewConfig = viewConfig;




        if (this.joysticksOn) { //make sure to turn off game mode for mobile.
          this.closeJoysticksClicked();
        }
        if (!this.firstViewLoad) {
          this.controls.autoRotate = false;
        }
        this.firstViewLoad = false;

        if (viewConfig == null) {
          return;
        }




        this.colorZonesFromConfig(viewConfig.zonesMode);

        if (viewConfig.photospheresConfig) {
          this.updatePhotoSpheres();
        }



        if (viewConfig.cameraParameters != null) {
          let transition = null;
          if (viewConfig.cameraParameters.directionalLightOptions) {
            //todo?: backward comp.  - load from old config params the equiv params (date), or at least add defaults?
            for (let key in this.appActionsService.directionalLightOptions) {
              const value = viewConfig.cameraParameters.directionalLightOptions[key];
              if (value !== null && value !== undefined) {
                this.appActionsService.directionalLightOptions[key] = value;
              }

            }

            this.appActionsService.directionalLightOptions.advancedCameraOptions = !!viewConfig.cameraParameters.directionalLightOptions.advancedCameraOptions

            this.appActionsService.directionalLightOptionsChanged.next();
          }



          if (
            viewConfig.cameraParameters.position != null &&
            viewConfig.cameraParameters.target != null
          ) {

            const sameCameraParams = (
              new THREE.Vector3(viewConfig.cameraParameters.position.x, viewConfig.cameraParameters.position.y, viewConfig.cameraParameters.position.z).distanceTo(this.camera.position) < 0.01 &&
              new THREE.Vector3(viewConfig.cameraParameters.target.x, viewConfig.cameraParameters.target.y, viewConfig.cameraParameters.target.z).distanceTo(this.controls.target) < 0.01

            );

            if (
              (this.dataService.loadedView
                ? this.dataService.loadedView.cameraTransitions && !sameCameraParams
                : false) &&
              (viewConfig.mapboxMode == "off" || viewConfig.mapboxMode == null)
            ) {


              try {

                transition = this.transitionToNewCamera(viewConfig.cameraParameters, 2)
                this.currentTransition = transition



              }
              catch (err) {
                //CLEANUP.. WHAT DO WE NEED HERE?
                if (viewConfig.cameraParameters.photoAmbientOcclusion) {
                  this.dataService.photoAmbientOcclusion.next(viewConfig.cameraParameters.photoAmbientOcclusion)
                  //ask for rendering to apply ambient occlusion only for this pose
                  if (this.composer) {
                    this.composer.render();
                  }
                }
                else {
                  this.turnOffSSAA();
                  this.dataService.photoAmbientOcclusion.next(false)
                }

                //do not conitnue to changing other config..
                return;


              }



            } else {


              //need to check if here because same camera, so need to send an event saying transition camera no
              if (
                this.dataService.loadedView &&
                this.dataService.loadedView.cameraTransitions && sameCameraParams
              ) {
                this.appActionsService.transitionCamera.next(false)
              }

              let position = new THREE.Vector3(
                viewConfig.cameraParameters.position.x,
                viewConfig.cameraParameters.position.y,
                viewConfig.cameraParameters.position.z
              );
              let target = new THREE.Vector3(
                viewConfig.cameraParameters.target.x,
                viewConfig.cameraParameters.target.y,
                viewConfig.cameraParameters.target.z
              );

              this.camera.position.set(position.x, position.y, position.z);
              this.camera.lookAt(target);
              this.controls.target = target;
              this.controls.update();
              this.loadClippingConfig(viewConfig.clippingMode, 0);






              this.askForRender();

            }
            //setting mapbox :
            this.compassAngle =
              (this.controls.getAzimuthalAngle() * 180) / Math.PI;
            if (viewConfig.mapboxMode) {
              if (!this.mapboxOn) {
                this.setMapboxMode(viewConfig.mapboxMode);
              } else {
                const updates = {
                  ...this.getMapboxCameraParams(),
                  fly: this.dataService.loadedView.cameraTransitions,
                  mapboxMode: viewConfig.mapboxMode
                };
                this.appActionsService.mapboxMode = this.mapboxMode = viewConfig.mapboxMode;
                this.appActionsService.toolboxEvents.next(new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, updates));
              }
            } else {
              this.setMapboxMode("off");
            }

            //now update configs

            this.loadClippingConfig(viewConfig.clippingMode, this.dataService.loadedView.cameraTransitions ? 1 : 0);

            if (viewConfig.cameraParameters.fov) {
              this.dataService.cameraFovUpdated.next(viewConfig.cameraParameters.fov)
            }


            try {
              await transition
              console.log('trainstion ok?')
            } catch (err) {
              console.warn('transition stoped')
              return;
            }



            this.updateModelsVisibilityFromConfig();




            //check photo ambient occlusion
            //if (viewConfig.cameraParameters.photoAmbientOcclusion && !this.controls.autoRotate) {
            if (viewConfig.cameraParameters.photoAmbientOcclusion) {
              this.SAOOn = viewConfig.cameraParameters.photoAmbientOcclusion
              this.dataService.photoAmbientOcclusion.next(viewConfig.cameraParameters.photoAmbientOcclusion)
            }
            else {
              this.turnOffSSAA();

              this.dataService.photoAmbientOcclusion.next(false)
            }


          }


          this.controls.enableRotate = !viewConfig.cameraParameters.blockOrbit;
          this.dataService.blockOrbit.next(!this.controls.enableRotate);


          if (!!viewConfig.cameraParameters.contrastLightAngle) this.dataService.contrastLightAngle.next(viewConfig.cameraParameters.contrastLightAngle);
          if (!!viewConfig.cameraParameters.contrastLightIntensity) this.dataService.contrastLightIntensity.next(viewConfig.cameraParameters.contrastLightIntensity);

          this.dataService.blockZ.next(!!viewConfig.cameraParameters.blockZ)


          //check ambient light intensity
          if (viewConfig.cameraParameters.ambientLightIntensity) {
            this.ambientLight.intensity = viewConfig.cameraParameters.ambientLightIntensity
            this.dataService.ambientLightIntensity.next(viewConfig.cameraParameters.ambientLightIntensity)
          }
        }

        console.log('finished in loadconfig subscription')
        this.transitionFinished.next(true)

      })
    );


    this.subscriptions.push(
      this.appActionsService.resetShadowCamSize.subscribe(() => {
        this.resetShadowCameraSize()
      })
    );


    this.subscriptions.push(
      this.appActionsService.resetShadowTarget.subscribe(() => {
        this.resetShadowTarget();
      })
    );

    this.subscriptions.push(this.appActionsService.materialConfigLoaded.subscribe(() => {
      //small hack to prevent slow first load of clipping config:


      this.turnOffSSAA()
      this.turnClippingOn();

      this.render();
      this.turnOffSSAA()
      this.turnClippingOff();
      this.render();

      console.log(this.dataService.viewConfig.MaterialsMode.openedConfigKey);

      this.appActionsService.lastestLoadedMaterialConfig = this.dataService.viewConfig.MaterialsMode.openedConfigKey //important for the transition to wait for;
    }))
    this.subscriptions.push(
      this.appActionsService.materialsUpdated.subscribe(() => {


        if (this.singleMesh) { //fix some bugs for some cases needsupdate was not called for materials
          this.singleMesh.material.forEach(m => {
            m.needsUpdate = true;
          })
        }
        this.askForRender();
      })
    );

    this.subscriptions.push(
      this.appActionsService.materialOpacityUpdated.subscribe(() => {
        this.debounceUpdateSingleMeshPasslightFromMaterials();
      })
    );

    this.subscriptions.push(
      this.appActionsService.toggleVisibilityOfObjects.subscribe(async (toggle) => {
        if (this.currentTransition) {
          try {
            await this.currentTransition;
          } catch (err) {
            //do nothing
          }
        }

        if (this.inFlight) { //cancel in case still in flight, meaning another transition started...
          return
        }

        //update whatever...


        for (let oid of toggle.oids) {
          this.setVisibilityFilterOut(oid, "tree", !toggle.state);
          this.updateVisibilityOfObjectFromFilterOut(oid);
        }

        this.updateVisibilityInSingleMesh();
        this.askForRender(); //check
        this.appActionsService.rerenderOnMapbox.next();
      })
    );

    this.subscriptions.push(
      this.dataService.deletedObjectsChanged.subscribe((change) => {
        this.updateVisibilityOfObjectFromFilterOut(change.guid)
        this.updateVisibilityInSingleMesh();
        this.askForRender(); //check
      })
    );

    this.subscriptions.push(
      this.appActionsService.changeAllZonesVisibility.subscribe((state) => {
        this.zones.forEach((zone) => {
          zone.visible = state;
        });
        this.updateVisibilityInSingleMesh();

        this.askForRender();
      })
    );

    this.subscriptions.push(
      this.appActionsService.globalZonesOpacityChanged.subscribe((opacity) => {
        console.log('OPACITY SUBSCRIB')
       this.zoneMat.opacity = opacity;

        this.askForRender();
      })
    );

    this.subscriptions.push(
      this.appActionsService.changeZonesProperties.subscribe((changes) => {
        let visChanges = false;
        for (let change of changes) {




          let zoneMesh = this.objectsGuidQuery.threejs[change.guid];

          if (change["color"] != null) {
            if (this.zonesColors[change.guid]) {
              this.zonesColors[change.guid].color = new THREE.Color(
                change.color
              );
            }



            if (this.selectedObject) {
              if (this.selectedObject.name != change.guid) {
                //this.scene.getObjectByName(change.guid).material.color = new THREE.Color(change.color);
                zoneMesh.material.color = new THREE.Color(change.color);
              } else {
                //here we deal with the case the object is selected.. hence we need to change the previous material...  and deadl with visibility..

                this.selectedObject["lastUsedMaterial"].color = new THREE.Color(
                  change.color
                );
              }
            } else {
              zoneMesh.material.color = new THREE.Color(change.color);
              //this.scene.getObjectByName(change.guid).material.color = new THREE.Color(change.color);
            }
            this.zonesLabels[change.guid].color = change.color;
          }

          if (change["visible"] != null) {
            if (
              (this.selectedObject ? this.selectedObject.name : null) !=
              change.guid
            ) {
              zoneMesh.visible = change.visible;
            } else {
              zoneMesh.lastVisibleState = change.visible;

            }

            visChanges = true;

          }

          if (change["opacity"] != null) {//TODO : Remove this for single mesh
            let zoneIsSelected = false;
            if (this.selectedObject) {
              if (this.selectedObject.name == change.guid) {
                zoneIsSelected = true;
              }
            }

            if (!zoneIsSelected) {
              zoneMesh.material.opacity = change.opacity;
            }
          }
        }

        if (visChanges) {
          this.updateVisibilityInSingleMesh();
        }

        this.updateColorsInSingleMesh();
        this.askForRender();
      })
    );

    this.subscriptions.push(
      this.appActionsService.changeLayersVisibility.subscribe((layers) => {
        for (let layer of layers) {
          for (let oid of layer.objectsOids) {
            this.setVisibilityFilterOut(oid, "layers", !layer.visibility);
            this.updateVisibilityOfObjectFromFilterOut(oid);
          }
        }
        this.updateVisibilityInSingleMesh();
        this.appActionsService.rerenderOnMapbox.next();
        this.askForRender();
      })
    );

    this.subscriptions.push(this.appActionsService.selectedModelId.subscribe((id: string | null) => {

      if (id) {
        this.appActionsService.selectedModel.next(this.models[id])
        this.startTransformModel(id)
      } else {

        this.appActionsService.selectedModel.next(null)
        this.stopTransformModel();
      }
    }))

    this.subscriptions.push(
      this.appActionsService.chosenObjectOid.subscribe((oid) => {

        if (this.selectedObject) {
          this.unchooseOnScene();
        }

        if (oid) {
          if (this.appActionsService.selectedDataMode != "materials-editor") {
            this.chooseOnScene(oid);

          }
        }
      })
    );

    this.subscriptions.push(
      this.appActionsService.toolboxEvents.subscribe((event) => {

        if (event.tool == null) {

          this.closeSelectedMode();
          this.exitImagePlacement();
        }

        if (event.type == "open") {
          this.exitImagePlacement();
          if (this.selectedMode) {
            //close open mode:
            this.closeSelectedMode();


            //resend open event;
            this.appActionsService.toolboxEvents.next(event);
            return;
          }

          if (this.selectedMode == null) {
            //now open the new tool:
            this.appActionsService.selectedModelId.next();
            this.appActionsService.modelIdToAdd.next();
            switch (event.tool) {
              case "transparentMode":
                this.selectedMode = event.tool;
                this.enterTransparentMode();
                break;
              case "measureMode":
                this.selectedMode = event.tool;
                this.enterMeasureMode();
                break;

              case "placeOnMapMode":
                this.selectedMode = event.tool;
                this.enterPlaceOnMapMode();
                break;
              case "zonesMode":
                this.selectedMode = event.tool;
                this.enterZonesMode();
                break;
              case "clippingPlanes":
                this.selectedMode = event.tool;
                this.enterClippingPlanesMode();
                break;

            }


          }
        }

        if (event.type == "updates") {
          if (
            event.tool == "clippingPlanes" &&
            event.emitter != this.constructor.name
          ) {
            if (event.updates) {
              let updates = event.updates;

              if (updates.clippingOn == true) {
                this.turnClippingOn();
              }

              if (updates.clippingOn == false) {
                this.turnClippingOff();
              }


              if (updates.stencilsOn == true) {
                this.turnClippingStencilsOn();
              }

              if (updates.stencilsOn == false) {
                this.turnClippingStencilsOff();
              }

              if (updates.reset) {
                this.resetClippingCube();
              }

              if (updates.config) {
                //todo: remove this? i dont thing it react to anything...
                this.loadClippingConfig(updates.config);
              }
            }

            this.askForRender();
          }

          if (
            event.tool == "pdfCompareMode" &&
            event.emitter != this.constructor.name
          ) {



            if (event.updates.stopImagePlacement) {
              this.exitImagePlacement();
            } else if (event.updates.startImagePlacement) {
              this.enterImagePlacement(event.updates.startImagePlacement);
            }

            if (event.updates.positionOnCamera) {
              this.placeImageOnCamera(event.updates.positionOnCamera)
            }

            if (event.updates.updateImage) {
              this.updateImageLayer(event.updates.updateImage);
            }

            if (event.updates.updateLayersOrder) {
              this.orderLayers();
            }

            if (event.updates.addNewImage) {
              this.generateImageLayer(event.updates.addNewImage)
                .then(() => {
                  this.orderLayers();

                  this.updateImageLayer(event.updates.addNewImage);
                })
                .catch((rejected) => {
                  if (rejected.code == 2) {
                    this.orderLayers();
                    this.updateImageLayer(event.updates.addNewImage);
                  }

                  if (rejected.code == 1) {
                    console.error(rejected.err);
                  } else {
                    console.error(rejected);
                  }
                });
            }

            if (event.updates.imageDeleted) {
              this.deleteImageLayer(event.updates.imageDeleted);
            }
          }

          if (
            event.tool == "placeOnMapMode" &&
            event.emitter != this.constructor.name &&
            this.selectedMode == "placeOnMapMode"
          ) {
            if (event.updates.switchMapMode) {
              this.setMapboxMode(event.updates.switchMapMode);
            }



            if (event.updates.cameraProperties && this.mapboxOn) {

              let cp = event.updates.cameraProperties;

              if (cp.compassAngle) {
                //updating compass
                this.compassAngle = cp.compassAngle
              }

              //upading threejs camera now :
              //default cp (incase not probided)
              let r = 1000;
              let phi = Math.PI / 2;
              let theta = 0;

              if (cp.zoom) {
                //calc r from zoom
                r = Math.pow(29.16 / cp.zoom, 10.45);
              }

              if (cp.pitch != null) {
                phi = Math.max(Math.PI * (cp.pitch / 180), 0.01);
              }

              if (cp.compassAngle) {
                theta = (Math.PI * (cp.compassAngle - this.dataService.siteData.rotate)) / 180;
              }

              if (cp.center) {
                let xy = this.convertCoordinates(cp.center.long, cp.center.lat);
                let target = new THREE.Vector3(xy.x, xy.y, -this.dataService.siteData.offsetZ);

                let p = new THREE.Vector3()
                  .setFromSpherical(new THREE.Spherical(r, phi, theta))
                  .applyAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

                this.camera.position.addVectors(target, p);

                this.controls.target = target;
                this.askForRender();
                this.camera.lookAt(target);
                this.controls.update();
              }

              if (this.mapboxOn) {
              } else {
                this.askForRender();
              }
            }
          }

          if (
            event.tool == "gallery" &&
            event.emitter != this.constructor.name
          ) {
            if (event.updates.takePhoto) {
              this.takePhoto();
            }

            if (event.updates.refreshPhoto) {
              this.takePhoto(event.updates.refreshPhoto);
            }
          }

          if (
            event.tool == "measureMode" &&
            event.emitter != this.constructor.name
          ) {
            if (event.updates.magnetOn != null) {
              this.clearMagnetPoints();
              this.magnetOn = event.updates.magnetOn;
            }
          }

          if (
            event.tool == "addNote" &&
            event.emitter != this.constructor.name
          ) {
            if (event.type == "updates") {
              if (event.updates.size) {
                this.pinnedNoteScale = event.updates.size;
              }
              if (event.updates.pinMode != null) {
                if (event.updates.pinMode) {
                  this.selectedMode = event.tool;
                  this.enterPinnedNoteMode();
                } else {
                  this.closeSelectedMode();
                  this.appActionsService.toolboxEvents.next(
                    new ToolboxEvent("addNote", "open", this.constructor.name)
                  );
                }
              }
            }
          }
        }
      })
    );

    this.subscriptions.push(this.appActionsService.selectedDataModeChanged.subscribe(() => {
      this.exitImagePlacement();
      //clear models appaction params

      this.appActionsService.modelIdToAdd.next(null);
      if (this.appActionsService.selectedDataMode !== "materials-editor") {
        this.appActionsService.selectedModelId.next();
      }




      if (this.appActionsService.selectedDataMode === "materials-editor") {
        this.unchooseOnScene();
      }
    }))

    this.subscriptions.push(
      this.appActionsService.cssGalleryHeight.subscribe(height => {
        if (height == 0) {
          this.navToolsCssBottom = 0
        }
        else {
          this.navToolsCssBottom = height + 15
        }
      })
    );

    this.subscriptions.push(
      this.dataService.photoAmbientOcclusion.subscribe(saoon => {
        this.photoAmbientOcclusion = saoon
        this.SAOOn = saoon
        this.dataService.viewConfig.cameraParameters.photoAmbientOcclusion = saoon
        this.askForRender();
      })
    );
    this.subscriptions.push(
      this.dataService.blockOrbit.subscribe(bo => {

        this.blockOrbitControls = bo
        this.controls.enableRotate = !bo;
        this.dataService.viewConfig.cameraParameters.blockOrbit = bo
        this.askForRender();  //@mg want to render now ?

      })
    );

    //@mg Don't let to go below the ground
    this.dataService.blockZ.subscribe(bo => {
      this.blockZControls = bo
      if (this.blockZControls === false) {
        this.controls.maxPolarAngle = Math.PI;
      } else {
        this.controls.maxPolarAngle = Math.PI / 2;
      }


      this.dataService.viewConfig.cameraParameters.blockZ = bo
      this.askForRender();
    })



    this.subscriptions.push(
      this.dataService.ambientLightIntensity.subscribe(ambientLightIntensity => {
        this.ambientLight.intensity = ambientLightIntensity
        this.askForRender()
      })
    );


    this.subscriptions.push(
      this.dataService.contrastLightAngle.subscribe(angle => {
        this.contrastLightAngle = angle;
        this.contrastLight.setRotationFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          angle * Math.PI / 180
        );
        this.askForRender()
      })
    );

    this.subscriptions.push(
      this.dataService.contrastLightIntensity.subscribe(value => {
        this.contrastLightIntensity = value;
        this.contrastLight.children.forEach((l) => {
          l.intensity = value;
        })
        this.askForRender()
      })
    );

    this.subscriptions.push(
      this.dataService.notesDataFetched.subscribe(() => {

        this.refreshPinnedNotes();
        if (this.selectedMode) {
          if (this.selectedMode == "transparentMode") {
            this.refreshTransparentMode();
          }
        }
      })
    );

    this.subscriptions.push(
      this.dataService.labelsUpdated.subscribe(() => {
        this.labels = this.dataService.labels;

        this.updateLabelsWorldPositions();
        this.askForRender(); // will also update labels..
      })
    );

    this.subscriptions.push(
      this.dataService.photospheresUpdated.subscribe(() => {
        this.updatePhotoSpheres();

        for (let id in this.dataService.photospheres) {
          if (!this.photosphereLabels[id]) {
            this.photosphereLabels[id] = {};
          }
        }

        for (let id in this.photosphereLabels) {
          if (!this.dataService.photospheres[id]) {
            delete this.photosphereLabels[id]
          }
        }


        this.updatePhotospheresLabels();

        this.askForRender(); // will also update labels..
      })
    );

    this.subscriptions.push(
      this.dataService.notesObjectsRelationsDataFetched.subscribe(() => {
        if (this.selectedMode) {
          if (this.selectedMode == "transparentMode") {
            this.refreshTransparentMode();
          }
        }
      })
    );

    this.subscriptions.push(
      this.appActionsService.photsphereLabelClicked.subscribe((id) => {
        this.onPhotosphereClicked(id);
      })
    );
    this.subscriptions.push(
      this.appActionsService.flyToPhotosphereClicked.subscribe((id) => {
        const mesh = this.photospheres.getObjectByName(id);
        const pos = new THREE.Vector3();
        mesh.getWorldPosition(pos);
        this.transitionToNewCamera({
          position: pos,
          target: pos.clone()
            .add(new THREE.Vector3(0, 1, 0)),
        });
      })
    );

    this.subscriptions.push(
      this.appActionsService.openNote.subscribe((note) => {
        if (note) {
          if (note.position) {
            this.openedPinnedNoteObject.visible = true;

            this.pinnedNotesGroup
              .getObjectByName("noteMarker_" + note.id)
              .getWorldPosition(this.openedPinnedNoteObject.position);

            let scale = 1.1 * note.scale;

            this.openedPinnedNoteObject.scale.set(scale, scale, scale);

            this.flyTo(this.openedPinnedNoteObject);
          } else {
            this.openedPinnedNoteObject.visible = false;
          }
          this.askForRender();
        } else {
          this.openedPinnedNoteObject.visible = false;
          this.askForRender();
        }
      })
    );

    //added by ben 2022-04-20
    this.subscriptions.push(
      this.dataService.cameraFovUpdated.subscribe(async (value) => {
        const start = this.camera.fov;
        this.dataService.viewConfig.cameraParameters.fov = value

        let T = 0;

        if (this.inFlight) {
          T = 2;
          // await this.currentTransition;
        }

        const end = value;

        this.animationsManager.addAnimation(
          Math.max(1, Math.ceil(60 * T)),
          (a) => {

            const alpha = a;

            this.camera.fov = (1 - alpha) * start + alpha * end;

            this.camera.updateProjectionMatrix();
            this.saoPass.updateFromCameraMatrix();
            this.turnOffSSAA();
            this.askForRender();


          },
          (isCanceled) => {
            if (isCanceled) {
              return;
            }


          },
          "fov", true)



        // if (value) {
        //   this.camera.fov = value;
        //   //this.camera.setFocalLength(value);
        //   this.dataService.viewConfig.cameraParameters.fov = value

        // }
        // else {
        //   //to delete?!
        //   this.camera.fov = value;
        //   //this.camera.setFocalLength(72);
        // }


      })
    );

    this.appActionsService.assetCustomMaterialUpdate.subscribe(update => {
      if (update.reset) {
        this.resetAssetModel(update.modelId)
      } else {
        this.updateAssetMaterials(update)
      }
    })

    this.subscriptions.push(
      this.appActionsService.objectCustomMaterialUpdated.subscribe(update => {
        this.updateCustomObjectMaterial(update)
      })
    )
    this.subscriptions.push(
      this.appActionsService.objectCustomMaterialReset.subscribe(() => {
        this.singleMesh.geometry.index.array = this.singleMesh.resetData.array.slice();
        this.singleMesh.geometry.groups = JSON.parse(JSON.stringify(this.singleMesh.resetData.groups));
        this.singleMesh.geometry.index.needsUpdate = true;
        for (let oid in this.dataService.objectsData) {
          if (this.dataService.objectsData[oid].material) {
            const lengthBefore = this.dataService.objectsData[oid].material.length


            this.dataService.objectsData[oid].material.push(...this.dataService.objectsData[oid].originalMaterial)

            this.dataService.objectsData[oid].material.splice(0, lengthBefore)
          }

        }


        //for assets model

        for (let modelId in this.models) {
          this.scene.remove(this.models[modelId])
          delete this.models[modelId];
        }
        this.updateModelsFromData();


      })

    )
    this.subscriptions.push(
      this.appActionsService.updateMaterialsForSingleMesh.subscribe(() => {
        this.updateSingleMeshMaterialFromMaterialList();
      })

    )

  }

  async loadViewConfigForSeq(viewConfig, cameraTransitionTime = 2) {
    //TODO: wait here untill material was sure to be loaded...

    this.lastViewConfig = viewConfig;


    if (this.joysticksOn) { //make sure to turn off game mode for mobile.
      this.closeJoysticksClicked();
    }
    if (!this.firstViewLoad) {
      this.controls.autoRotate = false;
    }
    this.firstViewLoad = false;

    if (viewConfig == null) {
      return;
    }




    this.colorZonesFromConfig(viewConfig.zonesMode);

    if (viewConfig.photospheresConfig) {
      this.updatePhotoSpheres();
    }



    if (viewConfig.cameraParameters != null) {
      let transition = null;
      if (viewConfig.cameraParameters.directionalLightOptions) {
        //todo?: backward comp.  - load from old config params the equiv params (date), or at least add defaults?
        for (let key in this.appActionsService.directionalLightOptions) {
          const value = viewConfig.cameraParameters.directionalLightOptions[key];
          if (value !== null && value !== undefined) {
            this.appActionsService.directionalLightOptions[key] = value;
          }

        }

        this.appActionsService.directionalLightOptions.advancedCameraOptions = !!viewConfig.cameraParameters.directionalLightOptions.advancedCameraOptions

        this.appActionsService.directionalLightOptionsChanged.next();
      }



      if (
        viewConfig.cameraParameters.position != null &&
        viewConfig.cameraParameters.target != null
      ) {

        const sameCameraParams = (
          new THREE.Vector3(viewConfig.cameraParameters.position.x, viewConfig.cameraParameters.position.y, viewConfig.cameraParameters.position.z).distanceTo(this.camera.position) < 0.01 &&
          new THREE.Vector3(viewConfig.cameraParameters.target.x, viewConfig.cameraParameters.target.y, viewConfig.cameraParameters.target.z).distanceTo(this.controls.target) < 0.01

        );

        if (
          (this.dataService.loadedView
            ? this.dataService.loadedView.cameraTransitions && !sameCameraParams
            : false) &&
          (viewConfig.mapboxMode == "off" || viewConfig.mapboxMode == null)
        ) {


          try {

            transition = this.transitionToNewCamera(viewConfig.cameraParameters, cameraTransitionTime)
            this.currentTransition = transition



          }
          catch (err) {
            //CLEANUP.. WHAT DO WE NEED HERE?
            if (viewConfig.cameraParameters.photoAmbientOcclusion) {
              this.dataService.photoAmbientOcclusion.next(viewConfig.cameraParameters.photoAmbientOcclusion)
              //ask for rendering to apply ambient occlusion only for this pose
              if (this.composer) {
                this.composer.render();
              }
            }
            else {
              this.turnOffSSAA();
              this.dataService.photoAmbientOcclusion.next(false)
            }

            //do not conitnue to changing other config..
            return;


          }



        } else {


          //need to check if here because same camera, so need to send an event saying transition camera no
          if (
            this.dataService.loadedView &&
            this.dataService.loadedView.cameraTransitions && sameCameraParams
          ) {
            this.appActionsService.transitionCamera.next(false)
          }

          let position = new THREE.Vector3(
            viewConfig.cameraParameters.position.x,
            viewConfig.cameraParameters.position.y,
            viewConfig.cameraParameters.position.z
          );
          let target = new THREE.Vector3(
            viewConfig.cameraParameters.target.x,
            viewConfig.cameraParameters.target.y,
            viewConfig.cameraParameters.target.z
          );

          this.camera.position.set(position.x, position.y, position.z);
          this.camera.lookAt(target);
          this.controls.target = target;
          this.controls.update();
          this.loadClippingConfig(viewConfig.clippingMode, 0);






          this.askForRender();

        }
        //setting mapbox :
        this.compassAngle =
          (this.controls.getAzimuthalAngle() * 180) / Math.PI;
        if (viewConfig.mapboxMode) {
          if (!this.mapboxOn) {
            this.setMapboxMode(viewConfig.mapboxMode);
          } else {
            const updates = {
              ...this.getMapboxCameraParams(),
              fly: true,
              mapboxMode: viewConfig.mapboxMode
            };
            this.appActionsService.toolboxEvents.next(new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, updates));
          }
        } else {
          this.setMapboxMode("off");
        }

        //now update configs

        this.loadClippingConfig(viewConfig.clippingMode, cameraTransitionTime / 2);

        if (viewConfig.cameraParameters.fov) {
          this.dataService.cameraFovUpdated.next(viewConfig.cameraParameters.fov)
        }


        try {
          await transition
          console.log('trainstion ok?')
        } catch (err) {
          console.warn('transition stoped')
          return;
        }



        this.updateModelsVisibilityFromConfig();




        //check photo ambient occlusion
        //if (viewConfig.cameraParameters.photoAmbientOcclusion && !this.controls.autoRotate) {
        if (viewConfig.cameraParameters.photoAmbientOcclusion) {
          this.SAOOn = viewConfig.cameraParameters.photoAmbientOcclusion
          this.dataService.photoAmbientOcclusion.next(viewConfig.cameraParameters.photoAmbientOcclusion)
        }
        else {
          this.turnOffSSAA();

          this.dataService.photoAmbientOcclusion.next(false)
        }


      }


      this.controls.enableRotate = !viewConfig.cameraParameters.blockOrbit;
      this.dataService.blockOrbit.next(!this.controls.enableRotate);


      if (!!viewConfig.cameraParameters.contrastLightAngle) this.dataService.contrastLightAngle.next(viewConfig.cameraParameters.contrastLightAngle);
      if (!!viewConfig.cameraParameters.contrastLightIntensity) this.dataService.contrastLightIntensity.next(viewConfig.cameraParameters.contrastLightIntensity);

      this.dataService.blockZ.next(!!viewConfig.cameraParameters.blockZ)


      //check ambient light intensity
      if (viewConfig.cameraParameters.ambientLightIntensity) {
        this.ambientLight.intensity = viewConfig.cameraParameters.ambientLightIntensity
        this.dataService.ambientLightIntensity.next(viewConfig.cameraParameters.ambientLightIntensity)
      }
    }

    console.log('finished in loadconfig subscription')
    this.recordingTransitionFinished.next(true)


  }

  async waitForMaterialReady() {

    await new Promise((res) => {
      const ivl = setInterval(() => {
        if (this.appActionsService.lastestLoadedMaterialConfig == this.dataService.viewConfig.MaterialsMode.openedConfigKey) {
          clearInterval(ivl)
          res(true);
          return;
        }
      }, 15);
    })

  }

  loadPhotoAndWaitForTransitionFinished(photo) {

    return new Promise((resolve) => {

      this.dataService.loadedView.cameraTransitions = false;
      this.dataService.loadPhotoView(photo)

      const sub1 = this.transitionFinished.subscribe(async () => {
        await waitSeconds(0.3)
        sub1.unsubscribe();
        resolve(true);
      })
    })


  }

  setColorsFromTheme() {
    this.primaryColor = getComputedStyle(
      this.primaryColorDiv.nativeElement
    ).color;
    this.accentColor = getComputedStyle(
      this.accentColorDiv.nativeElement
    ).color;
    this.warnColor = getComputedStyle(this.warnColorDiv.nativeElement).color;
    this.okColor = getComputedStyle(this.okColorDiv.nativeElement).color;
    this.problemColor = getComputedStyle(
      this.problemColorDiv.nativeElement
    ).color;
    this.noneColor = getComputedStyle(this.noneColorDiv.nativeElement).color;

    this.selectedMaterial.color.set(this.accentColor);
    this.selectedMaterailColorUniform.value = this.selectedMaterial.color;
    this.openedPinnedNoteObject.material.color.set(this.accentColor);

    this.transparentModeColorGray.color.set(this.noneColor);
    this.transparentModeColorGreen.color.set(this.okColor);
    this.transparentModeColorOrange.color.set(this.problemColor);

    this.clippingPlanesHoverMat.color.set(this.primaryColor);
  }



  closeSelectedMode() {



    switch (this.selectedMode) {
      case "addNote":
        this.exitPinnedNoteMode();
        break;
      case "transparentMode":
        this.exitTransparentMode();
        break;
      case "measureMode":
        this.exitMeasureMode();
        break;

      case "placeOnMapMode":
        this.exitPlaceOnMapMode();
        break;
      case "zonesMode":
        this.exitZonesMode();
        break;
      case "clippingPlanes":
        this.exitClippingPlanesMode();
        break;
    }
  }

  getBoundingBoxOfGroup(group) {

    return new THREE.Box3().setFromObject(group);
  }


  async setCameraInitPosition() {
    const photo = await this.dataService.getLoadedViewDefaultPhoto()

    if (photo) {
      const viewConfig = photo.viewConfig;
      let position = new THREE.Vector3(
        viewConfig.cameraParameters.position.x,
        viewConfig.cameraParameters.position.y,
        viewConfig.cameraParameters.position.z
      );
      let target = new THREE.Vector3(
        viewConfig.cameraParameters.target.x,
        viewConfig.cameraParameters.target.y,
        viewConfig.cameraParameters.target.z
      );

      this.camera.position.set(position.x, position.y, position.z);
      this.camera.lookAt(target);
      this.controls.target = target;
      this.controls.update();
    } else {
      this.placeCameraOnFullProjectView();
    }
  }

  placeCameraOnFullProjectView() {
    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

    let d = Math.sqrt(
      Math.pow(modelBox.max.x - modelBox.min.x, 2) +
      Math.pow(modelBox.max.y - modelBox.min.y, 2)
    );
    d = modelBox.min.distanceTo(modelBox.max);

    let modelCenter = new THREE.Vector3();
    modelBox.getCenter(modelCenter);

    let target = new THREE.Vector3();
    modelBox.getCenter(target);

    let cameraStartPosition = new THREE.Vector3(
      modelBox.min.x - d / 3,
      modelCenter.y - d / 3,
      modelBox.max.z + d / 3
    );
    cameraStartPosition = target
      .clone()
      .add(new THREE.Vector3(0, -2, 1).normalize().multiplyScalar(d));

    this.camera.position.set(
      cameraStartPosition.x,
      cameraStartPosition.y,
      cameraStartPosition.z
    );

    this.camera.lookAt(target);
    this.controls.target = target;
    this.controls.update();
  }


  loadProjectModel() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.loadingManager = new THREE.LoadingManager(() => {
          afterPorjectLoaded();
        });

        let afterPorjectLoaded = () => {
          this.scene.add(this.projectColladaObject);



          this.projectColladaObject.add(this.pinnedNotesGroup);


          this.subscriptions.push(
            this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
              if (ifcData == null) {
                return;
              }

              let zones = this.dataService.getZones();

              zones.forEach((zone) => {
                let zoneMesh = this.scene.getObjectByName(zone.guid); // (old query way) , but it runs just on loading, and usually we dont have the objectsguidquery ready yet..

                if (zoneMesh) {

                  zoneMesh.material = new THREE.MeshStandardMaterial({
                    color: new THREE.Color("hsl(197,70%,39%)"),
                    transparent: true,
                    opacity: 0.3,
                    depthWrite: false,
                    side: THREE.FrontSide,
                    name: "zoneMaterial",
                  });
                  zoneMesh.isZone = true;
                  this.zones.push(zoneMesh);
                  zoneMesh.visible = false;

                  this.zonesLabels[zone.guid] = {
                    shortName: zone.name,
                    longName: zone.longName,
                    color: zone.color,
                  };
                } else {
                  console.warn(
                    "zone '" + zone.name + "' not found in DAE model"
                  );
                }
              });

              //update Zones Colors, inscase its not a zone mode that is loaded aswell in the same time

              if (this.dataService.viewConfig.zonesMode) {
                let updates = [];

                for (let zone of this.dataService.viewConfig.zonesMode) {
                  let update = { guid: zone.guid };
                  if (zone.color != null) {
                    update["color"] = zone.color;
                  } else {
                    update["color"] = "hsl(197,70%,39%)"; //rememeber to change it if we change the default color
                  }

                  updates.push(update);
                }
                this.appActionsService.changeZonesProperties.next(updates);
              }


              resolve(null);
            })
          );
        };

        this.dataService.getProjectModel(this.project).then((modelData) => {
          if (modelData.type == "dae") {
            let dataurl = window.URL.createObjectURL(modelData.blob);
            const loader = new ColladaLoader(this.loadingManager);

            loader.load(dataurl, null, (collada) => {

              loadColladaFile(collada);
            });
          }

          if (modelData.type == "json") {
            loadJsonModel(JSON.parse(modelData.data));
          }
        }).catch(err => {

          this.appActionsService.errorMessage = 'Error in loading project'
        });

        let loadJsonModel = (json) => {
          const loader = new THREE.ObjectLoader();
          if (this.project.origin == 'collada') {
            this.projectColladaObject = loader.parse(json);
            // const singleMesh = loader.parse(json).children[0];
            const singleMesh = generateSingleMeshFromColladaObjet(this.projectColladaObject)


            this.singleMesh = singleMesh;

          } else {
            json.singleMesh.materials.concat(json.materials).forEach(m => {
              m.type = "MeshStandardMaterial";
              m.roughness = 1;
              m.metalness = 0;
            })

            this.singleMesh = loader.parse(json.singleMesh);
            delete json.singleMesh;

            this.projectColladaObject = loader.parse(json);
          }




          // const flatModel = new THREE.Object3D();
          // const modelWithHirarchy = loader.parse(json);
          // modelWithHirarchy.traverse( object => {
          //   flatModel.add
          // })
          const meshes = [];
          this.projectColladaObject.traverse(object => {
            if (object == this.projectColladaObject) {
              return;
            }

            meshes.push(object);
          })

          this.projectColladaObject.clear();
          meshes.forEach(m => {
            m.clear();
            this.projectColladaObject.add(m)
          })




          const scale = json.metadata.scale || 1;
          this.projectColladaObject.scale.set(scale, scale, scale);

          if (this.projectColladaObject.userData.type == "ifc") {
            this.projectColladaObject.setRotationFromEuler(
              new THREE.Euler(Math.PI / 2, 0, 0, "ZXY")
            );
          } else {
            this.projectColladaObject.setRotationFromAxisAngle(
              new THREE.Vector3(0, 0, 1),
              0
            );
          }




          let t = new Tictac("genlits");
          this.generateObjectsList(
            this.projectColladaObject,
            this.objectsGuidQuery.threejs
          );

          // this.generateObjectsList(
          //   this.dataService.project3dObject,
          //   this.objectsGuidQuery.mapbox
          // );

          this.generateEidGuidDicts();

          if (this.project.origin !== 'collada') {
            this.generateUVsAndComputeNormals()
          }




          t.logPassedTime();

          afterPorjectLoaded();
        };

        let loadColladaFile = (collada) => {

          this.projectColladaObject = collada.scene;

          this.dataService.project3dObject = this.projectColladaObject.clone();

          let t = new Tictac("genlits");
          this.generateObjectsList(
            this.projectColladaObject,
            this.objectsGuidQuery.threejs
          );
          this.generateObjectsList(
            this.dataService.project3dObject,
            this.objectsGuidQuery.mapbox
          );

          t.logPassedTime();
        };
      } catch (err) {
        reject(err);
      }
    });
  }

  setSceneBackground(mode?) {
    if (mode == "light") {
      this.scene.background = new THREE.Color(0xffffff);
    } else {
      this.scene.background = new THREE.Color(0x1a1a1a);  //default background color
    }
    this.renderRequired = true;

  }


  //compute project center and size to be use by other algo (sun position for example)
  initProjectCenterAndSize() {
    //check if directionalLight for sun , yes need to remove and replace
    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

    this.modelBoxSize = new THREE.Vector3();
    modelBox.getSize(this.modelBoxSize)
    let target = new THREE.Vector3();
    modelBox.getCenter(target);
    //init an Object3D with porject collada center position to let the directional light target it
    this.targetCenterProject = new THREE.Object3D();
    this.targetCenterProject.position.set(target.x, target.y, target.z)
    this.scene.add(this.targetCenterProject)
  }

  initSceneAndLight() {
    this.scene = new THREE.Scene();

    // //debug spheres
    // this.scene.add(this.debugSphere1)

    // this.scene.add(this.debugSphere2)

    this.setSceneBackground();
    //set the default environment texture: fix also ambient occlusion background grey
    var loader = new THREE.TextureLoader();




    this.scene.add(this.imageLayers);
    this.scene.add(this.photospheres);

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(this.ambientLight);

    const hl1 = new THREE.HemisphereLight(0xffffff, 0x000000, 1)
    hl1.position.set(0, 1, 0);

    const hl2 = new THREE.HemisphereLight(0xffffff, 0x000000, 1)
    hl2.position.set(1, 0, 0);
    this.contrastLight.add(hl1)
    this.contrastLight.add(hl2)
    this.scene.add(this.contrastLight);



    this.newNoteObject.visible = false;
    this.scene.add(this.newNoteObject);
    this.openedPinnedNoteObject.visible = false;
    this.openedPinnedNoteObject.name = "openedNoteMarker";
    this.scene.add(this.openedPinnedNoteObject);

    this.measurePointA.visible = false;
    this.measurePointA.name = "measurePointA";

    this.measurePointB.visible = false;
    this.measurePointB.name = "measurePointB";
    this.scene.add(this.measurePointA);
    this.scene.add(this.measurePointB);
    this.scene.add(this.measureOffsetPlaneHelper)
    this.measureOffsetPlaneHelper.visible = false;

    this.meter.visible = false;
    this.scene.add(this.meter);
  }

  initCameraAndControls() {
    let width = document.getElementById("app-content-container").clientWidth;
    let height = document.getElementById("app-content-container").clientHeight;

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 100000);

    this.camera.position.set(200, 200, 200);
    this.camera.up = new THREE.Vector3(0, 0, 1);
    //
    this.initPlcControls();

    this.ngZone.runOutsideAngular(() => {
      this.controls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
    })


    this.appActionsService.startRotating.next();
    this.controls.target = new THREE.Vector3(0, 0.3, 0.36);

    this.controls.addEventListener("change", (e) => {

      this.appActionsService.hideAllLabelsAndPhotosphere = true;

      this.turnOffSSAA();
      if (this.dataService.collectionDimensioning) {
        this.dataService.collectionDimensioning.setDimensioningsScaleCamera(this.camera)
      }

      this.askForRender();
    });

    this.controls.addEventListener("start", (e) => {

      this.controlsChangeStarted = true; //hack to make sure it happens only when user did it.
      this.flightInterferdByControls = true;
      this.animationsManager.cancelAnaimation("cameraTransition", true)
    });

    this.controls.addEventListener("end", (e) => {

    });
    this.controls.update();

    // How far you can orbit vertically, upper and lower limits.
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;

    // How far you can dolly in and out ( PerspectiveCamera only )
    this.controls.minDistance = 0;
    this.controls.maxDistance = Infinity;

    this.controls.enableZoom = true; // Set to false to disable zooming
    this.controls.zoomSpeed = 1.0;

    this.controls.enablePan = true;


    //add transform controls
    this.ngZone.runOutsideAngular(() => {
      this.transformControls = new TransformControls(
        this.camera,
        this.renderer.domElement);
    });
    this.transformControls.addEventListener('dragging-changed', (event) => {

      this.controls.enabled = !event.value;
      this.askForRender();
    });

    this.transformControls.addEventListener('mouseUp', (event) => {

      if (this.transformCloneModel) { //clone with transform 
        this.executeTransformClone();
      } else { //normal transformChange
        const model = this.transformControls.object;
        if (model) {
          this.updateModelTransformData(model.userData.modelId)
        }

      }

      this.askForRender();
    });

    this.transformControls.addEventListener('change', (event) => {

      this.turnOffSSAA()
      if (this.directionalLightBimshow) {
        this.directionalLightBimshow.updateShadowMap();//maybe will be too heavy in some cases? too check.. for now looks fine
      }

      this.askForRender();
    });

    this.transformControls.snapToIntersectHandler = () => {


      let intersectWith = [this.projectColladaObject];

      if (this.appActionsService.selectedDataMode === 'models') {
        let models: any[] = Object.values(this.models)

        if (this.transformControls.object) {

          if (this.transformControls.object.userData.category == "plants_outdoor") { //for trees intersect only with floor
            models = [];

          }
        }

        intersectWith = intersectWith.concat(models);
      }

      this.raycaster.setFromCamera(this.mouse, this.camera);
      var intersects = this.raycaster.intersectObjects(
        intersectWith,
        true
      );



      let firstVisible = null;

      intersects = intersects.filter(i => (i.object.userData.modelId !== this.transformControls.object.userData.modelId))

      for (let intersect of intersects) {
        if (intersect.object.visible) {
          firstVisible = intersect;
          break;
        }
      }

      let intersect = null;
      if (this.clippingOn) {
        for (let i = 0; i < intersects.length; i++) {
          if (intersect) break; //exit loop as intersect found
          const relativeToCubePosition = this.clippingCube.worldToLocal(intersects[i].point.clone());


          if (
            this.clippingCube.geometry.boundingBox.distanceToPoint(
              relativeToCubePosition
            ) == 0 && intersects[i].object.visible
          ) {

            intersect = intersects[i]

          }

        }
      } else {
        intersect = firstVisible
      }


      if (intersect) {


        this.transformControls.object.position.copy(intersect.point)

        this.turnOffSSAA();
        this.askForRender();


      }
    }





    //subscriptions
    https://track.toggl.com/timer
    this.subscriptions.push(
      this.appActionsService.startRotating.subscribe((rotate) => {
        this.controls.autoRotateSpeed = 0.3;
        this.controls.autoRotate = true;
        this.controls.addEventListener("start", () => {
          //stop autoRotate after first interaction with controls

          this.appActionsService.stopRotating.next()
        });
      })
    );

    this.subscriptions.push(
      this.appActionsService.stopRotating.subscribe(() => {
        this.controls.autoRotate = false;
      })
    );
  }

  initPinnedNotes() {
    this.pinnedNotes = this.dataService.getPinnedNotes();

    this.pinnedNotes.forEach((note, i) => {
      let newObject = this.newNoteObject.clone();
      newObject.material = this.newNoteObject.material.clone();
      newObject.geometry = this.newNoteObject.geometry.clone();
      newObject.visible = true;
      newObject.position.set(note.position.x, note.position.y, note.position.z);
      newObject.scale.set(note.scale, note.scale, note.scale);
      newObject.name = "noteMarker_" + note.id;
      newObject.userData["type"] = "note";
      newObject.userData["noteId"] = note.id;
      newObject.userData["pinnedNotesIndex"] = i;

      switch (note.type) {
        case "ok":
          newObject.material.color.set("#4caf50");
          break;
        case "problem":
          newObject.material.color.set("#ff9800");
          break;
        case "none":
          break;
      }

      this.pinnedNotesGroup.add(newObject);
    });

    this.askForRender();
  }

  initPlacementPoints() {
    const mat1 = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const mat2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 })

    for (let i = 0; i < 4; i++) {

      const point = new THREE.Mesh(
        new THREE.SphereGeometry(0.005, 36, 36),
        i < 2 ? mat1 : mat2
      )
      this.imagePlacementPointsObject.add(point);
    }


  }

  cloneObject(object) {
    let geo = object.geometry.clone();
    let mat = object.material.clone();
    return new THREE.Mesh(geo, mat);
  }

  refreshPinnedNotes() {
    let i = 1;
    let pinnedToDelete = [];
    this.pinnedNotesGroup.children.forEach((o) => {
      pinnedToDelete.push(o);
    });
    pinnedToDelete.forEach((p) => {
      this.pinnedNotesGroup.remove(p);
      p.material.dispose();
      p.geometry.dispose();
    });

    this.initPinnedNotes();
    this.askForRender();
  }

  ngAfterViewInit() {
    let width = document.getElementById("app-content-container").clientWidth;
    let height = document.getElementById("app-content-container").clientHeight;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = .5;


    this.initSAO();

  }

  closestCollisionFromCamera(direction) {
    let cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);

    switch (direction) {
      case "forward":
        cameraDirection.multiplyScalar(1);
        break;
      case "back":
        cameraDirection.multiplyScalar(-1);
        break;

      case "right":
        cameraDirection.cross(new THREE.Vector3(0, 0, 1));
        break;

      case "left":
        cameraDirection.cross(new THREE.Vector3(0, 0, -1));
        break;
    }

    this.raycaster.set(this.camera.position, cameraDirection);
    let intersects = this.raycaster.intersectObjects(
      this.collidableObjects, false
    );

    const intersect = intersects.find((i) => {

      if (!i.object.visible) {
        return false;
      }

      if (this.clippingOn) {

        const relativeToCubePosition = this.clippingCube.worldToLocal(i.point.clone());
        return (
          this.clippingCube.geometry.boundingBox.distanceToPoint(
            relativeToCubePosition
          ) == 0 && i.object.visible)
      } else {
        return i.object.visible;
      }

    });


    if (intersect) {
      return intersect.distance;
    } else {
      return Infinity;
    }
  }

  getCollisionsDistancesForGameMode() {
    return {
      forward: this.closestCollisionFromCamera("forward"),
      back: this.closestCollisionFromCamera("back"),
      right: this.closestCollisionFromCamera("right"),
      left: this.closestCollisionFromCamera("left"),
    };

  }

  animate() {
    this.stats.begin();

    if (this.controls.autoRotate) {
      this.controls.update();
    }


    // Time dependant shader POC, this can cost alot of performance time in the composerpass, as we should render in the animation loop.
    // this.askForRender();
    // this.timeUniform.value = performance.now() / 1000;


    if (this.destroyed) {
      return;
    }



    this.camera.getWorldDirection(this.cameraDirection);
    if (this.plcOn) {
      //check if current object selected to unselect it
      this.clearINTERSECTED()
      //collisions:

      let distances = this.getCollisionsDistancesForGameMode();

      let closestCollision = Math.min(
        distances.forward,
        distances.back,
        distances.left,
        distances.right
      );


      this.closestCollisionFront = distances.forward.toFixed(2); //@mg

      let speed = 0.08;




      const bs = this.singleMesh.geometry.boundingSphere;
      const p = this.camera.position.clone();
      const c = bs.center.clone();
      this.singleMesh.localToWorld(c);

      const d = p.distanceTo(c);
      if (d < bs.radius) {
        speed = 0.04;

        if (!this.walkMode) {

          if (closestCollision != Infinity) {
            speed = 0.04 + closestCollision / 100;
          } else {
            speed = 0.04 + d / 100;
          }

        }



      } else {

        speed = 0.04 + d / 100;
      }


      speed = Math.min(1, speed);
      speed = speed * this.speedFactor;


      if (this.activePhotosphere) { //block movement if photospere is active
        speed = 0;
      }

      let forward = this.moveForward - this.moveBack;
      let right = this.moveRight - this.moveLeft;



      if (!this.appActionsService.editorMode) {
        if (forward > 0) {
          if (distances.forward > this.collisionStopDistance) {
            this.plcControls.moveForward(speed * forward);
          }
        } else {
          if (distances.back > this.collisionStopDistance) {
            this.plcControls.moveForward(speed * forward);

          }
        }

        if (right > 0) {
          if (distances.right > this.collisionStopDistance) {
            this.plcControls.moveRight(speed * right);

          }
        } else {
          if (distances.left > this.collisionStopDistance) {
            this.plcControls.moveRight(speed * right);

          }
        }
      } else {
        this.plcControls.moveForward(speed * forward);
        this.plcControls.moveRight(speed * right);

      }



      if (forward != 0 || right != 0) {
        this.turnOffSSAA();
        this.askForRender();
      }



    }


    if (this.joysticksOn) {
      let right = 0;
      let forward = 0;


      let distances = this.getCollisionsDistancesForGameMode();


      let p = 80;
      let k = 1000;
      //left joystick
      if (!this.activePhotosphere) {
        forward = -this.leftJoystick.deltaY();

        forward =
          0.1 *
          (Math.abs(forward) < 80
            ? forward / p
            : Math.sign(forward) *
            ((Math.abs(forward) - p) * ((Math.abs(forward) - p) / k + 1 / p) +
              1));

        right = this.leftJoystick.deltaX();

        right =
          0.1 *
          (Math.abs(right) < 80
            ? right / p
            : Math.sign(right) *
            ((Math.abs(right) - p) * ((Math.abs(right) - p) / k + 1 / p) + 1));

        if (!this.appActionsService.editorMode) {
          if (forward > 0) {
            if (distances.forward > this.collisionStopDistance) {
              this.plcControls.moveForward(forward);

            }
          } else {
            if (distances.back > this.collisionStopDistance) {
              this.plcControls.moveForward(forward);

            }
          }

          if (right > 0) {
            if (distances.right > this.collisionStopDistance) {
              this.plcControls.moveRight(right);

            }
          } else {
            if (distances.left > this.collisionStopDistance) {
              this.plcControls.moveRight(right);

            }
          }
        } else {

          this.plcControls.moveForward(forward);
          this.plcControls.moveRight(right);

        }

      }




      // //right joystick
      var euler = new THREE.Euler(0, 0, 0, "ZYX");
      euler.setFromQuaternion(this.camera.quaternion);


      //old behavior: (additive)
      // euler.x -= this.rightJoystick.deltaY() * 0.0005;
      // euler.z -= this.rightJoystick.deltaX() * 0.0005;



      //new behvaior (not additive)
      euler.x = this.lastCameraEuler.x - this.rightJoystick.deltaY() * Math.PI / 180;
      euler.z = this.lastCameraEuler.z - this.rightJoystick.deltaX() * Math.PI / 180;

      euler.x = Math.max(0, Math.min(2 * (Math.PI / 2), euler.x));

      this.camera.quaternion.setFromEuler(euler);

      if (this.rightJoystick.deltaY() != 0 || this.rightJoystick.deltaX() != 0 || right != 0 || forward || 0) {
        this.turnOffSSAA();
      }

      this.askForRender()



    }


    if (this.joysticksOn || this.plcOn) {
      //walk mode
      if (this.walkMode) {

        this.raycaster.set(this.camera.position, this.camera.up.clone().multiplyScalar(-1));
        let intersects = this.raycaster.intersectObjects(
          this.collidableObjects, false
        );


        const intersect = intersects.find((i) => {

          if (!i.object.visible) {
            return false;
          }

          if (this.clippingOn) {

            const relativeToCubePosition = this.clippingCube.worldToLocal(i.point.clone());
            return (
              this.clippingCube.geometry.boundingBox.distanceToPoint(
                relativeToCubePosition
              ) == 0 && i.object.visible)
          } else {
            return i.object.visible;
          }

        });





        if (intersect) {

          this.desiredZ = intersect.point.z + 1.63;
        } else {

          this.desiredZ = this.camera.position.z;
        }


        const deltaZ = this.desiredZ - this.camera.position.z;

        if (Math.abs(deltaZ) >= 0.02) {

          this.camera.position.z += deltaZ * 0.1;
          this.turnOffSSAA();
          this.askForRender();
        }
      }

    }

    if (!this.inFlight) {
      this.runIntersection();
    }

    if (this.previewModel) {
      this.updatePreviewModel();
    }
    //rendering:

    this.animationsManager.runFrame()


    if (
      !((this.mouseOutOfScene && !this.inFlight) || !this.loadingFinished) ||
      this.plcOn
    ) {
      try {
        if (this.renderRequired) {
          this.renderRequired = false;

          this.render();

          if (this.recordingTransition) {
            const frames = this.recordings[this.recordingTransition].length;
            const totalFrames = this.sequenceTime * 60;
            let quality = 0.4;

            if (frames < 2 || (frames >= totalFrames - 2)) { //make sure to render it with AA and better quality for frames in begning and end
              quality = 0.9;
              this.composer.passes[0].enabled = false;
              this.composer.passes[1].enabled = true;
              this.composer.render();
            }
            console.log('ssaa:' + this.composer.passes[1].enabled)
            const img = this.renderer.domElement.toDataURL("image/webp", quality)
            this.recordings[this.recordingTransition].push(img)
          }

        }
      } catch (e) {
        console.warn('ERROR IN RENDERING, FRAME NOT RENDERED')
        //error render
      }
    } else {
      if (this.frameCount % this.checkRenderRequireFrameCount == 0 || true) {

        if (this.renderRequired) {
          this.renderIfOutsideScene();
          this.renderRequired = false;
        }
      }
    }

    this.frameCount++;

    if (this.frameCount >= 60) {
      this.frameCount = 0;
    }


    //if (this.controls.autoRotate) {
    //  this.controls.update();
    //  this.renderIfOutsideScene();
    //}



    this.stats.end();

    window.requestAnimationFrame(() => this.animate());
  }



  @HostListener("window:keydown", ["$event"])
  onKeyDown(event) {

    if (this.transformControls.object) {
      this.handleTransformKeydown(event.keyCode);
      return;
    }


    if (this.mapboxOn) {
      return;
    }

    if (event.key == "q" && event.altKey && event.ctrlKey) {
      //need to find better shourtcut.
      if (!this.plcOn) {
        this.lockPlc()
      } else {
        this.plcControls.unlock();
      }
      return;
    }

    if (this.plcOn) {
      let key = event.key;

      switch (key) {
        case "w":
        case "W":
        case "z":
        case "Z":
        case "ArrowUp":
          this.moveForward = 1;
          break;

        case "s":
        case "S":
        case "ArrowDown":
          this.moveBack = 1;
          break;

        case "d":
        case "D":
        case "ArrowRight":
          this.moveRight = 1;
          break;

        case "a":
        case "A":
        case "q":
        case "Q":
        case "ArrowLeft":
          this.moveLeft = 1;
          break;

        case "Shift":
          this.speedFactor = 4;
          break;

        case "Tab":
          this.walkMode = !this.walkMode;
          event.preventDefault();
          break;
      }
    } else {   //@mg shortcut to activate plccontrols
      let key = event.key;
      switch (key) {
        case "w":
        case "W":
        case "z":
        case "Z":
        case "ArrowUp":
          if (!this.mouseOutOfScene && event.target == document.body) {
            this.appActionsService.showPointerLockOverlay = true;
            this.lockPlc();
            break;
          }


      }
    }

    if (
      this.selectedMode == "clippingPlanes" &&
      event.shiftKey &&
      this.clippingModeState == "rotatingCube"
    ) {
      let minDelta = 360;
      let closestAngle = 0;
      for (let i = 0; i < 8; i++) {
        let delta = 0;
        let magnetAngle = (i * 360) / 8;
        delta = Math.abs(this.clippingCube.zRotationAngle - magnetAngle);
        if (delta < minDelta) {
          minDelta = delta;
          closestAngle = magnetAngle;
        }
      }
      this.setClippingCubeRotation(closestAngle);
      this.updateRotateSphereCenterToIntersectLine(0, 0);
    }
  }
  @HostListener("window:keyup", ["$event"])
  onKeyUp(event) {
    if (this.previewModel) {
      switch (event.keyCode) {
        case 84: // T 
          this.orientToClosestWall(this.previewModel);
          this.previewModelRotation = this.previewModel.rotation.z;
          break;

        case 71: // G
          this.add90DegToRotation(this.previewModel);
          this.previewModelRotation = this.previewModel.rotation.z;
          break;

        case 107:
          this.previewModelScale *= 11 / 10;
          this.updatePreviewModelRoationAndScale()
          break

        case 109:
          this.previewModelScale *= 10 / 11;
          this.updatePreviewModelRoationAndScale()
          break

        case 96:
          this.resetPreviewModelRotationAndScale()
          break;
      }
    }

    if (this.dataService.collectionDimensioning.editModeStatus) {
      this.dataService.collectionDimensioning.keyUp(event)
      return;
    }

    if (this.transformControls.object) {
      this.handleTransformKeyup(event.keyCode)
      return;
    }

    if (this.selectedMode == "clippingPlanes") {
      if (event.key == "Control") {
        this.turnRotateSphereOff();
      }
    }

    if (this.mapboxOn) {
      return;
    }

    let key = event.key;
    switch (key) {
      case "w":
      case "W":
      case "z":
      case "Z":
      case "ArrowUp":
        this.moveForward = 0;
        break;

      case "s":
      case "S":
      case "ArrowDown":
        this.moveBack = 0;
        break;
      case "d":
      case "D":
      case "ArrowRight":
        this.moveRight = 0;
        break;
      case "a":
      case "A":
      case "q":
      case "Q":
      case "ArrowLeft":
        this.moveLeft = 0;
        break;

      case "Shift":
        this.speedFactor = 1;
        break;

      case "F":
      case "f":
        if (!this.mouseOutOfScene && event.target == document.body) {

          if (this.selectedObject) {
            this.flyTo(this.selectedObject, null, null, null, true)
          } else if (this.INTERSECTED) {
            this.flyTo(this.INTERSECTED)
          }


        }
        break;

      case "Backspace":
        if (this.appActionsService.imagePlacement.id) {
          this.undoLastImagePlacmentPoint()
        }
        break;

      case "Escape":
        if (this.appActionsService.imagePlacement.id) {
          this.exitImagePlacement()
        }
        break;

    }
   
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    if (!this.loadingFinished || this.recordingMode) {
      return;
    }
    let width = this.rendererContainer.nativeElement.clientWidth;
    let height = this.rendererContainer.nativeElement.clientHeight;

    if (this.appActionsService.lockAspectRatio) {

      if (width / height > 16 / 9) {
        width = height * 16 / 9;
      } else {
        height = width * 9 / 16
      }


    }

    const mapboxContainer = document.getElementById('mapboxContainer');
    mapboxContainer.style.width = width + 'px';
    mapboxContainer.style.height = height + 'px';
    this.camera.aspect = width / height;

    this.camera.updateProjectionMatrix();

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);
    console.log('resizing', width, height)
    try {
      this.render();
    } catch (e) {
      //error render
    }
    this.askForRender();
  }


  @HostListener("wheel", ["$event"])
  onMouseWheel(event?) {


    this.turnOffSSAA();
  }

  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event?) {

    this.mouseOutOfScene = true;

    this.clearINTERSECTED();

    this.askForRender();
  }

  @HostListener("mouseenter", ["$event"])
  onMouseEnter(event?) {
    this.mouseOutOfScene = false;
  }

  onMouseMove(event) {

    this.onMouseMoveClippingPlaneHandler(event);

    //this.mouseOutOfScene = false; //i commented it , i think we dont need it,maybe previous workaraound? i let it here in case we try to debug something and might need to added it again..

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    this.mouse.x =
      (event.layerX / (this.renderer.domElement.clientWidth / 1)) * 2 - 1;
    this.mouse.y =
      -(event.layerY / (this.renderer.domElement.clientHeight / 1)) * 2 + 1;
    this.mouse.layerX = event.layerX;
    this.mouse.layerY = event.layerY;

    this.zoneHoverTimer = performance.now();


    if (this.dataService.collectionDimensioning) {
      if (this.dataService.collectionDimensioning.editModeStatus) {
        this.dataService.collectionDimensioning.pointerMove(event, this.camera)
      }
    }




  }

  @HostListener("mousedown", ["$event"])
  onMouseDownClippingPlaneHandler(event) {
    if (this.dataService.collectionDimensioning.editModeStatus) {
      this.dataService.collectionDimensioning.pointerDown(event, this.camera, this.controls)
      return;
    }

    this.mousePressedButtons[event.button] = true;

    if (
      this.selectedMode != "clippingPlanes" ||
      !(event.button == 0 || event.button == 2)
    ) {
      return;
    }

    if (this.mousePressedButtons[2] && event.ctrlKey) {
      this.turnRotateSphereOn();
    }

    if (this.intersectedPlane.normal) {
      this.controls.enabled = false;

      let intersect = this.raycaster.intersectObject(this.clippingCube);
      if (intersect.length > 0) {
        this.planeDragStartingPoint = intersect[0].point;

        const normal = this.intersectedPlane.normal;


        if (normal.z > 0.99999 || normal.z < -0.99999) {

          //plane is Zmin / Zmax

          let guidingPlaneNormal = normal
            .clone()
            .cross(new THREE.Vector3(0, 1, 0));
          guidingPlaneNormal.normalize();

          let d = guidingPlaneNormal.dot(this.planeDragStartingPoint);

          this.guidingPlane = new THREE.Plane(guidingPlaneNormal, -d);

          //2nd
          let guidingPlaneNormal2 = normal
            .clone()
            .cross(new THREE.Vector3(1, 0, 0));
          guidingPlaneNormal2.normalize();

          let d2 = guidingPlaneNormal2.dot(this.planeDragStartingPoint);

          this.guidingPlane2 = new THREE.Plane(guidingPlaneNormal2, -d2);
        } else {
          let guidingPlaneNormal = normal
            .clone()
            .cross(new THREE.Vector3(0, 0, 1));
          guidingPlaneNormal.normalize();

          let d = guidingPlaneNormal.dot(this.planeDragStartingPoint);

          this.guidingPlane = new THREE.Plane(guidingPlaneNormal, -d);

          //2nd
          let guidingPlaneNormal2 = normal
            .clone()
            .cross(guidingPlaneNormal.clone());
          guidingPlaneNormal2.normalize();

          let d2 = guidingPlaneNormal2.dot(this.planeDragStartingPoint);

          this.guidingPlane2 = new THREE.Plane(guidingPlaneNormal2, -d2);
        }
      }
    }
  }

  @HostListener("mouseup", ["$event"])
  onMouseUpandler(event) {
    if (this.dataService.collectionDimensioning.editModeStatus) {
      this.dataService.collectionDimensioning.pointerUp(this.controls)
      return;
    }


    //clipping planes:
    this.clippingModeState = null;
    this.mousePressedButtons[event.button] = false;
    this.debounceSSAAOn();

    if (this.selectedMode == "clippingPlanes") {
      this.turnRotateSphereOff();
      this.guidingPlane = null;

      this.controls.enabled = true;
    }




  }

  @HostListener("blurred", ["event"])
  OnBlurred(event) {
  }

  @HostListener("dblclick", ["$event"])
  onDoubleClick(event) {
    if (this.previewModel) {
      return;
    }


    if (!this.appActionsService.editorMode) {
      return;
    }

    if (this.dataService.collectionDimensioning.editModeStatus) {
      return;
    }




    if (this.INTERSECTED_MATERIAL && this.appActionsService.selectedDataMode == 'materials-editor') {
      this.appActionsService.objectDataAction.next({ action: 'materialClicked', materialName: this.INTERSECTED_MATERIAL.name })
    }


    if (this.INTERSECTED) {
      if (this.appActionsService.selectedDataMode === 'models' || this.appActionsService.selectedDataMode === 'materials-editor') {
        if (this.INTERSECTED.userData.modelLibrary) {
          this.appActionsService.selectedModelId.next(this.INTERSECTED.userData.modelId)
          this.appActionsService.chosenObjectOid.next();

          return
        }



      }


      //objects of ifc:

      if (this.INTERSECTED == this.selectedObject) {
        //unchosing if alreayd selected

        this.appActionsService.chosenObjectOid.next(null);
        return;
      }

      this.onUnHoverObject(this.INTERSECTED);

      this.appActionsService.selectedModelId.next(null)
      this.appActionsService.chosenObjectOid.next(
        this.dataService.getObjectIdOfGuid(this.INTERSECTED.name)
      );
    } else {
      this.appActionsService.chosenObjectOid.next(null);
    }
  }

  @HostListener("click", ["$event"])
  onClick(event) {



    if (this.appActionsService.selectedDataMode == 'materials-editor' && this.INTERSECTED_MATERIAL && this.appActionsService.paintMaterialMode) {


      const materialOpenInListIndex = this.dataService.materials.findIndex(mi => (mi == this.appActionsService.materialOpenInList))
      if (materialOpenInListIndex != -1) {
        const guid = this.INTERSECTED.userData.guid;
        this.dataService.setNewMaterialToObject(materialOpenInListIndex, guid, this.INTERSECTED_MATERIAL.uuid, this.dataService.getObjectIdOfGuid(this.INTERSECTED.name))
      }

      return;

    }


    if (this.dataService.collectionDimensioning.editModeStatus) {
      return;
    }


    if (event.ctrlKey && this.previewModel) {

      let euler = { _x: 0, _y: 0, _z: this.previewModelRotation, _order: "XYZ" }
      let scale = this.previewModelScale;

      if (this.dataService.modelsLibrary[this.modelIdToAdd].category == "plants_outdoor") {



        this.previewModelRotation = Math.random() * Math.PI * 2;
        this.updatePreviewModelRoationAndScale();

      }

      this.dataService.addModel(
        {
          lib_id: this.modelIdToAdd,
          position: { x: this.previewModel.position.x, y: this.previewModel.position.y, z: this.previewModel.position.z },
          scale: { x: scale, y: scale, z: scale },
          euler: euler
        }
      )




    }

    if (this.appActionsService.imagePlacement.id && event.ctrlKey) {
      this.imagePlacementOnClick();
    }



    if (this.selectedMode == "measureMode") {
      this.measureModeOnClick();
    }

    //to notify there is a click : example to stop auto diaporama in guest mode
    this.appActionsService.isThreejsClicked.next()

    if (!this.appActionsService.editorMode) {
      return;
    }

    if (event.altKey && event.ctrlKey) {
      console.log(this.INTERSECTED);
      console.log(this.getBoundingBoxOfGroup(this.INTERSECTED))
    }

    this.printMe(performance.now() + ": click");

    this.mouseOutOfScene = false;

    if (this.selectedMode) {
      if (this.selectedMode == "addNote") {
        this.addNoteOnClick();
      }
    }

    //emit event out on default mode
    if (this.selectedMode == null && !this.plcOn) {
      if (this.projectColladaObject) {
        if (this.INTERSECTED) {

          this.appActionsService.threejsClick.next({
            positionWorldSpace: {
              x: this.INTERSECTED_POINT.x,
              y: this.INTERSECTED_POINT.y,
              z: this.INTERSECTED_POINT.z,
            },

            guid: this.INTERSECTED.name,
          });
        } else {
          const planeIntersect = new THREE.Vector3();
          this.raycaster.setFromCamera(this.mouse, this.camera);
          if (this.raycaster.ray.intersectPlane(this.xy_plane, planeIntersect)) {
            this.appActionsService.threejsClick.next({
              positionWorldSpace: {
                x: planeIntersect.x,
                y: planeIntersect.y,
                z: 0,
              },

              guid: null,
            });
          }
        }
      }
    }
  }

  clearINTERSECTED() {
    if (this.INTERSECTED) {
      if (this.INTERSECTED != this.selectedObject) {
        this.onUnHoverObject(this.INTERSECTED);
      }
    }

    this.INTERSECTED = null;
  }

  onHoverObject(object) {
    if (this.previewModel) {
      return;
    }

    if (this.appActionsService.selectedDataMode === 'models' || this.appActionsService.selectedDataMode === 'materials-editor') {
      if (object.userData.modelLibrary) {
        this.onHoverModel(object);
        this.turnOffSSAA()
        this.askForRender();
        return;
      }

    }


    if (object.isZone) {
      if (object.visible) {
        this.onZoneModeHover(object);
      }

      return;
    }

    this.updateHoverOnSingleMesh(object.name)

    object["lastUsedMaterial"] = object.material;

    // this.setToHoveredEdges(object);
    this.turnOffSSAA()
    this.askForRender();
  }

  onUnHoverObject(object) {
    if (this.previewModel) {
      return;
    }

    if (this.appActionsService.selectedDataMode === 'models' || this.appActionsService.selectedDataMode === 'materials-editor') {
      if (object.userData.modelLibrary) {

        this.onUnHoverModel();
        this.turnOffSSAA()
        this.askForRender();
        return;
      }

    }
    if (object.isZone) {
      this.onZoneModeUnHover(object);
      return;
    }

    this.updateHoverOnSingleMesh()

    if (object["lastUsedMaterial"]) {
      object.material = object["lastUsedMaterial"];
      object.lastUsedMaterial = null;
    }

    // this.removeHoveredEdges();
    this.turnOffSSAA()
    this.askForRender();
  }


  updateHoverOnSingleMesh(guid = null) {
    this.hoverUniform.value = guid ? this.GuidToEid[guid] : 0.0

  }



  defaultIntersecter() {

    let intersectWith = [this.projectColladaObject];
    if (this.appActionsService.selectedDataMode === 'models' || this.appActionsService.selectedDataMode === 'materials-editor') {
      let models: any[] = Object.values(this.models)
      if (this.previewModel) {

        if (this.previewModel.userData.category == "plants_outdoor") { //for trees intersect only with floor
          models = [];

        }
      }

      intersectWith = intersectWith.concat(models);
    }

    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(
      intersectWith,
      true
    );



    let firstVisible = null;


    for (let intersect of intersects) {
      if (intersect.object.visible) {
        firstVisible = intersect;
        break;
      }
    }

    let intersect = null;
    if (this.clippingOn) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersect) break; //exit loop as intersect found

        const relativeToCubePosition = this.clippingCube.worldToLocal(intersects[i].point.clone());


        if (
          this.clippingCube.geometry.boundingBox.distanceToPoint(
            relativeToCubePosition
          ) == 0 && intersects[i].object.visible
        ) {
          intersect = intersects[i];

        }


      }
    } else {
      intersect = firstVisible
    }


    if (
      intersect &&
      this.nonInteractiveObjects.indexOf(intersect.object) == -1
    ) {

      this.INTERSECTED_MATERIAL = intersect.object.material[intersect.face.materialIndex]
      this.INTERSECTED_POINT = intersect.point; //every time mouse moves!
      this.INTERSECTED_NORMAL = intersect.normal;
      intersect.object.localToWorld(this.INTERSECTED_NORMAL)


      if (intersect.object.userData.modelLibrary) {
        const modelId = intersect.object.userData.modelId;
        if (modelId) {
          intersect.object = this.models[modelId]
        } else {
          console.warn('cant find intersect root of model library model')
        }

      }



      if (this.INTERSECTED != intersect.object) {
        //only when object changes

        if (this.INTERSECTED) {

          this.onUnHoverObject(this.INTERSECTED);

        }

        this.INTERSECTED = intersect.object;


        this.onHoverObject(this.INTERSECTED);

      }
    } else {
      if (this.INTERSECTED) {

        this.onUnHoverObject(this.INTERSECTED);

      }
      this.INTERSECTED = null;
      this.INTERSECTED_POINT = null;
      this.INTERSECTED_MATERIAL = null
      this.INTERSECTED_NORMAL = null;
    }
  }

  onZoneModeHover(zone) {
    this.hoveredZoneGuid = zone.name;

    zone.material.opacity = 0.5;
    zone.hover = true;

    this.updateHoverOnSingleMesh(zone.name)
    this.turnOffSSAA();
    this.askForRender();
  }

  onZoneModeUnHover(zone) {


    zone.material.opacity = 0.15;
    zone.hover = false;
    this.hoveredZoneGuid = null;
    this.updateHoverOnSingleMesh()
    this.turnOffSSAA();
    this.askForRender();
  }

  zonesIntersecter() {

    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(this.zones, true);

    let firstVisible = null;


    for (let intersect of intersects) {
      if (intersect.object.visible) {
        firstVisible = intersect;
        break;
      }
    }

    let intersect = null;
    if (this.clippingOn) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersect) break; //exit loop as intersect found


        if (
          this.clippingPlanes.every(
            function (elem2) {
              return elem2.distanceToPoint(intersects[i].point) > 0;
            }
          ) && intersects[i].object.visible
        ) {
          intersect = intersects[i];

        }

      }
    } else {
      intersect = firstVisible
    }


    if (
      intersect &&
      this.nonInteractiveObjects.indexOf(intersect.object) == -1
    ) {

      if (this.INTERSECTED != intersect.object) {
        if (this.INTERSECTED) {
          if (this.INTERSECTED != this.selectedObject) {
            this.onZoneModeUnHover(this.INTERSECTED);
          }
        }

        this.INTERSECTED = intersect.object;
        this.zoneHoverTimer = performance.now();

        if (this.INTERSECTED != this.selectedObject) {
          //hover if the object is not currently selected..
          this.onZoneModeHover(this.INTERSECTED);
        }
      }
    } else {
      if (this.INTERSECTED) {
        if (this.INTERSECTED != this.selectedObject) {
          this.onZoneModeUnHover(this.INTERSECTED);
        }
      }
      this.INTERSECTED = null;
      this.hoveredZoneGuid = null;
    }
  }

  addNoteIntersecter() {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersects = this.raycaster.intersectObjects(
      [this.projectColladaObject],
      true
    );

    let firstVisible = null;

    for (let intersect of intersects) {
      if (intersect.object.visible) {
        firstVisible = intersect;
        break;
      }
    }

    const intersect = firstVisible
    if (intersect) {
      let position = intersect.point;

      this.newNoteObject.position.copy(position);
    }
  }

  addNoteOnClick() {
    let newNotePosition = this.projectColladaObject.worldToLocal(
      this.newNoteObject.position.clone()
    );

    let scale = this.newNoteObject.scale.x;

    this.exitPinnedNoteMode();
    const newName = this.translate.instant('note.newName');
    const newDescription = this.translate.instant('note.newDescription');
    const newType = this.translate.instant('note.newType');

    //this.dataService.createNote("New pinned note", "type your description here", "none", {
    this.dataService.createNote(newName, newDescription, newType, {
      position: {
        x: newNotePosition.x,
        y: newNotePosition.y,
        z: newNotePosition.z,
      },
      scale: scale,
    })
      .then(
        (resolved) => {
          this.appActionsService.openNote.next(resolved);
        },
        (rejected) => { }
      )
      .catch((err) => {
        console.warn(err);
      });
  }

  measureIntersecter() {
    this.scaleObject(this.measurePointA);
    this.scaleObject(this.measurePointB);

    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersects = this.raycaster.intersectObjects(
      [this.projectColladaObject].concat(this.measureArray),
      true
    );


    let firstVisible = null;

    for (let intersect of intersects) {
      if (intersect.object.visible) {
        firstVisible = intersect;
        break;
      }
    }



    let intersect = null;
    if (this.clippingOn) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersect) break; //exit loop as intersect found

        const relativeToCubePosition = this.clippingCube.worldToLocal(intersects[i].point.clone());


        if (
          this.clippingCube.geometry.boundingBox.distanceToPoint(
            relativeToCubePosition
          ) == 0 && intersects[i].object.visible
        ) {
          intersect = intersects[i];

        }
      }
    } else {
      intersect = firstVisible
    }


    if (intersect) {
      if (this.measureArray.indexOf(intersect.object) != -1) {
        //magent point intersected
        this.measureIntersected = intersect.object;
        this.intersectPoint = intersect.object.position.clone();

      } else {
        this.measureIntersected = null;

        this.intersectPoint = intersect.point;

        if (this.measureObject != intersect.object) {
          //not need to do all this if its the same object..
          this.measureObject = intersect.object;

          if (this.magnetOn) {
            this.createMagnetPoints();
          }
        }
      }



    } else {
      this.clearMagnetPoints();
      this.measureIntersected = null;
      this.measureObject = null;
      this.intersectPoint = null;
    }

    //meter drawer:
    switch (this.measurePoints.length) {
      case 0:
        break;
      case 1:
        if (this.intersectPoint) {
          this.drawMeter(this.measurePointA.position, this.intersectPoint);

        }
        break;
      case 2:

        if (!this.appActionsService.editorMode || !this.appActionsService.measureModeCreateMode) {
          break;
        }
        this.measureOffsetPlane.setFromNormalAndCoplanarPoint(this.measurePointA.position.clone().sub(this.measurePointB.position).normalize(), this.measurePointB.position)
        const offset = new THREE.Vector3();
        this.raycaster.ray.intersectPlane(this.measureOffsetPlane, offset);
        offset.sub(this.measurePointB.position);
        this.measureOffset.copy(offset);
        this.drawMeter(this.measurePointA.position, this.measurePointB.position, offset);
        break;
    }


    this.askForRender();
  }

  measureModeOnClick() {
    switch (this.measurePoints.length) {
      case 0:
        if (this.intersectPoint) {
          this.measurePointA.position.copy(this.intersectPoint);


          this.measurePointA.visible = true;
          this.measurePoints.push(this.measurePointA);
        }


        break;
      case 1:

        if (!this.intersectPoint) {
          this.initMeter();
          break;
        }
        this.measurePointB.position.copy(this.intersectPoint);


        this.measurePointB.visible = true;
        this.measurePoints.push(this.measurePointB);
        this.drawMeter(
          this.measurePointA.position,
          this.measurePointB.position
        );

        // if (!this.appActionsService.editorMode || !this.appActionsService.measureModeCreateMode) {
        //   this.initMeter()

        // }



        break;

      case 2:

        if (!this.appActionsService.editorMode || !this.appActionsService.measureModeCreateMode) {
          this.initMeter()
          return;
        }


        const distance = this.measurePointA.position.distanceTo(this.measurePointB.position)
        const distanceToCamera = this.measurePointA.position.clone().add(this.measurePointB.position).multiplyScalar(0.5).distanceTo(this.camera.position)

        this.appActionsService.currentMeasure.next(
          {
            offset: this.measureOffset,
            extensionLineLength: this.measureOffset.length(),
            directionNormal: this.measureOffset.clone().normalize(),
            distanceToCamera,
            distance,
            pointA: this.measurePointA.position,
            pointB: this.measurePointB.position
          }
        )
        this.initMeter();
    }
  }

  initMeter() {
    this.measurePointB.visible = false;
    this.measurePointA.visible = false;
    this.measureOffsetPlaneHelper.visible = false;
    this.measurePoints = [];

    this.meter.visible = false;
    this.activeMeasure.distance = 0;
    this.askForRender();
  }

  drawMeter(point1, point2, offset?) {

    this.meter.visible = true;



    let l1 = point1.clone();
    let l2 = point2.clone();


    if (offset) {
      this.measureOffsetPlaneHelper.position.copy(l2)
      this.measureOffsetPlaneHelper.lookAt(l1.clone())
      const scale = Math.max(l1.distanceTo(l2) / 5, 0.5);
      this.measureOffsetPlaneHelper.scale.set(scale, scale, scale)
      l1.add(offset)
      l2.add(offset)
      this.measureOffsetPlaneHelper.visible = true;
      this.meter.geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute([l1.x - offset.x, l1.y - offset.y, l1.z - offset.z, l1.x, l1.y, l1.z, l2.x, l2.y, l2.z, l2.x - offset.x, l2.y - offset.y, l2.z - offset.z], 3)
      );
    } else {
      this.measureOffsetPlaneHelper.visible = false;
      this.meter.geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute([l1.x, l1.y, l1.z, l2.x, l2.y, l2.z], 3)
      );
    }


    this.meter.geometry.verticesNeedUpdate = true;
    this.meter.geometry.computeBoundingSphere();
    this.halfMeter = new THREE.Vector3((l1.x + l2.x) / 2, (l1.y + l2.y) / 2, (l1.z + l2.z) / 2)


    this.activeMeasure.distance = l1.distanceTo(l2).toFixed(2);
    this.updateMeasureLabel();

  }

  clearMagnetPoints() {
    this.measureArray.forEach((o) => {
      this.scene.remove(o);
      o.material.dispose();
      o.geometry.dispose();
      o = undefined;
    });
    this.measureArray = [];


    this.askForRender();
  }
  createMagnetPoints() {
    this.clearMagnetPoints();

    let array = this.measureObject.geometry.attributes.position.array;
    if (array.length > 800) {

      //jsut for now, change this!!!!
      return;
    }
    let vertices = [];
    for (let i = 0; i < array.length; i = i + 3) {
      vertices.push(new THREE.Vector3(array[i], array[i + 1], array[i + 2]));
    }

    //remove duplicates;
    let finalVertices = [];

    for (let i = 0; i < vertices.length; i++) {
      let v = vertices[i];
      const relativeToCubePosition = this.clippingCube.worldToLocal(this.measureObject.localToWorld(v.clone()));
      if (
        !(
          this.clippingCube.geometry.boundingBox.distanceToPoint(relativeToCubePosition) != 0 && this.clippingOn
        )
      ) {
        //if its not inside clipping box
        let existalready = false;
        finalVertices.forEach((fv) => {
          if (fv.distanceTo(v) == 0) {
            existalready = true;
          }
        });

        if (!existalready) {
          finalVertices.push(v);
        }
      }
    }

    finalVertices.forEach((v, i) => {
      let position = this.measureObject.localToWorld(v);

      let sphere = new THREE.Mesh(
        this.measurePointGeometry.clone(),
        this.measurePointMaterial.clone()
      );

      sphere.visible = true;
      this.measureArray.push(sphere);
      sphere.position.copy(position);
      this.scaleObject(sphere);
      this.scene.add(sphere);
      this.measureArray.push(sphere);
    });
  }

  scaleObject(object) {
    let s = object.position.distanceTo(this.camera.position) * this.camera.fov / 60; //@mg to avoid big balls
    object.scale.set(s, s, s);
  }

  runIntersection() {

    if (this.dataService.collectionDimensioning.editModeStatus) {
      return;
    }

    if (!this.appActionsService.mouseDetected) {
      return;
    }

    if (this.mouseOutOfScene) {
      return;
    }

    if (!this.appActionsService.isMobile) {
      if (this.selectedMode == null && !this.plcOn && this.appActionsService.editorMode) { //@mg to let label creation
        //need to check if mouse down, to avoid compute unnecessary intersect computation
        if (
          this.mousePressedButtons[0]
          || this.mousePressedButtons[1]
          || this.mousePressedButtons[2]
        ) {
          return
        }
        if (!this.appActionsService.imagePlacement.id) {
          this.defaultIntersecter();
        } else {
          this.imagePlacementIntersecter()
        }

        this.updateZoneLabel();
      } else {
        //need to check if mouse down, to avoid compute unnecessary intersect computation
        if (
          this.plcOn
          || (
            (
              this.mousePressedButtons[0]
              || this.mousePressedButtons[1]
              || this.mousePressedButtons[2]
            )
            && this.selectedMode != "clippingPlanes"
          )
        ) {
          return
        }

        if (this.selectedMode == "addNote") {
          this.addNoteIntersecter();
        }

        if (this.selectedMode == "transparentMode") {
          this.defaultIntersecter();
        }

        if (this.selectedMode == "measureMode") {
          if (!this.dataService.collectionDimensioning.editModeStatus) {
            this.measureIntersecter();
          }

        }


        if (this.selectedMode == "zonesMode") {
          this.zonesIntersecter();
          this.updateZoneLabel();
        }

        if (this.selectedMode == "clippingPlanes") {
          this.clippingPlanesIntersecter();
        }

        if (!this.selectedMode) {
          this.zonesIntersecter();
          this.updateZoneLabel();
        }
      }


    }
  }

  scaleImagePlacmenetPoints() {
    this.imagePlacementPointsObject.children.forEach(point => {
      const s = point.position.distanceTo(this.camera.position);
      point.scale.set(s, s, s)
    })
  }

  render() {
    if (this.renderDebuggerOn) {
      debugger;
    }

    if (this.appActionsService.imagePlacement.id) {
      this.scaleImagePlacmenetPoints();
    }


    this.updateEnvmap();
    if (this.clippingStencilsOn) {

      const clippingBoxCenter = new THREE.Vector3();

      this.clippingCube.geometry.boundingBox.getCenter(clippingBoxCenter)
      this.clippingCube.localToWorld(clippingBoxCenter)


      if (this.stencils.planeObjects) {
        for (let i = 0; i < this.stencils.planeObjects.length; i++) {
          const plane = this.stencils.planes[i];
          const po = this.stencils.planeObjects[i];



          //offset in xy the position of the plane (for XY planes)
          const o = new THREE.Vector3()
          plane.coplanarPoint(o);
          const r = clippingBoxCenter.clone();
          const dir = plane.normal.clone().cross(new THREE.Vector3(0, 0, 1));
          let offset = dir.multiplyScalar(-o.clone().sub(r).dot(dir))


          if (i < 2) { //in the case this is Z related planes, we should just offset to the center simply.
            offset.z = 0;
            offset.x = r.x;
            offset.y = r.y
          }



          plane.coplanarPoint(po.position);
          po.position.copy(o.add(offset))



          po.lookAt(
            po.position.x - plane.normal.x,
            po.position.y - plane.normal.y,
            po.position.z - plane.normal.z,
          );



        }
      }




    }

    const camera = this.mapboxOn ? this.mapboxCam : this.camera;
    this.renderer.info.reset()

    //sao check
    if (this.SAOOn && !this.mapboxOn && this.selectedMode !== "measureMode") {

      if (this.composer) {

        this.outlinePass.enabled = this.appActionsService.editorMode

        this.composer.render();

      } else {
        this.renderer.render(this.scene, camera);
      }
    } else {
      this.renderer.render(this.scene, camera);
    }

    this.updateDomRelatedElements();

    if (!this.firstRenderedImageTaken && this.directionalLightBimshow) {  //@mg
      const imgData = this.renderer.domElement.toDataURL();
      const ratio =
        this.renderer.domElement.clientWidth /
        this.renderer.domElement.clientHeight;
      this.resizedataURL(imgData, 150 * ratio, 150).then((thumb) => {
        this.dataService.onFirstImageTaken(thumb);
      });

      this.firstRenderedImageTaken = true;
    }

  }

  updateDomRelatedElements() {
    if (!this.mapboxOn) {
      this.compassAngle = (this.controls.getAzimuthalAngle() * 180) / Math.PI;
    }


    this.updateLabels();
    this.updatePhotospheresLabels();

    this.updateContainedZone();
    this.updateMeasureLabel();

    this.cdr.detectChanges();
  }

  updateMeasureLabel() {
    const { x, y } = this.worldToScreenPosition(this.halfMeter)
    this.activeMeasure.x = x;
    this.activeMeasure.y = y;
  }

  updateContainedZone() {
    let t = new Tictac("t");
    if (!this.plcOn) {
      return;
    }

    for (let i = 0; i < this.zones.length; i++) {

      let x1 = [];
      let x2 = [];
      let y1 = [];
      let y2 = [];
      let z1 = [];
      let z2 = [];

      this.raycaster.set(this.camera.position, new THREE.Vector3(1, 0, 0));
      this.zones[i].raycast(this.raycaster, x1);

      this.raycaster.set(this.camera.position, new THREE.Vector3(-1, 0, 0));
      this.zones[i].raycast(this.raycaster, x2);

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 1, 0));
      this.zones[i].raycast(this.raycaster, y1);

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, -1, 0));
      this.zones[i].raycast(this.raycaster, y2);

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 0, 1));
      this.zones[i].raycast(this.raycaster, z1);

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 0, -1));
      this.zones[i].raycast(this.raycaster, z2);

      if (
        x1.length > 0 &&
        x2.length > 0 &&
        y1.length > 0 &&
        y2.length > 0 &&
        z1.length > 0 &&
        z2.length > 0
      ) {
        if (this.zones[i].name != this.currentContainedZone) {
          this.currentContainedZone = this.zones[i].name;

        }
        return;
      }
    }

    if (null != this.currentContainedZone) {
      this.currentContainedZone = null;
    }

    // t.logPassedTime();
  }

  renderIfOutsideScene() {
    if (this.mouseOutOfScene) {
      this.render()

      this.updateDomRelatedElements();

    }
  }

  askForRender() {
    this.renderRequired = true;
  }

  chooseOnScene(oid) {

    let guid = this.dataService.getGuidOfObjectId(oid);


    //single mesh
    this.selectedUniform.value = guid ? this.GuidToEid[guid] : 0.0;

    //end single mesh


    this.selectedObject = this.projectColladaObject.getObjectByName(guid);

    if (this.selectedObject == null) {
      console.warn("object not found, cant select");
      return;
    }

    this.setToSelectedMaterialAndEdges(this.selectedObject);



    this.askForRender();
  }

  setToSelectedMaterialAndEdges(object) {
    this.removeSelectedMaterialEdges();
    object.lastUsedMaterial = object.material;
    object.material = this.selectedMaterial;

    var geometry = new THREE.EdgesGeometry(object.geometry); // or WireframeGeometry
    this.selectedEdges = new THREE.LineSegments(
      geometry,
      this.selectedEdgesMaterial
    );

    this.selectedEdges.scale.copy(this.getWorldScale(object));
    object.getWorldQuaternion(this.selectedEdges.quaternion);

    object.getWorldPosition(this.selectedEdges.position);
    this.scene.add(this.selectedEdges); // add wireframe as a child of the parent mesh

    if (object.isZone) {
      object.lastVisibleState = object.visible;
    }
    object.visible = true;
  }

  removeSelectedMaterialEdges() {
    if (this.selectedEdges) {
      this.scene.remove(this.selectedEdges);
      this.selectedEdges.geometry.dispose();
      this.selectedEdges.material.dispose();
      this.selectedEdges = null;
    }
  }

  setToHoveredEdges(object) {
    this.removeHoveredEdges();

    if (object.type == "Mesh") {

      var geometry = new THREE.EdgesGeometry(object.geometry); // or WireframeGeometry
      this.hoveredEdgesMaterial.clippingPlanes = this.clippingOn ? this.clippingPlanes : null;
      this.hoveredEdges = new THREE.LineSegments(
        geometry,
        this.hoveredEdgesMaterial
      );

      this.hoveredEdges.scale.copy(this.getWorldScale(object));

      object.getWorldQuaternion(this.hoveredEdges.quaternion);

      object.getWorldPosition(this.hoveredEdges.position);
      this.scene.add(this.hoveredEdges); // add wireframe as a child of the parent mesh
    }
  }

  getWorldScale(object) {
    if (object.parent) {
      const parentScale = this.getWorldScale(object.parent);
      return new THREE.Vector3(
        object.scale.x * parentScale.x,
        object.scale.y * parentScale.y,
        object.scale.z * parentScale.z
      );
    } else {
      return object.scale.clone();
    }
  }

  removeHoveredEdges() {
    if (this.hoveredEdges) {
      this.scene.remove(this.hoveredEdges);
      this.hoveredEdges.geometry.dispose();
      this.hoveredEdges.material.dispose();
      this.hoveredEdges = null;
    }
  }

  unchooseOnScene() {

    if (this.selectedObject == null) {
      return;
    }


    if (this.selectedObject.isZone && this.selectedMode == "zonesMode") {
      this.selectedObject.visible = this.selectedObject.lastVisibleState;
    }



    //single mesh
    this.selectedUniform.value = 0.0;
    //end single mesh

    this.lastSelectedObjectOid = this.selectedObject.name; 
    this.INTERSECTED = null;
    this.selectedObject = null;


    this.removeSelectedMaterialEdges();

    this.updateVisibilityOfObjectFromFilterOut(this.lastSelectedObjectOid);
    this.updateVisibilityInSingleMesh();

    this.askForRender();
  }

  enterPinnedNoteMode() {


    this.newNoteObject.visible = true;
    let d = this.pinnedNoteScale;
    this.newNoteObject.scale.set(d, d, d);

    this.askForRender();
  }

  exitPinnedNoteMode() {
    if (this.selectedMode) {
      if (this.selectedMode == "addNote") {
        this.selectedMode = null;

        this.newNoteObject.visible = false;
        this.appActionsService.toolboxEvents.next(
          new ToolboxEvent(null, null, this.constructor.name)
        );
      }
    }

    this.askForRender();
  }

  labelToScreenPosition(key, camera) {
    //calc 2d coordinate of object
    camera = this.mapboxOn ? this.mapboxCam : camera;
    if (this.labels[key].worldPosition == null) {
      return {
        x: -1000,
        y: -1000,
      };
    }

    var widthHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.width, 10);
    var heightHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.height, 10);




    let cameraVector = new THREE.Vector3();
    let vector = new THREE.Vector3(this.labels[key].worldPosition.x, this.labels[key].worldPosition.y, this.labels[key].worldPosition.z);
    cameraVector.copy(vector);
    cameraVector.project(camera);




    let notInView = false





    cameraVector.x = cameraVector.x * widthHalf + widthHalf;
    cameraVector.y = -(cameraVector.y * heightHalf) + heightHalf;
    if (cameraVector.z > 1) {
      //for hiding object  projecting from the back
      notInView = true;
    }

    return {
      x: cameraVector.x,
      y: cameraVector.y,
      notInView: notInView
    };
  }

  photosphereToScreenPosition(photosphereMesh) {
    //calc 2d coordinate of object
    if (photosphereMesh == null) {
      return null;
    }
    var widthHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.width, 10);
    var heightHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.height, 10);

    let cameraVector = new THREE.Vector3();
    let vector = new THREE.Vector3();
    photosphereMesh.getWorldPosition(vector);
    cameraVector.copy(vector);
    cameraVector.project(this.camera);


    if (cameraVector.z > 1) {
      //for hiding object  projecting from the back
      return null;
    }

    cameraVector.x = cameraVector.x * widthHalf + widthHalf;
    cameraVector.y = -(cameraVector.y * heightHalf) + heightHalf;

    return {
      x: cameraVector.x,
      y: cameraVector.y
    };
  }

  worldToScreenPosition(worldPosition) {
    var widthHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.width, 10);
    var heightHalf =
      0.5 * parseInt(this.renderer.getContext().canvas.style.height, 10);

    let cameraVector = new THREE.Vector3();
    let vector = new THREE.Vector3().copy(worldPosition)
    cameraVector.copy(vector);
    cameraVector.project(this.camera);


    cameraVector.x = cameraVector.x * widthHalf + widthHalf;
    cameraVector.y = -(cameraVector.y * heightHalf) + heightHalf;

    return {
      x: cameraVector.x,
      y: cameraVector.y
    };
  }

  enterTransparentMode() {


    this.clearINTERSECTED();

    this.saveObjectsMaterials();

    this.colorAllObjectInGrayTransparent();

    this.colorAllObjectByRelatedNotesForTransparentMode();


    this.askForRender();
  }

  exitTransparentMode() {
    if (this.selectedMode == "transparentMode") {



      this.loadLastObjectsMaterials();


      this.askForRender();
      this.selectedMode = null;
      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent(null, null, this.constructor.name)
      );
    }
  }

  enterZonesMode() {

    this.appActionsService.chosenObjectOid.next(null)

    this.clearINTERSECTED();

    this.saveObjectsMaterials();

    this.colorAllObjectInGrayTransparent();

    this.pinnedNotesGroup.visible = false;

    this.directionalLightBimshow.displayShadows(false)

    this.askForRender();
  }

  exitZonesMode() {
    if (this.selectedMode == "zonesMode") {
      this.loadLastObjectsMaterials();


      this.pinnedNotesGroup.visible = true;
      this.directionalLightBimshow.displayShadows(true);
      this.askForRender();
      this.selectedMode = null;
      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent(null, null, this.constructor.name)
      );
      this.appActionsService.refreshLayersVisability.next();
    }
  }

  updateZoneLabel() {
    if (performance.now() - this.zoneHoverTimer > 100) {
      this.zoneLabel = this.zonesLabels[this.hoveredZoneGuid];

      const overwrite = this.dataService.overwrites[this.hoveredZoneGuid];

      if (overwrite) {
        this.zoneLabel.shortName = overwrite.name;
        this.zoneLabel.longName = overwrite.longName;
      }
    } else {
      this.zoneLabel = null;
    }
  }

  displayShortNameLabels() {
    if (this.displayShortNameLabelsIsOn) {

      this.displayShortNameLabelsIsOn = false
    } else {
      this.displayShortNameLabelsIsOn = true
    }
  }

  changeZoneOpacity() {
    //to do @mg
  }

  saveObjectsMaterials() {
    this.singleMesh.defaultModeMaterials = this.singleMesh.material;




  }

  loadLastObjectsMaterials() {
    //main model
    this.singleMesh.material = this.singleMesh.defaultModeMaterials;

    //assets
    for (let modelId in this.models) {
      const object = this.models[modelId];

      object.traverse(o => {

        const material = this.originalMaterials[o.uuid]
        if (material) {

          o.material = material;
        }

      })
    }
  }

  colorAllObjectInGrayTransparent() {
    //main model
    this.singleMesh.material = this.singleMesh.material.map(mat => {
      if (mat.name !== 'zoneMaterial') {
        return this.transparentGrayMaterial;
      } else {
        return mat
      }
    })

    //assets:
    for (let modelId in this.models) {
      const object = this.models[modelId];

      object.traverse(o => {

        const material = o.material;
        if (material) {
          this.originalMaterials[o.uuid] = material;
          o.material = this.transparentGrayMaterial;
        }

      })
    }
  }


  colorAllObjectByRelatedNotesForTransparentMode() {
    let objectIsChosen = !!this.selectedObject;

    let allNotes: Note[] = this.dataService.getAllNotes();
    let objectsToColor = { ok: [], problem: [], none: [] };

    allNotes.forEach((note) => {
      this.dataService.getRelatedObjectsOfNote(note.id).forEach((rel) => {
        objectsToColor[note.type].push(rel.objectGuid);
      });
    });

    new THREE.MeshPhongMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.8,
    });
    //now we need to deal with multiple notes for one object, so we need to choose the type hirarchy we would color... simply we color multiple time, but in order that will perserve our goal
    objectsToColor.none.forEach((objectGuid) => {
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object["beforeColoredMaterial"] = object.material;
        object.material = this.transparentModeColorGray;
      }
    });

    objectsToColor.ok.forEach((objectGuid) => {
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object["beforeColoredMaterial"] = object.material;
        object.material = this.transparentModeColorGreen;
      }
    });

    objectsToColor.problem.forEach((objectGuid) => {
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object["beforeColoredMaterial"] = object.material;
        object.material = this.transparentModeColorOrange;
      }
    });


    this.askForRender();
  }

  refreshTransparentMode() {
    this.exitTransparentMode();
    this.appActionsService.toolboxEvents.next(
      new ToolboxEvent("transparentMode", "open", this.constructor.name)
    );
  }

  enterMeasureMode() {

  }

  exitMeasureMode() {
    this.debounceSSAAOn();
    if (this.selectedMode == "measureMode") {

      this.initMeter();
      this.clearMagnetPoints();
      this.selectedMode = null;

      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent(null, null, this.constructor.name)
      );
    }
  }

  enterClippingPlanesMode() {
    this.clippingCube.visible = true;
    this.askForRender();
  }

  exitClippingPlanesMode() {
    if (this.selectedMode == "clippingPlanes") {

      this.clippingCube.visible = false;
      this.turnRotateSphereOff();
      this.askForRender();

      this.selectedMode = null;

      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent(null, null, this.constructor.name)
      );
    }
  }

  enterImagePlacement(id) {
    this.appActionsService.imagePlacement.id = id;
    this.appActionsService.imagePlacement.points = [];

    this.imagePlacementPointsObject.children.forEach(point => {
      point.visible = false;
    })
    this.scene.add(this.imagePlacementPointsObject)
    this.askForRender();

  }

  exitImagePlacement() {

    this.appActionsService.imagePlacement.id = null;
    this.imagePlacementPointsObject.children.forEach(point => {
      point.visible = false;
    })

    this.scene.remove(this.imagePlacementPointsObject)
    this.askForRender();

  }


  flyToSiteZero() {
    if (this.mapboxOn) {
      let box = new THREE.Box3().setFromObject(this.clippingCube);
      let target = new THREE.Vector3();
      box.getCenter(target);


      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, {
          flyTo: {
            target: {
              long: this.dataService.siteData.longitude,
              lat: this.dataService.siteData.latitude,
            },
            center: {
              x: target.x,
              y: target.y
            },

            zoom: 17,
          },
        })
      );
    } else {

      this.flyTo(this.clippingCube, 0, .7, 1)

    }
  }

  enterPlaceOnMapMode() {
    this.setMapboxMode(
      this.mapboxMode == null || this.mapboxMode == 'off' ? this.appActionsService.lastMapboxMode : this.mapboxMode
    ); //when quitting placeonmapbox we also set the mapboxmode to null.. now, sometimes our loadview subscribption set already the mapbox mode to the photo's config. so now we know we dont need the 'lastMapboxMode', we need the current one. but when we toggle ourself the place on map mode, we want to have the last one that used. all this mass is because of the old way we implemented the changes here...But.. it works



  }

  exitPlaceOnMapMode() {
    if (this.selectedMode == "placeOnMapMode") {
      //this.flyTo(this.projectColladaObject, 0, 0.1, 1)
      this.scene.fog = null;
      this.pinnedNotesGroup.visible = true;
      this.askForRender();


      this.selectedMode = null;

      this.setMapboxMode("off");

      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent(null, null, this.constructor.name)
      );
      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, {
          openMapbox: false,
        })
      );
    }

    this.updateLabelsWorldPositions();
  }

  imagePlacementIntersecter() {

    if (!this.appActionsService.imagePlacement.id) {
      return;
    }

    let intersectWith = this.appActionsService.imagePlacement.points.length < 2 ? [this.imageLayers] : [this.projectColladaObject];
    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(
      intersectWith,
      true
    );


    let firstVisible = null;

    for (let intersect of intersects) {
      if (intersect.object.visible) {
        firstVisible = intersect;
        break;
      }
    }

    let intersect = null;
    if (this.clippingOn) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersect) break; //exit loop as intersect found
        const relativeToCubePosition = this.clippingCube.worldToLocal(intersects[i].point.clone());


        if (
          this.clippingCube.geometry.boundingBox.distanceToPoint(
            relativeToCubePosition
          ) == 0 && intersects[i].object.visible
        ) {

          intersect = intersects[i]

        }

      }
    } else {
      intersect = firstVisible
    }


    if (intersect) {
      this.INTERSECTED_POINT = intersect.point;

    } else {
      this.INTERSECTED_POINT = null;
    }

  }

  undoLastImagePlacmentPoint() {
    if (this.appActionsService.imagePlacement.points.length > 0) {
      this.appActionsService.imagePlacement.points.splice(this.appActionsService.imagePlacement.points.length - 1, 1)
    }
    this.updateImagePlacementPointsOnScenes()

  }

  updateImagePlacementPointsOnScenes() {
    for (let i = 0; i < this.imagePlacementPointsObject.children.length; i++) {
      const point = this.imagePlacementPointsObject.children[i];
      if (i < this.appActionsService.imagePlacement.points.length) {
        point.visible = true;
        point.position.copy(this.appActionsService.imagePlacement.points[i]);

      } else {
        point.visible = false
      }

    }
    this.askForRender();

  }
  imagePlacementOnClick() {
    if (!this.appActionsService.imagePlacement.id || this.appActionsService.imagePlacement.points.length >= 4) {
      return;
    }

    if (this.INTERSECTED_POINT) {
      this.appActionsService.imagePlacement.points.push(this.INTERSECTED_POINT.clone())
    }

    this.updateImagePlacementPointsOnScenes()


    if (this.appActionsService.imagePlacement.points.length >= 4) {
      this.updateImagePlacementBasedOnPoints()
      this.exitImagePlacement();

    }


  }

  updateImagePlacementBasedOnPoints() {
    if (this.appActionsService.imagePlacement.points.length >= 4) {
      const imageId = this.appActionsService.imagePlacement.id;
      const imageLayer = this.imageLayers.getObjectByName(imageId);
      let img = this.imageLayers.getObjectByName(imageId);
      let imageData = this.getImage(imageId);

      if (img) {
        const A_0 = this.appActionsService.imagePlacement.points[0].clone();
        const B_0 = this.appActionsService.imagePlacement.points[1].clone();
        const A_1 = this.appActionsService.imagePlacement.points[2].clone();
        const B_1 = this.appActionsService.imagePlacement.points[3].clone();


        const a_0 = A_0.clone()
        imageLayer.worldToLocal(a_0);


        A_0.z = 0;
        B_0.z = 0;
        A_1.z = B_1.z;



        const l0 = A_0.distanceTo(B_0);
        const l1 = A_1.distanceTo(B_1);
        const scale = l1 / l0;





        //now with have a_0 as the the delta from center in the plane space.

        const N1 = B_1.clone().sub(A_1).normalize();
        const N0 = B_0.clone().sub(A_0).normalize();
        const delta_rotation = Math.atan2(N1.y, N1.x) - Math.atan2(N0.y, N0.x)

        const original_rotation = -(imageData.rotation || 0) * Math.PI / 180;

        const a_1 = {
          x: scale * (Math.cos(delta_rotation + original_rotation) * a_0.x * imageLayer.scale.x - Math.sin(delta_rotation + original_rotation) * a_0.y * imageLayer.scale.y),
          y: scale * (Math.sin(delta_rotation + original_rotation) * a_0.x * imageLayer.scale.x + Math.cos(delta_rotation + original_rotation) * a_0.y * imageLayer.scale.y)
        }

        const center = {
          x: A_1.x - a_1.x,
          y: A_1.y - a_1.y
        }



        imageData.rotation = (imageData.rotation || 0) - 180 * delta_rotation / Math.PI;
        imageData.offsetX = center.x;
        imageData.offsetY = center.y;

        imageData.offsetZ = A_1.z + 0.01;

        imageData.height = imageData.height * l1 / l0;
        imageData.width = imageData.width * l1 / l0;



        this.updateImageLayer(imageId)
        this.askForRender();
      }
    }
  }

  updateCameraParamsInViewConfig() {
    if (this.directionalLightBimshow) {//makesure its initiated yet..  
      this.dataService.viewConfig.cameraParameters = {
        position: this.toSimpleVector(this.camera.position),
        fov: this.camera.fov,
        photoAmbientOcclusion: this.dataService.photoAmbientOcclusion.getValue(),
        ambientLightIntensity: this.ambientLight.intensity,
        directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
        edgeOptions: this.edgeBimshow.getOptions(),
        target: this.toSimpleVector(this.controls.target),
        blockOrbit: this.blockOrbitControls,
        blockZ: this.blockZControls,
        contrastLightAngle: this.contrastLightAngle,
        contrastLightIntensity: this.contrastLightIntensity
      };
    }

  }

  async takePhoto(refreshPhotoId?) {

    //set center as target:
    this.mouse.x = 0;
    this.mouse.y = 0;
    this.defaultIntersecter();

    if (this.INTERSECTED_POINT) {
      this.camera.lookAt(this.INTERSECTED_POINT);
      this.controls.target.copy(this.INTERSECTED_POINT);
      this.controls.update();

    }

    this.appActionsService.openPhoto.next(null);
    this.cameraSound.play();
    this.takingPhoto = true;
    setTimeout(() => {
      this.takingPhoto = false;
    }, 300);

    this.appActionsService.showCameraOverlay = false;

    if (this.SAOOn && !this.mapboxOn) {
      if (this.composer) {
        this.composer.render();
      }
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    let imgData = null;
    if (this.mapboxOn) {
      imgData = await this.appActionsService.mapboxThreejsModel.takePhoto();
    } else {
      imgData = this.renderer.domElement.toDataURL();
    }

    //updating viewConfig
    this.updateCameraParamsInViewConfig();


    this.dataService.viewConfig.mapboxMode = this.mapboxMode;

    this.saveClippingToConfig();

    let ratio =
      this.renderer.domElement.clientWidth /
      this.renderer.domElement.clientHeight;
    this.resizedataURL(imgData, 150 * ratio, 150).then((thumb) => {
      let date = new Date();
      let title =
        this.dataService.user.firstName[0] +
        this.dataService.user.lastName[0] +
        "_" +
        date.getTime();
      let description = this.translate.instant('photo.noDescription');
      //let description = "No description";

      let newPhoto = {
        title: title,
        description: description,
        createdBy: this.dataService.user.id,
        dateTaken: date.toString(),
        thumbData: thumb,
        viewConfig: this.dataService.viewConfig,

      };

      if (refreshPhotoId) {

        this.dataService.refreshPhoto(refreshPhotoId, newPhoto);
      } else {

        this.dataService.addPhoto(newPhoto).catch((err) => {
          console.warn(err.message);
        });
      }
    });
  }

  toSimpleVector(vector) {
    return { x: vector.x, y: vector.y, z: vector.z };
  }

  resizedataURL(datas, wantedWidth, wantedHeight) {
    // We create an image to receive the Data URI
    var img = document.createElement("img");
    img.src = datas;

    return new Promise<any>((resolve, reject) => {
      img.onload = () => {
        // We create a canvas and get its context.
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

        var thumb = canvas.toDataURL();
        resolve(thumb);

        /////////////////////////////////////////
        // Use and treat your Data URI here !! //
        /////////////////////////////////////////
      };
    });
  }

  transitionToNewCamera(cameraParameters, time = 2) {

    this.appActionsService.transitionCamera.next(true)
    function easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    }
    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
    const endTarget = new THREE.Vector3(
      cameraParameters.target.x,
      cameraParameters.target.y,
      cameraParameters.target.z
    );
    const endPosition = cameraParameters.position;

    this.flightId = this.flightId + 1;
    const dateNow = Date.now()

    return new Promise((resolve, reject) => {
      this.turnOffSSAA();


      let id = this.flightId;

      this.inFlight = true;
      var T = time; //flight time in seconds;

      var startpoint = { x: 0, y: 0, z: 0 };
      startpoint.x = 0 + this.camera.position.x;
      startpoint.y = 0 + this.camera.position.y;
      startpoint.z = 0 + this.camera.position.z;

      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);
      cameraDirection.normalize(); //just in case.  i think it is already normailzed.

      var starttarget = {
        x: +this.controls.target.x,
        y: +this.controls.target.y,
        z: +this.controls.target.z,
      };




      const r0 = new THREE.Vector3(
        startpoint.x,
        startpoint.y,
        startpoint.z
      ).sub(new THREE.Vector3(starttarget.x, starttarget.y, starttarget.z));
      const theta0 = Math.acos(r0.z / r0.length());
      let phi0 = Math.atan2(r0.y, r0.x);

      phi0 = phi0 > 0 ? phi0 : 2 * Math.PI + phi0;

      const r1 = new THREE.Vector3(
        endPosition.x,
        endPosition.y,
        endPosition.z
      ).sub(new THREE.Vector3(endTarget.x, endTarget.y, endTarget.z));
      const theta1 = Math.acos(r1.z / r1.length());
      let phi1 = Math.atan2(r1.y, r1.x);

      phi1 = phi1 > 0 ? phi1 : 2 * Math.PI + phi1;



      this.flightInterferdByControls = false;


      this.appActionsService.transitionCamera.next(true)
      this.animationsManager.addAnimation(60 * T,
        (a) => {

          this.inFlight = true;

          this.camera.position.x =
            startpoint.x +
            (endPosition.x - startpoint.x) * easeInOutQuad(a);
          this.camera.position.y =
            startpoint.y +
            (endPosition.y - startpoint.y) * easeInOutQuad(a);
          this.camera.position.z =
            startpoint.z +
            (endPosition.z - startpoint.z) * easeInOutQuad(a);

          if ((theta1 * 180) / Math.PI > 5 && (theta0 * 180) / Math.PI > 5) {
            this.controls.target.x =
              starttarget.x +
              (endTarget.x - starttarget.x) * easeInOutQuad(a);
            this.controls.target.y =
              starttarget.y +
              (endTarget.y - starttarget.y) * easeInOutQuad(a);
            this.controls.target.z =
              starttarget.z +
              (endTarget.z - starttarget.z) * easeInOutQuad(a);
          } else {
            let theta =
              theta0 + (theta1 - theta0) * easeInOutQuad(a);
            let phi = phi0 + (phi1 - phi0) * easeInOutQuad(a);

            let r = new THREE.Vector3(
              Math.cos(phi) * Math.sin(theta),
              Math.sin(phi) * Math.sin(theta),
              Math.cos(theta)
            );

            this.controls.target.x = this.camera.position.x - r.x;
            this.controls.target.y = this.camera.position.y - r.y;
            this.controls.target.z = this.camera.position.z - r.z;
          }

          this.camera.lookAt(this.controls.target);
          this.camera.updateProjectionMatrix();
          this.controls.update();

        },
        (isCanceled) => {
          this.inFlight = false;
          this.askForRender();
          this.appActionsService.transitionCamera.next(false)
          if (isCanceled) {
            reject('aborted');
            return;
          }


          resolve(null);


        },
        "cameraTransition",
        true
      )

      this.inFlight = true; //we have to set it also after adding animation. because a previous animation that will be canceled will set in flight to 0;


    })





  }






  flyTo(object, zjump?, time?, zoomFactor?, moveOnlyForward?) {
    if (object == null) {
      //treminate function if object is null for any reason.

      return;
    }
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.enableRotate = false;

    if (zjump == null) {
      zjump = 0;
    }

    if (time == null) {
      time = 0.5;
    }


    function easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    }
    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    let box = new THREE.Box3().setFromObject(object);
    let target = new THREE.Vector3();
    box.getCenter(target);

    let size = new THREE.Vector3();
    box.getSize(size);

    let distance =
      Math.sqrt(size.x * size.x + size.y * size.y + size.z * size.z) * 2;
    if (zoomFactor) {
      distance =
        Math.sqrt(size.x * size.x + size.y * size.y + size.z * size.z) *
        zoomFactor / this.camera.fov * 30;
    }


    //onlyforward:
    if (moveOnlyForward) {
      if (this.camera.position.distanceTo(target) < distance) {
        distance = this.camera.position.distanceTo(target);
      }
    }


    new Promise((resolve, reject) => {



      function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      }
      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }




      var startpoint = { x: 0, y: 0, z: 0 };
      startpoint.x = 0 + this.camera.position.x;
      startpoint.y = 0 + this.camera.position.y;
      startpoint.z = 0 + this.camera.position.z;

      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);
      cameraDirection.normalize(); //just in case.  i think it is already normailzed.

      let endpoint = target
        .clone()
        .add(cameraDirection.clone().multiplyScalar(-1 * distance));




      this.animationsManager.addAnimation(
        60 * time,
        (a) => {
          this.inFlight = true;
          this.camera.position.x =
            startpoint.x + (endpoint.x - startpoint.x) * easeInOutQuad(a);
          this.camera.position.y =
            startpoint.y + (endpoint.y - startpoint.y) * easeInOutQuad(a);
          this.camera.position.z =
            startpoint.z +
            (endpoint.z - startpoint.z) * easeInOutQuad(a)


          this.turnOffSSAA()
          this.askForRender();
        }, (
        (isCanceled) => {
          if (isCanceled) {
            reject()
            return;
          }
          this.camera.position.set(endpoint.x, endpoint.y, endpoint.z);
          this.camera.lookAt(target);
          this.controls.target = target;
          this.controls.update();


          this.askForRender();
          this.inFlight = false;
          this.controls.enableZoom = true;
          this.controls.enablePan = true;
          this.controls.enableRotate = true;

          resolve();

        }
      ))


    });
  }


  addParts() {
    //   var loader = new THREE.TextureLoader();
    // loader.load( "../../assets/images/icons/ps_smoke.png");
    for (let guid in this.objectsGuidQuery.threejs) {
      this.meshParts = this.objectsGuidQuery.threejs[guid]
      if (this.meshParts.type && this.meshParts.type == "Mesh") {
        var psMat = new THREE.PointsMaterial({
          // psMat.map = loader;
          color: new THREE.Color(0xff0000),
          transparent: true,
          size: 0.2
          // blending : THREE.AdditiveBlending
        })

        const ps = new THREE.Points(this.meshParts, psMat);


        // add the particle system to the scene
        this.scene.add(ps);
        //this.collidableObjects.push(ps)




      }
    }

  }

  addGrid() {
    this.gridHelper.rotation.x = Math.PI / 2;
    this.gridHelper.position.y = this.projectColladaObject.position.y;
    this.gridHelper.position.x = this.projectColladaObject.position.x;
    this.gridHelper.position.z = -1
    this.scene.add(this.gridHelper);
  }

  removeGrid() {
    this.gridHelper.name = "grid"
    var selectedObject = this.scene.getObjectByName(this.gridHelper.name);
    this.scene.remove(selectedObject)

  }

  convertCoordinates(lon, lat) {


    const m_per_deg_lat = 111132.954;
    const m_per_deg_lon = 111132.954 * Math.cos(Math.PI * lat / 180);

    const theta = -Math.PI * Number(this.dataService.siteData.rotate) / 180
    let x_north_oriented = (lon - Number(this.dataService.siteData.longitude)) * m_per_deg_lon - this.dataService.siteData.offsetX;
    let y_north_oriented = (lat - Number(this.dataService.siteData.latitude)) * m_per_deg_lat - this.dataService.siteData.offsetY;


    const x_scene = Math.cos(theta) * x_north_oriented - Math.sin(theta) * y_north_oriented;
    const y_scene = Math.sin(theta) * x_north_oriented + Math.cos(theta) * y_north_oriented;


    return { x: x_scene, y: y_scene };
  }



  moveToNorth() {
    let target = this.controls.target.clone();
    let pos = this.camera.position.clone();
    let r = new THREE.Vector3().subVectors(pos, target);

    r = r.applyAxisAngle(
      new THREE.Vector3(0, 0, 1),
      -this.controls.getAzimuthalAngle()
    );
    r.add(target);
    this.camera.position.set(r.x, r.y, r.z);
    this.camera.lookAt(target);
    this.controls.update();

    if (this.mapboxOn) {
      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, {
          resetNorth: true,
        })
      );
    }
  }


  zoom(x) {
    if (this.mapboxOn) {
      this.appActionsService.toolboxEvents.next(
        new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, {
          zoomInOut: x < 1 ? 1 : -1
        })
      );
    } else {
      let d = this.camera.position.distanceTo(this.controls.target) * this.camera.fov / 30;
      d = d * x;


      this.flyToSphere.scale.set(d, d, d);
      this.flyToSphere.position.copy(this.controls.target);
      this.flyTo(this.flyToSphere, 0, 0.2, 0.288);

      if (this.dataService.collectionDimensioning) {
        this.dataService.collectionDimensioning.setDimensioningsScaleCamera(this.camera)
      }
    }
  }

  getImage(imageId) {
    let image = null;
    for (let existingImage of this.dataService.compareImages) {
      if (existingImage.id == imageId) {
        image = existingImage;
      }
    }

    return image;
  }

  generateImageLayer(imageId) {

    return new Promise<any>((resolve, reject) => {
      if (this.imageLayers.getObjectByName(imageId)) {
        //verify this layer is not already generated!
        reject({
          code: 2,
          message: "layer already exist, generateImageLayer stoped.",
        });
        return;
      }

      let image = this.getImage(imageId);

      if (image == null) {
        reject({
          code: 2,
          message:
            "image doesnt exist in compareImages array! generateImageLayer stoped.",
        });
        return;
      }

      let geometry = new THREE.PlaneGeometry(1, 1);

      // instantiate a loader
      var loader = new THREE.TextureLoader();

      // load a resource
      loader.load(
        // resource URL (if imageData managed to fetch already, it will load it.. if not, it will download again...)
        image.imageData ? URL.createObjectURL(image.imageData) : image.url,

        // onLoad callback
        (imageTexture) => {
          imageTexture.minFilter = THREE.LinearFilter;
          // in this example we create the material when the texture is loaded
          imageTexture.colorSpace = THREE.SRGBColorSpace;
          let opcaity, visible;
          if (this.dataService.viewConfig.pdfCompareMode[image.id]) {
            opcaity =
              this.dataService.viewConfig.pdfCompareMode[image.id].opacity;
            visible =
              this.dataService.viewConfig.pdfCompareMode[image.id].visible;
          }
          var ditherTex;   //@mg try to fix shadows
          // opacity is not available in the shader by default


          //@mg how to make transparent shadows ? see   https://gkjohnson.github.io/threejs-sandbox/screendoor-transparency/
          // const depthShader = THREE.DitheredTransparencyShaderMixin(THREE.ShaderLib.depth);
          // depthShader.fragmentShader = `uniform float opacity;\n${ depthShader.fragmentShader }`;

          // const depthMat = new THREE.ShaderMaterial(depthShader);
          // depthMat.defines.DEPTH_PACKING = THREE.RGBADepthPacking;
          // depthMat.uniforms.ditherTex.value = ditherTex;




          let material = new THREE.MeshBasicMaterial({
            map: imageTexture,
            side: THREE.DoubleSide,
            opacity: opcaity ? opcaity : 1,
            transparent: true
          });

          let plane = new THREE.Mesh(geometry, material);
          plane.visible = visible != null ? visible : false;
          plane.userData.ambientOcclusion = true;

          if (image.id) {
            plane.name = image.id;
          }

          this.imageLayers.add(plane);
          image["modelReady"] = true;

          resolve(null);
          return;
        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        (err) => {
          reject({ code: 1, message: "error happened", error: err });
          console.error("An error happened.", err);
        }
      );
    });
  }

  updateAllImageLayers() {
    this.dataService.compareImages.forEach((image) => {
      this.updateImageLayer(image.id);
    });
  }

  updateImageLayer(imageId) {
    let imageLayer = this.imageLayers.getObjectByName(imageId);
    let image = this.getImage(imageId);

    if (image == null || imageLayer == null) {
      return;
    }

    if (image.width != null) {
      imageLayer.scale.x = image.positionOnCamera ? image.postionOnCameraWidth : image.width;
    }

    if (image.height != null) {
      imageLayer.scale.y = image.positionOnCamera ? image.postionOnCameraHeight : image.height;
    }

    let pos = new THREE.Vector3();

    if (image.longitude != null && image.latitude != null) {
      let xy = this.convertCoordinates(image.longitude, image.latitude);

      pos.set(xy.x, xy.y, 0);
    } else {
      pos.copy(this.projectColladaObject.position);
    }

    let offset = new THREE.Vector3(
      image.offsetX ? image.offsetX : 0,
      image.offsetY ? image.offsetY : 0,
      image.offsetZ ? image.offsetZ : 0
    );

    imageLayer.position.copy(pos.add(offset));

    //reset all rotations:
    imageLayer.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), 0);

    if (image.vertical) {
      //flip

      imageLayer.setRotationFromAxisAngle(
        new THREE.Vector3(1, 0, 0),
        Math.PI / 2
      );
    }

    if (image.rotation != null) {
      if (image.vertical) {
        imageLayer.rotateOnAxis(
          new THREE.Vector3(0, 1, 0),
          (-image.rotation * Math.PI) / 180
        ); //rotate around y
      } else {
        imageLayer.rotateOnAxis(
          new THREE.Vector3(0, 0, 1),
          (-image.rotation * Math.PI) / 180
        ); //rotate around z
      }
    }

    if (image.viewConfig.opacity != null) {
      imageLayer.material.opacity = image.viewConfig.opacity;
      if (image.viewConfig.opacity < 1) {
        imageLayer.material.depthWrite = false;
      } else {
        imageLayer.material.depthWrite = true;
      }
    }

    if (image.viewConfig.visible != null) {
      if (this.mapboxOn && !image.vertical) {
        imageLayer.visible = false;
      } else {
        imageLayer.visible = image.viewConfig.visible;
      }

    }

    if (image.positionOnCamera) {


      imageLayer.position.set(image.position.x, image.position.y, image.position.z);
      imageLayer.up.set(0, 0, 1);
      imageLayer.lookAt(image.lookAt.x, image.lookAt.y, image.lookAt.z)

    }






    this.askForRender();
  }

  deleteImageLayer(id) {
    let img = this.imageLayers.getObjectByName(id);
    if (img) {
      this.imageLayers.remove(img);
      img.material.dispose();
      img.geometry.dispose();
    }

    this.askForRender();
  }

  orderLayers() {
    let i = -1;
    let layerOrder = [];
    if (this.dataService.viewConfig["pdfCompareMode"]["layersOrderById"]) {
      //incase config exist for layerorder
      layerOrder =
        this.dataService.viewConfig["pdfCompareMode"]["layersOrderById"];
    }

    this.dataService.compareImages.forEach((image) => {
      //start pushing all ids that doesnt exist in config
      if (layerOrder.indexOf(image.id) == -1) {
        layerOrder.push(image.id);
      }
    });

    layerOrder.forEach((id) => {
      //now set renderOrder
      let imageLayerToOrder = this.imageLayers.getObjectByName(id); //there is chance layer was deleted..
      if (imageLayerToOrder) {
        imageLayerToOrder.renderOrder = i;
        i--;
      }
    });

    this.askForRender();
  }

  updateSkyAndBackgroundBasedOnMode() {
    if (this.mapboxOn) {
      this.scene.background = null;
      if (this.directionalLightBimshow) {
        if (this.directionalLightBimshow.sky) {
          this.directionalLightBimshow.sky.visible = false;
        }

      }
    } else {
      if (this.directionalLightBimshow) {
        if (this.directionalLightBimshow.sky) {
          this.directionalLightBimshow.sky.visible = true;
        }
      }
      this.setSceneBackground(
        this.dataService.loadedView ? (this.dataService.loadedView.lightBackground ? "light" : null) : null
      );
    }


  }

  getMapboxCameraParams() {
    return {
      targetProjectSpace:
      {
        x: this.controls.target.x,
        y: this.controls.target.y
      }
      ,
      zoom: (
        (29.16 * 1) /
        Math.pow(
          this.controls.target.distanceTo(this.camera.position),
          0.0956
        )
      ).toFixed(1),
      compassAngle: this.compassAngle,
      pitch: (this.controls.getPolarAngle() * 180) / Math.PI
    }
  }

  setMapboxMode(mode) {
    if (
      this.appActionsService.mapboxMode != "off" &&
      this.appActionsService.mapboxMode != null
    ) {
      this.appActionsService.lastMapboxMode = this.appActionsService.mapboxMode;
    }


    switch (mode) {
      case "off":
        this.mapboxMode = "off";
        this.mapboxOn = false;
        break;
      case "map":
        this.mapboxOn = true;
        this.mapboxMode = "map";
        break;
      case "sat":
        this.mapboxOn = true;
        this.mapboxMode = "sat";
        break;
    }



    this.updateSkyAndBackgroundBasedOnMode();
    this.updateAllImageLayers() // required to update the visibility of threejs images based on vertical 
    if (this.mapboxOn) {
      //this.controls.autoRotate = false;
      if (this.appActionsService.isMobile) {
        this.closeJoysticksClicked();
      }
    }



    this.appActionsService.mapboxMode = this.mapboxMode;

    const cameraParams = this.getMapboxCameraParams()
    this.appActionsService.toolboxEvents.next(
      new ToolboxEvent("placeOnMapMode", "updates", this.constructor.name, {
        openMapbox: this.mapboxOn,
        mapboxMode: this.mapboxMode,
        targetProjectSpace: cameraParams.targetProjectSpace,
        zoom: cameraParams.zoom,
        compassAngle: cameraParams.compassAngle,
        pitch: cameraParams.pitch
      })
    );
  }

  @HostListener("document:fullscreenchange", ["$event"])
  @HostListener("document:webkitfullscreenchange", ["$event"])
  @HostListener("document:mozfullscreenchange", ["$event"])
  @HostListener("document:MSFullscreenChange", ["$event"])
  screenChange(event) {
    this.fullscreen = !this.fullscreen;
  }

  toggleFullscreen() {
    if (!this.fullscreen) {
      if (this.d["documentElement"].requestFullscreen) {
        this.d["documentElement"].requestFullscreen();
      } else if (this.d["documentElement"].mozRequestFullScreen) { /* Firefox */
        this.d["documentElement"].mozRequestFullScreen();
      } else if (this.d["documentElement"].webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        this.d["documentElement"].webkitRequestFullscreen();
      }
      else if (this.d["documentElement"].msRequestFullscreen) { /* IE/Edge */
        this.d["documentElement"].msRequestFullscreen();
      }

    } else {

      if (this.d.webkitExitFullscreen) { this.d.webkitExitFullscreen() }
      else if (this.d.exitFullscreen) { this.d.exitFullscreen() }
      else if (this.d.mozCancelFullScreen) { this.d.mozCancelFullScreen() }
      else if (this.d.msExitFullscreen) { this.d.msExitFullscreen() };
    }

    setTimeout(() => {
      this.onWindowResize();
    }, 200);
  }

  //vis filters:
  setVisibilityFilterOut(oid, mode, state) {

    if (this.visibilityFilters[oid] == null) {
      this.visibilityFilters[oid] = {};
    }

    this.visibilityFilters[oid][mode] = state;
  }

  updateVisibilityOfObjectFromFilterOut(oid) {
    if (
      this.visibilityFilters[oid] == null ||
      this.selectedMode == "zonesMode"
    ) {
      return;
    }


    let visible = !this.dataService.deletedObjects[oid] && !(
      this.visibilityFilters[oid]["layers"] ||
      this.visibilityFilters[oid]["tree"]
    );

    let guid = this.dataService.getGuidOfObjectId(oid); //threejs model

    if (guid != null) {
      let object = this.objectsGuidQuery.threejs[guid];

      if (object) {
        if (object == this.projectColladaObject) {
          //we dont wish to change the visibility of the main object holding all the meshes as children.
          return;
        }
       
        object.visible = visible;
      }

      //need to create a list for mapbox object query aswell!!!
      let objectInMapboxModel = this.objectsGuidQuery.mapbox[guid]; //for mapboxmodel

      if (objectInMapboxModel) {
        objectInMapboxModel.visible = visible;
      }

      //update stencils:
      if (this.stencils.guidDict) {
        if (this.stencils.guidDict[guid]) {
          this.stencils.guidDict[guid].forEach(stencilMesh => {
            stencilMesh.visible = object.visible;
          })
        }
      }
    }

  }

  //debugger
  debugMouseEntered() {
    this.onMouseLeave();
  }

  debugMouseLeft() {
    this.onMouseEnter();
  }

  debugHandleDblClicked(e) {
    this.toggleMinimizeDebugger();
  }

  toggleMinimizeDebugger() {
    this.debuggerMinimized = !this.debuggerMinimized;
  }

  printMe(p) {
    this.mobileLog = p;
  }



  toggleControls() {
    this.controls.enabled = !this.controls.enabled;
  }

  addFlashLight() {


    let flashlight = new THREE.SpotLight(0xff0000, .5, 15);

    flashlight.position.set(1, 0, 1);
    flashlight.target = this.camera;
    this.camera.add(flashlight);

    let flashlight2 = new THREE.SpotLight(0x0000ff, .5, 15);
    flashlight2.position.set(0, 1, 1);
    // flashlight2.position.copy(this.camera.position);
    this.camera.add(flashlight2);

    flashlight.target = this.camera;
    const pointLight = new THREE.PointLight(0x00ff00, .5, 6);
    this.camera.add(flashlight);


    this.scene.add(this.camera);


    // let sphere = new THREE.Mesh(new THREE.SphereGeometry(2,8,8), new THREE.MeshBasicMaterial({color:0xffffff}));
    // let spot =  new THREE.SpotLight(0xffffff, 1);
    // this.projectColladaObject.add(spot);
    // this.projectColladaObject.add(sphere)
    // spot.position.set(10, 10, 30);
    // sphere.position.set(10,10,30)
    // spot.target = this.projectColladaObject;
  }

  updateSingleMeshMaterialFromMaterialList() {

    //reset
    this.singleMesh.material = this.singleMesh.resetData.material.slice();

    //add all new custome materials
    for (let materialItem of this.materialsList) {
      const material = materialItem.material
      if (this.singleMesh.material.indexOf(material) == -1) {
        material.onBeforeCompile = this.singleMesh.onBeforeCompileFunc;
        this.singleMesh.material.push(material)
        //also add clipping planes:
        material.clippingPlanes = this.clippingPlanes;
      }
    }

  }

  updateSingleMeshPasslightFromMaterials() {

    const IndexTransparentDict = {};



    for (let group of this.singleMesh.geometry.groups) {

      const mat = this.singleMesh.material[group.materialIndex];
      if (mat.opacity < 1) {
        for (let i = 0; i < group.count; i++) {
          const vertexIndex = this.singleMesh.geometry.index.array[group.start + i];

          IndexTransparentDict[vertexIndex] = true;
        }
      }

    }

    this.singleMesh.geometry.setAttribute('passlight', new THREE.Float32BufferAttribute(new Float32Array(this.singleMesh.geometry.attributes.passlight.array).map((v, i) => {
      return (IndexTransparentDict[i] ? 1.0 : 0.0)
    }), 1));

    this.singleMesh.geometry.attributes.passlight.needsUpdate = true;

    if (this.directionalLightBimshow) {
      this.directionalLightBimshow.updateShadowMap();
    }

    this.askForRender();


  }

  initSingleMesh() {


    const model = this.singleMesh;
    model.geometry.computeBoundingSphere();


    model.geometry.computeVertexNormals();



    if (this.project.origin !== 'collada') {
      //calculate UV coordinates, if uv attribute is not present, it will be added
      applyBoxUV(model.geometry, new THREE.Matrix4(), 1);
      model.geometry.attributes.uv.needsUpdate = true;
      model.setRotationFromEuler(
        new THREE.Euler(Math.PI / 2, 0, 0, "ZXY")
      );
    }

    this.scene.add(model)

    model.receiveShadow = true;
    model.castShadow = true;


    model.geometry.setAttribute('expressID', new THREE.Float32BufferAttribute(new Float32Array(model.geometry.attributes.expressID.array), 1));
    model.geometry.setAttribute('hidden', new THREE.Float32BufferAttribute(new Float32Array(model.geometry.attributes.expressID.array).map(() => 0), 1));
    model.geometry.setAttribute('passlight', new THREE.Float32BufferAttribute(new Float32Array(model.geometry.attributes.expressID.array).map(() => 0), 1));









    let colorIndex = 0;
    const spaceColorArray = [];
    for (let i = 0; i < model.geometry.attributes.expressID.array.length; i++) {

      const eid = model.geometry.attributes.expressID.array[i];
      const guid = this.EidToGuid[eid];
      const isIfcSpace = this.objectsGuidQuery.threejs[guid] ? this.objectsGuidQuery.threejs[guid].userData.ifcType == 'IfcSpace' : null;
      if (isIfcSpace) {

        if (this.zonesColors[guid]) {
          spaceColorArray.push(
            this.zonesColors[guid].index
          )

        } else {
          this.zonesColors[guid] = {
            guid,
            eid,
            index: colorIndex,
            color: { r: 30 / 256, g: 130 / 256, b: 169 / 256 }

          }


          spaceColorArray.push(
            colorIndex
          )

          colorIndex++;
        }

      }
      else {
        spaceColorArray.push(
          0
        )
      }
    }




    let c = [];
    for (let i = 0; i < model.geometry.attributes.position.count; i++) {
      c.push(i)
    }


    model.geometry.setAttribute('spaceIndex', new THREE.Float32BufferAttribute(spaceColorArray, 1));





    // model.material = model.material.map(() => (testmat))
    this.zoneMat = new THREE.MeshLambertMaterial({
      vertexColors: true,
      transparent: true,
      opacity: 0.5,
      side: THREE.FrontSide,
      name: "zoneMaterial",
      clippingPlanes: this.clippingPlanes
    });

    const colorArray = [];

    for (let guid in this.zonesColors) {
      const color = this.zonesColors[guid].color;
      colorArray.push(color.r, color.g, color.b, 1);


    }


    const DT = new THREE.DataTexture(new Float32Array(colorArray), colorArray.length / 4, 1, THREE.RGBAFormat, THREE.FloatType);
    DT.needsUpdate = true;


    this.zonesColorsUniform = {
      value: DT

    }


    this.zoneMat.onBeforeCompile = (shader) => {

      shader.uniforms.colors = this.zonesColorsUniform;
      shader.uniforms.hover = this.hoverUniform;
      shader.uniforms.selected = this.selectedUniform;
      shader.uniforms.time = this.timeUniform;

      shader.vertexShader = `
    	uniform sampler2D colors;
      attribute float spaceIndex;
      ${shader.vertexShader}`


      shader.vertexShader = shader.vertexShader.replace(
        `#include <color_vertex>`,
        [`#include <color_vertex>`,
          `vColor = texture(colors, vec2(spaceIndex / ${colorArray.length / 4 - 1}., 0.)).rgb;`,
        ].join('\n')

      );

      shader.vertexShader = shader.vertexShader.replace(
        '#include <common>',
        [`#include <common>`,
          `attribute float expressID;`,
          `attribute float hidden;`,
          `varying float vhidden;`,
          `varying float vexpressID;`].join('\n')

      );
      shader.vertexShader = shader.vertexShader.replace(
        '#include <begin_vertex>',
        [`#include <begin_vertex>`,
          `vhidden = hidden;`,
          `vexpressID = expressID;`
        ].join('\n')

      );


      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <common>',
        [`#include <common>`,
          `varying float vhidden;`,
          `varying float vexpressID;`,
          `uniform float hover;`,
          `uniform float selected;`,
          `uniform float time;`].join('\n')
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <clipping_planes_fragment>`,
        [`#include <clipping_planes_fragment>`,
          `bool isSelected = abs(vexpressID - selected) < 0.5;`,
          `if(vhidden  > 0.0 && !isSelected) {`,
          `discard;`,
          `return;`,
          `}`,].join('\n')

      )

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <dithering_fragment>`,
        [`#include <dithering_fragment>`,
          `if(abs(vexpressID - hover) < 0.5) {`,
          `gl_FragColor.rgb = 1.3 * gl_FragColor.rgb;`,
          `gl_FragColor.a += 0.1;`,
          `}`,
          `if(abs(vexpressID - selected) < 0.5) {`,
          `gl_FragColor.rgb = 1.3*gl_FragColor.rgb;`,
          // `gl_FragColor.rgb = (1.0 + sin(time*3.0) * 0.3) * gl_FragColor.rgb ;`, //flickering POC
          `gl_FragColor.a = 1.0;`,
          `}`].join('\n')

      )





    };

    this.singleMesh.onBeforeCompileFunc = (shader) => {
      shader.uniforms.hover = this.hoverUniform;
      shader.uniforms.selected = this.selectedUniform;
      shader.uniforms.selectedMaterialColor = this.selectedMaterailColorUniform;

      shader.vertexShader = shader.vertexShader.replace(
        '#include <common>',
        [`#include <common>`,
          `attribute float expressID;`,
          `attribute float hidden;`,
          `varying float vhidden;`,
          `varying float vexpressID;`].join('\n')

      );
      shader.vertexShader = shader.vertexShader.replace(
        '#include <begin_vertex>',
        [`#include <begin_vertex>`,
          `vhidden = hidden;`,
          `vexpressID = expressID;`
        ].join('\n')

      );


      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <common>',
        [`#include <common>`,
          `varying float vhidden;`,
          `varying float vexpressID;`,
          `uniform float hover;`,
          `uniform float selected;`,
          `uniform vec3 selectedMaterialColor;`].join('\n')
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <clipping_planes_fragment>`,
        [`#include <clipping_planes_fragment>`,
          `bool isSelected = abs(vexpressID - selected) < 0.5;`,
          `if(vhidden  > 0.0 && !isSelected) {`,
          `discard;`,
          `return;`,
          `}`,].join('\n')

      )

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <color_fragment>`,
        [`#include <color_fragment>`,
          `if(vexpressID > 0.0 && abs(vexpressID - selected) < 0.5 ) {`,
          `diffuseColor.rgb = selectedMaterialColor.rgb;`,
          `}`,
          `if(abs(vexpressID - hover) < 0.5) {`,
          `diffuseColor.rgb = (1.0 - sign( (diffuseColor.r + diffuseColor.g + diffuseColor.b) / 3.0 - 0.8 ) * 0.2 ) * diffuseColor.rgb;`,
          `}`,].join('\n')

      )


    }


    model.material = model.material.map((mat) => {
      const ml = this.materialsList.find((ml) => (ml.material.name == mat.name));
      if (ml) {
        ml.material.onBeforeCompile = this.singleMesh.onBeforeCompileFunc;
        return ml.material
      } else {
        console.warn('diodnt find ', mat) //sometimes we dont find ? why? debug ! 
        // return mat
        return this.zoneMat;
      }
    });


    this.transparentGrayMaterial.onBeforeCompile = (shader) => {

      this.singleMesh.onBeforeCompileFunc(shader);
      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <dithering_fragment>`,
        [`#include <dithering_fragment>`,
          `if( vexpressID > 0.0 && abs(vexpressID - selected) < 0.5 ) {`,
          `gl_FragColor.a = 0.7;`,
          `}`].join('\n')

      )
    }








    this.singleMesh.resetData = {
      array: this.singleMesh.geometry.index.array.slice(),
      groups: JSON.parse(JSON.stringify(this.singleMesh.geometry.groups)),
      material: this.singleMesh.material.slice()
    }


    this.scene.remove(this.projectColladaObject);
    // this.singleMesh.position.x += 300;

    this.singleMesh.customDepthMaterial = new THREE.MeshDepthMaterial({
      onBeforeCompile: discardHiddenOnBeforeCompileFunc,
      depthPacking: THREE.RGBADepthPacking,
      clippingPlanes: this.clippingPlanes
    })

    this.updateSingleMeshPasslightFromMaterials();
    this.scene.add(this.singleMesh);
    this.singleMesh.userData.ambientOcclusion = true;
    this.dataService.project3dObject = this.singleMesh.clone();

  }

  generateEidGuidDicts() {
    for (let guid in this.objectsGuidQuery.threejs) {

      const object = this.objectsGuidQuery.threejs[guid];
      if (object.userData.expressID) {
        this.EidToGuid[object.userData.expressID] = guid;
        this.GuidToEid[guid] = object.userData.expressID;
      }
    }


  }

  updateColorsInSingleMesh() {
    const colorArray = []

    for (let guid in this.zonesColors) {

      const color = this.zonesColors[guid].color;

      colorArray.push(color.r, color.g, color.b, 1);


    }

    const DT = new THREE.DataTexture(new Float32Array(colorArray), colorArray.length / 4, 1, THREE.RGBAFormat, THREE.FloatType);
    DT.needsUpdate = true;
    this.zonesColorsUniform.value = DT;


  }

  updateVisibilityInSingleMesh() {

    const eidsVisDict = {};
    for (let guid in this.objectsGuidQuery.threejs) {

      const object = this.objectsGuidQuery.threejs[guid];
      if (object.userData.expressID) {
        eidsVisDict[object.userData.expressID] = object.visible;
      }



    }



    this.singleMesh.geometry.setAttribute('hidden', new THREE.Float32BufferAttribute(new Float32Array(this.singleMesh.geometry.attributes.expressID.array).map((_eid) => { return (eidsVisDict[_eid] ? 0.0 : 1.0) }), 1));

    this.singleMesh.geometry.attributes.hidden.needsUpdate = true;


    //setvisibility for edge if existing
    if (this.edgeBimshow) {

      this.edgeBimshow.setVisibilityByMesh(eidsVisDict)
    }


    if (this.directionalLightBimshow) {
      this.directionalLightBimshow.updateShadowMap();


    }
    this.askForRender();




  }

  async renderEqui() {



    const cubeRes = 2048;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.toneMapping = this.renderer.toneMapping;
    // renderer.toneMappingExposure = this.renderer.toneMappingExposure;
    // const renderer = this.renderer;

    const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(cubeRes, { format: THREE.RGBAFormat, magFilter: THREE.LinearFilter, minFilter: THREE.LinearFilter });

    var cubeCamera = new THREE.CubeCamera(this.camera.near, this.camera.far, cubeRenderTarget);
    cubeCamera.up = new THREE.Vector3(0, 0, 1);


    this.renderer.setSize(cubeRes, cubeRes);
    this.composer.setSize(cubeRes, cubeRes)

    // cubeCamera.position.copy(this.camera.position);


    //require for correct cameras init 
    if (cubeCamera.parent === null) cubeCamera.updateMatrixWorld();
    if (cubeCamera.coordinateSystem !== renderer.coordinateSystem) {

      cubeCamera.coordinateSystem = renderer.coordinateSystem;

      cubeCamera.updateCoordinateSystem();

    }

    // const [cameraPX, cameraNX, cameraPY, cameraNY, cameraPZ, cameraNZ] = cubeCamera.children;


    // const currentRenderTarget = renderer.getRenderTarget();
    // const currentActiveCubeFace = renderer.getActiveCubeFace();
    // const currentActiveMipmapLevel = renderer.getActiveMipmapLevel();

    // const currentXrEnabled = renderer.xr.enabled;

    // renderer.xr.enabled = false;

    // const generateMipmaps = renderTarget.texture.generateMipmaps;

    // renderTarget.texture.generateMipmaps = false;

    const reset = this.camera.clone();

    const images = [];
    for (let i = 0; i < cubeCamera.children.length; i++) {

      let camera = cubeCamera.children[i];

      this.camera.copy(camera)
      this.camera.position.copy(reset.position)
      this.camera.far = reset.far;
      this.camera.near = reset.near;
      this.camera.updateProjectionMatrix();
      this.camera.updateWorldMatrix();
      this.camera.updateMatrixWorld();
      this.saoPass.updateFromCameraMatrix();

      console.log(this.camera.fov)
      this.composer.passes[0].enabled = false;
      this.composer.passes[1].enabled = true;

      this.composer.setSize(cubeRes, cubeRes)

      this.composer.render();
      const img = this.renderer.domElement.toDataURL("image/webp", .9);
      images.push(
        img
      )


      // this.appActionsService.downloadFile(img,'composer'+i+'.jpg');
    }

    this.camera.copy(reset)
    this.camera.updateProjectionMatrix();
    this.camera.updateWorldMatrix();
    this.camera.updateMatrixWorld();


    this.onWindowResize();

    const order = [
      images[0],
      images[1],
      images[3],
      images[2],
      images[4],
      images[5]
    ]


    const loader = new THREE.TextureLoader();

    const maps = [

    ]

    const mats = await Promise.all(order.map(async pic => {
      return await new Promise(resolve => {
        loader.load(pic, (texture) => {

          texture.colorSpace = THREE.SRGBColorSpace;
          texture.minFilter = texture.magFilter = THREE.LinearFilter;
          texture.needsUpdate = true;
          console.log('resolved')
          resolve(new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide, transparent: false, toneMapped: false }))

        })

      });
    }))




    const box = new THREE.Mesh(
      new THREE.BoxGeometry(10000, 10000, 10000),
      mats
      // mats.map(m=>new THREE.MeshBasicMaterial({color:new THREE.Color(Math.random(),Math.random(),Math.random()), side:THREE.DoubleSide}))
      // new THREE.MeshBasicMaterial({color:'red',side:THREE.DoubleSide})
    )
    box.scale.set(-1, 1, 1)

    const scene = new THREE.Scene();
    scene.add(box)
    box.rotateZ(Math.PI / 2)
    // this.singleMesh.visible = false;
    // this.askForRender();  
    // setTimeout(() => {
    //   this.singleMesh.visible = true;
    //   this.scene.remove(box)
    //   this.askForRender();
    // }, 10000);







    // this.camera.position.set(0,0,0)

    scene.add(cubeCamera);

    renderer.render(scene, this.camera)





    var equiUnmanaged = new CubemapToEquirectangular(renderer, false);

    cubeCamera.update(renderer, scene)

    // call this to convert the cubemap rendertarget to a panorama
    const webp = equiUnmanaged.convert(cubeCamera);
    // this.appActionsService.downloadFile(webp, 'equi.webp')
    // this.appActionsService.downloadFile(webp, "equitest.webp")
    return webp;



  }

  async test() {

  }

  placeImageOnCamera(imageId) {

    const image = this.imageLayers.getObjectByName(imageId);
    const imageData = this.getImage(imageId);
    if (image && imageData) {
      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);

      const d = 1;
      const h = 0.9 * 2 * d * Math.tan((Math.PI / 180) * this.camera.fov / 2);
      const w = imageData.aspectRatio * h;

      image.scale.x = w;
      image.scale.y = h;


      image.position.copy(this.camera.position);
      image.position.add(cameraDirection.multiplyScalar(d))
      image.up.set(0, 0, 1);
      image.lookAt(this.camera.position)

      imageData.position = {
        x: image.position.x,
        y: image.position.y,
        z: image.position.z,
      }

      imageData.lookAt = {
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z,
      }

      // imageData.width = w;
      // imageData.height = h;

      imageData.postionOnCameraHeight = h
      imageData.postionOnCameraWidth = w



      this.dataService.updateImageDataInFirebase(imageData);
      this.askForRender();

    }
  }

  dataUrItoBlob(dataUri) {
    var binary = atob(dataUri.split(',')[1]);
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
  };


  async generateSequances() {
    const cameraTransitionsBeforeGeneration = this.dataService.loadedView.cameraTransitions;
    await this.dataService.resetPrerenderedData();
    this.recordings = {};
    this.labelsPositions = {};
    this.photospherePositions = {};

    this.recordingMode = true;
    const width = 1280;
    const height = 720;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    const menu = this.dataService.loadedView.menu;

    const photos = {};
    let transitions = [];


    const mapboxPhotos = {};
    const photosAsEquis = {};

    let addTransition = (photoId1, photoId2) => {
      if (!cameraTransitionsBeforeGeneration) {
        return;
      }
      const photo1 = this.dataService.photos[photoId1];
      const photo2 = this.dataService.photos[photoId2];
      if (photo1 && photo2) {
        if ((photo1.viewConfig.mapboxMode == 'off' || !photo1.viewConfig.mapboxMode) && (photo2.viewConfig.mapboxMode == 'off' || !photo2.viewConfig.mapboxMode)) {
          transitions.push(
            {
              from: photoId1,
              to: photoId2
            }
          )
        }
      }

    }



    let addVisibleLabelPhotos = (photo) => {
      if (photo.viewConfig.labelsConfig) {
        for (let labelKey in photo.viewConfig.labelsConfig) {
          const labelConfig = photo.viewConfig.labelsConfig[labelKey];
          if (labelConfig && labelConfig.visible) {
            const label = this.dataService.labels[labelKey];
            if (label && label.type === 'photo') {
              const labelPhoto = this.dataService.photos[label.linkedToId];
              if (labelPhoto) {

                photos[label.linkedToId] = true;

                if (labelPhoto.generateAsEquiForPrerendered) {
                  photosAsEquis[label.linkedToId] = true;
                }

                if (!photos[label.linkedToId]) { //making sure we dont endlessly turn in this recursive method
                  addVisibleLabelPhotos(labelPhoto)
                }

              }

            }
          }
        }
      }
    }


    //initial photo
    if (this.dataService.loadedView.initialPhoto && this.dataService.photos[this.dataService.loadedView.initialPhoto]) {
      photos[this.dataService.loadedView.initialPhoto] = true

    }
    if (menu) {
      //photos/labels still/equis
      for (let button of menu) {
        if (button.onClick && button.onClick.action == 'loadPhoto') {
          const mainButtonPhotoId = button.onClick.value;

          const buttonPhoto = this.dataService.photos[mainButtonPhotoId];

          if (buttonPhoto) {

            if (!(buttonPhoto.viewConfig.mapboxMode == 'off' || !buttonPhoto.viewConfig.mapboxMode)) {
              mapboxPhotos[mainButtonPhotoId] = true;

            } else {
              photos[mainButtonPhotoId] = true;

              if (buttonPhoto.generateAsEquiForPrerendered) {
                photosAsEquis[mainButtonPhotoId] = true;
              }

              addVisibleLabelPhotos(buttonPhoto)
            }


          }
        }

        if (button.children) {
          for (let child_btn of button.children) {
            if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto') {
              const childButtonPhotoId = child_btn.onClick.value;
              const childButtonPhoto = this.dataService.photos[childButtonPhotoId];

              if (childButtonPhoto) {
                if (!(childButtonPhoto.viewConfig.mapboxMode == 'off' || !childButtonPhoto.viewConfig.mapboxMode)) {
                  mapboxPhotos[childButtonPhotoId] = true;

                } else {
                  photos[childButtonPhotoId] = true;

                  if (childButtonPhoto.generateAsEquiForPrerendered) {
                    photosAsEquis[childButtonPhotoId] = true;
                  }

                  addVisibleLabelPhotos(childButtonPhoto)
                }


              }
            }

          }
        }
      }


      //transitions:
      if (this.dataService.loadedView.style == 'style3') {
        for (let button of menu) { //style 3 spectific transitions + force equi for labels on floorplan

          if (button.onClick && button.onClick.action == 'loadPhoto') {

            const mainButtonPhotoId = button.onClick.value;

            const buttonPhoto = this.dataService.photos[mainButtonPhotoId];



            if (button.children) {
              for (let child_btn of button.children) {
                if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto') {
                  const childButtonPhotoId = child_btn.onClick.value;


                  const childButtonPhoto = this.dataService.photos[childButtonPhotoId];

                  addTransition(mainButtonPhotoId, childButtonPhotoId)
                  addTransition(childButtonPhotoId, mainButtonPhotoId)


                  for (let other_child_btn of button.children) { //betwen child buttons transitions !! this cost n*n(-1) per parent menu ! (if eveny vid is ~2m this will be ~60mb for 6 childs buttons!)
                    const otherChildPhoto = this.dataService.photos[other_child_btn.onClick.value];
                    if (other_child_btn != child_btn && otherChildPhoto && other_child_btn.onClick && other_child_btn.onClick.action == 'loadPhoto') {
                      addTransition(childButtonPhotoId, other_child_btn.onClick.value)

                    }
                  }

                  //label load photos transitions for floorplan
                  const photo = childButtonPhoto;

                  if (child_btn.style3Type == 'floorplan') {
                    if (photo.viewConfig.labelsConfig) {
                      for (let labelKey in photo.viewConfig.labelsConfig) {
                        const labelConfig = photo.viewConfig.labelsConfig[labelKey];
                        if (labelConfig && labelConfig.visible) {
                          const label = this.dataService.labels[labelKey];
                          if (label && label.type === 'photo') {

                            photosAsEquis[mainButtonPhotoId] = true;
                            addTransition(childButtonPhotoId, label.linkedToId)
                            addTransition(label.linkedToId, childButtonPhotoId)

                            console.log('transition nadded for fp to label (2 way)', label.linkedToId)

                          }

                        }


                      }
                    }
                  }

                  if (this.dataService.loadedView.initialPhoto) { //from child back to initial photo
                    addTransition(
                      childButtonPhotoId, this.dataService.loadedView.initialPhoto
                    )

                  }




                }




              }


            }


            //with other menu buttons
            // for (let otherButton of menu) { //this is not required for style 3 !
            //   if (otherButton != button) {
            //     if (otherButton.onClick && otherButton.onClick.action == 'loadPhoto') {
            //       debugger;
            //       transitions.push({
            //         from: mainButtonPhotoId,
            //         to: otherButton.onClick.value
            //       })
            //     }

            //   }
            // }

            if (this.dataService.loadedView.initialPhoto) {

              //with homephoto
              addTransition(
                mainButtonPhotoId,
                this.dataService.loadedView.initialPhoto
              )

              addTransition(
                this.dataService.loadedView.initialPhoto,
                mainButtonPhotoId
              )

            }



          }
        }
      }


      if (this.dataService.loadedView.style == 'style1') {
        for (let button of menu) { //style 1 spectific transitions

          if (button.onClick && button.onClick.action == 'loadPhoto') {

            const mainButtonPhotoId = button.onClick.value;

            const buttonPhoto = this.dataService.photos[mainButtonPhotoId];

            if (button.children) {
              for (let child_btn of button.children) {
                if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto') {
                  const childButtonPhotoId = child_btn.onClick.value;


                  const childButtonPhoto = this.dataService.photos[childButtonPhotoId];

                  addTransition(
                    mainButtonPhotoId,
                    childButtonPhotoId
                  )

                  addTransition(
                    childButtonPhotoId,
                    mainButtonPhotoId
                  )



                  for (let other_child_btn of button.children) { //betwen child buttons transitions !! this cost n*n(-1) per parent menu ! (if eveny vid is ~2m this will be ~60mb for 6 childs buttons!)  **THINK IF NECESSERY?**
                    const otherChildPhoto = this.dataService.photos[other_child_btn.onClick.value];
                    if (other_child_btn != child_btn && otherChildPhoto && other_child_btn.onClick && other_child_btn.onClick.action == 'loadPhoto') {
                      addTransition(
                        childButtonPhotoId, other_child_btn.onClick.value
                      )

                    }
                  }

                  if (this.dataService.loadedView.initialPhoto) { //from child back to initial photo **THINK IF NECESSERY?**

                    addTransition(childButtonPhotoId, this.dataService.loadedView.initialPhoto)




                  }




                }




              }


            }


            // with other menu buttons
            for (let otherButton of menu) {
              if (otherButton != button) {
                if (otherButton.onClick && otherButton.onClick.action == 'loadPhoto') {
                  addTransition(mainButtonPhotoId, otherButton.onClick.value)
                }

              }
            }

            if (this.dataService.loadedView.initialPhoto) {

              //with homephoto

              addTransition(mainButtonPhotoId, this.dataService.loadedView.initialPhoto)
              addTransition(this.dataService.loadedView.initialPhoto, mainButtonPhotoId)

            }



          }
        }
      }

      if (this.dataService.loadedView.style == 'style2') {

        const flatButtonsPhotos = [];
        for (let btn of menu) {
          if (btn.onClick && btn.onClick.action == 'loadPhoto' && btn.onClick.value) {
            flatButtonsPhotos.push(btn.onClick.value)
          }
          if (btn.children) {
            for (let child_btn of btn.children) {
              if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto' && child_btn.onClick.value) {
                flatButtonsPhotos.push(child_btn.onClick.value)
              }
            }
          }
        }


        addTransition(
          this.dataService.loadedView.initialPhoto,
          [0]
        )
        addTransition(
          flatButtonsPhotos[flatButtonsPhotos.length - 1],
          flatButtonsPhotos[0]
        )
        addTransition(
          flatButtonsPhotos[0],
          flatButtonsPhotos[flatButtonsPhotos.length - 1]
        )


        for (let i = 1; i < flatButtonsPhotos.length; i++) {
          addTransition(
            flatButtonsPhotos[i - 1],
            flatButtonsPhotos[i]
          )
          addTransition(
            flatButtonsPhotos[i],
            flatButtonsPhotos[i - 1]
          )
        }
      }

    }






    let done = 0;
    let lastRunTime = 0;

    const equis = {};
    for (let transition of transitions) {

      const t0 = performance.now();
      // if (done > 3) { break };
      this.renderer.setSize(width, height);
      this.composer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      await this.generateTransitionBetweenTwoPhotos(transition.from, transition.to)





      done++
      lastRunTime = performance.now() - t0;
      const eta = Math.ceil((transitions.length - done) * lastRunTime / 1000);
      console.log('rendered video finished (' + done + '/' + transitions.length + ')');
      console.log('ETA: ' + eta + 'seconds');

      this.appActionsService.preRenderingInProcess.progress = done / transitions.length;


    }



    const finalFramePhotos = {};
    for (let photoId in photos) {




      this.renderer.setSize(width, height);
      this.composer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      await this.loadPhotoAndWaitForTransitionFinished(this.dataService.photos[photoId])
      this.updateDomRelatedElements();
      this.composer.render();
      this.labelsPositions[photoId] = JSON.parse(JSON.stringify(this.labels));
      this.photospherePositions[photoId] = JSON.parse(JSON.stringify(this.photosphereLabels))

      const img = this.renderer.domElement.toDataURL("image/webp", .9)
      finalFramePhotos[photoId] = img;
    }


    for (let equiPhotoId in photosAsEquis) {


      await this.loadPhotoAndWaitForTransitionFinished(this.dataService.photos[equiPhotoId])
      const equi = await this.renderEqui();
      equis[equiPhotoId] = equi;
    }


    const mapboxContainer = document.getElementById('mapboxContainer');
    mapboxContainer.style.width = width + 'px';
    mapboxContainer.style.height = height + 'px';
    for (let photoId in mapboxPhotos) {
      this.setMapboxMode('off');
      await waitSeconds(1)
      this.renderer.setSize(width, height);
      this.composer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      await this.loadPhotoAndWaitForTransitionFinished(this.dataService.photos[photoId])

      const imgData = await this.appActionsService.mapboxThreejsModel.takePhoto(true);
      finalFramePhotos[photoId] = imgData;



    }

    mapboxContainer.style.width = null;
    mapboxContainer.style.height = null;
    this.appActionsService.mapboxThreejsModel.map.resize();
    this.setMapboxMode('off');

    console.log(this.recordings)
    console.log(this.labelsPositions)

    this.recordingMode = false;


    const vids = {}

    this.appActionsService.preRenderingInProcess.step = 'finalizingVideos'
    this.appActionsService.preRenderingInProcess.progress = 0;


    for (let key in this.recordings) {
      if (this.recordings[key] && this.recordings[key].length > 0) {
        vids[key] = await this.writeWebm(this.recordings[key])
      }

    }



    this.appActionsService.preRenderingInProcess.step = 'uploading'

    const uploads = [];
    for (let key in vids) {
      uploads.push(this.dataService.uploadVideoOfProject(vids[key], key + '.webm').then(() => {
        console.log('video uploaded ' + key)
      }).catch(err => {
        console.warn('error uploading video ' + key)
      }));
    }

    for (let key in equis) {
      const blob = this.dataUrItoBlob(equis[key])
      uploads.push(this.dataService.uploadEquiPhotoOfProject(blob, key + '.webp').then(() => {
        console.log('equi uploaded ' + key)
      }).catch(err => {
        console.warn('error uploading videquieo ' + key, err)
      }));
    }


    // this.renderer.setPixelRatio(window.devicePixelRatio * 0.85);
    // this.askForRender();



    this.dataService.loadedView.cameraTransitions = cameraTransitionsBeforeGeneration;
    await Promise.all(uploads);
    console.log('uploads done.')

    await this.dataService.updateLightWeightPhotos(finalFramePhotos, height, width)
    await this.dataService.updateLabelPositionsOfPrerendered(this.labelsPositions)
    await this.dataService.updatePhotosphereLabelsPositionsOfPrerendered(this.photospherePositions)
    await this.dataService.updateLastSuccededPrerendered();

    this.appActionsService.preRenderingInProcess.step = 'finished';

    this.onWindowResize();
    this.askForRender();


  }

  async writeWebm(frames) {
    const fps = 30;
    let webmEncoder = new Whammy.Video(fps, 0.9);
    frames.forEach(f => webmEncoder.add(f));
    let blob = await new Promise(resolve => webmEncoder.compile(false, resolve));
    return blob

  }

  generateTransitionBetweenTwoPhotos(photoId1, photoId2) {
    return new Promise((resolve, reject) => {

      if (photoId1 == photoId2) {
        resolve('skipping same');
        return;
      }

      if (this.recordings[photoId1 + '.' + photoId2]) {
        console.log('skip, already done', photoId1 + '.' + photoId2);
        resolve('skipped');
        return;
      }

      if (this.recordings[photoId2 + '.' + photoId1]) {
        console.log('already recorded other direction, flipping and copying array')
        this.recordings[photoId1 + '.' + photoId2] = this.recordings[photoId2 + '.' + photoId1].toReversed()
        resolve('done');
        return;
      }





      this.dataService.loadedView.cameraTransitions = false;
      this.dataService.loadPhotoView(this.dataService.photos[photoId1])

      const sub1 = this.transitionFinished.subscribe(async () => {
        await waitSeconds(0.3)




        this.recordingTransition = null;
        sub1.unsubscribe();
        this.dataService.loadedView.cameraTransitions = true;




        this.recordingTransition = photoId1 + '.' + photoId2;
        this.dataService.loadPhotoView(this.dataService.photos[photoId2])
        console.log('starting ', this.recordingTransition)

        this.recordings[this.recordingTransition] = [];

        const sub2 = this.recordingTransitionFinished.subscribe(() => {
          // this.labelsPositions[photoId2] = JSON.parse(JSON.stringify(this.labels));
          // this.photospherePositions[photoId2] = JSON.parse(JSON.stringify(this.photosphereLabels))
          console.log('DONE!', this.recordingTransition)
          this.recordingTransition = null;
          sub2.unsubscribe()
          resolve('done')
        })


      })



    })




  }




  async generateThumbnailsForAssetLib() {
    // //for trees
    // const promises = [];
    // const ids = [];

    // for (let id in this.dataService.modelsLibrary) {

    //   const modelData = this.dataService.modelsLibrary[id];
    //   if (modelData.category === 'plants_outdoor' && modelData.id == 't71') {
    //     promises.push(this.dataService.getModel(id))
    //     ids.push(id);
    //   }

    //   // if (promises.length > 5) break;
    // }



    // const trees = await Promise.all(promises);



    // const renderer = new THREE.WebGLRenderer();
    // const scene = new THREE.Scene();


    // const person = await this.dataService.getModel('-NyqDrTgD2F5Qdo8ZFOC'); //person model fetch
    // const personMat = new THREE.MeshBasicMaterial({ color: '#9f9f9f' })
    // person.traverse(o => { //change material?
    //   if (o.material) {
    //     o.material = personMat;
    //   }
    // })
    // const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // scene.add(ambientLight);
    // scene.add(person)

    // for (let i = 0; i < trees.length; i++) {

    //   const id = ids[i];
    //   const tree = trees[i]
    //   if (!tree) { continue; }
    //   person.position.x = 0;
    //   person.position.z = 0;


    //   const box = new THREE.Box3();

    //   box.setFromObject(person)
    //   const personSize = new THREE.Vector3()
    //   const personCenter = new THREE.Vector3();
    //   box.getSize(personSize);
    //   box.getCenter(personCenter);


    //   const treeBox = new THREE.Box3().setFromObject(tree)

    //   person.position.x = treeBox.max.x - personCenter.x + personSize.x / 2;
    //   person.position.z = treeBox.min.z - personCenter.z + personSize.z / 2;






    //   scene.add(tree)
    //   box.setFromObject(scene);

    //   const size = new THREE.Vector3();
    //   box.getSize(size)
    //   const width = size.x;
    //   const height = size.z;


    //   // const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    //   const camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, 1, 1000);
    //   camera.up = new THREE.Vector3(0, 0, 1);
    //   const center = new THREE.Vector3()
    //   box.getCenter(center)
    //   camera.position.copy(center);
    //   camera.position.y = treeBox.min.y - 5;

    //   camera.lookAt(center);
    //   const maxPixel = 150;

    //   if (width > height) {
    //     renderer.setSize(maxPixel, maxPixel * height / width);
    //   } else {

    //     renderer.setSize(maxPixel * width / height, maxPixel);


    //   }



    //   renderer.render(scene, camera);
    //   const img = renderer.domElement.toDataURL();


    //   this.dataService.updateModelThumb(id, img).then(
    //     () => {
    //       console.log('IMG GENERATED FOR ' + id)
    //     }
    //   )
    //   scene.remove(tree)


    // }


    //for objects
    // const promises = [];
    // const ids = [];

    // for (let id in this.dataService.modelsLibrary) {

    //   const modelData = this.dataService.modelsLibrary[id];
    //   if (modelData.category === 'beds') {
    //     promises.push(this.dataService.getModel(id))
    //     ids.push(id);
    //   }

    //   // if (promises.length > 5) break;
    // }


    // console.log('waiting for downloads')
    // const objects = await Promise.all(promises);
    // console.log('start rendering')


    // const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    // const scene = new THREE.Scene();

    // const envmap = new THREE.TextureLoader().load("assets/images/defaultEnvMapWhite.jpg")
    // envmap.mapping = THREE.EquirectangularReflectionMapping;



    // const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // const dl = new THREE.DirectionalLight(0xffffff, 1);
    // dl.position.set(-1, -1, -1)
    // scene.add(ambientLight);
    // scene.add(dl);

    // for (let i = 0; i < objects.length; i++) {

    //   const id = ids[i];
    //   const object = objects[i]
    //   if (!object) { 
    //     this.dataService.deleteModel(id);
    //     continue; }

    //   object.traverse(o=>{
    //     let m_list: any[] = [];
    //     const mat = (o as THREE.Mesh).material
    //     if (mat) {
    //       if (Array.isArray(mat)) {
    //         m_list = mat;
    //       } else {
    //         m_list = [mat]
    //       }
    //     }

    //     m_list.forEach(m => {
    //       if (m.metalness) {
    //         m.envMap=envmap;
    //         m.needsUpdate = true;
    //       }
    //     })
    //   })
    //   const sphere = new THREE.Sphere()
    //   const box = new THREE.Box3();


    //   scene.add(object)
    //   box.setFromObject(scene);
    //   box.getBoundingSphere(sphere)
    //   const size = new THREE.Vector3();
    //   box.getSize(size)
    //   const width = size.x;
    //   const height = size.z;
    //   const max = Math.max(width, height)


    //   const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);

    //   camera.up = new THREE.Vector3(0, 0, 1);
    //   const center = new THREE.Vector3()
    //   box.getCenter(center)
    //   camera.position.copy(sphere.center);
    //   const arm = (sphere.radius * 1.5) / Math.tan(camera.fov * Math.PI / 180 / 2)
    //   const elevation = 30;
    //   camera.position.add(new THREE.Vector3(0,
    //     -Math.cos(elevation * Math.PI / 180) * arm
    //     ,
    //     Math.sin(elevation * Math.PI / 180) * arm))

    //   object.rotateZ(Math.PI / 4)




    //   camera.lookAt(center);
    //   const maxPixel = 150;


    //   renderer.setSize(maxPixel, maxPixel);






    //   renderer.render(scene, camera);
    //   const img = renderer.domElement.toDataURL();


    //   this.dataService.updateModelThumb(id, img).then(
    //     () => {
    //       console.log('IMG GENERATED FOR ' + id)
    //     }
    //   )
    //   scene.remove(object)
    // }



    //envmap texture

    let textureEquirec: any = await new Promise((resolve, reject) => {

      const textureLoader = new RGBELoader();
      textureLoader.load(
        'assets/data/debug/brown_photostudio_02_2k.hdr',
        (texture) => {

          resolve(texture);
        },
        (p) => {
          //progress
          console.log(p)
        },
        (error) => {
          console.warn(error)
          reject();
        })


    })
    textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
    textureEquirec.colorSpace = THREE.SRGBColorSpace;



    //for objects
    const promises = [];
    const ids = [];

    for (let id in this.dataService.modelsLibrary) {

      const modelData = this.dataService.modelsLibrary[id];
      if (!modelData.thumb) {
        console.log(id + ' no thumb')
        promises.push(this.dataService.getModel(id))
        ids.push(id);
      }


    }





    const objects = await Promise.all(promises);



    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    const scene = new THREE.Scene();






    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    const dl = new THREE.DirectionalLight(0xffffff, 1);
    dl.position.set(-1, -1, -1)
    // scene.add(ambientLight);
    // scene.add(dl);

    for (let i = 0; i < objects.length; i++) {

      const id = ids[i];
      const object = objects[i]
      if (!object) { continue; }
      const sphere = new THREE.Sphere()
      const box = new THREE.Box3();

      object.traverse(o => {
        let m_list: any[] = [];
        const mat = (o as THREE.Mesh).material
        if (mat) {
          if (Array.isArray(mat)) {
            m_list = mat;
          } else {
            m_list = [mat]
          }
        }

        m_list.forEach(m => {
          if (m) {
            console.log('updating')

            m.envMap = textureEquirec;
            m.needsUpdate = true;




          }
        })
      })

      scene.add(object)
      box.setFromObject(scene);
      box.getBoundingSphere(sphere)
      const size = new THREE.Vector3();
      box.getSize(size)
      const width = size.x;
      const height = size.z;
      const max = Math.max(width, height)


      const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);

      camera.up = new THREE.Vector3(0, 0, 1);
      const center = new THREE.Vector3()
      box.getCenter(center)
      camera.position.copy(sphere.center);
      const arm = (sphere.radius * 1.5) / Math.tan(camera.fov * Math.PI / 180 / 2)
      const elevation = 30;
      camera.position.add(new THREE.Vector3(0,
        -Math.cos(elevation * Math.PI / 180) * arm
        ,
        Math.sin(elevation * Math.PI / 180) * arm))

      object.rotateZ(Math.PI / 4)




      camera.lookAt(center);
      const maxPixel = 150;


      renderer.setSize(maxPixel, maxPixel);






      renderer.render(scene, camera);
      const img = renderer.domElement.toDataURL();

      console.log(img)
      this.dataService.updateModelThumb(id, img).then(
        () => {
          console.log('IMG GENERATED FOR ' + id)
        }
      )
      scene.remove(object)
    }












  }

  updateEnvmap() {

    if (!this.sceneNoProjectCubeCamera) return;
    this.sceneNoProjectCubeCamera.position.copy(this.camera.position)


    this.singleMesh.material.forEach(m => {
      m.envMap = null;
      m.needsUpdate = true;
    })

    Object.keys(this.modelsMaterials).forEach(key => {
      const m = this.modelsMaterials[key];
      m.envMap = null;
      m.needsUpdate = true;

    })

    this.sceneNoProjectCubeCamera.update(this.renderer, this.scene)
    this.singleMesh.material.forEach(m => {
      if (m.enableEnvMap) {
        m.envMap = this.sceneNoProjectCubeCameraRenderTarget.texture;
        m.needsUpdate = true;
      }

    })

    Object.keys(this.modelsMaterials).forEach(key => {
      const m = this.modelsMaterials[key];
      if (m.metalness) {

        m.envMap = this.sceneNoProjectCubeCameraRenderTarget.texture;
        m.needsUpdate = true;
      }

    })
  }


  updateAssetMaterials(update) {
    //todo: currently we are not reducing changes that are not relvent. for example if we change A -> B -> C , it will better just apply A -> C. although, we dont have a lot of computation for now, but if the model traverse is big, we will waste time for non relevent changes
    console.log(update)
    const model = this.models[update.modelId];

    if (!model) {
      return;
    }
    for (let changeKey in update.changes) {
      const change = update.changes[changeKey];
      //search the new material
      const newMaterialItem = this.dataService.materials.find(mi => (mi.material.name == change.to));
      const newMaterial = newMaterialItem ? newMaterialItem.material : null

      if (newMaterial) {

        model.traverse(o => {

          const mat = (o as THREE.Mesh).material
          if (mat) {
            if (Array.isArray(mat)) {
              for (let i = 0; i < mat.length; i++) {
                const m = mat[i]
                if (m.name === change.from) {
                  mat[i] = newMaterial;
                }
              }
            } else {
              if (mat.name === change.from) {
                (o as THREE.Mesh).material = newMaterial
              }

            }
          }
        })
      }

    }





    // model.traverse(o => {

    //   const mat = (o as THREE.Mesh).material
    //   if (mat) {
    //     if (Array.isArray(mat)) {
    //       for (let i = 0; i < mat.length; i++) {
    //         const m = mat[i]
    //         if (m === originalMaterial) {
    //           mat[i] = newMaterial;
    //         }
    //       }
    //     } else {
    //       (o as THREE.Mesh).material = newMaterial
    //     }
    //   }
    // })
  }

  async resetAssetModel(modelId) {
    let selectedModelId = null;
    if (this.transformControls.object) {
      selectedModelId = this.transformControls.object.userData.modelId
      this.appActionsService.selectedModelId.next()
    }
    this.scene.remove(this.models[modelId])
    delete this.models[modelId];

    await this.updateModelsFromData();

    if (selectedModelId) {
      this.appActionsService.selectedModelId.next(modelId)
    }
  }



  updateCustomObjectMaterial(update) {


    const guid = update.guid;
    const eid = this.GuidToEid[guid];

    if (!eid) {
      console.warn('updateCustomObjectMaterial skipping for object ' + update.guid + ' - object no longer existing')
      return;
    }
    const originalMaterialUUID = update.materialUuidToChange;
    const newMaterialUUID = update.newUuid;
    const originalMaterialIndex = this.singleMesh.material.findIndex(mat => {
      return mat.uuid === originalMaterialUUID //maybe should be name?... for ifcupdate or whatever
    })
    const newMaterialIndex = this.singleMesh.material.findIndex(mat => {
      return mat.uuid === newMaterialUUID
    })


    const originalGroupIndex = this.singleMesh.geometry.groups.findIndex(g => (g.materialIndex == originalMaterialIndex));
    if (originalGroupIndex == -1) { //for some reason material doesnt exist in singlemesh
      console.warn('updateCustomObjectMaterial skipping , cant update custom material for object as original material not existing')
      return;
    }

    let newGroupIndex = this.singleMesh.geometry.groups.findIndex(g => (g.materialIndex == newMaterialIndex));
    if (newGroupIndex == -1) {
      const lastGroup = this.singleMesh.geometry.groups[this.singleMesh.geometry.groups.length - 1];
      this.singleMesh.geometry.addGroup(lastGroup.start + lastGroup.count, 0, newMaterialIndex)
      newGroupIndex = this.singleMesh.geometry.groups.length - 1;
    }

    const originalGroup = this.singleMesh.geometry.groups[originalGroupIndex];
    const newGroup = this.singleMesh.geometry.groups[newGroupIndex]

    const indecesToMove = {}
    let totalIndicesChanged = 0;


    //fix index array and group array
    for (let i = originalGroup.start; i < originalGroup.start + originalGroup.count; i++) {

      const vertexNumber = this.singleMesh.geometry.index.array[i];

      if (this.singleMesh.geometry.attributes.expressID.array[vertexNumber] == eid) {
        indecesToMove[i] = true;
        totalIndicesChanged++
      }
    }


    const toPush = [];

    let newArray = Array.prototype.slice.call(this.singleMesh.geometry.index.array);

    newArray = newArray.map((element, index) => {
      if (indecesToMove[index]) {
        toPush.push(element)
        return -1;
      } else {
        return element
      }
    });

    //change to normal array as we cant splice on typed arrays
    newArray.splice(newGroup.start, 0, ...toPush)

    newArray = newArray.filter(e => (e !== -1)) // add and remove relevent elements

    newArray = new Uint32Array(newArray) //reconvert 

    for (let i = 0; i < newArray.length; i++) { //copy to original array
      this.singleMesh.geometry.index.array[i] = newArray[i]
    }



    for (let i = 0; i < this.singleMesh.geometry.groups.length; i++) {

      const group = this.singleMesh.geometry.groups[i]

      if (i == originalGroupIndex) {
        group.count -= totalIndicesChanged;

      }

      if (i == newGroupIndex) {
        group.count += totalIndicesChanged;

      }

      if (i > originalGroupIndex) {
        group.start -= totalIndicesChanged;
      }

      if (i > newGroupIndex) {
        group.start += totalIndicesChanged;
      }
    }




    this.singleMesh.geometry.index.needsUpdate = true;






  }

  ////////@mg format hour slider

  formatLabelMonthes(value: number) {
    let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return month[value];
  }
  formatLabelHours(value: number) {
    return value + ' h';
  }



  lockPlc() {


    this.plcControls.lock();


  }

  toggleDebuggerOnRender() {
    this.renderDebuggerOn = !this.renderDebuggerOn;
  }

  initPlcControls() {
    this.ngZone.runOutsideAngular(() => {
      this.plcControls = new PointerLockControls(
        this.camera,
        document.body
      );

    })

    this.plcControls.addEventListener("lock", () => {
      this.onPlcLock();
    });

    this.plcControls.addEventListener("unlock", () => {
      this.lastPLCExist = performance.now();
      this.appActionsService.showPointerLockOverlay = false;

      //@mg putback the frontside of zones materials
      for (let i = 0; i < this.zones.length; i++) {
        this.zones[i].material.side = 0;


      }


      this.onPlcUnlock();
    });

    this.plcControls.addEventListener("change", () => {
      this.appActionsService.hideAllLabelsAndPhotosphere = true;
      this.turnOffSSAA();
      this.askForRender();
    });

  }

  onPlcLock() {
    this.controls.autoRotate = false;
    this.appActionsService.stopRotating.next()
    this.moveForward = this.moveLeft = this.moveBack = this.moveRight = 0;
    this.controls.enabled = false;
    this.controls.update();
    this.plcOn = true;
    this.appActionsService.gameModeOn = true;


  }

  onPlcUnlock() {
    this.exitFromActivePhotosphere();

    this.currentContainedZone = null;

    let raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(new THREE.Vector2(0, 0), this.camera);
    let intersects = raycaster.intersectObjects(
      [this.projectColladaObject],
      true
    );
    let target = null;

    if (intersects.length > 0) {
      target = new THREE.Vector3(
        intersects[0].point.x,
        intersects[0].point.y,
        intersects[0].point.z
      );
    } else {
      let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);
      let d = Math.pow(
        Math.abs(
          (modelBox.max.x - modelBox.min.x) *
          (modelBox.max.y - modelBox.min.y) *
          (modelBox.max.z - modelBox.min.z)
        ),
        1 / 3
      );
      let v = new THREE.Vector3();
      this.camera.getWorldDirection(v);

      v.multiplyScalar(d).add(this.camera.position.clone());

      target = v;
    }


    if (this.blockZControls) {   // if blocked z we need to look back on horizon so we wont try to look on latest intersected object when existing the plc mode


      // //1st option -  this one is based on previous intersect logic (i prefer other way)
      // target.z -= this.camera.position.z + 0.001;


      // 2nd option - will allow a rotating option in a room. user can always zoom out on controls...
      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);


      cameraDirection.z = -0.0001;
      //in the most simple way we can just look forward on a fix distance, instead of raycasting the object. it will offer actually a better way to turn around the 

      let v = new THREE.Vector3();

      this.camera.getWorldDirection(v);


      target = cameraDirection.multiplyScalar(0.1).add(this.camera.position);


    }

    this.camera.lookAt(target);
    this.controls.target = target;

    this.plcOn = false; // should it be here ? on start of function?
    this.controls.enabled = true;

    this.controls.update();
    this.appActionsService.gameModeOn = false;


  }

  sendMessage() {
    this.eventStatusThree.emit(this.statusThree);
  }

  //materials list
  scanAllMaterials() {
    let scannedMaterials = [];
    let objectsUsingMaterials = {};

    for (let guid in this.objectsGuidQuery.threejs) {
      let child = this.objectsGuidQuery.threejs[guid];

      if (child.material) {
        if (this.zones.indexOf(child) == -1) {
          let oid = this.dataService.getObjectIdOfGuid(child.name); // push material / materials to objectsdata

          if (this.dataService.objectsData[oid] == null) {
            this.dataService.objectsData[oid] = {};
          }

          this.dataService.objectsData[oid].material = child.material;
          this.dataService.objectsData[oid].originalMaterial = child.material.slice();

          if (child.material.length > 0) {
            child.material.forEach((mat) => {
              if (scannedMaterials.indexOf(mat) == -1) {
                objectsUsingMaterials[mat.uuid] = [];
                scannedMaterials.push(mat);
              }

              objectsUsingMaterials[mat.uuid].push(child.name);
            });
          } else {
            if (scannedMaterials.indexOf(child.material) == -1) {
              objectsUsingMaterials[child.material.uuid] = [];

              scannedMaterials.push(child.material);
            }

            objectsUsingMaterials[child.material.uuid].push(child.name);
          }
        }
      }
    }

    scannedMaterials.forEach((material) => {

      material.side = THREE.DoubleSide;
      if (material.emissiveIntensity) { material.emissiveIntensity = 0 }
      if (material.opacity < 1) material.depthWrite = false
      material.toneMapped = false
      this.fixMaterialName(material);
      this.materialsList.push({
        material: material,
        usedByObjects: objectsUsingMaterials[material.uuid],
      });
    });

    this.dataService.materials = this.materialsList;
  }


  initCurrentSceneEnvMapNoProject() {
    if (this.sceneNoProjectCubeCameraRenderTarget == null) {
      this.sceneNoProjectCubeCameraRenderTarget = new THREE.WebGLCubeRenderTarget(128);
      this.sceneNoProjectCubeCameraRenderTarget.texture.mapping = THREE.CubeReflectionMapping;
      this.sceneNoProjectCubeCameraRenderTarget.texture.name = 'Scene'
    }

    // Create cube camera if needed
    if (this.sceneNoProjectCubeCamera == null) {
      this.sceneNoProjectCubeCamera = new THREE.CubeCamera(0.1, 1000, this.sceneNoProjectCubeCameraRenderTarget);
      this.sceneNoProjectCubeCamera.up = this.camera.up
      this.sceneNoProjectCubeCamera.position.set(0, 0, 0)
    }
    this.scene.add(this.sceneNoProjectCubeCamera)

  }


  fixMaterialName(material) {
    if (material.userData.name == null) {
      return;
    }
    let agrave = /\\X2\\00E0\\X0\\/gm; // à
    let aaigu = /\\X2\\00E2\\X0\\/gm; // à
    let egrave = /\\X2\\00E8\\X0\\/gm; // é
    let egraveM = /\\X2\\00C9\\X0\\/gm; // 'E'
    let eaigu = /\\X2\\00E9\\X0\\/gm; //é
    let ecircon = /\\X2\\00EA\\X0\\/gm; ///ê
    let ccedille = /\\X2\\00E7\\X0\\/gm; //ç
    let ocircon = /\\X2\\00F4\\X0\\/gm; //ô
    let ucircon = /\\X2\\00FB\\X0\\/gm; //û
    let multi = /\\X2\\00D7\\X0\\/gm; //x multiplier
    let icircon = /\\X2\\00EE\\X0\\/gm; //î
    let a0 = /\\X2\\00A0\\X0\\/gm; // espace
    let apostrophe = /\\X2\\2019\\X0\\/gm; // apostr
    let degr = /\\X2\\00B0\\X0\\/gm; // apostr

    material.userData.name = material.userData.name
      .replace(aaigu, "a")
      .replace(agrave, "à")
      .replace(egrave, "è")
      .replace(eaigu, "é")
      .replace(ecircon, "ê")
      .replace(ccedille, "ç")
      .replace(multi, "x")
      .replace(ocircon, "ô")
      .replace(icircon, "î")
      .replace(ucircon, "û")
      .replace(a0, "")
      .replace(egraveM, "E")
      .replace(apostrophe, "'")
      .replace(degr, "°");
    // return txt;
  }

  updateLabelsWorldPositions() {

    for (let key in this.labels) {
      if (this.projectColladaObject) {

        var vector = new THREE.Vector3();

        if (this.labels[key].position) { // backward compability as some times there is no position.
          vector.set(
            this.labels[key].position.x,
            this.labels[key].position.y,
            this.labels[key].position.z,
          )



        }


        //backward compability for old labels which where assigned to guid
        let object = this.objectsGuidQuery.threejs[this.labels[key].guid];
        if (object) {
          let box = new THREE.Box3().setFromObject(object);

          box.getCenter(vector);
        }

        //in case its photo, we want to have the photo's latest position instead of the position of the label  // @mg should add have the choice to
        if (this.labels[key].type == 'photo' && this.labels[key].usePhotoPosition) {
          const photo = this.dataService.photos[this.labels[key].linkedToId]
          if (photo) {
            vector.x = photo.viewConfig.cameraParameters.position.x;
            vector.y = photo.viewConfig.cameraParameters.position.y;
            vector.z = photo.viewConfig.cameraParameters.position.z;
          }


        }

        //if offset exist:
        if (this.labels[key].offset) {
          vector.x = vector.x + this.labels[key].offset.x;
          vector.y = vector.y + this.labels[key].offset.y;
          vector.z = vector.z + this.labels[key].offset.z;
        }

        this.labels[key].worldPosition = this.toSimpleVector(vector);
      }
    }
  }

  updateLabels() {
    for (let key in this.labels) {
      if (this.projectColladaObject) {
        if (this.labels[key].worldPosition && this.labels[key].visible) {
          const { x, y, notInView } = this.labelToScreenPosition(
            key,
            this.camera
          );
          this.labels[key].screenPosition = { x: x, y: y }
          this.labels[key].notInView = notInView;


        } else {
          this.labels[key].notInView = true;
          this.labels[key].screenPosition = {
            x: -1000,
            y: -1000,
          }
        }
      }



    }

    this.ngZone.runOutsideAngular(() => {

      this.debounceUpdateLabelsAndPhotosphereVis();
    })
  }

  updatePhotospheresLabels() {
    for (let id in this.photosphereLabels) {
      let showLabel = false;

      if (this.dataService.viewConfig.photospheresConfig != null) {
        const config = this.dataService.viewConfig.photospheresConfig[id];
        if (config) {
          const visible =
            this.dataService.viewConfig.photospheresConfig[id].visible;
          const ps = this.dataService.photospheres[id];

          if (ps) {
            if (!ps.showAlways && visible) {
              const screenPosition = this.photosphereToScreenPosition(
                this.photospheres.getObjectByName(id)
              );
              if (screenPosition) {
                this.photosphereLabels[id].screenPosition = screenPosition;
                showLabel = true;
              }
            }
          }
        }
      }

      if (showLabel == false) {
        this.photosphereLabels[id].screenPosition = null;
      }
    }


  }

  updateLabelsAndPhotosphereVis() {
    const camera = this.camera;
    for (let key in this.labels) {
      if (this.labels[key].worldPosition && this.labels[key].visible) {

        let cameraVector = new THREE.Vector3();
        let vector = new THREE.Vector3(this.labels[key].worldPosition.x, this.labels[key].worldPosition.y, this.labels[key].worldPosition.z);
        cameraVector.copy(vector);
        cameraVector.project(this.camera);


        this.raycasterLabels.setFromCamera(cameraVector, camera);

        let rayDistance = Math.abs(
          this.raycasterLabels.ray.origin.distanceTo(vector)
        );

        this.raycasterLabels.far = rayDistance - 0.01;
        let intersects = this.raycasterLabels.intersectObjects(
          [this.projectColladaObject],
          true
        )


        let blocked = false;
        //added for  label not to be hidden by clipping
        const intersect = intersects.find((i) => {

          if (!i.object.visible) {
            return false;
          }

          if (this.clippingOn) {

            const relativeToCubePosition = this.clippingCube.worldToLocal(i.point.clone());
            return (
              this.clippingCube.geometry.boundingBox.distanceToPoint(
                relativeToCubePosition
              ) == 0 && i.object.visible)
          } else {
            return i.object.visible;
          }

        });






        let obj = this.objectsGuidQuery.threejs[this.labels[key].guid];


        if (intersect) {
          if (!(this.labels[key].type == 'note' || this.labels[key].type == 'photo')) {
            obj = null;
          }
          if (intersect.object != obj) {
            blocked = true;
          }
        }

        this.labels[key].blocked = blocked;
      }
    }


    for (let id in this.photosphereLabels) {

      const photosphereMesh = this.photospheres.getObjectByName(id)

      let cameraVector = new THREE.Vector3();
      let vector = new THREE.Vector3();
      photosphereMesh.getWorldPosition(vector);
      cameraVector.copy(vector);
      cameraVector.project(this.camera);

      // let raycaster = new THREE.Raycaster(); //hiding if object center is blocked
      this.raycasterPhotospheres.setFromCamera(cameraVector, camera);

      let rayDistance = Math.abs(
        this.raycasterPhotospheres.ray.origin.distanceTo(vector)
      );

      this.raycasterPhotospheres.far = rayDistance + 1;

      let intersects = this.raycasterPhotospheres.intersectObjects(
        [this.projectColladaObject],
        true
      )


      const intersect = intersects.find((i) => {

        if (!i.object.visible) {
          return false;
        }

        if (this.clippingOn) {

          const relativeToCubePosition = this.clippingCube.worldToLocal(i.point.clone());
          return (
            this.clippingCube.geometry.boundingBox.distanceToPoint(
              relativeToCubePosition
            ) == 0 && i.object.visible)
        } else {
          return i.object.visible;
        }

      });


      let hidden = false;

      if (intersect) {
        if (intersect.distance < rayDistance - 0.001) {
          hidden = true;
        }
      }


      this.photosphereLabels[id].hidden = hidden;





    }
    this.appActionsService.hideAllLabelsAndPhotosphere = false;

  }

  generateObjectsList(object, list) {
    let name = object.name;
    if (name != null && name != "") {
      list[object.name] = object;
    }

    if (object.children.length > 0) {
      for (let i = 0; i < object.children.length; i++) {
        this.generateObjectsList(object.children[i], list);
      }
    }
  }

  resetClippingCube() {

    for (let i = 0; i < 24; i++) {
      this.setClppingCubePointByIndex(
        i,
        this.clippingCube.resetData.vectors[i]
      );
    }

    this.clippingCube.zRotationAngle = 0;

    this.clippingCube.position.copy(this.clippingCube.resetData.position);

    this.updateClippingCube();

    this.updateClippingPlanesFromClippingCube();
  }

  vectorsToBufferVertices(vectors: any[]) {
    const forBuffer = [];
    for (let v of vectors) {
      forBuffer.push(v.x, v.y, v.z);
    }

    return new Float32Array(forBuffer);
  }

  initClippingCube() {
    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

    var cc = {
      o: modelBox.min.clone().add(new THREE.Vector3(-0.1, -0.1, -0.1)),
      a: new THREE.Vector3(modelBox.max.x - modelBox.min.x + 0.2, 0, 0),
      b: new THREE.Vector3(0, modelBox.max.y - modelBox.min.y + 0.2, 0),
      c: new THREE.Vector3(0, 0, modelBox.max.z - modelBox.min.z + 0.2),
    };

    const vectors = [];

    vectors.push(
      new THREE.Vector3(), //0
      cc.a, //1
      cc.b, //2
      cc.a.clone().add(cc.b), //3
      cc.c, //4
      cc.a.clone().add(cc.c), //5
      cc.b.clone().add(cc.c), //6
      cc.a.clone().add(cc.b).add(cc.c), //7

      new THREE.Vector3(), //0
      cc.a, //1
      cc.b, //2
      cc.a.clone().add(cc.b), //3
      cc.c, //4
      cc.a.clone().add(cc.c), //5
      cc.b.clone().add(cc.c), //6
      cc.a.clone().add(cc.b).add(cc.c), //7

      new THREE.Vector3(), //0
      cc.a, //1
      cc.b, //2
      cc.a.clone().add(cc.b), //3
      cc.c, //4
      cc.a.clone().add(cc.c), //5
      cc.b.clone().add(cc.c), //6
      cc.a.clone().add(cc.b).add(cc.c) //7
    );

    const clippingCubeGeometry = new THREE.BufferGeometry();

    clippingCubeGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(this.vectorsToBufferVertices(vectors), 3)
    );
    clippingCubeGeometry.setIndex([
      1,
      0,
      2, //zdown
      3,
      1,
      2, //zdown
      4,
      5,
      6, //zup
      5,
      7,
      6, //zup
      2 + 8,
      0 + 8,
      4 + 8, //xdown
      6 + 8,
      2 + 8,
      4 + 8, //xdown
      1 + 8,
      3 + 8,
      5 + 8, //xup
      3 + 8,
      7 + 8,
      5 + 8, //xup
      0 + 16,
      1 + 16,
      5 + 16, //ydown
      0 + 16,
      5 + 16,
      4 + 16, //ydown
      3 + 16,
      2 + 16,
      7 + 16, //yup
      7 + 16,
      2 + 16,
      6 + 16, //yup
    ]);

    clippingCubeGeometry.addGroup(0, 6, 0);
    clippingCubeGeometry.addGroup(6, 6, 1);
    clippingCubeGeometry.addGroup(12, 6, 2);
    clippingCubeGeometry.addGroup(18, 6, 3);
    clippingCubeGeometry.addGroup(24, 6, 4);
    clippingCubeGeometry.addGroup(30, 6, 5);

    clippingCubeGeometry.position = modelBox.min;

    clippingCubeGeometry.computeBoundingBox();

    clippingCubeGeometry.computeVertexNormals();

    const materials = [];
    for (let i = 0; i < 6; i++) {
      materials.push(
        new THREE.MeshPhongMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.2,
          depthWrite: false,
        })
      );
    }

    clippingCubeGeometry.setAttribute('hidden', new THREE.Float32BufferAttribute(new Float32Array(vectors).map(() => 1), 1));

    this.clippingCube = new THREE.Mesh(clippingCubeGeometry, materials);
    this.clippingCube.position.copy(cc.o);

    this.clippingCube.resetData = {
      position: this.clippingCube.position.clone(),
      vectors: vectors,
    };

    this.clippingCube.zRotationAngle = 0;

    this.scene.add(this.clippingCube);

    this.clippingCube.visible = false;

    this.clippingPlanes.push(
      new THREE.Plane(new THREE.Vector3(0, 0, -1), -100000), //zdown
      new THREE.Plane(new THREE.Vector3(0, 0, 1), 100000), //zup
      new THREE.Plane(new THREE.Vector3(0, -1, 0), -100000), //xdown
      new THREE.Plane(new THREE.Vector3(0, 1, 0), 100000), //xup
      new THREE.Plane(new THREE.Vector3(-1, 0, 0), -100000), //ydown
      new THREE.Plane(new THREE.Vector3(1, 0, 0), 100000), //yup
    );

    this.clippingPlanes[0].refCubeIndex = 0;
    this.clippingPlanes[1].refCubeIndex = 4;
    this.clippingPlanes[2].refCubeIndex = 10;
    this.clippingPlanes[3].refCubeIndex = 9;
    this.clippingPlanes[4].refCubeIndex = 16;
    this.clippingPlanes[5].refCubeIndex = 19;

    this.updateClippingPlanesFromClippingCube();

    //adding rotateguide sphere an dline

    this.clippingCube.rotateCubeSphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 16, 16),
      new THREE.MeshPhongMaterial({ color: 0xff0000 })
    );
    var material = new THREE.LineBasicMaterial({
      color: 0x0000ff,
    });
    var points = [];
    points.push(new THREE.Vector3(0, 0, -1000));
    points.push(new THREE.Vector3(0, 0, 1000));
    var geometry = new THREE.BufferGeometry().setFromPoints(points);
    var line = new THREE.Line(geometry, material);
    this.clippingCube.rotateCubeSphere.add(line);

    var material = new THREE.LineBasicMaterial({
      color: 0x00ff00,
    });

    material.color.set(this.accentColor);
    var points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(0, 0, 0));
    var geometry = new THREE.BufferGeometry().setFromPoints(points);
    this.clippingCube.rotateCubeSphere.centerToIntersectLine = new THREE.Line(
      geometry,
      material
    );
    this.clippingCube.rotateCubeSphere.add(
      this.clippingCube.rotateCubeSphere.centerToIntersectLine
    );

    this.scene.add(this.clippingCube.rotateCubeSphere);

    this.clippingCube.rotateCubeSphere.material.color.set(this.accentColor);
    line.material.color.set(this.accentColor);

    let center = new THREE.Vector3(0, 0, 0);

    center.add(vectors[4]).add(vectors[5]).add(vectors[6]).add(vectors[7]);

    this.clippingCube.rotateCubeSphere.position.copy(center);
    this.turnRotateSphereOff();

    this.updateClippingCube();
  }

  turnClippingOn() {
    //we set clipping planes incase of..


    if (this.saoPass) {
      this.saoPass.normalMaterial.clippingPlanes = this.clippingPlanes;
    }
    this.transparentGrayMaterial.clippingPlanes = this.clippingPlanes;


    this.materialsList.forEach((materialItem) => {
      materialItem.material.clippingPlanes = this.clippingPlanes;
      materialItem.material.clipShadows = true;
    });

    if (this.edgeBimshow) {
      this.edgeBimshow.setClippingPlanes(this.clippingPlanes)
    }

    for (let zone of this.zones) {
      zone.material.clippingPlanes = this.clippingPlanes;

    }

    this.renderer.localClippingEnabled = true;
    this.clippingOn = true;

    if (this.clippingStencilsOn) {
      this.turnClippingStencilsOn();
    }
  }



  turnClippingOff() {
    //we set clipping planes incase of..
    this.transparentGrayMaterial.clippingPlanes = this.clippingPlanes;
    this.materialsList.forEach((materialItem) => {
      materialItem.material.clippingPlanes = this.clippingPlanes;
    });

    if (this.edgeBimshow) {
      this.edgeBimshow.setClippingPlanes(null)
    }

    for (let zone of this.zones) {
      zone.material.clippingPlanes = this.clippingPlanes;
      //refresh edge if needed
    }

    this.renderer.localClippingEnabled = false;
    this.clippingOn = false;

    this.cleanStencils();

  }

  turnClippingStencilsOn() {
    // this.dataService.photoAmbientOcclusion.next(false)
    this.clippingStencilsOn = true;
    this.generateStencils();
    this.askForRender();


  }

  turnClippingStencilsOff() {
    this.clippingStencilsOn = false;
    this.cleanStencils();
    this.askForRender();
  }

  clippingPlanesIntersecter() {
    if (this.clippingModeState != null) {
      return;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObject(this.clippingCube, false);

    if (intersects.length > 0) {
      if (this.intersectedPlane.index !== intersects[0].face.materialIndex) {
        if (this.intersectedPlane.material) {
          this.intersectedPlane.material.color.set(0xffffff);
          this.intersectedPlane.material.opacity = 0.2;
        }

        if (this.intersectedPlane.index != intersects[0].face.materialIndex) {
          this.intersectedPlane.material =
            intersects[0].object.material[intersects[0].face.materialIndex];
          this.intersectedPlane.material.color.set(this.primaryColor);
          this.intersectedPlane.material.opacity = 0.7;
          this.intersectedPlane.normal = intersects[0].face.normal;
          this.intersectedPlane.index = intersects[0].face.materialIndex;
          this.askForRender();
        }

      }
    } else {
      if (this.intersectedPlane.material) {
        this.intersectedPlane.material.color.set(0xffffff);
        this.intersectedPlane.material.opacity = 0.2;
        this.askForRender();
      }
      this.intersectedPlane.material = null;
      this.intersectedPlane.normal = null;
      this.intersectedPlane.index = null;
    }
  }

  onMouseMoveClippingPlaneHandler(event) {
    if (this.selectedMode != "clippingPlanes") {
      return;
    }

    if (this.intersectedPlane.index !== null) {
      if (event.ctrlKey) {
        if (this.mousePressedButtons[0]) {
          this.clippingModeState = "draggingCube";

          this.translateCubeOnDrag();

          this.updateClippingPlanesFromClippingCube();
          return;
        }

        if (this.mousePressedButtons[2]) {
          this.clippingModeState = "rotatingCube";

          this.turnRotateSphereOn();
          this.rotateCubeOnDrag(event);

          this.updateClippingPlanesFromClippingCube();
          return;
        }
      } else {
        if (this.mousePressedButtons[0]) {
          this.clippingModeState = "draggingPlane";
          this.translatePlaneOnDrag();

          this.updateClippingPlanesFromClippingCube();
          return;
        }
      }
    }
  }

  rotateCubeOnDrag(event) {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.guidingPlaneZ.constant = -this.planeDragStartingPoint.z;
    let intersect = this.raycaster.ray.intersectPlane(
      this.guidingPlaneZ,
      new THREE.Vector3()
    );

    if (intersect) {
      let center = new THREE.Vector3(0, 0, 0);
      for (let i = 4; i < 8; i++) {
        center
          .add(this.getClippingCubePointByIndex(i))
          .add(this.clippingCube.position);
      }

      center.multiplyScalar(1 / 4);
      center.z = this.planeDragStartingPoint.z;
      // this.clippingCube.rotateCubeSphere.position.z = center.z;

      let v1 = new THREE.Vector3().subVectors(
        this.planeDragStartingPoint.clone(),
        center
      );
      let v2 = new THREE.Vector3().subVectors(intersect.clone(), center);

      this.updateRotateSphereCenterToIntersectLine(v2.x, v2.y);

      if (v2.length() < 2 || event.shiftKey) {
        //reacts with magnet logic inside keydown event
        this.planeDragStartingPoint = intersect;
        this.updateRotateSphereCenterToIntersectLine(0, 0);
        return;
      }


      v1.normalize();
      v2.normalize();

      let dot = v1.dot(v2);
      if (dot < -1) {
        dot = -1;
      }

      if (dot > 1) {
        dot = 1;
      }

      let angle = (180 * Math.acos(dot)) / Math.PI;
      let cross = new THREE.Vector3().copy(v1).cross(v2);

      if (cross.dot(this.guidingPlaneZ.normal.clone()) < 0) {
        // Or > 0

        angle = -angle;
      }

      this.rotateClippingCube(angle);

      this.planeDragStartingPoint = intersect;
    } else {
      //controll rotation by mouse event movement (left/right = decrease/increase)
      this.updateRotateSphereCenterToIntersectLine(0, 0);
      let angle = event.movementX * 0.4;

      this.rotateClippingCube(-angle);
    }
  }

  translateCubeOnDrag() {


    if (this.intersectedPlane.index === null) {
      return;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const normal = this.intersectedPlane.normal.clone();
    const clippingPlane = this.getClippingPlaneFromMaterialIndex(
      this.intersectedPlane.index
    );


    let intersect = this.raycaster.ray.intersectPlane(
      clippingPlane,
      new THREE.Vector3()
    );

    if (intersect) {
      let delta = new THREE.Vector3().subVectors(
        intersect,
        this.planeDragStartingPoint
      );

      delta.add(normal.multiplyScalar(this.clippingPlaneConstantOffset));
      this.translateClippingCube(delta);

      this.planeDragStartingPoint.add(delta);
    }
  }

  translatePlaneOnDrag() {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    let delta = new THREE.Vector3(0, 0, 0);

    var intersect1 = this.raycaster.ray.intersectPlane(
      this.guidingPlane,
      new THREE.Vector3()
    );
    var intersect2 = this.raycaster.ray.intersectPlane(
      this.guidingPlane2,
      new THREE.Vector3()
    );

    let d1 = intersect1
      ? intersect1.distanceTo(this.camera.position)
      : Infinity;
    let d2 = intersect2
      ? intersect2.distanceTo(this.camera.position)
      : Infinity;

    let intersect = intersect1;

    if (d2 < d1) {

      intersect = intersect2;
    }



    if (intersect) {
      const isZPlane =
        this.intersectedPlane.index === 0 || this.intersectedPlane.index === 1;


      if (isZPlane) {
        delta.z = intersect.z - this.planeDragStartingPoint.z;
      } else {
        // delta.x = intersect.x - this.planeDragStartingPoint.x;
        // delta.y = intersect.y - this.planeDragStartingPoint.y;

        delta.copy(this.intersectedPlane.normal);
        delta.multiplyScalar(this.intersectedPlane.normal.dot(intersect.clone().sub(this.planeDragStartingPoint)))


      }
    }

    const indeces = this.getClippingCubePointsIndexFromMaterialIndex(
      this.intersectedPlane.index
    );

    const newPositions = {};

    for (let index of indeces) {
      const old = this.getClippingCubePointByIndex(index);

      newPositions[index] = new THREE.Vector3().copy(old).add(delta);
    }

    //DETECT FLIPPING
    const oldMax = this.getClippingCubePointByIndex(7);
    const oldMin = this.getClippingCubePointByIndex(0);

    const oldSigns = {
      x: Math.sign(oldMax.x - oldMin.x),
      y: Math.sign(oldMax.y - oldMin.y),
      z: Math.sign(oldMax.z - oldMin.z),
    };

    if (newPositions[0]) {
      // a down plane is moved
      const newMin = newPositions[0];
      const newSigns = {
        x: Math.sign(oldMax.x - newMin.x),
        y: Math.sign(oldMax.y - newMin.y),
        z: Math.sign(oldMax.z - newMin.z),
      };

      if (
        oldSigns.x != newSigns.x ||
        oldSigns.y != newSigns.y ||
        oldSigns.z != newSigns.z
      ) {
        // console.log("flipped min");
        return;
      }
    }

    if (newPositions[7]) {
      //up plane is moved
      const newMax = newPositions[7];
      const newSigns = {
        x: Math.sign(newMax.x - oldMin.x),
        y: Math.sign(newMax.y - oldMin.y),
        z: Math.sign(newMax.z - oldMin.z),
      };

      if (
        oldSigns.x != newSigns.x ||
        oldSigns.y != newSigns.y ||
        oldSigns.z != newSigns.z
      ) {
        // console.log("flipped max");
        return;
      }
    }

    for (let i in newPositions) {
      this.setClppingCubePointByIndex(i, newPositions[i]);
    }

    this.planeDragStartingPoint.add(delta);
  }

  getClippingCubePointsIndexFromMaterialIndex(i) {
    const indeces = [];

    if (i === null) {
      return [];
    }

    if (i == 0) {
      indeces.push(
        0,
        1,
        2,
        3,
        0 + 8,
        1 + 8,
        2 + 8,
        3 + 8,
        0 + 16,
        1 + 16,
        2 + 16,
        3 + 16
      );
    }

    if (i == 1) {
      indeces.push(
        4,
        5,
        7,
        6,
        4 + 8,
        5 + 8,
        7 + 8,
        6 + 8,
        4 + 16,
        5 + 16,
        7 + 16,
        6 + 16
      );
    }

    if (i == 2) {
      indeces.push(
        0,
        2,
        4,
        6,
        0 + 8,
        2 + 8,
        4 + 8,
        6 + 8,
        0 + 16,
        2 + 16,
        4 + 16,
        6 + 16
      );
    }

    if (i == 3) {
      indeces.push(
        1,
        3,
        5,
        7,
        1 + 8,
        3 + 8,
        5 + 8,
        7 + 8,
        1 + 16,
        3 + 16,
        5 + 16,
        7 + 16
      );
    }

    if (i == 4) {
      indeces.push(
        0,
        1,
        4,
        5,
        0 + 8,
        1 + 8,
        4 + 8,
        5 + 8,
        0 + 16,
        1 + 16,
        4 + 16,
        5 + 16
      );
    }

    if (i == 5) {
      indeces.push(
        2,
        3,
        6,
        7,
        2 + 8,
        3 + 8,
        6 + 8,
        7 + 8,
        2 + 16,
        3 + 16,
        6 + 16,
        7 + 16
      );
    }

    return indeces;
  }

  getClippingCubePointByIndex(i) {
    const array = this.clippingCube.geometry.attributes.position.array;
    return new THREE.Vector3(array[3 * i], array[3 * i + 1], array[3 * i + 2]);
  }

  getClippingCubeNormalByIndex(i) {
    const array = this.clippingCube.geometry.attributes.normal.array;
    return new THREE.Vector3(array[3 * i], array[3 * i + 1], array[3 * i + 2]);
  }

  setClppingCubePointByIndex(index, vector) {
    const array = this.clippingCube.geometry.attributes.position.array;
    // this.clippingCube.geometry.index.setXYZ(index, vector.x, vector.y, vector.z);
    array[3 * index] = vector.x;
    array[3 * index + 1] = vector.y;
    array[3 * index + 2] = vector.z;

    this.updateClippingCube();
  }

  getClippingPlaneFromMaterialIndex(i) {
    if (i !== null) {
      return this.clippingPlanes[i];
    }

    return null;
  }

  updateClippingCube() {
    this.clippingCube.geometry.attributes.position.needsUpdate = true;

    this.clippingCube.geometry.computeBoundingBox();
    this.clippingCube.geometry.computeBoundingSphere();
    this.clippingCube.geometry.computeVertexNormals();

    this.clippingCube.updateMatrix();
    this.clippingCube.updateMatrixWorld();
    if (this.directionalLightBimshow) {
      this.directionalLightBimshow.updateShadowMap();
    }
  }

  updateClippingPlanesFromClippingCube() {
    for (let cp of this.clippingPlanes) {
      const index = cp.refCubeIndex;

      let point = this.getClippingCubePointByIndex(index);
      let normal = this.getClippingCubeNormalByIndex(index);
      cp.normal = normal.clone().multiplyScalar(-1);
      cp.constant = point.add(this.clippingCube.position).dot(normal); //set constant
      cp.constant -= this.clippingPlaneConstantOffset; //offset constant abit inside the cube
    }

    if (this.directionalLightBimshow) {
      this.directionalLightBimshow.updateShadowMap();
    }

    this.turnOffSSAA();
    this.askForRender();


  }

  turnRotateSphereOn() {
    this.updateRotateSpherePosition();
    this.clippingCube.rotateCubeSphere.visible = true;
  }

  turnRotateSphereOff() {
    this.clippingCube.rotateCubeSphere.visible = false;

    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[3] = 0;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[4] = 0;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.needsUpdate =
      true;
  }

  updateRotateSphereCenterToIntersectLine(x, y) {
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[3] =
      x;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[4] =
      y;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.needsUpdate =
      true;
  }

  updateRotateSpherePosition() {
    const center = this.clippingCube.geometry.boundingBox.min
      .clone()
      .add(this.clippingCube.geometry.boundingBox.max)
      .multiplyScalar(0.5);

    this.clippingCube.rotateCubeSphere.position.copy(
      center.add(this.clippingCube.position)
    );
  }

  setClippingCubeRotation(angle) {
    angle = angle % 360;
    if (angle < 0) {
      angle = angle + 360;
    }

    const center = this.clippingCube.geometry.boundingBox.min
      .clone()
      .add(this.clippingCube.geometry.boundingBox.max)
      .multiplyScalar(0.5);

    for (let i = 0; i < 24; i++) {
      this.setClppingCubePointByIndex(
        i,
        this.getClippingCubePointByIndex(i).addScaledVector(center, -1)
      );
    }

    this.clippingCube.geometry.rotateZ(
      (-this.clippingCube.zRotationAngle * Math.PI) / 180
    );

    this.clippingCube.geometry.rotateZ((angle * Math.PI) / 180);

    this.clippingCube.zRotationAngle = angle;

    for (let i = 0; i < 24; i++) {
      this.setClppingCubePointByIndex(
        i,
        this.getClippingCubePointByIndex(i).addScaledVector(center, 1)
      );
    }

    this.updateClippingCube();
    this.updateClippingPlanesFromClippingCube();
  }

  rotateClippingCube(angle) {
    this.setClippingCubeRotation(this.clippingCube.zRotationAngle + angle);
  }

  translateClippingCube(vector) {
    this.clippingCube.position.add(vector);
    this.updateClippingCube();
    this.updateClippingPlanesFromClippingCube();
  }

  saveClippingToConfig() {
    let vertices = [];
    for (let i = 0; i < 8; i++) {
      vertices.push(this.toSimpleVector(this.getClippingCubePointByIndex(i)));
    }

    this.dataService.viewConfig.clippingMode = {
      vertices: vertices,
      rotation: this.clippingCube.zRotationAngle,
      position: this.toSimpleVector(this.clippingCube.position),
      clippingOn: this.clippingOn,
      stencilsOn: this.clippingStencilsOn
    };
  }

  loadClippingConfigImmediate(config) {

    if (config.clippingOn) {
      this.turnClippingOn();
    } else {
      this.turnClippingOff();
    }

    if (config.stencilsOn == true) {
      this.turnClippingStencilsOn();
    } else {
      this.turnClippingStencilsOff();
    }

    let finalPositions: Array<{ x: number, y: number, z: number }> | null = null;
    if (config.clippingOn) {
      if (config.vertices) {
        finalPositions = config.vertices.concat(
          config.vertices,
          config.vertices
        );
      }
    } else {
      finalPositions = this.clippingCube.resetData.vectors;
    }

    if (finalPositions) {

      const currentPositions = finalPositions.map((value, index) => {
        return {
          x: value.x,
          y: value.y,
          z: value.z
        }
      })

      for (let i = 0; i < currentPositions.length; i++) {
        this.setClppingCubePointByIndex(i, currentPositions[i]);
      }
    }

    if (config.rotation) {
      this.clippingCube.zRotationAngle = config.rotation;
    }

    if (config.position) {
      this.clippingCube.position.set(
        config.position.x,
        config.position.y,
        config.position.z
      );
    }
    this.updateClippingPlanesFromClippingCube();
  }

  loadClippingConfig(config, transitionTime = 0) {
    if (transitionTime == 0) {
      this.loadClippingConfigImmediate(config);
      return;
    }

    this.animationsManager.cancelAnaimation("clippingCubeTransform")
    if (!this.clippingOn) {
      this.resetClippingCube();
    }

    if (config.clippingOn) {
      this.turnClippingOn();
    }

    if (config.stencilsOn == true) {
      this.turnClippingStencilsOn();
    } else {
      this.turnClippingStencilsOff();
    }

    const startingPositions: Array<{ x: number, y: number, z: number }> = [];
    for (let i = 0; i < this.clippingCube.geometry.attributes.position.array.length; i++) {
      if (i % 3 == 0) {
        startingPositions.push({
          x: this.clippingCube.geometry.attributes.position.array[i],
          y: this.clippingCube.geometry.attributes.position.array[i + 1],
          z: this.clippingCube.geometry.attributes.position.array[i + 2]
        })
      }
    }



    let finalPositions: Array<{ x: number, y: number, z: number }> | null = null;
    if (config.clippingOn) {
      if (config.vertices) {
        finalPositions = config.vertices.concat(
          config.vertices,
          config.vertices
        );
      }
    } else {
      finalPositions = this.clippingCube.resetData.vectors;
    }


    let startingRotation = this.clippingCube.zRotationAngle;
    let starrtingPosition = this.clippingCube.position.clone();




    this.animationsManager.addAnimation(
      Math.max(1, Math.ceil(60 * transitionTime)),
      (a) => {

        const alpha = InOutQuadBlend(a);


        if (finalPositions) {

          const currentPositions = finalPositions.map((value, index) => {
            return {
              x: value.x * alpha + (1 - alpha) * startingPositions[index].x,
              y: value.y * alpha + (1 - alpha) * startingPositions[index].y,
              z: value.z * alpha + (1 - alpha) * startingPositions[index].z
            }
          })

          for (let i = 0; i < currentPositions.length; i++) {
            this.setClppingCubePointByIndex(i, currentPositions[i]);
          }
        }

        if (config.rotation) {
          this.clippingCube.zRotationAngle = startingRotation * (1 - alpha) + alpha * config.rotation;
        }

        if (config.position) {
          this.clippingCube.position.set(
            (1 - alpha) * starrtingPosition.x + alpha * config.position.x,
            (1 - alpha) * starrtingPosition.y + alpha * config.position.y,
            (1 - alpha) * starrtingPosition.z + alpha * config.position.z
          );
        }
        this.updateClippingPlanesFromClippingCube();


      },
      (isCanceled) => {
        if (isCanceled) {
          return;
        }

        if (!config.clippingOn) {
          this.turnClippingOff();
        }

      },
      "clippingCubeTransform", true)










  }

  async animateFunction(framerate, time, callback: Function) {

    const frame = 0;

    return new Promise((resolve, reject) => {
      if (!callback) {
        return;
      }
      const totalFrames = time * 1 * framerate;
      let frame = 0;


      const ivl = setInterval(() => {
        try {
          const alpha = frame / totalFrames;
          callback(alpha)
          frame++;


          if (frame > totalFrames) {
            clearInterval(ivl);
            callback(1)
            resolve(null);
          }
        } catch (err) {

          clearInterval(ivl);
          callback(1)
          reject();
        }

      }, time / framerate)
    })

  }

  async gameModeClicked(withJoysticks = false) {


    if (!withJoysticks) {
      if (performance.now() - this.lastPLCExist < 1000) {
        console.log('waiting/...')
        await waitSeconds(1)
        console.log('done')
      }
      this.lockPlc();
      //@mg to fix display zones on gamemode due to FrontSide material is not casted
      for (let i = 0; i < this.zones.length; i++) {
        this.zones[i].material.side = 2;


      }
    } else {
      this.updateLastCameraEuler();
      this.joysticksOn = true;
      this.appActionsService.gameModeOn = true;

    }
  }

  onGameModeTouchStart(event?) {

    this.appActionsService.stopRotating.next()
    if (event) {
      event.stopPropagation();
    }
    if (!this.joysticksGenerated) {
      this.initJoysticks();
    }

    this.gameModeClicked(true)



  }

  walkModeClicked() {
    this.walkMode = !this.walkMode;
  }
  initJoysticks() {

    this.leftJoystick = new VirtualJoystick({
      container: this.mobileFpsControls.nativeElement,
      strokeStyle: this.accentColor,
      limitStickTravel: true,
      stickRadius: 120,
    });

    this.leftJoystick.addEventListener(
      "touchStartValidation",
      function (event) {
        var touch = event.changedTouches[0];
        if (touch.pageX >= window.innerWidth / 2) return false;
        return true;
      }
    );

    this.rightJoystick = new VirtualJoystick({
      container: this.mobileFpsControls.nativeElement,
      strokeStyle: this.accentColor,
      limitStickTravel: true,
      stickRadius: 120,
    });
    this.rightJoystick.addEventListener(
      "touchStartValidation",
      function (event) {
        var touch = event.changedTouches[0];
        if (touch.pageX < window.innerWidth / 2) return false;


        return true;
      }
    );
    this.rightJoystick.addEventListener("touchStart", () => {
      this.updateLastCameraEuler();
    });

    this.rightJoystick.addEventListener("touchEnd", (event) => {
      const touch = event.changedTouches[0];
      this.updateLastCameraEuler()
    });

    this.joysticksGenerated = true;
  }

  closeJoysticksClicked() {
    this.joysticksOn = false;
    this.appActionsService.gameModeOn = false;

    this.exitFromActivePhotosphere();
  }

  updateLastCameraEuler() {
    const euler = new THREE.Euler(0, 0, 0, "ZYX");
    const camera_q = new THREE.Quaternion();
    this.camera.getWorldQuaternion(camera_q);
    euler.setFromQuaternion(camera_q)
    this.lastCameraEuler.x = euler.x;
    this.lastCameraEuler.z = euler.z;
  }

  initSAO() {
    let composer = new EffectComposer(this.renderer);
    this.composer = composer;
    this.composer.stencilBuffer = true;
    this.composer.renderTarget1.stencilBuffer = true;
    this.composer.renderTarget2.stencilBuffer = true;

    let width = document.getElementById("app-content-container").clientWidth;
    let height = document.getElementById("app-content-container").clientHeight;

    this.composer.setSize(width, height);

    let renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);
    renderPass.enabled = false;

    let ssaaRenderPass = new SSAARenderPass(
      this.scene,
      this.camera,
      0xaaaaaa,
      0
    );


    ssaaRenderPass.unbiased = true
    ssaaRenderPass.clearColor = 0xaaaaaa;//to avoid getClearColor error
    this.composer.addPass(ssaaRenderPass);


    const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), this.scene, this.camera);
    composer.addPass(outlinePass);
    outlinePass.selectedObjects = [];
    this.outlinePass = outlinePass;
    const depthMaterialOriginalFunction = this.outlinePass.depthMaterial.onBeforeCompile;
    outlinePass.depthMaterial.onBeforeCompile = (shader) => {
      if (depthMaterialOriginalFunction) { depthMaterialOriginalFunction(shader) };
      discardHiddenOnBeforeCompileFunc(shader);
    };
    outlinePass.depthMaterial.clippingPlanes = this.clippingPlanes

    let saoPass = new SAOPass(this.scene, this.camera, false, true);
    this.saoPass = saoPass;

    saoPass.transparent = true; //@mg ne pas predre en compte le background
    saoPass.clearColor = 0xaaaaaa;
    saoPass.renderToScreen = true;
    const normalMaterialOriginalFunction = this.saoPass.normalMaterial.onBeforeCompile;
    saoPass.normalMaterial.onBeforeCompile = (shader) => {
      if (normalMaterialOriginalFunction) { normalMaterialOriginalFunction(shader) };
      discardHiddenOnBeforeCompileFunc(shader);
    };

    saoPass.normalMaterial.side = THREE.DoubleSide;

    saoPass.resolution.set(256, 256);



    composer.addPass(saoPass);

    const outputPass = new OutputPass();
    composer.addPass(outputPass);




    for (let param in this.saoParams) {
      this.saoPass.params[param] = this.saoParams[param];
    }




    // Init gui
    this.debugGUI = new dat.GUI({ autoPlace: false });
    this.domRenderer.appendChild(
      this.guiContainer.nativeElement,
      this.debugGUI.domElement
    );


    this.debugGUI
      .add(this.saoPass.params, "output", {
        Default: SAOPass.OUTPUT.Default,
        SAO: SAOPass.OUTPUT.SAO,
        Normal: SAOPass.OUTPUT.Normal,
      })
      .onChange((value) => {
        this.saoPass.params.output = parseInt(value);
      });



    this.debugGUI.add(this.saoPass.params, "saoBias", -1, 1).onChange(() => { this.askForRender() });
    this.debugGUI
      .add(this.saoPass.params, "saoIntensity", 0, 1)
      .step(0.01).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoScale", 10000, 100000).step(2000).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoKernelRadius", 1, 100).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoMinResolution", 0, 1).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoBlur").onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoBlurRadius", 0, 50).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoBlurStdDev", 0.5, 150).onChange(() => { this.askForRender() });
    this.debugGUI.add(this.saoPass.params, "saoBlurDepthCutoff", 0.0, 0.000001).step(0.00000001).onChange(() => { this.askForRender() });



  }



  generateCollisionObjectsArray() {
    let noCollideGuids = this.dataService.getAllDoorsAndWindows();
    for (let zone of this.zones) {
      noCollideGuids.push(zone.name);
    }

    for (let guid in this.objectsGuidQuery.threejs) {
      let object = this.objectsGuidQuery.threejs[guid];
      if (noCollideGuids.indexOf(guid) == -1) {
        this.collidableObjects.push(object);
      }
    }
  }

  turnOffSSAA() {

    if (!this.composer) {
      return;
    }
    this.composer.passes[0].enabled = true;
    this.composer.passes[1].enabled = false;

    this.ngZone.runOutsideAngular(() => {
      this.debounceSSAAOn();
    })

  }

  turnOnSSAA() {
    if (!this.composer) {
      return;
    }
    this.composer.passes[0].enabled = false;
    this.composer.passes[1].enabled = true;
    this.askForRender();

  }

  updatePhotoSpheres() {

    //update or add
    for (let id in this.dataService.photospheres) {
      const photosphere = this.dataService.photospheres[id];
      let mesh = this.photospheres.getObjectByName(id);

      if (mesh == null) {

        this.createPhotospere(photosphere);
      } else {
        this.updatePhotosphere(mesh, photosphere);
      }
    }

    //remove
    const ids = Object.keys(this.dataService.photospheres);

    for (let mesh of this.photospheres.children) {
      if (ids.indexOf(mesh.name) == -1) {
        this.photospheres.remove(mesh);
      }
    }
  }

  updatePhotosphere(mesh, properties) {
    mesh.material.opacity = properties.opacity;
    if (properties.opacity < 1) {
      mesh.material.depthWrite = false;
    }
    else {
      mesh.material.depthWrite = true;
    }
    mesh.position.set(
      properties.position.x,
      properties.position.y,
      properties.position.z,

    );
    mesh.scale.set(properties.radius, properties.radius, properties.radius);

    mesh.setRotationFromEuler(
      new THREE.Euler(Math.PI / 2, properties.yaw * Math.PI / 180, 0, "XYZ")
    );

    if (this.dataService.viewConfig.photospheresConfig[mesh.name]) {
      //make sure visible for showAlways mesh if its on on config.

      mesh.visible =
        Boolean(properties.showAlways) &&
        Boolean(
          this.dataService.viewConfig.photospheresConfig[mesh.name].visible
        );

    } else {
      mesh.visible = false;

    }
    mesh.material.needsUpdate = true;

  }
  createPhotospere(photosphere) {
    const mat = new THREE.MeshBasicMaterial();
    mat.opacity = 1;
    //mat.transparent = true;
    mat.visible = false;
    mat.transparent = true;

    const mesh = new THREE.Mesh(this.photosphereGeometry, mat);
    mesh.name = photosphere.id;
    mesh.photosphereName = photosphere.name;
    // mesh.rotateX(Math.PI / 2);
    // mesh.up = this.camera.up;
    mesh.visible = false;
    mesh.userData.ambientOcclusion = true;
    this.photospheres.add(mesh);
    this.updatePhotosphere(mesh, photosphere);

    this.dataService
      .getPhotosphereImageUrl(photosphere.storageName)
      .then((textureUrl) => {


        new THREE.TextureLoader().load(
          textureUrl,
          (texture, textureData) => {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            mat.map = texture;
            mat.visible = true;
            texture.needsUpdate = true;
            mat.needsUpdate = true;
            this.askForRender();
          });


      });
  }

  changeCameraImmediate(position, target) {

    if (position && target) {
      this.camera.position.set(position.x, position.y, position.z);
      this.camera.lookAt(target);
      this.controls.target = target;
      this.controls.update();
    }



  }


  async onPhotosphereClicked(id) {
    const mesh = this.photospheres.getObjectByName(id);

    if (mesh) {
      this.beforePhotosphereCameraProperties = {
        position: this.camera.position.clone(),
        target: this.controls.target.clone(),
      };
      const pos = new THREE.Vector3();
      mesh.getWorldPosition(pos);
      const target = pos.clone().add(new THREE.Vector3(0, 1, 0));

      if (
        this.dataService.loadedView &&
        this.dataService.loadedView.cameraTransitions) {
        await this.transitionToNewCamera({
          position: pos,
          target: target
        })
      } else {
        this.changeCameraImmediate(pos, target);
      }
      this.activePhotosphere = id;
      this.askForRender()
      if (this.appActionsService.mouseDetected) {
        this.gameModeClicked(!this.appActionsService.mouseDetected);
      } else {
        this.onGameModeTouchStart()
      }

      mesh.visible = true;

    }
  }



  exitFromActivePhotosphere() {
    if (this.activePhotosphere) {
      const mesh = this.photospheres.getObjectByName(this.activePhotosphere);
      mesh.visible = false;
      this.activePhotosphere = null;
      if (
        this.dataService.loadedView &&
        this.dataService.loadedView.cameraTransitions) {
        this.transitionToNewCamera(this.beforePhotosphereCameraProperties);
      } else {
        this.changeCameraImmediate(this.beforePhotosphereCameraProperties.position, this.beforePhotosphereCameraProperties.target);
      }

    }
  }

  colorZonesFromConfig(zonesColors) {

    for (let change of zonesColors) {
      if (this.zonesColors[change.guid]) {
        this.zonesColors[change.guid].color = new THREE.Color(change.color);
      }
    }
    this.updateColorsInSingleMesh();

    for (let change of zonesColors) {
      if (this.zonesLabels[change.guid]) {
        this.zonesLabels[change.guid].color = change.color;
      }

      const zoneMesh = this.objectsGuidQuery.threejs[change.guid];

      if (zoneMesh) {
        if (this.selectedObject == zoneMesh) {
          this.selectedObject["lastUsedMaterial"].color = new THREE.Color(
            change.color
          );
        } else {
          zoneMesh.material.color = new THREE.Color(change.color);
        }
      }
    }
  }

  setFocalLength(value) {
    this.camera.setFocalLength(value);
  }

  generateHomePhoto() {
    this.placeCameraOnFullProjectView();

    const newViewConfig = JSON.parse(JSON.stringify(this.dataService.viewConfig));
    delete newViewConfig.mapboxMode;
    delete newViewConfig.selectedMode;


    newViewConfig.cameraParameters = {
      position: this.toSimpleVector(this.camera.position),
      fov: this.camera.fov,
      photoAmbientOcclusion: this.photoAmbientOcclusion,
      ambientLightIntensity: this.ambientLight.intensity,
      target: this.toSimpleVector(this.controls.target),
      directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
      edgeOptions: this.edgeBimshow.getOptions()
    };




    let date = new Date();
    this.render();



    return {
      title: this.translate.instant('viewEditor.InitialPhoto'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: this.renderer.domElement.toDataURL(),
      viewConfig: newViewConfig,
      wizardGenerated: true
    }
  }


  generateSitesPhotos() {
    this.placeCameraOnFullProjectView();
    const cameraParameters = {
      position: this.toSimpleVector(this.camera.position),
      fov: 75,
      photoAmbientOcclusion: this.photoAmbientOcclusion,
      ambientLightIntensity: this.ambientLight.intensity,
      target: this.toSimpleVector(this.controls.target),
      directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
      edgeOptions: this.edgeBimshow.getOptions()
    };

    const closePhotosDistance = this.camera.position.distanceTo(this.controls.target);

    const closeViewConfig = JSON.parse(JSON.stringify(this.dataService.viewConfig));

    closeViewConfig.cameraParameters = cameraParameters;
    closeViewConfig.mapboxMode = "sat";
    closeViewConfig.selectedMode = "placeOnMapMode";

    const farViewConfig = JSON.parse(JSON.stringify(closeViewConfig));

    const farDistanceInMeters = 80;
    farViewConfig.cameraParameters.position.x = farViewConfig.cameraParameters.target.x;
    farViewConfig.cameraParameters.position.y = farViewConfig.cameraParameters.target.y - 1;
    farViewConfig.cameraParameters.position.z = farViewConfig.cameraParameters.target.z + farDistanceInMeters;
    let date = new Date();
    const photoClose = {
      title: this.translate.instant('wizard.viewGenerator.siteClose'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/map.png',
      viewConfig: closeViewConfig,
      wizardGenerated: true
    }


    const photoFar = {
      title: this.translate.instant('wizard.viewGenerator.siteFar'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/map.png',
      viewConfig: farViewConfig,
      wizardGenerated: true
    }

    const northViewConfig = JSON.parse(JSON.stringify(closeViewConfig));
    delete northViewConfig.mapboxMode;
    delete northViewConfig.selectedMode;
    northViewConfig.cameraParameters.fov = 24;

    const sideViewPhotoDistance = closePhotosDistance * 2;

    northViewConfig.cameraParameters.position.x = farViewConfig.cameraParameters.target.x;
    northViewConfig.cameraParameters.position.y = farViewConfig.cameraParameters.target.y + sideViewPhotoDistance;
    northViewConfig.cameraParameters.position.z = farViewConfig.cameraParameters.target.z;
    const photoNorth = {
      title: this.translate.instant('wizard.viewGenerator.photoSideNorth'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/facades.png',
      viewConfig: northViewConfig
    }

    const southViewConfig = JSON.parse(JSON.stringify(northViewConfig));
    const westViewConfig = JSON.parse(JSON.stringify(northViewConfig));
    const eastViewConfig = JSON.parse(JSON.stringify(northViewConfig));

    southViewConfig.cameraParameters.position.x = northViewConfig.cameraParameters.target.x;
    southViewConfig.cameraParameters.position.y = northViewConfig.cameraParameters.target.y - sideViewPhotoDistance;
    southViewConfig.cameraParameters.position.z = northViewConfig.cameraParameters.target.z;

    westViewConfig.cameraParameters.position.x = northViewConfig.cameraParameters.target.x - sideViewPhotoDistance;
    westViewConfig.cameraParameters.position.y = northViewConfig.cameraParameters.target.y;
    westViewConfig.cameraParameters.position.z = northViewConfig.cameraParameters.target.z;


    eastViewConfig.cameraParameters.position.x = northViewConfig.cameraParameters.target.x + sideViewPhotoDistance;
    eastViewConfig.cameraParameters.position.y = northViewConfig.cameraParameters.target.y;
    eastViewConfig.cameraParameters.position.z = northViewConfig.cameraParameters.target.z;

    const photoSouth = {
      title: this.translate.instant('wizard.viewGenerator.photoSideSouth'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/facades.png',
      viewConfig: southViewConfig,
      wizardGenerated: true
    }

    const photoEast = {
      title: this.translate.instant('wizard.viewGenerator.photoSideEast'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/facades.png',
      viewConfig: eastViewConfig,
      wizardGenerated: true
    }

    const photoWest = {
      title: this.translate.instant('wizard.viewGenerator.photoSideWest'),
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/facades.png',
      viewConfig: westViewConfig,
      wizardGenerated: true
    }

    return { photoClose, photoFar, photoNorth, photoSouth, photoEast, photoWest };

  }

  generateWhitePhotos() {
    this.placeCameraOnFullProjectView();
    const cameraParameters = {
      position: this.toSimpleVector(this.camera.position),
      fov: 75,
      photoAmbientOcclusion: this.photoAmbientOcclusion,
      ambientLightIntensity: this.ambientLight.intensity,
      target: this.toSimpleVector(this.controls.target),
      directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
      edgeOptions: this.edgeBimshow.getOptions()
    };

    const closePhotosDistance = this.camera.position.distanceTo(this.controls.target);

    const closeViewConfig = JSON.parse(JSON.stringify(this.dataService.viewConfig));

    closeViewConfig.cameraParameters = cameraParameters;

    const farViewConfig = JSON.parse(JSON.stringify(closeViewConfig));

    const farDistanceInMeters = 15;
    farViewConfig.cameraParameters.position.x = farViewConfig.cameraParameters.target.x;
    farViewConfig.cameraParameters.position.y = farViewConfig.cameraParameters.target.y - 1;
    farViewConfig.cameraParameters.position.z = farViewConfig.cameraParameters.target.z + farDistanceInMeters;
    let date = new Date();

    const north1ViewConfig = JSON.parse(JSON.stringify(closeViewConfig));

    north1ViewConfig.cameraParameters.fov = 80;

    const sideViewPhotoDistance = closePhotosDistance * 2;

    north1ViewConfig.cameraParameters.position.x = farViewConfig.cameraParameters.target.x;
    north1ViewConfig.cameraParameters.position.y = farViewConfig.cameraParameters.target.y + sideViewPhotoDistance;
    north1ViewConfig.cameraParameters.position.z = farViewConfig.cameraParameters.target.z + 16;
    const photo1 = {
      title: "WHITE 1",
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/autophoto1.png',
      viewConfig: north1ViewConfig,
      wizardGenerated: true
    }

    const south1ViewConfig = JSON.parse(JSON.stringify(north1ViewConfig));
    const west1ViewConfig = JSON.parse(JSON.stringify(north1ViewConfig));
    const east1ViewConfig = JSON.parse(JSON.stringify(north1ViewConfig));

    south1ViewConfig.cameraParameters.position.x = north1ViewConfig.cameraParameters.target.x - 10;
    south1ViewConfig.cameraParameters.position.y = north1ViewConfig.cameraParameters.target.y - sideViewPhotoDistance;
    south1ViewConfig.cameraParameters.position.z = north1ViewConfig.cameraParameters.target.z + 20;

    west1ViewConfig.cameraParameters.position.x = north1ViewConfig.cameraParameters.target.x - sideViewPhotoDistance + 10;
    west1ViewConfig.cameraParameters.position.y = north1ViewConfig.cameraParameters.target.y;
    west1ViewConfig.cameraParameters.position.z = north1ViewConfig.cameraParameters.target.z + 12;

    east1ViewConfig.cameraParameters.position.x = north1ViewConfig.cameraParameters.target.x + sideViewPhotoDistance;
    east1ViewConfig.cameraParameters.position.y = north1ViewConfig.cameraParameters.target.y;
    east1ViewConfig.cameraParameters.position.z = north1ViewConfig.cameraParameters.target.z + 25;

    const photo2 = {
      title: "WHITE 2",
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/autophoto1.png',
      viewConfig: south1ViewConfig,
      wizardGenerated: true
    }

    const photo3 = {
      title: "WHITE 3 ",
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/autophoto1.png',
      viewConfig: east1ViewConfig,
      wizardGenerated: true
    }

    const photo4 = {
      title: "WHITE 4",
      description: 'description',
      createdBy: this.dataService.user.id,
      dateTaken: date.toString(),
      thumbData: 'assets/images/icons/autophoto1.png',
      viewConfig: west1ViewConfig,
      wizardGenerated: true
    }

    return { photo1, photo2, photo3, photo4 };

  }

  generateSpacePhoto(guid) {

    const spaceObject = this.objectsGuidQuery.threejs[guid];

    if (spaceObject) {
      const box = new THREE.Box3().setFromObject(spaceObject);
      const target = new THREE.Vector3();

      box.getCenter(target);

      const position = target.clone();
      position.y -= 0.1;

      //todo: change position to be better point..

      const cameraParameters = {
        position: this.toSimpleVector(position),
        fov: 90,
        photoAmbientOcclusion: this.photoAmbientOcclusion,
        ambientLightIntensity: this.ambientLight.intensity,
        target: this.toSimpleVector(target),
        directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
        edgeOptions: this.edgeBimshow.getOptions()
      };

      const newViewConfig = JSON.parse(JSON.stringify(this.dataService.viewConfig));
      delete newViewConfig.mapboxMode;
      delete newViewConfig.selectedMode;

      newViewConfig.cameraParameters = cameraParameters;

      let date = new Date();
      const newPhoto = {
        title: 'title',
        description: 'description',
        createdBy: this.dataService.user.id,
        dateTaken: date.toString(),
        thumbData: 'assets/images/icons/zone.png',
        viewConfig: newViewConfig,
        wizardGenerated: true
      }
      return newPhoto;
    }
    return null
  }

  generateFloorPhoto(floorGuid) {

    const floorObject = this.objectsGuidQuery.threejs[floorGuid];

    if (floorObject) {

      let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

      let d = modelBox.min.distanceTo(modelBox.max) / 2;

      let modelCenter = new THREE.Vector3();
      modelBox.getCenter(modelCenter);

      const cameraParameters = {
        position: this.toSimpleVector(modelCenter.clone().add(new THREE.Vector3(0, -0.1 * d, 0.9 * d))),
        fov: 70,
        photoAmbientOcclusion: this.photoAmbientOcclusion,
        ambientLightIntensity: this.ambientLight.intensity,
        target: this.toSimpleVector(modelCenter),
        directionalLightOptions: JSON.parse(JSON.stringify(this.appActionsService.directionalLightOptions)),
        edgeOptions: this.edgeBimshow.getOptions()
      };

      const newViewConfig = JSON.parse(JSON.stringify(this.dataService.viewConfig));
      delete newViewConfig.mapboxMode;
      delete newViewConfig.selectedMode;

      newViewConfig.cameraParameters = cameraParameters;

      let date = new Date();
      const newPhoto = {
        title: this.translate.instant('wizard.viewGenerator.floors'),
        description: 'description',
        createdBy: this.dataService.user.id,
        dateTaken: date.toString(),
        thumbData: 'assets/images/icons/autophoto1.png',
        viewConfig: newViewConfig,
        wizardGenerated: true
      }

      return newPhoto;
    }

  }

  createPlaneStencilGroup(meshes, plane, renderOrder) {

    const group = new THREE.Group();
    const baseMat = new THREE.MeshBasicMaterial();
    baseMat.depthWrite = false;
    baseMat.depthTest = false;
    baseMat.colorWrite = false;
    baseMat.stencilWrite = true;
    baseMat.stencilFunc = THREE.AlwaysStencilFunc;

    // back faces
    const mat0 = baseMat.clone();

    mat0.side = THREE.BackSide;
    mat0.clippingPlanes = [plane];
    mat0.stencilFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZPass = THREE.IncrementWrapStencilOp;
    mat0.onBeforeCompile = discardHiddenOnBeforeCompileFunc;

    // front faces
    const mat1 = baseMat.clone();

    mat1.side = THREE.FrontSide;

    mat1.clippingPlanes = [plane];

    mat1.stencilFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZPass = THREE.DecrementWrapStencilOp;
    mat1.onBeforeCompile = discardHiddenOnBeforeCompileFunc;
    // TODO: allow filtering by ifc types / hide specific object need to be implemented with singlemesh
    // const allowIfcTypes = ['IfcWall', 'IfcStandardWallCase', 'IfcSlab', 'IfcBeam']
    // const excludeIfcTypes = ['IfcBuildingStorey', 'IfcProject', 'IfcAnnotation'] 


    for (let mesh of meshes) {



      // if (allowIfcTypes.indexOf(mesh.userData.ifcType) != -1 || true) {
      const geometry = mesh.geometry;
      // console.log('%c IFCTYPE', 'background:red', mesh.userData.ifcType,geometry)
      const mesh0 = new THREE.Mesh(geometry, mat0);
      mesh.getWorldQuaternion(mesh0.quaternion)
      mesh.getWorldPosition(mesh0.position)
      mesh0.renderOrder = renderOrder;
      group.add(mesh0);
      mesh0.userData.guid = mesh.name;
      mesh0.visible = mesh.visible;

      const mesh1 = new THREE.Mesh(geometry, mat1);
      mesh.getWorldQuaternion(mesh1.quaternion)
      mesh.getWorldPosition(mesh1.position)
      mesh1.renderOrder = renderOrder;
      group.add(mesh1);
      mesh1.userData.guid = mesh.name;
      mesh1.visible = mesh.visible;

      if (this.stencils.guidDict[mesh.name]) {
        this.stencils.guidDict[mesh.name].push(mesh0, mesh1)
      } else {
        this.stencils.guidDict[mesh.name] = []
      }



    }



    return group;

  }


  cleanStencils() {
    ///cleanup

    this.scene.remove(this.stencils.stencilsObject)
    this.scene.remove(this.stencils.poGroup);
    this.scene.remove(this.stencils.stencilsObject);

    this.stencils = {};
    this.renderer.clearStencil();

  }
  generateStencils() {
    {

      this.cleanStencils();

      //init
      this.stencils.guidDict = {}
      this.stencils.planes = this.clippingPlanes;

      this.stencils.stencilsObject = new THREE.Group();
      this.scene.add(this.stencils.stencilsObject);

      // Set up clip plane rendering
      this.stencils.planeObjects = [];

      const d = Math.max(100, this.getProjectFullSizeScale())
      const planeGeom = new THREE.PlaneGeometry(3 * d, 3 * d);
      this.stencils.poGroup = new THREE.Group();

      for (let i = 0; i < 6; i++) {


        const plane = this.stencils.planes[i];
        // const stencilGroup = this.createPlaneStencilGroup(this.projectColladaObject.children.filter(p => !p.isZone), plane, i + 1);
        const stencilGroup = this.createPlaneStencilGroup([this.singleMesh], plane, i + 1);

        // plane is clipped by the other clipping planes


        const po = new THREE.Mesh(planeGeom, this.planeMat.clone());
        po.userData.ambientOcclusion = true;
        po.material.clippingPlanes = this.stencils.planes.filter(p => p !== plane);
        po.customNormalMaterial = this.planeNormalMat.clone();
        po.customNormalMaterial.blending = 0;
        po.customNormalMaterial.clippingPlanes = this.stencils.planes.filter(p => p !== plane);
        po.onAfterRender = function (renderer) {
          renderer.clearStencil();

        };

        po.renderOrder = i + 1.1;

        this.stencils.stencilsObject.add(stencilGroup);
        this.stencils.poGroup.add(po);
        this.stencils.planeObjects.push(po);
        this.scene.add(this.stencils.poGroup);

      }


    }
  }



  generateUVsAndComputeNormals() {
    for (let guid in this.objectsGuidQuery.threejs) {
      const mesh = this.objectsGuidQuery.threejs[guid];
      if (mesh.geometry) {

        mesh.geometry.computeVertexNormals();
        const bufferGeometry = mesh.geometry

        //calculate UV coordinates, if uv attribute is not present, it will be added
        applyBoxUV(bufferGeometry, new THREE.Matrix4(), 1);
        // bufferGeometry.attributes.uv2 = bufferGeometry.attributes.uv;

        bufferGeometry.attributes.uv.needsUpdate = true;

      }
    }
  }

  getProjectFullSizeScale() {
    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);
    let d = modelBox.min.distanceTo(modelBox.max);
    return d;
  }


  resetShadowCameraSize() {

    this.appActionsService.directionalLightOptions.shadowCameraSize = 2 * this.singleMesh.geometry.boundingSphere.radius;
    this.appActionsService.directionalLightOptionsChanged.next();
  }

  resetShadowTarget() {
    this.appActionsService.directionalLightOptions.target = {
      x: this.targetCenterProject.position.x,
      y: this.targetCenterProject.position.y,
      z: this.targetCenterProject.position.z,
    }
    this.appActionsService.directionalLightOptionsChanged.next();
  }

  async updateModelsFromData() {


    const models = this.dataService.models;

    //remove deleted models
    for (let id in this.models) {
      if (!models[id]) {
        const modelToDelete = this.models[id];
        if (this.transformControls.object === modelToDelete) {
          this.transformControls.detach();
          this.transformControlOn = false;
        }
        this.scene.remove(modelToDelete);
        delete this.models[id];

        //TODO: Discard to free memeory??..
      }
    }

    //add & update models
    const pendingModels = [];
    for (let id in models) {

      pendingModels.push(this.updateModelInScene(id, models[id]));



    }

    await Promise.all(pendingModels);


    this.directionalLightBimshow.updateShadowMap();
    this.askForRender();




  }

  async updateModelInScene(id, modelConfig) {
    let model = this.models[id]
    if (!model) { //get if not existing
      model = await this.dataService.getModel(modelConfig.lib_id);
      if (!model) {
        console.warn('model no longer exist');
        return;
      }
      model.userData.id = id;
      model.userData.lib_id = modelConfig.lib_id;



      if (!this.models[id]) { //we check again, because there is a chance the model was tried to add in multiple calls, and we want to make sure we add it only once.
        model.traverse(o => {
          o.userData.ambientOcclusion = true;
          o.userData.modelId = id;


          //clippingplanes
          let m_list: any[] = [];
          const mat = (o as THREE.Mesh).material
          if (mat) {
            if (Array.isArray(mat)) {
              m_list = mat;
            } else {
              m_list = [mat]
            }
          }

          m_list.forEach(m => {
            if (m.map) {
              m.clippingPlanes = this.clippingPlanes
              m.clipShadows = true;
            }

            this.modelsMaterials[m.uuid] = m;


          })

        })
        this.scene.add(model);
        this.models[id] = model;

      }

    }

    //now update
    model.position.copy(new THREE.Vector3(modelConfig.position.x, modelConfig.position.y, modelConfig.position.z));

    model.setRotationFromEuler(new THREE.Euler(modelConfig.euler._x, modelConfig.euler._y, modelConfig.euler._z, modelConfig.euler._order));
    model.scale.set(modelConfig.scale.x, modelConfig.scale.y, modelConfig.scale.z)
    model.updateMatrix()

    //now update visibility from latest vis config:
    this.setModelAndChildrenVisibility(model, !!this.dataService.viewConfig.modelsConfig[id])

    this.directionalLightBimshow.updateShadowMap();



  }

  addNewModel(model) {


  }

  updateModelTransformData(id) {

    const model = this.models[id];
    if (model) {
      const updates = JSON.parse(JSON.stringify(
        {
          euler: model.rotation,
          position: model.position,
          scale: model.scale
        }
      ));

      this.dataService.updateModel(id, updates)
    }




  }

  setModelAndChildrenVisibility(model, visible: boolean) {
    const isTransformedControl = model == this.transformControls.object


    model.visible = visible;
    model.traverse(o => {

      o.visible = isTransformedControl || visible;


      const material = o.material;

      if (material) {
        if (isTransformedControl && !visible) {
          if (!o.originalMaterial) {
            o.originalMaterial = material;
          }
          o.material = this.hiddenModelMateiral;
        } else {
          if (o.originalMaterial) {
            o.material = o.originalMaterial;
          }
        }
      }



    })
    this.directionalLightBimshow.updateShadowMap();

  }


  updateModelsVisibilityFromConfig() {
    for (let id in this.models) {
      const model = this.models[id];
      if (model) {
        this.setModelAndChildrenVisibility(model, !!this.dataService.viewConfig.modelsConfig[id])
      }
    }

    this.askForRender();
  }

  startTransformModel(id) {
    this.onUnHoverModel();

    if (this.transformControls.object) {
      this.removeObjectFromOutlinePAss(this.transformControls.object)
    }


    const model = this.models[id];
    if (model) {
      this.scene.add(this.transformControls)
      this.transformControls.attach(model)
      this.transformControlOn = true;
      this.addObjectToOutlinePass(model)
    }
    this.updateModelsVisibilityFromConfig();

  }

  stopTransformModel() {

    const model = this.transformControls.object;

    if (model) {
      this.removeObjectFromOutlinePAss(model)
      this.updateModelTransformData(model.userData.modelId)
    }

    this.transformControls.detach()
    this.transformControlOn = false;
    this.updateModelsVisibilityFromConfig();

  }

  onHoverModel(object) {

    if (this.hoveredModel == object || object == this.transformControls.object) {
      return;
    }

    this.addObjectToOutlinePass(object)
    this.hoveredModel = object;


  }

  onUnHoverModel() {
    this.removeObjectFromOutlinePAss(this.hoveredModel)
    if (this.hoveredModel == null) {
      return
    }

    this.hoveredModel = null;
  }

  addObjectToOutlinePass(object) {

    const index = this.outlinePass.selectedObjects.indexOf(object);
    if (index == -1) {

      this.composer.passes[2].selectedObjects.push(object);
    }
  }

  removeObjectFromOutlinePAss(object) {

    const index = this.composer.passes[2].selectedObjects.indexOf(object);
    if (index > -1) {

      this.outlinePass.selectedObjects.splice(index, 1)
    }
  }

  clearAllObjectsFromOutlinePass() {

    this.outlinePass.selectedObjects.splice(0, this.outlinePass.selectedObjects.length)
  }

  enterTransformClone() {

    this.clearAllObjectsFromOutlinePass();
    this.onUnHoverModel();
    if (!this.transformCloneModel) {

      this.originaltransformCloneModel = null;
      this.transformCloneModel = this.transformControls.object.clone();
      this.originaltransformCloneModel = this.transformControls.object;
      this.scene.add(this.transformCloneModel)
      // this.transformControls.reset(); //reset original 
      // this.transformControls.detach();

      if (this.transformControls.dragging) {

        this.originaltransformCloneModel.position.copy(this.transformControls._positionStart);
        this.originaltransformCloneModel.quaternion.copy(this.transformControls._quaternionStart);
        this.originaltransformCloneModel.scale.copy(this.transformControls._scaleStart);
      }



      this.transformControls.attach(this.transformCloneModel)
    }

  }

  async executeTransformClone() {

    if (this.transformCloneModel) {
      const addModelData = {
        lib_id: this.transformCloneModel.userData.lib_id,
        ...JSON.parse(JSON.stringify(
          {
            euler: this.transformCloneModel.rotation,
            position: this.transformCloneModel.position,
            scale: this.transformCloneModel.scale
          }
        ))
      }
      this.transformControls.detach();
      this.transformControlOn = false;
      this.scene.remove(this.transformCloneModel);
      this.transformCloneModel = null;
      this.originaltransformCloneModel = null;

      const uuid = await this.dataService.addModel(
        addModelData
      )
      this.appActionsService.selectedModelId.next(uuid)
    }
  }

  cancelTransformClone() {

    if (this.transformCloneModel) {

      this.scene.remove(this.transformCloneModel);


      if (this.transformControls.dragging) {

        this.originaltransformCloneModel.position.copy(this.transformCloneModel.position);
        this.originaltransformCloneModel.quaternion.copy(this.transformCloneModel.quaternion);
        this.originaltransformCloneModel.scale.copy(this.transformCloneModel.scale);
      }
      this.transformCloneModel = null;

      this.transformControls.attach(this.originaltransformCloneModel)
      this.originaltransformCloneModel = null;

    }

  }

  handleTransformKeydown(keyCode) {

    switch (keyCode) {

      case 16: // Shift
        if (!this.transformCloneModel) {
          this.enterTransformClone();

        }
        break;
    }
  }

  handleTransformKeyup(keyCode) {
    const control = this.transformControls;
    switch (keyCode) {

      case 81: // Q
        this.transformControls.setSpace(control.space === 'local' ? 'world' : 'local');
        break;

      case 16: // Shift
        this.cancelTransformClone();
        break;

      case 87: // W
        control.setMode('translate');
        break;

      case 69: // E
        control.setMode('rotate');
        break;


      case 82: // R
        control.setMode('scale');
        break;


      // case 187:
      // case 107: // +, =, num+
      //   control.setSize(control.size + 0.1);
      //   break;

      // case 189:
      // case 109: // -, _, num-
      //   control.setSize(Math.max(control.size - 0.1, 0.1));
      //   break;

      // case 88: // X
      //   control.showX = !control.showX;
      //   break;

      // case 89: // Y
      //   control.showY = !control.showY;
      //   break;

      // case 90: // Z
      //   control.showZ = !control.showZ;
      //   break;

      // case 32: // Spacebar
      //   control.enabled = !control.enabled;
      //   break;

      case 84: // T
        this.orientToClosestWall(this.transformControls.object);
        break;

      case 71: // G
        this.add90DegToRotation(this.transformControls.object);
        break;

      case 27: // Esc
        control.reset();
        this.appActionsService.selectedModelId.next()
        break;

      case 70: //F
        this.flyTo(this.transformControls.object, null, null, null, true)
        break;

      case 46: //DEL

        const model = this.transformControls.object;
        if (model) {
          this.dataService.removeModel(model.userData.modelId)
        }
        break;




    }
  }

  resetPreviewModelRotationAndScale() {
    this.previewModelScale = 1;
    this.previewModelRotation = 0;
    this.updatePreviewModelRoationAndScale();
  }

  randomPreviewModelRotationAndScale() {
    console.log('randoming')
    this.previewModelScale = 1 + 0.2 * (0.5 - Math.random()) * 2
    this.previewModelRotation = Math.random() * Math.PI * 2;
    this.updatePreviewModelRoationAndScale()

  }

  updatePreviewModelRoationAndScale() {
    if (this.previewModel) {
      this.previewModel.setRotationFromEuler(new THREE.Euler(0, 0, this.previewModelRotation, 'XYZ'));
      this.previewModel.scale.set(this.previewModelScale, this.previewModelScale, this.previewModelScale)
      this.previewModel.updateMatrix();
      this.askForRender();
    }
  }

  loadPreviewModel() {

    this.resetPreviewModelRotationAndScale();
    if (this.modelIdToAdd) {
      this.stopTransformModel();
      const obj = this.dataService.modelsCache[this.modelIdToAdd];

      if (obj) {
        this.previewModel = obj.clone();

        if (this.previewModel.userData.category == 'plants_outdoor') {

          this.randomPreviewModelRotationAndScale();
          this.updatePreviewModelRoationAndScale();


        }


        this.scene.add(this.previewModel);
      }
    } else {
      this.scene.remove(this.previewModel);
      this.previewModel = null;

    }

    this.askForRender();


  }

  updatePreviewModel() {
    if (this.previewModel) {

      this.previewModel.visible = !this.mouseOutOfScene;

      if (this.INTERSECTED_POINT) {
        if (this.INTERSECTED_POINT.distanceTo(this.previewModel.position) > 0.0001) {
          this.previewModel.position.copy(this.INTERSECTED_POINT)
          // this.previewModel.up.set(0,0,1)

          if (this.dataService.modelsLibrary[this.modelIdToAdd].orientToFaceNormal) {
            this.previewModel.lookAt(this.INTERSECTED_POINT.clone().add(this.INTERSECTED_NORMAL));
          }

          this.turnOffSSAA();
          this.askForRender();
        }


      } else {
        const planeIntersect = new THREE.Vector3();
        this.raycaster.setFromCamera(this.mouse, this.camera);
        if (this.raycaster.ray.intersectPlane(this.xy_plane, planeIntersect)) {
          if (planeIntersect.distanceTo(this.previewModel.position) > 0.0001) {
            this.previewModel.position.copy(planeIntersect)
            this.turnOffSSAA();
            this.askForRender();
          }

        }
      }

      this.directionalLightBimshow.updateShadowMap();
    }

  }

  orientToClosestWall(object) {
    const position = object.position.clone();
    position.z += 1.2; //small offset 


    let closestIntersect = null;
    const N = 8;
    for (let i = 0; i < N; i++) {
      const direction = new THREE.Vector3(Math.sin(2 * Math.PI * i / N), Math.cos(2 * Math.PI * i / N), 0);
      this.raycaster.set(position, direction);
      let intersects = this.raycaster.intersectObjects(
        this.collidableObjects, false
      );

      intersects = intersects.filter(intersect => {
        return intersect.object.visible
      })


      if (intersects[0]) {
        const intersect = intersects[0]
        if (!closestIntersect) {
          closestIntersect = intersect
        } else {
          if (intersect.distance < closestIntersect.distance) {
            closestIntersect = intersect
          }
        }


      }
    }


    const normal = closestIntersect.normal;
    closestIntersect.object.localToWorld(normal)


    normal.z = 0;
    normal.normalize();
    let angle = normal.angleTo(new THREE.Vector3(0, -1, 0))
    const sign = -Math.sign(normal.clone().cross(new THREE.Vector3(0, -1, 0)).z)

    const euler = new THREE.Euler(0, 0, angle * sign, "XYZ");
    object.setRotationFromEuler(euler)
    this.askForRender();

  }

  add90DegToRotation(object) {
    object.rotateZ(Math.PI / 2)
    this.askForRender()

  }


  drawDebugSphere(position, radius, color, timeInSeconds) {
    let sphere = new THREE.Mesh(
      new THREE.SphereGeometry(radius, 12, 12),
      new THREE.MeshPhongMaterial({ color: color })
    );
    sphere.position.copy(position)
    this.scene.add(sphere)

    setTimeout(() => {

      this.scene.remove(sphere)
      sphere.material.dispose();
      sphere.geometry.dispose();
      sphere = undefined;
      this.askForRender()
    }, timeInSeconds * 1000);
  }

  updateMaterialsForAssets(changesList) {


  }


}
