import { Component, EventEmitter, Input, OnInit, Output, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';


@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {

  sliderHueBackground = null;
  @Input('color') color: any;
  @Input('iconAsButton') iconAsButton: boolean;
  @Output() colorChange = new EventEmitter<string>();
  @Output() change = new EventEmitter<string>();
  @Output() closed = new EventEmitter<string>();
  menuOpen = false;

  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit() {
    this.generateHueBackground();
  }

  labelColorChanged(a, b) {
   
  }



  generateHueBackground() {
    this.sliderHueBackground = 'linear-gradient(to right'

    for (let i = 0; i < 360; i++) {
      this.sliderHueBackground = this.sliderHueBackground + ',hsl(' + i + ',100%,50%)'
    }

    this.sliderHueBackground = this.sliderHueBackground + ')'
  }

  onColorChanged(e) {
    this.colorChange.emit(this.color)
    this.change.emit(this.color)
  }

  onMenuClosed() {
    this.closed.emit(this.color)
  }
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

}
