import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss']
})
export class InputRangeComponent implements OnInit {
  @Input('value') value: any;
  @Output() valueChange = new EventEmitter<number>()
  @Input('max') max: number = 100;
  @Input('disabled') disabled: boolean = false
  @Input('min') min: number = 0;
  @Input('step') step: number = 0.1;
  @Input('allowStrechMaxMin') allowStrechMaxMin: boolean = true;
  @Output('change') change = new EventEmitter<number>();
  @Output('input') input = new EventEmitter<number>();
  inputOutOfBounds = false;

  constructor() { }

  ngOnInit() {

    this.setMinMaxFromInput();
  }

  onInputInput(e) {
    e.stopPropagation()
    if (!this.allowStrechMaxMin) { //make sure value is not out of bounding if stretch is not allowed 

      if (this.value < this.min || this.value > this.max) {
        this.inputOutOfBounds = true;
        return;
      } else {
        this.inputOutOfBounds = false;
      }

    } else {
      this.inputOutOfBounds = false;
    }
    this.valueChange.emit(this.value)
    this.input.emit(this.value)
  }

  onInputChange(e) {
    e.stopPropagation()
    if (!this.allowStrechMaxMin) { //make sure value is not out of bounding if stretch is not allowed 
      this.value = Math.min(Math.max(this.min, this.value), this.max)
      this.inputOutOfBounds = false;
    }

    this.valueChange.emit(this.value)
    this.change.emit(this.value)
    this.setMinMaxFromInput();
  }


  onSliderInput(value) {

    this.value = value;
    this.valueChange.emit(this.value)
    this.input.emit(this.value)

  }
  onSliderChange(value) {
    this.value = value;
    this.valueChange.emit(this.value)
    this.change.emit(this.value)
  }

  setMinMaxFromInput() {
    if (this.allowStrechMaxMin) {
      if (this.min > this.value) {
        this.min = this.value - 100*this.step;
      }

      if (this.max < this.value) {
        this.max = this.value + 100*this.step
      }
    }

  }





}
