import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy, HostListener, NgZone } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { debounce } from 'lodash';
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

  tip: any = null;
  subscriptions: Subscription[] = [];
  left = 0;
  top = 0;
  idle = false;
  right = false;
  bottom = false;
  toggledOn: boolean = false;
  @ViewChild('toolTipContainer', { static: true }) toolTipContainer: ElementRef;
  debounceIdle = debounce(this.setIdle, 50);

  constructor(private appActionsService: AppActionsService, private renderer: Renderer2, private ngZone: NgZone) { }


  ngOnInit() {

    this.subscriptions.push(this.appActionsService.toolTipMessage.subscribe(tip => {


      if (tip) {
        this.tip = tip;
        this.toggleOn()

      }

      else {
        this.toggleOff();

      }


    }))

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener("mousemove", () => {
        if (!this.tip) {
          return;
        }

        this.idle = false;

        this.debounceIdle()
      })
    });

  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  toggleOn() {
    this.toggledOn = true;


    this.left = this.tip.clientRect.right;
    this.top = this.tip.clientRect.top;

    this.right = this.left > window.innerWidth / 2;
    this.bottom = this.top > window.innerHeight / 2

    if (this.right) {
      this.left = this.tip.clientRect.left - 8;
    } else {
      this.left = this.tip.clientRect.right + 8;
    }

    if (this.bottom) {
      this.top = this.tip.clientRect.bottom;
    }

  }

  toggleOff() {

    this.toggledOn = false;

  }

  setIdle() {
    this.idle = true;
  }



  @HostListener('window:mousedown', ['$event'])
  onMouseDown(eventData: MouseEvent) {
    if (!this.tip) {
      return;
    }


    this.toggleOff();
  }
}
