import { Animation, OnAnimationFinishedCallback, OnNewFrameCallback } from "./animation.model";

export class AnimationsManager {
    private ids = 0;
    public animations: { [id: number]: Animation };
    public onNeedUpdate: () => void;

    constructor(onNeedUpdate?: () => void) {

        this.animations = {};
        this.onNeedUpdate = onNeedUpdate;

    }



    addAnimation(totalFrames, onNewFrameCallback: OnNewFrameCallback, onAnimationFinishedCallback?: OnAnimationFinishedCallback, name?:string, replace?: boolean) {
        if (name) {
            for (let id in this.animations) {
                if (this.animations[id].name === name) {
                    if (!replace) {
                        console.warn(`animation  with ${name} already exist, not replacing`)
                        return;
                    } else {
                        console.warn(`animation  with ${name} already exist, canceling old and starting new`)
                        this.cancelAnaimation(name,true)
                    }
                }


            }
        }

        this.animations[this.ids++] = new Animation(totalFrames, onNewFrameCallback, onAnimationFinishedCallback, name);

    }

    removeAnimation(id) {
        delete this.animations[id];
    }

    runFrame() {

        let isThereAnimationsToRun = false;

        for (let id in this.animations) {
            const animation = this.animations[id];

            if (!animation.finished) {
                isThereAnimationsToRun = true;
                try {
                    animation.runAnimation();
                } catch (err) {
                    console.warn('error running animation')
                }

            } else {
                this.removeAnimation(id)
            }

        }

        if (this.onNeedUpdate && isThereAnimationsToRun) this.onNeedUpdate();




    }




    cancelAnaimation(name: string, triggerOnAnimationFinished = false) {

        for (let id in this.animations) {
            if (this.animations[id].name === name) {

                if (triggerOnAnimationFinished) {

                    this.animations[id].finishAnimation(true);
                    if (this.onNeedUpdate) this.onNeedUpdate();

                }
                this.removeAnimation(id)
            }
        }



    }



}
