import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  viewOption: string | null = null;
  public lat0 = 0;
  public lon0 = 0;
  expr: any;
  public optionTooltip =  null;
  zonesExist = false;
  tooManyZones = false;

  



  constructor(private appActionService: AppActionsService, private dataService: DataService,private translate: TranslateService, private appActionsService:AppActionsService) { }

  ngOnInit() {
    this.lat0 = Number(this.dataService.siteData.latitude);
    this.lon0 = Number(this.dataService.siteData.longitude);
    
    const zones = this.dataService.getZones()
    if (zones.length >0) {
      this.zonesExist = true;
      if(zones.length > 50) { //max zones set to 50
        this.tooManyZones = true;
      }
     
    }


    
  }

  latlon(lat0, lon0) {
    this.lat0 = Number(this.dataService.siteData.latitude);
    this.lon0 = Number(this.dataService.siteData.longitude);
    lat0 = this.lat0;
    lon0 = this.lon0;
  }

  selectViewOption(option: string) {
    this.viewOption = option;
   
    this.expr = option
    switch (this.expr) {
      case 'fromGalleryTemplate':
        this.optionTooltip = this.translate.instant('wizard.templatesInfo.fromGalleryTemplate');
        break;
      case 'whiteModeltemplate':
        this.optionTooltip = this.translate.instant('wizard.templatesInfo.whiteModeltemplate');
        break;
      case 'roomsAsButtons':
        this.optionTooltip = this.translate.instant('wizard.templatesInfo.roomsasbuttons');
        break;
      case 'roomsAsLabels':
        this.optionTooltip = this.translate.instant('wizard.templatesInfo.roomsaslabels');
        break;
        case 'simpleTemplate':
          this.optionTooltip = this.translate.instant('wizard.templatesInfo.simpletemplate');
         break;
      default:
        this.optionTooltip = `Sorry, we are out of ${this.expr}.`;
    }
  }

  async setupView() {
    const taskId = this.appActionsService.addUserBlockingTask('generateViewInWizard');

    try {
      if (this.viewOption != 'blank') {
        await this.dataService.generateTemplateFromTree(this.viewOption);
   
      }
   
      this.closeWizard()
    } catch(err) {
      console.warn('error generating view in wizard')
    }

    this.appActionsService.removeUserBlockTask(taskId);




  }

  closeWizard() {
    this.appActionService.wizardOpen = false;
    this.appActionService.openCloseViewsEditor.next('close');
    this.appActionService.editorMode = false;

  }



}


