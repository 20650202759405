import { Component, HostListener, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-model-library',
  templateUrl: './model-library.component.html',
  styleUrls: ['./model-library.component.scss']
})
export class ModelLibraryComponent implements OnInit {
  subscriptions: Subscription[] = [];
  categories = []
  selectedCategory = null;
  selectedModelIdInList = null;
  modelIdToAdd = null;
  tags = {}
  models = []
  expanded = false;

  constructor(public dataService: DataService, public appActionsService: AppActionsService) { }

  ngOnInit() {

    //generating categories array - assuming modelsLibrary is ready already once this component iss loading..

    for (let key in this.dataService.modelsLibrary) {

      const category = this.dataService.modelsLibrary[key].category;
      if (category && this.categories.findIndex(c => c == category) == -1) {
        this.categories.push(category)
      }
    }

    this.subscriptions.push(this.appActionsService.selectedModelId.subscribe((id) => {
      this.selectedModelIdInList = id;
    }))

    this.subscriptions.push(this.appActionsService.modelIdToAdd.subscribe((id) => {
      this.modelIdToAdd = id;
    }))
  }



  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {

    if (event.keyCode == 27) { //ESC Pressed
      this.appActionsService.modelIdToAdd.next()
    }
  }


  async modelCicked(modelData) {

    if (this.modelIdToAdd == modelData.id) { //toggle off
      this.appActionsService.modelIdToAdd.next()

      return;
    }

    if (modelData.ready) {
      if (!this.dataService.canAddAsset(modelData.size, 'model', this.modelIdToAdd)) {
        this.appActionsService.notify(
          'storage.snackbackModelProjectSizeError',
          "warn",
          10)
      } else {

        this.appActionsService.modelIdToAdd.next(modelData.id);
      }
      return;
    }

    if (modelData.progress < 1) {
      return;
    } else {
      await this.dataService.downloadModel(modelData);
    }


  }

  visibilityToggleClicked(modelId, event) {
    event.stopPropagation();
    this.dataService.viewConfig.modelsConfig[modelId] = !this.dataService.viewConfig.modelsConfig[modelId];
    this.appActionsService.updateModelsVisFromConfig.next();
  }

  selectCategory(cat) {
    this.selectedCategory = cat;
    this.models = [];
    this.tags = {};
    for (let lib_id in this.dataService.modelsLibrary) {
      const model = this.dataService.modelsLibrary[lib_id];
      if (model.category == this.selectedCategory) {
        this.models.push(model)
        if (model.tags) {
          for (let tag of model.tags) {
            this.tags[tag] = false;
          }
        }
      }
    }
    this.filterModelsByTags()

    this.appActionsService.modelIdToAdd.next();
  }

  tagClicked(tag) {
    this.tags[tag] = !this.tags[tag]
    this.resetModelsByCategory();
    this.filterModelsByTags()
  }

  resetModelsByCategory() {
    this.models = [];
    for (let lib_id in this.dataService.modelsLibrary) {
      const model = this.dataService.modelsLibrary[lib_id];
      if (model.category == this.selectedCategory) {
        this.models.push(model)
      }
    }
  }

  filterModelsByTags() {
    const onTags = {};
    for (let tag in this.tags) {
      if (this.tags[tag]) {
        onTags[tag] = true;
      }
    }
    this.models = this.models.filter(m => {
      let shouldDisplay = true;
      for (let tag in onTags) {

        if (m.tags) {

          shouldDisplay = shouldDisplay && (m.tags.indexOf(tag) > -1);
        }
        else {
          shouldDisplay = false;
        }

      }

      return shouldDisplay;
    })
  }


  onModelItemClicked(model) {
    this.appActionsService.selectedModelId.next(model.key)
  }

  bulkVisibility(value) {
    for (let modelId in this.dataService.models) {
      this.dataService.viewConfig.modelsConfig[modelId] = value
    }

    this.appActionsService.updateModelsVisFromConfig.next();
  }

  onTabChanged(event) {
    if (event == 1) {
      this.appActionsService.modelIdToAdd.next();
    }
  }

  goToDB(key, event) {

    if (event.ctrlKey && this.dataService.user.level == 'admin') {

      window.open('https://console.firebase.google.com/u/0/project/bimshow-dev1/database/bimshow-dev1/data/models/' + key, '_blank').focus();
    }

  }
  toggleExpanded() {
    this.expanded = !this.expanded;

    if (this.modelIdToAdd) {


      setTimeout(() => {
        document.getElementById('asset_' + this.modelIdToAdd).scrollIntoView({ behavior: "smooth", block: "end" });


      }, 150)

    }

  }

}
