import * as THREE from '../threejs/three.module.js';
import { FontLoader } from '../threejs/loaders/FontLoader.js';
import { VertexNormalsHelper } from '../threejs/helpers/VertexNormalsHelper.js';
import {
  EventDispatcher,
} from '../threejs/three.module.js';
class AbstractDimensionLineComponent {

  constructor() {
    this.mesh = null
    this.defaultColor = null
    this.material = null
    if (this.constructor === AbstractDimensionLineComponent) {
      throw new TypeError('Abstract class "AbstractConfig" cannot be instantiated directly')
    }
  }

  create3D(color, key) {
    throw new Error('You must implement this function')
  }

  setColor(color, isDefault = false) {
    if (isDefault) this.defaultColor = color
    this.material.color.set(color)
  }

  resetColor() {
    this.material.color.set(this.defaultColor)
  }

  dispose() {
    if (this.mesh && this.mesh.parent) {
      this.mesh.parent.remove(this.mesh)
      this.mesh.geometry.dispose()
      this.mesh.material.dispose()
      this.mesh = null
    }

  }
}

export class ExtensionLineBimshow extends AbstractDimensionLineComponent {
  //extensionLineLength : Vector3, pointStart: Vector3, vector direction to translate the line
  constructor(pointStart, unitVectorDirection, extensionLineLength, pointType) {
    super()
    this.pointStart = pointStart
    this.unitVectorDirection = unitVectorDirection
    this.pointExtensionLine = pointStart.clone().add(unitVectorDirection.clone().multiplyScalar(extensionLineLength))
    this.mesh = null
    this.pointType = pointType
  }
  create3D(color = '#ffffff', key = null) {
    this.defaultColor = color
    this.material = new THREE.LineBasicMaterial(
      {
        color: color,
        // depthTest: false,
        transparent: true
      }
    );
    const points = []
    points.push(this.pointStart)
    points.push(this.pointExtensionLine)
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    this.mesh = new THREE.Line(geometry, this.material)
    if (this.pointType == 'a') {
      this.mesh.name = "extensionLineBimshowA"
    }
    if (this.pointType == 'b') {
      this.mesh.name = "extensionLineBimshowB"
    }
    this.mesh.visible = false;
    return this.mesh
  }

}
export class TextBimshow extends AbstractDimensionLineComponent {
  //pointA and pointB are THREE.Vector3
  constructor(name, key) {
    super()
  
    this.mesh = new THREE.Group()
    this.font = null
    this.message = name
    this.key = key
    this.materialBlack = new THREE.LineBasicMaterial(
      {
        color: '#000000',
        // depthTest: false,
        transparent: true
      }
    )
  }
  removeTextMeshes() {
    return new Promise(
      (resolve, reject) => {
        if (this.mesh.children.length > 0) {
          this.mesh.children.forEach(function (child) {
            child.children.forEach(function (child2) {
              if (child2.geometry) {
                child2.geometry.dispose()
              }
              child2.parent.remove(child2)
            })
            child.parent.remove(child)
          })
          resolve(true)
        }
        else {
          resolve(true)
        }
      }
    )
  }
  create3D(color = '#3486eb') {
    return new Promise(
      (resolve, reject) => {

        const loader = new FontLoader();
        loader.load(
          'assets/fonts/helvetiker_regular.typeface.json',
          font => {
            this.font = font
            this.defaultColor = color
            this.material = new THREE.MeshBasicMaterial({
              color: color,
              side: THREE.DoubleSide,
              // depthTest: false,
              transparent: true
            });
            this.createMeshes()
            resolve(true)
          }
        )
      }
    )
  }
  createMeshes() {
    if (!this.font) return
    const shapes = this.font.generateShapes(this.message, 1)

    const textOffsetFromLine = 0.3;
    //outside the text
    const geometry = new THREE.ShapeGeometry(shapes);
    geometry.computeBoundingBox();
    const center = new THREE.Vector3();
    geometry.boundingBox.getCenter(center)
    geometry.translate(-center.x, textOffsetFromLine, 0)

    const holeShapes = [];

    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];
      if (shape.holes && shape.holes.length > 0) {
        for (let j = 0; j < shape.holes.length; j++) {
          const hole = shape.holes[j];
          holeShapes.push(hole);
        }
      }
    }

    shapes.push.apply(shapes, holeShapes);

    const lineText = new THREE.Object3D();

    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];
      const points = shape.getPoints();
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      geometry.translate(-center.x, textOffsetFromLine, 0)
      const lineMesh = new THREE.Line(geometry, this.materialBlack);
      lineMesh.userData.key = this.key
      lineMesh.userData.element = "distanceText"
      lineText.add(lineMesh);

    }

    this.mesh.add(lineText);

    //inside the text
    const textInsideMesh = new THREE.Mesh(geometry, this.material)
    textInsideMesh.userData.key = this.key
    textInsideMesh.userData.element = "distanceText"
    this.mesh.add(textInsideMesh)

    this.mesh.name = "distanceText"
    this.mesh.userData.key = this.key
    this.mesh.userData.element = 'distanceText'
    this.mesh.renderOrder = 999

    return this.mesh;
  }
  setFontSize(size) {
    this.mesh.scale.setScalar(size)

  }
  dispose() {
    if (this.mesh && this.mesh.parent) {
      this.removeTextMeshes()
      this.material.dispose()
      this.materialBlack.dispose()
      this.mesh.clear()
      this.mesh = null
    }
  }
}
export class DimensionLineBimshow extends AbstractDimensionLineComponent {
  //pointA and pointB are THREE.Vector3
  constructor(distance, width = 0.03) {
    super()
    this.distance = distance //- 2*(distance/15) //because of arrow length
    // this.distanceTextSize = distanceTextSize;
    this.mesh = null
    this.width = width
  }
  create3D(color = '#ffffff', key = null) {
    this.defaultColor = color

    this.material = new THREE.LineBasicMaterial(
      {
        color: color,
        // depthTest: false,
        transparent: true
      }
    )
    const points = [];
    points.push(new THREE.Vector3(0, 0, 0)) //beacause arrow length at the start
    points.push(new THREE.Vector3(this.distance, 0, 0)) //beacause arrow length at the start and end

    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    this.mesh = new THREE.Line(geometry, this.material)

    this.mesh.name = "dimensionLineBimshow"
    this.mesh.userData.key = key
    this.mesh.userData.element = 'dimensionLine'
    return this.mesh
  }
};
export class ArrowBimshow extends AbstractDimensionLineComponent {
  //pointA in THREE.Vector3, angle in radians, pointType can be 'a' or 'b'
  constructor(pointType, distance, distanceTextSize) {
    super()
    this.distanceTextSize = distanceTextSize;
    this.distance = distance
    this.pointType = pointType
    this.mesh = null
  }
  create3D(color = '#ff0000', key = null) {
    this.defaultColor = color
    let coneHeight = this.distanceTextSize;
    if (coneHeight > this.distance / 4) {
      coneHeight = this.distance / 8;
    }
    const geometry = new THREE.ConeGeometry(coneHeight / 3, coneHeight, 10)
    this.material = new THREE.MeshBasicMaterial(
      {
        color: color,
        // depthTest: false,
        transparent: true
      }
    );
    this.mesh = new THREE.Mesh(geometry, this.material)
    if (this.pointType == 'a') {
      this.mesh.position.set(coneHeight / 2, 0, 0)
      this.mesh.rotateZ(Math.PI / 2)
      this.mesh.name = "arrowBimshowA"
      this.mesh.userData.key = key
      this.mesh.userData.element = 'arrowBimshowA'
    }
    if (this.pointType == 'b') {
      this.mesh.rotateZ(-Math.PI / 2)
      this.mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), this.distance - coneHeight / 2)
      this.mesh.name = "arrowBimshowB"
      this.mesh.userData.key = key
      this.mesh.userData.element = 'arrowBimshowB'
    }
    return this.mesh
  }
};

export class Dimensioning {
  //extensionLineLength is a vector
  //color and fontcolor new THREE.Color style
  constructor(key, scene, pointA, pointB, color, fontColor, extensionLineLength, directionNormal = null, distanceTextSize = null) {
    this.id = new Date().getTime();
    this.name = 'undefined'
    this.key = key
    this.scene = scene
    this.pointA = pointA
    this.pointB = pointB
    this.spherePointA = null
    this.spherePointB = null
    this.arrowBimshowA = null
    this.arrowBimshowB = null
    this.dimensionLineBimshow = null
    this.distanceText = null
    this.group = null
    this.groupIntersect = []
    this.extensionLineBimshowA = null
    this.extensionLineBimshowB = null
    this.distanceTextSize = distanceTextSize

    this.extensionLineLength = extensionLineLength
    this.directionNormal = directionNormal
    this.planNormalA = null
    this.planNormalB = null
    this.coplan = null
    this.vectorExtensionLineVector = null
    this.vectorAB = new THREE.Vector3(
      this.pointB.x - this.pointA.x,
      this.pointB.y - this.pointA.y,
      this.pointB.z - this.pointA.z,
    )
    this.distance = Math.sqrt(Math.pow(this.pointA.x - this.pointB.x, 2) + Math.pow(this.pointA.y - this.pointB.y, 2) + Math.pow(this.pointA.z - this.pointB.z, 2))
    this.initNormal()
    this.color = color.clone()
    this.defaultColor = color.clone()
    this.fontColor = fontColor.clone()

    this.cssColor = this.color.getStyle()
    this.cssDefaultColor = this.color.getStyle()
    this.cssFontColor = this.fontColor.getStyle()

    this.visible = false
    this.materialPoint = new THREE.MeshBasicMaterial(
      {
        color: color,
        // depthTest: false,
        transparent: true
      }
    )

  }
  getParameters() {
    return {
      name: this.name,
      color: this.cssColor,
      fontColor: this.fontColor,
      key: this.key,
      pointA: {
        x: this.pointA.x,
        y: this.pointA.y,
        z: this.pointA.z
      },
      pointB: {
        x: this.pointB.x,
        y: this.pointB.y,
        z: this.pointB.z
      },
      directionNormal: {
        x: this.directionNormal.x,
        y: this.directionNormal.y,
        z: this.directionNormal.z
      },
      extensionLineLength: this.extensionLineLength,
      distanceTextSize: this.distanceTextSize
    }
  }
  setName(name) {
    this.name = name
  }
  getObject(ref) {
    //dict objects
    const objects = {
      arrowBimshowA: this.arrowBimshowA,
      arrowBimshowB: this.arrowBimshowB,
      dimensionLine: this.dimensionLineBimshow,
      distanceText: this.distanceText,
      extensionLineBimshowA: this.extensionLineBimshowA,
      extensionLineBimshowB: this.extensionLineBimshowB
    }
    return objects[ref]

  }
  displayPlane(refPlane, status) {
    if (this.helper) {
      this.scene.remove(this.helper)
    }
    if (status) {
      let planNormal = null
      if (refPlane == 'arrowBimshowA') {
        planNormal = this.planNormalA
      }
      if (refPlane == 'arrowBimshowB') {
        planNormal = this.planNormalB
      }
      this.helper = new THREE.PlaneHelper(planNormal, 50, 0xffff00)
      // this.helper.material.depthTest = false
      this.helper.material.transparent = true
      this.scene.add(this.helper)
    }
  }
  initNormal() {
    if (!this.directionNormal || this.directionNormal === undefined) {
      this.directionNormal = new THREE.Vector3()
      const projectPoint = new THREE.Vector3()
      const newPoint = this.pointA.clone().add(new THREE.Vector3(1, 0, 0))

      const lineSegment = new THREE.Line3(this.pointA, this.pointB)
      lineSegment.closestPointToPoint(newPoint, false, projectPoint)

      this.directionNormal.subVectors(newPoint, projectPoint).normalize()
    }


    //compute the normal plan
    this.planNormalA = new THREE.Plane()
    this.planNormalA.setFromNormalAndCoplanarPoint(this.vectorAB.normalize(), this.pointA)
    this.planNormalB = new THREE.Plane()
    this.planNormalB.setFromNormalAndCoplanarPoint(this.vectorAB.normalize(), this.pointB)
    this.initCoplan()

    //const helper2 = new THREE.PlaneHelper( this.planNormalB, 5, 0xffff00 );
    //this.scene.add( helper2 );
    //const helper = new THREE.PlaneHelper( this.planNormalA, 5, 0xffff00 );
    //this.scene.add( helper );
  }
  initCoplan() {
    this.coplan = new THREE.Plane()
    const coplanNormal = new THREE.Vector3()
    coplanNormal.crossVectors(this.directionNormal, this.vectorAB)
    this.coplan.setFromNormalAndCoplanarPoint(coplanNormal, this.pointA)
    //const helper = new THREE.PlaneHelper( this.coplan, 5, 0xff0000 );
    //this.scene.add( helper );
  }
  getMiddlePoint() {
    return new THREE.Vector3(
      (this.pointB.x + this.pointA.x) / 2,
      (this.pointB.y + this.pointA.y) / 2,
      (this.pointB.z + this.pointA.z) / 2)
  }
  remove3DExtensionLines() {
    this.scene.remove(this.extensionLineBimshowA.mesh)
    this.scene.remove(this.extensionLineBimshowB.mesh)
  }
  create3DExtensionLines() {
    this.extensionLineBimshowA = new ExtensionLineBimshow(this.pointA, this.directionNormal, this.extensionLineLength, 'a')
    this.extensionLineBimshowA.create3D(this.color)
    this.scene.add(this.extensionLineBimshowA.mesh)
    this.extensionLineBimshowB = new ExtensionLineBimshow(this.pointB, this.directionNormal, this.extensionLineLength, 'b')
    this.extensionLineBimshowB.create3D(this.color)
    this.scene.add(this.extensionLineBimshowB.mesh)
    this.vectorExtensionLineVector = new THREE.Vector3(
      this.extensionLineBimshowB.pointExtensionLine.x - this.extensionLineBimshowA.pointExtensionLine.x,
      this.extensionLineBimshowB.pointExtensionLine.y - this.extensionLineBimshowA.pointExtensionLine.y,
      this.extensionLineBimshowB.pointExtensionLine.z - this.extensionLineBimshowA.pointExtensionLine.z,
    )
  }
  refresh3DDimensionLine() {
    //to rotate
    const normalizedVectorAB = this.vectorExtensionLineVector.clone()
    normalizedVectorAB.normalize()

    var qrot = new THREE.Quaternion()
    qrot.setFromUnitVectors(new THREE.Vector3(1, 0, 0), normalizedVectorAB)
    this.group.setRotationFromQuaternion(qrot)

    //translate
    this.group.position.set(
      this.extensionLineBimshowA.pointExtensionLine.x,
      this.extensionLineBimshowA.pointExtensionLine.y,
      this.extensionLineBimshowA.pointExtensionLine.z)

    const groupWorldPosition = new THREE.Vector3()
    this.group.getWorldPosition(groupWorldPosition)
    this.distanceText.mesh.position.set(
      (this.extensionLineBimshowA.pointExtensionLine.x + this.extensionLineBimshowB.pointExtensionLine.x) / 2,
      (this.extensionLineBimshowA.pointExtensionLine.y + this.extensionLineBimshowB.pointExtensionLine.y) / 2,
      (this.extensionLineBimshowA.pointExtensionLine.z + this.extensionLineBimshowB.pointExtensionLine.z) / 2
    )

    this.setVisible(this.visible)
  }
  translateDimensionLine(distance, direction) {
    this.remove3DExtensionLines()
    this.initCoplan()
    this.directionNormal = direction
    this.extensionLineLength = distance
    this.create3DExtensionLines()
    this.refresh3DDimensionLine()
  }
  create3D() {
    return new Promise(async (resolve, reject) => {
      this.create3DExtensionLines()

      //creates the point

      const geometryPointA = new THREE.SphereGeometry(0.02, 10, 10)
      this.spherePointA = new THREE.Mesh(geometryPointA, this.materialPoint)
      this.spherePointA.position.set(this.pointA.x, this.pointA.y, this.pointA.z)
      this.spherePointA.visible = false;

      const geometryPointB = new THREE.SphereGeometry(0.02, 10, 10);
      this.spherePointB = new THREE.Mesh(geometryPointB, this.materialPoint)
      this.spherePointB.position.set(this.pointB.x, this.pointB.y, this.pointB.z)
      this.spherePointB.visible = false;
      this.scene.add(this.spherePointA)
      this.scene.add(this.spherePointB)

      //create the line
      const dimensionLineBimshow = new DimensionLineBimshow(this.distance)

      //add an arrow
      const arrowBimshowA = new ArrowBimshow('a', this.distance, this.distanceTextSize)
      const arrowBimshowB = new ArrowBimshow('b', this.distance, this.distanceTextSize)

      //add the text for the distance
      const distanceText = new TextBimshow(this.name, this.key)
      this.distanceText = distanceText


      //create meshes associated
      const meshDistanceText = await distanceText.create3D(this.fontColor.getStyle())
      const meshDimensionLineBimshow = dimensionLineBimshow.create3D(this.color.getStyle(), this.key)
      const meshArrowBimshowA = arrowBimshowA.create3D(this.color.getStyle(), this.key)
      const meshArrowBimshowB = arrowBimshowB.create3D(this.color.getStyle(), this.key)

      this.dimensionLineBimshow = dimensionLineBimshow
      this.arrowBimshowA = arrowBimshowA
      this.arrowBimshowB = arrowBimshowB

      //create group to rotate / translate every meshes in EJS
      this.group = new THREE.Group()
      this.group.visible = this.visible

      //this.group.add(meshDistanceText)
      this.group.add(meshDimensionLineBimshow)
      this.group.add(meshArrowBimshowA)
      this.group.add(meshArrowBimshowB)

      //create group for intersection with pointer
      this.groupIntersect = []
      this.groupIntersect.push(meshArrowBimshowA)
      this.groupIntersect.push(meshArrowBimshowB)
      this.groupIntersect.push(meshDimensionLineBimshow)

      this.refresh3DDimensionLine()
      resolve(true)
    }
    )
  }
  setColor(color, isDefault = false) {
    if (isDefault) {
      this.defaultColor.set(color)
      this.cssDefaultColor = this.defaultColor.getStyle()
    }
    this.color.set(color)
    this.cssColor = this.color.getStyle()

    if (this.materialPoint) this.materialPoint.color.set(this.color)
    if (this.extensionLineBimshowA) this.extensionLineBimshowA.mesh.material.color.set(this.color)
    if (this.extensionLineBimshowB) this.extensionLineBimshowB.mesh.material.color.set(this.color)
    if (this.arrowBimshowA) this.arrowBimshowA.mesh.material.color.set(this.color)
    if (this.arrowBimshowB) this.arrowBimshowB.mesh.material.color.set(this.color)
    if (this.dimensionLineBimshow) this.dimensionLineBimshow.mesh.material.color.set(this.color)
    this.refreshMaterial()
  }
  setFontColor(color, isDefault = false) {
    this.fontColor.set(color)
    this.cssFontColor = this.fontColor.getStyle()
    if (this.distanceText.material) this.distanceText.material.color.set(this.fontColor)
    this.refreshMaterial()
  }
  resetColor() {
    this.color.set(this.defaultColor)
    this.cssColor = this.cssDefaultColor

    if (this.extensionLineBimshowA) this.extensionLineBimshowA.mesh.material.color.set(this.color)
    if (this.extensionLineBimshowB) this.extensionLineBimshowB.mesh.material.color.set(this.color)
    if (this.arrowBimshowA) this.arrowBimshowA.mesh.material.color.set(this.color)
    if (this.arrowBimshowB) this.arrowBimshowB.mesh.material.color.set(this.color)
    if (this.dimensionLineBimshow) this.dimensionLineBimshow.mesh.material.color.set(this.color)
    this.refreshMaterial()
  }

  refreshMaterial() {
    this.spherePointA.material.needsUpdate = true
    this.spherePointB.material.needsUpdate = true
    if (this.extensionLineBimshowA) this.extensionLineBimshowA.mesh.material.needsUpdate = true
    if (this.extensionLineBimshowB) this.extensionLineBimshowB.mesh.material.needsUpdate = true
    if (this.arrowBimshowA) this.arrowBimshowA.mesh.material.needsUpdate = true
    if (this.arrowBimshowB) this.arrowBimshowB.mesh.material.needsUpdate = true
    if (this.dimensionLineBimshow) this.dimensionLineBimshow.mesh.material.needsUpdate = true
  }

  setVisible(visible) {
    this.visible = visible
    if (this.group) {
      this.group.visible = visible
    }
    if (this.spherePointA) {
      this.spherePointA.visible = visible
    }
    if (this.spherePointB) {
      this.spherePointB.visible = visible
    }
    if (this.extensionLineBimshowA) {
      this.extensionLineBimshowA.mesh.visible = visible
    }
    if (this.extensionLineBimshowB) {
      this.extensionLineBimshowB.mesh.visible = visible
    }
    if (this.distanceText) {
      this.distanceText.mesh.visible = visible;
    }
  }

  setScale(value) {
    if (this.group) {
      this.group.scale.setScalar(value)
    }
    if (this.spherePointA) {
      this.spherePointA.scale.setScalar(value)
    }
    if (this.spherePointB) {
      this.spherePointB.scale.setScalar(value)
    }
    if (this.extensionLineBimshowA) {
      this.extensionLineBimshowA.mesh.scale.setScalar(value)
    }
    if (this.extensionLineBimshowB) {
      this.extensionLineBimshowB.mesh.scale.setScalar(value)
    }
    if (this.distanceText) {
      this.distanceText.mesh.scale.setScalar(value)
    }
  }

  dispose() {
    return new Promise((resolve, reject) => {
      if (this.arrowBimshowA) {

        this.arrowBimshowA.dispose()
        this.arrowBimshowB.dispose()
        this.dimensionLineBimshow.dispose()
        this.extensionLineBimshowA.dispose()
        this.extensionLineBimshowB.dispose()
        this.distanceText.dispose()

        this.spherePointA.parent.remove(this.spherePointA)
        this.spherePointA.geometry.dispose()
        this.spherePointA.material.dispose()
        this.spherePointB.parent.remove(this.spherePointB)
        this.spherePointB.geometry.dispose()
        this.spherePointB.material.dispose()

        this.arrowBimshowA = null
        this.arrowBimshowB = null
        this.dimensionLineBimshow = null
        this.extensionLineBimshowA = null
        this.extensionLineBimshowB = null
        this.distanceText = null
        this.spherePointA = null
        this.spherePointB = null

        this.scene.remove(this.group)
        if (this.group) {
          this.group.clear()
          this.group = null
        }
        resolve(true)
      }
      else {
        resolve(false)
      }
    })

  }
}

export class CollectionDimensioning extends EventDispatcher {
  //color and fontcolor CSS style
  constructor(scene, color = '#0000FF', fontColor = '#3486eb') {
    super();
    this.name = 'undefined'
    this.key = 'undefined'
    this.dimensionings = {}
    this.color = new THREE.Color(color)
    this.fontColor = new THREE.Color(fontColor)
    this.cssColor = color
    this.cssFontColor = fontColor

    //for pointer interaction
    this.scene = scene
    this.threeJSComponent = null
    this.selectedKey = null
    this.hoverKey = null
    this.hoverElement = null
    this.pointerDownStatus = false
    this.editModeStatus = false
    this.visible = true

    this.subscriptions = []
  }
  set3JSComponent(value) {
    this.threeJSComponent = value

  }



  setVisible(visible) {
    this.visible = visible
    for (const key in this.dimensionings) {
      this.dimensionings[key].setVisible(visible)
    }


  }




  //color css style
  initColor(color) {
    this.color.set(color)
    this.cssColor = color
  }
  setColor(color) {
    this.color.set(color)
    this.cssColor = color
    for (const key in this.dimensionings) {
      this.dimensionings[key].setColor(color, true)
    }
    this.threeJSComponent.askForRender()

  }

  //color css style
  initFontColor(color) {
    this.fontColor.set(color)
    this.cssFontColor = color
  }
  setFontColor(color) {
    this.fontColor.set(color)
    this.cssFontColor = color
    for (const key in this.dimensionings) {
      this.dimensionings[key].setFontColor(color, true)
    }
    this.threeJSComponent.askForRender()

  }

  setColorDimensioning(key, color, isDefault = false) {

    this.dimensionings[key].setColor(color, isDefault)
    this.threeJSComponent.askForRender()

  }
  setFontColorDimensioning(key, color, isDefault = false) {

    this.dimensionings[key].setFontColor(color, isDefault)
    this.threeJSComponent.askForRender()

  }


  resetColorDimensioning(key) {
    this.dimensionings[key].resetColor()
    this.threeJSComponent.askForRender()

  }
  refreshMaterial() {
    for (const key in this.dimensionings) {
      this.dimensionings[key].refreshMaterial()
    }
  }

  setName(name) {
    this.name = name
  }
  setKey(key) {
    this.key = key
  }
  setEditModeStatus(status) {
    this.editModeStatus = status
  }


  newUpdateVisibilityAndColor(dimensionings) {
    for (let key in dimensionings) {
      if (this.dimensionings[key]) {
        this.dimensionings[key].setVisible(dimensionings[key].visible)
        this.dimensionings[key].setColor(dimensionings[key].color)
        this.dimensionings[key].setFontColor(dimensionings[key].fontColor)
      }

    }
    this.setDimensioningsScaleCamera(this.threeJSComponent.camera);
    this.threeJSComponent.askForRender()

  }



  newSetDimensionings(dimensionings) {
    return new Promise(
      async (resolve, reject) => {
        await this.removeDimensionings()
        for (const key in dimensionings) {
          const confDimensioning = dimensionings[key]
          if (confDimensioning.pointA) {
            const dimensioning = this.createDimensioning(
              key,
              new THREE.Vector3(
                confDimensioning.pointA.x,
                confDimensioning.pointA.y,
                confDimensioning.pointA.z
              ),
              new THREE.Vector3(
                confDimensioning.pointB.x,
                confDimensioning.pointB.y,
                confDimensioning.pointB.z
              ),
              confDimensioning.extensionLineLength || 1,
              confDimensioning.color,
              confDimensioning.fontColor,
              confDimensioning.directionNormal ? new THREE.Vector3(
                confDimensioning.directionNormal.x,
                confDimensioning.directionNormal.y,
                confDimensioning.directionNormal.z
              ) : null,
              confDimensioning.distanceTextSize
            )
            dimensioning.setName(confDimensioning.name)

            await this.create3DNewDimensioningByKey(key)


          }
        }


        resolve(true)
      }
    )
  }

  setDimensioningsFromConfiguration(dimensionings) {
    return new Promise(
      async (resolve, reject) => {
        await this.removeDimensionings()
        for (const key in dimensionings) {
          const confDimensioning = dimensionings[key]
          if (confDimensioning.pointA) {
            const dimensioning = this.createDimensioning(
              key,
              new THREE.Vector3(
                confDimensioning.pointA.x,
                confDimensioning.pointA.y,
                confDimensioning.pointA.z
              ),
              new THREE.Vector3(
                confDimensioning.pointB.x,
                confDimensioning.pointB.y,
                confDimensioning.pointB.z
              ),
              confDimensioning.extensionLineLength,
              confDimensioning.color,
              confDimensioning.fontColor,
              new THREE.Vector3(
                confDimensioning.directionNormal.x,
                confDimensioning.directionNormal.y,
                confDimensioning.directionNormal.z
              ),
              confDimensioning.distanceTextSize
            )
            dimensioning.setName(confDimensioning.name)
            await this.create3DNewDimensioningByKey(key)
          }
        }
        resolve(true)
      }
    )
  }

  getParameters() {
    let toReturn = {
      parameters: {
        name: this.name,
        color: this.cssColor,
        fontColor: this.cssFontColor
      },
      dimensionings: {}
    }
    for (const key in this.dimensionings) {
      toReturn.dimensionings[key] = this.dimensionings[key].getParameters()
    }

    return toReturn
  }

  createDimensioning(key, pointA, pointB, extensionLineLength, color = this.color, fontColor = this.fontColor, directionNormal, distanceTextSize) {

    //check if key already exists, if yes so replace it by new
    this.removeDimensioning(key)
    const dimensioning = new Dimensioning(key, this.scene, pointA, pointB, new THREE.Color(color), new THREE.Color(fontColor), extensionLineLength, directionNormal, distanceTextSize)
    this.addDimensioning(dimensioning, key)
    return dimensioning
  }
  addDimensioning(dimensioning, key) {

    this.dimensionings[key] = dimensioning
  }
  getDimensioning(key) {
    return this.dimensionings[key]
  }
  getSelectedDimensioning() {
    return this.dimensionings[this.selectedKey]
  }
  removeDimensioning(key) {

    if (key in this.dimensionings) {
      this.dimensionings[key].dispose()
      delete this.dimensionings[key]
      this.refreshGroupIntersect()


      this.threeJSComponent.askForRender()
  
    }
  }
  refreshGroupIntersect() {
    this.groupIntersect = []
    for (const key in this.dimensionings) {
      this.groupIntersect = this.groupIntersect.concat(this.dimensionings[key].groupIntersect)
    }
  }
  create3DNewDimensioningByKey(key) {
    return this.create3DNewDimensioning(this.dimensionings[key])
  }

  create3DNewDimensioning(dimensioning) {
    return new Promise(
      async (resolve, reject) => {

        await dimensioning.dispose()
        await dimensioning.create3D()
        if (this.dimensionings[dimensioning.key] !== dimensioning) { //incase for some reason dimensioning is removed by other one (same key, but different instance) we have to not generate the 3d. this is due to possible async calls to this function
          console.warn('Warning: dimensioning instance is no longer the last created instance, canceling 3d generataion')
          await dimensioning.dispose()
          resolve();
          return;
        }
        //add text
        this.scene.add(dimensioning.distanceText.mesh)

        this.group.add(dimensioning.group)
        this.groupIntersect = this.groupIntersect.concat(dimensioning.groupIntersect)
        //if no text size so compute it before change the scale and direction of the text else scale and direction directly
        if (!dimensioning.distanceTextSize) {
          await this.setSizeDistanceTextCameraDimensioning(dimensioning, this.threeJSComponent.camera)
        }
        else {
          this.setDimensioningScaleCamera(dimensioning, this.threeJSComponent.camera)
        }
        resolve(true)
      }
    )

  }
  addGroupToScene() {
    if (!this.scene.getObjectByName('collectionDimensioning')) {
      this.group = new THREE.Group()
      this.group.name = 'collectionDimensioning'
      this.group.renderOrder = 998
      this.scene.add(this.group)
      this.group.visible = this.visible
      this.groupIntersect = []
    }
  }

  removeDimensionings() {
    return new Promise(
      async (resolve, reject) => {
        for (const key in this.dimensionings) {
          await this.dimensionings[key].dispose()
        }
        this.dimensionings = {}
        this.addGroupToScene()
        resolve(true)
      }
    )
  }


  dispose() {
    this.removeDimensionings()
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  //need controls to keep mouse movement only for dimensioning
  selectDimensioning(key, element, controls) {
    this.selectedKey = key
    this.selectedElement = element
    controls.enabled = false
    if (element.startsWith("arrowBimshow") && key in this.dimensionings) {
      // this.dimensionings[key].displayPlane(element, true)
    }
  }
  unselectDimensioning(controls) {
    return new Promise(
      (resolve, reject) => {
        if (this.selectedElement && this.selectedElement.startsWith("arrowBimshow")) {
          this.dimensionings[this.selectedKey].displayPlane(null, false)
        }
        this.selectedKey = null
        this.selectedElement = null
        this.hoverKey = null
        this.hoverElement = null
        controls.enabled = true
        resolve(true)
      }
    )
  }

  setSizeDistanceTextCameraDimensioning(dimensioning, camera) {
    return new Promise(
      (resolve, reject) => {
        if (!camera || !dimensioning) resolve(true)
        const distanceText = dimensioning.getObject('distanceText')
        //compute distance text/camera
        const textPosition = new THREE.Vector3()
        distanceText.mesh.getWorldPosition(textPosition)

        const cameraPosition = new THREE.Vector3()
        camera.getWorldPosition(cameraPosition)

        const distanceToCamera = textPosition.distanceTo(cameraPosition)

        dimensioning.distanceTextSize = (distanceToCamera / 25)
        this.setDimensioningScaleCamera(dimensioning, camera)
        resolve(true)
      }
    )
  }


  setDimensioningScaleCamera(dimensioning, camera) {
    if (dimensioning.visible) {
      const distanceText = dimensioning.getObject('distanceText')
      //compute distance text/camera
      const textPosition = new THREE.Vector3()
      distanceText.mesh.getWorldPosition(textPosition)

      const cameraPosition = new THREE.Vector3()
      camera.getWorldPosition(cameraPosition)

      const distanceToCamera = textPosition.distanceTo(cameraPosition)

      //based on text size, because else the text is not readable
      if (dimensioning.distanceTextSize * 120 > distanceToCamera) {
        dimensioning.setScale(1)
        distanceText.setFontSize(dimensioning.distanceTextSize)
        this.setDistanceTextLookAtDimensioning(dimensioning, camera)
      }
      else {
        dimensioning.setScale(0)
      }
    }
  }

  //set the scale of the distance texts according to the distance to the camera
  setDimensioningsScaleCamera(camera) {
    if (!camera) return
    for (const key in this.dimensionings) {
      this.setDimensioningScaleCamera(this.dimensionings[key], camera)
    }
  }

  setDistanceTextLookAtDimensioning(dimensioning, camera) {
    if (!camera || !dimensioning) return
    if (dimensioning.visible) {
      const distanceText = dimensioning.getObject('distanceText')

      distanceText.mesh.lookAt(camera.position)
      distanceText.mesh.quaternion.copy(camera.quaternion)
    }
  }
  setDistanceTextLookAt(camera) {
    if (!camera) return
    for (const key in this.dimensionings) {
      this.setDistanceTextLookAtDimensioning(this.dimensionings[key], camera)
    }
  }

  //method to load and save visibility of the collection following structure below
  //{
  //  key : <collectionKey>,
  //  visible : true | false,
  //  dimensionings : {
  //    <dimensioningkey> : { visible : true | false},
  //    ...
  //  }
  //}


  getVisibility() {
    const toReturn = {
      key: this.key,
      visible: this.visible,
      dimensionings: {}
    }
    for (const key in this.dimensionings) {
      toReturn.dimensionings[key] = { visible: this.dimensionings[key].visible }
    }

    return toReturn
  }

  //todelete
  // setVisibility(configuration) {

  //   for (const key in this.dimensionings) {
  //     //test if in configuration, if not , not visible
  //     if (configuration.dimensionings && configuration.dimensionings[key]) {
  //       this.setVisibleDimensioning(key, configuration.dimensionings[key].visible)
  //     } else {
  //       this.setVisibleDimensioning(key, false)
  //     }



  //   }
  // }

  //to check if selected, test the key value if null
  pointerMove(event, camera) {
    if (this.threeJSComponent.mouseOutOfScene) {
      this.pointerUp(this.threeJSComponent.controls)
      return
    }
    const pointer = new THREE.Vector2()

    pointer.x =
      (event.layerX / (this.threeJSComponent.renderer.domElement.clientWidth / 1)) * 2 - 1;
    pointer.y =
      -(event.layerY / (this.threeJSComponent.renderer.domElement.clientHeight / 1)) * 2 + 1;

    const raycaster = new THREE.Raycaster();

    raycaster.params.Line.threshold = 0.1;
    raycaster.setFromCamera(pointer, camera)


    if (this.selectedKey) {
      const dimensioning = this.getSelectedDimensioning()
      if (
        dimensioning &&
        this.selectedElement == 'dimensionLine'
      ) {
        const pointOfIntersection = new THREE.Vector3()
        raycaster.ray.intersectPlane(dimensioning.coplan, pointOfIntersection)
        if (pointOfIntersection) {
          const lineSegment = new THREE.Line3(dimensioning.pointA, dimensioning.pointB);
          const projectPoint = new THREE.Vector3()
          lineSegment.closestPointToPoint(pointOfIntersection, false, projectPoint);

          //direction
          const newDirection = new THREE.Vector3(
            pointOfIntersection.x - projectPoint.x,
            pointOfIntersection.y - projectPoint.y,
            pointOfIntersection.z - projectPoint.z
          )
          //distance
          const newDistance = pointOfIntersection.distanceTo(projectPoint)
          dimensioning.translateDimensionLine(newDistance, newDirection.normalize())
        }
      }
      if (this.selectedElement.startsWith("arrowBimshow")) {
        const dimensioningPoint = this.selectedElement == "arrowBimshowA" ? dimensioning.pointA : dimensioning.pointB
        const dimensioningPlanNormal = this.selectedElement == "arrowBimshowA" ? dimensioning.planNormalA : dimensioning.planNormalB

        const pointOfIntersection = new THREE.Vector3()
        raycaster.ray.intersectPlane(dimensioningPlanNormal, pointOfIntersection)
        if (pointOfIntersection) {
          //direction
          const newDirection = new THREE.Vector3(
            pointOfIntersection.x - dimensioningPoint.x,
            pointOfIntersection.y - dimensioningPoint.y,
            pointOfIntersection.z - dimensioningPoint.z
          )
          //distance
          const newDistance = pointOfIntersection.distanceTo(dimensioningPoint)
          dimensioning.translateDimensionLine(newDistance, newDirection.normalize())
        }
      }
    }
    if (!this.selectedElement && this.groupIntersect && this.groupIntersect.length > 0) {
      if (this.hoverKey) {

        if (this.dimensionings[this.hoverKey]) {
          this.dimensionings[this.hoverKey].resetColor()
          this.threeJSComponent.turnOffSSAA();
          this.threeJSComponent.askForRender()
        }
        this.hoverKey = null
        this.hoverElement = null
      }
      const intersects = raycaster.intersectObjects(this.groupIntersect, true)
      //const intersects = raycaster.intersectObject( this.group, true )



      if (intersects.length > 0) {


        const res = intersects.filter(function (res) {
          return res && res.object;
        })[0];



        if (res && res.object) {


          this.hoverKey = res.object.userData.key
          this.hoverElement = res.object.userData.element
          res.object.material.color.set('#FF0000')
          res.object.material.needsUpdate = true
        }
      }
    }
    this.threeJSComponent.turnOffSSAA();
    this.threeJSComponent.askForRender()
  }

  pointerDown(event, camera, controls) {
    if (this.threeJSComponent.mouseOutOfScene) return;
    const pointer = new THREE.Vector2()
    //pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1
    //pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1

    pointer.x =
      (event.layerX / (this.threeJSComponent.renderer.domElement.clientWidth / 1)) * 2 - 1;
    pointer.y =
      -(event.layerY / (this.threeJSComponent.renderer.domElement.clientHeight / 1)) * 2 + 1;
    const raycaster = new THREE.Raycaster()
    raycaster.params.Line.threshold = 0.1;
    raycaster.setFromCamera(pointer, camera)
    this.pointerDownStatus = true
    const intersects = raycaster.intersectObjects(this.groupIntersect, true);
    if (intersects.length > 0) {
      this.selectDimensioning(
        intersects[0].object.userData.key,
        intersects[0].object.userData.element,
        controls
      )
    }
  }

  pointerUp(controls) {
    this.pointerDownStatus = false
    const dimensioning = this.getSelectedDimensioning()
    if (dimensioning) {
      const toUpdate = {
        directionNormal: {
          x: dimensioning.directionNormal.x,
          y: dimensioning.directionNormal.y,
          z: dimensioning.directionNormal.z
        },
        extensionLineLength: dimensioning.extensionLineLength,
        distanceTextSize: dimensioning.distanceTextSize
      }

      this.dispatchEvent({
        type: "change",
        key: dimensioning.key,
        ...toUpdate
      })
    }
    this.unselectDimensioning(controls)

  }

  async keyUp(event) {
    const dimensioning = this.dimensionings[this.hoverKey];
    if (!dimensioning) {
      return;
    }
    if (!(event.key == '-' || event.key == '+')) {
      return
    }
    console.log(event.key)
    const sign = event.key == '-' ? -1 : +1;
    const C = 1.1;


    if (this.hoverKey) {

      if (this.dimensionings[this.hoverKey]) {
        this.dimensionings[this.hoverKey].distanceTextSize = sign > 0 ? C * this.dimensionings[this.hoverKey].distanceTextSize : this.dimensionings[this.hoverKey].distanceTextSize / C;


        await this.create3DNewDimensioning(this.dimensionings[this.hoverKey])
        this.setDimensioningScaleCamera(this.dimensionings[this.hoverKey], this.threeJSComponent.camera)
        const toUpdate = {
          directionNormal: {
            x: dimensioning.directionNormal.x,
            y: dimensioning.directionNormal.y,
            z: dimensioning.directionNormal.z
          },
          extensionLineLength: dimensioning.extensionLineLength,
          distanceTextSize: dimensioning.distanceTextSize
        }

        this.dispatchEvent({
          type: "change",
          key: dimensioning.key,
          ...toUpdate
        })
        this.threeJSComponent.turnOffSSAA();
        this.threeJSComponent.askForRender();
      }

    }
  }

}
