import { Vector3, Matrix4, Float32BufferAttribute, Vector2, Box3, Mesh, Euler, MeshLambertMaterial, BufferGeometry } from '../../assets/threejs/three.module'
import * as  BufferGeometryUtils from '../../assets/threejs/utils/BufferGeometryUtils.js';
const _UP = new Vector3(0, 1, 0);

export function _applyBoxUV(geom, transformMatrix, bbox, bbox_max_size) {
  let coords = [];
  coords.length = (2 * geom.attributes.position.array.length) / 3;

  // geom.removeAttribute('uv');
  if (geom.attributes.uv === undefined) {
    geom.setAttribute("uv", new Float32BufferAttribute(coords, 2));
  }

  //maps 3 verts of 1 face on the better side of the cube
  //side of the cube can be XY, XZ or YZ
  let makeUVs = (v0, v1, v2, coords, debug) => {
    //pre-rotate the model so that cube sides match world axis
    v0.applyMatrix4(transformMatrix);
    v1.applyMatrix4(transformMatrix);
    v2.applyMatrix4(transformMatrix);

    //get normal of the face, to know into which cube side it maps better
    let n = new Vector3();
    n.crossVectors(v1.clone().sub(v0), v1.clone().sub(v2)).normalize();



    let uv0 = new Vector2();
    let uv1 = new Vector2();
    let uv2 = new Vector2();





    const slope = new Vector3();
    const orthToSlope = new Vector3();


    if (Math.abs(_UP.dot(n)) < 0.9999) {
      orthToSlope.crossVectors(_UP, n).normalize();
    } else {

      const _left = new Vector3(1, 0, 0);
      orthToSlope.crossVectors(_left, n).normalize(); 1;



      if (debug) {
        // debugger;
      }
    }




    slope.crossVectors(orthToSlope, n).normalize();
    uv0.x = orthToSlope.dot(v0);
    uv0.y = slope.dot(v0);

    uv1.x = orthToSlope.dot(v1);
    uv1.y = slope.dot(v1);

    uv2.x = orthToSlope.dot(v2);
    uv2.y = slope.dot(v2);

    return {
      uv0: uv0,
      uv1: uv1,
      uv2: uv2,
    };
  };

  if (geom.index) {
    // is it indexed buffer geometry?
    for (let vi = 0; vi < geom.index.array.length; vi += 3) {
      let idx0 = geom.index.array[vi];
      let idx1 = geom.index.array[vi + 1];
      let idx2 = geom.index.array[vi + 2];

      let vx0 = geom.attributes.position.array[3 * idx0];
      let vy0 = geom.attributes.position.array[3 * idx0 + 1];
      let vz0 = geom.attributes.position.array[3 * idx0 + 2];

      let vx1 = geom.attributes.position.array[3 * idx1];
      let vy1 = geom.attributes.position.array[3 * idx1 + 1];
      let vz1 = geom.attributes.position.array[3 * idx1 + 2];

      let vx2 = geom.attributes.position.array[3 * idx2];
      let vy2 = geom.attributes.position.array[3 * idx2 + 1];
      let vz2 = geom.attributes.position.array[3 * idx2 + 2];

      let v0 = new Vector3(vx0, vy0, vz0);
      let v1 = new Vector3(vx1, vy1, vz1);
      let v2 = new Vector3(vx2, vy2, vz2);

      let uvs = makeUVs(v0, v1, v2, coords);

      coords[2 * idx0] = uvs.uv0.x;
      coords[2 * idx0 + 1] = uvs.uv0.y;

      coords[2 * idx1] = uvs.uv1.x;
      coords[2 * idx1 + 1] = uvs.uv1.y;

      coords[2 * idx2] = uvs.uv2.x;
      coords[2 * idx2 + 1] = uvs.uv2.y;
    }
  } else {

    for (let vi = 0; vi < geom.attributes.position.array.length; vi += 9) {
      let vx0 = geom.attributes.position.array[vi];
      let vy0 = geom.attributes.position.array[vi + 1];
      let vz0 = geom.attributes.position.array[vi + 2];

      let vx1 = geom.attributes.position.array[vi + 3];
      let vy1 = geom.attributes.position.array[vi + 4];
      let vz1 = geom.attributes.position.array[vi + 5];

      let vx2 = geom.attributes.position.array[vi + 6];
      let vy2 = geom.attributes.position.array[vi + 7];
      let vz2 = geom.attributes.position.array[vi + 8];

      let v0 = new Vector3(vx0, vy0, vz0);
      let v1 = new Vector3(vx1, vy1, vz1);
      let v2 = new Vector3(vx2, vy2, vz2);

      let uvs = makeUVs(v0, v1, v2, coords);

      let idx0 = vi / 3;
      let idx1 = idx0 + 1;
      let idx2 = idx0 + 2;

      coords[2 * idx0] = uvs.uv0.x;
      coords[2 * idx0 + 1] = uvs.uv0.y;

      coords[2 * idx1] = uvs.uv1.x;
      coords[2 * idx1 + 1] = uvs.uv1.y;

      coords[2 * idx2] = uvs.uv2.x;
      coords[2 * idx2 + 1] = uvs.uv2.y;
    }
  }

  geom.attributes.uv.array = new Float32Array(coords);
}

export function applyBoxUV(bufferGeometry, transformMatrix, boxSize) {
  if (transformMatrix === undefined) {
    transformMatrix = new Matrix4();
  }

  if (boxSize === undefined) {
    let geom = bufferGeometry;
    geom.computeBoundingBox();
    let bbox = geom.boundingBox;

    let bbox_size_x = bbox.max.x - bbox.min.x;
    let bbox_size_z = bbox.max.z - bbox.min.z;
    let bbox_size_y = bbox.max.y - bbox.min.y;

    boxSize = Math.max(bbox_size_x, bbox_size_y, bbox_size_z);
  }

  let uvBbox = new Box3(
    new Vector3(-boxSize / 2, -boxSize / 2, -boxSize / 2),
    new Vector3(boxSize / 2, boxSize / 2, boxSize / 2)
  );

  _applyBoxUV(bufferGeometry, transformMatrix, uvBbox, boxSize);
}



export function discardHiddenOnBeforeCompileFunc(shader) {
  shader.vertexShader = shader.vertexShader.replace(
    '#include <common>',
    [`#include <common>`,
      `attribute float expressID;`,
      `attribute float hidden;`,
      `attribute float passlight;`,
      `varying float vhidden;`,
      `varying float vpasslight;`].join('\n')

  );
  shader.vertexShader = shader.vertexShader.replace(
    '#include <begin_vertex>',
    [`#include <begin_vertex>`,
      `vhidden = hidden;`,
      `vpasslight = passlight;`,
    ].join('\n')

  );



  const releventInclude = shader.fragmentShader.includes('#include <packing>') ? '#include <packing>' : '#include <common>';
  shader.fragmentShader = shader.fragmentShader.replace(
    releventInclude,
    [releventInclude,
      `varying float vhidden;`,
      `varying float vpasslight;`].join('\n')
  );


  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <clipping_planes_fragment>`,
    [`#include <clipping_planes_fragment>`,
      `if(vpasslight > 0.0) {`,
      `discard;`,
      `return;`,
      `}`,
      `if(vhidden  > 0.0) {`,
      `discard;`,
      `return;`,
      `}`,].join('\n')

  )


};

export function separateGroups(bufGeom, material) {

  var outGeometries = [];

  var groups = bufGeom.groups;

  var origVerts = bufGeom.getAttribute('position').array;
  var origNormals = bufGeom.getAttribute('normal') ? bufGeom.getAttribute('normal').array : null;
  var origUVs = bufGeom.getAttribute('uv') ? bufGeom.getAttribute('uv').array : null;
  var origNumVerts = Math.floor(origVerts.length / 3);

  for (var ig = 0, ng = groups.length; ig < ng; ig++) {

    var group = groups[ig];

    var destNumVerts = group.count;

    var newBufGeom = new BufferGeometry();
    var newPositions = new Float32Array(destNumVerts * 3);
    var newNormals = new Float32Array(destNumVerts * 3);
    var newUVs = new Float32Array(destNumVerts * 2);

    for (var iv = 0; iv < destNumVerts; iv++) {

      var indexOrig = 3 * (group.start + iv);
      var indexDest = 3 * iv;

      var indexOrigUV = 2*(group.start + iv);
      var indexDestUV = 2*iv;

      newPositions[indexDest] = origVerts[indexOrig];
      newPositions[indexDest + 1] = origVerts[indexOrig + 1];
      newPositions[indexDest + 2] = origVerts[indexOrig + 2];
      if (origNormals) {
        newNormals[indexDest] = origNormals[indexOrig];
        newNormals[indexDest + 1] = origNormals[indexOrig + 1];
        newNormals[indexDest + 2] = origNormals[indexOrig + 2];
      }

      if (origUVs) {

        newUVs[indexDestUV] = origUVs[indexOrigUV];
        newUVs[indexDestUV + 1] = origUVs[indexOrigUV + 1];
      }

    }

    newBufGeom.setAttribute('position', new Float32BufferAttribute(newPositions, 3));
    if (origNormals) {
      newBufGeom.setAttribute('normal', new Float32BufferAttribute(newNormals, 3));
    }

    if (origUVs) {
      newBufGeom.setAttribute('uv', new Float32BufferAttribute(newUVs, 2));
    }



    outGeometries.push({ geometry: newBufGeom, material: Array.isArray(material) ? material[group.materialIndex] : material });

  }

  return outGeometries;

}


export function generateSingleMeshFromColladaObjet(object) {
  let defaultMat = new MeshLambertMaterial({ color: 0xff0000 })

  const geosByMat = {};
  let expressID = 1;
  object.traverse(o => {
    o.userData.expressID = expressID;
    expressID++;
    let geometry = o.geometry;
    const material = o.material;
    if (geometry && material) {

      let geos = separateGroups(geometry, material);

      geos.forEach(gm => {
        let geo = gm.geometry


        o.updateWorldMatrix();
        geo.applyMatrix4(o.matrixWorld) 

        if (!geo.getAttribute('uv')) {

          applyBoxUV(geo, new Matrix4(), 1);
        }

        if (geo.getAttribute('normal')) { //dont include lines for now 
       
          if (!geosByMat[gm.material.uuid]) {
            geosByMat[gm.material.uuid] = { material: gm.material, geometries: [] }
          }
          geo = BufferGeometryUtils.mergeVertices(gm.geometry, 0.00000000001);
          const expressID = Array.from({ length: geo.attributes.position.count }).map(e=>o.userData.expressID);
          geo.setAttribute('expressID', new Float32BufferAttribute(new Float32Array(expressID), 1));
          geosByMat[gm.material.uuid].geometries.push(geo);

        }



      })



    }
  })

  console.log('gbm',geosByMat)
  let material = []
  const geometriesToMerge = [];
  Object.values(geosByMat).forEach(gbm => {
    if (gbm.material.name == "") {
      gbm.material.name = "Material_"+material.length;
      gbm.material.userData.name =  gbm.material.name;
    }
    material.push(gbm.material)
    gbm.geometries.forEach(g => {
      geometriesToMerge.push(g)
    })
  });




  const mergedGeo = BufferGeometryUtils.mergeGeometries(geometriesToMerge);


  let offset = 0;
  let materialIndex = 0;



  Object.values(geosByMat).forEach(gm => {
  
    let count = 0;
    gm.geometries.forEach(geometry => {

      count += geometry.index.count;
    })

    mergedGeo.addGroup(
      offset,
      count,
      materialIndex,
    )


    materialIndex++;
    offset += count;

  })




  const merged = new Mesh(mergedGeo, material)
  return merged;


};
