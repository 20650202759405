import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-change-material-menu',
  templateUrl: './change-material-menu.component.html',
  styleUrls: ['./change-material-menu.component.scss']
})
export class ChangeMaterialMenuComponent implements OnInit {
  @Output() materialSelected = new EventEmitter<number>();
  constructor(private dataService:DataService) { }

  ngOnInit() {
  }

  onMaterialSelected(index: number) {
    this.materialSelected.emit(index)
    console.log('material selected: ', this.dataService.materials[index])
  }

  materialMouseEnter(material) {
    //TODO
  }
  
  materialMouseLeave(material) {
    //TODO
  }

}
