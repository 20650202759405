import { Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';

import * as THREE from "../../assets/threejs/three.module.js";

import { InOutQuadBlend } from "../modules/HelperFunctions";


@Component({
  selector: 'app-photosphere-viewer',
  templateUrl: './photosphere-viewer.component.html',
  styleUrls: ['./photosphere-viewer.component.scss']
})
export class PhotosphereViewerComponent implements OnInit, OnChanges {
  @ViewChild("rendererContainer", { static: true })
  rendererContainer: ElementRef;
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, powerPreference: "high-performance" });
  scene: THREE.Scene;
  camera: THREE.Camera;
  @Input('photosphereImage') photosphereImage: { url: string, cameraParameters: any };
  photosphereGeometry = new THREE.SphereGeometry(1, 128, 128).scale(-1, 1, 1);
  photosphereMaterial = new THREE.MeshBasicMaterial({ color: 'white', wireframe: false })
  photosphereMesh: THREE.Mesh;
  isUserInteracting = false;
  onPointerDownMouseX = 0;
  onPointerDownMouseY = 0;
  lon = 0;
  onPointerDownLon = 0;
  lat = 0;
  onPointerDownLat = 0;
  phi = 0;
  theta = 0;
  autoRotate = false;

  pinchDelta = 0;
  pinchStartFov = 0;

  lastSetWidth = 0;
  lastSetHeight = 0;




  @Output() readyState = new EventEmitter();


  constructor(private ngZone: NgZone) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)

    this.readyState.emit(false)

    this.onWindowResize();
    if (this.photosphereImage) {
      this.autoRotate = true;

      new THREE.TextureLoader().load(
        this.photosphereImage.url,
        (texture) => {
          texture.colorSpace = THREE.SRGBColorSpace;
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          this.photosphereMaterial.map = texture;

          texture.needsUpdate = true;
          this.photosphereMaterial.needsUpdate = true;
          this.updateFromCameraParams(this.photosphereImage.cameraParameters)
          this.updateCameraFromLonLat();
          this.render();
          this.readyState.emit(true)


        });

    } else {

      this.photosphereMaterial.map = null;
      this.photosphereMaterial.needsUpdate = true;
      this.lon = this.lat = 0;
    }


  }

  ngOnInit() {
    this.initScene();
  }

  updateFromCameraParams(params: any) { 
    const fov = params.fov;
    this.camera.fov = THREE.MathUtils.clamp(fov, 10, 75);
    const { lat, lon } = this.convertToLonLat(params)
    this.lon = lon;
    this.lat = lat;

    this.camera.updateProjectionMatrix();

    // setTimeout(() => {
    //   this.resetToCameraConfig()
    // }, 2000);

  }

  convertToLonLat(params) {

    const position = new THREE.Vector3(params.position.x, params.position.y, params.position.z)
    const target = new THREE.Vector3(params.target.x, params.target.y, params.target.z)

    const direction = target.clone().sub(position).normalize();






    const phi = Math.atan2(direction.y, direction.x);
    const theta = Math.acos(direction.z / direction.length());

    return {
      lon: 90 - phi * 180 / Math.PI,
      lat: 90 - theta * 180 / Math.PI
    }
  }


  initScene() {
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement)
    this.scene = new THREE.Scene({ background: 'green' });
    this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    this.photosphereMesh = new THREE.Mesh(
      this.photosphereGeometry,
      this.photosphereMaterial
    )
    this.scene.add(this.photosphereMesh)
    this.photosphereMesh.rotateY(Math.PI)
    this.onWindowResize()

    this.ngZone.runOutsideAngular(() => {
      this.rendererContainer.nativeElement.addEventListener('pointerdown', (event) => { this.onPointerDown(event) });

      document.addEventListener('wheel', (event) => { this.onDocumentMouseWheel(event) });

      window.addEventListener('resize', () => { this.onWindowResize() });

      document.addEventListener('pointermove', (event) => { this.onPointerMove(event) });
      document.addEventListener('pointerup', (event) => { this.onPointerUp(event) });



      this.animate()
    })

  }

  onPointerDown(event) {
    this.autoRotate = false;
    if (event.isPrimary === false) return;

    this.isUserInteracting = true;

    this.onPointerDownMouseX = event.clientX;
    this.onPointerDownMouseY = event.clientY;

    this.onPointerDownLon = this.lon;
    this.onPointerDownLat = this.lat;



    // this.ngZone.runOutsideAngular(() => {
    //   document.addEventListener('pointermove', this.onPointerMove);
    //   document.addEventListener('pointerup', this.onPointerUp);
    // })

  }

  onPointerMove(event) {
    let width = this.rendererContainer.nativeElement.clientWidth;
    let height = this.rendererContainer.nativeElement.clientHeight;


    if (!this.isUserInteracting) return;

    if (event.isPrimary === false) return;

    const hFOV = 2 * Math.atan(Math.tan(this.camera.fov * Math.PI / 180 / 2) * this.camera.aspect) * 180 / Math.PI; // degrees/ height

    this.lon = this.onPointerDownLon - ((Math.atan((event.clientX / width - 0.5) * 2 * Math.tan(hFOV * Math.PI / 180 / 2)) * 180 / Math.PI) - (Math.atan((this.onPointerDownMouseX / width - 0.5) * 2 * Math.tan(hFOV * Math.PI / 180 / 2)) * 180 / Math.PI));
    this.lat = this.onPointerDownLat + (Math.atan((event.clientY / height - 0.5) * 2 * Math.tan(this.camera.fov * Math.PI / 180 / 2)) - Math.atan((this.onPointerDownMouseY / height - 0.5) * 2 * Math.tan(this.camera.fov * Math.PI / 180 / 2))) * 180 / Math.PI;



  }

  onPointerUp(event) {

    this.autoRotate = false;
    if (event.isPrimary === false) return;

    this.isUserInteracting = false;
  }

  onDocumentMouseWheel(event) {
    this.autoRotate = false;
    const fov = this.camera.fov + event.deltaY * 0.05;

    this.camera.fov = THREE.MathUtils.clamp(fov, 10, 75);

    this.camera.updateProjectionMatrix();

  }

  checkIfNeedResize() {

    let width = this.rendererContainer.nativeElement.clientWidth;
    let height = this.rendererContainer.nativeElement.clientHeight;

    if(width !== this.lastSetWidth || this.lastSetHeight !== height) {
      this.onWindowResize();
          console.log('need');
    } else {
      console.log('no need')
    }
  }


  onWindowResize() {
    let width = this.rendererContainer.nativeElement.clientWidth;
    let height = this.rendererContainer.nativeElement.clientHeight;

    this.camera.aspect = width / height;

    this.camera.updateProjectionMatrix();

    this.lastSetHeight = height;
    this.lastSetWidth = width;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.render()
  }

  @HostListener("touchmove", ["$event"])
  onTouchMove(e) {
    this.autoRotate = false;
    e.preventDefault()
    e.stopPropagation();

    if (e.touches) {
      if (e.touches.length == 2) {
        console.log(e)
        const delta = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY)

        if (this.pinchDelta == 0) {
          this.pinchDelta = delta;
        }

        const fov = this.pinchStartFov * this.pinchDelta / delta



        this.camera.fov = THREE.MathUtils.clamp(fov, 10, 75);

        this.camera.updateProjectionMatrix();



      }
    }

  }


  @HostListener("touchstart", ["$event"])
  onTouchStart(e) {
    e.stopPropagation();
    e.preventDefault()

    this.pinchDelta = 0;
    this.pinchStartFov = this.camera.fov;
    // if (e.touches) {
    //   if (e.touches.length == 2) {

    //     const delta = Math.hypot(
    //       e.touches[0].pageX - e.touches[1].pageX,
    //       e.touches[0].pageY - e.touches[1].pageY)


    //   }


    // }

  }

  render() {

    this.renderer.render(this.scene, this.camera)
  }

  updateCameraFromLonLat() {

    this.lat = Math.max(- 85, Math.min(85, this.lat));
    this.phi = THREE.MathUtils.degToRad(90 - this.lat);
    this.theta = THREE.MathUtils.degToRad(this.lon);


    const x = 500 * Math.sin(this.phi) * Math.cos(this.theta);
    const y = 500 * Math.cos(this.phi);
    const z = 500 * Math.sin(this.phi) * Math.sin(this.theta);

    this.camera.lookAt(x, y, z);

  }

  getLonInBoundaries(lon) {
    return lon >= 0 ? ((180 + lon) % 360) - 180 : -(((180 - lon) % 360) - 180);
  }

  resetToCameraConfig() {

    return new Promise<void>(resolve => {

      const lat0 = this.lat;
      const lon0 = this.getLonInBoundaries(this.lon)


      let { lat, lon } = this.convertToLonLat(this.photosphereImage.cameraParameters)
      lon = this.getLonInBoundaries(lon);


      if (Math.abs(lon - lon0) > 180) {
        if (lon0 > 0) {
          lon = lon + 360;
        } else {
          lon = lon - 360;
        }

      }


      const delta = Math.max(Math.abs(lat - lat0), Math.abs(lon - lon0))

      const speed = 180; //deg/sec
      const T = Math.min(0.8, delta / speed); //in sec

      const t0 = performance.now();
      const ivl = setInterval(() => {
        const t = (performance.now() - t0) / (1000 * T)
        if (t < 1) {
          this.lat = lat0 + InOutQuadBlend(t) * (lat - lat0)
          this.lon = lon0 + InOutQuadBlend(t) * (lon - lon0)
        }
        else {
          this.lat = lat;
          this.lon = lon;
          clearInterval(ivl)
          resolve();
        }



      }, 16);
    })

  }

  animate() {

    
    if (this.autoRotate) {

      this.lon += 0.02
    }
    this.updateCameraFromLonLat();
    this.render();

    window.requestAnimationFrame(() => this.animate());
  }

}
