import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ZipService } from 'src/app/services/zip.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';

import * as THREE from "../../../assets/threejs/three.module.js";
import { OrbitControls } from "../../../assets/threejs/controls/OrbitControls.js"
import { ColladaLoader } from "../../../assets/threejs/loaders/ColladaLoader.js"
@Component({
  selector: 'app-collada',
  templateUrl: './collada.component.html',
  styleUrls: ['./collada.component.scss']
})

export class ColladaComponent implements OnInit {
  @Output() closed = new EventEmitter<string>();

  @ViewChild('rendererContainer', { static: true }) rendererContainer: ElementRef;
  @ViewChild('file', { static: true }) file: ElementRef;
  @Input('inputFile') inputFile;
  @Input('name') name;
  renderer = new THREE.WebGLRenderer({ antialias: true });
  scene = null;
  camera = null;
  materials = [];
  controls = null;


  materialTextureMapping = {}

  textures = [];
  jsonScene = null
  tree = {};
  nodes = {};
  scale = 1;

  textureDict = {};
  currentStep = 'Unziping file';

  uploadFinised = false;
  errors = [];

  constructor(private zipService: ZipService, public _DomSanitizer: DomSanitizer, private dataService: DataService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(600, 600);

    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.init();
    this.loadModel();

  }

  init() {

    this.scene = new THREE.Scene();

    // this.scene.add(new THREE.Mesh(new THREE.BoxGeometry(1,1,1),new THREE.MeshBasicMaterial({color:0x00ff00})))

    this.camera = new THREE.PerspectiveCamera(75, 600 / 600, 0.1, 100000);
    this.scene.background = new THREE.Color(0x1a1a1a);



    var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    this.scene.add(ambientLight);


    for (let i = 0; i < 6; i++) {
      let deg = 180 * (1 + i) / 9
      let dl = new THREE.DirectionalLight(0xffffff, 0.1 + 0.05 * Math.sin(Math.PI * (deg / 180)))
      dl.position.set(Math.cos(Math.PI * (deg / 180)), Math.sin(Math.PI * (deg / 180)), 1)
      this.scene.add(dl)

    }
    this.camera.position.set(200, 200, 200);
    this.camera.up = new THREE.Vector3(0, 0, 1);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // How far you can orbit vertically, upper and lower limits.
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;


    // How far you can dolly in and out ( PerspectiveCamera only )
    this.controls.minDistance = 0;
    this.controls.maxDistance = Infinity;

    this.controls.enableZoom = true; // Set to false to disable zooming
    this.controls.zoomSpeed = 1.0;


    this.controls.enablePan = true;

    this.camera.position.set(0, 0, 40);

    this.controls.target = new THREE.Vector3(0, 0, 0)


    // this.animate();

  }

  animate() {
    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(() => this.animate());
  }


  loadModel() {
    this.uploadFinised = false;
    let file = this.inputFile;

    try {

      let loadingManager = new THREE.LoadingManager(() => {
        //when loaded

      });

      var loader = new ColladaLoader(loadingManager);





      let loadColladaFile = (collada) => {
        let model = collada.scene;

        model.rotateX(Math.PI / 2);
        // this.scene.add(model)
        this.scale = model.scale.x;
        this.scanMaterials(model)

        //limit size!
        const size = (new TextEncoder().encode(JSON.stringify(this.jsonScene))).length;
        // if ( size > 104857600) {
        //   console.log('TOO BIG')
        //   this.errors.push('File too big');
        //   this.uploadFinised = true;
        //   this.currentStep = 'Sorry, we did no manage to import your project'
        //   return;
        // }
      

        this.generateTextureDict();
        this.nodes = {};
    
        this.generateTree()
        this.generateMaterialTextureMapping()



        this.createProject();
      }


      this.zipService.unzip(file).then(
        unzipped => {

          this.currentStep = 'processing files';
          this.textures = unzipped.textures;

          unzipped.textures.forEach(texture => {
            texture.url = window.URL.createObjectURL(texture.blob).split('/').pop();


          })



          let dataurl = window.URL.createObjectURL(unzipped.dae.blob);


          loader.load(dataurl, unzipped.textures, (collada) => {

            loadColladaFile(collada);

          })

        },

        rejected => {

          console.warn(rejected)
        }
      )




    } catch (err) {
      console.warn('error')
    }
  }

  scanMaterials(model) {

    let json = model.toJSON();
    this.materials = json.materials;

    this.jsonScene = json;



  }



  generateTextureDict() {
    if (!this.jsonScene.textures) {
      return;
    }

    for (let texture of this.jsonScene.textures) {
      this.textureDict[texture.uuid] = this.textures.filter(t => t.url == texture.name)[0]
    }

  }

  generateTree() {



    let generateNodes = (object) => {

      //change object name to guid!  //@mg les groupes sont des noeuds avec des enfants??? Limiter les sous ensemble Error: Reference.push failed: first argument path specified exceeds the maximum depth that can be written (32) or object contains a cycle in property 'projects.data.ifcData.tree.children.0.children.10.children.0.children.0.children.0.children.0.children.0.children.2.children.0.children.15.children.0.children.0.children.0.children.0.children'
      object.name = object.uuid;
      const node: any = {
        children: [],
        id: object.uuid,
        guid: object.uuid
      }



      if (object.children) {
        for (let children of object.children) {
          const childNode = generateNodes(children);
          childNode.parentId = node.id;
          node.children.push(childNode)

        }
      }


      this.nodes[node.id] = node;
      return node;
    }

    this.tree = generateNodes(this.jsonScene.object);

  }

  generateMaterialTextureMapping() {
    let mapping = {}
   
    for (let material of this.materials.filter(m => m.map)) {
      if (this.textureDict[material.map]) {
        mapping[material.uuid] = material.map 
      }

    }

    this.materialTextureMapping = mapping;


  }



  async createProject() {

    this.currentStep = 'Creating project, This may take few minutes... Please keep this window active.';
    // generate textures array to upload:
    const textures = [];
    
    if (!this.jsonScene.textures) { return }
    for (let texture of this.jsonScene.textures) {
      textures.push({
        id: texture.uuid,
        type: this.textureDict[texture.uuid].type,
        blob: this.textureDict[texture.uuid].blob
      })
    }

    //@mg because it's broken
    // if(this.jsonScene.textures) {
    //   for (let texture of this.jsonScene.textures) {
    //     textures.push({
    //       id: texture.uuid,
    //       type: this.textureDict[texture.uuid].type,
    //       blob: this.textureDict[texture.uuid].blob
    //     })
    //   }
    // }


    //now remove textures from json (we copy our scene)
    let sceneToSave = JSON.parse(JSON.stringify(this.jsonScene));

    if (sceneToSave.textures) {
      delete sceneToSave.textures;

      for (let material of sceneToSave.materials) {
        if (material.map) {
          delete material.map;
        }
      }


    }



    sceneToSave.metadata.scale = this.scale;


    
    await this.dataService.createThreejsProject(sceneToSave, { tree: this.tree, nodes: this.nodes, layers: [] }, this.materialTextureMapping, textures, this.name);

    this.currentStep = 'Project created!';
    this.uploadFinised = true;
  }

  closeClicked() {

    this.closed.emit('closed');
    this.dataService.initProjectSizeParams(true)

  }




}
