

import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';

@Injectable({
    providedIn: 'root',
})


export class ZipService {
    constructor() {

    }



    zip(file, onUpdate?: { (percent: number): void }) {
        return new Promise<any>((resolve, reject) => {
            var zip = new JSZip();

            zip.file(file.name, file)

            console.time('zip timer')

            zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 9
                }
            }, function updateCallback(metaData) {
                //not working so good as this not really run during progress and is just fired all together oncezzzyyyyyyz
                const percent = metaData.percent;

                if (onUpdate) {
                    onUpdate(percent)
                }


            })
                .then((content) => {
                    console.timeEnd('zip timer')
                    resolve(content);
                }).catch(err => {
                    reject(err)
                });
        })

    }

    getZipContent(file) {
        const jszip = new JSZip();
        jszip.loadAsync(file).then((zip) => {
            // Do something with the zipcontent

        });
    }


    async unzipJsonModel(file) {

        const jszip = new JSZip();
        const zip = await jszip.loadAsync(file);

        const json = await (await zip.files['model.json'].async('blob')).text();

        return json;

    }
    async unzip(file) {
        let dae = null;
        const textures = [];

        const jszip = new JSZip();

        const zip = await jszip.loadAsync(file)

        Object.keys(zip.files).forEach(async (filename) => {


            const type = filename.split('.').pop();

            if (type == 'dae') {
                dae = { filename: filename, blob: zip.files[filename].async('blob'), type: type }
            } else {
                if (filename[filename.length - 1] != '/') { textures.push({ filename: filename, blob: zip.files[filename].async('blob'), type: type }) }

            }


        });


        await Promise.all(textures.map(t => t.blob).concat(dae.blob))
        dae.blob = await dae.blob;
        textures.forEach(async (t) => {
            t.blob = await t.blob;
        })
        return {
            textures: textures,
            dae: dae
        }





    }

    downloadContent(content, name) {
        let dataurl = window.URL.createObjectURL(content);
        var a = document.createElement("a");
        a.href = dataurl;
        a.setAttribute('target', '_blank');
        a.setAttribute('download', name)
        a.click();
    }
} { }