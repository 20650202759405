import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { AppActionsService } from '../services/app-actions.service';
import { ToolboxEvent } from '../../models/toolbox-event';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit, OnDestroy {


  selectedMode = null;
  collapsed = false;
  subscriptions: Subscription[] = [];

  // to output a message to parent when the toolbox component is loaded and rdy to startTuto
  statusToolbox = false;
  @Output() eventStatusToolbox = new EventEmitter<any>();

  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {
      if (event.type == null) {
        this.selectedMode = null;
      }
      if (event.type == 'open') {
        this.selectedMode = event.tool;
      }
    }))

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {
        if (viewConfig) {
          if (viewConfig.selectedMode) {
            if (viewConfig.selectedMode == "placeOnMapMode" || viewConfig.selectedMode == "zonesMode") { //we actualy dont want modes beside place on map to be opened.
              this.switchToMode(viewConfig.selectedMode);
            } else {
              this.switchToMode(null)
            }

          } else {
            this.switchToMode(null)
          }
        }
      })
    )

    // send message to parent to inform three is loaded
    this.statusToolbox = true
    this.sendMessage();

  }

  sendMessage() {
    this.eventStatusToolbox.emit(this.statusToolbox);
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  toggleModes(mode) {

    if (this.selectedMode == mode) {
      this.selectedMode = null;
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(mode, 'close', this.constructor.name))
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))


    } else {
      this.selectedMode = mode;
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(this.selectedMode, 'open', this.constructor.name))

    }
    this.dataService.viewConfig['selectedMode'] = this.selectedMode;
    this.appActionsService.selectedModeChanged.next();
  }

  switchToMode(mode) {
    if (mode != this.selectedMode) {
      if (mode) {
        this.selectedMode = mode;
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(this.selectedMode, 'open', this.constructor.name))
      } else {
        this.selectedMode = null;
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))
      }
      this.dataService.viewConfig['selectedMode'] = this.selectedMode;
    }
  }
  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
}
