import { Component, Input, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  constructor(public appActionsService:AppActionsService) { }

  ngOnInit() {
  }

  close() {
    this.appActionsService.openImageUrl = null;
  }

}
