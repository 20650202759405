import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { PhotosphereViewerComponent } from '../photosphere-viewer/photosphere-viewer.component';
import { stringify } from 'querystring';

@Component({
  selector: 'app-light-website',
  templateUrl: './light-website.component.html',
  styleUrls: ['./light-website.component.scss']
})
export class LightWebsiteComponent implements OnInit {

  @ViewChild("videoContainer", { static: true }) videoContainer: ElementRef;
  @ViewChild("video1", { static: true }) video1: ElementRef;
  @ViewChild("video2", { static: true }) video2: ElementRef;
  @ViewChild("source1", { static: true }) source1: ElementRef;
  @ViewChild("source2", { static: true }) source2: ElementRef;
  @ViewChild("photosphereViewerComp", { static: true }) photosphereViewerComp: PhotosphereViewerComponent;

  videos = {};
  currentPhotoKey = null;
  fetchingVideos = true;
  fetchingEquis = true;
  activeVideoElement = null;
  lastActiveVideoElement = null;
  activeSourceElement = null;
  lastActiveSourceElement = null;
  videoPlaying = false;
  labels = {};
  photospheres = {};
  equis = {};
  currentPhotosphere = null;

  photosphereLoaded = false;
  vidsDownloadProgress = 0;

  currentPhotoImage = null;

  touchendX = 0;
  touchstartX = 0;

  aspectRatio = 16 / 9;


  lightWeightDataExists = false;
  imagePinchDelta = 0;
  imageZoomPinchStart = 1;
  imageZoom = 1;
  lastTouch = 0;
  imageTouchStartX = null;
  imageTouchMoveX = null;




  constructor(private dataService: DataService, private appActions: AppActionsService, private cdr: ChangeDetectorRef) { }



  async ngOnInit() {

    this.lightWeightDataExists = this.dataService.lightweightData && this.dataService.lightweightData.lastSucceded


  }

  async ngAfterViewInit() {


    this.initAll()

  }



  animate() {
    if (this.dataService.lightweightData.labels) {
      this.labels = this.updateLabelPositions(this.dataService.lightweightData.labels[this.currentPhotoKey] || {});
      this.sortLabels();
    }
    if (this.dataService.lightweightData.photospheresLabels) {
      this.photospheres = this.updatePhotospheresPositions(this.dataService.lightweightData.photospheresLabels[this.currentPhotoKey] || {});
    }

    window.requestAnimationFrame(() => this.animate());
  }


  async initAll() {
    this.animate();


    if (!this.lightWeightDataExists) {
      return;
    }

    this.aspectRatio = this.dataService.lightweightData.details.width / this.dataService.lightweightData.details.height;

    this.activeVideoElement = this.video1;
    this.lastActiveVideoElement = this.video2;
    this.activeSourceElement = this.source1;
    this.lastActiveSourceElement = this.source2;

    const importantVideos = {};

    if (this.dataService.loadedView) {
      if (this.dataService.loadedView.style !== 'style2') { //for style 1 and 3.
        for (let button of this.dataService.loadedView.menu) {
          if (button.onClick && button.onClick.action == "loadPhoto") {
            importantVideos[this.dataService.loadedView.initialPhoto + '.' + button.onClick.value] = true;


            if (button.children) {
              for (let child_btn of button.children) {
                if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto') {
                  // importantVideos[button.onClick.value + '.' + child_btn.onClick.value] = true;
                  if (child_btn.style3Type == 'floorplan') {
                    importantVideos[button.onClick.value + '.' + child_btn.onClick.value] = true;
                  }
                }
              }


            }
          }
        }

      }

      if (this.dataService.loadedView.style == 'style2') {
        const flatButtonsPhotos = [];
        for (let btn of this.dataService.loadedView.menu) {
          if (btn.onClick && btn.onClick.action == 'loadPhoto' && btn.onClick.value) {
            flatButtonsPhotos.push(btn.onClick.value)
          }
          if (btn.children) {
            for (let child_btn of btn.children) {
              if (child_btn.onClick && child_btn.onClick.action == 'loadPhoto' && child_btn.onClick.value) {
                flatButtonsPhotos.push(child_btn.onClick.value)
              }
            }
          }
        }

        importantVideos[this.dataService.loadedView.initialPhoto + '.' + flatButtonsPhotos[0]] = true;
        for (let i = 1; i < flatButtonsPhotos.length; i++) {
          importantVideos[flatButtonsPhotos[i - 1] + '.' + flatButtonsPhotos[i]] = true;
        }

      }
    }


    this.equis = await this.dataService.getProjectEquis();
    this.fetchingEquis = false;
    this.videos = await this.dataService.getProjectVideos((progress => {
      this.vidsDownloadProgress = 100 * progress;

    }), importantVideos)
    this.fetchingVideos = false;
    console.log('Important videos fetched')



    this.appActions.photoOpened.subscribe(async (key) => {
      if (this.currentPhotoKey == key) {
        console.log('photo already loaded.')
        return;
      }


      this.labels = {};
      this.photospheres = {};

      if (this.currentPhotosphere) {
        await this.photosphereViewerComp.resetToCameraConfig()
      }
      this.currentPhotoImage = null;
      this.currentPhotosphere = null;
      const previousKey = this.currentPhotoKey;
      this.currentPhotoKey = key;


      const photo = this.dataService.photos[this.currentPhotoKey]


      this.playVideo(previousKey, this.currentPhotoKey)

    })

    this.appActions.photsphereLabelClicked.subscribe((id) => {
      this.loadPhotosphere(id)
    })

    this.video1.nativeElement.addEventListener("loadeddata", () => {

      this.video1.nativeElement.play();
      this.video1.nativeElement.classList.remove('off')
      this.video2.nativeElement.classList.add('off')
      this.videoPlaying = true;
      this.cdr.detectChanges();
    });
    this.video2.nativeElement.addEventListener("loadeddata", () => {
      this.video2.nativeElement.play();
      this.video2.nativeElement.classList.remove('off')
      this.video1.nativeElement.classList.add('off')
      this.videoPlaying = true;
      this.cdr.detectChanges();
    });

    this.video1.nativeElement.addEventListener("ended", () => {
      this.onVideoEnded(this.video1);

    });
    this.video2.nativeElement.addEventListener("ended", () => {
      this.onVideoEnded(this.video2)

    });

  }

  onImageDoubleTap(e) {
    function easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
    const img = e.target;
    const parent = img.parentElement;
    const parentRect = parent.getBoundingClientRect();




    let i = 1;
    const finalScale = 3;
    const steps = 20;

    let beginScale = 1
    let endScale = 3;

    if (this.imageZoom > 1) {
      beginScale = this.imageZoom
      endScale = 1;
    }

    const ivl = setInterval(() => {
      if (i > steps) {
        clearInterval(ivl)
        return;
      }


      const beforeScaleZoom = this.imageZoom;


      this.imageZoom = Math.max(beginScale + (endScale - beginScale) * easeInOutQuad(i / steps), 1)
      i++;

      const scale = this.imageZoom / beforeScaleZoom;



      let rect = img.getBoundingClientRect();


      let touch = e.touches[0]; // First touch point
      let touchX = touch.clientX - rect.left;
      let touchY = touch.clientY - rect.top;


      const deltaLeft = scale * (touchX) - touch.clientX;
      const deltaTop = scale * (touchY) - touch.clientY + parentRect.y;



      img.style.height = 100 * this.imageZoom + '%';
      img.style.width = 100 * this.imageZoom + '%';



      parent.scrollLeft = deltaLeft;
      parent.scrollTop = deltaTop;



    }, 16);







  }

  onImageTouchStart(e) {
    this.imageZoomPinchStart = this.imageZoom;
    this.imagePinchDelta = 0;

    if (e.touches.length !== 1) return;

    this.imageTouchMoveX = null;
    this.imageTouchStartX = e.touches[0].pageX;




    const now = performance.now();

    if (now - this.lastTouch < 200) {

      this.lastTouch = 0;
      this.onImageDoubleTap(e)



    } else {
      this.lastTouch = now;
    }







  }

  onImageTouchEnd(e) {

    if (e.touches.length == 0) {
      if (this.imageTouchStartX && this.imageTouchMoveX && this.imageZoom == 1) {
        if (Math.abs(this.imageTouchMoveX - this.imageTouchStartX) > 100)
          this.appActions.lightWebsiteVideoContainerSwipped.next(this.imageTouchStartX - this.imageTouchMoveX)

      }
    }




  }

  onImageTouchMove(e) {

    const img = e.target;

    if (e.touches) {

      if (e.touches.length == 2) {

        this.imageTouchStartX = null; //cancel any touch swip detection.
        this.imageTouchMoveX = null;

        // img.classList.add('cancel-drag');
        e.preventDefault()
        e.stopPropagation();


        const delta = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY)

        if (this.imagePinchDelta == 0) {
          this.imagePinchDelta = delta;
        }

        const beforeScaleZoom = this.imageZoom;
        this.imageZoom = Math.max(1, this.imageZoomPinchStart * delta / this.imagePinchDelta);
        const scale = this.imageZoom / beforeScaleZoom;



        let rect = img.getBoundingClientRect();
        const parent = img.parentElement;
        const parentRect = parent.getBoundingClientRect();

        let touch1 = e.touches[0]; // First touch point
        let touch1X = touch1.clientX - rect.left;
        let touch1Y = touch1.clientY - rect.top;

        let touch2 = e.touches[1]; // First touch point
        let touch2X = touch1.clientX - rect.left;
        let touch2Y = touch1.clientY - rect.top;

        const middleTouchX = (touch1X + touch2X) / 2;
        const middleTouchY = (touch1Y + touch2Y) / 2;
        const middleTouchClientX = (touch1.clientX + touch2.clientX) / 2;
        const middleTouchClientY = (touch1.clientY + touch2.clientY) / 2;


        const deltaLeft = scale * (touch1X) - touch1.clientX;
        const deltaTop = scale * (touch1Y) - touch1.clientY + parentRect.y;


        img.style.height = 100 * this.imageZoom + '%';
        img.style.width = 100 * this.imageZoom + '%';

        // img.style.height = '300%';
        // img.style.width ='300%';

        parent.scrollLeft = deltaLeft;
        parent.scrollTop = deltaTop;


      } else {
        // img.classList.remove('cancel-drag');
        if (e.touches.length == 1) {
          if (performance.now() - this.lastTouch > 100)
            this.imageTouchMoveX = e.touches[0].pageX;
        }
      }


    }

  }

  videoTouchStart(e) {

    this.touchstartX = e.changedTouches[0].screenX
  }

  videoTouchEnd(e) {

    if (this.videoPlaying) {
      return
    }
    this.touchendX = e.changedTouches[0].screenX

    this.appActions.lightWebsiteVideoContainerSwipped.next(this.touchstartX - this.touchendX)

  }


  onPhotoOrVideoMouseUp() {
    this.appActions.lightWebsiteVideoContainerSwipped.next(0)
  }



  onPhotosphereReadyState(state) {
    this.photosphereLoaded = state;
    this.cdr.detectChanges();
  }

  onVideoEnded(videoElementRef: ElementRef) {
    if (this.activeVideoElement !== videoElementRef) { //cancel on video ended if its not the last active video...
      return;
    }


    const equiUrl = this.equis[this.currentPhotoKey + '.webp'];

    if (equiUrl) {

      this.loadEqui()
    } else {
      this.currentPhotoImage = this.dataService.lightweightData.photos[this.currentPhotoKey]
    }




    if (this.dataService.lightweightData.labels) {
      this.labels = this.updateLabelPositions(this.dataService.lightweightData.labels[this.currentPhotoKey] || {});
      this.sortLabels();
    }

    if (this.dataService.lightweightData.photospheresLabels) {
      this.photospheres = this.updatePhotospheresPositions(this.dataService.lightweightData.photospheresLabels[this.currentPhotoKey] || {});
    }

    this.videoPlaying = false;

  }


  sortLabels() { //copied from labels-editor as we dont run it on lightversion...

    let sortOrder = [];
    if (this.dataService.viewConfig.labelsConfig) {
      if (this.dataService.viewConfig.labelsConfig['sortOrder']) {
        sortOrder = this.dataService.viewConfig.labelsConfig['sortOrder'];
      }

    }

    for (let key in this.labels) { //pushing keys that are not in the sort order!
      if (sortOrder.indexOf(key) == -1) {
        sortOrder.push(key);
      }

    }

    //removing keys that doesnt have labels:
    let keysToRemoveFromSort = [];
    for (let key of sortOrder) {
      if (this.labels[key] == null) {
        keysToRemoveFromSort.push(key)
      }
    }
    for (let key of keysToRemoveFromSort) {
      sortOrder.splice(sortOrder.indexOf(key), 1)
    }

    for (let i = 0; i < sortOrder.length; i++) { //setting index label according to new order
      this.labels[sortOrder[i]].index = i;

    }


  }

  updateLabelPositions(lightweightLabelPositioningData) {
    const newLabels = {};

    let width = this.videoContainer.nativeElement.clientWidth;
    let height = this.videoContainer.nativeElement.clientHeight;
    let xOffset = (width - height * this.aspectRatio) / 2;


    let zoomOffset = {
      x: 0,
      y: 0
    }

    const img = document.getElementById("static-photo-image");
    if (img) {
      const imgRect: any = img.getBoundingClientRect();

      width = imgRect.width;
      height = imgRect.height;
      zoomOffset.x = imgRect.left;
      zoomOffset.y = imgRect.top;
      xOffset = (width - height * this.aspectRatio) / 2;
    }




    for (let key in lightweightLabelPositioningData) {
      if (this.dataService.labels[key] && !lightweightLabelPositioningData[key].blocked && !lightweightLabelPositioningData[key].notInView) {
        newLabels[key] = this.dataService.labels[key]
        if (newLabels[key]) {
          newLabels[key].screenPosition = {
            x: zoomOffset.x + xOffset + height * this.aspectRatio * lightweightLabelPositioningData[key].screenPosition.x / this.dataService.lightweightData.details.width,
            y: zoomOffset.y + height * lightweightLabelPositioningData[key].screenPosition.y / this.dataService.lightweightData.details.height,
          }
        }
      }


    }




    return newLabels;
  }

  updatePhotospheresPositions(photospheresPositioningData) {
    let newPhotospheres = {}
    let width = this.videoContainer.nativeElement.clientWidth;
    let height = this.videoContainer.nativeElement.clientHeight;
    let xOffset = (width - height * this.aspectRatio) / 2;
    let zoomOffset = {
      x: 0,
      y: 0
    }

    const img = document.getElementById("static-photo-image");
    if (img) {
      const imgRect = img.getBoundingClientRect();

      width = imgRect.width;
      height = imgRect.height;
      zoomOffset.x = imgRect.left;
      zoomOffset.y = imgRect.top;
      xOffset = (width - height * this.aspectRatio) / 2;
    }

    for (let key in photospheresPositioningData) {
      if (photospheresPositioningData[key].screenPosition && !photospheresPositioningData[key].screenPosition.hidden) {
        newPhotospheres[key] = {
          hidden: false
        }

        //fix position:

        newPhotospheres[key].screenPosition = {
          x: zoomOffset.x + xOffset + height * this.aspectRatio * photospheresPositioningData[key].screenPosition.x / this.dataService.lightweightData.details.width,
          y: zoomOffset.y + height * photospheresPositioningData[key].screenPosition.y / this.dataService.lightweightData.details.height,
        }
      }

    }
    return newPhotospheres;
  }

  loadEqui() {

    if (this.currentPhotoKey) {

      const photo = this.dataService.photos[this.currentPhotoKey]


      const url = this.equis[this.currentPhotoKey + '.webp'];
      if (url) {
        this.currentPhotosphere = {
          url,
          cameraParameters: photo.viewConfig.cameraParameters,
          fromPhoto: true
        };
      }




    }
  }

  async loadPhotosphere(photosphereId) {
    const loadId = this.appActions.addUserBlockingTask('loadingMaterialCongfig');
    const photosphere = this.dataService.photospheres[photosphereId];
    const imgUrl = await this.dataService.getPhotosphereImageUrl(photosphere.storageName)
    await fetch(imgUrl); // prefetch

    if (photosphere) {

      try {
        this.currentPhotosphere = {
          url: imgUrl,
          fromPhoto: false,
          cameraParameters: {
            position: { x: 0, y: 0, z: 0 },
            target: { x: -Math.sin(photosphere.yaw), y: Math.cos(photosphere.yaw), z: 0 },
            fov: 75
          }
        }
        this.appActions.removeUserBlockTask(loadId);
      } catch (err) {
        this.appActions.removeUserBlockTask(loadId);
        return;
      }

    }

  }

  removeEqui() {
    this.currentPhotosphere = null;
  }

  playVideo(from, to) {





    const video = this.videos[from + '.' + to + '.webm']
    if (video) {

      let temp = this.activeVideoElement;
      this.activeVideoElement = this.lastActiveVideoElement;
      this.lastActiveVideoElement = temp;

      temp = this.activeSourceElement;
      this.activeSourceElement = this.lastActiveSourceElement;
      this.lastActiveSourceElement = temp;


      this.activeSourceElement.nativeElement.setAttribute('src', video);
      this.activeSourceElement.nativeElement.setAttribute('type', 'video/webm');

      this.activeVideoElement.nativeElement.load();
      // this.activeVideoElement.nativeElement.play();
    } else {



      this.onVideoEnded(this.activeVideoElement)  // we triger this to load the equi/image and config etc...





    }



    //check if finished correctly, if yes, update labels. ??


  }

  playFirstToVideo(photoKey) {
    for (let vidKey in this.videos) {
      const [from, to] = vidKey.split('.');

      if (to == photoKey) {
        this.playVideo(from, to)
        return;
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    if (this.dataService.lightweightData.labels) {
      this.labels = this.updateLabelPositions(this.dataService.lightweightData.labels[this.currentPhotoKey] || {});
      this.sortLabels();
    }
    if (this.dataService.lightweightData.photospheresLabels) {
      this.photospheres = this.updatePhotospheresPositions(this.dataService.lightweightData.photospheresLabels[this.currentPhotoKey] || {});
    }


  }

}
