import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import * as THREE from "../../assets/threejs/three.module.js";

@Component({
  selector: 'app-object-data',
  templateUrl: './object-data.component.html',
  styleUrls: ['./object-data.component.scss']
})
export class ObjectDataComponent implements OnInit, OnDestroy {
  objectData = null;
  relatedNotes = [];
  openNoteId = null;

  layerName = null;
  materials = [];
  label = null;

  oid = null;
  guid = null;
  fieldNamesTranslater = {
    '_t': 'Type',
    'GlobalId': 'GUID',
    'ObjectType': 'Object Type'
  }
  fieldFilterOut = { //need to build a pipe...
    oid: true,
    Tag: true,
  }
  subscriptions: Subscription[] = [];

  selectMaterialToAdd = -1;
  hiddenOids = {};
  isHidden = false;

  constructor(
    private appActionsService: AppActionsService,
    private dataService: DataService,
    private translate: TranslateService) { }


  ngOnInit() {
    this.subscriptions.push(this.appActionsService.toggleVisibilityOfObjects.subscribe((toggle) => {
      for (let oid of toggle.oids) {
        this.hiddenOids[oid]  = !toggle.state
      }
      
    }))


    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe((oid) => {
      if (oid) {
        this.loadObject(oid);
      }
      else {
        this.closeObject();
      }
    }))
    this.subscriptions.push(
      this.appActionsService.selectedMaterialsConfig.subscribe(configName => {
        if (this.oid) {
          this.loadObject(this.oid);
        }
        else {
          this.closeObject();
        }
      })
    )
    this.subscriptions.push(this.appActionsService.openNote.subscribe(note => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = null;
      }
    }))


    this.subscriptions.push(this.dataService.labelsUpdated.subscribe(() => {
      if (this.oid) {
        this.loadObject(this.oid)
      }
    }))

    this.subscriptions.push(this.dataService.notesObjectsRelationsDataFetched.subscribe(() => {
      if (this.oid) {
        this.loadObject(this.oid)
      }
    }))

    this.subscriptions.push(this.dataService.notesDataFetched.subscribe(() => {
      if (this.oid) {
        this.loadObject(this.oid)
      }
    }))
  }

  ngOnDestroy() {
    this.closeObjectDataClicked();

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadObject(oid) {
    this.oid = oid;
    this.guid = this.dataService.getGuidOfObjectId(oid);
    this.objectData = this.dataService.getObjectDataByObjectId(oid);
    this.relatedNotes = this.dataService.getRelatedNoteOfObject(this.guid);
    this.layerName = this.dataService.objectsData[oid].layerName;


    if (Array.isArray(this.dataService.objectsData[oid].material)) {
      this.materials = this.dataService.objectsData[oid].material.filter((item, pos) => {
        return this.dataService.objectsData[oid].material.indexOf(item) == pos;
      });
    } else {

      if (this.dataService.objectsData[oid].material) {
        this.materials = [this.dataService.objectsData[oid].material]
      } else {
        this.materials = [];
      }

    }
    this.label = this.dataService.getLabelOfObject(this.guid)


    if (
      this.appActionsService.selectedDataMode == 'layers'
    ) {
      //select the first material
      this.layerClicked();
    }
  }



  //newMaterialIndex : -1 if new material, else the index in the current dataservice materials
  changeMaterial(indexLocalMaterial, newMaterialIndex) {

    const newDataServiceMaterialIndex = this.dataService.setNewMaterialToObject(newMaterialIndex, this.guid, this.materials[indexLocalMaterial].uuid, this.oid)

    //test if it is a new material so use new index return by dataservice
    if (newDataServiceMaterialIndex) {
      this.materials.splice(indexLocalMaterial, 1, this.dataService.materials[newDataServiceMaterialIndex].material)
      this.materialClicked({ name: this.dataService.materials[newDataServiceMaterialIndex].material.name })
    }
    else {
      //means replaced by existing material
      this.materials.splice(indexLocalMaterial, 1, this.dataService.materials[newMaterialIndex].material)
      this.materialClicked({ name: this.dataService.materials[newMaterialIndex].material.name })
    }
  }

  removeMaterial(index) {
    //remove from object
    if (Array.isArray(this.dataService.objectsData[this.oid].material)) {
      this.dataService.objectsData[this.oid].material.splice(index, 1)
    }
    else {
      this.dataService.objectsData[this.oid].material = null
    }
    //remove locally
    this.materials.splice(index, 1)
    //TODO remove from this.dataService.materials
  }

  closeObject() {
    this.guid = null;
    this.oid = null;
    this.objectData = null;
    this.relatedNotes = null;
  }

  closeObjectDataClicked() {

    this.appActionsService.chosenObjectOid.next(null);

  }

  noteClickedOnList(note) {
    this.appActionsService.openNote.next(note);
  }

  addNewNoteClicked() {
    const newName = this.translate.instant('note.newName');
    const newDescription = this.translate.instant('note.newDescription');
    const newType = this.translate.instant('note.newType');
    //this.dataService.createNote('New note title', 'Enter note description here', 'none').then(note => {
    this.dataService.createNote(newName, newDescription, newType).then(note => {

      this.dataService.addRelatedObjectToNote(note.id, this.guid).then(() => {
        this.appActionsService.openNote.next(note)
      })

    })
  }


  materialClicked(material) {
    this.appActionsService.objectDataAction.next({ action: 'materialClicked', materialName: material.name })
  }

  materialMouseEnter(material) {
    this.appActionsService.objectDataAction.next({ action: 'materialMouseEnter', materialName: material.name })
  }

  materialMouseLeave(material) {
    this.appActionsService.objectDataAction.next({ action: 'materialMouseLeave', materialName: material.name })
  }

  layerClicked() {
    this.appActionsService.objectDataAction.next({ action: 'layerClicked', layerName: this.layerName })
  }

  showHideObject() {
    this.appActionsService.toggleObjectHidden.next(this.oid)
    
  }

  async deleteObject() {
    await this.dataService.deleteObject(this.oid);
    this.appActionsService.chosenObjectOid.next(null)
      
  }

  flyToClicked() {
    this.appActionsService.selectAndFlyToObjectClicked.next(
      this.oid
    )
  }



  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }



}
