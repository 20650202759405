export class ViewConfig {
    public pdfCompareMode: any;
    public labelsConfig:any;
    public layersConfig:any;
    public treeConfig:any;
    public zonesMode: any[];
    public selectedMode: any;
    public MaterialsMode: any;
    public cameraParameters : any;
    public mapboxMode: any;
    public clippingMode: any;
    public photospheresConfig:any;
    public terrainOn:boolean;
    public dimensioning:any;
    public measuresConfig:any
    public modelsConfig:any;
    public zonesGlobalOpacity: number;

    constructor(){
      this.pdfCompareMode = {};
      this.labelsConfig  = {};
      this.layersConfig  = [];
      this.treeConfig  = {};
      this.zonesMode  = [];
      this.selectedMode  = {};
      this.MaterialsMode = {};
      this.cameraParameters = {};
      this.mapboxMode = 'off';
      this.terrainOn = true;
      this.clippingMode = {}
      this.photospheresConfig = {};
      this.dimensioning = {};
      this.modelsConfig = {};
      this.measuresConfig = {};
      this.zonesGlobalOpacity = 0.5;
    }
}
