import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { ViewButton } from '../../../../models/viewButton.model';
import { Subscription } from 'rxjs';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { DataService } from 'src/app/services/data.service';
import { debounce } from 'lodash';

@Component({
  selector: 'app-button-editor',
  templateUrl: './button-editor.component.html',
  styleUrls: ['./button-editor.component.scss']
})
export class ButtonEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input('button') button!: ViewButton;
  @Input('viewId') viewId;
  @Output() buttonDataChanged: EventEmitter<any> = new EventEmitter<any>();
  note: any;
  subscriptions: Subscription[] = [];
  debounceDataChanged = debounce(this.dataChanged, 3000);
  iconOptions= [
    'home','double_arrow',
    'priority_high',
    'question_mark', 'collections',
    'stars', 'verified_user', 'work', 'chat', 'note',
    'flag', 'waves', 'weekend', 'attach_file', 'bubble_chart',
    'looks', 'directions_bike', 'local_cafe', 'local_hotel', 'local_offer',
    'local_parking', 'local_shipping', 'beach_access', 'kitchen', 'meeting_room',
    'pool', 'school', 'whatshot', 'audiotrack', 'family_restroom', 'electric_bolt', 'card_travel', 'construction',
    'drive_eta',
    'power', 'restaurant',
    'restaurant_menu', 'airline_seat_individual_suite', 'wc', 'child_friendly'];

  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    this.appActionsService.buttonEditorOpened.next();
    this.subscriptions.push(this.appActionsService.itemDroppedOnButton.subscribe(item => {

      if (item.type == 'photo') {
        this.linkPhotoToButton(item.value);
      }

      if (item.type == 'note') {
        this.linkNoteToButton(item.value);
      }

    }))
    this.supportNewData()

  }

  supportNewData() {
    if (!this.button.text) {
      this.button.text = null;
    }
    if (!this.button.rooms) {
      this.button.rooms = [];
    }
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  ngOnChanges() {
    this.supportNewData()

    if (this.button.onClick) {
      if (this.button.onClick.action == 'loadNote') {
        this.note = this.dataService.getNoteById(this.button.onClick.value);
        return;
      }

    }

    this.note = null;

 


  }

  actionChanged() {
    this.button.onClick.value = null;
    this.note = null;
    this.dataChanged();


  }

  noEnterPredicte() {
    return false;
  }

  linkPhotoToButton(photoId) {

    this.button.onClick.value = photoId;
    if (this.button.title == '') {
      this.button.title = this.dataService.photos[photoId].title;
    }
    this.dataChanged();

  }

  linkNoteToButton(noteId) {

    this.button.onClick.value = noteId;
    this.note = this.dataService.getNoteById(noteId);
    if (this.button.title == '') {
      this.button.title = this.note.title;
    }
    this.dataChanged();

  }

  linkDownloadUrlToButton(url) {
    this.button.onClick.value = url;
    this.dataChanged();
  }

  addChildButton() {
    if (this.button.children == undefined) {
      this.button['children'] = [];
    }

    this.button.children.push(new ViewButton('', null, null, null, 'sub', { action: 'loadPhoto', value: null }));
    this.dataChanged();
  }

  removeButton() {
    this.dataService.removeButtonFromView(this.viewId, this.button.id)
    this.dataChanged();
    this.close();
  }

  close() {
    this.dataChanged();
    this.appActionsService.closeButtonEditor.next();
  }

  trimOnClickValue() {
    this.button.onClick.value = this.button.onClick.value.trim();
    this.dataChanged();
  }

  dataChanged() {
    console.log('changed?')
    this.appActionsService.checkPhotosLinkedToButton.next();
    this.buttonDataChanged.emit(null)
  }

  selectIconOnMenu(iconstring) {
    this.button.icon =  iconstring;
    this.dataChanged();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }
}
