import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from "../services/data.service";
import { ToolboxEvent } from '../../models/toolbox-event';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @ViewChild('getFocusLeave', { static: true }) getFocusLeaveElement: ElementRef;

  selectedTabIndex = 0;
  subscriptions: Subscription[] = []

  fov = 72
  ambientOcclusion = false
  ambientLightIntensity = 0.6
  edgeBimshow = false
  edgeBimshowColor = '#000000'
  edgeBimshowOpacity = 1

  //for sun light
  directionalLightsCoefIntensity = 1.5
  directionalLightsEnable = true
  directionalLightPositionMethod = 'sun' //can be sun or manual
  directionalLightPositionManual = { x: 0, y: 0, z: 40 }
  directionalLightSourceGizmo = false
  directionalLightsDate = null
  directionalLightSource = null
  timezoneLight = ":00+00:00"


  //for shadows
  boundariesDetails = false
  directionalLightsCastShadows = false
  directionalLightShadowComputation = true
  directionalLightsCastShadowsHelper = false
  directionalLightShadow3JSObject = null

  blockOrbitControl = true;
  blockZControl = false;


  debugCamera = false;
  advancedCameraOptions = false;

  contrastLightAngle = 0;
  contrastLightIntensity = 0.3;

  constructor(
    private appActionsService: AppActionsService,
    private dataService: DataService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  ngOnInit() {

    this.dataService.contrastLightAngle.next(0);
    this.dataService.contrastLightIntensity.next(0.15)

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {
        if (viewConfig) {
          if (viewConfig.cameraParameters != null) {

            if (viewConfig.cameraParameters.directionalLightOptions) {
              if (this.debugCamera) {
                this.debugCamera = !!viewConfig.cameraParameters.directionalLightOptions.advancedCameraOptions;
                this.onDebugCameraChange();
              }
            }
          }
        }

      })
    )

    this.subscriptions.push(
      this.dataService.contrastLightAngle.subscribe((angle) => {
        this.contrastLightAngle = angle;
      })
    )

    this.subscriptions.push(
      this.dataService.contrastLightIntensity.subscribe((intensity) => {
        this.contrastLightIntensity = intensity;
      })
    )

    this.subscriptions.push(
      this.appActionsService.debugShadowCamera.subscribe((value) => {
        this.debugCamera = value;
      })
    )
    this.subscriptions.push(
      this.appActionsService.debugShadowCamera.subscribe((value) => {
        this.debugCamera = value;
      })
    )


    this.subscriptions.push(
      this.dataService.cameraFovUpdated.subscribe((value) => {
        this.fov = value;
      })
    )
    this.subscriptions.push(
      this.dataService.photoAmbientOcclusion.subscribe((saoon) => {
        this.ambientOcclusion = saoon
      })
    )
    this.subscriptions.push(   //@mg orbit test nice for images
      this.dataService.blockOrbit.subscribe((value) => {
        this.blockOrbitControl = value
      })
    )
    this.subscriptions.push(   //@mg orbit test nice for images
      this.dataService.blockZ.subscribe((value) => {
        this.blockZControl = value
      })
    )
    //set the ambient light intensity
    this.ambientLightIntensity = this.dataService.ambientLightIntensity.getValue()
    this.subscriptions.push(
      this.dataService.ambientLightIntensity.subscribe(ambientLightIntensity => {
        this.ambientLightIntensity = ambientLightIntensity
      })
    )



    //for edge geometry
    this.edgeBimshow = this.dataService.edgeBimshow.getValue()
    this.subscriptions.push(
      this.dataService.edgeBimshow.subscribe(edgeBimshow => {
        this.edgeBimshow = edgeBimshow
      })
    )
    this.edgeBimshowColor = this.dataService.edgeBimshowColor.getValue()
    this.subscriptions.push(
      this.dataService.edgeBimshowColor.subscribe(color => {
        this.edgeBimshowColor = color
      })
    )
    this.edgeBimshowOpacity = this.dataService.edgeBimshowOpacity.getValue()
    this.subscriptions.push(
      this.dataService.edgeBimshowOpacity.subscribe(opacity => {
        this.edgeBimshowOpacity = opacity
      })
    )



    //for shadowCameraTarget change
    this.subscriptions.push(this.appActionsService.threejsClick.subscribe(threejsClickEvent => {
      if (this.appActionsService.updateShadowCameraTargetMode) {


        this.appActionsService.directionalLightOptions.target = {
          x: threejsClickEvent.positionWorldSpace.x,
          y: threejsClickEvent.positionWorldSpace.y,
          z: threejsClickEvent.positionWorldSpace.z
        }
        this.appActionsService.updateShadowCameraTargetMode = false;
        this.directionalLightOptionsChanged()
      }
    }))
  }

  fovChange(value) {
    this.fov = Number(value);

    this.dataService.cameraFovUpdated.next(this.fov);
  }
  switchAmbientOcclusion() {
    this.dataService.photoAmbientOcclusion.next(this.ambientOcclusion)
  }
  ambientLightIntensityChange(value) {
    this.dataService.ambientLightIntensity.next(value)
  }



  edgeBimshowChange() {
    this.dataService.edgeBimshow.next(this.edgeBimshow)
  }
  edgeBimshowColorChange() {
    this.dataService.edgeBimshowColor.next(this.edgeBimshowColor)
  }
  edgeBimshowOpacityChange() {
    this.dataService.edgeBimshowOpacity.next(this.edgeBimshowOpacity)
  }
  shadowCameraTargetPinClick() {
    this.appActionsService.updateShadowCameraTargetMode = !this.appActionsService.updateShadowCameraTargetMode;
    this.shadowCameraInputChanged();
  }

  shadowCameraInputChanged() {
    this.debugCamera = true;
    this.onDebugCameraChange();
    this.directionalLightOptionsChanged();
  }

  directionalLightOptionsChanged() {
    this.appActionsService.directionalLightOptionsChanged.next();
  }


  loadPhotoView(photo) {
    this.dataService.loadPhotoView(photo)
  }
  onCameraButtonMouseEnter() {
    this.appActionsService.showCameraOverlay = true;
  }

  onCameraButtonMouseOut() {
    this.appActionsService.showCameraOverlay = false;
  }
  refreshPhotoView(photoId) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('gallery', 'updates', this.constructor.name, { refreshPhoto: photoId }))
  }


  //@mg Orbit and Z limits
  onBlockOrbitChanged() {
    this.dataService.blockOrbit.next(this.blockOrbitControl)
  }

  onBlockZChanged() {
    this.dataService.blockZ.next(this.blockZControl)
  }

  onDebugCameraChange() {
    this.appActionsService.debugShadowCamera.next(this.debugCamera)
  }

  advancedCameraOptionsChanged() {
    if (!this.appActionsService.directionalLightOptions.advancedCameraOptions) {
      this.appActionsService.resetShadowCamSize.next()
      this.appActionsService.resetShadowTarget.next()
      this.debugCamera = false;

    } else {
      this.debugCamera = true;
    }

    this.onDebugCameraChange();
  }

  setDirectionalLightColor(value) {
    this.appActionsService.directionalLightOptions.color = value;
    this.directionalLightOptionsChanged();
  }

  onTabGroupIndexChanged(event) {
   this.selectedTabIndex = event;
  }
  
  contrastLightAngleChanged(event) {

    this.dataService.contrastLightAngle.next(this.contrastLightAngle)
  }

  contrastLightIntensityChanged(event) {

    this.dataService.contrastLightIntensity.next(this.contrastLightIntensity)
  }

  
  //get events to avoid propagation to 3JS and trigger unwanted action
  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event?) {
    this.getFocusLeaveElement.nativeElement.focus()
  }
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

  
}
