import { UUID } from 'angular2-uuid';

export class ViewButton {
    public id: string;
    public title: string;
    public tooltipText: string;
    public color: string;
    public background: string;
    public onClick: any;
    public children: any;
    public type: string;
    public text: string;
    public rooms: { name: string, area: number }[];
    public style3Type: string;
    public subtitle: string;
    public icon:string;
    public iconFill: boolean;



    constructor(title, tooltipText, color, background, type, onClick) {

        this.id = UUID.UUID();
        this.type = type;
        this.title = title;
        this.tooltipText = tooltipText;
        this.color = color;
        this.background = background;
        this.children = null;
        this.onClick = onClick;
        this.rooms = [];
        this.text = null;
        this.style3Type = "exterior"
        this.subtitle = null;
        this.icon =null;
        this.iconFill = false;



    }

}
