import {
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  Color,
  Float32BufferAttribute
} from "../threejs/three.module.js"
import { discardHiddenOnBeforeCompileFunc } from "../../assets/utils/ThreejsCalcFunctions.js";
import * as  BufferGeometryUtils from '../../assets/threejs/utils/BufferGeometryUtils.js';

/*
 *create an object to display edge all geometries of the current scene
 */

class EdgeBimshow {
  //time in millisecond
  constructor(threeJSComponent) {
    this.threeJSComponent = threeJSComponent
    this.status = false
    this.edgesMesh = null
    this.subscriptions = []
    this.initSubscriptions()
  }
  dispose() {
    this.remove()
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  initSubscriptions() {
    this.subscriptions.push(
      this.threeJSComponent.dataService.edgeBimshow.subscribe(status => {
        this.display(status)
      })
    );
    this.subscriptions.push(
      this.threeJSComponent.dataService.edgeBimshowOpacity.subscribe(opacity => {
        this.setOpacity(opacity)
      })
    );
    this.subscriptions.push(
      this.threeJSComponent.dataService.edgeBimshowColor.subscribe(color => {
        this.setColor(color)
      })
    );
    this.subscriptions.push(
      this.threeJSComponent.appActionsService.loadViewConfig.subscribe(async (viewConfig) => {
        await this.remove()
        if (
          viewConfig &&
          'cameraParameters' in viewConfig &&
          'edgeOptions' in viewConfig.cameraParameters
        ) {

          const edgeOptions = viewConfig.cameraParameters.edgeOptions

          if ('status' in edgeOptions && edgeOptions.status) {
            if ('opacity' in edgeOptions) {
              this.threeJSComponent.dataService.edgeBimshowOpacity.next(edgeOptions.opacity)
            }
            else {
              this.threeJSComponent.dataService.edgeBimshowOpacity.next(.3)
            }
            if ('color' in edgeOptions) {
              this.threeJSComponent.dataService.edgeBimshowColor.next(edgeOptions.color)
            }
            else {
              this.threeJSComponent.dataService.edgeBimshowColor.next('#000000')
            }
            this.threeJSComponent.dataService.edgeBimshow.next(edgeOptions.status)
          }
          else {
            this.threeJSComponent.dataService.edgeBimshow.next(false)
            this.threeJSComponent.dataService.edgeBimshowOpacity.next(.3)
            this.threeJSComponent.dataService.edgeBimshowColor.next('#000000')
          }

        }
        else {
          this.threeJSComponent.dataService.edgeBimshow.next(false)
          this.threeJSComponent.dataService.edgeBimshowOpacity.next(.3)
          this.threeJSComponent.dataService.edgeBimshowColor.next('#000000')
        }
      })
    )
  }

  setClippingPlanes(clippingPlanes) {
    if (this.edgesMesh) {
      this.edgesMesh.material.clippingPlanes = clippingPlanes
    }
  }
  //don't show it if source mesh is hidden
  setVisibility(status) {
    //todo
    if (this.edgesMesh) {
      this.edgesMesh.visible = status;
    }
  }

  setVisibilityByMesh(eidsVisDict) {

    if (this.edgesMesh) {
      this.edgesMesh.geometry.setAttribute('hidden', new Float32BufferAttribute(new Float32Array(this.edgesMesh.geometry.attributes.expressID.array).map((_eid) => { return (eidsVisDict[_eid] ? 0.0 : 1.0) }), 1));
    }


  }

  async display(status) {
    this.status = status
    if (status) {
      await this.add()
      this.threeJSComponent.updateVisibilityInSingleMesh()
    }
    else {
      await this.remove()
    }
   
    this.threeJSComponent.render()
  }
  //setAllObjectToWireframe
  add() {
    return new Promise(async (resolve, reject) => {
      await this.remove() //in case already existing


      if (this.edgesMesh) {
        this.edgesMesh.visible = true;
        this.threeJSComponent.updateVisibilityInSingleMesh();
        resolve(true);
        return;
      }

      this.edgesMesh = null;
      const geos = [];
      for (let guid in this.threeJSComponent.objectsGuidQuery.threejs) {

        const mesh = this.threeJSComponent.objectsGuidQuery.threejs[guid];
        if (mesh.geometry) {
          let geo = new EdgesGeometry(mesh.geometry);

          const count = geo.getAttribute('position').count;
          if (count > 0) {

            const expressID = mesh.geometry.attributes.expressID.array[0];
            const a = [];
            for (let i = 0; i < count; i++) {
              a.push(expressID)
            }
            geo.setAttribute('expressID', new Float32BufferAttribute(new Float32Array(a), 1));


            geos.push(geo);
          }

        }



      }


      const mergedGeo = BufferGeometryUtils.mergeGeometries(geos);
      let mat = new LineBasicMaterial(
        {
          color: this.threeJSComponent.dataService.edgeBimshowColor.getValue(),
          opacity: this.threeJSComponent.dataService.edgeBimshowOpacity.getValue(),
          transparent: this.threeJSComponent.dataService.edgeBimshowOpacity.getValue() == 1 ? false : true
        }
      );

      mat.onBeforeCompile = discardHiddenOnBeforeCompileFunc;

      const edges = new LineSegments(mergedGeo, mat)


      edges.renderOrder = 1 // make sure wireframes are rendered 2nd

      //set the size, position and orientation.
      //if added directly to the mesh , after conflict with mouse intersection
      edges.scale.copy(this.threeJSComponent.getWorldScale(this.threeJSComponent.singleMesh))
      this.threeJSComponent.singleMesh.getWorldQuaternion(edges.quaternion)
      this.threeJSComponent.singleMesh.getWorldPosition(edges.position)

      this.threeJSComponent.scene.add(edges)
      this.threeJSComponent.updateVisibilityInSingleMesh();


      this.setClippingPlanes(this.threeJSComponent.clippingPlanes)

      this.edgesMesh = edges;

      resolve(true)
    })
  }

  remove() {
    return new Promise((resolve, reject) => {
      if (this.edgesMesh) {

        this.edgesMesh.visible = false;


        this.status = false
        resolve(true)
      }
      else {
        resolve(true)
      }
    });
  }

  setColor(color) {
    if (this.edgesMesh) {
      this.edgesMesh.material.color = new Color(color)
      this.threeJSComponent.render()
      this.threeJSComponent.askForRender()
    }

  }

  setOpacity(opacity) {
    if (this.edgesMesh) {
      this.edgesMesh.material.opacity = opacity
      this.edgesMesh.material.transparent = opacity == 1 ? false : true
      this.edgesMesh.material.needsUpdate = true
      this.threeJSComponent.render()
      this.threeJSComponent.askForRender()
    }

  }

  getOptions() {

    const status = this.threeJSComponent.dataService.edgeBimshow.getValue() ? this.threeJSComponent.dataService.edgeBimshow.getValue() : false
    const opacity = this.threeJSComponent.dataService.edgeBimshowOpacity.getValue() ? this.threeJSComponent.dataService.edgeBimshowOpacity.getValue() : 1
    const color = this.threeJSComponent.dataService.edgeBimshowColor.getValue() ? this.threeJSComponent.dataService.edgeBimshowColor.getValue() : '#000000'

    return {
      status: status,
      opacity: opacity,
      color: color
    }
  }

}
export { EdgeBimshow };
