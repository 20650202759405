import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';
import { ToolboxEvent } from 'src/models/toolbox-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clipping-planes-editor',
  templateUrl: './clipping-planes-editor.component.html',
  styleUrls: ['./clipping-planes-editor.component.scss']
})
export class ClippingPlanesEditorComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  clippingOn = false;
  clippingFill = false;
  stencilsColor = null;

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {

    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {


      if (viewConfig) {
        if (viewConfig.clippingMode) {

          this.clippingOn = viewConfig.clippingMode.clippingOn ? true : false;
          this.clippingFill = !!viewConfig.clippingMode.stencilsOn;
          this.appActionsService.clippingStencilsOn = !!this.clippingFill;


        }
      }
    }))

    this.subscriptions.push(this.dataService.stencilsColor.subscribe(color=>{
      this.stencilsColor = color;
    }))
  }


  ngOnDestroy() {



    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }


  update() {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('clippingPlanes', 'updates', this.constructor.name, { clippingOn: this.clippingOn }))
  }

  updateFill() {

    this.appActionsService.toolboxEvents.next(new ToolboxEvent('clippingPlanes', 'updates', this.constructor.name, { stencilsOn: this.clippingFill }))
    this.appActionsService.clippingStencilsOn = this.clippingFill;

  }

  resetToProjectBox() {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('clippingPlanes', 'updates', this.constructor.name, { reset: true }))
  }


  changeStencilColor(color) {
    this.dataService.stencilsColor.next(color)
  }

  saveStencilsColor(color) {
    this.dataService.setStencilsColorInDB(color);
  }

}
