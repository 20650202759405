import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { resizeImage, resizeToPowerOf2 } from '../modules/HelperFunctions';

@Component({
  selector: 'app-custom-material-create',
  templateUrl: './custom-material-create.component.html',
  styleUrls: ['./custom-material-create.component.scss']
})
export class CustomMaterialCreateComponent implements OnInit {
  @Output() closed = new EventEmitter();
  name: string = 'New Custom Material';
  maps = {
    roughness: null,
    metalness: null,
    ao: null,
    "normal-ogl": null,
    albedo: null
  }
  mapsRatios = {
    roughness: null,
    metalness: null,
    ao: null,
    "normal-ogl": null,
    albedo: null
  }




  enableEnvMap = false;
  inProgress = false;
  totalSize = 0;
  mapsPreviews = {};

  maxRes = 2048;

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private translate: TranslateService, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.name = this.translate.instant('customMaterial.defaultNewMaterialName')


  }

  async uploadMap(mapName, fileInput,) {
    let file = fileInput.files[0]
    fileInput.value = null;
    this.mapsPreviews[mapName] = null;
    this.mapsRatios[mapName] = null;
    if (file) {
      if ((file.type == 'image/png') || (file.type == 'image/jpeg') || (file.type == 'image/jpg') || (file.type == 'image/gif')) {

        const { maxRes, powerOf2 } = await this.checkImageDimensions(file)

        const { width, height } = await this.getImageSize(file);
        this.mapsRatios[mapName] = width/height;
        
        let notificationMessage = null;
        let objectUrl;
        if (!powerOf2) {
          const { blob, finalRatio, originalRatio } = await resizeToPowerOf2(file);
          file = blob;
          this.mapsRatios[mapName] = { finalRatio, originalRatio }
        
          notificationMessage = this.translate.instant('customMaterial.notPowerOf2') + '\n\n';
          this.updateTotalSize();

        }

        if (!maxRes) {
          objectUrl = await resizeImage(file, this.maxRes)
          file = await fetch(objectUrl).then(r => r.blob());
     
          notificationMessage += this.translate.instant('customMaterial.maxResError');
          this.updateTotalSize();
        } else {
          objectUrl = URL.createObjectURL(file)
        }

        if(notificationMessage) {
          this.appActionsService.notify(notificationMessage, "warn", 20)
        }
    
        this.mapsPreviews[mapName] = this.sanitizer.bypassSecurityTrustUrl(objectUrl);


        this.maps[mapName] = file;





      } else {
        this.appActionsService.notify("customMaterial.badFileType", "error", 10)
        console.warn('customMaterial.wrongFile')

      }
    }

    this.updateTotalSize();



  }



  removeMap(mapName, event) {
    event.stopPropagation()
    console.log(mapName)

    this.maps[mapName] = null;
    this.mapsPreviews[mapName] = null;
    this.mapsRatios[mapName] = null;
    this.updateTotalSize();


  }

  updateTotalSize() {
    this.totalSize = 0;
    for (let mapName in this.maps) {
      const file = this.maps[mapName];
      if (file) {
        this.totalSize += file.size;
      }

    }
  }

  async checkImageDimensions(file) {
    const { width, height } = await this.getImageSize(file);
    return {
      maxRes: (width <= this.maxRes && height <= this.maxRes),
      powerOf2: (Math.log2(width) % 1 == 0) && (Math.log2(height) % 1 == 0)
    }
  }

  getImageSize(file) {

    return new Promise<{ width: number, height: number }>((resolve, reject) => {
      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].indexOf(file.type) == -1) {
        reject('not image file')
        return;
      }


      var reader = new FileReader();
      reader.onload = (event: any) => {
        var image = new Image();

        //Validate the File Height and Width.
        image.onload = () => {
          const width = image.naturalWidth;
          const height = image.naturalHeight;
          console.log(width, height)
          resolve({ width, height })
        };

        image.onerror = reject;

        if (typeof event.target.result == 'string') {
          image.src = event.target.result;

        }





      }

      reader.readAsDataURL(file)
    })

  }

  async createClicked() {


    this.inProgress = true



    try {

      if (!this.maps.albedo) {
        throw ('albedo map must be set')
      }

      const thumb = await resizeImage(this.maps.albedo, 150);


      await this.dataService.createCustomMaterialInProject({
        maps: this.maps,
        name: this.name,
        enableEnvMap: this.enableEnvMap,
        preview: thumb,
        size: this.totalSize / 1024 / 1024,
        mapsRatios: this.mapsRatios
      }, this.dataService.project.id)
      this.closed.emit();

    } catch (err) {
      console.warn('failed to create new material', err)
    }

    this.inProgress = false;


  }

  closeClicked() {
    this.closed.emit();
  }

}
