import { Directive, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';

@Directive({
    selector: '[toolTip]'
})

export class ToolTipDirective implements OnInit {
    @Input('toolTip') toolTip:any = null;
    isTouch = false;
    constructor(private elementRef: ElementRef, private appActionService: AppActionsService ) {}

    ngOnInit(){
    

    }

    @HostListener('touchstart', ['$event']) touchstart(event) {
 
       this.isTouch=true;
     
    }




    @HostListener('mouseenter', ['$event']) mouseenter(eventData: MouseEvent) {

        
        
        if (!this.isTouch) {
            this.appActionService.toolTipMessage.next({...this.toolTip,clientRect:this.elementRef.nativeElement.getBoundingClientRect()});
        }
      
    }

    @HostListener('mouseleave', ['$event']) mouseleave(eventData:Event) {
        this.appActionService.toolTipMessage.next(null);
    }

   
}