import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  signupForm: FormGroup;
  authError = null;
  signupError = null;
  waiting = false;
  signupMode = false;
  inAnimation = false;
  firstSignup = true;
  conditionsOpen = false;

  isLocalhost = window.location.host.includes('localhost') || window.location.host.includes('4200');

  constructor(private dataService: DataService, private route: ActivatedRoute,private router: Router,) { }


  ngOnInit() {
    this.waiting = true;

    setTimeout(() => { //timeout necessery to query correctly the params.

      let projectId = this.route.snapshot.queryParams.p;

      this.dataService.checkIfUserLoggedAlready().then(
        resolve => {

          if (this.dataService.user.isAnonymous && !projectId) { //will logout of guest if there is no project in link
            // console.log('this is guest user');

            this.dataService.logout().then(() => {
              // console.log('logged out from guest user')
              this.waiting = false;

            });


          } else {
            this.onLoginSucceed();
          }


        },
        reject => {



          if (!projectId) { //noproject link
            if (this.route.snapshot.fragment == 'publicProjects') {
              this.loginAsGuest();
              this.router.navigate(['/'])
            }
            else {
              // console.log('not login as guest now')

              this.waiting = false;
              if (!this.isLocalhost) {

                window.location.href = window.location.origin + "/account/signin?redirect=" + window.location.href;
              }
            }


          } else {//login as guest only if there is a project link
            // console.log('login as guest now')
            this.loginAsGuest();
          }

        }
      )
      this.initLoginForm();
      this.initSignupForm();
    }, 200);

  }

  initSignupForm() {
    this.signupForm = new FormGroup({
      'firstName': new FormControl(null, Validators.required),
      'lastName': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email, this.emailHasFirebaseErrors.bind(this)]),
      'password': new FormControl(null, [Validators.required, this.passwordHasFirebaseErrors.bind(this)]),
      'verifyPassword': new FormControl(null, [Validators.required, this.passwordVerifier.bind(this)]),
      'agreementAccepted': new FormControl(null, Validators.requiredTrue)
    })
  }

  initLoginForm() {
    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    })
  }

  onSubmit() {

    if (!this.loginForm.valid) {

      return;
    }

    this.waiting = true;
    this.dataService.login(this.loginForm.value.email, this.loginForm.value.password).then(
      resolved => {

        this.onLoginSucceed();
      },
      rejected => {


        if (rejected.code == 'auth/user-not-found') {
          this.authError = 'E-mail does not exist. Please try again';
        }

        if (rejected.code == 'auth/wrong-password') {
          this.authError = 'Password is incorrect. Please try again';
        }

        this.loginForm.reset();
        this.loginForm.markAsPristine();
        this.loginForm.markAsUntouched();
        this.loginForm.updateValueAndValidity();
        this.waiting = false;



      }
    ).catch(err => {
      console.warn(err)
    })

  }

  onLoginSucceed() {
    this.dataService.getUserData();

  }

  toggleSignupMode() {

    this.inAnimation = true;
    this.signupMode = !this.signupMode;

    setTimeout(() => {

      this.inAnimation = false;
    }, 500)
  }

  onSignupSubmit() {
    this.firstSignup = false;
    if (!this.signupForm.valid) {

      return;
    }

    this.signupError = null;
    this.waiting = true;
    this.dataService.signup(this.signupForm.value).then(
      resolved => {
        this.dataService.login(this.signupForm.value.email, this.signupForm.value.password).then(
          resolved => {
            this.onLoginSucceed()
          },
          rejected => {
            console.warn('login failed after signup')
          }
        ).catch(err => {
          console.warn(err)
        })
      },
      rejected => {

        this.signupError = rejected;
        //this.signupForm.reset();
        //this.signupForm.markAsPristine();
        //this.signupForm.markAsUntouched();
        this.signupForm.controls.password.setValue(null)
        this.signupForm.controls.verifyPassword.reset();
        this.signupForm.updateValueAndValidity();
        this.waiting = false;
      })
  }

  emailHasFirebaseErrors(control: FormControl): { [s: string]: boolean } {
    let errorCodes = ['auth/email-already-in-use', 'auth/invalid-email']
    if (this.signupError) {
      if (errorCodes.indexOf(this.signupError.code) !== -1) {
        return { 'emailHasFirebaseErrors': true };
      }
    }


    return null;
  }

  passwordHasFirebaseErrors(control: FormControl): { [s: string]: boolean } {
    let errorCodes = ['auth/weak-password']
    if (this.signupError) {
      if (errorCodes.indexOf(this.signupError.code) !== -1) {

        return { 'passwordHasFirebaseErrors': true };
      }
    }


    return null;
  }

  passwordVerifier(control: FormControl): { [s: string]: boolean } {
    if (this.signupForm) {

      if (this.signupForm.controls.password.value !== this.signupForm.controls.verifyPassword.value) {

        return { 'passwordMismatch': true };
      }
    }

    return null;

  }

  emailInput() {
    if (this.signupForm.controls.email.hasError('emailHasFirebaseErrors')) {
      this.signupError = null;
      this.signupForm.controls.email.updateValueAndValidity();


    }
  }

  passwordInput() {
    this.signupForm.controls.verifyPassword.setValue(null);
    if (this.signupForm.controls.password.hasError('passwordHasFirebaseErrors')) {

      this.signupError = null;
      this.signupForm.controls.password.updateValueAndValidity();


    }
  }

  async loginAsGuest() {

    try {
      this.waiting = true;
      await this.dataService.loginAnonymous();
    } catch (err) {
      this.waiting = false;
    }

    this.onLoginSucceed()

  }

  openConditions() {
    this.conditionsOpen = true;
  }

  closeConditions() {
    this.conditionsOpen = false;
  }
}
