import { Component, OnInit, Input, OnChanges, OnDestroy, HostListener } from '@angular/core';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnInit, OnChanges {
  @Input('labels') labels: any;
  openLabel = null;
  mobileActiveLabel = null;


  constructor(private dataService: DataService,
    private appActionsService: AppActionsService,
  ) { }

  ngOnInit() {

  }




  ngOnChanges(c) {

  }

  labelClicked(label) {
    if (label.type == 'photo') {
      this.dataService.loadPhotoView(this.dataService.photos[label.linkedToId])
    }
    if (label.type == 'note') {

      const note = this.dataService.getNoteById(label.linkedToId);

      this.appActionsService.openNote.next(note)
    }

    if (label.type !== 'note' && label.type !== 'photo') {
      this.toggleLabel(label);

    }

    this.appActionsService.objectDataAction.next({ action: 'labelClicked', index: label.index })

  }

  mobileLabelClicked(label) {

    if (label && (label.showTextBehavior == 'never' || (label.type == 'photo' && label.showTextBehavior == 'active'))) {
      this.labelClicked(label)
      this.mobileActiveLabel = null;
    } else {
      this.mobileActiveLabel = label;
    }
  }

  runMobileLabelAction(label) {
    this.mobileActiveLabel = null;
    this.labelClicked(label);

  }

  toggleLabel(label) {
    if (label.alwaysShowText) { //dont do anything if this label is always showed. (it will not close other labels on click... mainly..)
      return;
    }

    if (this.openLabel == label) {
      this.openLabel = null;
    } else {
      this.openLabel = label
    }


  }

  getLabelKey(label) {
    for (let key in this.labels) {
      if (this.labels[key] === label) {
        return key;
      }
    }
  }

  async imageClicked(label, event) {
    event.stopPropagation()
    this.appActionsService.openImageUrl = 'loading';
    const imageurl = await this.dataService.getLabelImageUrl(label.imageId)
    this.appActionsService.openImageUrl = imageurl;

  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }
}
