import { Component, OnInit, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { AppActionsService } from 'src/app/services/app-actions.service';

@Component({
  selector: 'app-view-editor',
  templateUrl: './view-editor.component.html',
  styleUrls: ['./view-editor.component.scss']
})
export class ViewEditorComponent implements OnInit, OnDestroy {

  dropListsIds = [];
  views = null;
  subButtonIsDragged = false;
  lastDragged = null;
  currentLoadedProject = null;
  saveError = null;

  currentView = null;
  selectedButton = null;
  subscriptions: Subscription[] = [];
  deleteDialogOn = false;

  constructor(private appActionsService: AppActionsService, private dataService: DataService, private domRenderer: Renderer2) { }

  ngOnInit() {
    this.subscriptions.push(
      this.appActionsService.viewsDataLoaded.subscribe(() => {
        this.views = this.dataService.views;


      })
    )
    this.subscriptions.push(
      this.appActionsService.closeButtonEditor.subscribe(() => {

        this.selectedButton = null;
      })
    )

    this.subscriptions.push(
      this.appActionsService.viewLoaded.subscribe((view) => {
        if (view) {
          if (view.createdBy == this.dataService.user.id) {

            this.currentView = this.views[view.id];
            if (this.currentView.style == null || this.currentView.style == 'default') {//because some views didnt have this
              this.currentView['style'] = 'style1';

            }

          } else {
            this.currentView = null;
          }
        } else {
          this.currentView = null;
        }
      })
    )
    this.subscriptions.push(this.appActionsService.photoDroppedOnView.subscribe(photoId => {
      this.linkPhotoToView(photoId);
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  selectButton(button) {
    this.selectedButton = button;
  }

  dropMainButtons(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.currentView.menu, event.previousIndex, event.currentIndex);
    this.updateCurrentViewProperty('menu')
  }

  dropSubButtons(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.updateCurrentViewProperty('menu')
  }

  generateDropListsIds() {
    this.dropListsIds = [];
    if (this.currentView.menu) {
      this.currentView.menu.forEach(b => {
        this.dropListsIds.push('subButtonList_' + b.id)
      })
    }

  }

  viewSelected(event) {
    this.dataService.loadView(this.currentView.id)
    this.generateDropListsIds();
  }


  addButtonClicked() {
    this.dataService.createNewMainButton(this.currentView.id);
    this.generateDropListsIds();
    this.updateCurrentViewProperty('menu')
  }


  async createNewViewClicked() {
    this.currentView = await this.dataService.createNewView();
    this.dataService.loadView(this.currentView.id)
    this.generateDropListsIds();

  }



  dragReleaseOnEmptySubMenu(newViewButton) {
    newViewButton['children'] = [this.lastDragged.sub]
    setTimeout(() => {
      this.lastDragged.main.children.splice(this.lastDragged.main.children.indexOf(this.lastDragged.sub), 1);
      if (this.lastDragged.main.children.length == 0) {
        this.lastDragged.main.children = null;
      }
    }, 10);

    this.updateCurrentViewProperty('menu')
  }

  updateCurrentViewProperty(propertyKey) {
    const updates = {};
    updates[propertyKey] = this.currentView[propertyKey];
    this.dataService.updateView(this.currentView.id, updates)
    this.appActionsService.viewUpdated.next()
  }

  resetColor() {
    this.currentView.color = null;
    this.updateCurrentViewProperty('color')
  }






  subDragStart(subViewButton, viewButton, event) {
    this.lastDragged = {
      main: viewButton,
      sub: subViewButton,
      el: event.source.element.nativeElement
    }
    this.domRenderer.addClass(this.lastDragged.el, 'noTransform')
  }

  close() {
    this.appActionsService.openCloseViewsEditor.next('close')
  }


  deleteViewClicked() {
    if (this.currentView.wizardGenerated) {
      this.deleteDialogOn = true;
    } else {
      this.deleteView();
    }




  }

  closeDeleteDialog() {
    this.deleteDialogOn = false
  }

  deleteView(deleteRelatedPhoto = false) {
    const id = this.currentView.id;
    this.currentView = null;

    if (deleteRelatedPhoto) {
      this.dataService.removeViewRelatedPhotosAndLabels(id);
    }


    this.dataService.deleteView(id)


    this.deleteDialogOn = false;
  }



  deleteAllButtonLinkedPhoto() {
    const photosIdsToRemove = [];
    for (let button of this.currentView.menu) {
      if (button.onClick.action == 'loadPhoto') {
        photosIdsToRemove.push(button.onClick.value)



      }

      if (button.children) {
        for (let childButton of button.children) {
          if (childButton.onClick.action == 'loadPhoto') {
            photosIdsToRemove.push(childButton.onClick.value)
          }
        }
      }
    }

    photosIdsToRemove.push(this.currentView.initialPhoto)

    photosIdsToRemove.forEach(pid => this.dataService.deletePhoto(this.dataService.photos[pid]))
  }

  noEnterPredicte() {
    return false;
  }

  linkPhotoToView(id) {
    this.currentView['initialPhoto'] = id;
    this.appActionsService.checkPhotosLinkedToButton.next();
    this.updateCurrentViewProperty('initialPhoto')
  }

  viewHeaderClicked() {
    this.selectedButton = null;
  }

  wizardButtonClicked() {
    this.appActionsService.wizardOpen = true;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

  generatePreRendered() {
    this.close();
    this.appActionsService.startPreRendering.next();
  }

  copyLightweightUrlToClipboard() {
    const url = `${window.location.origin}/app/?p=${this.dataService.project.id}&light=true`;
    navigator.clipboard.writeText(url);
    this.appActionsService.notify('the URL for the Light version website (pre-rendered) was copied to clipboard',"success",3)
  }

  goToLightweightWebsite() {
    const url = `${window.location.origin}/app/?p=${this.dataService.project.id}&light=true`;
    var a = document.createElement("a");
    a.href = url;
    a.setAttribute('target', '_blank');
    a.click();
  }
}
