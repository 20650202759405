import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { BehaviorSubject, Subscription } from 'rxjs';


@Component({
  selector: 'app-view-menu',
  templateUrl: './view-menu.component.html',
  styleUrls: ['./view-menu.component.scss']
})
export class ViewMenuComponent implements OnInit, OnDestroy, OnChanges {

  @Input('view') view;
  mobileMenuOn = false;
  toggleControl = {}

  navMenuActiveBtnIndex = 0;
  navMenuLength = 0;

  //to remove subs when component is destroyed
  subscriptions: Subscription[] = [];

  //style2
  flatButtons = { values: [] }
  activeNavButton: any = null;
  actionsAutoDiaporama = ['loadNote', 'loadPhoto', 'selectObject']
  project3dObjectGenerated = false
  currentSetIntervalId = null
  currentNavPhotoId = null;
  presentationRunning = false;
  presnetationStartScreen = true;
  presentationInterval = null;


  //style3
  selectedPage = null;
  selectedChild = null;
  style3floorplanMenu = false;
  style3floorplansArray= [];
  landscapeStyle3Minimized = false;
  style3AccentColor = null;






  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {

    this.view.timer;

    this.subscriptions.push(this.appActionsService.photoOpened.subscribe((photoId) => {
      //for style2

      if (this.view.style == 'style2') {

        if (this.currentNavPhotoId !== photoId) {
          this.pausePresentation();
        }
      }


    }))

    this.subscriptions.push(this.appActionsService.lightWebsiteVideoContainerSwipped.subscribe((dir) => {



      if (this.view.style == 'style3') {
        if (this.selectedChild && this.selectedChild.style3Type == 'floorplan') {
          return;
        }



        if (dir !== 0) {
          this.navStyle3ExButton(dir > 0 ? 1 : -1)
        }




      }




      if (this.view.style == 'style2') {
        if (dir == 0) {
          return;
        }

        if (!this.presnetationStartScreen) {

          this.navButtonClicked(dir > 0 ? 1 : -1)

        }
      }

      this.openCloseMobileMenu('close')
      this.setLandscapeStyle3Minimized(true)



    }))

    this.subscriptions.push(this.appActionsService.viewUpdated.subscribe(() => {
      this.setStyle3AccentColor();
    }))


    document.addEventListener('click', () => {
      this.style3floorplanMenu = false;
    })

  }





  ngOnDestroy() {
    clearInterval(this.presentationInterval)
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //if (this.project3dObjectGenerated) {
    //  this.initTimerMenu()
    //  this.navMenuActiveBtnIndex = 0
    //}
    this.setStyle3AccentColor();
  }

  setStyle3AccentColor() {
    const root: any = document.querySelector(':root');
    let color = getComputedStyle(root).getPropertyValue('--color-primary-lighter');
    if (this.view && this.view.color) {

      // to get css variable from :root


      color = this.style3AccentColor = this.view.color;


    }

    // set css variable
    root.style.setProperty('--style3-accent-color', color);

  }

  mainButtonClicked(button) {
    console.log('mian button clicked!')


    if (button.children && button.children.length > 0) {
      this.toggleButton(button.id)

      if (this.toggleControl[button.id]) { //incase we toggled on
        this.runButtonOnClick(button)

      } else { //incase we toggledOff
        //check if all is toggled off 
        let isAnyButtonToggled = false;
        for (let id in this.toggleControl) {
          if (this.toggleControl[id]) {
            isAnyButtonToggled = true;
          }
        }

        if (!isAnyButtonToggled) {
          const photo = this.dataService.photos[this.view.initialPhoto];
          this.dataService.loadPhotoView(photo)
        }

      }

    } else {
      this.runButtonOnClick(button)
      this.toggleOffAllButtons();
    }






  }

  subButtonClicked(button) {
    this.runButtonOnClick(button)
  }

  toggleButton(id) {
    this.toggleControl[id] = !this.toggleControl[id];

    for (let _id in this.toggleControl) { //toggle off all rest
      if (id !== _id) {

        this.toggleControl[_id] = false;
      }
    }
  }

  toggleOffAllButtons() {
    for (let _id in this.toggleControl) { //toggle off all rest
        this.toggleControl[_id] = false;
      
    }
  }

  runButtonOnClick(button) {
    this.appActionsService.openNote.next(); //close open note if exist
    //check if diaporama so update style2TextToDisplay
    if (this.view.style == 'style2') {
      if (this.flatButtons.values[this.navMenuActiveBtnIndex % this.navMenuLength]) {
        this.activeNavButton = this.flatButtons.values[this.navMenuActiveBtnIndex % this.navMenuLength];
        if (this.activeNavButton.onClick && this.activeNavButton.onClick.action == 'loadPhoto') {
          this.currentNavPhotoId = this.activeNavButton.onClick.value;
        } else {
          this.currentNavPhotoId = null;
        }
      } else {

        this.activeNavButton = null;
      }


    }

    if (button.onClick) {
      let action = button.onClick.action;
      let value = button.onClick.value;

      if (action) {
        switch (action) {
          case 'loadPhoto':
            let photo = this.dataService.photos[value];
            if (photo) {
              this.dataService.loadPhotoView(photo)
            }
            this.appActionsService.chosenObjectOid.next(null)
            break;
          case 'loadNote':
            let note = this.dataService.getNoteById(value)
            if (note) {
              this.appActionsService.openNote.next(note);
            }
            this.appActionsService.chosenObjectOid.next(null)
            break;

          case 'download':
            if (value) {
              this.downloadUrl(value);
            }
            break;

          case 'selectObject':
            if (value) {
              this.appActionsService.chosenObjectOid.next(this.dataService.getObjectIdOfGuid(value));
            }
            break;

          case 'toggleViewGallery':
            this.appActionsService.viewGalleryOn = !this.appActionsService.viewGalleryOn;
            break;

          case 'downloadIFC':
            this.dataService.getProjectIFCUrl().then(url => {
              this.downloadUrl(url)
            })
            break;

          case 'downloadDAE':
            this.dataService.getProjectModelUrl(this.dataService.project.daeFileName).then(url => {
              this.downloadUrl(url)
            })
            break;

        }
        if (value) {
          // this.openCloseMobileMenu('close')
        }
      }
    }

  }
  openViewGallery() {
    this.appActionsService.viewGalleryOn = true
  }

  downloadUrl(dataurl) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute('target', '_blank');
    //a.setAttribute('download', 'test.txt')
    a.click();
  }

  openCloseMobileMenu(state) {
    if (state == 'close') {
      this.mobileMenuOn = false;
    }

    if (state == 'open') {
      this.mobileMenuOn = true;
    }
  }

  computeFlatButtons(menu) {
    console.log('COMPUTE NAV BUTTON')
    let buttons = [];
    if (menu) {
      for (let viewButton of menu) {
        if (viewButton.onClick && viewButton.onClick.action == "loadPhoto" && viewButton.onClick.value) { buttons.push(viewButton); }

        if (viewButton.children) {
          for (let child of viewButton.children) {
            if (child.onClick && child.onClick.action == "loadPhoto" && child.onClick.value) { buttons.push(child) }
          }
        }
      }
      //init and reset
      this.navMenuLength = buttons.length;
      this.flatButtons.values = buttons
      return buttons;
    }
  }

  startPresentation() {
    this.presnetationStartScreen = false;
    const buttons = this.computeFlatButtons(this.view.menu)
    this.navMenuActiveBtnIndex = 0;
    this.runButtonOnClick(buttons[0])

    if (this.view.timer) {

      this.playPresentation()
    }
  }

  playPresentation() {
    this.presentationRunning = true;
    this.presentationInterval = setInterval(() => {
      this.navButton(1)
    }, (this.view.timer + (this.appActionsService.lightVersion ? 1 : 2)) * 1000); //added transition times depending on light/realtime mode
  }
  pausePresentation() {
    clearInterval(this.presentationInterval)
    this.presentationRunning = false;
  }

  navButtonClicked(delta) {
    if (this.presentationRunning) {
      this.pausePresentation();
      this.playPresentation();
    }

    this.navButton(delta)
  }

  navButton(delta) {

    //check if the new value is in the range else reset it
    this.navMenuActiveBtnIndex += delta;
    if (this.navMenuActiveBtnIndex < 0) {
      this.navMenuActiveBtnIndex = this.navMenuLength - 1;
    }
    if (this.navMenuActiveBtnIndex >= this.navMenuLength) {
      this.navMenuActiveBtnIndex = 0
    }

    const button = this.flatButtons.values[this.navMenuActiveBtnIndex % this.navMenuLength]
    if (button.onClick && button.onClick.action == 'loadPhoto') {
      this.currentNavPhotoId = button.onClick.value;
    } else {
      this.currentNavPhotoId = null;
    }

    this.runButtonOnClick(button)

  }

  navStyle3ExButton(dir) {
    if (this.selectedPage && this.selectedPage.children) {

      const extPhotos = this.selectedPage.children.filter((c) => (c.style3Type == 'exterior'))
      if (extPhotos) {
        const currentIndex = extPhotos.indexOf(this.selectedChild);

        let nextIndex = currentIndex + (dir >= 0 ? 1 : -1);
        if (nextIndex < 0) {
          nextIndex = extPhotos.length - 1;
        }

        if (nextIndex > extPhotos.length - 1) {
          nextIndex = 0;
        }

        const toLoad = extPhotos[nextIndex];
        if (toLoad)
          this.style3ChildClick(toLoad)
      }
    }
  }



  style3ParentClick(parent) {

    this.selectedChild = null;
    this.selectedPage = parent;

  
    this.style3floorplansArray  = this.selectedPage.children.filter(child=>(child.onClick.action == 'loadPhoto' && child.style3Type=='floorplan') )


    this.runButtonOnClick(parent)
  }



  style3ChildClick(child) {

    this.selectedChild = child;

    if (this.appActionsService.isMobile || child.style3Type == 'floorplan') {

      this.setLandscapeStyle3Minimized(true)
    }

    this.runButtonOnClick(child)
  }

  style3backToHome() {
    this.selectedPage = null;
    this.selectedChild = null;

    const photo = this.dataService.photos[this.view.initialPhoto];
    this.dataService.loadPhotoView(photo)
  }


  style3floorplansButtonClick(e) {
    e.stopPropagation();
    if(this.style3floorplansArray.length == 1) {
      this.style3ChildClick(this.style3floorplansArray[0])
    } else {
      this.style3floorplanMenu = true;
    }
  
  }

  setLandscapeStyle3Minimized(value) {
    this.landscapeStyle3Minimized = value;
  }

  floorplanBackClicked() {
    if (this.selectedChild) {
      if (this.selectedChild.onClick.value === this.dataService.currentLoadedPhotoId) {
        this.style3ParentClick(this.selectedPage);
        this.setLandscapeStyle3Minimized(false)
      } else {
        this.style3ChildClick(this.selectedChild)
      }
    }
  }

  getActiveNavBtn() {
    console.log('getactive')
    return this.flatButtons[this.navMenuActiveBtnIndex]
  }

  backToLastPhoto() {
    this.runButtonOnClick(this.activeNavButton);
  }


}

