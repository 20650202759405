import { Component, OnInit, Renderer2, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { ToolboxEvent } from '../../models/toolbox-event';
import { DataService } from '../services/data.service';
import * as MapboxGeocoder from '../../../node_modules/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min';

import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as tilebelt from '@mapbox/tilebelt';
import * as png from '@vivaxy/png';

declare var mapboxgl: any;
import * as THREE from "../../assets/threejs/three.module.js";

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss']
})
export class MapboxComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
  @ViewChild('geocoderContainer', { static: true }) geocoderContainer: ElementRef;
  map = null;
  currentZoom = null;
  objectLoaded = false;
  // geocoder = null;
  transform = {};
  renderAction = { dontRender: true }


  lastFetchedElevation = {
    lat: null,
    lng: null,
    elevation: 0
  }
  terrainIsOn = true;
  siteElevation = 0;
  projectZeroOffset = new THREE.Vector3();

  constructor(public appActionsService: AppActionsService, private domRenderer: Renderer2, private dataService: DataService, private http: HttpClient, private ngZone: NgZone) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {

      this.initMapbox();
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  initMapbox() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXNoa2VuYXppb3IiLCJhIjoiY2pveHhvcmhkMjVvMDN3cGg4eGtneWhxMSJ9.3pFGOEZUl_n-xkVNkS1kFg';
    this.map = new mapboxgl.Map({
      container: 'mapboxContainer', // container id
      style: 'mapbox://styles/ashkenazior/cjysbd07d0fdx1coc71jxlew8', // stylesheet location
      center: [55.5, -21.3], // starting position [lng, lat]
      zoom: 23, // starting zoom
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased,
      preserveDrawingBuffer: true
    });



    this.map.on('load', () => {

      this.initTerrain()

      this.subscriptions.push(
        this.appActionsService.rerenderOnMapbox.subscribe(() => {
          this.map.triggerRepaint();
        })
      )

      this.subscriptions.push(this.appActionsService.materialsUpdated.subscribe(() => {
        this.map.triggerRepaint();
      }))

      this.subscriptions.push(
        this.appActionsService.project3dObjectGenerated.subscribe(() => {
          this.add3dObject();
        })
      )
      this.subscriptions.push(
        this.appActionsService.toolboxEvents.subscribe(event => {
          if (event.tool == 'placeOnMapMode' && event.emitter != this.constructor.name) {

            if (event.type == 'updates') {

              if ((event.updates['openMapbox'] != false || !!event.updates['fly']) && !event.updates['flyTo']) { //also on openMapbox, and also on normal update
                const updates = {};

                if (event.updates['zoom']) {
                  updates['zoom'] = Number(event.updates['zoom'])
                }
                if (event.updates['targetProjectSpace']) {


                  //translate/rotate projectSpaceTarget to longlat (based on project rotation/offsets)
                  const lat0 = Number(this.dataService.siteData.latitude);
                  const lon0 = Number(this.dataService.siteData.longitude)
                  const ele0 = Number(this.dataService.siteData.elevation)
                  const r = {
                    x: Number(event.updates['targetProjectSpace'].x),
                    y: Number(event.updates['targetProjectSpace'].y)
                  }
                  const theta = Math.PI * this.dataService.siteData.rotate / 180;
                  const offsetLon_m = Math.cos(theta) * r.x - Math.sin(theta) * r.y;
                  const offsetLat_m = Math.sin(theta) * r.x + Math.cos(theta) * r.y;

                  const m_per_deg_lat = 111132.954;
                  const m_per_deg_lon = 111132.954 * Math.cos(Math.PI * lat0 / 180);

                  updates['elevation'] = ele0 + Number(this.dataService.siteData.offsetZ)


                  updates['center'] = [
                    lon0 + (offsetLon_m + Number(this.dataService.siteData.offsetX)) / m_per_deg_lon,
                    this.dataService.siteData.latitude + (offsetLat_m + Number(this.dataService.siteData.offsetY)) / m_per_deg_lat];

                }
                if (event.updates['compassAngle'] != null) {

                  updates['bearing'] = -event.updates['compassAngle'] - Number(this.dataService.siteData.rotate);

                }
                if (event.updates['pitch'] != null) {
                  updates['pitch'] = event.updates['pitch'];
                }


                if (updates['center']) {
                  updates['speed'] = 0.1;
                  if (event.updates.fly) {

                    this.map.flyTo(updates)
                  } else {

                    this.map.jumpTo(updates);
                  }

                }


              }

              if (event.updates['openMapbox'] == true) {
                this.updateTransform()
                this.domRenderer.addClass(this.mapboxContainer.nativeElement, 'on');
                this.renderAction.dontRender = false;
                this.map.resize();

              }

              if (event.updates['openMapbox'] == false) {

                this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
                let cp = {
                  compassAngle: -this.map.getBearing(),
                  pitch: this.map.getPitch(),
                  zoom: this.map.getZoom(),
                  center: {
                    lat: this.map.getCenter().lat,
                    long: this.map.getCenter().lng
                  }
                }

                this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { cameraProperties: cp }))
                this.domRenderer.removeClass(this.mapboxContainer.nativeElement, 'on');
                this.renderAction.dontRender = true;
              }

              if (event.updates['mapboxMode'] != null) {
                switch (event.updates['mapboxMode']) {
                  case ('sat'):
            
                    this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
                    console.log('updating sat = vsibile')
                    break;
                  default:
                    this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
                    console.log('updating sat = none')
                    break;
                }
              }

              if (event.updates['resetNorth']) {
                this.map.resetNorth();
              }

              if (event.updates['zoomInOut']) {
                if (event.updates['zoomInOut'] > 0) {
                  this.map.zoomIn();
                } else {
                  this.map.zoomOut();
                }
              }

              if (event.updates['flyTo']) {

                const target = mapboxgl.MercatorCoordinate.fromLngLat([event.updates['flyTo'].target.long, event.updates['flyTo'].target.lat], 0);
                const meter = target.meterInMercatorCoordinateUnits();
                let offset = event.updates['flyTo'].center;
                if (offset) {

                  target.x += meter * (offset.x + this.dataService.siteData.offsetX);
                  target.y -= meter * (offset.y + this.dataService.siteData.offsetY);
                }

                const targetLngLat = new mapboxgl.MercatorCoordinate(target.x, target.y, 0).toLngLat();


                this.map.flyTo({
                  center: [targetLngLat.lng, targetLngLat.lat],
                  speed: 0.5,
                  zoom: event.updates['flyTo'].zoom ? event.updates['flyTo'].zoom : this.map.getZoom()
                });
              }

              if (event.updates['siteProperties']) {

                this.updateTransform()
                this.updateAllLayers();

              }

              if (event.updates['terrainOn'] != null) {
                this.setTerrainOnOff(event.updates['terrainOn']);
              }

            }
          }

          if ((event.tool == 'pdfCompareMode') && (event.emitter != this.constructor.name)) {
            if (event.type == 'updates') {
              if (event.updates.updateImage) {
                this.updateImageLayer(event.updates.updateImage);
              }

              if (event.updates.updateLayersOrder) {
                this.orderLayers();
              }

              if (event.updates.addNewImage) {
                this.generateImageLayer(event.updates.addNewImage)
                this.orderLayers();
                this.updateImageLayer(event.updates.addNewImage)

              }
              if (event.updates.imageDeleted) {
                this.deleteImageLayer(event.updates.imageDeleted);
              }
            }
          }
        })
      )
    })






    this.map.on('move', () => {

      let cp = {
        compassAngle: -this.map.getBearing(),
        pitch: this.map.getPitch(),
        zoom: this.map.getZoom(),
        center: {
          lat: this.map.getCenter().lat,
          long: this.map.getCenter().lng
        }
      }

      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { cameraProperties: cp }))


    })




    // Add the control to the map.

    let geocoder = new MapboxGeocoder({ accessToken: mapboxgl.accessToken })
    this.map.addControl(geocoder)


    this.map.on('click', (e: any) => {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { LLFromClick: { lon: e.lngLat.wrap().lng, lat: e.lngLat.wrap().lat } }))

    });
  }


  waitForTilesToLoad() {
    return new Promise((resolve, reject) => {

      const ivl = setInterval(() => {
        const tilesLoaded = this.map.areTilesLoaded();
        console.log(tilesLoaded)

        if (tilesLoaded) {
          clearInterval(ivl)
          resolve(true)
          return;
        }
      }, 500);
    })



  }
  async updateTransform() {

    const Lat = this.dataService.siteData.latitude;
    const Lon = this.dataService.siteData.longitude;
    const offsetX = this.dataService.siteData.offsetX;
    const offsetY = this.dataService.siteData.offsetY;
    const offsetZ = this.dataService.siteData.offsetZ;
    const rotate = this.dataService.siteData.rotate;
    let elev = this.dataService.siteData.elevation;


    var modelOrigin = [Lon, Lat];
    var modelAltitude = 0;


    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
    var meter = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();

    let z = 0;
    if (this.lastFetchedElevation.lat != Lat || this.lastFetchedElevation.lng != Lon) {
      this.lastFetchedElevation.elevation = await this.getElevation(Lon, Lat);
      this.lastFetchedElevation.lat = Lat;
      this.lastFetchedElevation.lng = Lon;

      elev = this.siteElevation = this.lastFetchedElevation.elevation;




    }


    this.transform['translateX'] = modelAsMercatorCoordinate.x + offsetX * meter
    this.transform['translateY'] = modelAsMercatorCoordinate.y - offsetY * meter;
    if (this.terrainIsOn) {
      this.transform['translateZ'] = (this.lastFetchedElevation.elevation + offsetZ) * meter;
    } else {

      this.transform['translateZ'] = (0 + offsetZ) * meter; //this.siteElevation;  //@mg try to fix elevation on 3dterrain off
    }


    if (this.dataService.project3dObject.userData.type == 'ifc') {
      this.transform['rotateX'] = 0;
      this.transform['rotateY'] = 0;
      this.transform['rotateZ'] = Math.PI * rotate / 180;


    } else {
      this.transform['rotateX'] = 0;
      this.transform['rotateY'] = 0
      this.transform['rotateZ'] = Math.PI * rotate / 180;;

    }


    this.transform['scale'] = meter;

    this.transform['center'] = this.projectZeroOffset;

    this.map.triggerRepaint();


  }

  add3dObject() {


    let addObject = (id) => {
      //getting object and manipulating;
      let object = this.dataService.project3dObject;


      let box = new THREE.Box3().setFromObject(object);
      box.getCenter(this.projectZeroOffset);
      let renderAction = this.renderAction;

      //this is just init data.. it will update according to project data...
      var modelOrigin = [55.455, -20.888];
      var modelAltitude = 0;
      var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
      var meter = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();

      var initTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scale: meter
      }

      var transform: any = initTransform;

      if (this.transform == {}) {
        this.transform = transform;
      } else {
        transform = this.transform
      }

      // Create the Mapbox Custom Layer object
      // See
      var map = this.map;
      var threejsComp = this.dataService.threejsComp;
      var threeJSModel = {
        map: map,
        id: id,
        type: 'custom',
        onAdd: function (map, gl) {


          this.camera = threejsComp.mapboxCam;


          this.map = map;
        },
        render: function (gl, matrix) {

          if (!transform.center) {
            return;
          }
          if (renderAction.dontRender) {
            return;
          }
          const eulerMatrix = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(transform.rotateX, transform.rotateY, transform.rotateZ, 'XYZ'))
          var m = new THREE.Matrix4().fromArray(matrix);

          const off = new THREE.Matrix4().makeTranslation(-transform.center.x, -transform.center.y, 0)
          const off_inv = off.clone().invert()
          var l = new THREE.Matrix4().makeTranslation(transform.translateX, transform.translateY, transform.translateZ)
            .scale(new THREE.Vector3(transform.scale, -transform.scale, transform.scale))
            .multiply(off_inv).multiply(eulerMatrix).multiply(off)

          this.camera.projectionMatrix = m.multiply(l);


          // threejsComp.turnOffSSAA();
          threejsComp.render();


        },
        takePhoto: async (waitForTiles?) => {
          let cloneCanvas = (oldCanvas) => {

            //create a new canvas
            var newCanvas = document.createElement('canvas');
            var context = newCanvas.getContext('2d');

            //set dimensions
            newCanvas.width = oldCanvas.width;
            newCanvas.height = oldCanvas.height;

            //apply the old canvas to the new one
            context.drawImage(oldCanvas, 0, 0);

            //return the new canvas
            return newCanvas;
          }

          let addToCanvas = (oldCanvas, toAdd) => {
            oldCanvas.getContext('2d').drawImage(toAdd, 0, 0)
          }

          if (waitForTiles) {
            await this.waitForTilesToLoad();
          }


          let newC = cloneCanvas(this.map.getCanvas());
          threejsComp.render()
          addToCanvas(newC, threejsComp.renderer.domElement)

          // let imgData = this.renderer.domElement.toDataURL();
          let imgData = newC.toDataURL();


          return imgData;

        }
      }
      this.appActionsService.mapboxThreejsModel = threeJSModel
      this.map.addLayer(threeJSModel);
      this.objectLoaded = true;
    }
    addObject('project3dObject')

  }




  initTerrain() {
    this.map.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14
    });
    // add the DEM source as a terrain layer with exaggerated height

    this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });

    // add a sky layer that will show when the map is highly pitched

    this.map.addLayer({
      'id': 'sky',
      'type': 'sky',
      'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
      }
    });
  }

  setTerrainOnOff(terrainOn: boolean) {
    if (terrainOn) {
      this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });
      this.terrainIsOn = true;

    } else {
      this.map.setTerrain();
      this.terrainIsOn = false;
    }
    this.updateTransform()
  }

  getImage(imageId) {
    let image = null;
    for (let existingImage of this.dataService.compareImages) {
      if (existingImage.id == imageId) {
        image = existingImage;
      }
    }

    return image;
  }

  generateImageLayer(imageId) {
    let image = this.getImage(imageId)

    this.map.addSource(image.id, {
      "type": "image",
      "url": image.url,
      "coordinates": [
        [55.6, -21.1],
        [55.7, -21.1],
        [55.7, -21.2],
        [55.6, -21.2]
      ]
    });

    this.map.addLayer({
      "id": image.id,
      "source": image.id,
      "type": "raster",
      "paint": {
        "raster-opacity": image.viewConfig.opacity
      }
    });

  }

  deleteImageLayer(id) {
    if (this.map.getLayer(id)) { this.map.removeLayer(id); }
  }

  updateImageLayer(imageId) {

    let image = this.getImage(imageId);
    let imageLayer = this.map.getLayer(imageId);

    if ((image == null) || (imageLayer == null)) {
      console.warn('*no-image* error')
      return;
    }

    if (image.width == 0 || image.height == 0) {
      this.map.setLayoutProperty(imageId, 'visibility', 'none');
      return;
    }

    if (image.viewConfig.opacity != null) {
      this.map.setPaintProperty(imageId, 'raster-opacity', image.viewConfig.opacity);
    }

    if (image.viewConfig.visible != null) {
      if (image.vertical) {
        this.map.setLayoutProperty(imageId, 'visibility', 'none');
      } else {
        this.map.setLayoutProperty(imageId, 'visibility', image.viewConfig.visible ? 'visible' : 'none');
      }

    }

    let siteZeroLongLat = [this.dataService.siteData.longitude, this.dataService.siteData.latitude]
    let o = [this.dataService.siteData.longitude, this.dataService.siteData.latitude];

    let longLatOverride = false;
    if ((image.longitude != null) && (image.latitude != null)) {
      o = [image.longitude, image.latitude];
      longLatOverride = true;
    }

    let o_xy = mapboxgl.MercatorCoordinate.fromLngLat({ lng: o[0], lat: o[1] }, 0);
    let meter = o_xy.meterInMercatorCoordinateUnits();

    let site_0_xy = mapboxgl.MercatorCoordinate.fromLngLat({ lng: siteZeroLongLat[0], lat: siteZeroLongLat[1] }, 0);



    let image_offsetXinMercCoordinates = (image.offsetX ? image.offsetX : 0) * meter;
    let image_offsetYinMercCoordinates = (image.offsetY ? image.offsetY : 0) * meter;
    let site_offsetXinMercCoordinates = this.dataService.siteData.offsetY * meter;
    let site_offsetYinMercCoordinates = this.dataService.siteData.offsetY * meter;
    //rotating..

    let A = [-image.width / 2, -image.height / 2];
    let B = [image.width / 2, -image.height / 2];
    let C = [image.width / 2, image.height / 2];
    let D = [-image.width / 2, image.height / 2];

    let angle1 = (image.rotation ? image.rotation : 0) * Math.PI / 180;
    let angle2 = - Math.PI * this.dataService.siteData.rotate / 180;

    const rot1 = new THREE.Matrix3().set(
      Math.cos(angle1), - Math.sin(angle1), 0,
      Math.sin(angle1), Math.cos(angle1), 0,
      0, 0, 1);



    const rot2 = new THREE.Matrix3().set(
      Math.cos(angle2), - Math.sin(angle2), 0,
      Math.sin(angle2), Math.cos(angle2), 0,
      0, 0, 1);

    let vectors = [
      new THREE.Vector3(A[0], A[1], 1),
      new THREE.Vector3(B[0], B[1], 1),
      new THREE.Vector3(C[0], C[1], 1),
      new THREE.Vector3(D[0], D[1], 1)
    ]

    const imageOffset = new THREE.Vector3(
      image.offsetX,
      image.offsetY,
      0

    )

    const projectOffset = new THREE.Vector3(
      this.dataService.siteData.offsetX,
      this.dataService.siteData.offsetY,
      0

    )


    vectors.forEach(v => {
      v.applyMatrix3(rot1).add(new THREE.Vector3((o_xy.x - site_0_xy.x) / meter + imageOffset.x, (o_xy.y - site_0_xy.y) / meter - imageOffset.y, 0))
        .applyMatrix3(rot2)
        .add(new THREE.Vector3(site_0_xy.x / meter + projectOffset.x, site_0_xy.y / meter - projectOffset.y, 0))

    })





    this.map.getSource(imageId).setCoordinates(
      vectors.map(v => new mapboxgl.MercatorCoordinate(v.x * meter, v.y * meter, 0).toLngLat())
    );


  }

  updateAllLayers() {
    this.dataService.compareImages.forEach(image => {
      this.updateImageLayer(image.id)
    })
  }

  orderLayers() {
    let layerOrder = [];
    if (this.dataService.viewConfig['pdfCompareMode']['layersOrderById']) {
      layerOrder = this.dataService.viewConfig['pdfCompareMode']['layersOrderById'];
    }

    this.dataService.compareImages.forEach(image => { //start pushing all ids that doesnt exist in config
      if (layerOrder.indexOf(image.id) == -1) {
        layerOrder.push(image.id);
      }
    })

    let lastId = null;
    layerOrder.forEach(id => { //now set renderOrder
      if (lastId == null) {
        lastId = id;
      } else {
        if (this.map.getLayer(id) && this.map.getLayer(lastId)) {
          this.map.moveLayer(id, lastId);
          lastId = id;
        }
      }
    })
  }

  getElevation(lng, lat) {
    return new Promise<number>((resolve, reject) => {
      let zoom = 22;
      let tile = tilebelt.pointToTile(lng, lat, zoom);
      let url = "https://api.mapbox.com/v4/mapbox.terrain-rgb/" + zoom + "/" + tile[0] + "/" + tile[1] + ".pngraw?access_token=pk.eyJ1IjoiYXNoa2VuYXppb3IiLCJhIjoiY2pveHhvcmhkMjVvMDN3cGg4eGtneWhxMSJ9.3pFGOEZUl_n-xkVNkS1kFg";
      this.http.get(
        url,
        {
          responseType: 'blob'
        }
      ).subscribe(
        (data: any) => {
          data.arrayBuffer().then(buffer => {
            const metadata = png.decode(buffer);

            const height = -10000 + ((metadata.data[0] * 256 * 256 + metadata.data[1] * 256 + metadata.data[2]) * 0.1)
            resolve(height)
          })
        }
      )
    })
  }
}
