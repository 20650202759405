import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { ToolboxEvent } from '../../models/toolbox-event';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-data-toolbox',
  templateUrl: './data-toolbox.component.html',
  styleUrls: ['./data-toolbox.component.scss']
})
export class DataToolboxComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  selectedMode = null;
  collapsed = false;

  constructor(private domSanitizer: DomSanitizer, private appActionsService: AppActionsService, private dataService: DataService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {
      if (event.type == null) {
        if (this.selectedMode == 'zones') {
          this.selectedMode = null;
        }
      }
      if (event.type == 'open' && event.tool == 'zonesMode') {

        this.selectedMode = 'zones';
      }
      this.cdr.detectChanges();
    }))

    this.subscriptions.push(this.appActionsService.objectDataAction.subscribe(e => {
      if (e.action == 'labelClicked') {
        if (this.selectedMode != 'labels') {
          this.toggleModes('labels')
        }
      }

      if (e.action == 'materialClicked') {
        if (this.selectedMode != 'materials-editor') {
          this.toggleModes('materials-editor')
        }
      }

      if (e.action == 'layerClicked') {
        if (this.selectedMode != 'layers') {
          this.toggleModes('layers')
        }
      }
    }))



  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  toggleModes(mode) {
    this.appActionsService.materialOpenInList = null;
    this.appActionsService.paintMaterialMode = false;
    this.collapsed = false;
    this.appActionsService.debugShadowCamera.next(false);


    if (this.selectedMode == mode) {
      if (this.selectedMode == 'zones') {
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
        this.dataService.viewConfig['selectedMode'] = null;
      }
      this.selectedMode = null;
    } else {
      if (mode == 'zones') {
        let modeName = null;
        switch (mode) {
          case 'zones':
            modeName = 'zonesMode'
            break;
        }
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(modeName, 'open', this.constructor.name));
        this.dataService.viewConfig['selectedMode'] = modeName;
      } else {
        if (this.selectedMode == 'zones') {
          this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
          this.dataService.viewConfig['selectedMode'] = null;
        }
      }
      this.selectedMode = mode;
    }
    this.appActionsService.selectedDataMode = this.selectedMode;
    this.appActionsService.selectedDataModeChanged.next();


  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  galleryButtonClicked() {
    this.appActionsService.galleryIsOpen = !this.appActionsService.galleryIsOpen;
    this.appActionsService.modelIdToAdd.next();
    this.appActionsService.selectedModelId.next();
  }

  takePhotoClicked() {
    this.appActionsService.modelIdToAdd.next();
    this.appActionsService.selectedModelId.next();
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('gallery', 'updates', this.constructor.name, { takePhoto: true }))
  }

  onCameraButtonMouseEnter() {
    this.appActionsService.showCameraOverlay = true;
  }

  onCameraButtonMouseOut() {
    this.appActionsService.showCameraOverlay = false;
  }


}
