import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-photospheres-tool',
  templateUrl: './photospheres-tool.component.html',
  styleUrls: ['./photospheres-tool.component.scss']
})
export class PhotospheresToolComponent implements OnInit, OnDestroy {
  @ViewChild('getFocusLeave', { static: true }) getFocusLeaveElement: ElementRef;

  subscriptions = [];
  photospheres = {};
  openedPhotospheres = {};
  photosphereWaitingForPosition = null;
  inUpload = false;

  @ViewChild('photosphereFile', { static: false }) photosphereFile: ElementRef;

  constructor(public dataService: DataService, private appActions: AppActionsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(this.dataService.photospheresUpdated.subscribe(() => {
      this.photospheres = this.dataService.photospheres;
    }))

    this.subscriptions.push(this.appActions.threejsClick.subscribe(e => {
      if (this.photosphereWaitingForPosition) {
        ;
        this.dataService.updatePhotosphere(this.photosphereWaitingForPosition, {
          position: {
            x: e.positionWorldSpace.x,
            y: e.positionWorldSpace.y,
            z: e.positionWorldSpace.z + 1.5,
          }
        })
        this.photosphereWaitingForPosition = null
      }

      this.appActions.crosshairIconOn = !!this.photosphereWaitingForPosition;

    }))

    this.subscriptions.push(this.appActions.photsphereLabelClicked.subscribe(id => {
      this.openedPhotospheres[id] = true;
      this.cdr.detectChanges();
      document.getElementById('photosphere_container_' + id).scrollIntoView({ behavior: "smooth", block: "end" });
    }))


    this.subscriptions.push(this.appActions.selectedDataModeChanged.subscribe(() => {
      this.photosphereWaitingForPosition = null;
      this.appActions.crosshairIconOn = false
    }))



  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }





  photospherePositionChanged(id, coordinate, value) {
    let position = this.photospheres[id].position;
    position[coordinate] = +value;
    this.dataService.updatePhotosphere(id, { position: position })
  }

  photosphereOpacityChanged(id, value) {
    const opacity = Math.max(0, Math.min(1, +value));

    this.dataService.updatePhotosphere(id, { opacity: opacity })
  }

  photosphereRadiusChanged(id, value) {
    const radius = Math.max(1, +value);

    this.dataService.updatePhotosphere(id, { radius: radius })
  }

  photosphereYawChanged(id, value) {
    const yaw = Number(value);

    this.dataService.updatePhotosphere(id, { yaw })
  }


  toggleProperites(id) {
    this.photosphereWaitingForPosition = null;
    this.appActions.crosshairIconOn = !!this.photosphereWaitingForPosition;


    if (this.openedPhotospheres[id]) {
      delete this.openedPhotospheres[id]
    } else {
      this.openedPhotospheres[id] = true;
    }


  }

  toggleVisibility(id) {

    if (this.dataService.viewConfig.photospheresConfig[id] == null) {
      this.dataService.viewConfig.photospheresConfig[id] = { visible: false }
    }
    this.dataService.viewConfig.photospheresConfig[id].visible = !this.dataService.viewConfig.photospheresConfig[id].visible;
    this.dataService.photospheresUpdated.next();

  }



  deletePhotosphere(id) {
    this.dataService.deletePhotosphere(id)
  }

  pinPhotosphereClicked(id) {
    if (this.photosphereWaitingForPosition == id) {
      this.photosphereWaitingForPosition = null;
    } else {
      this.photosphereWaitingForPosition = id;
    }
    this.appActions.crosshairIconOn = !!this.photosphereWaitingForPosition;
  }

  async onFileInputChanged(event) {
    const file = this.photosphereFile.nativeElement.files[0];

    if (file) {
      this.inUpload = true;
      try {
        const id = await this.dataService.addNewPhotoSphere(file)
        this.inUpload = false;
        this.dataService.viewConfig.photospheresConfig[id] = { visible: true }
        this.dataService.photospheresUpdated.next();
      } catch (err) {
        this.inUpload = false;
      }
     
    }


  }

  photospherePropertyChanged(id, property, value) {

    let updates = {};
    updates[property] = value;
    this.dataService.updatePhotosphere(id, updates)
  }

  goToPhotosphereClick(id) { 
    this.appActions.flyToPhotosphereClicked.next(id)
  }

  //get events to avoid propagation to 3JS and trigger unwanted action
  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event?) {
    this.getFocusLeaveElement.nativeElement.focus()
  }
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

}
