export const IFCACTIONREQUEST = 3821786052;
export const IFCACTOR = 2296667514;
export const IFCACTORROLE = 3630933823;
export const IFCACTUATOR = 4288193352;
export const IFCACTUATORTYPE = 2874132201;
export const IFCADDRESS = 618182010;
export const IFCADVANCEDBREP = 1635779807;
export const IFCADVANCEDBREPWITHVOIDS = 2603310189;
export const IFCADVANCEDFACE = 3406155212;
export const IFCAIRTERMINAL = 1634111441;
export const IFCAIRTERMINALBOX = 177149247;
export const IFCAIRTERMINALBOXTYPE = 1411407467;
export const IFCAIRTERMINALTYPE = 3352864051;
export const IFCAIRTOAIRHEATRECOVERY = 2056796094;
export const IFCAIRTOAIRHEATRECOVERYTYPE = 1871374353;
export const IFCALARM = 3087945054;
export const IFCALARMTYPE = 3001207471;
export const IFCALIGNMENT = 325726236;
export const IFCALIGNMENT2DHORIZONTAL = 749761778;
export const IFCALIGNMENT2DHORIZONTALSEGMENT = 3199563722;
export const IFCALIGNMENT2DSEGMENT = 2483840362;
export const IFCALIGNMENT2DVERSEGCIRCULARARC = 3379348081;
export const IFCALIGNMENT2DVERSEGLINE = 3239324667;
export const IFCALIGNMENT2DVERSEGPARABOLICARC = 4263986512;
export const IFCALIGNMENT2DVERTICAL = 53199957;
export const IFCALIGNMENT2DVERTICALSEGMENT = 2029264950;
export const IFCALIGNMENTCURVE = 3512275521;
export const IFCANNOTATION = 1674181508;
export const IFCANNOTATIONFILLAREA = 669184980;
export const IFCAPPLICATION = 639542469;
export const IFCAPPLIEDVALUE = 411424972;
export const IFCAPPROVAL = 130549933;
export const IFCAPPROVALRELATIONSHIP = 3869604511;
export const IFCARBITRARYCLOSEDPROFILEDEF = 3798115385;
export const IFCARBITRARYOPENPROFILEDEF = 1310608509;
export const IFCARBITRARYPROFILEDEFWITHVOIDS = 2705031697;
export const IFCASSET = 3460190687;
export const IFCASYMMETRICISHAPEPROFILEDEF = 3207858831;
export const IFCAUDIOVISUALAPPLIANCE = 277319702;
export const IFCAUDIOVISUALAPPLIANCETYPE = 1532957894;
export const IFCAXIS1PLACEMENT = 4261334040;
export const IFCAXIS2PLACEMENT2D = 3125803723;
export const IFCAXIS2PLACEMENT3D = 2740243338;
export const IFCBSPLINECURVE = 1967976161;
export const IFCBSPLINECURVEWITHKNOTS = 2461110595;
export const IFCBSPLINESURFACE = 2887950389;
export const IFCBSPLINESURFACEWITHKNOTS = 167062518;
export const IFCBEAM = 753842376;
export const IFCBEAMSTANDARDCASE = 2906023776;
export const IFCBEAMTYPE = 819618141;
export const IFCBEARING = 4196446775;
export const IFCBEARINGTYPE = 3649138523;
export const IFCBLOBTEXTURE = 616511568;
export const IFCBLOCK = 1334484129;
export const IFCBOILER = 32344328;
export const IFCBOILERTYPE = 231477066;
export const IFCBOOLEANCLIPPINGRESULT = 3649129432;
export const IFCBOOLEANRESULT = 2736907675;
export const IFCBOUNDARYCONDITION = 4037036970;
export const IFCBOUNDARYCURVE = 1136057603;
export const IFCBOUNDARYEDGECONDITION = 1560379544;
export const IFCBOUNDARYFACECONDITION = 3367102660;
export const IFCBOUNDARYNODECONDITION = 1387855156;
export const IFCBOUNDARYNODECONDITIONWARPING = 2069777674;
export const IFCBOUNDEDCURVE = 1260505505;
export const IFCBOUNDEDSURFACE = 4182860854;
export const IFCBOUNDINGBOX = 2581212453;
export const IFCBOXEDHALFSPACE = 2713105998;
export const IFCBRIDGE = 644574406;
export const IFCBRIDGEPART = 963979645;
export const IFCBUILDING = 4031249490;
export const IFCBUILDINGELEMENT = 3299480353;
export const IFCBUILDINGELEMENTPART = 2979338954;
export const IFCBUILDINGELEMENTPARTTYPE = 39481116;
export const IFCBUILDINGELEMENTPROXY = 1095909175;
export const IFCBUILDINGELEMENTPROXYTYPE = 1909888760;
export const IFCBUILDINGELEMENTTYPE = 1950629157;
export const IFCBUILDINGSTOREY = 3124254112;
export const IFCBUILDINGSYSTEM = 1177604601;
export const IFCBURNER = 2938176219;
export const IFCBURNERTYPE = 2188180465;
export const IFCCSHAPEPROFILEDEF = 2898889636;
export const IFCCABLECARRIERFITTING = 635142910;
export const IFCCABLECARRIERFITTINGTYPE = 395041908;
export const IFCCABLECARRIERSEGMENT = 3758799889;
export const IFCCABLECARRIERSEGMENTTYPE = 3293546465;
export const IFCCABLEFITTING = 1051757585;
export const IFCCABLEFITTINGTYPE = 2674252688;
export const IFCCABLESEGMENT = 4217484030;
export const IFCCABLESEGMENTTYPE = 1285652485;
export const IFCCAISSONFOUNDATION = 3999819293;
export const IFCCAISSONFOUNDATIONTYPE = 3203706013;
export const IFCCARTESIANPOINT = 1123145078;
export const IFCCARTESIANPOINTLIST = 574549367;
export const IFCCARTESIANPOINTLIST2D = 1675464909;
export const IFCCARTESIANPOINTLIST3D = 2059837836;
export const IFCCARTESIANTRANSFORMATIONOPERATOR = 59481748;
export const IFCCARTESIANTRANSFORMATIONOPERATOR2D = 3749851601;
export const IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM = 3486308946;
export const IFCCARTESIANTRANSFORMATIONOPERATOR3D = 3331915920;
export const IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM = 1416205885;
export const IFCCENTERLINEPROFILEDEF = 3150382593;
export const IFCCHILLER = 3902619387;
export const IFCCHILLERTYPE = 2951183804;
export const IFCCHIMNEY = 3296154744;
export const IFCCHIMNEYTYPE = 2197970202;
export const IFCCIRCLE = 2611217952;
export const IFCCIRCLEHOLLOWPROFILEDEF = 2937912522;
export const IFCCIRCLEPROFILEDEF = 1383045692;
export const IFCCIRCULARARCSEGMENT2D = 1062206242;
export const IFCCIVILELEMENT = 1677625105;
export const IFCCIVILELEMENTTYPE = 3893394355;
export const IFCCLASSIFICATION = 747523909;
export const IFCCLASSIFICATIONREFERENCE = 647927063;
export const IFCCLOSEDSHELL = 2205249479;
export const IFCCOIL = 639361253;
export const IFCCOILTYPE = 2301859152;
export const IFCCOLOURRGB = 776857604;
export const IFCCOLOURRGBLIST = 3285139300;
export const IFCCOLOURSPECIFICATION = 3264961684;
export const IFCCOLUMN = 843113511;
export const IFCCOLUMNSTANDARDCASE = 905975707;
export const IFCCOLUMNTYPE = 300633059;
export const IFCCOMMUNICATIONSAPPLIANCE = 3221913625;
export const IFCCOMMUNICATIONSAPPLIANCETYPE = 400855858;
export const IFCCOMPLEXPROPERTY = 2542286263;
export const IFCCOMPLEXPROPERTYTEMPLATE = 3875453745;
export const IFCCOMPOSITECURVE = 3732776249;
export const IFCCOMPOSITECURVEONSURFACE = 15328376;
export const IFCCOMPOSITECURVESEGMENT = 2485617015;
export const IFCCOMPOSITEPROFILEDEF = 1485152156;
export const IFCCOMPRESSOR = 3571504051;
export const IFCCOMPRESSORTYPE = 3850581409;
export const IFCCONDENSER = 2272882330;
export const IFCCONDENSERTYPE = 2816379211;
export const IFCCONIC = 2510884976;
export const IFCCONNECTEDFACESET = 370225590;
export const IFCCONNECTIONCURVEGEOMETRY = 1981873012;
export const IFCCONNECTIONGEOMETRY = 2859738748;
export const IFCCONNECTIONPOINTECCENTRICITY = 45288368;
export const IFCCONNECTIONPOINTGEOMETRY = 2614616156;
export const IFCCONNECTIONSURFACEGEOMETRY = 2732653382;
export const IFCCONNECTIONVOLUMEGEOMETRY = 775493141;
export const IFCCONSTRAINT = 1959218052;
export const IFCCONSTRUCTIONEQUIPMENTRESOURCE = 3898045240;
export const IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE = 2185764099;
export const IFCCONSTRUCTIONMATERIALRESOURCE = 1060000209;
export const IFCCONSTRUCTIONMATERIALRESOURCETYPE = 4105962743;
export const IFCCONSTRUCTIONPRODUCTRESOURCE = 488727124;
export const IFCCONSTRUCTIONPRODUCTRESOURCETYPE = 1525564444;
export const IFCCONSTRUCTIONRESOURCE = 2559216714;
export const IFCCONSTRUCTIONRESOURCETYPE = 2574617495;
export const IFCCONTEXT = 3419103109;
export const IFCCONTEXTDEPENDENTUNIT = 3050246964;
export const IFCCONTROL = 3293443760;
export const IFCCONTROLLER = 25142252;
export const IFCCONTROLLERTYPE = 578613899;
export const IFCCONVERSIONBASEDUNIT = 2889183280;
export const IFCCONVERSIONBASEDUNITWITHOFFSET = 2713554722;
export const IFCCOOLEDBEAM = 4136498852;
export const IFCCOOLEDBEAMTYPE = 335055490;
export const IFCCOOLINGTOWER = 3640358203;
export const IFCCOOLINGTOWERTYPE = 2954562838;
export const IFCCOORDINATEOPERATION = 1785450214;
export const IFCCOORDINATEREFERENCESYSTEM = 1466758467;
export const IFCCOSTITEM = 3895139033;
export const IFCCOSTSCHEDULE = 1419761937;
export const IFCCOSTVALUE = 602808272;
export const IFCCOVERING = 1973544240;
export const IFCCOVERINGTYPE = 1916426348;
export const IFCCREWRESOURCE = 3295246426;
export const IFCCREWRESOURCETYPE = 1815067380;
export const IFCCSGPRIMITIVE3D = 2506170314;
export const IFCCSGSOLID = 2147822146;
export const IFCCURRENCYRELATIONSHIP = 539742890;
export const IFCCURTAINWALL = 3495092785;
export const IFCCURTAINWALLTYPE = 1457835157;
export const IFCCURVE = 2601014836;
export const IFCCURVEBOUNDEDPLANE = 2827736869;
export const IFCCURVEBOUNDEDSURFACE = 2629017746;
export const IFCCURVESEGMENT2D = 1186437898;
export const IFCCURVESTYLE = 3800577675;
export const IFCCURVESTYLEFONT = 1105321065;
export const IFCCURVESTYLEFONTANDSCALING = 2367409068;
export const IFCCURVESTYLEFONTPATTERN = 3510044353;
export const IFCCYLINDRICALSURFACE = 1213902940;
export const IFCDAMPER = 4074379575;
export const IFCDAMPERTYPE = 3961806047;
export const IFCDEEPFOUNDATION = 3426335179;
export const IFCDEEPFOUNDATIONTYPE = 1306400036;
export const IFCDERIVEDPROFILEDEF = 3632507154;
export const IFCDERIVEDUNIT = 1765591967;
export const IFCDERIVEDUNITELEMENT = 1045800335;
export const IFCDIMENSIONALEXPONENTS = 2949456006;
export const IFCDIRECTION = 32440307;
export const IFCDISCRETEACCESSORY = 1335981549;
export const IFCDISCRETEACCESSORYTYPE = 2635815018;
export const IFCDISTANCEEXPRESSION = 1945343521;
export const IFCDISTRIBUTIONCHAMBERELEMENT = 1052013943;
export const IFCDISTRIBUTIONCHAMBERELEMENTTYPE = 1599208980;
export const IFCDISTRIBUTIONCIRCUIT = 562808652;
export const IFCDISTRIBUTIONCONTROLELEMENT = 1062813311;
export const IFCDISTRIBUTIONCONTROLELEMENTTYPE = 2063403501;
export const IFCDISTRIBUTIONELEMENT = 1945004755;
export const IFCDISTRIBUTIONELEMENTTYPE = 3256556792;
export const IFCDISTRIBUTIONFLOWELEMENT = 3040386961;
export const IFCDISTRIBUTIONFLOWELEMENTTYPE = 3849074793;
export const IFCDISTRIBUTIONPORT = 3041715199;
export const IFCDISTRIBUTIONSYSTEM = 3205830791;
export const IFCDOCUMENTINFORMATION = 1154170062;
export const IFCDOCUMENTINFORMATIONRELATIONSHIP = 770865208;
export const IFCDOCUMENTREFERENCE = 3732053477;
export const IFCDOOR = 395920057;
export const IFCDOORLININGPROPERTIES = 2963535650;
export const IFCDOORPANELPROPERTIES = 1714330368;
export const IFCDOORSTANDARDCASE = 3242481149;
export const IFCDOORSTYLE = 526551008;
export const IFCDOORTYPE = 2323601079;
export const IFCDRAUGHTINGPREDEFINEDCOLOUR = 445594917;
export const IFCDRAUGHTINGPREDEFINEDCURVEFONT = 4006246654;
export const IFCDUCTFITTING = 342316401;
export const IFCDUCTFITTINGTYPE = 869906466;
export const IFCDUCTSEGMENT = 3518393246;
export const IFCDUCTSEGMENTTYPE = 3760055223;
export const IFCDUCTSILENCER = 1360408905;
export const IFCDUCTSILENCERTYPE = 2030761528;
export const IFCEDGE = 3900360178;
export const IFCEDGECURVE = 476780140;
export const IFCEDGELOOP = 1472233963;
export const IFCELECTRICAPPLIANCE = 1904799276;
export const IFCELECTRICAPPLIANCETYPE = 663422040;
export const IFCELECTRICDISTRIBUTIONBOARD = 862014818;
export const IFCELECTRICDISTRIBUTIONBOARDTYPE = 2417008758;
export const IFCELECTRICFLOWSTORAGEDEVICE = 3310460725;
export const IFCELECTRICFLOWSTORAGEDEVICETYPE = 3277789161;
export const IFCELECTRICGENERATOR = 264262732;
export const IFCELECTRICGENERATORTYPE = 1534661035;
export const IFCELECTRICMOTOR = 402227799;
export const IFCELECTRICMOTORTYPE = 1217240411;
export const IFCELECTRICTIMECONTROL = 1003880860;
export const IFCELECTRICTIMECONTROLTYPE = 712377611;
export const IFCELEMENT = 1758889154;
export const IFCELEMENTASSEMBLY = 4123344466;
export const IFCELEMENTASSEMBLYTYPE = 2397081782;
export const IFCELEMENTCOMPONENT = 1623761950;
export const IFCELEMENTCOMPONENTTYPE = 2590856083;
export const IFCELEMENTQUANTITY = 1883228015;
export const IFCELEMENTTYPE = 339256511;
export const IFCELEMENTARYSURFACE = 2777663545;
export const IFCELLIPSE = 1704287377;
export const IFCELLIPSEPROFILEDEF = 2835456948;
export const IFCENERGYCONVERSIONDEVICE = 1658829314;
export const IFCENERGYCONVERSIONDEVICETYPE = 2107101300;
export const IFCENGINE = 2814081492;
export const IFCENGINETYPE = 132023988;
export const IFCEVAPORATIVECOOLER = 3747195512;
export const IFCEVAPORATIVECOOLERTYPE = 3174744832;
export const IFCEVAPORATOR = 484807127;
export const IFCEVAPORATORTYPE = 3390157468;
export const IFCEVENT = 4148101412;
export const IFCEVENTTIME = 211053100;
export const IFCEVENTTYPE = 4024345920;
export const IFCEXTENDEDPROPERTIES = 297599258;
export const IFCEXTERNALINFORMATION = 4294318154;
export const IFCEXTERNALREFERENCE = 3200245327;
export const IFCEXTERNALREFERENCERELATIONSHIP = 1437805879;
export const IFCEXTERNALSPATIALELEMENT = 1209101575;
export const IFCEXTERNALSPATIALSTRUCTUREELEMENT = 2853485674;
export const IFCEXTERNALLYDEFINEDHATCHSTYLE = 2242383968;
export const IFCEXTERNALLYDEFINEDSURFACESTYLE = 1040185647;
export const IFCEXTERNALLYDEFINEDTEXTFONT = 3548104201;
export const IFCEXTRUDEDAREASOLID = 477187591;
export const IFCEXTRUDEDAREASOLIDTAPERED = 2804161546;
export const IFCFACE = 2556980723;
export const IFCFACEBASEDSURFACEMODEL = 2047409740;
export const IFCFACEBOUND = 1809719519;
export const IFCFACEOUTERBOUND = 803316827;
export const IFCFACESURFACE = 3008276851;
export const IFCFACETEDBREP = 807026263;
export const IFCFACETEDBREPWITHVOIDS = 3737207727;
export const IFCFACILITY = 24185140;
export const IFCFACILITYPART = 1310830890;
export const IFCFAILURECONNECTIONCONDITION = 4219587988;
export const IFCFAN = 3415622556;
export const IFCFANTYPE = 346874300;
export const IFCFASTENER = 647756555;
export const IFCFASTENERTYPE = 2489546625;
export const IFCFEATUREELEMENT = 2827207264;
export const IFCFEATUREELEMENTADDITION = 2143335405;
export const IFCFEATUREELEMENTSUBTRACTION = 1287392070;
export const IFCFILLAREASTYLE = 738692330;
export const IFCFILLAREASTYLEHATCHING = 374418227;
export const IFCFILLAREASTYLETILES = 315944413;
export const IFCFILTER = 819412036;
export const IFCFILTERTYPE = 1810631287;
export const IFCFIRESUPPRESSIONTERMINAL = 1426591983;
export const IFCFIRESUPPRESSIONTERMINALTYPE = 4222183408;
export const IFCFIXEDREFERENCESWEPTAREASOLID = 2652556860;
export const IFCFLOWCONTROLLER = 2058353004;
export const IFCFLOWCONTROLLERTYPE = 3907093117;
export const IFCFLOWFITTING = 4278956645;
export const IFCFLOWFITTINGTYPE = 3198132628;
export const IFCFLOWINSTRUMENT = 182646315;
export const IFCFLOWINSTRUMENTTYPE = 4037862832;
export const IFCFLOWMETER = 2188021234;
export const IFCFLOWMETERTYPE = 3815607619;
export const IFCFLOWMOVINGDEVICE = 3132237377;
export const IFCFLOWMOVINGDEVICETYPE = 1482959167;
export const IFCFLOWSEGMENT = 987401354;
export const IFCFLOWSEGMENTTYPE = 1834744321;
export const IFCFLOWSTORAGEDEVICE = 707683696;
export const IFCFLOWSTORAGEDEVICETYPE = 1339347760;
export const IFCFLOWTERMINAL = 2223149337;
export const IFCFLOWTERMINALTYPE = 2297155007;
export const IFCFLOWTREATMENTDEVICE = 3508470533;
export const IFCFLOWTREATMENTDEVICETYPE = 3009222698;
export const IFCFOOTING = 900683007;
export const IFCFOOTINGTYPE = 1893162501;
export const IFCFURNISHINGELEMENT = 263784265;
export const IFCFURNISHINGELEMENTTYPE = 4238390223;
export const IFCFURNITURE = 1509553395;
export const IFCFURNITURETYPE = 1268542332;
export const IFCGEOGRAPHICELEMENT = 3493046030;
export const IFCGEOGRAPHICELEMENTTYPE = 4095422895;
export const IFCGEOMETRICCURVESET = 987898635;
export const IFCGEOMETRICREPRESENTATIONCONTEXT = 3448662350;
export const IFCGEOMETRICREPRESENTATIONITEM = 2453401579;
export const IFCGEOMETRICREPRESENTATIONSUBCONTEXT = 4142052618;
export const IFCGEOMETRICSET = 3590301190;
export const IFCGRID = 3009204131;
export const IFCGRIDAXIS = 852622518;
export const IFCGRIDPLACEMENT = 178086475;
export const IFCGROUP = 2706460486;
export const IFCHALFSPACESOLID = 812098782;
export const IFCHEATEXCHANGER = 3319311131;
export const IFCHEATEXCHANGERTYPE = 1251058090;
export const IFCHUMIDIFIER = 2068733104;
export const IFCHUMIDIFIERTYPE = 1806887404;
export const IFCISHAPEPROFILEDEF = 1484403080;
export const IFCIMAGETEXTURE = 3905492369;
export const IFCINDEXEDCOLOURMAP = 3570813810;
export const IFCINDEXEDPOLYCURVE = 2571569899;
export const IFCINDEXEDPOLYGONALFACE = 178912537;
export const IFCINDEXEDPOLYGONALFACEWITHVOIDS = 2294589976;
export const IFCINDEXEDTEXTUREMAP = 1437953363;
export const IFCINDEXEDTRIANGLETEXTUREMAP = 2133299955;
export const IFCINTERCEPTOR = 4175244083;
export const IFCINTERCEPTORTYPE = 3946677679;
export const IFCINTERSECTIONCURVE = 3113134337;
export const IFCINVENTORY = 2391368822;
export const IFCIRREGULARTIMESERIES = 3741457305;
export const IFCIRREGULARTIMESERIESVALUE = 3020489413;
export const IFCJUNCTIONBOX = 2176052936;
export const IFCJUNCTIONBOXTYPE = 4288270099;
export const IFCLSHAPEPROFILEDEF = 572779678;
export const IFCLABORRESOURCE = 3827777499;
export const IFCLABORRESOURCETYPE = 428585644;
export const IFCLAGTIME = 1585845231;
export const IFCLAMP = 76236018;
export const IFCLAMPTYPE = 1051575348;
export const IFCLIBRARYINFORMATION = 2655187982;
export const IFCLIBRARYREFERENCE = 3452421091;
export const IFCLIGHTDISTRIBUTIONDATA = 4162380809;
export const IFCLIGHTFIXTURE = 629592764;
export const IFCLIGHTFIXTURETYPE = 1161773419;
export const IFCLIGHTINTENSITYDISTRIBUTION = 1566485204;
export const IFCLIGHTSOURCE = 1402838566;
export const IFCLIGHTSOURCEAMBIENT = 125510826;
export const IFCLIGHTSOURCEDIRECTIONAL = 2604431987;
export const IFCLIGHTSOURCEGONIOMETRIC = 4266656042;
export const IFCLIGHTSOURCEPOSITIONAL = 1520743889;
export const IFCLIGHTSOURCESPOT = 3422422726;
export const IFCLINE = 1281925730;
export const IFCLINESEGMENT2D = 3092502836;
export const IFCLINEARPLACEMENT = 388784114;
export const IFCLINEARPOSITIONINGELEMENT = 1154579445;
export const IFCLOCALPLACEMENT = 2624227202;
export const IFCLOOP = 1008929658;
export const IFCMANIFOLDSOLIDBREP = 1425443689;
export const IFCMAPCONVERSION = 3057273783;
export const IFCMAPPEDITEM = 2347385850;
export const IFCMATERIAL = 1838606355;
export const IFCMATERIALCLASSIFICATIONRELATIONSHIP = 1847130766;
export const IFCMATERIALCONSTITUENT = 3708119000;
export const IFCMATERIALCONSTITUENTSET = 2852063980;
export const IFCMATERIALDEFINITION = 760658860;
export const IFCMATERIALDEFINITIONREPRESENTATION = 2022407955;
export const IFCMATERIALLAYER = 248100487;
export const IFCMATERIALLAYERSET = 3303938423;
export const IFCMATERIALLAYERSETUSAGE = 1303795690;
export const IFCMATERIALLAYERWITHOFFSETS = 1847252529;
export const IFCMATERIALLIST = 2199411900;
export const IFCMATERIALPROFILE = 2235152071;
export const IFCMATERIALPROFILESET = 164193824;
export const IFCMATERIALPROFILESETUSAGE = 3079605661;
export const IFCMATERIALPROFILESETUSAGETAPERING = 3404854881;
export const IFCMATERIALPROFILEWITHOFFSETS = 552965576;
export const IFCMATERIALPROPERTIES = 3265635763;
export const IFCMATERIALRELATIONSHIP = 853536259;
export const IFCMATERIALUSAGEDEFINITION = 1507914824;
export const IFCMEASUREWITHUNIT = 2597039031;
export const IFCMECHANICALFASTENER = 377706215;
export const IFCMECHANICALFASTENERTYPE = 2108223431;
export const IFCMEDICALDEVICE = 1437502449;
export const IFCMEDICALDEVICETYPE = 1114901282;
export const IFCMEMBER = 1073191201;
export const IFCMEMBERSTANDARDCASE = 1911478936;
export const IFCMEMBERTYPE = 3181161470;
export const IFCMETRIC = 3368373690;
export const IFCMIRROREDPROFILEDEF = 2998442950;
export const IFCMONETARYUNIT = 2706619895;
export const IFCMOTORCONNECTION = 2474470126;
export const IFCMOTORCONNECTIONTYPE = 977012517;
export const IFCNAMEDUNIT = 1918398963;
export const IFCOBJECT = 3888040117;
export const IFCOBJECTDEFINITION = 219451334;
export const IFCOBJECTPLACEMENT = 3701648758;
export const IFCOBJECTIVE = 2251480897;
export const IFCOCCUPANT = 4143007308;
export const IFCOFFSETCURVE = 590820931;
export const IFCOFFSETCURVE2D = 3388369263;
export const IFCOFFSETCURVE3D = 3505215534;
export const IFCOFFSETCURVEBYDISTANCES = 2485787929;
export const IFCOPENSHELL = 2665983363;
export const IFCOPENINGELEMENT = 3588315303;
export const IFCOPENINGSTANDARDCASE = 3079942009;
export const IFCORGANIZATION = 4251960020;
export const IFCORGANIZATIONRELATIONSHIP = 1411181986;
export const IFCORIENTATIONEXPRESSION = 643959842;
export const IFCORIENTEDEDGE = 1029017970;
export const IFCOUTERBOUNDARYCURVE = 144952367;
export const IFCOUTLET = 3694346114;
export const IFCOUTLETTYPE = 2837617999;
export const IFCOWNERHISTORY = 1207048766;
export const IFCPARAMETERIZEDPROFILEDEF = 2529465313;
export const IFCPATH = 2519244187;
export const IFCPCURVE = 1682466193;
export const IFCPERFORMANCEHISTORY = 2382730787;
export const IFCPERMEABLECOVERINGPROPERTIES = 3566463478;
export const IFCPERMIT = 3327091369;
export const IFCPERSON = 2077209135;
export const IFCPERSONANDORGANIZATION = 101040310;
export const IFCPHYSICALCOMPLEXQUANTITY = 3021840470;
export const IFCPHYSICALQUANTITY = 2483315170;
export const IFCPHYSICALSIMPLEQUANTITY = 2226359599;
export const IFCPILE = 1687234759;
export const IFCPILETYPE = 1158309216;
export const IFCPIPEFITTING = 310824031;
export const IFCPIPEFITTINGTYPE = 804291784;
export const IFCPIPESEGMENT = 3612865200;
export const IFCPIPESEGMENTTYPE = 4231323485;
export const IFCPIXELTEXTURE = 597895409;
export const IFCPLACEMENT = 2004835150;
export const IFCPLANARBOX = 603570806;
export const IFCPLANAREXTENT = 1663979128;
export const IFCPLANE = 220341763;
export const IFCPLATE = 3171933400;
export const IFCPLATESTANDARDCASE = 1156407060;
export const IFCPLATETYPE = 4017108033;
export const IFCPOINT = 2067069095;
export const IFCPOINTONCURVE = 4022376103;
export const IFCPOINTONSURFACE = 1423911732;
export const IFCPOLYLOOP = 2924175390;
export const IFCPOLYGONALBOUNDEDHALFSPACE = 2775532180;
export const IFCPOLYGONALFACESET = 2839578677;
export const IFCPOLYLINE = 3724593414;
export const IFCPORT = 3740093272;
export const IFCPOSITIONINGELEMENT = 1946335990;
export const IFCPOSTALADDRESS = 3355820592;
export const IFCPREDEFINEDCOLOUR = 759155922;
export const IFCPREDEFINEDCURVEFONT = 2559016684;
export const IFCPREDEFINEDITEM = 3727388367;
export const IFCPREDEFINEDPROPERTIES = 3778827333;
export const IFCPREDEFINEDPROPERTYSET = 3967405729;
export const IFCPREDEFINEDTEXTFONT = 1775413392;
export const IFCPRESENTATIONITEM = 677532197;
export const IFCPRESENTATIONLAYERASSIGNMENT = 2022622350;
export const IFCPRESENTATIONLAYERWITHSTYLE = 1304840413;
export const IFCPRESENTATIONSTYLE = 3119450353;
export const IFCPRESENTATIONSTYLEASSIGNMENT = 2417041796;
export const IFCPROCEDURE = 2744685151;
export const IFCPROCEDURETYPE = 569719735;
export const IFCPROCESS = 2945172077;
export const IFCPRODUCT = 4208778838;
export const IFCPRODUCTDEFINITIONSHAPE = 673634403;
export const IFCPRODUCTREPRESENTATION = 2095639259;
export const IFCPROFILEDEF = 3958567839;
export const IFCPROFILEPROPERTIES = 2802850158;
export const IFCPROJECT = 103090709;
export const IFCPROJECTLIBRARY = 653396225;
export const IFCPROJECTORDER = 2904328755;
export const IFCPROJECTEDCRS = 3843373140;
export const IFCPROJECTIONELEMENT = 3651124850;
export const IFCPROPERTY = 2598011224;
export const IFCPROPERTYABSTRACTION = 986844984;
export const IFCPROPERTYBOUNDEDVALUE = 871118103;
export const IFCPROPERTYDEFINITION = 1680319473;
export const IFCPROPERTYDEPENDENCYRELATIONSHIP = 148025276;
export const IFCPROPERTYENUMERATEDVALUE = 4166981789;
export const IFCPROPERTYENUMERATION = 3710013099;
export const IFCPROPERTYLISTVALUE = 2752243245;
export const IFCPROPERTYREFERENCEVALUE = 941946838;
export const IFCPROPERTYSET = 1451395588;
export const IFCPROPERTYSETDEFINITION = 3357820518;
export const IFCPROPERTYSETTEMPLATE = 492091185;
export const IFCPROPERTYSINGLEVALUE = 3650150729;
export const IFCPROPERTYTABLEVALUE = 110355661;
export const IFCPROPERTYTEMPLATE = 3521284610;
export const IFCPROPERTYTEMPLATEDEFINITION = 1482703590;
export const IFCPROTECTIVEDEVICE = 738039164;
export const IFCPROTECTIVEDEVICETRIPPINGUNIT = 2295281155;
export const IFCPROTECTIVEDEVICETRIPPINGUNITTYPE = 655969474;
export const IFCPROTECTIVEDEVICETYPE = 1842657554;
export const IFCPROXY = 3219374653;
export const IFCPUMP = 90941305;
export const IFCPUMPTYPE = 2250791053;
export const IFCQUANTITYAREA = 2044713172;
export const IFCQUANTITYCOUNT = 2093928680;
export const IFCQUANTITYLENGTH = 931644368;
export const IFCQUANTITYSET = 2090586900;
export const IFCQUANTITYTIME = 3252649465;
export const IFCQUANTITYVOLUME = 2405470396;
export const IFCQUANTITYWEIGHT = 825690147;
export const IFCRAILING = 2262370178;
export const IFCRAILINGTYPE = 2893384427;
export const IFCRAMP = 3024970846;
export const IFCRAMPFLIGHT = 3283111854;
export const IFCRAMPFLIGHTTYPE = 2324767716;
export const IFCRAMPTYPE = 1469900589;
export const IFCRATIONALBSPLINECURVEWITHKNOTS = 1232101972;
export const IFCRATIONALBSPLINESURFACEWITHKNOTS = 683857671;
export const IFCRECTANGLEHOLLOWPROFILEDEF = 2770003689;
export const IFCRECTANGLEPROFILEDEF = 3615266464;
export const IFCRECTANGULARPYRAMID = 2798486643;
export const IFCRECTANGULARTRIMMEDSURFACE = 3454111270;
export const IFCRECURRENCEPATTERN = 3915482550;
export const IFCREFERENCE = 2433181523;
export const IFCREFERENT = 4021432810;
export const IFCREGULARTIMESERIES = 3413951693;
export const IFCREINFORCEMENTBARPROPERTIES = 1580146022;
export const IFCREINFORCEMENTDEFINITIONPROPERTIES = 3765753017;
export const IFCREINFORCINGBAR = 979691226;
export const IFCREINFORCINGBARTYPE = 2572171363;
export const IFCREINFORCINGELEMENT = 3027567501;
export const IFCREINFORCINGELEMENTTYPE = 964333572;
export const IFCREINFORCINGMESH = 2320036040;
export const IFCREINFORCINGMESHTYPE = 2310774935;
export const IFCRELAGGREGATES = 160246688;
export const IFCRELASSIGNS = 3939117080;
export const IFCRELASSIGNSTOACTOR = 1683148259;
export const IFCRELASSIGNSTOCONTROL = 2495723537;
export const IFCRELASSIGNSTOGROUP = 1307041759;
export const IFCRELASSIGNSTOGROUPBYFACTOR = 1027710054;
export const IFCRELASSIGNSTOPROCESS = 4278684876;
export const IFCRELASSIGNSTOPRODUCT = 2857406711;
export const IFCRELASSIGNSTORESOURCE = 205026976;
export const IFCRELASSOCIATES = 1865459582;
export const IFCRELASSOCIATESAPPROVAL = 4095574036;
export const IFCRELASSOCIATESCLASSIFICATION = 919958153;
export const IFCRELASSOCIATESCONSTRAINT = 2728634034;
export const IFCRELASSOCIATESDOCUMENT = 982818633;
export const IFCRELASSOCIATESLIBRARY = 3840914261;
export const IFCRELASSOCIATESMATERIAL = 2655215786;
export const IFCRELCONNECTS = 826625072;
export const IFCRELCONNECTSELEMENTS = 1204542856;
export const IFCRELCONNECTSPATHELEMENTS = 3945020480;
export const IFCRELCONNECTSPORTTOELEMENT = 4201705270;
export const IFCRELCONNECTSPORTS = 3190031847;
export const IFCRELCONNECTSSTRUCTURALACTIVITY = 2127690289;
export const IFCRELCONNECTSSTRUCTURALMEMBER = 1638771189;
export const IFCRELCONNECTSWITHECCENTRICITY = 504942748;
export const IFCRELCONNECTSWITHREALIZINGELEMENTS = 3678494232;
export const IFCRELCONTAINEDINSPATIALSTRUCTURE = 3242617779;
export const IFCRELCOVERSBLDGELEMENTS = 886880790;
export const IFCRELCOVERSSPACES = 2802773753;
export const IFCRELDECLARES = 2565941209;
export const IFCRELDECOMPOSES = 2551354335;
export const IFCRELDEFINES = 693640335;
export const IFCRELDEFINESBYOBJECT = 1462361463;
export const IFCRELDEFINESBYPROPERTIES = 4186316022;
export const IFCRELDEFINESBYTEMPLATE = 307848117;
export const IFCRELDEFINESBYTYPE = 781010003;
export const IFCRELFILLSELEMENT = 3940055652;
export const IFCRELFLOWCONTROLELEMENTS = 279856033;
export const IFCRELINTERFERESELEMENTS = 427948657;
export const IFCRELNESTS = 3268803585;
export const IFCRELPOSITIONS = 1441486842;
export const IFCRELPROJECTSELEMENT = 750771296;
export const IFCRELREFERENCEDINSPATIALSTRUCTURE = 1245217292;
export const IFCRELSEQUENCE = 4122056220;
export const IFCRELSERVICESBUILDINGS = 366585022;
export const IFCRELSPACEBOUNDARY = 3451746338;
export const IFCRELSPACEBOUNDARY1STLEVEL = 3523091289;
export const IFCRELSPACEBOUNDARY2NDLEVEL = 1521410863;
export const IFCRELVOIDSELEMENT = 1401173127;
export const IFCRELATIONSHIP = 478536968;
export const IFCREPARAMETRISEDCOMPOSITECURVESEGMENT = 816062949;
export const IFCREPRESENTATION = 1076942058;
export const IFCREPRESENTATIONCONTEXT = 3377609919;
export const IFCREPRESENTATIONITEM = 3008791417;
export const IFCREPRESENTATIONMAP = 1660063152;
export const IFCRESOURCE = 2914609552;
export const IFCRESOURCEAPPROVALRELATIONSHIP = 2943643501;
export const IFCRESOURCECONSTRAINTRELATIONSHIP = 1608871552;
export const IFCRESOURCELEVELRELATIONSHIP = 2439245199;
export const IFCRESOURCETIME = 1042787934;
export const IFCREVOLVEDAREASOLID = 1856042241;
export const IFCREVOLVEDAREASOLIDTAPERED = 3243963512;
export const IFCRIGHTCIRCULARCONE = 4158566097;
export const IFCRIGHTCIRCULARCYLINDER = 3626867408;
export const IFCROOF = 2016517767;
export const IFCROOFTYPE = 2781568857;
export const IFCROOT = 2341007311;
export const IFCROUNDEDRECTANGLEPROFILEDEF = 2778083089;
export const IFCSIUNIT = 448429030;
export const IFCSANITARYTERMINAL = 3053780830;
export const IFCSANITARYTERMINALTYPE = 1768891740;
export const IFCSCHEDULINGTIME = 1054537805;
export const IFCSEAMCURVE = 2157484638;
export const IFCSECTIONPROPERTIES = 2042790032;
export const IFCSECTIONREINFORCEMENTPROPERTIES = 4165799628;
export const IFCSECTIONEDSOLID = 1862484736;
export const IFCSECTIONEDSOLIDHORIZONTAL = 1290935644;
export const IFCSECTIONEDSPINE = 1509187699;
export const IFCSENSOR = 4086658281;
export const IFCSENSORTYPE = 1783015770;
export const IFCSHADINGDEVICE = 1329646415;
export const IFCSHADINGDEVICETYPE = 4074543187;
export const IFCSHAPEASPECT = 867548509;
export const IFCSHAPEMODEL = 3982875396;
export const IFCSHAPEREPRESENTATION = 4240577450;
export const IFCSHELLBASEDSURFACEMODEL = 4124623270;
export const IFCSIMPLEPROPERTY = 3692461612;
export const IFCSIMPLEPROPERTYTEMPLATE = 3663146110;
export const IFCSITE = 4097777520;
export const IFCSLAB = 1529196076;
export const IFCSLABELEMENTEDCASE = 3127900445;
export const IFCSLABSTANDARDCASE = 3027962421;
export const IFCSLABTYPE = 2533589738;
export const IFCSLIPPAGECONNECTIONCONDITION = 2609359061;
export const IFCSOLARDEVICE = 3420628829;
export const IFCSOLARDEVICETYPE = 1072016465;
export const IFCSOLIDMODEL = 723233188;
export const IFCSPACE = 3856911033;
export const IFCSPACEHEATER = 1999602285;
export const IFCSPACEHEATERTYPE = 1305183839;
export const IFCSPACETYPE = 3812236995;
export const IFCSPATIALELEMENT = 1412071761;
export const IFCSPATIALELEMENTTYPE = 710998568;
export const IFCSPATIALSTRUCTUREELEMENT = 2706606064;
export const IFCSPATIALSTRUCTUREELEMENTTYPE = 3893378262;
export const IFCSPATIALZONE = 463610769;
export const IFCSPATIALZONETYPE = 2481509218;
export const IFCSPHERE = 451544542;
export const IFCSPHERICALSURFACE = 4015995234;
export const IFCSTACKTERMINAL = 1404847402;
export const IFCSTACKTERMINALTYPE = 3112655638;
export const IFCSTAIR = 331165859;
export const IFCSTAIRFLIGHT = 4252922144;
export const IFCSTAIRFLIGHTTYPE = 1039846685;
export const IFCSTAIRTYPE = 338393293;
export const IFCSTRUCTURALACTION = 682877961;
export const IFCSTRUCTURALACTIVITY = 3544373492;
export const IFCSTRUCTURALANALYSISMODEL = 2515109513;
export const IFCSTRUCTURALCONNECTION = 1179482911;
export const IFCSTRUCTURALCONNECTIONCONDITION = 2273995522;
export const IFCSTRUCTURALCURVEACTION = 1004757350;
export const IFCSTRUCTURALCURVECONNECTION = 4243806635;
export const IFCSTRUCTURALCURVEMEMBER = 214636428;
export const IFCSTRUCTURALCURVEMEMBERVARYING = 2445595289;
export const IFCSTRUCTURALCURVEREACTION = 2757150158;
export const IFCSTRUCTURALITEM = 3136571912;
export const IFCSTRUCTURALLINEARACTION = 1807405624;
export const IFCSTRUCTURALLOAD = 2162789131;
export const IFCSTRUCTURALLOADCASE = 385403989;
export const IFCSTRUCTURALLOADCONFIGURATION = 3478079324;
export const IFCSTRUCTURALLOADGROUP = 1252848954;
export const IFCSTRUCTURALLOADLINEARFORCE = 1595516126;
export const IFCSTRUCTURALLOADORRESULT = 609421318;
export const IFCSTRUCTURALLOADPLANARFORCE = 2668620305;
export const IFCSTRUCTURALLOADSINGLEDISPLACEMENT = 2473145415;
export const IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION = 1973038258;
export const IFCSTRUCTURALLOADSINGLEFORCE = 1597423693;
export const IFCSTRUCTURALLOADSINGLEFORCEWARPING = 1190533807;
export const IFCSTRUCTURALLOADSTATIC = 2525727697;
export const IFCSTRUCTURALLOADTEMPERATURE = 3408363356;
export const IFCSTRUCTURALMEMBER = 530289379;
export const IFCSTRUCTURALPLANARACTION = 1621171031;
export const IFCSTRUCTURALPOINTACTION = 2082059205;
export const IFCSTRUCTURALPOINTCONNECTION = 734778138;
export const IFCSTRUCTURALPOINTREACTION = 1235345126;
export const IFCSTRUCTURALREACTION = 3689010777;
export const IFCSTRUCTURALRESULTGROUP = 2986769608;
export const IFCSTRUCTURALSURFACEACTION = 3657597509;
export const IFCSTRUCTURALSURFACECONNECTION = 1975003073;
export const IFCSTRUCTURALSURFACEMEMBER = 3979015343;
export const IFCSTRUCTURALSURFACEMEMBERVARYING = 2218152070;
export const IFCSTRUCTURALSURFACEREACTION = 603775116;
export const IFCSTYLEMODEL = 2830218821;
export const IFCSTYLEDITEM = 3958052878;
export const IFCSTYLEDREPRESENTATION = 3049322572;
export const IFCSUBCONTRACTRESOURCE = 148013059;
export const IFCSUBCONTRACTRESOURCETYPE = 4095615324;
export const IFCSUBEDGE = 2233826070;
export const IFCSURFACE = 2513912981;
export const IFCSURFACECURVE = 699246055;
export const IFCSURFACECURVESWEPTAREASOLID = 2028607225;
export const IFCSURFACEFEATURE = 3101698114;
export const IFCSURFACEOFLINEAREXTRUSION = 2809605785;
export const IFCSURFACEOFREVOLUTION = 4124788165;
export const IFCSURFACEREINFORCEMENTAREA = 2934153892;
export const IFCSURFACESTYLE = 1300840506;
export const IFCSURFACESTYLELIGHTING = 3303107099;
export const IFCSURFACESTYLEREFRACTION = 1607154358;
export const IFCSURFACESTYLERENDERING = 1878645084;
export const IFCSURFACESTYLESHADING = 846575682;
export const IFCSURFACESTYLEWITHTEXTURES = 1351298697;
export const IFCSURFACETEXTURE = 626085974;
export const IFCSWEPTAREASOLID = 2247615214;
export const IFCSWEPTDISKSOLID = 1260650574;
export const IFCSWEPTDISKSOLIDPOLYGONAL = 1096409881;
export const IFCSWEPTSURFACE = 230924584;
export const IFCSWITCHINGDEVICE = 1162798199;
export const IFCSWITCHINGDEVICETYPE = 2315554128;
export const IFCSYSTEM = 2254336722;
export const IFCSYSTEMFURNITUREELEMENT = 413509423;
export const IFCSYSTEMFURNITUREELEMENTTYPE = 1580310250;
export const IFCTSHAPEPROFILEDEF = 3071757647;
export const IFCTABLE = 985171141;
export const IFCTABLECOLUMN = 2043862942;
export const IFCTABLEROW = 531007025;
export const IFCTANK = 812556717;
export const IFCTANKTYPE = 5716631;
export const IFCTASK = 3473067441;
export const IFCTASKTIME = 1549132990;
export const IFCTASKTIMERECURRING = 2771591690;
export const IFCTASKTYPE = 3206491090;
export const IFCTELECOMADDRESS = 912023232;
export const IFCTENDON = 3824725483;
export const IFCTENDONANCHOR = 2347447852;
export const IFCTENDONANCHORTYPE = 3081323446;
export const IFCTENDONCONDUIT = 3663046924;
export const IFCTENDONCONDUITTYPE = 2281632017;
export const IFCTENDONTYPE = 2415094496;
export const IFCTESSELLATEDFACESET = 2387106220;
export const IFCTESSELLATEDITEM = 901063453;
export const IFCTEXTLITERAL = 4282788508;
export const IFCTEXTLITERALWITHEXTENT = 3124975700;
export const IFCTEXTSTYLE = 1447204868;
export const IFCTEXTSTYLEFONTMODEL = 1983826977;
export const IFCTEXTSTYLEFORDEFINEDFONT = 2636378356;
export const IFCTEXTSTYLETEXTMODEL = 1640371178;
export const IFCTEXTURECOORDINATE = 280115917;
export const IFCTEXTURECOORDINATEGENERATOR = 1742049831;
export const IFCTEXTUREMAP = 2552916305;
export const IFCTEXTUREVERTEX = 1210645708;
export const IFCTEXTUREVERTEXLIST = 3611470254;
export const IFCTIMEPERIOD = 1199560280;
export const IFCTIMESERIES = 3101149627;
export const IFCTIMESERIESVALUE = 581633288;
export const IFCTOPOLOGICALREPRESENTATIONITEM = 1377556343;
export const IFCTOPOLOGYREPRESENTATION = 1735638870;
export const IFCTOROIDALSURFACE = 1935646853;
export const IFCTRANSFORMER = 3825984169;
export const IFCTRANSFORMERTYPE = 1692211062;
export const IFCTRANSITIONCURVESEGMENT2D = 2595432518;
export const IFCTRANSPORTELEMENT = 1620046519;
export const IFCTRANSPORTELEMENTTYPE = 2097647324;
export const IFCTRAPEZIUMPROFILEDEF = 2715220739;
export const IFCTRIANGULATEDFACESET = 2916149573;
export const IFCTRIANGULATEDIRREGULARNETWORK = 1229763772;
export const IFCTRIMMEDCURVE = 3593883385;
export const IFCTUBEBUNDLE = 3026737570;
export const IFCTUBEBUNDLETYPE = 1600972822;
export const IFCTYPEOBJECT = 1628702193;
export const IFCTYPEPROCESS = 3736923433;
export const IFCTYPEPRODUCT = 2347495698;
export const IFCTYPERESOURCE = 3698973494;
export const IFCUSHAPEPROFILEDEF = 427810014;
export const IFCUNITASSIGNMENT = 180925521;
export const IFCUNITARYCONTROLELEMENT = 630975310;
export const IFCUNITARYCONTROLELEMENTTYPE = 3179687236;
export const IFCUNITARYEQUIPMENT = 4292641817;
export const IFCUNITARYEQUIPMENTTYPE = 1911125066;
export const IFCVALVE = 4207607924;
export const IFCVALVETYPE = 728799441;
export const IFCVECTOR = 1417489154;
export const IFCVERTEX = 2799835756;
export const IFCVERTEXLOOP = 2759199220;
export const IFCVERTEXPOINT = 1907098498;
export const IFCVIBRATIONDAMPER = 1530820697;
export const IFCVIBRATIONDAMPERTYPE = 3956297820;
export const IFCVIBRATIONISOLATOR = 2391383451;
export const IFCVIBRATIONISOLATORTYPE = 3313531582;
export const IFCVIRTUALELEMENT = 2769231204;
export const IFCVIRTUALGRIDINTERSECTION = 891718957;
export const IFCVOIDINGFEATURE = 926996030;
export const IFCWALL = 2391406946;
export const IFCWALLELEMENTEDCASE = 4156078855;
export const IFCWALLSTANDARDCASE = 3512223829;
export const IFCWALLTYPE = 1898987631;
export const IFCWASTETERMINAL = 4237592921;
export const IFCWASTETERMINALTYPE = 1133259667;
export const IFCWINDOW = 3304561284;
export const IFCWINDOWLININGPROPERTIES = 336235671;
export const IFCWINDOWPANELPROPERTIES = 512836454;
export const IFCWINDOWSTANDARDCASE = 486154966;
export const IFCWINDOWSTYLE = 1299126871;
export const IFCWINDOWTYPE = 4009809668;
export const IFCWORKCALENDAR = 4088093105;
export const IFCWORKCONTROL = 1028945134;
export const IFCWORKPLAN = 4218914973;
export const IFCWORKSCHEDULE = 3342526732;
export const IFCWORKTIME = 1236880293;
export const IFCZSHAPEPROFILEDEF = 2543172580;
export const IFCZONE = 1033361043;

export const IfcElements = [
	IFCACTUATOR,
	IFCAIRTERMINAL,
	IFCAIRTERMINALBOX,
	IFCAIRTOAIRHEATRECOVERY,
	IFCALARM,
	IFCALIGNMENT,
	IFCANNOTATION,
	IFCAUDIOVISUALAPPLIANCE,
	IFCBEAM,
	IFCBEAMSTANDARDCASE,
	IFCBEARING,
	IFCBOILER,
	IFCBRIDGE,
	IFCBRIDGEPART,
	IFCBUILDING,
	IFCBUILDINGELEMENT,
	IFCBUILDINGELEMENTPART,
	IFCBUILDINGELEMENTPROXY,
	IFCBUILDINGSTOREY,
	IFCBURNER,
	IFCCABLECARRIERFITTING,
	IFCCABLECARRIERSEGMENT,
	IFCCABLEFITTING,
	IFCCABLESEGMENT,
	IFCCAISSONFOUNDATION,
	IFCCHILLER,
	IFCCHIMNEY,
	IFCCIVILELEMENT,
	IFCCOIL,
	IFCCOLUMN,
	IFCCOLUMNSTANDARDCASE,
	IFCCOMMUNICATIONSAPPLIANCE,
	IFCCOMPRESSOR,
	IFCCONDENSER,
	IFCCONTROLLER,
	IFCCOOLEDBEAM,
	IFCCOOLINGTOWER,
	IFCCOVERING,
	IFCCURTAINWALL,
	IFCDAMPER,
	IFCDEEPFOUNDATION,
	IFCDISCRETEACCESSORY,
	IFCDISTRIBUTIONCHAMBERELEMENT,
	IFCDISTRIBUTIONCONTROLELEMENT,
	IFCDISTRIBUTIONELEMENT,
	IFCDISTRIBUTIONFLOWELEMENT,
	IFCDISTRIBUTIONPORT,
	IFCDOOR,
	IFCDOORSTANDARDCASE,
	IFCDUCTFITTING,
	IFCDUCTSEGMENT,
	IFCDUCTSILENCER,
	IFCELECTRICAPPLIANCE,
	IFCELECTRICDISTRIBUTIONBOARD,
	IFCELECTRICFLOWSTORAGEDEVICE,
	IFCELECTRICGENERATOR,
	IFCELECTRICMOTOR,
	IFCELECTRICTIMECONTROL,
	IFCELEMENT,
	IFCELEMENTASSEMBLY,
	IFCELEMENTCOMPONENT,
	IFCENERGYCONVERSIONDEVICE,
	IFCENGINE,
	IFCEVAPORATIVECOOLER,
	IFCEVAPORATOR,
	IFCEXTERNALSPATIALELEMENT,
	IFCEXTERNALSPATIALSTRUCTUREELEMENT,
	IFCFACILITY,
	IFCFACILITYPART,
	IFCFAN,
	IFCFASTENER,
	IFCFEATUREELEMENT,
	IFCFEATUREELEMENTADDITION,
	IFCFEATUREELEMENTSUBTRACTION,
	IFCFILTER,
	IFCFIRESUPPRESSIONTERMINAL,
	IFCFLOWCONTROLLER,
	IFCFLOWFITTING,
	IFCFLOWINSTRUMENT,
	IFCFLOWMETER,
	IFCFLOWMOVINGDEVICE,
	IFCFLOWSEGMENT,
	IFCFLOWSTORAGEDEVICE,
	IFCFLOWTERMINAL,
	IFCFLOWTREATMENTDEVICE,
	IFCFOOTING,
	IFCFURNISHINGELEMENT,
	IFCFURNITURE,
	IFCGEOGRAPHICELEMENT,
	IFCGRID,
	IFCHEATEXCHANGER,
	IFCHUMIDIFIER,
	IFCINTERCEPTOR,
	IFCJUNCTIONBOX,
	IFCLAMP,
	IFCLIGHTFIXTURE,
	IFCLINEARPOSITIONINGELEMENT,
	IFCMECHANICALFASTENER,
	IFCMEDICALDEVICE,
	IFCMEMBER,
	IFCMEMBERSTANDARDCASE,
	IFCMOTORCONNECTION,
	IFCOPENINGELEMENT,
	IFCOPENINGSTANDARDCASE,
	IFCOUTLET,
	IFCPILE,
	IFCPIPEFITTING,
	IFCPIPESEGMENT,
	IFCPLATE,
	IFCPLATESTANDARDCASE,
	IFCPORT,
	IFCPOSITIONINGELEMENT,
	IFCPROJECTIONELEMENT,
	IFCPROTECTIVEDEVICE,
	IFCPROTECTIVEDEVICETRIPPINGUNIT,
	IFCPROXY,
	IFCPUMP,
	IFCRAILING,
	IFCRAMP,
	IFCRAMPFLIGHT,
	IFCREFERENT,
	IFCREINFORCINGBAR,
	IFCREINFORCINGELEMENT,
	IFCREINFORCINGMESH,
	IFCROOF,
	IFCSANITARYTERMINAL,
	IFCSENSOR,
	IFCSHADINGDEVICE,
	IFCSITE,
	IFCSLAB,
	IFCSLABELEMENTEDCASE,
	IFCSLABSTANDARDCASE,
	IFCSOLARDEVICE,
	IFCSPACE,
	IFCSPACEHEATER,
	IFCSPATIALELEMENT,
	IFCSPATIALSTRUCTUREELEMENT,
	IFCSPATIALZONE,
	IFCSTACKTERMINAL,
	IFCSTAIR,
	IFCSTAIRFLIGHT,
	IFCSTRUCTURALACTION,
	IFCSTRUCTURALACTIVITY,
	IFCSTRUCTURALCONNECTION,
	IFCSTRUCTURALCURVEACTION,
	IFCSTRUCTURALCURVECONNECTION,
	IFCSTRUCTURALCURVEMEMBER,
	IFCSTRUCTURALCURVEMEMBERVARYING,
	IFCSTRUCTURALCURVEREACTION,
	IFCSTRUCTURALITEM,
	IFCSTRUCTURALLINEARACTION,
	IFCSTRUCTURALMEMBER,
	IFCSTRUCTURALPLANARACTION,
	IFCSTRUCTURALPOINTACTION,
	IFCSTRUCTURALPOINTCONNECTION,
	IFCSTRUCTURALPOINTREACTION,
	IFCSTRUCTURALREACTION,
	IFCSTRUCTURALSURFACEACTION,
	IFCSTRUCTURALSURFACECONNECTION,
	IFCSTRUCTURALSURFACEMEMBER,
	IFCSTRUCTURALSURFACEMEMBERVARYING,
	IFCSTRUCTURALSURFACEREACTION,
	IFCSURFACEFEATURE,
	IFCSWITCHINGDEVICE,
	IFCSYSTEMFURNITUREELEMENT,
	IFCTANK,
	IFCTENDON,
	IFCTENDONANCHOR,
	IFCTENDONCONDUIT,
	IFCTRANSFORMER,
	IFCTRANSPORTELEMENT,
	IFCTUBEBUNDLE,
	IFCUNITARYCONTROLELEMENT,
	IFCUNITARYEQUIPMENT,
	IFCVALVE,
	IFCVIBRATIONDAMPER,
	IFCVIBRATIONISOLATOR,
	IFCVIRTUALELEMENT,
	IFCVOIDINGFEATURE,
	IFCWALL,
	IFCWALLELEMENTEDCASE,
	IFCWALLSTANDARDCASE,
	IFCWASTETERMINAL,
	IFCWINDOW,
	IFCWINDOWSTANDARDCASE
];