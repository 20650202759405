import { Component, OnInit, OnDestroy, ChangeDetectorRef, ɵConsole, ChangeDetectionStrategy, HostListener, Input } from '@angular/core';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { AppActionsService } from '../services/app-actions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FixSpecialChars } from '../pipes/fixSpecialChars.pipe';

const regexPatternMatchAttributes = /\(([^)]*)\)/g;  // Attention can be bad usage
const regexLineNum = /(#(.{0,12}\s*=\s*))/gm   ///(#(.+\s*=\s*))/gm  
const catchLine = /\s*=\s*.*/g
const regexLineEnd = /.*/gm
@Component({
  selector: 'app-ifc-analayzer',
  templateUrl: './ifc-analayzer.component.html',
  styleUrls: ['./ifc-analayzer.component.scss']
})
export class IfcAnalayzerComponent implements OnInit {

  @Input('ifcFile') ifcFile: File;
  subscriptions: Subscription[] = [];
  // huebackground = null;
  openedLabels = {};
  sortOrder = [];

  analysis: any = {}
  loading = true;
  size: any;
  sparseKeys: any;


  openedProperty: any;
  openedKeys: any;

  openCategories = {}
  filters = {};
  toAnonymize: { category: string, originalLineText: string, parameters: { index: number, value: number | string }[] }[] = [];
  linesToRemove: { category: string, linenum: number }[] = [];
  ifcLineDict = []
  fileName = "Filename";
  ifcOriginalText = '';

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private cdr: ChangeDetectorRef, private translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private fixSpecialChars: FixSpecialChars) {
    iconRegistry.addSvgIcon(
      'incognito',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/incognito.svg'));


  }

  async ngOnChanges() {
    this.loading = true;
    this.analysis = {}
    this.filters = {};
    this.toAnonymize = [];
    this.linesToRemove = [];

    this.preAnalyzeIfcFile();
    this.loading = false;
  }

  ngOnInit() {

  }

  toggleCategory(cat) {
    this.openCategories[cat] = !this.openCategories[cat];
  }

  toggleFilter(cat, filter) {

    if (this.filters[cat] == filter) {
      delete this.filters[cat];
    } else {
      this.filters[cat] = filter
    }



  }



  readIfcFile(): Promise<string> {
    this.ifcLineDict = []

    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
          let text = event.target.result.replace(/ +/g, '')  //remove
          text = this.fixSpecialChars.transform(text);
          const lines = text.match(new RegExp(/\#(.*?)\);/, "gm"))
          lines.forEach(line => {
            const splitted = line.split("=");
            const number = splitted[0].slice(1, splitted[0].length)
            this.ifcLineDict[number] = line;

          })


          this.ifcOriginalText = text;
          resolve(text);


        };
        this.size = this.ifcFile.size / 1024 / 1024
        fileReader.onerror = (ev: any) => {
          reject(ev)
        }

        fileReader.readAsText(this.ifcFile);
        return this.size
      } catch (err) {
        reject(err)
      }

    })


  }

  addToRemoveLines(category, line) {
    const linenum = this.getLineNumber(line);
    if (linenum) {
      this.linesToRemove.push({ category, linenum })
    } else {
      console.warn('cant find line num')
    }

  }
  addToAnonymizedData(category, line, parameters: { index: number, value: string | number }[]) {
    parameters.forEach(param => {
      if (typeof param.value === 'string') {
        param.value = "'" + param.value + "'";
      }
    })
    this.toAnonymize.push({
      category,
      originalLineText: line,
      parameters
    })
  }

  async preAnalyzeIfcFile() {
    const ltext = await this.readIfcFile();
    this.detectScheme(ltext);
    this.detectSite(ltext);

  }

  async analyzeIfcFile() {
    this.loading = true;

    const ltext = await this.readIfcFile();
    this.detectSite(ltext);//we do it again here as readifc file is overwriting the line dict
    this.detectinfo(ltext);
    this.detectGeometries(ltext)
    this.detectLayers(ltext);
    this.detectStoreys(ltext)
    this.detectobjects(ltext)
    this.detectOpenings(ltext)
    this.detectMaterials(ltext)
    this.detectSpaces(ltext)
    this.detectClassifications(ltext)
    this.detectProperties(ltext)
    this.detectQuantities(ltext)

    this.analysis.advancedAnalysis = true;


    this.loading = false;

  }


  findLines(text, str) {
    let regex = new RegExp(regexLineNum.source + str + '\\(' + regexLineEnd.source, "gm");

    const match = (text || '').match(regex) || [];
    return match;
  }

  count(text, name) {

    const matches = this.findLines(text, name);

    return ({
      count: matches.length,
      numOfCharacters: matches.reduce((a, c) => (a + c.length), 0),
      lines: matches
    })


  }

  removeStringQuotes(str) {
    return str ? (str).slice(1, str.length - 1) : null
  }

  getParamsOfLine(line): string[] {
    return line.toString().replace(/([^,\(\)\;]+)/, '').slice(1, -2).split(/[,]+(?![^\(]*\))+(?=(?:[^']*'[^']*')*[^']*$)/g)
  }

  getLineNumber(line) {
    const splitted = line.split("=");
    const number = splitted[0].slice(1, splitted[0].length)
    return number;
  }

  getLineParametersOfLineNumber(text, lineNumber) {

    let regex = new RegExp(lineNumber + catchLine.source, "gm");
    let lineSelected = text.match(regex)

    let attrs = this.getParamsOfLine(lineSelected);
    return attrs;

  }

  filterResult(infoGroup, dict: any[]) {
    const group = infoGroup;
    const resultObject = dict.reduce((result, [nom, valeurs]) => {
      if (!result[nom]) {
        result[nom] = { occurrences: 0, valeurs: [] };
      }
      result[nom].occurrences += 1;
      const sameValues = result[nom].valeurs
      result[nom].valeurs.push(valeurs);
      group.list = result
      return result;
    }, {});


    // console.log(resultObject)

  }

  ////////////////////        END FUNCTIONS       //////////////////////////

  detectGeometries(text) {
    this.analysis.geometriesInfo = {
      elements: {},
      debug: {}
    };
    let numberOfLines = text.match(/\n/g) || [];
    const numLines = numberOfLines.length + 1;


    let classIfc = [

      'IFCBUILDINGELEMENTPROXYTYPE',
      'IFCBUILDINGELEMENTPROXY',
      'IFCBUILDINGELEMENTPART',
      'IFCBUILDINGELEMENTCOMPONENT',
      'IFCBUILDINGELEMENT',
      'IFCPROXY',
      'IFCINDEXEDPOLYGONALFACE',
      'IFCPOLYGONALFACESET',
      'IFCFACETEDBREP',
      'IFCCARTESIANPOINT',
      'IFCWALL',


    ]

    let relatedInIfc = [   //list of used lines to calculate  weight
      'IFCFACEOUTERBOUND',
      'IFCPOLYLOOP',
      'IFCRELAGGREGATES',

    ]


    const totalFileChars = text.length;
    let totalGeometryChars = 0;






    for (let element of classIfc) {

      const { count, numOfCharacters } = this.count(text, element)

      if (count > 0) {
        totalGeometryChars += numOfCharacters;
        this.analysis.geometriesInfo.elements[element] = {
          count
        }
      }

    }


    for (let element of relatedInIfc) {

      const { count, numOfCharacters } = this.count(text, element)
      if (count > 0) {
        totalGeometryChars += numOfCharacters;
        this.analysis.geometriesInfo.debug[element] = {
          count
        }
      }

    }

    this.analysis.geometriesInfo.percentage = (totalGeometryChars / totalFileChars * 100).toFixed(1)
    this.analysis.geometriesInfo.lines = numLines;


  }

  detectScheme(text) {
    if (text.indexOf("FILE_SCHEMA(('IFC4'))") != -1) {
      this.analysis.scheme = "IFC4"
      // ifcType = 'IFC4'
    }
    else if (text.indexOf("FILE_SCHEMA(('IFC2X3'))") != -1) {
      this.analysis.scheme = "IFC2X3"
      // ifcType = 'IFC2X3'
    }
    else if (text.indexOf("FILE_SCHEMA(('IFC4X3'))") != -1) {
      this.analysis.scheme = "IFC4X3"
      // ifcType = 'IFC4X3'
    }
  }


  detectinfo(text) {

    this.analysis.globalInfo = {
      elements: [],
      inHeaders: [],
      anonymousValue: 'Anonymous'
    };





    if (text.indexOf("FILE_NAME") != -1) {
      let fileName = text.match(/(?<=FILE_NAME)(.*)(?=\);)/) || [];

      let names = fileName[0].toString().split("\,")

      const cleannames = names.map(item => item.replace(/['"()]/g, ''));


      let name = cleannames[0].toString()
      this.analysis.globalInfo.fileName = name;
      this.fileName = name;
      for (let i = 1; i < cleannames.length; i++) {
        this.analysis.globalInfo.inHeaders[i] = cleannames[i].toString()


      }

    }


    const regUnit = /#.{0,12}=.*.LENGTHUNIT.*/gm
    const unit = text.match(regUnit)
    let meter = true

    for (let i in unit) {
      if (unit[i].toString().match('.MILLI.')) {
        this.analysis.globalInfo.unit = "MILLIMETRE"
        meter = false
      }
    }
    if (meter === true) {
      this.analysis.globalInfo.unit = "METRE"
    }


    let classIfc = [
      'IFCPROJECT',
      'IFCOWNERHISTORY',
      'IFCORGANIZATION',
      'IfcPostalAddress',
      'IfcAddress',
      'IfcTelecomAddress',
      'IFCPERSON']



    for (let element of classIfc) {




      let ifcLines = this.findLines(text, element)
      const anonymousValue = 'Anonymous';

      for (let line of ifcLines) {

        const params = this.getParamsOfLine(line)



        let anonymizedAllStringParams = params.map((param, i) => {
          if (param[0] === "'" && param.slice(-1) === "'") {
            return { index: i, value: anonymousValue }
          } else {
            return null;
          }
        }).filter(p => !!p);



        let anonymizeParams = [];
        switch (element) {
          case 'IFCPROJECT':
            anonymizeParams = anonymizedAllStringParams.filter(p => (p.index !== 0))
            break;

          case 'IFCOWNERHISTORY':
            anonymizeParams = anonymizedAllStringParams;

            break;

          case 'IFCORGANIZATION':
            anonymizeParams = anonymizedAllStringParams;
            break;

          case 'IfcPostalAddress':
            anonymizeParams = anonymizedAllStringParams;
            break;

          case 'IfcAddress':
            anonymizeParams = anonymizedAllStringParams;
            break;

          case 'IfcTelecomAddress':
            anonymizeParams = anonymizedAllStringParams;
            break;

          case 'IFCPERSON': ;
            anonymizeParams = anonymizedAllStringParams
            break;

          default:
            break;
        }

        if (anonymizeParams.length > 0) {
          this.analysis.globalInfo.elements.push({
            name: element,
            params: anonymizeParams.map(p => this.removeStringQuotes(params[p.index]))
          }
          )

          this.addToAnonymizedData('info', line, anonymizeParams)
        }


      }

    }




  }

  detectSite(text) {
    let changeOrigin = false  // RESET ORIGIN OPTION (SAVE OLD VALUES?)
    this.analysis.siteInfo = {};

    if (text.indexOf("IFCSITE") != -1) {  //@mg to get ifc site. Surely not ok with multiste  // Whatif survey point ?
      let ifcSiteLine = text.match(/(#(.{0,12}\s*=\s*))IFCSITE.*/gm)
      // #354= IFCSITE('20FpTZCqJy2vhVJYtjuIce',#12,'Site',$,$,#85,#349,$,.ELEMENT.,(-20,-53,0,0),(55,30,0,0),0.,$,$);
      let tempSite = ifcSiteLine[0].toString()
      let ifcSite = tempSite.replace(/([^,\(\)\;]+)/, '').match(/([^,\(\)\;]+)/g)   // 
      let lonLatText = ""
      let lonLat = lonLatText.concat("LON:", ifcSite[9], ",", ifcSite[10], ",", ifcSite[11], "  -  LAT:", ifcSite[13], ",", ifcSite[14], ",", ifcSite[15])

      this.analysis.siteInfo.longitude = Number(ifcSite[9]) + Number(ifcSite[10] / 60) + Number(ifcSite[11] / 3600);
      this.analysis.siteInfo.latitude = Number(ifcSite[13]) + Number(ifcSite[14] / 60) + Number(ifcSite[15] / 3600);
      let ifcGeoLine = text.match(/(#(.{0,12}\s*=\s*))IFCGEOGRAPHICELEMENTTYPE.*/gm)   // For IFC4 only
      if (ifcGeoLine) { console.log('%c  IFCGEOGRAPHICELEMENTTYPE  ', 'background:#125a64', ifcGeoLine) }
      let objectPlacementSite = ifcSite[5].toString()
      let regex = new RegExp(objectPlacementSite + catchLine.source, "g");
      let objectPlacementLine = text.match(regex)
      let objectPlacement = objectPlacementLine.toString().match(/#[0-9]*/gm)
      let AxisToPlacementLine = objectPlacement[1].toString()
      let regex2 = new RegExp(AxisToPlacementLine + catchLine.source, "g");
      let axisToPlacement = text.match(regex2)
      let positionLine = axisToPlacement.toString()
      let regex3 = positionLine.match(/#[0-9]*/gm)
      let position = regex3[1].toString()
      let regex4 = new RegExp(position + regexLineEnd.source, "gm");
      let again = text.match(regex4)
      let originSiteFindLine = again[0].toString().match(/[-+]?\d*\.?\d+/gm)
      let originRegex = originSiteFindLine[0].toString()
      let regex5 = new RegExp("#" + originRegex + "\=" + regexLineEnd.source, "gm");
      let preoriginSite = text.match(regex5)
      let originSite = preoriginSite[0].toString().match(/[-+]?\d*\.?\d+/gm)
      let x = originSite[1]
      let y = originSite[2]
      let z = originSite[3] || 0

      this.analysis.siteInfo.origin = { x, y, z }

      const line = preoriginSite[0];
      let textWithNewOrigin = line.replace(line.toString(), `${position}=IFCCARTESIANPOINT((0.,0.,0.));`)
      const splitted = line.split("=");
      const number = splitted[0].slice(1, splitted[0].length)
      this.ifcLineDict[number] = textWithNewOrigin;
      if (changeOrigin) { textWithNewOrigin }

    }
  }


  detectLayers(text) {

    this.analysis.layerInfo = {
      layers: []
    }


    let ifcLines = text.match(/(#(.{0,12}\s*=\s*))IFCPRESENTATIONLAYERASSIGNMENT.*/gm)


    for (let i in ifcLines) {
      let tempElem = ifcLines[i].toString()
      const params = this.getParamsOfLine(tempElem);

      const name = params[0];

      let elements = params[2];

      let elementsCount = elements.split(',').length

      this.analysis.layerInfo.layers[i] = {
        name, count: elementsCount, anonymousValue: 'LAYER_' + i
      }

      this.addToAnonymizedData('layers', tempElem, [{ index: 0, value: this.analysis.layerInfo.layers[i].anonymousValue }])



    }

  }

  detectStoreys(text) {

    this.analysis.storeyInfo = {
      storeys: []
    }


    let ifcLines = text.match(/(#(.{0,12}\s*=\s*))IFCBUILDINGSTOREY.*/gm)


    for (let i in ifcLines) {
      let tempElem = ifcLines[i].toString()
      const matches = [...tempElem.matchAll(regexPatternMatchAttributes)].map(match => match[1]);
      let name = this.removeStringQuotes(matches[0].split(',')[2])
      let alt = matches[0].split(',')[9]
      this.analysis.storeyInfo.storeys[i] = {
        name, altitude: alt, anonymousValue: 'LEVEL_' + i
      }

      this.addToAnonymizedData('storeys', tempElem, [{ index: 2, value: this.analysis.storeyInfo.storeys[i].anonymousValue }])



    }

  }



  detectobjects(text) { ////////// objects IMPROVE WITH THICKNESS
    this.analysis.objectInfo = {
      objects: {},
    };




    let classIfc = [
      'IFCWALL',
      'IFCWALLSTANDARDCASE',
      'IFCCURTAINWALL',
      'IFCSLAB',
      'IFCROOF',
      'IFCPROXY',
      'IFCCOLUMN',
      'IFCBEAM',
      'IFCSTAIRS',
      'IFCBUILDINGELEMENTPROXY',
      'IFCFURNISHINGELEMENT',
      // 'IFCDOORSTANDARDCASE',
      // 'IFCOPENINGSTANDARDCASE',
      // 'IFCOPENINGELEMENT',
      // 'IFCDOOR',
      // 'IFCWINDOW',
      // 'IFCWINDOWSTANDARDCASE'
    ]
    for (let element of classIfc) {
      const count = this.count(text, element).count

      if (count > 0) {
        this.analysis.objectInfo.objects[element] = { count: count, names: {} }


        let ifcLines = this.findLines(text, element)


        let namingCounter = 0;
        for (let line of ifcLines) {


          const params = this.getParamsOfLine(line)

          const name = this.removeStringQuotes(params[2]);


          if (!this.analysis.objectInfo.objects[element].names[name]) {
            this.analysis.objectInfo.objects[element].names[name] = {
              count: 1,
              anonymousValue: element + '_' + namingCounter
            }
            namingCounter++;
          } else {
            this.analysis.objectInfo.objects[element].names[name].count++;
          }

          this.addToAnonymizedData('objects', line,
            [{
              index: 2,
              value: this.analysis.objectInfo.objects[element].names[name].anonymousValue
            }])



        }





      }


    }


  }

  detectOpenings(text) {

    this.analysis.openingInfo = {
      openingTypes: {},
    };


    let classIfc = [
      'IFCDOORSTANDARDCASE',
      'IFCOPENINGSTANDARDCASE',
      'IFCOPENINGELEMENT',
      'IFCDOOR',
      'IFCWINDOW',
      'IFCWINDOWSTANDARDCASE'
    ]
    for (let element of classIfc) {
      const count = this.count(text, element).count

      if (count > 0) {


        const openings = {

        }

        let ifcLines = this.findLines(text, element)
        let namingCounter = 0;
        for (let line of ifcLines) {

          const params = this.getParamsOfLine(line)

          const name = this.removeStringQuotes(params[2]);
          const width = Number(params[8]);
          const height = Number(params[9]);

          const key = [name, width, height].join('|%|')
          if (openings)

            if (!openings[key]) {
              openings[key] = { count: 1, anonymousValue: element + '_' + namingCounter };
              namingCounter++;
            } else {
              openings[key].count++;
            }

          this.addToAnonymizedData('objects', line, [{
            index: 2,
            value: openings[key].anonymousValue
          }])


        }
        this.analysis.openingInfo.openingTypes[element] = {
          count: count,
          openings: Object.keys(openings).map(key => {
            const [name, width, height] = key.split('|%|');
            return {
              count: openings[key].count,
              name,
              width,
              height,
              anonymousValue: openings[key].anonymousValue
            }
          })
        }





      }


    }

  }

  detectMaterials(text) {  ////////// MATERIALS
    this.analysis.materialInfo = {
      materials: []
    };


    let classIfc = [

      'IFCMATERIAL',
      'IFCSURFACESTYLE',
      'IFCMATERIALLAYERSET'
    ]

    let related = [

      // 'IFCCOLOURRGB',
      // 'IFCRELASSOCIATESMATERIAL',
      // 'IFCINDEXEDCOLOURMAP',
      // 'IFCINDEXEDTEXTUREMAP',
      // 'IFCCOLOURRGBLIST',
      // 'IFCPREDEFINEDCOLOUR',
      // 'IFCMATERIALDEFINITIONREPRESENTATION',
      // 'IFCMAPPEDITEM',
      // 'IFCMATERIALPROPERTIES',
      // 'IFCMATERIALLIST',
      // 'IFCMATERIALLAYERSETUSAGE',
      // 'IFCMATERIALLAYERSET ',
      // 'IFCMATERIALLAYER',
      // 'IFCMATERIALCLASSIFICATIONRELATIONSHIP ',
    ]

    let i = 0;


    for (let element of classIfc) {
      let ifcLines = this.findLines(text, element)

      for (let line of ifcLines) {


        const params = this.getParamsOfLine(line);

        const name = this.removeStringQuotes(params[0]);
        const anonymousValue = 'MATERIAL_' + i;
        if (element !== 'IFCMATERIALLAYERSET') {
          this.analysis.materialInfo.materials.push({ name, anonymousValue: anonymousValue })
          this.addToAnonymizedData('materials', line, [{ index: 0, value: anonymousValue }])
          i++
        } else {
          this.addToAnonymizedData('materials', line, [{ index: 1, value: 'IFCMATERIALLAYERSET' }])
        }





        console.log(anonymousValue)


      }



    }





  }

  detectSpaces(text) { ////////// ZONES SPACES LOOK FOR AREAS TO DO

    this.analysis.spaceInfo = {
      spaces: []
    }
    let classIfc = ['IFCSPACE']

    let i = 0;
    for (let element of classIfc) {


      let ifcLine = this.findLines(text, element)

      for (let line of ifcLine) {
        const params = this.getParamsOfLine(line);

        this.analysis.spaceInfo.spaces.push(
          {
            longName: this.removeStringQuotes(params[7]), shortName: this.removeStringQuotes(params[2]), anonymousValue: { shortName: String(i), longName: 'SPACE_' + i }
          }
        )

        this.addToAnonymizedData('spaces', line, [
          { index: 2, value: this.analysis.spaceInfo.spaces[i].anonymousValue.shortName },
          { index: 7, value: this.analysis.spaceInfo.spaces[i].anonymousValue.longName },]);
        i++

      }

    }


  }

  detectClassifications(text) { ////////// CLASSIFICATIONS  go on each type to improve the selection


    this.analysis.classificationInfo = {
      classifications: []
    }

    let classIfc = [
      'IFCCLASSIFICATION',
    ]

    let relObj = [
      'IFCCONSTRAINTCLASSIFICATIONRELATIONSHIP',
      'IFCCLASSIFICATIONNOTATIONFACET',
      'IFCCLASSIFICATIONNOTATION',
      'IFCCLASSIFICATIONITEM',
      'IFCCLASSIFICATIONREFERENCE',
      'IFCRELASSOCIATESCLASSIFICATION'
    ]

    const totalFileChars = text.length;
    let totalClassificationsChars = 0;



    for (let element of classIfc) {
      const { count, numOfCharacters, lines } = this.count(text, element);

      if (count > 0) {
        totalClassificationsChars += numOfCharacters;
        for (let line of lines) {
          const params = this.getParamsOfLine(line);

          this.analysis.classificationInfo.classifications.push(
            {
              name: this.removeStringQuotes(params[3]),
              version: this.removeStringQuotes(params[1]),
              ref: this.removeStringQuotes(params[0])
            }
          )
          this.addToRemoveLines('classifications', line);
        }

      }

    }

    for (let element of relObj) {

      const { count, numOfCharacters, lines } = this.count(text, element)
      if (count > 0) {
        totalClassificationsChars += numOfCharacters;
        for (let line of lines) {
          this.addToRemoveLines('classifications', line);
        }
      }


    }


    this.analysis.classificationInfo.percentage = (totalClassificationsChars / totalFileChars * 100).toFixed(1)



  }


  detectProperties(text) {
    this.analysis.propertyInfo = {
      elements: {}
    }


    let classIfc = ['IFCPROPERTYTEMPLATE',
      'IFCPROPERTYSETTEMPLATE',
      'IFCPROPERTYTEMPLATEDEFINITION',
      'IFCPROPERTYABSTRACTION',
      'IFCPROPERTYSET']

    const totalFileChars = text.length;
    let totalPropertiesChars = 0;



    for (let element of classIfc) {

      const { count, numOfCharacters, lines } = this.count(text, element)

      if (count > 0) {
        totalPropertiesChars += numOfCharacters;
        this.analysis.propertyInfo.elements[element] = {
          count,

        }

        lines.forEach(line => {
          this.addToRemoveLines('properties', line)
        })
      }

    }

    this.analysis.propertyInfo.percentage = (totalPropertiesChars / totalFileChars * 100).toFixed(1)
  }


  detectQuantities(text) {
    this.analysis.quantityInfo = {
      elements: {}
    }

    let classIfc = [
      'IFCQUANTITYNUMBER',
      'IFCQUANTITYSET',
      'IFCELEMENTQUANTITY',
      'IFCPHYSICALCOMPLEXQUANTITY',
      'IFCQUANTITYWEIGHT',
      'IFCQUANTITYNUMBER',
      'IFCQUANTITYTIME',
      'IFCQUANTITYLENGTH',
      'IFCQUANTITYCOUNT',
      'IFCQUANTITYAREA',
      'IFCPHYSICALSIMPLEQUANTITY',
      'IFCPHYSICALQUANTITY']


    const totalFileChars = text.length;
    let totalQuantityChars = 0;



    for (let element of classIfc) {

      const { count, numOfCharacters, lines } = this.count(text, element)

      if (count > 0) {
        totalQuantityChars += numOfCharacters;
        this.analysis.quantityInfo.elements[element] = {
          count,

        }

        lines.forEach(l => {
          this.addToRemoveLines('quantities', l)
        })
      }

    }

    this.analysis.quantityInfo.percentage = (totalQuantityChars / totalFileChars * 100).toFixed(1)


  }


  anonymizeTest(text) {
    function createUUID() {   //@mg - note : the UUID should be the same for same name.
      // Generate four random hex digits
      function randomHex() {
        return Math.floor(Math.random() * 0x10000).toString(16).padStart(4, '0');
      }
      // Generate a version 4 UUID
      return '\'' + randomHex() + randomHex() + '-' + randomHex() + '-4' + randomHex().substr(0, 3) + '-' + randomHex() + '-' + randomHex() + randomHex() + randomHex() + '\'';
    }

    let headers = /^(?!\s*[#])\S.*/gm  // new reg(Ex) is better
    let noHeaders = text.replace(headers, 'TEMPDATAHEADER;')
    let regex = /\'(?!Component|SweptSolid|IsExternal|LoadBearing|Reference|Clipping|Axis|Curve2D|BREP|Body|Model)\b[^,;]+\b\'/gmi
    let anonymousText = text.replace(regex, createUUID)


    // STOREYS  REPLACEMENT OF NAMES  :::::::::::::::::
    //#104 = IFCBUILDINGSTOREY('0K89Qzmib3yBFIA89i4HLf',#20, 'SS2', $, 'Niveau:Niveau sans titre',#103, $, 'SS2',.ELEMENT., -584.9339965540496);
    let storeyAnon = anonymousText.match(/(#(.{0,12}\s*=\s*))IFCBUILDINGSTOREY.*/gm)
    let regexLine = /([^,\(]+)/gm
    let regexElem = /[0-9|\-\.]+/gm
    for (let i in storeyAnon) {
      let variables2 = storeyAnon[i].match(regexLine)  //Extract

      let alt = variables2[10].match(regexElem)
      // console.log(regexLine, regexElem, variables2, alt)
      let niveaux = ('NIVEAU ' + alt).toString()
      console.log('%c STOREYS ', 'background:#002299', niveaux, `' m'`)

      // text = anonymousText.replace('TEMPDATAHEADER;',headers)

      //////////@mg DOWNLOAD NEW FILE 
      if (true) {
        // console.log(anonymousText)
        const uint8array = new TextEncoder().encode();
        const blob = new Blob([anonymousText]);
        const file = new File([blob], "modified.ifc");
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.innerText = "Download";
        link.download = "BIMSHOW_ExportFile_Anonymous.ifc";
        link.setAttribute("href", url);
        link.click()
        window.URL.revokeObjectURL(url);

      }



    }

  }


  allOriginPointInOne(text) {//@mg Try to lower the size experiment 
    let cartesianpointOnOrigin = text.match(/(#(.{0,12}\s*=\s*))IFCCARTESIANPOINT\(\(0\.,0\.,0\..*/gm)
    let pointRef = cartesianpointOnOrigin[0].match(/#[0-9]*/gm)[0].toString()
    let newText = text
    let pointArray = []
    for (let i = 0; i < cartesianpointOnOrigin.length; i++) {
      // console.log(cartesianpointOnOrigin[i])

      let regex3 = cartesianpointOnOrigin[i].match(/#[0-9]*/gm)
      let position = regex3[0].toString()
      // console.log(regex3)
      const regUnit = /(#(.{0,12}\s*=\s*))/g
      let at = newText.replace(position, pointRef)
      pointArray.push(position)
    }

  }

  calcSize() {
    console.log('%c IFC SIZE', 'background:purple', this.ifcFile.size) /// Make it functionnal and dynamic
  }

  anonymizeAndFilterFile(): File {

    this.toAnonymize.forEach(
      a => {
        if (this.filters[a.category] === 'anonymize') {

          const params = this.getParamsOfLine(a.originalLineText);
          for (let param of a.parameters) {
            //changing to anonymoized params (not all params)
            params[param.index] = String(param.value);
          }


          //generate new line with anonymized params

          const newLine = a.originalLineText.split('(')[0] + "(" + params.join(',') + ');';

          const splitted = newLine.split("=");
          const number = splitted[0].slice(1, splitted[0].length)

          //replaceLine now:
          this.ifcLineDict[number] = newLine;

        }

      })



    this.linesToRemove.forEach(lineToRemove => {
      if (this.filters[lineToRemove.category] === 'delete') {
        delete this.ifcLineDict[lineToRemove.linenum];
      }

    })






    let linesText = 'DATA;\n';
    this.ifcLineDict.forEach(line => {
      linesText += line + '\n'

    })
    linesText += 'ENDSEC;\n'


    let headersAndEnding = this.ifcOriginalText.replace(new RegExp(/DATA;\s([\s\S]*?)s*ENDSEC;/, "gm"), 'ENTERLINESDATA')


    if (this.analysis.globalInfo) {
      if (this.filters['info']) {
        for (let headerValue of this.analysis.globalInfo.inHeaders) {
          headersAndEnding = headersAndEnding.replace(`'${headerValue}'`, `'${this.analysis.globalInfo.anonymousValue}'`);
        }
        headersAndEnding = headersAndEnding.replace(`'${this.analysis.globalInfo.fileName}'`, `'${this.analysis.globalInfo.anonymousValue}'`);
      } else {
        headersAndEnding = headersAndEnding.replace(`'${this.analysis.globalInfo.fileName}'`, `'${this.fileName}'`);
      }
    }


    const splitted = headersAndEnding.split("ENTERLINESDATA");
    const text = splitted[0] + linesText + splitted[1];


    const blob = new Blob([text]);
    const file = new File([blob], "modified.ifc");


    // //Download for debug:
    // const url = URL.createObjectURL(file);
    // const link = document.createElement("a");
    // link.innerText = "Download";
    // link.download = "BIMSHOW_ExportFile_Anonymous.ifc";
    // link.setAttribute("href", url);
    // link.click()
    // window.URL.revokeObjectURL(url);



    return file;




  }



}





