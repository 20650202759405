import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'fixSpecialChars'
})

export class FixSpecialChars implements PipeTransform {

    constructor() {

    }
    transform(text: string): string {



        let before = /\\X2\\/gm; 
        let after = /\\X0\\/gm;

  
        let nobreakspace = /00A0/gm;
        let invertedexclamation = /00A1/gm;
        let centsign = /00A2/gm;
        let poundsign = /00A3/gm;
        let currencysign = /00A4/gm;
        let yensign = /00A5/gm;
        let brokenbar = /00A6/gm;
        let sectionsign = /00A7/gm;
        let diaeresis = /00A8/gm;
        let copyrightsign = /00A9/gm;
        let femordinalindicator = /00AA/gm;
        let leftdoubleangle = /00AB/gm;
        let notsign = /00AC/gm;
        let softhyphen = /00AD/gm;
        let registered = /00AE/gm;
        let macron = /00AF/gm;
        let degree = /00B0/gm;
        let plusminus = /00B1/gm;
        let superscripttwo = /00B2/gm;
        let superscriptthree = /00B3/gm;
        let acuteaccent = /00B4/gm;
        let micro = /00B5/gm;
        let pilcrow = /00B6/gm;
        let middledot = /00B7/gm;
        let cedilla = /00B8/gm;
        let superscriptone = /00B9/gm;
        let ordinalindicator = /00BA/gm;
        let rightdoubleangle = /00BB/gm;
        let onequarter = /00BC/gm;
        let onehalf = /00BD/gm;
        let threequarters = /00BE/gm;
        let invquestionmark = /00BF/gm;
        let capagrave = /00C0/gm;
        let capaacute = /00C1/gm;
        let capacircumflex = /00C2/gm;
        let capatilde = /00C3/gm;
        let capadiaeresis = /00C4/gm;
        let caparingabove = /00C5/gm;
        let capae = /00C6/gm;
        let capccedilla = /00C7/gm;
        let capegrave = /00C8/gm;
        let capeacute = /00C9/gm;
        let capecircumflex = /00CA/gm;
        let capediaeresis = /00CB/gm;
        let capigrave = /00CC/gm;
        let capiacute = /00CD/gm;
        let capicircumflex = /00CE/gm;
        let capidiaeresis = /00CF/gm;
        let capeth = /00D0/gm;
        let capntilde = /00D1/gm;
        let capograve = /00D2/gm;
        let capoacute = /00D3/gm;
        let capocircumflex = /00D4/gm;
        let capotilde = /00D5/gm;
        let capodiaeresis = /00D6/gm;
        let multiplication = /00D7/gm;
        let capostroke = /00D8/gm;
        let capugrave = /00D9/gm;
        let capuacute = /00DA/gm;
        let capucircumflex = /00DB/gm;
        let capudiaeresis = /00DC/gm;
        let capyacute = /00DD/gm;
        let capthorn = /00DE/gm;
        let sharps = /00DF/gm;
        let agrave = /00E0/gm;
        let aacute = /00E1/gm;
        let acircumflex = /00E2/gm;
        let atilde = /00E3/gm;
        let adiaeresis = /00E4/gm;
        let aringabove = /00E5/gm;
        let ae = /00E6/gm;
        let ccedilla = /00E7/gm;
        let egrave = /00E8/gm;
        let eacute = /00E9/gm;
        let ecircumflex = /00EA/gm;
        let ediaeresis = /00EB/gm;
        let igrave = /00EC/gm;
        let iacute = /00ED/gm;
        let icircumflex = /00EE/gm;
        let idiaeresis = /00EF/gm;
        let eth = /00F0/gm;
        let ntilde = /00F1/gm;
        let ograve = /00F2/gm;
        let oacute = /00F3/gm;
        let ocircumflex = /00F4/gm;
        let otilde = /00F5/gm;
        let odiaeresis = /00F6/gm;
        let division = /00F7/gm;
        let ostroke = /00F8/gm;
        let ugrave = /00F9/gm;
        let uacute = /00FA/gm;
        let ucircumflex = /00FB/gm;
        let udiaeresis = /00FC/gm;
        let yacute = /00FD/gm;
        let thorn = /00FE/gm;
        let ydiaeresis = /00FF/gm;

      //  let agrave = /\\X2\\00E0\\X0\\/gm; // à  #1= IFCPERSON($,'Non d\X2\00E9\X0\fini',$,$,$,$,$,$);   \X\E9


        let nobreakspace2 = /\\X\\A0/gm;
        let invertedexclamation2 = /\\X\\A1/gm;
        let centsign2 = /\\X\\A2/gm;
        let poundsign2 = /\\X\\A3/gm;
        let currencysign2 = /\\X\\A4/gm;
        let yensign2 = /\\X\\A5/gm;
        let brokenbar2 = /\\X\\A6/gm;
        let sectionsign2 = /\\X\\A7/gm;
        let diaeresis2 = /\\X\\A8/gm;
        let copyrightsign2 = /\\X\\A9/gm;
        let femordinalindicator2 = /\\X\\AA/gm;
        let leftdoubleangle2 = /\\X\\AB/gm;
        let notsign2 = /\\X\\AC/gm;
        let softhyphen2 = /\\X\\AD/gm;
        let registered2 = /\\X\\AE/gm;
        let macron2 = /\\X\\AF/gm;
        let degree2 = /\\X\\B0/gm;
        let plusminus2 = /\\X\\B1/gm;
        let superscripttwo2 = /\\X\\B2/gm;
        let superscriptthree2 = /\\X\\B3/gm;
        let acuteaccent2 = /\\X\\B4/gm;
        let micro2 = /\\X\\B5/gm;
        let pilcrow2 = /\\X\\B6/gm;
        let middledot2 = /\\X\\B7/gm;
        let cedilla2 = /\\X\\B8/gm;
        let superscriptone2 = /\\X\\B9/gm;
        let ordinalindicator2 = /\\X\\BA/gm;
        let rightdoubleangle2 = /\\X\\BB/gm;
        let onequarter2 = /\\X\\BC/gm;
        let onehalf2 = /\\X\\BD/gm;
        let threequarters2 = /\\X\\BE/gm;
        let invquestionmark2 = /\\X\\BF/gm;
        let capagrave2 = /\\X\\C0/gm;
        let capaacute2 = /\\X\\C1/gm;
        let capacircumflex2 = /\\X\\C2/gm;
        let capatilde2 = /\\X\\C3/gm;
        let capadiaeresis2 = /\\X\\C4/gm;
        let caparingabove2 = /\\X\\C5/gm;
        let capae2 = /\\X\\C6/gm;
        let capccedilla2 = /\\X\\C7/gm;
        let capegrave2 = /\\X\\C8/gm;
        let capeacute2 = /\\X\\C9/gm;
        let capecircumflex2 = /\\X\\CA/gm;
        let capediaeresis2 = /\\X\\CB/gm;
        let capigrave2 = /\\X\\CC/gm;
        let capiacute2 = /\\X\\CD/gm;
        let capicircumflex2 = /\\X\\CE/gm;
        let capidiaeresis2 = /\\X\\CF/gm;
        let capeth2 = /\\X\\D0/gm;
        let capntilde2 = /\\X\\D1/gm;
        let capograve2 = /\\X\\D2/gm;
        let capoacute2 = /\\X\\D3/gm;
        let capocircumflex2 = /\\X\\D4/gm;
        let capotilde2 = /\\X\\D5/gm;
        let capodiaeresis2 = /\\X\\D6/gm;
        let multiplication2 = /\\X\\D7/gm;
        let capostroke2 = /\\X\\D8/gm;
        let capugrave2 = /\\X\\D9/gm;
        let capuacute2 = /\\X\\DA/gm;
        let capucircumflex2 = /\\X\\DB/gm;
        let capudiaeresis2 = /\\X\\DC/gm;
        let capyacute2 = /\\X\\DD/gm;
        let capthorn2 = /\\X\\DE/gm;
        let sharps2 = /\\X\\DF/gm;
        let agrave2 = /\\X\\E0/gm;
        let aacute2 = /\\X\\E1/gm;
        let acircumflex2 = /\\X\\E2/gm;
        let atilde2 = /\\X\\E3/gm;
        let adiaeresis2 = /\\X\\E4/gm;
        let aringabove2 = /\\X\\E5/gm;
        let ae2 = /\\X\\E6/gm;
        let ccedilla2 = /\\X\\E7/gm;
        let egrave2 = /\\X\\E8/gm;
        let eacute2 = /\\X\\E9/gm;
        let ecircumflex2 = /\\X\\EA/gm;
        let ediaeresis2 = /\\X\\EB/gm;
        let igrave2 = /\\X\\EC/gm;
        let iacute2 = /\\X\\ED/gm;
        let icircumflex2 = /\\X\\EE/gm;
        let idiaeresis2 = /\\X\\EF/gm;
        let eth2 = /\\X\\F0/gm;
        let ntilde2 = /\\X\\F1/gm;
        let ograve2 = /\\X\\F2/gm;
        let oacute2 = /\\X\\F3/gm;
        let ocircumflex2 = /\\X\\F4/gm;
        let otilde2 = /\\X\\F5/gm;
        let odiaeresis2 = /\\X\\F6/gm;
        let division2 = /\\X\\F7/gm;
        let ostroke2 = /\\X\\F8/gm;
        let ugrave2 = /\\X\\F9/gm;
        let uacute2 = /\\X\\FA/gm;
        let ucircumflex2 = /\\X\\FB/gm;
        let udiaeresis2 = /\\X\\FC/gm;
        let yacute2 = /\\X\\FD/gm;
        let thorn2 = /\\X\\FE/gm;
       let ydiaeresis2 = /\\X\\FF/gm;

        let str = null
        if (text) {
            str = text
                .replace(before, "")
                .replace(after, "")
                .replace(nobreakspace, "_")
                .replace(invertedexclamation, "¡")
                .replace(centsign, "¢")
                .replace(poundsign, "£")
                .replace(currencysign, "¤")
                .replace(yensign, "¥")
                .replace(brokenbar, "¦")
                .replace(sectionsign, "§")
                .replace(diaeresis, "¨")
                .replace(copyrightsign, "©")
                .replace(femordinalindicator, "ª")
                .replace(leftdoubleangle, "«")
                .replace(notsign, "¬")
                .replace(softhyphen, "­")
                .replace(registered, "®")
                .replace(macron, "¯")
                .replace(degree, "°")
                .replace(plusminus, "±")
                .replace(superscripttwo, "²")
                .replace(superscriptthree, "³")
                .replace(acuteaccent, "´")
                .replace(micro, "µ")
                .replace(pilcrow, "¶")
                .replace(middledot, "·")
                .replace(cedilla, "¸")
                .replace(superscriptone, "¹")
                .replace(ordinalindicator, "º")
                .replace(rightdoubleangle, "»")
                .replace(onequarter, "¼")
                .replace(onehalf, "½")
                .replace(threequarters, "¾")
                .replace(invquestionmark, "¿")
                .replace(capagrave, "À")
                .replace(capaacute, "Á")
                .replace(capacircumflex, "Â")
                .replace(capatilde, "Ã")
                .replace(capadiaeresis, "Ä")
                .replace(caparingabove, "Å")
                .replace(capae, "Æ")
                .replace(capccedilla, "Ç")
                .replace(capegrave, "È")
                .replace(capeacute, "É")
                .replace(capecircumflex, "Ê")
                .replace(capediaeresis, "Ë")
                .replace(capigrave, "Ì")
                .replace(capiacute, "Í")
                .replace(capicircumflex, "Î")
                .replace(capidiaeresis, "Ï")
                .replace(capeth, "Ð")
                .replace(capntilde, "Ñ")
                .replace(capograve, "Ò")
                .replace(capoacute, "Ó")
                .replace(capocircumflex, "Ô")
                .replace(capotilde, "Õ")
                .replace(capodiaeresis, "Ö")
                .replace(multiplication, "×")
                .replace(capostroke, "Ø")
                .replace(capugrave, "Ù")
                .replace(capuacute, "Ú")
                .replace(capucircumflex, "Û")
                .replace(capudiaeresis, "Ü")
                .replace(capyacute, "Ý")
                .replace(capthorn, "Þ")
                .replace(sharps, "ß")
                .replace(agrave, "à")
                .replace(aacute, "á")
                .replace(acircumflex, "â")
                .replace(atilde, "ã")
                .replace(adiaeresis, "ä")
                .replace(aringabove, "å")
                .replace(ae, "æ")
                .replace(ccedilla, "ç")
                .replace(egrave, "è")
                .replace(eacute, "é")
                .replace(ecircumflex, "ê")
                .replace(ediaeresis, "ë")
                .replace(igrave, "ì")
                .replace(iacute, "í")
                .replace(icircumflex, "î")
                .replace(idiaeresis, "ï")
                .replace(eth, "ð")
                .replace(ntilde, "ñ")
                .replace(ograve, "ò")
                .replace(oacute, "ó")
                .replace(ocircumflex, "ô")
                .replace(otilde, "õ")
                .replace(odiaeresis, "ö")
                .replace(division, "÷")
                .replace(ostroke, "ø")
                .replace(ugrave, "ù")
                .replace(uacute, "ú")
                .replace(ucircumflex, "û")
                .replace(udiaeresis, "ü")
                .replace(yacute, "ý")
                .replace(thorn, "þ")
                .replace(ydiaeresis, "ÿ")
                .replace(nobreakspace2, "_")
                .replace(invertedexclamation2, "¡")
                .replace(centsign2, "¢")
                .replace(poundsign2, "£")
                .replace(currencysign2, "¤")
                .replace(yensign2, "¥")
                .replace(brokenbar2, "¦")
                .replace(sectionsign2, "§")
                .replace(diaeresis2, "¨")
                .replace(copyrightsign2, "©")
                .replace(femordinalindicator2, "ª")
                .replace(leftdoubleangle2, "«")
                .replace(notsign2, "¬")
                .replace(softhyphen2, "­")
                .replace(registered2, "®")
                .replace(macron2, "¯")
                .replace(degree2, "°")
                .replace(plusminus2, "±")
                .replace(superscripttwo2, "²")
                .replace(superscriptthree2, "³")
                .replace(acuteaccent2, "´")
                .replace(micro2, "µ")
                .replace(pilcrow2, "¶")
                .replace(middledot2, "·")
                .replace(cedilla2, "¸")
                .replace(superscriptone2, "¹")
                .replace(ordinalindicator2, "º")
                .replace(rightdoubleangle2, "»")
                .replace(onequarter2, "¼")
                .replace(onehalf2, "½")
                .replace(threequarters2, "¾")
                .replace(invquestionmark2, "¿")
                .replace(capagrave2, "À")
                .replace(capaacute2, "Á")
                .replace(capacircumflex2, "Â")
                .replace(capatilde2, "Ã")
                .replace(capadiaeresis2, "Ä")
                .replace(caparingabove2, "Å")
                .replace(capae2, "Æ")
                .replace(capccedilla2, "Ç")
                .replace(capegrave2, "È")
                .replace(capeacute2, "É")
                .replace(capecircumflex2, "Ê")
                .replace(capediaeresis2, "Ë")
                .replace(capigrave2, "Ì")
                .replace(capiacute2, "Í")
                .replace(capicircumflex2, "Î")
                .replace(capidiaeresis2, "Ï")
                .replace(capeth2, "Ð")
                .replace(capntilde2, "Ñ")
                .replace(capograve2, "Ò")
                .replace(capoacute2, "Ó")
                .replace(capocircumflex2, "Ô")
                .replace(capotilde2, "Õ")
                .replace(capodiaeresis2, "Ö")
                .replace(multiplication2, "×")
                .replace(capostroke2, "Ø")
                .replace(capugrave2, "Ù")
                .replace(capuacute2, "Ú")
                .replace(capucircumflex2, "Û")
                .replace(capudiaeresis2, "Ü")
                .replace(capyacute2, "Ý")
                .replace(capthorn2, "Þ")
                .replace(sharps2, "ß")
                .replace(agrave2, "à")
                .replace(aacute2, "á")
                .replace(acircumflex2, "â")
                .replace(atilde2, "ã")
                .replace(adiaeresis2, "ä")
                .replace(aringabove2, "å")
                .replace(ae2, "æ")
                .replace(ccedilla2, "ç")
                .replace(egrave2, "è")
                .replace(eacute2, "é")
                .replace(ecircumflex2, "ê")
                .replace(ediaeresis2, "ë")
                .replace(igrave2, "ì")
                .replace(iacute2, "í")
                .replace(icircumflex2, "î")
                .replace(idiaeresis2, "ï")
                .replace(eth2, "ð")
                .replace(ntilde2, "ñ")
                .replace(ograve2, "ò")
                .replace(oacute2, "ó")
                .replace(ocircumflex2, "ô")
                .replace(otilde2, "õ")
                .replace(odiaeresis2, "ö")
                .replace(division2, "÷")
                .replace(ostroke2, "ø")
                .replace(ugrave2, "ù")
                .replace(uacute2, "ú")
                .replace(ucircumflex2, "û")
                .replace(udiaeresis2, "ü")
                .replace(yacute2, "ý")
                .replace(thorn2, "þ")
                .replace(ydiaeresis2, "ÿ")
        }

        return str;
    }
}
