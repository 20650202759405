import { Component, OnInit, OnDestroy } from '@angular/core';
import { Layer } from '../../models/layer.model';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-layer-view',
  templateUrl: './layer-view.component.html',
  styleUrls: ['./layer-view.component.scss']
})
export class LayerViewComponent implements OnInit, OnDestroy {

  layers: Layer[] = [];
  loading = true;
  subscriptions: Subscription[] = [];
  highlightedLayerIndex = -1;


  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
        if (ifcData) {

          this.loadLayers();

          this.loading = false;
        }
      })
    )
    this.subscriptions.push(
      this.appActionsService.refreshLayersVisability.subscribe(() => {
        this.refreshLayers();
      })
    )

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {

        let config = [];

        if (viewConfig) {
          if (viewConfig.layersConfig) {
            config = viewConfig.layersConfig;

          }

        }

        try {
          this.loadLayerConfig(config);
        } catch (err) { console.warn(err) }

      }))


    this.subscriptions.push(
      this.appActionsService.objectDataAction.subscribe(e => {
        if (e.action == "layerClicked") {
          this.focusOnLayer(e.layerName)
        }
      }))

    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe((oid) => {
      if (!oid) {
        this.highlightedLayerIndex = -1;;
      }
    }))

  }


  focusOnLayer(name) {
    const i = this.layers.findIndex(l => (l.name == name));
    this.highlightedLayerIndex = i;
    if (i != -1) {
      let domElement = document.getElementById('layer_' + i);
      if (domElement) {
        setTimeout(() => {
          domElement.scrollIntoView(false)
        }, 20)
      }
    }
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadLayers() {
    this.layers = this.dataService.getLayers();


    this.layers.forEach(layer => {

      if (this.checkIfLayerContainsZonesOnly(layer)) {
        layer.visibility = 0;
       
      }

      for (let oid of layer['objectsOids']) {

        if (this.dataService.objectsData[oid] == null) {
          this.dataService.objectsData[oid] = {}
        }
        this.dataService.objectsData[oid].layerName = layer.name;
      }


    })

    this.appActionsService.changeLayersVisibility.next(this.layers)


    if (this.dataService.viewConfig.layersConfig == null) {

      //this.updateLayerConfig();
    }




  }

  updateLayerConfig() {

    let newLayersConfig = [];

    this.layers.forEach(layer => {
      newLayersConfig.push(
        {
          name: layer.name,
          visibility: layer.visibility
        }
      )

    })
    this.dataService.viewConfig.layersConfig = newLayersConfig;
  
  }

  showAllLayersConfig(state) {   //@mg show all
    this.layers = this.dataService.getLayers();
    // if (state = "inverse"){
    // }

    this.layers.forEach(layer => {
      if (this.layers) {

        layer.visibility = state;
       
      }

    })

    this.appActionsService.changeLayersVisibility.next(this.layers)

    this.updateLayerConfig();

  }



  loadLayerConfig(config) {

    this.layers.forEach(layer => {



      for (let layerConfig of config) {
        if (layerConfig.name == layer.name) {
          let visibility = layerConfig.visibility;

          if (visibility != null) {
            layer.visibility = visibility;

          }
        }

      }

    })

    this.updateLayerConfig();
    this.refreshLayers();
  }


  refreshLayers() {

    this.appActionsService.changeLayersVisibility.next(this.layers)

  }

  checkIfLayerContainsZonesOnly(layer) {

    let containZone = true;
    let oids = layer.objectsOids;

    if (!oids) {
      //if no objects...
      return false;
    }

    for (let i = 0; i < oids.length; i++) {
      if (this.dataService.ifcData.nodes[oids[i]]) {


        containZone = containZone && (this.dataService.ifcData.nodes[oids[i]].type == 'IfcSpace');
      }


      if (!containZone) {
        return false;
      }
    }




    return true;
  }
  //@mg to display onlu one layer
  list = []
  index = 0
  visibilityButtonClicked(layer: Layer) {
    if (layer.visibility == 1) {
      layer.visibility = 0;
    } else {
      layer.visibility = 1;
    }

    this.layers.forEach(layer => {
      layer.solo = 0;
      this.list = []
    })

    this.appActionsService.changeLayersVisibility.next([layer])
    this.updateLayerConfig();
  }

  listBeforeSolo() {
    this.list = []
    this.layers.forEach(layer => {
      let ind = 0
      if (layer.visibility == (1 || true)) { ind = 1 } else { ind = 0 }
      this.list.push([this.index, ind])
      layer.solo = 0;
      this.index++;
    })

  }
  othersLayersVisibilityButtonClicked(layer: Layer, solo: Layer) {
    if (this.list === undefined || this.list.length == 0) {
      this.listBeforeSolo()
      this.layers.forEach(layer => {
        layer.visibility = 0;
        layer.solo = 0;
     

      })
      layer.visibility = 1;
      layer.solo = 1;

      this.updateLayerConfig();
    } else {
      let index = 0
      if (layer.solo == 1) {
        this.layers.forEach(layer => {
          let ind = 0;
          if (this.list[index][1] == (1 || true)) { ind = 1 } else { ind = 0 }
          layer.visibility = ind;
          layer.solo = 0;
          index++;
         
  

        })
        this.list = []
      } else {
        this.layers.forEach(layer => {
          layer.visibility = 0;
          layer.solo = 0;
          index++;
       


        })
        layer.visibility = 1;
        layer.solo = 1;
    
        this.updateLayerConfig();
      }


    }

    this.appActionsService.changeLayersVisibility.next(this.layers)
  }


}




