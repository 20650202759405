import { Component, OnInit, OnDestroy, ChangeDetectorRef, ɵConsole, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { AppActionsService } from '../services/app-actions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import {resizeImage } from '../modules/HelperFunctions';
@Component({
  selector: 'app-labels-editor',
  templateUrl: './labels-editor.component.html',
  styleUrls: ['./labels-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsEditorComponent implements OnInit, OnDestroy {
  labels = {}
  iconOptions = [
    'priority_high',
    'question_mark', 'collections',
    'stars', 'verified_user', 'work', 'chat', 'note',
    'flag', 'waves', 'weekend', 'attach_file', 'bubble_chart',
    'looks', 'directions_bike', 'local_cafe', 'local_hotel', 'local_offer',
    'local_parking', 'local_shipping', 'beach_access', 'kitchen', 'meeting_room',
    'pool', 'school', 'whatshot', 'audiotrack', 'family_restroom', 'electric_bolt', 'card_travel', 'construction',
    'drive_eta',
    'power', 'restaurant',
    'restaurant_menu', 'airline_seat_individual_suite', 'wc', 'child_friendly']

  subscriptions: Subscription[] = [];
  // huebackground = null;
  openedLabels = {};
  sortOrder = [];

  uploadingImage = {};
  uploadingImageProgress = 0;


  constructor(private dataService: DataService, private appActionsService: AppActionsService, private cdr: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {

    // this.generateHueBackground();
    this.labels = this.dataService.labels;
    this.sortLabels();

    this.subscriptions.push(this.appActionsService.selectedModeChanged.subscribe(() => {

      this.cdr.detectChanges();
    }))

    this.subscriptions.push(this.dataService.labelsUpdated.subscribe(() => {


      this.sortLabels();
      this.cdr.detectChanges();
      for (let key in this.labels) {
        if (!this.labels[key].showTextBehavior) { //set default value for showTextBehavior
          this.labels[key].showTextBehavior = 'active';
        }
      }


    }))

    this.subscriptions.push(this.appActionsService.threejsClick.subscribe(async threejsClickEvent => {
      if (this.appActionsService.createLabelMode) {

        const newLabelKkey = await this.dataService.addLabel({
          title: 'New Label',
          type: 'default',
          position: threejsClickEvent.positionWorldSpace
        })
        this.appActionsService.createLabelMode = false;


        //very hacky solution to make sure added label goes into the config.
        const labelsConfig = this.dataService.viewConfig['labelsConfig'] || {};
        labelsConfig[newLabelKkey] = { visible: true }
        labelsConfig.sortOrders = this.sortOrder;
        this.dataService.viewConfig['labelsConfig'] = labelsConfig;


      }


    }))

    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {


      let config = {};

      if (viewConfig) {
        if (viewConfig.labelsConfig) {
          config = viewConfig.labelsConfig;

        }

      }

      this.loadLabelsConfigFromViewConfig(config)



    }))

    this.subscriptions.push(
      this.appActionsService.objectDataAction.subscribe(e => {
        if (e.action == 'labelClicked') {
          this.openedLabels = {};

          for (let key in this.labels) {
            if (this.labels[key].index == e.index) {
              this.openedLabels[key] = true;
              this.cdr.detectChanges();
              setTimeout(() => {
                document.getElementById('label_' + key).scrollIntoView(false)
              }, 20)

            }
          }
        }

        if (e.action == 'labelToggleVisible') {

          for (let key in this.labels) {
            if (this.labels[key].guid == e.guid) {
              this.toggleLabelVisibility(key)

            }
          }

        }
      })
    )

    this.subscriptions.push(this.appActionsService.selectedDataModeChanged.subscribe(() => {
      this.appActionsService.createLabelMode = false;
      this.cdr.detectChanges();
    }))
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })


  }

  sortLabels() {


    if (this.dataService.viewConfig.labelsConfig) {
      if (this.dataService.viewConfig.labelsConfig['sortOrder']) {
        this.sortOrder = this.dataService.viewConfig.labelsConfig['sortOrder'];
      }

    }

    for (let key in this.labels) { //pushing keys that are not in the sort order!
      if (this.sortOrder.indexOf(key) == -1) {
        this.sortOrder.push(key);
      }

    }

    //removing keys that doesnt have labels:
    let keysToRemoveFromSort = [];
    for (let key of this.sortOrder) {
      if (this.labels[key] == null) {
        keysToRemoveFromSort.push(key)
      }
    }
    for (let key of keysToRemoveFromSort) {
      this.sortOrder.splice(this.sortOrder.indexOf(key), 1)
    }

    for (let i = 0; i < this.sortOrder.length; i++) { //setting index label according to new order
      this.labels[this.sortOrder[i]].index = i;

    }


  }




  // generateHueBackground() {
  //   this.huebackground = 'linear-gradient(to right'

  //   for (let i = 0; i < 360; i++) {
  //     this.huebackground = this.huebackground + ',hsl(' + i + ',50%,60%)'
  //   }

  //   this.huebackground = this.huebackground + ')'
  // }



  removeLabel(key) {
    this.dataService.removeLabel(key)
  }

  showTextBehaviorChanged(key, event) {


    let updates = {
      showTextBehavior: event.target.value
    }

    this.dataService.updateLabel(key, updates)
  }

  usePhotoPositionChanged(key, event) {

    let updates = {
      usePhotoPosition: event.checked
    }

    this.dataService.updateLabel(key, updates)

  }

  labelTypeChanged(key, event) {
    let updates = null;

    if (event.target.value != '') {
      updates = {
        type: event.target.value
      }
    }


    if (updates) {
      this.dataService.updateLabel(key, updates)
    }
  }

  linkedToIdChanged(key, event) {

    let updates = null;

    if (event.target.value != '') {
      updates = {
        linkedToId: event.target.value
      }
    }


    if (updates) {
      this.dataService.updateLabel(key, updates)
    }


  }


  labelTextChanged(key, event) {
    let updates = {
      text: event.target.value
    }


    this.dataService.updateLabel(key, updates);

  }



  labelColorClosed(key, event) {


    const updates = {
      color: event
    }

    this.dataService.updateLabel(key, updates)

  }

  labelTitleChanged(key, event) {
    let updates = null;

    if (event.target.value != '') {
      updates = {
        title: event.target.value
      }
    } else {
      updates = {
        title: this.translate.instant('labels.untitledLabel')
      }
    }


    if (updates) {
      this.dataService.updateLabel(key, updates)
    }

  }



  showAllLabels() {

    for (let key in this.labels) {
      this.labels[key].visible = true;
      this.cdr.detectChanges();
    }

    this.updateViewConfig()
    this.dataService.labelsUpdated.next();

  }

  closeLabelPannels() {
    for (let key in this.labels) {
      this.openedLabels[key] = false;
      this.cdr.detectChanges();

    }
  }



  async uploadImage(key, fileInput) {
    this.uploadingImage[key] = 0.01;
    try {
      let file = fileInput.files[0]
      fileInput.value = null;

      const thumbData = await resizeImage(file, 200)
    

 

      const imageId = UUID.UUID();
      await this.dataService.uploadLabelImageToStorage(file, `${imageId}.jpg`,
        (snapshot) => {
          const progress = 100*snapshot.bytesTransferred/ snapshot.totalBytes;
          this.uploadingImage[key] = progress;
          this.cdr.detectChanges();
        }
      )

      this.dataService.updateLabel(key, {
        image: thumbData,
        imageId: imageId
      })

    } catch (err) {
      delete this.uploadingImage[key]
      this.cdr.detectChanges()
      this.appActionsService.notify('Error uploading image', "error", 10);
    }

    delete this.uploadingImage[key]
    this.cdr.detectChanges()

  }


  removeImage(key) {
    const label = this.labels[key];
    this.dataService.deleteFile(`projects/${this.dataService.project.id}/labels`, `${label.imageId}.jpg`)
    this.dataService.updateLabel(key, { image: null, imageId: null }).then(
      deleted => {
        // deleted is needed here because the update that happens in dataService is actully on reacting to (child_changed) event in firebase,
        //  but the way i wrote it (in dataService) is that it only update the existing properties in firebase. therefor its not nulling the 'image' property.
        //   so we delete it here!
        delete this.labels[key].imageId
        delete this.labels[key].image

        this.cdr.detectChanges();
      }
    )
  }

  toggleLabelProperites(key) {
    if (this.openedLabels[key]) {
      delete this.openedLabels[key]
    } else {
      this.openedLabels[key] = true;
    }

  }


  toggleLabelVisibility(key) {
    if (this.labels[key]) {
      if (this.labels[key].visible) {
        this.labels[key].visible = false;
      } else {
        this.labels[key].visible = true;
      }
    }

    this.cdr.detectChanges()
    this.updateViewConfig()
    this.dataService.labelsUpdated.next();
  }


  hideAllLabelsClicked() {
    this.hideAllLabels();
    this.updateViewConfig();
    this.dataService.labelsUpdated.next();
    this.cdr.detectChanges();
  }

  hideAllLabels() {

    for (let key in this.labels) {
      this.labels[key].visible = false;
    }


  }

  loadLabelsConfigFromViewConfig(labelsConfig) {
    this.hideAllLabels();
    if (labelsConfig) {


      for (let key in labelsConfig) {
        if (this.labels[key]) {


          if (labelsConfig[key].visible != null) {
            this.labels[key]['visible'] = labelsConfig[key].visible;
          } else {
            delete this.labels[key]['visible'];
          }



        }



      }
    }

    this.sortLabels();
    this.cdr.detectChanges()


  }

  updateViewConfig() {

    let labelsConfig = {
      sortOrder: this.sortOrder
    }

    for (let key in this.labels) {
      if (labelsConfig[key] == null) {
        labelsConfig[key] = {}
      }

      if (this.labels[key].visible != null) {
        if (labelsConfig[key]) {
          labelsConfig[key]['visible'] = this.labels[key].visible;
        }
      }



    }
    this.dataService.viewConfig['labelsConfig'] = labelsConfig;



  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.sortOrder, event.previousIndex, event.currentIndex);
    this.sortLabels();
    this.updateViewConfig();
  }

  labelOffsetChanged(key, coordinate, value) {
    if (value == null) {
      value = 0;
    }
    if (this.labels[key]) {
      if (this.labels[key].offset == null) {
        this.labels[key]['offset'] = { x: 0, y: 0, z: 0 }
      }

      let offset = {
        x: this.labels[key].offset.x,
        y: this.labels[key].offset.y,
        z: this.labels[key].offset.z
      }

      offset[coordinate] = Number(value);

      let updates = {
        offset: offset
      }





      if (updates)
        this.dataService.updateLabel(key, updates)

    }

  }

  addNewLabelClicked() {
    this.appActionsService.createLabelMode = !this.appActionsService.createLabelMode;
  }


  selectIconOnMenu(labelKey, iconString) {
    this.labels[labelKey].icon = iconString;

    this.dataService.updateLabel(labelKey, {
      icon: iconString
    })
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }


}
