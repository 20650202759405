export class Zone {
    public oid: string;
    public guid: string;
    public name:string;
    public longName:string;
    public visible:boolean;
    public color:string;
   


    constructor(oid,guid,name,longName){
        this.oid = oid;
        this.guid = guid;
        this.name = name;
        this.visible = true;
        this.color = null;
        this.longName  = longName || null;

       
    }


}

