export class Layer {
    public id: string;
    public name:string;
    public objectsOids:string[];
    public visibility: number;
    public solo : number;



    constructor(id,name,objectsOids,visibility,solo?){
        this.id = id;
        this.name = name;
        this.objectsOids = objectsOids;
        this.visibility = visibility;
        this.solo=solo;
       
    }

  
}

