export const IfcElements: {[key: number]: string} = {
    103090709: 'IFCPROJECT',
    4097777520: 'IFCSITE',
    4031249490: 'IFCBUILDING',
    3124254112: 'IFCBUILDINGSTOREY',
    3856911033: 'IFCSPACE',
    1674181508: 'IFCANNOTATION',
    25142252: 'IFCCONTROLLER',
    32344328: 'IFCBOILER',
    76236018: 'IFCLAMP',
    90941305: 'IFCPUMP',
    177149247: 'IFCAIRTERMINALBOX',
    182646315: 'IFCFLOWINSTRUMENT',
    263784265: 'IFCFURNISHINGELEMENT',
    264262732: 'IFCELECTRICGENERATOR',
    277319702: 'IFCAUDIOVISUALAPPLIANCE',
    310824031: 'IFCPIPEFITTING',
    331165859: 'IFCSTAIR',
    342316401: 'IFCDUCTFITTING',
    377706215: 'IFCMECHANICALFASTENER',
    395920057: 'IFCDOOR',
    402227799: 'IFCELECTRICMOTOR',
    413509423: 'IFCSYSTEMFURNITUREELEMENT',
    484807127: 'IFCEVAPORATOR',
    486154966: 'IFCWINDOWSTANDARDCASE',
    629592764: 'IFCLIGHTFIXTURE',
    630975310: 'IFCUNITARYCONTROLELEMENT',
    635142910: 'IFCCABLECARRIERFITTING',
    639361253: 'IFCCOIL',
    647756555: 'IFCFASTENER',
    707683696: 'IFCFLOWSTORAGEDEVICE',
    738039164: 'IFCPROTECTIVEDEVICE',
    753842376: 'IFCBEAM',
    812556717: 'IFCTANK',
    819412036: 'IFCFILTER',
    843113511: 'IFCCOLUMN',
    862014818: 'IFCELECTRICDISTRIBUTIONBOARD',
    900683007: 'IFCFOOTING',
    905975707: 'IFCCOLUMNSTANDARDCASE',
    926996030: 'IFCVOIDINGFEATURE',
    979691226: 'IFCREINFORCINGBAR',
    987401354: 'IFCFLOWSEGMENT',
    1003880860: 'IFCELECTRICTIMECONTROL',
    1051757585: 'IFCCABLEFITTING',
    1052013943: 'IFCDISTRIBUTIONCHAMBERELEMENT',
    1062813311: 'IFCDISTRIBUTIONCONTROLELEMENT',
    1073191201: 'IFCMEMBER',
    1095909175: 'IFCBUILDINGELEMENTPROXY',
    1156407060: 'IFCPLATESTANDARDCASE',
    1162798199: 'IFCSWITCHINGDEVICE',
    1329646415: 'IFCSHADINGDEVICE',
    1335981549: 'IFCDISCRETEACCESSORY',
    1360408905: 'IFCDUCTSILENCER',
    1404847402: 'IFCSTACKTERMINAL',
    1426591983: 'IFCFIRESUPPRESSIONTERMINAL',
    1437502449: 'IFCMEDICALDEVICE',
    1509553395: 'IFCFURNITURE',
    1529196076: 'IFCSLAB',
    1620046519: 'IFCTRANSPORTELEMENT',
    1634111441: 'IFCAIRTERMINAL',
    1658829314: 'IFCENERGYCONVERSIONDEVICE',
    1677625105: 'IFCCIVILELEMENT',
    1687234759: 'IFCPILE',
    1904799276: 'IFCELECTRICAPPLIANCE',
    1911478936: 'IFCMEMBERSTANDARDCASE',
    1945004755: 'IFCDISTRIBUTIONELEMENT',
    1973544240: 'IFCCOVERING',
    1999602285: 'IFCSPACEHEATER',
    2016517767: 'IFCROOF',
    2056796094: 'IFCAIRTOAIRHEATRECOVERY',
    2058353004: 'IFCFLOWCONTROLLER',
    2068733104: 'IFCHUMIDIFIER',
    2176052936: 'IFCJUNCTIONBOX',
    2188021234: 'IFCFLOWMETER',
    2223149337: 'IFCFLOWTERMINAL',
    2262370178: 'IFCRAILING',
    2272882330: 'IFCCONDENSER',
    2295281155: 'IFCPROTECTIVEDEVICETRIPPINGUNIT',
    2320036040: 'IFCREINFORCINGMESH',
    2347447852: 'IFCTENDONANCHOR',
    2391383451: 'IFCVIBRATIONISOLATOR',
    2391406946: 'IFCWALL',
    2474470126: 'IFCMOTORCONNECTION',
    2769231204: 'IFCVIRTUALELEMENT',
    2814081492: 'IFCENGINE',
    2906023776: 'IFCBEAMSTANDARDCASE',
    2938176219: 'IFCBURNER',
    2979338954: 'IFCBUILDINGELEMENTPART',
    3024970846: 'IFCRAMP',
    3026737570: 'IFCTUBEBUNDLE',
    3027962421: 'IFCSLABSTANDARDCASE',
    3040386961: 'IFCDISTRIBUTIONFLOWELEMENT',
    3053780830: 'IFCSANITARYTERMINAL',
    3079942009: 'IFCOPENINGSTANDARDCASE',
    3087945054: 'IFCALARM',
    3101698114: 'IFCSURFACEFEATURE',
    3127900445: 'IFCSLABELEMENTEDCASE',
    3132237377: 'IFCFLOWMOVINGDEVICE',
    3171933400: 'IFCPLATE',
    3221913625: 'IFCCOMMUNICATIONSAPPLIANCE',
    3242481149: 'IFCDOORSTANDARDCASE',
    3283111854: 'IFCRAMPFLIGHT',
    3296154744: 'IFCCHIMNEY',
    3304561284: 'IFCWINDOW',
    3310460725: 'IFCELECTRICFLOWSTORAGEDEVICE',
    3319311131: 'IFCHEATEXCHANGER',
    3415622556: 'IFCFAN',
    3420628829: 'IFCSOLARDEVICE',
    3493046030: 'IFCGEOGRAPHICELEMENT',
    3495092785: 'IFCCURTAINWALL',
    3508470533: 'IFCFLOWTREATMENTDEVICE',
    3512223829: 'IFCWALLSTANDARDCASE',
    3518393246: 'IFCDUCTSEGMENT',
    3571504051: 'IFCCOMPRESSOR',
    3588315303: 'IFCOPENINGELEMENT',
    3612865200: 'IFCPIPESEGMENT',
    3640358203: 'IFCCOOLINGTOWER',
    3651124850: 'IFCPROJECTIONELEMENT',
    3694346114: 'IFCOUTLET',
    3747195512: 'IFCEVAPORATIVECOOLER',
    3758799889: 'IFCCABLECARRIERSEGMENT',
    3824725483: 'IFCTENDON',
    3825984169: 'IFCTRANSFORMER',
    3902619387: 'IFCCHILLER',
    4074379575: 'IFCDAMPER',
    4086658281: 'IFCSENSOR',
    4123344466: 'IFCELEMENTASSEMBLY',
    4136498852: 'IFCCOOLEDBEAM',
    4156078855: 'IFCWALLELEMENTEDCASE',
    4175244083: 'IFCINTERCEPTOR',
    4207607924: 'IFCVALVE',
    4217484030: 'IFCCABLESEGMENT',
    4237592921: 'IFCWASTETERMINAL',
    4252922144: 'IFCSTAIRFLIGHT',
    4278956645: 'IFCFLOWFITTING',
    4288193352: 'IFCACTUATOR',
    4292641817: 'IFCUNITARYEQUIPMENT',
    3009204131: 'IFCGRID'
};
