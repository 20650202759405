import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppActionsService } from '../../../services/app-actions.service';
import { Subscription } from 'rxjs';
import { ToolboxEvent } from 'src/models/toolbox-event';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {
  //datas
  showUsersPhotos = true;
  photos = [];
  selectedPhotoId = null;
  lastMovedPath = null;
  openNoteId = null;
  linkedButtons = {};
  initialPhotoId = null;
  subscriptions: Subscription[] = [];
  materialsConfig = null;

  //gui datas
  cssHeight;
  //to track mouse position for resizing height
  previousMouseY = null;
  lastOpenPhoto = null;
  emptyGallery = 0;



  constructor(
    private dataService: DataService,
    private appActionsService: AppActionsService,
    private eltRef: ElementRef
  ) {
    this.setCssGalleryHeight(150)
  }

  ngOnInit() {
    this.materialsConfig = this.dataService.materialsConfigs;

    this.subscriptions.push(this.appActionsService.openNote.subscribe(note => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = null;
      }
    }))

    this.subscriptions.push(this.dataService.photosDataFetched.subscribe(photos => {
      this.photos = photos;
      if (this.photos.length>1) {
      
      this.emptyGallery = 1;
      } else {this.emptyGallery = 0}
      

      //refresh the last photo
      for (let photo of photos){
        if (this.lastOpenPhoto && photo.id == this.lastOpenPhoto.id){
          this.lastOpenPhoto = photo
        }
      }

      this.updateLinkedButtons()
    }))

    this.subscriptions.push(this.appActionsService.openPhoto.subscribe(photo => {
      if (photo) {
        this.selectedPhotoId = photo.id;
      } else {
        this.selectedPhotoId = null;
      }
    }))

    this.subscriptions.push(this.appActionsService.checkPhotosLinkedToButton.subscribe(() => {
      this.updateLinkedButtons()
    }))
    this.subscriptions.push(
      this.dataService.lastOpenPhoto.subscribe( (photo) => {
        this.lastOpenPhoto = photo;
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
    this.appActionsService.cssGalleryHeight.next(0)
  }

  showUsersPhotoChanged(event) {
    this.showUsersPhotos = !event.checked;
  }

  openPhoto(photo) {
    if (photo.id == this.selectedPhotoId) {
      return;
    }
    this.appActionsService.openPhoto.next(photo);
  }

  loadPhotoView(photo) {
    this.dataService.loadPhotoView(photo)
  }

  refreshPhotoView(photoId) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('gallery', 'updates', this.constructor.name, { refreshPhoto: photoId }))
  }

  dragReleased(photo) {
    if (this.lastMovedPath == null) {
      return;
    }


    if (this.lastMovedPath == "thumbsList-note") {
      this.dataService.relatePhotoToNote(this.openNoteId, photo.id);

    }

    if (this.lastMovedPath == "photoDropZone-button") {
      this.appActionsService.itemDroppedOnButton.next({ type: 'photo', value: photo.id })
    }

    if (this.lastMovedPath == "photoDropZone-view") {
      this.appActionsService.photoDroppedOnView.next(photo.id)
    }

    this.lastMovedPath = null;
  }

  dragMoved(e) {   

    if (e.event.target && e.event.target.dataset && e.event.target.dataset.targetRef) {
      this.lastMovedPath = e.event.target.dataset.targetRef
    }
   
  }

  close() {
    this.appActionsService.galleryIsOpen = !this.appActionsService.galleryIsOpen;
  }

  updateLinkedButtons() {
    if (this.dataService.loadedView == null) {
      return;
    }
    this.linkedButtons = {};
    if (this.dataService.loadedView.menu) {
      for (let button of this.dataService.loadedView.menu) {
        if(button.onClick) {
          if (button.onClick.action == "loadPhoto") {

            this.linkedButtons[button.onClick.value] = button.id
          }
        }


        if (button.children) {
          for (let childButton of button.children) {
            if (childButton.onClick.action == "loadPhoto") {

              this.linkedButtons[childButton.onClick.value] = childButton.id
            }
          }
        }
      }
    }

    this.initialPhotoId = this.dataService.loadedView.initialPhoto;
  }
  onCameraButtonMouseEnter() {
    this.appActionsService.showCameraOverlay=true;
  }

  onCameraButtonMouseOut() {
    this.appActionsService.showCameraOverlay=false;
  }

  setCssGalleryHeight(value){
    this.cssHeight = value;
    this.eltRef.nativeElement.style.height = this.cssHeight+"px";
    this.appActionsService.cssGalleryHeight.next(this.cssHeight)
  }
  resizeHeight(event){
    //0 seems to mean last event so don't take it account
    if(event.clientY != 0 && this.previousMouseY){
      let newHeight = this.cssHeight - event.clientY + this.previousMouseY
      this.previousMouseY = event.clientY
      //check if new height is at least 20px
      if (newHeight > 20){
        this.setCssGalleryHeight(newHeight)
      }
    }
  }
  resizeHeightStart(event){
    this.previousMouseY = event.clientY;
    document.getElementById('resizeButton').setPointerCapture(event.pointerId)
  }
  resizeHeightStop(event){
    this.previousMouseY = null;
    document.getElementById('resizeButton').releasePointerCapture(event.pointerId)
  }
  onMaterialsConfigSelected(event){
    this.dataService.materialsConfigsUpdated.next({type : 'config_changed', key: event.value})
  }

  deleteAllPhotos() {
    this.subscriptions.push(this.dataService.photosDataFetched.subscribe(photos => {
      this.photos = photos;
   

      //refresh the last photo
      for (let photo of this.photos) {
        this.dataService.deletePhoto(photo)
        this.updateLinkedButtons()
        }
      }))
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
    this.ngOnInit()
    this.emptyGallery = 0
  }

}
