import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { ToolboxEvent } from '../../models/toolbox-event';

import { Zone } from '../../models/zone.model';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { SearchInValuePipe } from '../pipes/searchInValue.pipe';
import { debug } from 'util';

@Component({
  selector: 'app-zones-viewer',
  templateUrl: './zones-viewer.component.html',
  styleUrls: ['./zones-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZonesViewerComponent implements OnInit, OnDestroy {

  chosenZone = null;
  zones: Zone[] = [];
  loading = true;
  colors = [];
  defaultColor = ''
  filterString = '';
  subscriptions: Subscription[] = [];
  loadedConfig = null;

  zonesObject = {};
  zoneToEdit = null;
  opacity = 0.5;

  constructor(private appActionsService: AppActionsService, private cdr: ChangeDetectorRef, private dataService: DataService, private searchInValuePipe: SearchInValuePipe) { }

  ngOnInit() {

    this.generateColors();

    this.subscriptions.push(this.dataService.overwritesChanged.subscribe((change) => {
      const zone = this.zonesObject[change.guid];

      if (zone) {//only update if existing alreay created in zonesojbect

        if (change.type == 'child_changed' || change.type == 'child_added') {

          zone.name = change.value.name;
          zone.longName = change.value.longName;


        }

        if (change.type == 'child_removed') {

          zone.name = zone.originData.name;
          zone.longName = zone.originData.longName;

        }
        this.cdr.detectChanges();
      }

    }))

    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe((event) => {
      if (event.tool == 'zonesMode') {
        if (event.type == 'open') {
          this.loadZonesPropertiesFromViewConfig(this.dataService.viewConfig.zonesMode);
          this.updateZonesProperties();
        }
      }

    }))

    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe((event) => {

      if (event.tool == null) {
        this.loadedConfig = null;

      }

    }))

    this.subscriptions.push(this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
      if (ifcData) {
        this.zones = this.dataService.getZones();
        for (let zone of this.zones) {
          this.zonesObject[zone.guid] = zone;
          this.zonesObject[zone.guid].originData = {
            name: zone.name,
            longName: zone.longName
          }
        }
        this.loading = false; // will not let loadzoneproperties to run unless it set to false. important as our zones need to be fetched first after model has loaded in bimserverjsapi service
        this.loadZonesPropertiesFromViewConfig(this.dataService.viewConfig.zonesMode);
        this.updateAllZonesFromOverwrites();
        this.opacity = this.dataService.viewConfig.zonesGlobalOpacity;
        this.onOpacityChanged();
      }
    }))

    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {

      if (viewConfig) {
        this.opacity = viewConfig.zonesGlobalOpacity || 0.5;
        this.onOpacityChanged();
        if (viewConfig.zonesMode) {
          this.loadZonesPropertiesFromViewConfig(this.dataService.viewConfig.zonesMode);
          if (viewConfig.selectedMode == 'zonesMode') {
            this.updateZonesProperties();
          }

        }
      }
    }))

    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe(oid => {

      if (oid == null) {
        this.chosenZone = null;
      } else {
        this.zones.forEach(zone => {
          if (zone.oid == oid) {
            this.chosenZone = zone;
          }
        })
      }

      this.cdr.detectChanges();
      let domNode = document.getElementById('zoneButton_' + oid)
      if (domNode) {

        domNode.scrollIntoView(false);
      }
    }))
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  updateAllZonesFromOverwrites() {
    for (let key in this.zonesObject) {

      const overwrite = this.dataService.overwrites[key];
      if (overwrite) {
        this.zonesObject[key].name = overwrite.name;
        this.zonesObject[key].longName = overwrite.longName;
      } else {
        this.zonesObject[key].name = this.zonesObject[key].originData.name;
        this.zonesObject[key].longName = this.zonesObject[key].originData.longName;
      }


    }
  }

  zoneClicked(zone) {
    this.appActionsService.chosenObjectOid.next(zone.oid)
  }

  zoneMouseEnter(zone) {

    this.appActionsService.changeZonesProperties.next([{ guid: zone.guid, opacity: 0.7 }])
  }

  zoneMouseLeave(zone) {

    this.appActionsService.changeZonesProperties.next([{ guid: zone.guid, opacity: 0.35 }])
  }

  generateColors() {
    let n = 8;
    for (let i = 0; i < (n); i++) {
      //in this method we are setting colors[0] to the default color for ifcspace of the ifcconvert...
      this.colors.push('hsl(' + (197 + (i * 360 / n)) + ',70%,39%)')
    }

  }

  changeZoneColor(zone, color) {
    zone.color = color;
    this.appActionsService.changeZonesProperties.next([{ guid: zone.guid, color: color }]);
    this.updateViewConfig();
    this.cdr.detectChanges();
  }

  changeBulkZonesColor(color) {
    let filteredZones = this.searchInValuePipe.transform(this.zones, ['name', 'longName'], this.filterString);
    for (let zone of filteredZones) {
      zone.color = color;
      this.appActionsService.changeZonesProperties.next([{ guid: zone.guid, color: color }]);
    }

    this.updateViewConfig();
    this.cdr.detectChanges();

  }

  toggleZoneVisibility(zone) {
    if (zone.visible) {
      zone.visible = false;
    } else {
      zone.visible = true;
    }

    this.appActionsService.changeZonesProperties.next([{ guid: zone.guid, visible: zone.visible }]);
    this.updateViewConfig();
    this.cdr.detectChanges();

  }


  setBulkZonesVisibility(state) {

    let filteredZones = this.searchInValuePipe.transform(this.zones, ['name', 'longName'], this.filterString);
    let updates = [];
    for (let zone of filteredZones) {
      zone.visible = state
      updates.push({ guid: zone.guid, visible: zone.visible })

    }

    this.appActionsService.changeZonesProperties.next(updates);


    this.updateViewConfig();
    this.cdr.detectChanges();
  }

  onFilterInput() {
    this.cdr.detectChanges();
  }

  getZone(guid) {
    return this.zonesObject[guid];
  }


  updateViewConfig() {
    this.dataService.viewConfig['zonesMode'] = this.zones;
    this.dataService.viewConfig['zonesGlobalOpacity'] = this.opacity
  }

  loadZonesPropertiesFromViewConfig(originalConfig) {

    if (this.loading) {
      return;
    }

    if (originalConfig == this.loadedConfig) {



      return;
    } else {
      this.loadedConfig = originalConfig;
    }

    let config = JSON.parse(JSON.stringify(originalConfig));

    let configObject = {}

    for (let z of config) {
      configObject[z.guid] = z;
    }

    for (let zone of this.zones) {
      //reseting zones :

      zone['visible'] = false;
      zone['color'] = this.colors[0];

      let configedZone = configObject[zone.guid];

      if (configedZone) {
        if (configedZone.visible != null) {
          zone['visible'] = configedZone.visible;

        }

        if (configedZone.color != null) {
          zone['color'] = configedZone.color;

        }
      }
    }

    this.cdr.detectChanges();

  }

  updateZonesProperties() {
    let updates = []

    for (let zone of this.zones) {

      let update = { guid: zone.guid }

      if (zone.visible != null) {

        update['visible'] = zone['visible']

        if (zone['color'] != null) {

          update['color'] = zone['color']
        }
        updates.push(update)
      }
    }


    this.appActionsService.changeZonesProperties.next(updates)

  }

  zoneEditClicked(zone) {
    this.zoneToEdit = {
      guid: zone.guid,
      name: zone.name,
      longName: zone.longName
    }
  }

  async zaveZoneToEdit() {
    await this.dataService.updateOverwrite(this.zoneToEdit.guid,
      {
        name: this.zoneToEdit.name,
        longName: this.zoneToEdit.longName
      }
    )
    this.zoneToEdit = null;
    this.cdr.detectChanges();
  }


  async resetZoneOverwrite() {
    await this.dataService.removeOverwrite(this.zoneToEdit.guid)
    this.zoneToEdit = null;
    this.cdr.detectChanges();
  }


  onOpacityChanged() {
    this.updateViewConfig(); 
    this.appActionsService.globalZonesOpacityChanged.next(this.opacity);
  }


  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }
}
