import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';
import { Subscription, config } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { min } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToolboxEvent } from 'src/models/toolbox-event';
import * as THREE from "../../assets/threejs/three.module.js";

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-materials-editor',
  templateUrl: './materials-editor.component.html',
  styleUrls: ['./materials-editor.component.scss']

})
export class MaterialsEditorComponent implements OnInit, OnDestroy {
  @ViewChild('getFocusLeave', { static: true }) getFocusLeaveElement: ElementRef;

  lastMovedPath = null;
  materials: any[];
  @Input('model') model: any;
  @ViewChild('editButton', { static: false }) editButton: ElementRef;
  subscriptions: Subscription[] = [];

  waitingForTexture = null;
  waitingForMaterial = null;

  configs = null;
  openedConfigKey = null;
  defaultConfig = null;
  configNameEditor = null;
  namesToKeys = {};

  loader = new THREE.TextureLoader();
  mapsDict = {
    map: "albedo",
    aoMap: "ao",
    metalnessMap: "metalness",
    normalMap: "normal-ogl",
    roughnessMap: "roughness"
  }


  //because mat-select selectConfigBox emits twice the event when null value is selected, so to avoid to trigger twice loadDefaultConfig, else error
  semaphoreConfigChangeNull = false

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private http: HttpClient, private cdr: ChangeDetectorRef, public dialog: MatDialog) { }

  ngOnInit() {
    this.configs = this.dataService.materialsConfigs;


    // await new Promise((resolve, reject) => {
    this.subscriptions.push(this.appActionsService.materialsGenerated.subscribe(async () => {
      this.materials = this.dataService.materials;
      for (let material of this.materials) {
        material.color = material.material.color.getStyle();
        material.opacity = material.material.opacity;
        material.roughness = material.material.roughness;
        material.metalness = material.material.metalness;
      }
      this.defaultConfig = this.initConfig();
    }))
    // })
    this.subscriptions.push(this.appActionsService.materialAdded.subscribe((values) => {
      this.namesToKeys[values.name] = values.key;
    }))
    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(async viewConfig => {

      if (viewConfig) {
        if (viewConfig.MaterialsMode) {
          if (viewConfig.MaterialsMode.openedConfigKey) {
            if (viewConfig.MaterialsMode.openedConfigKey != this.openedConfigKey) {
              if (this.configs[viewConfig.MaterialsMode.openedConfigKey]) {
                const id = this.appActionsService.addUserBlockingTask('loadingMaterialCongfig');

                await this.selectConfig(viewConfig.MaterialsMode.openedConfigKey).catch(err => { console.warn('error loading viewconfig') })
                this.appActionsService.materialConfigLoaded.next();
                this.appActionsService.removeUserBlockTask(id)
              }


            }
            return;
          }
        }
      }
      //incase it didnt load config for some reason
      this.loadDefaultConfig()
    }))
    this.subscriptions.push(this.dataService.materialsConfigsUpdated.subscribe(async (update) => { //need this to react to remote changes ( for when the client is a viewer.)
      if (update.type != 'child_changed') {
        return;
      }
      if (this.openedConfigKey == update.key) {
        //this.loadDefaultConfig();
        //this.selectConfig(update.key)
        this.openedConfigKey = update.key;

        await this.loadConfig(this.configs[update.key])
      }
    }))

    this.subscriptions.push(
      this.appActionsService.objectDataAction.subscribe(e => {
        if (e.action == "materialClicked") {
          for (let mi of this.materials) {
            if (mi.material.name == e.materialName) {
              this.appActionsService.materialOpenInList = mi;
            }
          }
        }

        if (e.action == 'materialMouseEnter') {
          for (let mi of this.materials) {
            if (mi.material.name == e.materialName) {
              this.highlightMaterial(mi)
            }
          }
        }

        if (e.action == 'materialMouseLeave') {
          for (let mi of this.materials) {
            if (mi.material.name == e.materialName) {
              this.highlightOffMaterial(mi)
            }
          }
        }


        this.cdr.detectChanges();
        let domElement = document.getElementById('material_' + e.materialName);
        if (domElement) {
          setTimeout(() => {
            domElement.scrollIntoView(false)
          }, 20)
        }
      })
    )
    this.subscriptions.push(
      this.appActionsService.materialClickedOnLibrary.subscribe(material => {

        this.onMaterialClickedOnLibrary(material)
      })
    )





    this.subscriptions.push(
      this.appActionsService.toolboxEvents.subscribe(async (event) => {
        if (event.tool === 'wizard') {

          if (event.updates.createMaterialConfig.type === 'white' && event.updates.createMaterialConfig.status === 'start') {
            await this.createWhiteConfig();
            this.appActionsService.toolboxEvents.next(new ToolboxEvent('wizard', 'updates', this.constructor.name, { createMaterialConfig: { type: 'white', status: 'done' } }))
          }
        }
      })
    )
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  deleteConfigClicked(key) {
    this.appActionsService.materialOpenInList = null;
    this.appActionsService.paintMaterialMode = false;
    if (this.openedConfigKey = key) {
      this.loadDefaultConfig();
    }
    this.dataService.removeMaterialConfig(key)
  }

  onInputKeyDown(e) {
    if (e.key === "Enter") {
      // Do work
      e.target.blur();
    }
  }

  materialConfigNameChanged(key, value) {
    this.configNameEditor = null;
    this.dataService.updateMaterialConfigName(key, value)
  }

  async selectConfig(key) {
    this.appActionsService.materialOpenInList = null;
    this.appActionsService.paintMaterialMode = false;
    if (this.openedConfigKey == key) {
      this.loadDefaultConfig();
    } else {
      //need to undone previous object material swap
      await this.unloadConfig(this.configs[this.openedConfigKey])
      this.openedConfigKey = key;
      //apply new materials and  modification materials from selected config

      await this.addNewMaterialFromConfig(this.configs[key])
      await this.addLinkObjectMaterials(this.configs[key])
      await this.updateAssetMaterialChanges(this.configs[key])
      await this.loadConfig(this.configs[key])
    }

  }

  onConfigSelected(event) {
    if (event.value != null) {
      this.selectConfig(event.value)
    } else {
      if (!this.semaphoreConfigChangeNull) {
        this.semaphoreConfigChangeNull = true
        this.loadDefaultConfig();
      }
    }
  }

  async loadDefaultConfig() {
    if (this.openedConfigKey != null) {
      //need to undone previous object material swap
      await this.unloadConfig(this.configs[this.openedConfigKey])
    }
    this.openedConfigKey = null;
    await this.loadConfig(this.defaultConfig);
    this.semaphoreConfigChangeNull = false;
    this.appActionsService.lastestLoadedMaterialConfig = null;
  }

  async createNewConfig() {
    let newConfig = JSON.parse(JSON.stringify(this.configs[this.openedConfigKey] || this.defaultConfig)); //@mg to create from opened conf

    const currentDate = new Date();
    newConfig.name = 'Config' + currentDate.getTime();
    const key = await this.dataService.addNewMaterialConfig(newConfig)
    this.selectConfig(key)

  }

  async createWhiteConfig() {
    let newConfig = JSON.parse(JSON.stringify(this.configs[this.openedConfigKey] || this.defaultConfig)); //@mg to create from opened conf

    for (const property in newConfig.materials) {

      newConfig.materials[property].color = 'rgb(255,255,255)'

    }

    const currentDate = new Date();
    newConfig.name = 'white config' + currentDate.getTime();


    const key = await this.dataService.addNewMaterialConfig(newConfig)
    await this.selectConfig(key)

  }

  test(e) {
  }

  initConfig() { //Will work only first time, when materials are the original that threejs assigned to the dae model.
    let newConfig = { name: 'new config', materials: {} };
    for (let material of this.materials) {
      let id = UUID.UUID()
      newConfig.materials[id] = {
        name: material.material.name,
        // texture: null,
        userName: material.material.userData.name || 'undefined',
        originalUserName: material.material.userData.name || 'undefined',
        color: material.material.color.getStyle(),
        opacity: material.material.opacity,
        roughness: material.material.roughness,
        metalness: material.material.metalness,
        scale: 1,
        rotation: 0
      }



      if (this.dataService.materialTextureMapping && this.dataService.materialTextureMapping[material.material.uuid]) {
        for (let key in this.dataService.materialsLibrary) {
          const lib_material = this.dataService.materialsLibrary[key];
          if (lib_material.name == this.dataService.materialTextureMapping[material.material.uuid]) {
            newConfig.materials[id].lib_material = key;
          }
        }
      }

      // if (this.dataService.projectTextures[material.material.uuid]) {


      //   // newConfig.materials[id].textureId = this.dataService.projectTextures[material.material.uuid].materialId;


      //   // console.log(this.dataService.projectTextures[material.material.uuid].url)
      //   // material.material.map = this.dataService.projectTextures[material.material.uuid].url;
      //   // material.material.needsUpdate = true;

      // }

    }
    return newConfig;
  }

  saveOpenedConfigKeyToViewConfig() {
    this.dataService.viewConfig.MaterialsMode = { openedConfigKey: this.openedConfigKey }
  }

  saveToOpenedConfig(materialItem) {
    let update = {};
    update[this.namesToKeys[materialItem.material.name]] = {
      name: materialItem.material.name,
      textureId: materialItem.textureId ? materialItem.textureId : null,
      userName: materialItem.material.userData.name,
      originalUserName: materialItem.material.userData.originalName || 'undefined',
      color: materialItem.material.color.getStyle(),
      opacity: materialItem.opacity,
      scale: materialItem.scale,
      rotation: materialItem.rotation,
      roughness: materialItem.roughness,
      metalness: materialItem.metalness,
      enableEnvMap: materialItem.enableEnvMap || false,
      lib_material: materialItem.lib_material ? materialItem.lib_material : null
    }
    if (this.openedConfigKey) {
      this.dataService.updateMaterialInConfig(this.openedConfigKey, update);
    }
  }



  changeRotationForThreejsOnly(materialItem, value) {
    this.updateRotationForAllTextures(materialItem.material, value)

    this.appActionsService.materialsUpdated.next();
  }



  changeMaterialOpacity(materialItem, value, saveToConfig?) {
    materialItem.material.opacity = value;
    materialItem.material.transparent = value < 1 ? true : false;
    materialItem.material.depthWrite = value < 1 ? false : true;
    this.appActionsService.materialsUpdated.next();
    this.appActionsService.materialOpacityUpdated.next();
    this.appActionsService.changeCastShadowsObjects.next({ objects: materialItem.usedByObjects, value: value < 0.8 ? false : true })
    materialItem.opacity = value;
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }
  }

  changeEnableEnvMap(materialItem, value, saveToConfig?) {
    materialItem.material.enableEnvMap = value;
    if (saveToConfig) {
      this.saveToOpenedConfig(materialItem)
    }


  }


  changeMaterialMetalnessForThreejsOnly(materialItem, value) {
    materialItem.material.metalness = value;
    this.appActionsService.materialsUpdated.next();
  }
  changeMaterialRoughnessForThreejsOnly(materialItem, value) {
    materialItem.material.roughness = value;
    this.appActionsService.materialsUpdated.next();
  }

  changeMaterialMetalness(materialItem, value, saveToConfig?) {
    this.changeMaterialMetalnessForThreejsOnly(materialItem, value)
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }

  }
  changeMaterialRoughness(materialItem, value, saveToConfig?) {
    this.changeMaterialRoughnessForThreejsOnly(materialItem, value)
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }
  }

  changeMaterialOpacityForThreejsOnly(materialItem, value) {
    materialItem.material.opacity = value;
    materialItem.material.transparent = value < 1 ? true : false;
    materialItem.material.depthWrite = value < 1 ? false : true;
    this.appActionsService.materialsUpdated.next();
  }

  changeMaterialColor(materialItem, color) {
    materialItem.material.color.set(color)
    this.appActionsService.materialsUpdated.next();
  }




  changeScaleForThreejsOnly(materialItem, value) {
    this.updateScaleForAllTextures(materialItem.material, value, materialItem.mapsRatios)
    this.appActionsService.materialsUpdated.next();

  }


  changeScale(materialItem, scale, saveToConfig?) {
    this.changeScaleForThreejsOnly(materialItem, scale)
    materialItem.scale = scale;
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }
  }

  changeRotation(materialItem, rotation, saveToConfig?) {
    this.changeRotationForThreejsOnly(materialItem, rotation)
    materialItem.rotation = rotation;
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }
  }





  materialLabelClicked(materialItem) {
    if (this.appActionsService.materialOpenInList == materialItem) {
      this.appActionsService.materialOpenInList = null
      this.appActionsService.paintMaterialMode = false;
    } else {
      this.appActionsService.materialOpenInList = materialItem;
    }
    for (let mi of this.materials) {
      if (mi !== materialItem) {
        mi['highlight'] = false;

      }

    }
  }

  transparentAllMaterial(exclude?) {
    if (exclude == null) {
      exclude = [];
    }

    for (let materialItem of this.materials) {
      if (exclude.indexOf(materialItem) == -1) {
        if (!materialItem.transparent) {
          materialItem['originalOpacity'] = materialItem.material.opacity;
          materialItem['originalTransparency'] = materialItem.material.transparent;
          materialItem['transparent'] = true;
          materialItem.material.transparent = true;
          materialItem.material.opacity = 0.1
        }
      }
    }
    this.appActionsService.materialsUpdated.next();
  }

  highlightMaterial(materialItem) {
    materialItem.highlight = true;
    this.transparentAllMaterial([materialItem]);
  }

  returnAllMaterialsTocolor() {
    for (let materialItem of this.materials) {
      if (materialItem.transparent) {
        materialItem['transparent'] = false;
        materialItem.material.transparent = materialItem.originalTransparency;
        materialItem.material.opacity = materialItem.originalOpacity;
      }
    }
  }


  highlightOffMaterial(materialItem) {
    materialItem.highlight = false;
    this.returnAllMaterialsTocolor();
    this.appActionsService.materialsUpdated.next();
    this.appActionsService.materialOpacityUpdated.next();
  }

  materialNameChanged(materialItem, value) {
    this.saveToOpenedConfig(materialItem)
  }



  stopEnterKeyPress(e) {
    if (e.which == 13) {
      e.target.blur();
    }
  }

  removeTextureFromMaterial(materialItem, saveToConfig?) {

    materialItem.material.map = null;
    materialItem.material.needsUpdate = true;
    materialItem.textureId = null;
    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }
  }

  //TODO ben
  unloadConfig(config) {
    return new Promise(async (resolve, reject) => {
      if (!config) {
        resolve(null)
      }
      //remove link object/material
      await this.unloadLinkObjectMaterials(config)
      //remove new material
      await this.unloadNewMaterials(config)
      resolve(true)
    })
  }

  unloadNewMaterials(config) {
    return new Promise((resolve, reject) => {
      if (config && 'newMaterials' in config) {
        for (let newMaterialsKey in config.newMaterials) {
          this.materials.pop()
          delete this.namesToKeys[config.newMaterials[newMaterialsKey].name]
        }
      }
      resolve(true)
    })
  }
  unloadLinkObjectMaterials(config) {


    this.appActionsService.objectCustomMaterialReset.next()
    this.appActionsService.materialsUpdated.next()

  }

  loadConfig(config) {

    const materialLoads = []

    return new Promise(async (resolve, reject) => {
      if (config == null) {
        resolve(null);
      }

      let c = 0;
      for (let key in config.materials) {
        this.namesToKeys[config.materials[key].name] = key;
        c++;
      }


      for (let indexMaterialItem = 0; indexMaterialItem < this.materials.length; indexMaterialItem++) {
        const materialItem = this.materials[indexMaterialItem]
        let configedMaterial = config.materials[this.namesToKeys[materialItem.material.name]]


        if (configedMaterial && (this.namesToKeys[materialItem.material.name] != undefined)) {

          materialItem.material.color.set(configedMaterial.color)
          materialItem.color = configedMaterial.color;
          materialItem.scale = configedMaterial.scale || 1;
          materialItem.rotation = configedMaterial.rotation || 0;
          materialItem.material.userData.name = configedMaterial.userName;
          materialItem.enableEnvMap = configedMaterial.enableEnvMap;
          materialItem.roughness = configedMaterial.roughness;
          materialItem.metalness = configedMaterial.metalness;


          this.changeMaterialMetalness(materialItem, materialItem.metalness)
          this.changeMaterialRoughness(materialItem, materialItem.roughness)
          this.changeEnableEnvMap(materialItem, materialItem.enableEnvMap)


          if (configedMaterial.lib_material) {//incase config has  lib_material 
            if (materialItem.lib_material !== configedMaterial.lib_material) { //reload only if different material (not loaded already)
              materialLoads.push(
                this.loadLibMaterial(materialItem, configedMaterial.lib_material).catch(err => {
                  console.warn('Error loading material ' + configedMaterial.lib_material)
                  this.removeLibMaterial(materialItem)
                })
              )

            }
          } else { //incase config has no lib_material set
            if (materialItem.lib_material) { //unload if previously set a lib_material
              this.removeLibMaterial(materialItem)
            }
          }





          if (configedMaterial.opacity !== null) {
            this.changeMaterialOpacity(materialItem, configedMaterial.opacity)
          }

          this.saveOpenedConfigKeyToViewConfig();
        }
      }




      await Promise.all(materialLoads);

      //ask to update it to 3JS
      this.appActionsService.materialsUpdated.next()
      //notify new config selected
      this.appActionsService.selectedMaterialsConfig.next(config.name)
      resolve(true)
    });
  }

  addNewMaterialFromConfig(config) {
    return new Promise((resolve, reject) => {
      if (!config) resolve(false)
      if ('newMaterials' in config) {
        for (let key in config.newMaterials) {
          //create the material
          let material = config.materials[key]
          let material3JS = new THREE.MeshStandardMaterial({ side: THREE.DoubleSide })
          material3JS.name = material.name
          material3JS.uuid = material.name
          material3JS.userData.name = material.userName
          const materialToAddToService = {
            material: material3JS,
            color: material.color,
            opacity: material.opacity,
            roughness: material.roughness,
            metalness: material.metalness,
            rotation: 0,
            scale: 1,
            textureId: null,
            usedByObjects: []
          }
          this.materials.push(materialToAddToService)
        }
        this.appActionsService.updateMaterialsForSingleMesh.next()
        resolve(true)
      }
      else {
        resolve(false)
      }
    })
  }

  addLinkObjectMaterials(config) {
    return new Promise((resolve, reject) => {

      if (!config) resolve(false)
      if ('LinkObjectMaterials' in config) {
        for (let link of config.LinkObjectMaterials) {
          const materialUuidToChange = link.materialUuidToChange
          const oid = link.oid
          const newMaterialIndex = link.newMaterialIndex
          const previousMaterialIndex = link.previousMaterialIndex

          if (!('usedByObjects' in this.materials[newMaterialIndex])) {
            this.materials[newMaterialIndex].usedByObjects = []
          }
          this.materials[newMaterialIndex].usedByObjects.push(oid)
          const indexOid = this.materials[previousMaterialIndex].usedByObjects.indexOf(oid);
          if (indexOid > -1) {
            this.materials[previousMaterialIndex].usedByObjects.splice(indexOid, 1)
          }
          //apply to the current threejs session

          if (this.dataService.objectsData[oid]) { //we check if exist incase of object removed in ifc update
            if (Array.isArray(this.dataService.objectsData[oid].material)) {
              //test if the object is an array or single
              //potentially the material is multiple times in the material array
              for (let index = 0; index < this.dataService.objectsData[oid].material.length; index++) {
                if (this.dataService.objectsData[oid].material[index].uuid == materialUuidToChange) {
                  this.dataService.objectsData[oid].material[index] = this.materials[newMaterialIndex].material
                }
              }
            }
            else {
              this.dataService.objectsData[oid].material = this.materials[newMaterialIndex].material;
            }
          }


          this.appActionsService.objectCustomMaterialUpdated.next(link)


        }


        resolve(true)
      }
      else {
        resolve(true)
      }
    })
  }

  async updateAssetMaterialChanges(config) {
    if (!config) {
      throw new Error("no config")
    }

    if ('assetsMaterialsChanges' in config) {
      for (let modelId in config['assetsMaterialsChanges']) {
        const changes = config['assetsMaterialsChanges'][modelId]
        this.appActionsService.assetCustomMaterialUpdate.next({
          modelId, changes
        })
      }
    }
  }

  createNewConfigClicked(select) {
    this.createNewConfig()
    select.close()

  }







  openDialog(): void {
    const dialogRef = this.dialog.open(EditNameDialog, {
      width: '250px',
      data: { name: this.configs[this.openedConfigKey].name }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.editButton['_elementRef'].nativeElement.classList.remove('cdk-focused')
      this.editButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused')
      if (result != undefined) {
        if ((/\S/.test(result))) {
          this.materialConfigNameChanged(this.openedConfigKey, result)
        }
      }
    });
  }



  browseMaterials(material) {

    if (material.lib_material) {
      const lib_mat = this.dataService.materialsLibrary[material.lib_material];
      if (lib_mat) {

        this.appActionsService.materialsLibrarySelectedCategory = lib_mat.categories ? lib_mat.categories[0] : null;
      }
    }

    this.appActionsService.materialsLibraryOpen = true;
    this.waitingForMaterial = material
  }

  closeBrowserMaterials() {
    this.appActionsService.materialsLibraryOpen = false;
    this.waitingForMaterial = null;
  }



  async onMaterialClickedOnLibrary(lib_material_key) {
    if (!this.dataService.canAddAsset(this.dataService.materialsLibrary[lib_material_key].size, 'material', lib_material_key)) {
      this.closeBrowserMaterials();
      this.appActionsService.notify(
        'storage.snackbackMaterialProjectSizeError',
        "warn",
        10)
      return;
    }

    if (this.dataService.materialsLibrary[lib_material_key]) {
      const material = this.waitingForMaterial.material;
      const materialItem = this.waitingForMaterial;
      this.closeBrowserMaterials();
      await this.loadLibMaterial(materialItem, lib_material_key)

      //setup default params and save
      materialItem.roughness = material.roughness = this.dataService.materialsLibrary[lib_material_key].roughness != null ? this.dataService.materialsLibrary[lib_material_key].roughness : 1;
      materialItem.metalness = material.metalness = this.dataService.materialsLibrary[lib_material_key].metalness != null ? this.dataService.materialsLibrary[lib_material_key].metalness : material.metalnessMap ? 1 : 0;
      materialItem.scale = this.dataService.materialsLibrary[lib_material_key].scale || 1;
      materialItem.rotation = this.dataService.materialsLibrary[lib_material_key].rotation || 0;
      materialItem.enableEnvMap = this.dataService.materialsLibrary[lib_material_key].enableEnvMap || false;

      this.changeScale(materialItem, materialItem.scale)
      this.changeMaterialColor(materialItem, 'white')
      this.changeRotation(materialItem, materialItem.rotation) //not sure this is required, because maybe we want to keep the rotation set before?
      this.saveToOpenedConfig(materialItem)
    }


  }

  removeLibMaterial(materialItem, saveToConfig?) {

    materialItem.lib_material = null;
    const material = materialItem.material;
    material.map = null;
    material.metalnessMap = null;
    material.normalMap = null;
    material.roughnessMap = null;
    material.aoMap = null;
    materialItem.roughness = 1;
    materialItem.metalness = 0;
    materialItem.enableEnvMap = false;
    material.normalMaterialNeedsUpdate = true;
    materialItem.material.needsUpdate = true;


    if (saveToConfig) {
      this.saveToOpenedConfig(materialItem)
    }

  }

  async loadLibMaterial(materialItem, lib_material_key) {

    var loader = new THREE.TextureLoader();
    const lib_material = await this.dataService.downloadMaterial(lib_material_key);





    const material = materialItem.material
    //todo, map it better already in dataserice.. so texturematerial will include the map without wierd names. and build it with a loop/or maps etc..

    // debugger;

    const loadPromises = [];
    for (let map in this.mapsDict) {

      if (lib_material.maps[this.mapsDict[map]]) {

        loadPromises.push(
          new Promise((resolve, reject) => {
            material[map] = loader.load(lib_material.maps[this.mapsDict[map]],
              tex => {
                resolve()
              },
              null,
              err => {
                resolve()
              }
            )
          })
        )



        material[map].wrapS = material[map].wrapT = THREE.RepeatWrapping;
      } else {
        material[map] = null;
      }

    }

    materialItem.mapsRatios = this.dataService.materialsLibrary[lib_material_key].mapsRatios || null;

    this.changeScale(materialItem, materialItem.scale)
    this.changeRotation(materialItem, materialItem.rotation)
    material.normalMaterialNeedsUpdate = true;
    materialItem.material.needsUpdate = true;

    materialItem.lib_material = lib_material_key; // this also means its done.



    await Promise.all(loadPromises);





  }



  updateRotationForAllTextures(material, value) {
    value = Number(value);

    if (material.map) material.map.rotation = Math.PI * value / 180;
    if (material.normalMap) material.normalMap.rotation = Math.PI * value / 180;
    if (material.metalnessMap) material.metalnessMap.rotation = Math.PI * value / 180;
    if (material.roughnessMap) material.roughnessMap.rotation = Math.PI * value / 180;
    if (material.aoMap) material.aoMap.rotation = Math.PI * value / 180;
  }

  updateScaleForAllTextures(material, value, mapsRatios) {
    value = Number(value);
    //todo: calc strech factor correctly and strech.

    for (let map in this.mapsDict) {
      if (material[map]) {
        let stretch = 1;
        const ratios = mapsRatios ? mapsRatios[this.mapsDict[map]] : null;
        if (ratios && ratios.originalRatio && ratios.finalRatio) {
          stretch = ratios.originalRatio / ratios.finalRatio
        }

        material[map].repeat.set(1 / (value * stretch), 1 / value)
      }
    }
    // if (material.map) material.map.repeat.set(1 / value, 1 / value )
    // if (material.normalMap) material.normalMap.repeat.set(1 / value, 1 / value)
    // if (material.metalnessMap) material.metalnessMap.repeat.set(1 / value, 1 / value)
    // if (material.roughnessMap) material.roughnessMap.repeat.set(1 / value, 1 / value)
    // if (material.aoMap) material.aoMap.repeat.set(1 / value, 1 / value)
  }



  togglePaintMaterialMode() {
    this.appActionsService.paintMaterialMode = !this.appActionsService.paintMaterialMode;
  }

  //get events to avoid propagation to 3JS and trigger unwanted action
  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event?) {
    this.getFocusLeaveElement.nativeElement.focus()
  }
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'z', 'Z', 'w', 'W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }


}

@Component({
  selector: 'edit-name-dialog',
  templateUrl: 'edit-name-dialog.html',
})

export class EditNameDialog implements OnInit {
  nameIsValid = null

  constructor(
    public dialogRef: MatDialogRef<EditNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.checkName()
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  checkName() {
    this.nameIsValid = (/\S/.test(this.data.name))
  }



}
