import {
  DirectionalLight,
  HemisphereLight,
  MeshStandardMaterial,
  SphereGeometry,
  Mesh,
  Group,
  Vector3,
  Object3D,
  Box3,
  Color,
  Euler,
  ACESFilmicToneMapping,
  NoToneMapping,
  AdditiveBlending,
  PCFSoftShadowMap,
  CameraHelper,
  BoxHelper,
  MeshBasicMaterial,
  PlaneGeometry,
  MeshPhongMaterial,
  DirectionalLightHelper
} from "../threejs/three.module.js"
import { Subscription } from "rxjs";
import { TransformControls } from "./TransformControls.js";
import { Meuus } from "./meuusjs.1.0.3.min.jsm"
import { Sky } from "../threejs/objects/Sky.js";

class DirectionalLightBimshow {
  constructor(threeJSComponent) {
    this.sky = null;
    this.skyEffectController = {
      turbidity: 0.1,
      rayleigh: 3,
      mieCoefficient: 0.025,
      mieDirectionalG: 0.963,
      elevation: 35,
      azimuth: 180,
      up: new Vector3(0, 0, 1)
    };


    this.threeJSComponent = threeJSComponent
    this.threeJSComponent.renderer.shadowMap.enabled = true;
    this.threeJSComponent.renderer.shadowMap.type = PCFSoftShadowMap
    this.threeJSComponent.renderer.shadowMap.autoUpdate = false;
    this.directionalLight = new DirectionalLight()
    this.directionalLight.castShadow = true;

    this.directionalLight.shadow.bias = -0.001
    
    this.directionalLight.shadow.mapSize.width = 4096
    this.directionalLight.shadow.mapSize.height = 4096
    this.directionalLight.shadow.camera.near = 1
    this.directionalLight.shadow.camera.far = 100
    this.directionalLight.shadow.camera.left = 100
    this.directionalLight.shadow.camera.right = -100
    this.directionalLight.shadow.camera.top = 100
    this.directionalLight.shadow.camera.bottom = -100


    this.radius = 1000;





    threeJSComponent.scene.add(this.directionalLight.target)
    threeJSComponent.scene.add(this.directionalLight)

    this.directionalLightShadowHelper = new CameraHelper(this.directionalLight.shadow.camera);
    threeJSComponent.scene.add(this.directionalLightShadowHelper)


    this.directionalLightHelper = new DirectionalLightHelper(this.directionalLight);
    threeJSComponent.scene.add(this.directionalLightHelper)



    this.sphere = new Mesh(new SphereGeometry(10, 32, 32), new MeshBasicMaterial({ color: 'red' }));
    // threeJSComponent.scene.add(this.sphere)
    this.sphere.castShadow = true;
    this.sphere.position.set(0, 0, 8)

    this.plane = new Mesh(new PlaneGeometry(100, 100, 1, 1), new MeshPhongMaterial({ color: 'green' }))
    this.plane.receiveShadow = true;
    // threeJSComponent.scene.add(this.plane)


    this.hideHelpers();

  }

  getDefaultTarget() {
    return { x: 0, y: 0, z: 0 }
  }

  update(options) {
  
    this.directionalLight.color.setStyle(options.color || '#FFFFFF');

    this.directionalLight.intensity = options.intensity;


    if (options.target) {
      this.directionalLight.target.position.set(options.target.x, options.target.y, options.target.z)
    } else {
      this.directionalLight.target.position.set(0, 0, 0)
    }

    if (options.radius) {
      this.radius = options.radius;
    } else {
      this.radius = 1000;
    }

    

    if (options.positioningMode === 'manual') {
      this.updateLightPosition(options.azimuth * Math.PI / 180, options.elevation * Math.PI / 180)
    }
    else {
      this.updateLightPositionFromDate(options.date + ':00' + this.threeJSComponent.appActionsService.currentTimezone, this.threeJSComponent.dataService.siteData.longitude, this.threeJSComponent.dataService.siteData.latitude)
    }

    if (options.shadowCameraSize) {
      const d = options.shadowCameraSize;
      this.directionalLight.shadow.camera.left = d / 2;
      this.directionalLight.shadow.camera.right = -d / 2;
      this.directionalLight.shadow.camera.top = d / 2;
      this.directionalLight.shadow.camera.bottom = -d / 2;

      this.directionalLight.shadow.bias = -d/400000;

    } else {
      const d = 100;
      this.directionalLight.shadow.camera.left = d / 2;
      this.directionalLight.shadow.camera.right = -d / 2;
      this.directionalLight.shadow.camera.top = d / 2;
      this.directionalLight.shadow.camera.bottom = -d / 2;
      this.directionalLight.shadow.bias = -d/400000;
    }




    this.directionalLight.castShadow = !!options.castShadow;

    if (options.enabled === false) {
      this.directionalLight.castShadow = false;
      this.directionalLight.intensity = 0;
    }


    if (options.sky) {
      this.addSky()
      this.updateSkyBySunPosition()
    } else {
      this.removeSky();
    }




    this.directionalLight.shadow.camera.far = this.directionalLight.position.distanceTo(this.directionalLight.target.position) + 2 * this.radius;
    this.updateShadows()
    this.threeJSComponent.render();
    this.threeJSComponent.askForRender(); //i ask again for some reason its required for the shadow camera
  }


  updateLightPositionFromDate(date, lon, lat) {

    const meuus = new Meuus()
    var jdo = new meuus.engine.JulianDay(new Date(date));
    var coord = meuus.engine.EclCoord.fromWgs84(
      lat,
      lon
    );


    // gets the position of the sun
    var tp = meuus.engine.Solar.topocentricPosition(jdo, coord, true)


    let theta = tp.hz.az + Math.PI;
    let phi = tp.hz.alt;

    this.updateLightPosition(theta, phi)
  }

  updateLightPosition(azimuth, alt) {




    //theta measured from X angle in anti-clockwise
    let theta = Math.PI / 2 + - (azimuth  + Math.PI*this.threeJSComponent.dataService.siteData.rotate/180);
    let phi = (Math.PI / 2) - alt
    const r = this.radius;

    const n = new Vector3(
      Math.cos(theta) * Math.sin(phi),
      Math.sin(theta) * Math.sin(phi),
      Math.cos(phi)

    )

    this.directionalLight.position.copy(this.directionalLight.target.position.clone().add(n.multiplyScalar(r)));


  }



  updateShadowMap() {
    this.threeJSComponent.renderer.shadowMap.needsUpdate = true;
    this.directionalLight.shadow.needsUpdate = true
   
  }

  reset() {

  }

  getDirectionalLightOptions() {

  }

  updateShadows() {

    this.directionalLight.updateMatrixWorld()
    this.directionalLight.target.updateMatrixWorld();
    this.directionalLight.shadow.camera.updateProjectionMatrix()
    this.directionalLight.shadow.camera.updateMatrixWorld()

    this.directionalLightHelper.update();
    this.directionalLightShadowHelper.update()
    this.directionalLightShadowHelper.updateMatrixWorld()
    this.updateShadowMap();

  }

  showHelpers() {
    this.directionalLightHelper.visible = true;
    this.directionalLightShadowHelper.visible = true;
    this.threeJSComponent.askForRender()
  }

  hideHelpers() {
    this.directionalLightHelper.visible = false;
    this.directionalLightShadowHelper.visible = false;
    this.threeJSComponent.askForRender()
  }

  displayShadows(value) {
    this.directionalLight.castShadow = value;

  }


  setShadowsStatus() {

  }

  addSky() {
    if (
      (this.sky == null || this.sky == undefined)
    ) {

      

      this.sky = new Sky()
      this.sky.scale.setScalar(100000)
      this.threeJSComponent.scene.add(this.sky)

      let sun = new Vector3()

      const uniforms = this.sky.material.uniforms
      uniforms['turbidity'].value = this.skyEffectController.turbidity
      uniforms['rayleigh'].value = this.skyEffectController.rayleigh
      uniforms['mieCoefficient'].value = this.skyEffectController.mieCoefficient
      uniforms['mieDirectionalG'].value = this.skyEffectController.mieDirectionalG
      uniforms['up'].value = this.skyEffectController.up


      sun.x = 1;
      sun.y = 1;
      sun.z = 1;
      sun.normalize();

      uniforms['sunPosition'].value.copy(sun)



      this.threeJSComponent.askForRender()
      this.threeJSComponent.render()
      
    }
  }
  removeSky() {
    if (this.sky) {

      this.sky.geometry.dispose();
      this.sky.material.dispose();
      this.threeJSComponent.scene.remove(this.sky);
      this.sky = null
      this.threeJSComponent.askForRender()
      this.threeJSComponent.render()
    }
  }
  updateSkyBySunPosition() {
    if (this.sky) {
      let sun = this.directionalLight.position.clone().sub(this.directionalLight.target.position).normalize();


      const uniforms = this.sky.material.uniforms;
      uniforms['sunPosition'].value.copy(sun)
      this.threeJSComponent.render()


      const elevation = (new Vector3(sun.x, sun.y, 0)).normalize().angleTo(sun);
      const rayleigh_min = 0.36;
      const rayleigh_max = 2.5;
      uniforms.rayleigh.value = rayleigh_min + (rayleigh_max - rayleigh_min) * Math.cos(elevation) * Math.exp(-10 * elevation)


    }
  }

}

export { DirectionalLightBimshow };
