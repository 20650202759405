import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewButton } from 'src/models/viewButton.model';
import { debounce } from 'lodash';

@Component({
  selector: 'app-rooms-editor',
  templateUrl: './rooms-editor.component.html',
  styleUrls: ['./rooms-editor.component.scss']
})
export class RoomsEditorComponent implements OnInit {

  @Input('rooms') rooms: ViewButton["rooms"];
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  addRoom() {
    this.rooms.push({name:"",area:1});
    this.changed.emit();
  }

  removeRoom(i) {
    this.rooms.splice(i,1)
    this.emitChanged();
  }

  emitChanged() {
    console.log('emit changed!')
    this.changed.emit();
  }

  onInput() {
    this.emitChanged();
  }

}
