import { Injectable } from "@angular/core";
import { IFCLoader } from "./modules/IFCLoader.js";
import { IFCBUILDINGSTOREY, IFCSITE, IfcAPI } from "./modules/web-ifc";


//import * as THREE from "three";
import * as  BufferGeometryUtils from "../assets/threejs/utils/BufferGeometryUtils.js";
import { geometryTypes } from './../assets/threejs/loaders/geometryTypes';
// import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";

// Sets up optimized picking

import * as THREE from "../assets/threejs/three.module.js";

const ifcLoader: any = new IFCLoader();
ifcLoader.ifcManager.setWasmPath("assets/threejs/");
// ifcLoader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
const ifcapi = new IfcAPI();
ifcapi.SetWasmPath("assets/threejs/");


let modelID = null;

@Injectable({
  providedIn: "root",
})
export class ModelImportService {
  ifcPlainText: any;
  constructor() { }



  /////////////////@mg

  signedVolumeOfTriangle(p1, p2, p3) {

    return p1.dot(p2.cross(p3)) / 6.0;
  }


  getVolume(geometry) {
    if (!geometry.isBufferGeometry) {
      // console.log("'geometry' must be an indexed or non-indexed buffer geometry");
      return 0;
    }
    var isIndexed = geometry.index !== null;
    let position = geometry.attributes.position;
    let sum = 0;
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();
    if (!isIndexed) {
      let faces = position.count / 3;
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0);
        p2.fromBufferAttribute(position, i * 3 + 1);
        p3.fromBufferAttribute(position, i * 3 + 2);
        sum += this.signedVolumeOfTriangle(p1, p2, p3);
        // console.log('%c POINTS','background:blue',p1,p2,p3)
      }
    }
    else {
      let index = geometry.index;
      let faces = index.count / 3;
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, index.array[i * 3 + 0]);
        p2.fromBufferAttribute(position, index.array[i * 3 + 1]);
        p3.fromBufferAttribute(position, index.array[i * 3 + 2]);
        sum += this.signedVolumeOfTriangle(p1, p2, p3);
      }
    }

    return sum;
  }


  async LoadOriginData(file) {

    var ifcURL = URL.createObjectURL(file);

    fetch(ifcURL)
      .then((response) => response.text())
      .then((data) => {
        // This will send the file data to our LoadFileData method
        // this.LoadFileData(data);
        this.LoadOriginDataNow(data)


      });
  }
  async LoadOriginDataNow(ifcAsText) {
    const uint8array = new TextEncoder().encode(ifcAsText);
    modelID = await this.OpenIfc(uint8array);
    let site = ifcapi.GetLineIDsWithType(modelID, IFCSITE);
    let loca = ifcapi.GetLine(modelID, site.get(0)); //@
    let num = Number(loca.ObjectPlacement.value)
    let location = await ifcLoader.ifcManager.getItemProperties(modelID, num, true)
    let num2 = location.RelativePlacement.expressID
    let point = ifcapi.GetLine(modelID, num2); //@
    let num3 = Number(point.Location.value)
    let point2 = ifcapi.GetLine(modelID, num3); //@

    for (let i = 0; i < 1; i++) {
      const lvl = ifcapi.GetLine(modelID, num3);
      // if (lvl.Name.value === "NIVEAU 0") {
      lvl.Coordinates[0].value = 0;
      lvl.Coordinates[1].value = 0;
      lvl.Coordinates[2].value = 0;
      ifcapi.WriteLine(modelID, lvl);
  
      this.createDownloadLink(lvl);   // download new IFC file
    }
    // console.log('%c IFCDATA LOCATION', 'background:brown', loca)
    // console.log('%c IFCDATA LOCATION', 'background:brown', ifcAsText)
    this.ifcPlainText = ifcAsText
    debugger
    return this.ifcPlainText
  }

  async LoadFileData(ifcAsText) {
    const uint8array = new TextEncoder().encode(ifcAsText);
    // console.log(ifcAsText);  
    modelID = await this.OpenIfc(uint8array);
    // const allItems = this.GetAllItems(modelID);
    // const result = JSON.stringify(allItems, undefined, 2);
    // console.log(result) 
    let levels = ifcapi.GetLineIDsWithType(modelID, IFCBUILDINGSTOREY);



    for (let i = 0; i < levels.size(); i++) {
      const lvl = ifcapi.GetLine(modelID, levels.get(i));
      // if (lvl.Name.value === "NIVEAU 0") {

      lvl.Name.value = "NIVEAU" + i
      ifcapi.WriteLine(modelID, lvl);

      // } else {
      //   console.log(lvl);
      // this.createDownloadLink(lvl);   // download new IFC file
      // }
    }
  }





  GetAllItems(modelID, excludeGeometry = true) {
    const allItems = {};
    const lines = ifcapi.GetAllLines(modelID);
    this.getAllItemsFromLines(modelID, lines, allItems, excludeGeometry);
    return allItems;
  }

  getAllItemsFromLines(modelID, lines, allItems, excludeGeometry) {

    for (let i = 1; i <= lines.size(); i++) {
      try {
        this.saveProperties(modelID, lines, allItems, excludeGeometry, i);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  saveProperties(modelID, lines, allItems, excludeGeometry, index) {
    const itemID = lines.get(index);
    const props = ifcapi.GetLine(modelID, itemID);
    props.type = props.__proto__.constructor.name;
    if (!excludeGeometry || !geometryTypes.has(props.type)) {
      allItems[itemID] = props;
    }
  }

  async OpenIfc(ifcAsText) {
    await ifcapi.Init();
    return ifcapi.OpenModel(ifcAsText);
  }

  async createDownloadLink(lvl) {
    const uint8array = new TextEncoder().encode(lvl);
    const data = ifcapi.ExportFileAsIFC(modelID);
    const blob = new Blob([data]);
    const file = new File([blob], "modified.ifc");

    this.ifcPlainText = new TextDecoder().decode(data)


    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.innerText = "Download";
    link.download = "modified.ifc";
    link.setAttribute("href", url);
    link.click()
    window.URL.revokeObjectURL(url);

  }


  async loadIfcModel(file) {
    // this.LoadOriginData(file) //@mg to change origin data, for now write a new file....
    try {

      var ifcURL = URL.createObjectURL(file);
      // const result = await this.LoadFileData(file)
      // console.log(file,result)
      let ifcModel = await ifcLoader.loadAsync(ifcURL);
      // console.log(ifcModel)
      const modelID = ifcModel.mesh.modelID;
      const ifcProject = await ifcLoader.ifcManager.getSpatialStructure(
        modelID
      );



      const matGeos = ifcLoader.ifcManager.parser.geometriesByMaterials;

      const meshes = {};

      // IFCRELASSOCIATESMATERIAL
      const relassociates = await ifcLoader.ifcManager.getAllItemsOfType(0, 2655215786, true);
      // console.log('%c IFCRELASSOCIATESMATERIAL', 'background:orange', relassociates)
      const mats = relassociates.map(mat => (mat.RelatingMaterial.value))

      const objs = relassociates.map(mat => (mat.RelatedObjects || "nul"))

      //  console.log(mats)
      //  console.log(objs)


      let volumes = []   //@mg

      const materialUnique = new THREE.MeshLambertMaterial({
        color: "#000fff",
        side: THREE.DoubleSide,
        userData: { name: "too much materials", originalName: "Material IFC" }
      });

      if (Object.keys(matGeos).length > 120) { //@mg to fix files with too large amount of materials
        // console.log("too much materials,please check ifc model")
        for (let key in matGeos) {
          let { material, geometries } = matGeos[key];
          material = materialUnique


          for (let geometry of geometries) {
            const expressId = geometry.attributes.expressID.array[0];
            let mesh = meshes[expressId];

            if (mesh == null) {
              mesh = new THREE.Mesh(geometry, [material]);
              mesh.name = expressId;
              meshes[expressId] = mesh;

              mesh.geometry.addGroup(0, mesh.geometry.index.count, 0);
            } else {
              //if mesh already exist

              const geoLengthBeforeAdd = mesh.geometry.index.count;
              const mergedGeo = BufferGeometryUtils.mergeGeometries([
                mesh.geometry,
                geometry,
              ]);

              mergedGeo.userData = {};
              mesh.geometry.groups.forEach((group) => {
                mergedGeo.addGroup(group.start, group.count, group.materialIndex);
              });

              mesh.geometry = mergedGeo;

              mesh.material.push(material);
              mesh.geometry.addGroup(
                geoLengthBeforeAdd,
                geometry.index.count,
                mesh.material.length - 1
              );
            }
          }
        }
      } else {

        for (let key in matGeos) {
          const { material, geometries } = matGeos[key];

          material.name = material.uuid;

          for (let geometry of geometries) {
            const expressId = geometry.attributes.expressID.array[0];
            let mesh = meshes[expressId];

            if (mesh == null) {
              mesh = new THREE.Mesh(geometry, [material]);
              mesh.name = expressId;
              meshes[expressId] = mesh;

              mesh.geometry.addGroup(0, mesh.geometry.index.count, 0);
            } else {
              //if mesh already exist

              const geoLengthBeforeAdd = mesh.geometry.index.count;

              const mergedGeo = BufferGeometryUtils.mergeGeometries([
                mesh.geometry,
                geometry,
              ]);

              mergedGeo.userData = {};

              mesh.geometry.groups.forEach((group) => {
                mergedGeo.addGroup(group.start, group.count, group.materialIndex);
              });

              mesh.geometry = mergedGeo;

              mesh.material.push(material);
              mesh.geometry.addGroup(
                geoLengthBeforeAdd,
                geometry.index.count,
                mesh.material.length - 1
              );
            }
          }
        }
      }



      for (let eid in meshes) {
        const bufferGeometry = meshes[eid].geometry;

        volumes.push([this.getVolume(bufferGeometry), meshes[eid].userData])//@mg

      }
      volumes.sort((a, b) => parseFloat(a) - parseFloat(b));



      //console.table(volumes)//@mg

      const model = new THREE.Object3D();
      model.userData.type = "ifc";
      model.name == ifcProject.expressID;
      meshes[ifcProject.expressID] = model;

      //get longlat:
      const ifcSite = await ifcLoader.ifcManager.getAllItemsOfType(
        modelID,
        4097777520,
        true
      );

      const data = ifcSite[0];
      // console.log('%c IFCDATA', 'background:red', data)
      const location = await ifcLoader.ifcManager.getItemProperties(
        modelID,
        data.ObjectPlacement.value,
        true
      )
      // console.log('%c IFCDATA LOCATION', 'background:red', location.RelativePlacement.Location)  //@mg






      if (data && data.RefLongitude) {  //@mg if no data on ifcsite
        model.userData.RefLongitude =
          data.RefLongitude[0].value +
          data.RefLongitude[1].value / 60 +
          data.RefLongitude[2].value / 3600;
        model.userData.RefLatitude =
          data.RefLatitude[0].value +
          data.RefLatitude[1].value / 60 +
          data.RefLatitude[2].value / 3600;
      }

      const propertiesPromises = [];

      let generateMeshTree = function (node, parentObject) {
        let mesh = meshes[node.expressID];

        if (mesh == null) {
          mesh = new THREE.Object3D();
          meshes[node.expressID] = mesh;
        }


        propertiesPromises.push(
          ifcLoader.ifcManager.getItemProperties(
            modelID,
            Number(node.expressID),
            false
          )
        );

        if (parentObject) {
          parentObject.add(mesh);
        }

        for (let childNode of node.children) {
          generateMeshTree(childNode, mesh);
        }

        //console.log(node)
      };

      generateMeshTree(ifcProject, null);

      const layersData = await ifcLoader.ifcManager.getAllItemsOfType(
        modelID,
        2022622350,
        true
      );

      layersData.forEach((layer) => {
        layer.guids = [];
      });



      for (let expressId in meshes) {
        const element = await ifcLoader.ifcManager.getItemProperties(
          modelID,
          Number(expressId),
          false
        );

        if (element.Representation) {
          const shapeRep = await ifcLoader.ifcManager.getItemProperties(
            modelID,
            element.Representation.value,
            false
          );

          if (shapeRep) {
            const id = shapeRep.Representations[0].value;

            for (let layer of layersData) {
              for (let item of layer.AssignedItems) {
                if (item.value == id) {
                  layer.guids.push(element.GlobalId.value);
                }
              }
            }
          }
        }
      }

      const layers = layersData.map((layer) => ({
        name: layer.Name.value,
        objects: layer.guids,
      }));



      let accuTypes = {};

      //add properties
      const allPoperties = await Promise.all(propertiesPromises); //promised was pushed in generatemeshtree function
      for (let properties of allPoperties) {
        const mesh = meshes[properties.expressID];
        if (mesh) {
          mesh.userData.ifcType = properties.constructor.name;
          mesh.userData.guid = properties.GlobalId
            ? properties.GlobalId.value
            : null;
          mesh.userData.name = properties.Name ? properties.Name.value : null;
          mesh.userData.longName = properties.LongName
            ? properties.LongName.value
            : null;

          mesh.name = mesh.userData.guid;
          mesh.userData.expressID = properties.expressID;


          if (!accuTypes[mesh.userData.ifcType]) {
            accuTypes[mesh.userData.ifcType] = [];
          }
          accuTypes[mesh.userData.ifcType].push(mesh.name)



          if (mesh.userData.ifcType == "IfcSpace") {


            mesh.visible = false;
          }
        }
      }


      // console.table(accuTypes)
      const jsonModel = model.toJSON();
      jsonModel.layers = layers;



      jsonModel.singleMesh = ifcModel.mesh.toJSON();




      return jsonModel
    } catch (err) {
      throw err;
    }
  }

}
