import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToolboxEvent } from 'src/models/toolbox-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  loggedIn = false;
  user = null;
  fullscreen = false;
  currentLoadedProject = null;
  viewEditorOpen = false;
  isAvailable = false;
  isOwner = false;
  projectFolderOpen = false;
  notifications = {}
  newNotifications = false;
  nevigateToTicketDialogOn = false;

  subscriptions: Subscription[] = [];

  // to output a message to parent when the user-menu is loaded and rdy to start tutoJS
  statusMenu = false;
  @Output() eventStatusMenu = new EventEmitter<any>();
  @Output() eventStartTuto = new EventEmitter<any>();

  constructor(private translate: TranslateService, private appActionsService: AppActionsService, private dataService: DataService, private router: Router, private cdr: ChangeDetectorRef) { }


  ngOnInit() {


    this.subscriptions.push(
      this.appActionsService.loggedIn.subscribe(() => {

        this.loggedIn = true;
        this.user = this.dataService.user;
        this.notifications = this.dataService.notifications;
        this.newNotifications = false;
        for (let key in this.notifications) {
          if (!this.notifications[key].markedAsRead) {
            this.newNotifications = true;
          }
        }
        this.cdr.detectChanges();
      })
    )

    this.subscriptions.push(
      this.dataService.notificationsUpdated.subscribe(() => {
        this.newNotifications = false;
        for (let key in this.notifications) {
          if (!this.notifications[key].markedAsRead) {
            this.newNotifications = true;
          }
        }
        this.cdr.detectChanges();
      })

    )
    this.subscriptions.push(
      this.appActionsService.openProject.subscribe(project => {
        this.currentLoadedProject = project;

        if (project.roles[this.dataService.user.id] == 'owner') {
          this.isOwner = true;
        } else {
          this.isOwner = false;
        }
      })
    )

    this.subscriptions.push(
      this.appActionsService.projectsDataChanged.subscribe(() => {


        const updatedProject = this.dataService.user.projects.find(p => (p.id == this.currentLoadedProject.id))

        if (updatedProject) {

          this.currentLoadedProject = updatedProject;
        }


        this.cdr.detectChanges();
      })
    )







    this.subscriptions.push(
      this.appActionsService.openCloseViewsEditor.subscribe(status => {

        if (status == 'open') {
          this.viewEditorOpen = true;
        }

        if (status == 'close') {
          this.viewEditorOpen = false;
        }
      })
    )

    this.subscriptions.push(
      this.appActionsService.openCloseProjectsFolder.subscribe(status => {

        if (status == 'open') {
          this.projectFolderOpen = true;
        }

        if (status == 'close') {
          this.projectFolderOpen = false;
        }
      })
    )

    // send message to parent to inform three is loaded
    this.statusMenu = true
    this.sendMessage();

  }

  ngAfterViewInit() {
    // this.updateLangIcon();
  }
  updateLangIcon() {

    let flagBtn = document.getElementById('flagBtn');

    (this.translate.currentLang == 'en') ? (

      flagBtn.classList.remove('flag-icon-fr'),
      flagBtn.classList.add('flag-icon-gb')

    )
      : (

        flagBtn.classList.remove('flag-icon-gb'),
        flagBtn.classList.add('flag-icon-fr')

      );
  }

  changeLang() {
    //toggle switch current language and flag with : https://github.com/lipis/flag-icon-css/blob/master/css/flag-icon.css


    (this.translate.currentLang == 'en') ? (
      this.translate.use('fr')

    )
      : (this.translate.currentLang == 'fr') ? (
        this.translate.use('es')
      )
        : (this.translate.currentLang == 'es') ? (
          this.translate.use('en'))
        :  this.translate.use('fr')
        



  }

  // startTuto() {
  //   console.log('startTuto');
  //   this.eventStartTuto.emit(true);
  // }

  sendMessage() {
    this.eventStatusMenu.emit(this.statusMenu);
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }





  menuBtnClicked(event) {

    let e = event.srcElement;
    let newState = null;

    if (!e.classList.contains('active')) {
      newState = 'active';
    }

    let buttons = document.getElementsByClassName('user-menu-btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }

    if (newState) {
      e.classList.add(newState);
    }

  }

  openProjectFolder() {
    this.appActionsService.openCloseProjectsFolder.next('open');
  }

  toggleProjectsFolder() {

    if (this.projectFolderOpen) {
      this.appActionsService.openCloseProjectsFolder.next('close')
    } else {
      this.appActionsService.openCloseProjectsFolder.next('open');
    }
  }

  myProfileClicked() {

    const url = window.location.origin + '/account/';

    var a = document.createElement("a");
    a.href = url;
    a.setAttribute('target', '_blank');

    a.click();
  }

  createProjectTicket() {

    const url = window.location.origin + '/account/tickets/create/' + this.currentLoadedProject.id;

    var a = document.createElement("a");
    a.href = url;
    a.setAttribute('target', '_blank');

    a.click();
  }

  showAssetsCredits() {
    this.appActionsService.showAssetsCredits = true
  }



  logoutClicked() {

    this.dataService.logout().then(() => {
      window.location.href = window.location.origin + "/account/signin/?redirect=" + encodeURIComponent(window.location.href);
    })
  }

  loadView(viewId) {
    this.dataService.loadView(viewId)
  }

  toggleEditorViewerMode(e) {
    //e.stopPropagation();
    this.appActionsService.editorMode = !this.appActionsService.editorMode;
    this.appActionsService.selectedModelId.next()
    this.appActionsService.modelIdToAdd.next();
    if (this.dataService.viewConfig.selectedMode == 'measureMode') {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))
    }

  }

  toggleViewsEditor() {

    if (this.viewEditorOpen) {
      this.appActionsService.openCloseViewsEditor.next('close')
    } else {
      this.appActionsService.openCloseViewsEditor.next('open');
    }

  }

  goWebsite() {
    window.location.href = window.location.origin;
  }
  notificationsMenuClosed(key) {

    this.dataService.markAllNotifcationsAsRead()
  }

  archiveNotification(event, key) {
    event.stopPropagation();
    this.dataService.archiveNotifcation(key)

  }


  openTicketDialog() {
    this.nevigateToTicketDialogOn = true;
  }

  closeTicketDialog() {
    this.nevigateToTicketDialogOn = false;
  }

  toggleLockAspectRatio() {
    this.appActionsService.lockAspectRatio = !this.appActionsService.lockAspectRatio;
    this.appActionsService.resize.next()
  }

}
