export function resizeImage(imageFile, maxPixelDim = 200) {

  return new Promise<string>((resolve, reject) => {
    if (imageFile instanceof Blob || (imageFile.type == 'image/png') || (imageFile.type == 'image/jpeg') || (imageFile.type == 'image/jpg') || (imageFile.type == 'image/gif')) {



      var reader = new FileReader();
      reader.onload = (event: any) => {
        var img = new Image();
        img.onload = () => {


          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');


          if (img.height > img.width) {
            let height = Math.min(img.height, maxPixelDim)
            canvas.width = img.width * height / img.height;
            canvas.height = height;

          } else {
            let width = Math.min(img.width, maxPixelDim)

            canvas.width = width;
            canvas.height = img.height * width / img.width;
          }



          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);


          const data = canvas.toDataURL()

          resolve(data)




        }


        if (typeof event.target.result == 'string') {
          img.src = event.target.result;
        }



      }

      reader.readAsDataURL(imageFile);
    }
  })

}

export async function resizeToPowerOf2(image: File | Blob) {
  function getImageSize(file) {

    return new Promise<{ width: number, height: number }>((resolve, reject) => {
      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].indexOf(file.type) == -1) {
        reject('not image file')
        return;
      }


      var reader = new FileReader();
      reader.onload = (event: any) => {
        var image = new Image();

        //Validate the File Height and Width.
        image.onload = () => {
          const width = image.naturalWidth;
          const height = image.naturalHeight;
          console.log(width, height)
          resolve({ width, height })
        };

        image.onerror = reject;

        if (typeof event.target.result == 'string') {
          image.src = event.target.result;

        }





      }

      reader.readAsDataURL(file)
    })

  }


  function setImageSize(img, wantedWidth, wantedHeight) {


    return new Promise<string>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        var img = new Image();
        img.onload = () => {
  
  
          // We create an image to receive the Data URI
          // var img = document.createElement("img");
  
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
  
          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth;
          canvas.height = wantedHeight;
  
          // We resize the image with the canvas method drawImage();
          ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);
  
  
          const data = canvas.toDataURL("image/webp", .9);
  
  
          resolve(data)
  
  
  
  
        }
  
  
        if (typeof event.target.result == 'string') {
          img.src = event.target.result;
        }
  
  
  
      }
  
      reader.readAsDataURL(img);
  
    })

  
  }


  const { width, height } = await getImageSize(image)


  let [width_2, height_2] = [0, 0]
  let closestRatio = 1;
  let ratio = width / height;
  if (ratio < 1) {
    ratio = 1 / ratio;
    closestRatio = Math.round(ratio);

    height_2 = Math.pow(2, Math.floor(Math.log2(height)));
    width_2 = height_2 / closestRatio;


  } else {

    closestRatio = Math.round(ratio);

    width_2 = Math.pow(2, Math.floor(Math.log2(width)))
    height_2 = width_2 / closestRatio;


  }


  const imgdata = await setImageSize(image, width_2, height_2)


  // Will create a blob from data uri
  const blob = await fetch(imgdata).then((r) => r.blob());
  return {
    blob, finalRatio: closestRatio, originalRatio: ratio
  };





}

export function InOutQuadBlend(t: number) {
  if (t <= 0.5)
    return 2.0 * t * t;
  t -= 0.5;
  return 2.0 * t * (1.0 - t) + 0.5;
}

export async function waitSeconds(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
      return;
    }, time * 1000);
  })

}