/*
 *create a semaphore releasing access after a given time
 */

class DelaySetValueBimshow {
  //time in millisecond
  constructor(time,threeJSComponent,key,needRender){
    this.threeJSComponent = threeJSComponent
    this.key = key
    this.time = time ? time : 1500
    this.needRender = needRender
    this.currentTimeoutID = null
    this.currentSource = null
    this.sources = []
  }
  switchValueDelay(valueStart,valueStop,source){  console.log('delay switchValueDelay',valueStop)
    if (this.currentTimeoutID) {
      clearTimeout(this.currentTimeoutID)
      const indexCurrentSource = this.sources.indexOf(this.currentSource);
      if (indexCurrentSource != -1){
        this.sources.splice(indexCurrentSource, 1);
      }
      this.currentSource = null
      this.currentTimeoutID = null
    }
    this.threeJSComponent[this.key] = valueStart
    const index = this.sources.indexOf(source);
    if (index == -1){
      this.sources.push(source)
      if(this.needRender){
        this.threeJSComponent.render()
      }
    }
    this.currentSource = source
    this.currentTimeoutID = setTimeout(() => { 
      const index = this.sources.indexOf(source);
      if(index != -1){
        this.sources.splice(index, 1);
      }
      this.currentTimeoutID = null
      if(this.sources.length == 0){ console.log('delay switchValueDelay',valueStop)
        this.threeJSComponent[this.key] = valueStop
        if(this.needRender){
          this.threeJSComponent.render()
        }
      }
    }, this.time);

  }
  setWithDelayStop(value,source){ console.log('delay setWithDelayStop',value)
    if (this.currentTimeoutID) {
      clearTimeout(this.currentTimeoutID)
      const indexCurrentSource = this.sources.indexOf(this.currentSource);
      if (indexCurrentSource != -1){
        this.sources.splice(indexCurrentSource, 1);
      }
      this.currentSource = null
      this.currentTimeoutID = null
    }

    const index = this.sources.indexOf(source);
    if(index != -1){
      this.sources.splice(index, 1);
    }
    if(this.sources.length == 0){
      this.currentSource = source
      this.currentTimeoutID = setTimeout(() => { 
        this.currentTimeoutID = null
        this.threeJSComponent[this.key] = value
        if(this.needRender){
          this.threeJSComponent.render()
        }
      }, this.time);
    }
  }
  setNoDelayStart(value,source){console.log('delay setNoDelayStart',value)
    if (this.currentTimeoutID) {
      clearTimeout(this.currentTimeoutID)
      this.currentTimeoutID = null
      const indexCurrentSource = this.sources.indexOf(this.currentSource);
      if (indexCurrentSource != -1){
        this.sources.splice(indexCurrentSource, 1);
      }
      this.currentSource = null
    }
    this.threeJSComponent[this.key] = value
    const index = this.sources.indexOf(source);
    if (index == -1){
      this.sources.push(source)
      if(this.needRender){
        this.threeJSComponent.render()
      }
    }
  }

}

export {DelaySetValueBimshow};
