import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { Note } from '../../models/note.model';
import { AppActionsService } from '../services/app-actions.service';

import { DataService } from '../services/data.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit, OnDestroy {
  note: Note = null;
  userAsync: Observable<any>;

  @ViewChild('noteTitle', { static: false }) title;
  @ViewChild('noteDescription', { static: false }) description;
  @ViewChild('deleteButton', { static: false }) deleteButton: ElementRef;

  constructor(private appActionsService: AppActionsService, private dataService: DataService, public dialog: MatDialog) { }

  subscriptions: Subscription[] = [];

  // to output a message to parent when the note is loaded and rdy
  statusNote = false;
  @Output() eventStatusNote = new EventEmitter<any>();

  ngOnInit() {
    console.log('init note');


    this.subscriptions.push(
      this.dataService.notesDataFetched.subscribe((notes) => {

        if (this.note) {
          for (let i = 0; i < notes.length; i++) {
            if (notes[i].id == this.note.id) {
              this.loadNote(notes[i]);
              return;
            }
          }

        }


      }))

    this.subscriptions.push(
      this.appActionsService.openNote.subscribe((note) => {
        if(note) {
          this.loadNote(note)
        } else {
          this.closeNote();
        }

      }))

      // send message to parent to inform note is loaded
      this.statusNote = true
      this.sendMessage();





  }

  sendMessage(){
    this.eventStatusNote.emit(this.statusNote);
  }

  ngOnDestroy() {

    this.subscriptions.forEach( sub =>{sub.unsubscribe();})
  }


  closeNote() {
    this.note=null;
    let domElement = document.getElementById("app-note");
    domElement.classList.remove('on');
  }
  loadNote(note: Note) {
    let domElement = document.getElementById("app-note");
    domElement.classList.add('on');
    this.note = note;
    this.dataService.getUserById(note.createdBy).then(user => {
      this.userAsync = of(user);
    })

  }

  titleKeyDown(e) {
    return e.which != 13;
  }

  noteTitleBlured() {
    if (this.title.nativeElement.innerText == '') {

      this.title.nativeElement.innerText = 'Untitled Note'
    }

    let oldText = this.note.title;
    this.dataService.updateNoteDetails(this.note.id, { title: this.title.nativeElement.innerText }).catch(err => {
      console.warn('note update failed', err.message)
      this.title.nativeElement.innerText = oldText;
    })


  }


  noteDescriptionBlured() {
    if (this.description.nativeElement.innerText == '') {

      this.description.nativeElement.innerText = 'No Description'
    }
    let oldText = this.note.description;
    this.dataService.updateNoteDetails(this.note.id, { description: this.description.nativeElement.innerText }).catch(err => {
      console.warn('note update failed', err.message)
      this.description.nativeElement.innerText = oldText;
    })

  }

  changeNoteType(type: string) {


    let oldType = this.note.type;
    this.dataService.updateNoteDetails(this.note.id, { type: type }).catch(err => {
      console.warn('note update failed', err.message)
      this.note.type = oldType;
    })



  }

  deleteNoteClicked() {


    const dialogRef = this.dialog.open(DeleteNoteDialog, {
      width: '450px'
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {


      this.deleteButton['_elementRef'].nativeElement.classList.remove('cdk-focused')
      this.deleteButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused')
      if (result) {
        this.deleteNote();
      }



    }));


  }

  deleteNote() {

    this.dataService.deleteNote(this.note.id).then(()=>{
      this.appActionsService.openNote.next();

    }).catch(err => {
      console.warn(err)
    })
  }

  closeNoteClicked() {
    this.appActionsService.openNote.next()
  }

  noEnterPredicte() {
    return false;
  }





  notePositionButtonClicked(){
    this.appActionsService.openNote.next(this.note)

  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

}


@Component({
  selector: 'deleteNoteDialog',
  templateUrl: 'deleteNoteDialog.html',
})
export class DeleteNoteDialog { }
