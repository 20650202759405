import { Pipe, PipeTransform } from '@angular/core';
/*

*/
@Pipe({ name: 'searchInValue', pure: false })

export class SearchInValuePipe implements PipeTransform {

    transform(items: any[], values: string[], str: string): any {

        return items.filter(item => {

            let inString = false;
            values.forEach(value => {
                if (item[value]) {
                    if (item[value].toLowerCase().includes(str.toLowerCase())) {
                        inString = true;
                    }
                }

            })
            return inString;

        });

    }
}