import { Component, OnInit, Input, HostListener, Output,EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { UUID } from 'angular2-uuid';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-view-gallery-editor',
  templateUrl: './view-gallery-editor.component.html',
  styleUrls: ['./view-gallery-editor.component.scss']
})
export class ViewGalleryEditorComponent implements OnInit {

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Input('view') view;
  loadedImage = null;
  minimized = false;
  noHover = false;

  constructor(private sanitizer: DomSanitizer, private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {
    this.dataService.deleteRemovedViewImagesFromStorage(this.view)
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove(event) {
    this.noHover = !!event.buttons;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp','ArrowLeft','ArrowRight','z','Z','w','W'].includes(event.key)) {
      event.stopImmediatePropagation();
    }
  }

  uploadFile(files) {


    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      if ((element.type == 'image/png') || (element.type == 'image/jpeg') || (element.type == 'image/jpg') || (element.type == 'image/gif')) {



        var reader = new FileReader();
        reader.onload = (event: any) => {
          var img = new Image();
          img.onload = () => {


            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            canvas.width = 100 * img.width / img.height;
            canvas.height = 100;



            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            this.addImage(element, canvas.toDataURL())

            //


          }
          if (typeof event.target.result == 'string') {
            img.src = event.target.result;
          }



        }

        reader.readAsDataURL(element);
      }
    }
  }

 

  addImage(file, thumbData) {
    let fileType = null;
    if (file.type == 'image/png') {
      fileType = 'png';
    }

    if (file.type == 'image/jpeg') {
      fileType = 'jpg';
    }

    if (file.type == 'image/jpg') {
      fileType = 'jpg';
    }

    if (file.type == 'image/gif') {
      fileType = 'gif';
    }

    let storageName = 'IMG_' + new Date().getTime() + '.' + fileType;
    this.dataService.uploadViewImageToStorage(this.view.id, storageName, file).then(
      resolved => {
        let newImage = {
          id: UUID.UUID(),
          title: file.name,
          thumbData: thumbData,
          storageName: storageName

        }

        if (this.view.gallery == null) {
          this.view['gallery'] = [];
        }

        this.view.gallery.push(newImage);
        this.changed.emit();
      },
      rejected => {
        console.warn(rejected)
      }
    )


  }

  removeImage(image) {

    for (let i = 0; i < this.view.gallery.length; i++) {

      if (image.id == this.view.gallery[i].id) {

        this.view.gallery.splice(i, 1);
        this.changed.emit();
      }
    }
  }


  titleKeyDown(e) {
    return e.which != 13;
  }


  imageTitleBlured(event, image) {
    let newTitle = event.target.innerText;
    if (newTitle == '') {

      newTitle = 'Untitled Note'
    }

    image.title = newTitle;


  }

  droppedImage(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.view.gallery, event.previousIndex, event.currentIndex);
    this.changed.emit();
  }


  toggleMinimized() {
    this.minimized = !this.minimized;
  }

}
